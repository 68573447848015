import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Ticker from 'react-ticker';

import { Grid, Typography } from '@mui/material';

import { compareDates } from '../Helpers/DateHelper';
import theme from '../theme';
import { SystemMessage } from '../Types';

const RedBand: FC<SystemMessage> = ({ startDate, endDate, messageEn, messageFr }) => {
  const { i18n } = useTranslation();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  i18n.on('languageChanged', () => {
    // trigger re-render for the ticker to update
    setIsEnabled(false);
    setTimeout(() => {
      setIsEnabled(true);
    }, 100);
  });

  useEffect(() => {
    const now = new Date();
    if (
      startDate &&
      endDate &&
      compareDates(now, new Date(startDate)) >= 0 &&
      compareDates(now, new Date(endDate)) <= 0
    ) {
      setIsEnabled(true);
    }
  }, [startDate, endDate]);

  return (
    <>
      {isEnabled && (
        <Grid
          id="redBand-wrapper"
          item
          container
          alignItems="center"
          xs={12}
          sx={{ backgroundColor: `${theme.palette.primary.main}`, height: '60px' }}
        >
          <Grid item xs={12} paddingTop={0.5} paddingBottom={0.5} paddingLeft={2} paddingRight={2}>
            {(i18n.language.startsWith('en') && messageEn.length >= 150) ||
            (i18n.language.startsWith('fr') && messageFr.length >= 150) ? (
              <Ticker mode="smooth">
                {() => (
                  <Typography noWrap color={theme.palette.white.main} sx={{ marginRight: theme.spacing(5) }}>
                    {i18n.language.startsWith('en') ? messageEn : messageFr}
                  </Typography>
                )}
              </Ticker>
            ) : (
              <Typography noWrap color={theme.palette.white.main}>
                {i18n.language.startsWith('en') ? messageEn : messageFr}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RedBand;
