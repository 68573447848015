import { FC } from 'react';

import { Button, Fade, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import theme from '../../../theme';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    maxWidth: '75px',
    maxHeight: '20px',
    minWidth: '50px',
    minHeight: '15px',
  },
}));

export interface EditButtonProps {
  open: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  dataTestId?: string;
}

const EditButton: FC<EditButtonProps> = ({ open, onClick, dataTestId = 'editButton' }) => {
  const classes = useStyles();

  return (
    <Fade in={open}>
      <Button
        size="small"
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onClick}
        data-testid={dataTestId}
      >
        <Typography sx={{ color: theme.palette.white.main, fontWeight: 700, fontSize: '11pt' }}>Edit</Typography>
      </Button>
    </Fade>
  );
};

export default EditButton;
