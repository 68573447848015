import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createDOMPurify from 'dompurify';

import { FAQs } from '../axios';
import { BlobDto } from '../Types';

export const GetFaqContent = (): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [faq, setFaq] = useState('');

  const DOMPurify = createDOMPurify(window);

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      FAQs.getFaq(i18n.language.substring(0, 2))
        .then((data: BlobDto) => {
          setFaq(data.blobHtml);
        })
        .catch(() => {
          // if there is an error here then it's probably that the FAQs hasn't been set yet. don't display error message.
          setFaq('');
        });
    };

    onLoadFromBlobStorage();
  }, [i18n.language, t]);

  const sanitizedFaq = DOMPurify.sanitize(faq);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: sanitizedFaq }} />
    </>
  );
};
