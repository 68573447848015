import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';

import { Users as AxiosUser } from '../../../axios';
import Loader from '../../../Components/Loader';
import { getComparator, Order } from '../../../Helpers/TableHelper';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import theme from '../../../theme';
import { ModeEnum, ReactLocationState, UserProfileSearchRequest, UserProfileSearchResult } from '../../../Types';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface UserProfileSearchResultsProps {
  fetchedResults: Array<UserProfileSearchResult>;
  requestUserSearch: UserProfileSearchRequest;
}

interface HeadCell {
  id: keyof UserProfileSearchResult;
  label: string;
  width: number;
}

const UserProfileSearchResults: FC<UserProfileSearchResultsProps> = ({ fetchedResults, requestUserSearch }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const { state } = useLocation<ReactLocationState>();

  const [isLoadingGetUserById, setIsLoadingGetUserById] = useState<boolean>(false);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserProfileSearchResult>('userId');

  const headCells: readonly HeadCell[] = [
    {
      id: 'userId',
      label: t('userAdmin.userAdmin.userId'),
      width: 15,
    },
    {
      id: 'userName',
      label: t('userAdmin.userAdmin.name'),
      width: 15,
    },
    {
      id: 'organization',
      label: t('userProfile.create.lblEmployedAtOrg'),
      width: 22,
    },
    {
      id: 'reportingCompany',
      label: t('userProfile.create.lblEmployedAtCompany'),
      width: 22,
    },
    {
      id: 'unit',
      label: t('userAdmin.userAdmin.unit'),
      width: 16,
    },
    {
      id: 'status',
      label: t('userAdmin.userAdmin.status'),
      width: 10,
    },
  ];

  const handleClickEditUser = async (id: number) => {
    setIsLoadingGetUserById(true);
    let userFetch;
    await AxiosUser.findById(id)
      .then((result) => {
        userFetch = result;
        setIsLoadingGetUserById(false);

        history.push({
          pathname: '/user-administration/userProfile',
          state: {
            ...state,
            userProfileMode: ModeEnum.Update,
            userProfileToEdit: userFetch,
            userSearchRequest: requestUserSearch,
          } as ReactLocationState,
        });
      })
      .catch((error) => {
        handleError(error, { error403: t('userAdmin.error403') });
        setIsLoadingGetUserById(false);
      });
  };

  const handleRequestSort = (property: keyof UserProfileSearchResult) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof UserProfileSearchResult) => () => {
    handleRequestSort(property);
  };

  return (
    <TableContainer component={'div'} data-testid="tableContainer">
      <Table size="small" stickyHeader aria-label="table-history-result" sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ width: `${headCell.width}%` }}
                data-testid={`headerCell-${headCell.id}`}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="right" sx={{ width: '7%' }}>
              {t('userAdmin.userAdmin.profile')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="tableBody">
          {fetchedResults
            .map((oneFetchedResult) => ({
              id: oneFetchedResult.id,
              userId: oneFetchedResult.userId,
              userName: oneFetchedResult.userName,
              organization: i18n.language.startsWith('en')
                ? oneFetchedResult.organization?.nameEn
                  ? oneFetchedResult.organization?.nameEn
                  : ''
                : oneFetchedResult.organization?.nameFr
                ? oneFetchedResult.organization?.nameFr
                : '',
              reportingCompany:
                oneFetchedResult.organization && oneFetchedResult.reportingCompany
                  ? i18n.language.startsWith('en')
                    ? oneFetchedResult.reportingCompany?.nameEn
                      ? oneFetchedResult.reportingCompany?.nameEn
                      : ''
                    : oneFetchedResult.reportingCompany?.nameFr
                    ? oneFetchedResult.reportingCompany?.nameFr
                    : ''
                  : i18n.language.startsWith('en')
                  ? oneFetchedResult.organization?.nameEn
                    ? oneFetchedResult.organization?.nameEn
                    : ''
                  : oneFetchedResult.organization?.nameFr
                  ? oneFetchedResult.organization?.nameFr
                  : '',
              unit: i18n.language.startsWith('en')
                ? oneFetchedResult.unit?.nameEn
                  ? oneFetchedResult.unit?.nameEn
                  : ''
                : oneFetchedResult.unit?.nameFr
                ? oneFetchedResult.unit?.nameFr
                : '',
              status: oneFetchedResult.status,
            }))
            .sort(getComparator(order, orderBy))
            .map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                  sx={{ width: '28%' }}
                  data-testid={`tableCell-userId-${index}`}
                >
                  <Tooltip title={row.userId} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.userId}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '23%' }}
                  data-testid={`tableCell-userName-${index}`}
                >
                  <Tooltip title={row.userName} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.userName}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} sx={{ width: '13%' }} data-testid={`tableCell-org-${index}`}>
                  <Tooltip enterTouchDelay={0} title={row.organization}>
                    <Typography className={classes.textCell}>{row.organization}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} sx={{ width: '13%' }} data-testid={`tableCell-rpt-${index}`}>
                  <Tooltip enterTouchDelay={0} title={row.reportingCompany ?? ''}>
                    <Typography className={classes.textCell}>{row.reportingCompany}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} sx={{ width: '13%' }} data-testid={`tableCell-unit-${index}`}>
                  <Tooltip enterTouchDelay={0} title={row.unit ?? ''}>
                    <Typography className={classes.textCell}>{row.unit}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '10%' }}
                  data-testid={`tableCell-status-${index}`}
                >
                  <Tooltip
                    enterTouchDelay={0}
                    title={t('userAdmin.userAdmin.status', { context: row.status }).toString()}
                  >
                    <Typography className={classes.textCell}>
                      {t('userAdmin.userAdmin.status', { context: row.status })}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right" className={classes.tableCell} sx={{ width: '7%' }}>
                  <Button
                    className={classes.buttonText}
                    size="small"
                    variant="text"
                    color="primary"
                    id={`btnViewProfile${index}`}
                    onClick={() => handleClickEditUser(row.id)}
                    endIcon={<ArrowRightIcon />}
                    data-testid={`btnViewProfile${index}`}
                  >
                    {t('userAdmin.userAdmin.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Loader open={isLoadingGetUserById} />
    </TableContainer>
  );
};

export default UserProfileSearchResults;
