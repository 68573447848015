import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import * as DateHelper from '../../Helpers/DateHelper';
import { getConnectedUser } from '../../Slices/UserSlice';
import theme from '../../theme';
import { Functions, Policy } from '../../Types';
import EditDialogPolicy from './DataCorrection/Dialogs/EditDialogPolicy';
import EditButton from './DataCorrection/EditButton';
import PolicyDetail from './PolicyDetail';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      fontSize: '16pt',
      marginTop: '10px',
    },
    text: {
      textAlign: 'justify',
      fontWeight: 700,
      fontSize: '11pt',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('lg')]: {
        fontSize: '10pt',
      },
    },
    chip: {
      textTransform: 'capitalize',
      backgroundColor: undefined,
    },
    info: {
      display: 'inline',
      color: theme.palette.primary.dark,
    },
  })
);

interface PolicyItemProps {
  policy: Policy;
  isCorrectionMode: boolean;
  reportId: string;
  operatorKey: number;
  expand?: boolean;
  onAccordionStateChanged: () => void;
}

const PolicyItem: FC<PolicyItemProps> = ({
  policy,
  reportId,
  operatorKey,
  isCorrectionMode,
  expand = false,
  onAccordionStateChanged,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const [expanded, setExpanded] = useState<boolean>(expand);

  const [autocompleteEditDialogOpen, setAutocompleteEditDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  useEffect(() => {
    onAccordionStateChanged();
  }, [expanded, onAccordionStateChanged]);

  const isUserAllowedToEditPolicy = useCallback(() => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      return true;
    } else {
      const currentPolicyCompany = policy.carrier;

      const userCompanies = user.userFunctions.find(
        (oneUf) => oneUf.functionId === Functions.dataCorrection
      )?.reportingCompanies;

      return !!userCompanies?.find((oneComp) => oneComp.code === currentPolicyCompany.code);
    }
  }, [policy.carrier, user.userFunctions]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary data-testid={policy.policyNumber} expandIcon={<ArrowRightIcon />}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Typography className={classes.text}>
                {DateHelper.formatDateToLocalTime(policy.startDate, false)} {t('report.common.to')}{' '}
                {DateHelper.formatDateToLocalTime(policy.stopDate, false)}
              </Typography>
            </Grid>

            <Grid item xs={4} alignItems="center" paddingLeft={1}>
              <Typography className={classes.text} data-testid="typoOperator">
                {/* {(i18n.language.startsWith('fr')
                  ? policy.operatorType?.descriptionFr
                  : policy.operatorType?.descriptionEn) || t('report.common.notAvailable')} */}
                {i18n.language.startsWith('en') ? policy.carrier.nameEn : policy.carrier.nameFr}
              </Typography>
            </Grid>

            <Grid item container xs={3} justifyContent="flex-start" paddingLeft={1}>
              {policy.overlap && <Typography className={classes.text}>{t('report.policy.overLap')}</Typography>}
              {policy.overlap && policy.isCommercial && '\u00A0'}
              {policy.isCommercial && <Typography className={classes.text}>{t('report.policy.commercial')}</Typography>}
            </Grid>

            <Grid item container xs={3} justifyContent="flex-end">
              <Grid item container xs={isCorrectionMode ? 8 : 12} justifyContent="flex-end">
                <Typography className={classes.text} noWrap>
                  {(i18n.language.startsWith('fr') ? policy.status?.descriptionFr : policy.status?.descriptionEn) ||
                    t('report.common.notAvailable')}
                </Typography>
              </Grid>

              {isCorrectionMode && isUserAllowedToEditPolicy() && (
                <Grid item xs={4}>
                  <EditButton
                    open={isCorrectionMode}
                    onClick={(e) => {
                      setAutocompleteEditDialogOpen(true);
                      e.stopPropagation();
                    }}
                    dataTestId="statusEditButton"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          data-testid={`details${policy.policyNumber}`}
          sx={{ paddingBottom: 0, backgroundColor: theme.palette.secondary.light }}
        >
          {
            <PolicyDetail
              policy={policy}
              isCorrectionMode={isCorrectionMode}
              reportId={reportId}
              operatorKey={operatorKey}
              isUserAllowedToEditPolicy={isUserAllowedToEditPolicy}
            />
          }
        </AccordionDetails>
      </Accordion>

      {/* Dialog for Data correction */}
      <Dialog
        open={autocompleteEditDialogOpen}
        fullWidth
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setAutocompleteEditDialogOpen(false);
          }
        }}
      >
        <EditDialogPolicy
          title={t('vehicleReport.report.policies.policyStatus')}
          fieldName={'status'}
          onClose={() => setAutocompleteEditDialogOpen(false)}
          policy={policy}
          reportId={reportId}
          operatorKey={operatorKey}
        />
      </Dialog>
    </>
  );
};
export default PolicyItem;
