import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import Report90DSearch from '../Features/Report90Days/Report90DSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import NinetyDaysEN from '../Medias/titles/90-Days-EN.jpg';
// import NinetyDaysFR from '../Medias/titles/90-Days-FR.jpg';
import theme from '../theme';
import { Functions, User } from '../Types';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface Report90DaysSearchProps extends RouteComponentProps {
  user: User;
}

const Report90DaysSearch: FC<Report90DaysSearchProps> = ({ user }) => {
  const classes = useStyles();
  // const { t, i18n } = useTranslation();
  const { t } = useTranslation();

  const userAccessNinetyReport = doUserHaveAccessToFeature(user, Functions.ninetyDaysReport);

  return (
    user && (
      <Container className={classes.root}>
        {userAccessNinetyReport && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.90days')} />

                {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? NinetyDaysEN : NinetyDaysFR} /> */}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Report90DSearch />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    )
  );
};

export default withRouter(Report90DaysSearch);
