const myInitObject = {
  ibccrDomain: process.env.NODE_ENV === 'production' ? 'account.ibc.ca' : 'dashds.ibcdev.ca',
  provider: 'IBC.DASH',
  providerUrl: '#',
  providerAdress1: 'Adress1',
  providerAdress2: 'Adress2',
  providerEmail: 'admin@IBC.DASH.com',
  appTitle: 'IBC DASH',
  appSubtitle: 'A Web Application for insurers in canada',
  appVersion: process.env.NODE_ENV === 'production' ? '#B{Build.BuildNumber}#' : 'dev',
  applicationInsightsKey: process.env.NODE_ENV === 'production' ? '48d53246-9028-4bb9-b751-c4f0ff30cc43' : 'dev',
  authorityUrl:
    process.env.NODE_ENV === 'production'
      ? 'https://dashb2c.b2clogin.com/dashb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/v2.0/.well-known/openid-configuration'
      : 'https://dashdevb2c.b2clogin.com/dashdevb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/v2.0/.well-known/openid-configuration',
  clientId: process.env.NODE_ENV === 'production' ? 'cad7591c-d0fc-47ff-ac9a-e33ea6064df8' : '5546cb2d-4de8-48c6-81f8-bc59b9aad50a',
  scope:
    process.env.NODE_ENV === 'production'
      ? 'https://dashb2c.onmicrosoft.com/1da3f1c7-cb2b-4989-a547-c5dccc3ac5bb/ReadWrite'
      : 'https://dashdevb2c.onmicrosoft.com/2d3bda5e-ccce-4271-abd0-f55dfb3eeeba/ReadWrite',
  changePasswordUrl:
    process.env.NODE_ENV === 'production' ? '' : 'https://myaccount.microsoft.com',
  apiUrl: process.env.NODE_ENV === 'production' ? 'https://dashbeapi.ibc.ca/api' : 'https://localhost:6012/api',
  activateDevFeatures: process.env.NODE_ENV === 'production' ? '' : 'true',
  sessionTimeout: process.env.NODE_ENV === 'production' ? '15' : '60',
  passwordResetPortalUrl:
    process.env.NODE_ENV === 'production' ? 'https://ibccrpsr.ibc.ca' : 'https://ibccrpsr.ibcdev.ca',
  dashInfoSiteUrl: process.env.NODE_ENV === 'production' ? 'https://dashinfo.ibc.ca/' : 'https://dashinfo.ibc.ca/Home',
};

export const msalConfigs = {
  AUTHORITY_URL:
    process.env.NODE_ENV === 'production'
      ? 'https://login.microsoftonline.com/20aa26cc-dbc9-4321-a521-3618fbc92035'
      : 'https://login.microsoftonline.com/dc575541-0092-4e32-83b0-f791af747527',
  CLIENT_ID: process.env.NODE_ENV === 'production' ? 'ddaa4949-c60d-4975-828a-35aeaa60d31b' : 'f8c24ffa-049d-4f53-a5d1-1d4dd3fa482d',
  LOGIN_SCOPE: String(
    process.env.NODE_ENV === 'production'
      ? 'api://976d8ace-3333-4958-bfea-39a0819b4842/PasswordSelfServed'
      : 'api://e44f5b9e-6740-49dd-819a-5ffc10553b3f/PasswordSelfServed'
  )
    .trim()
    .split(';'),
};

export const DATE_MASK = '____-__-__';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_MASK_TIME_EN = '____-__-__ __:__ ____';
export const DATE_FORMAT_TIME_EN = 'yyyy-MM-dd hh:mm aaaa';
export const DATE_MASK_TIME_FR = '____-__-__ __:__';
export const DATE_FORMAT_TIME_FR = 'yyyy-MM-dd HH:mm';
export const ERROR_400_DOMAIN_NAME_NOT_PERMITTED_EMAIL = 'ERROR_400_DOMAIN_NAME_NOT_PERMITTED_EMAIL';
export const ERROR_400_DOMAIN_NAME_NOT_PERMITTED_USERID = 'ERROR_400_DOMAIN_NAME_NOT_PERMITTED_USERID';
export const ERROR_400_USERID_IN_USE = 'ERROR_400_USERID_IN_USE';
export const ERROR_400_EMAIL_IN_USE = 'ERROR_400_EMAIL_IN_USE';
export const ERROR_400_COMPANY_NOT_COMPANYCR = 'ERROR_400_COMPANY_NOT_COMPANYCR';
export const ERROR_400_COMPANY_NOT_IBCCR = 'ERROR_400_COMPANY_NOT_IBCCR';
export const ERROR_400_BROKER_NO_SPONSOR = 'ERROR_400_BROKER_NO_SPONSOR';
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';
export const ERROR_403_IS_ALLOWED_TO_MANAGE = 'ERROR_403_IS_ALLOWED_TO_MANAGE';
export const ERROR_403_IS_ALLOWED_TO_MANAGE_COMPANIES = 'ERROR_403_IS_ALLOWED_TO_MANAGE_COMPANIES';
export const ERROR_403_IS_ALLOWED_TO_MANAGE_FUNCTION = 'ERROR_403_IS_ALLOWED_TO_MANAGE_FUNCTION';
export const ERROR_403_IS_ACCOUNT_ENABLED = 'ERROR_403_IS_ACCOUNT_ENABLED';
export const PAGINATION_PAGESIZE = 100;
export const POLICY_STATUS = {
  ACTIVE: '1',
  ACTIVEDRIVERDELETED: '2',
  EXPIRED: '3',
  CANCELLEDFORNONPAYMENT: '4',
  CANCELLEDDUETOPOLICYRENEWALNOTTAKEN: '5',
  CANCELLEDBASEDONUNDERWRITING: '6',
  CANCELLEDFORMATERIALMISREPRESENTATION: '7',
  CANCELLEDATINSUREDSREQUEST: '8',
  CANCELLEDFORANYOTHERREASON: '9',
};
export default myInitObject;
