import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
import CorrectionReportSearch from '../Features/CorrectionReport/CorrectionReportSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
import theme from '../theme';
import { Functions, User } from '../Types';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface SystemReportPageProps extends RouteComponentProps {
  user: User;
}

const SystemReportPage: FC<SystemReportPageProps> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const correctionReportAccess = doUserHaveAccessToFeature(user, Functions.correctionReport);

  return (
    user && (
      <Container className={classes.root}>
        {correctionReportAccess && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.correctionReport')} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CorrectionReportSearch />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    )
  );
};

export default withRouter(SystemReportPage);
