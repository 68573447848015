import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../../../theme';
import { VehicleClaim } from '../../../../Types';
import VehicleReportClaimItem from './VehicleReportClaimItem';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      height: '32pt',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
      '@media print': {
        color: 'black',
        borderTop: '1px solid black',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: '16pt',
      [theme.breakpoints.down('md')]: {
        fontSize: '12pt',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '14pt',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16pt',
      },
    },
    noneContainer: {
      color: theme.palette.primary.dark,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      backgroundColor: theme.palette.white.main,
    },
    label: {
      display: 'inline',
      marginRight: '5px',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
    value: {
      display: 'inline',
      fontWeight: 'bold',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
  })
);

interface VehicleReportClaimsProps {
  claims: Array<VehicleClaim>;
  totalLoss: number;
  totalExpense: number;
}

const VehicleReportClaims: FC<VehicleReportClaimsProps> = ({ claims, totalLoss, totalExpense }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid container>
      <Grid container item data-testid="claimsHeader" alignItems="center" xs={12} className={classes.header}>
        <Grid item container xs={5} paddingLeft={1}>
          <Typography data-testid="header" className={classes.title}>
            {t('vehicleReport.report.claims.claims')}
          </Typography>
        </Grid>
        <Grid item container xs={7} justifyContent="flex-end" paddingRight={1} marginBottom={0.5}>
          <Grid item container xs={6} justifyContent="flex-end">
            <Typography className={classes.label}>{t('vehicleReport.report.claims.totalLoss')}:</Typography>
            <Typography className={classes.value}>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: 'CAD',
                currencyDisplay: 'narrowSymbol',
              }).format(totalLoss)}
            </Typography>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <Typography className={classes.label}>{t('vehicleReport.report.claims.totalExpense')}:</Typography>
            <Typography className={classes.value}>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: 'CAD',
                currencyDisplay: 'narrowSymbol',
              }).format(totalExpense)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ backgroundColor: theme.palette.secondary.light }}>
        {claims.length > 0 &&
          claims.map((oneClaim, index) => {
            return (
              <Grid key={index} item container xs={12} marginBottom={index === claims.length - 1 ? 0 : 0.5}>
                <VehicleReportClaimItem claim={oneClaim} />
              </Grid>
            );
          })}
        {claims.length === 0 && (
          <Grid item container xs={12} className={classes.noneContainer}>
            <Typography sx={{ fontWeight: 700 }}>{t('report.claim.none')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default VehicleReportClaims;
