import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import theme from '../../theme';
import { Inquiries } from '../../Types';

interface PreviousInquiriesProps {
  inquiries?: Array<Inquiries>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontWeight: 'bold',
      fontSize: '16pt',
      [theme.breakpoints.down('md')]: {
        fontSize: '12pt',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '14pt',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16pt',
      },
    },
    content: {
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      color: theme.palette.primary.dark,
    },
  })
);

const PreviousInquiries: FC<PreviousInquiriesProps> = ({ inquiries = [] }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container marginBottom={theme.spacing(2)}>
      <Grid container item xs={12} data-testid="inquiries" alignItems="center">
        <Grid item xs={12} sx={{ marginBottom: theme.spacing(2) }}>
          <Typography data-testid="header" className={classes.title}>
            {t('report.previousInquiries.header')}
          </Typography>
        </Grid>
        {inquiries.length > 0 &&
          inquiries.map((inc, i) => {
            return (
              <Grid key={i} item xs={12} className={classes.content}>
                <Typography>
                  {formatDateToLocalTime(inc.date, false)} -{' '}
                  {i18n.language.startsWith('en') ? inc.company.nameEn : inc.company.nameFr}
                </Typography>
              </Grid>
            );
          })}
        {inquiries.length === 0 && (
          <Grid item xs={12} padding={theme.spacing(2)}>
            <Typography>{t('report.previousInquiries.none')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PreviousInquiries;
