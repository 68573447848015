// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
//
// timestamp
//
import { formatInTimeZone } from 'date-fns-tz';
import enUs from 'date-fns/locale/en-US';

export function getTimestampByAddingDaysAfterToday(days: number): number {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  const nowInMs = Date.now();
  return nowInMs + oneDayInMs * days;
}

export function getTimestampByAddingDaysAfterTimestamp(days: number, timestamp: number): number {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  return timestamp + oneDayInMs * days;
}

//
// Date ISO
//
export function getDateFromTimestamp(timestamp: number): string {
  const date = new Date(timestamp);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset()); // remove UTC offset
  const strDate = date.toISOString().substring(0, 10); // ISOString always add UTC offset
  return strDate;
}

export function getDateFromNow(): string {
  return getDateFromTimestamp(Date.now());
}

export function getDateByAddingDaysAfterToday(days: number): string {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  const nowInMs = Date.now();
  return getDateFromTimestamp(nowInMs + oneDayInMs * days);
}

export function getDateByAddingDaysAfterTimestamp(days: number, timestamp: number): string {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  return getDateFromTimestamp(timestamp + oneDayInMs * days);
}

/**
 * Compares two Date objects and returns e number value that represents
 * the result:
 * 0 if the two dates are equal.
 * 1 if the first date is greater than second.
 * -1 if the first date is less than second.
 * @param date1 First date object to compare.
 * @param date2 Second date object to compare.
 */
export function compareDates(date1: Date, date2: Date): number {
  // With Date object we can compare dates them using the >, <, <= or >=.
  // The ==, !=, ===, and !== operators require to use date.getTime(),
  // so we need to create a new instance of Date with 'new Date()'

  // Check if the dates are equal
  const same = date1.getTime() === date2.getTime();
  if (same) return 0;

  // Check if the first is greater than second
  if (date1 > date2) return 1;

  // Check if the first is less than second
  if (date1 < date2) return -1;

  return 0;
}

/**
 * Compares two Date objects without timestamp and returns true or false
 * @param date1 First date object to compare.
 * @param date2 Second date object to compare.
 */
export function isSameDate(date1: Date, date2: Date): boolean {
  return date1.toDateString() === date2.toDateString();
}

/**
 * Checks if a Date is valid and returns true or false
 * @param date date to check.
 */
export const isValidDate = (date: Date | string | null) => {
  if (date && isNaN(new Date(date).valueOf())) {
    return false;
  } else {
    return true;
  }
};

//
// Date constructor
//
export function getYearFromDate(date: string): number {
  return parseInt(date.slice(0, 4));
}
export function getMonthIndexFromDate(date: string): number {
  return parseInt(date.slice(5, 7)) - 1;
}
export function getDayFromDate(date: string): number {
  return parseInt(date.slice(8, 10));
}
export function getHourFromTimestamp(timestamp: number): number {
  const date = new Date(timestamp);
  return date.getHours();
}

export const formatMonthYearBirthDateString = (date: string): string => {
  const splitedDate = date.split('-');
  return `${splitedDate[0]} - ${splitedDate[1]}`;
};

export const getDateStringWithoutTime = (date: Date | string) => {
  if (typeof date === 'string') {
    if (!date.includes('T')) {
      return date + 'T00:00:00';
    }
    return date;
  }
  return date.toDateString();
};

export const createDateIgnoreTime = (date: Date | string) => {
  let dateString = date;
  if (typeof date === 'string' && !date.includes('T')) {
    dateString = dateString + 'T00:00:00';
  }
  return new Date(dateString);
};

export const getDateIgnoreTime = (date: Date) => {
  const offset = date.getTimezoneOffset();

  if (offset > 0) {
    return new Date(date.getTime() + offset * 60000);
  } else {
    return new Date(date.getTime() - offset * 60000);
  }
};

export const formatDateToLocalTime = (
  date: Date | string,
  showHours: boolean,
  showHoursDash?: boolean,
  hostTimezone?: string
): string => {
  if (date) {
    return formatInTimeZone(
      date,
      hostTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      showHours ? (showHoursDash ? 'yyyy-MM-dd HH-mm-ss' : 'yyyy-MM-dd HH:mm:ss zzz') : 'yyyy-MM-dd',
      {
        timeZone: hostTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale: enUs,
      }
    );
  }
  return '';
};
