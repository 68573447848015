import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as DateHelper from '../../Helpers/DateHelper';
import theme from '../../theme';
import { Claims } from '../../Types';
import EditDialogClaim from './DataCorrection/Dialogs/EditDialogClaim';
import EditButton from './DataCorrection/EditButton';

interface ClaimDetailProps {
  claim?: Claims;
  isUserAllowedToEditClaim: () => boolean;
  isCorrectionMode: boolean;
  reportId: string;
  operatorKey: number;
  isAllowedToEditClaim: boolean;
}
const useStyles = makeStyles({
  container: {
    marginBottom: theme.spacing(0.5),
  },
  subContainer: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    background: theme.palette.white.main,
  },
  colonneLeft: {
    paddingRight: theme.spacing(2),
  },
  label: {
    display: 'inline',
    color: theme.palette.primary.dark,
  },
  value: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '5px',
    padding: '5px',
    color: theme.palette.primary.dark,
  },
});

const ClaimDetail: FC<ClaimDetailProps> = ({
  claim,
  isUserAllowedToEditClaim,
  isCorrectionMode,
  reportId,
  operatorKey,
  isAllowedToEditClaim,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [fieldSelected, setFieldSelected] = useState<'vehicle' | 'vehicleLoss' | 'fpDriver' | 'fpDriverExcluded'>(
    'vehicle'
  );
  const [titleDialog, setTitleDialog] = useState<string>('');
  const [autocompleteEditDialogOpen, setAutocompleteEditDialogOpen] = useState<boolean>(false);

  const isAllowedToEditFirstPartyDriver = useMemo(
    () =>
      claim?.claimDetailFirstParty?.firstPartyOperatorId
        ? claim?.claimDetailFirstParty?.firstPartyOperatorId > 0 ||
          !!claim?.claimDetailFirstParty?.firstPartyDriverFullName
        : false,
    [claim?.claimDetailFirstParty?.firstPartyDriverFullName, claim?.claimDetailFirstParty?.firstPartyOperatorId]
  );

  const handleEditButtonClick = (field: 'vehicle' | 'vehicleLoss' | 'fpDriver' | 'fpDriverExcluded') => {
    setFieldSelected(field);
    setAutocompleteEditDialogOpen(true);
    switch (field) {
      case 'vehicle':
        setTitleDialog(t('report.claim.vehicle'));
        return;
      case 'vehicleLoss':
        setTitleDialog(t('report.claim.vehicleLoss'));
        return;
      case 'fpDriver':
        setTitleDialog(t('report.claim.firstPartyDriver'));
        return;
      case 'fpDriverExcluded':
        setTitleDialog(t('report.claim.firstPartyDriverExcluded'));
        return;
      default:
        return;
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        {claim && (
          <Grid item container xs={12}>
            <Grid item container xs={12} className={classes.subContainer}>
              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.policyholderName')}:</Typography>
                  <Typography className={classes.value}>
                    {' '}
                    {claim.policyholderName || t('report.common.notAvailable')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.policyholderAddress')}:</Typography>
                  <Typography className={classes.value}>
                    {claim.policyholderFullAddress || t('report.common.notAvailable')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.policyNumber')}:</Typography>
                  <Typography className={classes.value}>
                    {claim.policyNumber || t('report.common.notAvailable')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.claimNumber')}:</Typography>
                  <Typography className={classes.value}>
                    {claim.claimNumber || t('report.common.notAvailable')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} paddingLeft={3}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.coverage')}:</Typography>
                  <Typography className={classes.value}>
                    {(claim?.majorCoverages &&
                      claim.majorCoverages.length > 0 &&
                      claim.majorCoverages.map((c, index) =>
                        claim.majorCoverages.length - 1 === index
                          ? `${i18n.language.startsWith('fr') ? c.descriptionFr : c.descriptionEn}`
                          : `${i18n.language.startsWith('fr') ? c.descriptionFr : c.descriptionEn}, `
                      )) ||
                      t('report.common.notAvailable')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.dateReported')}:</Typography>
                  <Typography className={classes.value}>
                    {DateHelper.formatDateToLocalTime(claim.dateReported, false)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{t('report.claim.claimStatus')}:</Typography>
                  <Typography className={classes.value}>
                    {(i18n.language.startsWith('fr') ? claim.status?.descriptionFr : claim.status?.descriptionEn) || ''}
                  </Typography>
                </Grid>
                {/* false lines - empty space */}
                <Box height="100%" />
              </Grid>
            </Grid>

            <Grid item container xs={12} className={classes.subContainer}>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.totalLoss')}:</Typography>
                <Typography className={classes.value} data-testid="totalLoss">
                  {new Intl.NumberFormat(i18n.language, {
                    style: 'currency',
                    currency: 'CAD',
                    currencyDisplay: 'narrowSymbol',
                  }).format(claim.totalLoss)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.totalExpense')}:</Typography>
                <Typography className={classes.value} data-testid="totalExpense">
                  {new Intl.NumberFormat(i18n.language, {
                    style: 'currency',
                    currency: 'CAD',
                    currencyDisplay: 'narrowSymbol',
                  }).format(claim.totalExpense)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>&nbsp;</Typography>
              </Grid>
              <Grid item xs={12}>
                {claim.claimLosses &&
                  claim.claimLosses.map((kol, i) => {
                    return (
                      <Grid item key={i}>
                        <Typography className={classes.label}>
                          {`${kol.code ? `KOL${kol.code} - ` : ''}${
                            i18n.language.startsWith('en') ? kol.descriptionEn : kol.descriptionFr
                          }:`}
                        </Typography>
                        <Typography className={classes.value}>
                          {`${new Intl.NumberFormat(i18n.language, {
                            style: 'currency',
                            currency: 'CAD',
                            currencyDisplay: 'narrowSymbol',
                          }).format(kol.lossAmount ?? 0)} (${t('report.claim.labelLoss')}); `}

                          {`${new Intl.NumberFormat(i18n.language, {
                            style: 'currency',
                            currency: 'CAD',
                            currencyDisplay: 'narrowSymbol',
                          }).format(kol.expenseAmount ?? 0)} (${t('report.claim.labelExpense')}); `}

                          {kol.isReserve ? `${t('report.claim.labelReserve')} (${kol.reserveDate})` : ''}
                        </Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>

            <Grid item container xs={12} className={classes.subContainer}>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.vehicle')}:</Typography>
                <Typography className={classes.value}>
                  {(i18n.language.startsWith('fr') ? claim.vehicleFr : claim.vehicleEn) ||
                    t('report.common.notAvailable')}
                </Typography>
                {isCorrectionMode &&
                  isUserAllowedToEditClaim() &&
                  claim.involvement?.code == 'F' &&
                  isAllowedToEditClaim && (
                    <EditButton
                      open={isCorrectionMode}
                      onClick={() => handleEditButtonClick('vehicle')}
                      dataTestId="vehicleEditButton"
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.typeOfUseVehicleClass')}:</Typography>
                <Typography className={classes.value}>
                  {claim.involvement?.code != 'F'
                    ? '******'
                    : `${claim.typeOfUse?.code || t('report.common.notAvailable')} / ${
                        (i18n.language.startsWith('fr')
                          ? claim.vehicleClass?.descriptionFr
                          : claim.vehicleClass?.descriptionEn) || t('report.common.notAvailable')
                      }`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.vehicleLoss')}:</Typography>
                <Typography className={classes.value}>
                  {claim.involvement?.code != 'F'
                    ? '******'
                    : (i18n.language.startsWith('fr')
                        ? claim.vehicleLoss?.descriptionFr
                        : claim.vehicleLoss?.descriptionEn) || t('report.common.notAvailable')}
                </Typography>
                {isCorrectionMode &&
                  isUserAllowedToEditClaim() &&
                  claim.involvement?.code == 'F' &&
                  isAllowedToEditClaim && (
                    <EditButton
                      open={isCorrectionMode}
                      onClick={() => handleEditButtonClick('vehicleLoss')}
                      dataTestId="vehicleLossEditButton"
                    />
                  )}
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.subContainer}>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.firstPartyDriver')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailFirstParty.firstPartyDriverFullName || t('report.common.notAvailable')}
                </Typography>
                {isCorrectionMode &&
                  isUserAllowedToEditClaim() &&
                  claim.involvement?.code == 'F' &&
                  isAllowedToEditClaim &&
                  isAllowedToEditFirstPartyDriver && (
                    <EditButton
                      open={isCorrectionMode}
                      onClick={() => handleEditButtonClick('fpDriver')}
                      dataTestId="fpDriverEditButton"
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.firstPartyDriverDln')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailFirstParty.firstPartyDriverDLN || t('report.common.notAvailable')}{' '}
                  {claim.claimDetailFirstParty.firstPartyDriverDLN
                    ? (i18n.language.startsWith('fr')
                        ? claim.claimDetailFirstParty.firstPartyDriverDLNJurisdiction?.descriptionFr
                        : claim.claimDetailFirstParty.firstPartyDriverDLNJurisdiction?.descriptionEn) || ''
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.firstPartyDriverDob')}:</Typography>
                <Typography className={classes.value}>
                  {claim.involvement?.code != 'F' || claim.operatorOnHold
                    ? '******'
                    : (claim.claimDetailFirstParty.firstPartyDriverDateOfBirth
                        ? DateHelper.formatDateToLocalTime(
                            claim.claimDetailFirstParty.firstPartyDriverDateOfBirth,
                            false
                          )
                        : claim.claimDetailFirstParty.firstPartyDriverYearOfBirth) || t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.firstPartyDriverListedOnPolicy')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailFirstParty.firstPartyDriverListedOnPolicy !== null
                    ? t('report.claim.firstPartyDriverListedOnPolicy', {
                        context: claim.claimDetailFirstParty.firstPartyDriverListedOnPolicy ? 'yes' : 'no',
                      })
                    : '******'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.firstPartyDriverExcluded')}:</Typography>
                <Typography className={classes.value}>
                  {claim.involvement?.code != 'F'
                    ? '******'
                    : (i18n.language.startsWith('fr')
                        ? claim.claimDetailFirstParty.firstPartyDriverExcluded?.descriptionFr
                        : claim.claimDetailFirstParty.firstPartyDriverExcluded?.descriptionEn) ||
                      t('report.common.notAvailable')}
                </Typography>
                {isCorrectionMode &&
                  isUserAllowedToEditClaim() &&
                  claim.involvement?.code == 'F' &&
                  isAllowedToEditClaim && (
                    <EditButton
                      open={isCorrectionMode}
                      onClick={() => handleEditButtonClick('fpDriverExcluded')}
                      dataTestId="fpDriverExcludedEditButton"
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.firstPartyDriverAtFault')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailFirstParty.firstPartyDriverAtFaultPercentage ||
                  claim.claimDetailFirstParty.firstPartyDriverAtFaultPercentage === 0
                    ? `${claim.claimDetailFirstParty.firstPartyDriverAtFaultPercentage}%`
                    : t('report.common.notAvailable')}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} className={classes.subContainer}>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.thirdPartyDriver')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailThirdParty.thirdPartyDriverFullName || t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.thirdPartyDriverDln')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailThirdParty.thirdPartyDriverDLN || t('report.common.notAvailable')}{' '}
                  {claim.claimDetailThirdParty.thirdPartyDriverDLN
                    ? (i18n.language.startsWith('fr')
                        ? claim.claimDetailThirdParty.thirdPartyDriverDLNJurisdiction?.descriptionFr
                        : claim.claimDetailThirdParty.thirdPartyDriverDLNJurisdiction?.descriptionEn) || ''
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.thirdPartyDriverInsurerName')}:</Typography>
                <Typography className={classes.value}>
                  {(i18n.language.startsWith('fr')
                    ? claim.claimDetailThirdParty.thirdPartyDriverInsurerCompany?.nameFr
                    : claim.claimDetailThirdParty.thirdPartyDriverInsurerCompany?.nameEn) ||
                    t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.thirdPartyDriverPolicy')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailThirdParty.thirdPartyDriverPolicyNumber
                    ? claim.claimDetailThirdParty.thirdPartyDriverPolicyNumber
                    : t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.claim.thirdPartyDriverAtFault')}:</Typography>
                <Typography className={classes.value}>
                  {claim.claimDetailThirdParty.thirdPartyDriverAtFaultPercentage ||
                  claim.claimDetailThirdParty.thirdPartyDriverAtFaultPercentage === 0
                    ? `${claim.claimDetailThirdParty.thirdPartyDriverAtFaultPercentage}%`
                    : t('report.common.notAvailable')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Dialog for Data correction */}
      <Dialog
        open={autocompleteEditDialogOpen}
        fullWidth
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setAutocompleteEditDialogOpen(false);
          }
        }}
      >
        <EditDialogClaim
          title={titleDialog}
          fieldName={fieldSelected}
          onClose={() => setAutocompleteEditDialogOpen(false)}
          claim={claim!}
          reportId={reportId}
          operatorKey={operatorKey}
        />
      </Dialog>
    </>
  );
};

export default ClaimDetail;
