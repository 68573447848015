import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Checkbox, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DriverReportOptions } from '../../../../axios';
import HelperText from '../../../../Components/HelperText';
import Loader from '../../../../Components/Loader';
import Message from '../../../../Components/Message';
import { sortDriverReportOptions } from '../../../../Helpers/SortHelpers';
import useErrorHandler from '../../../../Hooks/UseErrorHandler';
import UsePreviousState from '../../../../Hooks/UsePreviousState';
import theme from '../../../../theme';
import {
  DriverReportOption,
  Functions,
  Organization,
  ReportingCompany,
  UserDriverReportOption,
  UserFunction,
} from '../../../../Types';
import { FormValuesCreateEditUserProfileProps } from './CreateEditUserProfile';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.white.main,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: `1px solid #e0e0e0`,
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

interface UserDriverOptionsProps {
  formValues: FormValuesCreateEditUserProfileProps;
  setFormValues: Dispatch<SetStateAction<FormValuesCreateEditUserProfileProps>>;
  organizations: Array<Organization>;
  isLoadingOrganizations: boolean;
  rptCompanies: Array<ReportingCompany> | null;
  isLoadingRpt: boolean;
  userToEditId: number | null;
  isDroError: boolean;
}

const UserDriverOptions: FC<UserDriverOptionsProps> = ({
  formValues,
  setFormValues,
  organizations,
  isLoadingOrganizations,
  rptCompanies,
  isLoadingRpt,
  userToEditId,
  isDroError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const [isLoadingDros, setIsLoadingDros] = useState<boolean>(false);
  const [driverReportOptions, setDriverReportOptions] = useState<Array<DriverReportOption> | null>(null);

  // Getter for the previous state of UserFunction to compare it to the current and condition the call to the BE
  const prevFormValuesUserFunctions = UsePreviousState(formValues.userFunctions) as UserFunction[];

  const noDefaultError = useMemo(
    () =>
      formValues.userDriverReportOptions.length > 0 &&
      !formValues.userDriverReportOptions.some((udro) => udro.isDefault),
    [formValues.userDriverReportOptions]
  );

  const noDroSelectedError = useMemo(
    () =>
      formValues.userFunctions.some((uf) => uf.functionId === Functions.driverReport) &&
      formValues.userDriverReportOptions.length === 0,
    [formValues.userFunctions, formValues.userDriverReportOptions.length]
  );

  useEffect(() => {
    if (
      (prevFormValuesUserFunctions &&
        prevFormValuesUserFunctions.find((uf) => uf.functionId === Functions.driverReport)?.reportingCompanies !==
          formValues.userFunctions.find((uf) => uf.functionId === Functions.driverReport)?.reportingCompanies) ||
      !prevFormValuesUserFunctions
    ) {
      setIsLoadingDros(true);
      DriverReportOptions.list(userToEditId)
        .then((results) => {
          const allDro = results;
          // We filter the dro based on what is inside the Driver Report Function
          if (formValues.userFunctions.some((uf) => uf.functionId === Functions.driverReport)) {
            const filteredDros = allDro.filter((oneDro) => {
              if (oneDro.reportingCompanyId) {
                return formValues.userFunctions
                  .find((uf) => uf.functionId === Functions.driverReport)
                  ?.reportingCompanies.find((rpt) => rpt.reportingCompanyId === oneDro.reportingCompanyId);
              } else {
                return formValues.userFunctions
                  .find((uf) => uf.functionId === Functions.driverReport)
                  ?.reportingCompanies.find((rpt) => rpt.organizationId === oneDro.organizationId);
              }
            });

            // for new user, if there's only 1 dro, set it as default and checked
            if (!userToEditId && filteredDros.length === 1) {
              const newUserDro: UserDriverReportOption = {
                userId: 0,
                driverReportOption: filteredDros[0],
                isDefault: true,
              };
              setFormValues({
                ...formValues,
                userDriverReportOptions: [newUserDro],
              });
            }

            setDriverReportOptions(filteredDros);
            setIsLoadingDros(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingDros(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues.userFunctions, handleError, prevFormValuesUserFunctions, t]);

  const sortDro = useCallback(
    (a: DriverReportOption, b: DriverReportOption): number => {
      return sortDriverReportOptions(a, b, i18n, organizations, rptCompanies);
    },
    [i18n, organizations, rptCompanies]
  );

  const handleSelectDro = (isChecked: boolean, dro: DriverReportOption) => {
    if (isChecked) {
      const newUserDro: UserDriverReportOption = {
        userId: userToEditId ? userToEditId : 0,
        driverReportOption: dro,
        isDefault: false,
      };
      setFormValues({ ...formValues, userDriverReportOptions: formValues.userDriverReportOptions.concat(newUserDro) });
    } else {
      const filteredUserDro = formValues.userDriverReportOptions.filter(
        (udro) => udro.driverReportOption.id !== dro.id
      );
      setFormValues({ ...formValues, userDriverReportOptions: filteredUserDro });
    }
  };

  const handleDefaultSelect = (isChecked: boolean, dro: DriverReportOption) => {
    if (isChecked) {
      const userDroInForm = formValues.userDriverReportOptions.find((udro) => udro.driverReportOption.id === dro.id);
      if (userDroInForm) {
        // if the dro is already selected, we update the whole list of userDro in the form to default false, and default true for the userDro we clicked on
        const updatedUserDros = formValues.userDriverReportOptions.map((userDro) => ({
          ...userDro,
          isDefault: userDro.driverReportOption.id === dro.id ? true : false,
        }));
        setFormValues({ ...formValues, userDriverReportOptions: updatedUserDros });
      } else {
        // the userDro is not selected
        const newUserDro: UserDriverReportOption = {
          userId: userToEditId ? userToEditId : 0,
          driverReportOption: dro,
          isDefault: true,
        };
        // if other userDros exist, we put them at false
        const updatedUserDros = formValues.userDriverReportOptions.map((userDro) => ({
          ...userDro,
          isDefault: false,
        }));
        updatedUserDros.push(newUserDro);
        setFormValues({ ...formValues, userDriverReportOptions: updatedUserDros });
      }
    }
  };

  return (
    <>
      {!isLoadingDros &&
        !isLoadingRpt &&
        !isLoadingOrganizations &&
        driverReportOptions &&
        driverReportOptions.length > 0 &&
        formValues.assignedOrganizationsIds.length !== 0 && (
          <Grid container item xs={12}>
            <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
              <Grid item xs={10}>
                <Typography align="left" sx={{ fontWeight: 700 }}>
                  {t('userProfile.rptOptions.lblHeader')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align="right" sx={{ fontWeight: 700 }}>
                  {t('userProfile.rptOptions.lblDefault')}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                border: isDroError && (noDefaultError || noDroSelectedError) ? '1px solid #F44336' : undefined,
              }}
            >
              {driverReportOptions
                .sort((a, b) => sortDro(a, b))
                .map((option, index) => (
                  <Grid container item key={index} xs={12} className={classes.tableRow}>
                    <Grid item xs={10} textAlign="left">
                      {i18n.language.startsWith('en') ? (
                        <FormControlLabel
                          control={<Checkbox color="secondary" data-testid={`checkbox-udro-en-${index}`} />}
                          checked={formValues.userDriverReportOptions.some(
                            (udro) => udro.driverReportOption.id === option.id
                          )}
                          onChange={(_, checked) => handleSelectDro(checked, option)}
                          label={`${
                            option.yearsOfData ? `${option.yearsOfData} Years` : t('userAdmin.companyProf.maxYears')
                          }  ${option.note ? ` - ${option.note}` : ''}`}
                        />
                      ) : (
                        <FormControlLabel
                          control={<Checkbox color="secondary" data-testid={`checkbox-udro-fr-${index}`} />}
                          checked={formValues.userDriverReportOptions.some(
                            (udro) => udro.driverReportOption.id === option.id
                          )}
                          onChange={(_, checked) => handleSelectDro(checked, option)}
                          label={`${
                            option.yearsOfData ? `${option.yearsOfData} ans` : t('userAdmin.companyProf.maxYears')
                          } ${option.note ? ` - ${option.note}` : ''}`}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="right">
                        <Radio
                          value={option.id}
                          checked={
                            formValues.userDriverReportOptions.find((udro) => udro.driverReportOption.id === option.id)
                              ?.isDefault ?? false
                          }
                          name="radio-buttons"
                          onChange={(_, isChecked) => {
                            handleDefaultSelect(isChecked, option);
                          }}
                          inputProps={{ 'aria-label': 'default-radiobutton' }}
                          data-testid={`radio-udro-default-${index}`}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            {isDroError && noDefaultError && (
              // <Message message="userProfile.rptOptions.noDefaultWarn" severity="warning" />
              <HelperText message="userProfile.rptOptions.noDefaultWarn" />
            )}
            {isDroError && noDroSelectedError && (
              // <Message message="userProfile.rptOptions.noUdroSelected" severity="warning" />
              <HelperText message="userProfile.rptOptions.noUdroSelected" />
            )}
          </Grid>
        )}
      {formValues.userFunctions.some(
        (uf) => uf.functionId === Functions.driverReport && uf.reportingCompanies.length > 0
      ) &&
        isDroError &&
        !isLoadingDros &&
        (!driverReportOptions || driverReportOptions.length === 0) && (
          <Message message="userProfile.rptOptions.noDroAvailable" severity="warning" />
        )}
      <Loader open={isLoadingDros || isLoadingRpt || isLoadingOrganizations} />
    </>
  );
};

export default UserDriverOptions;
