import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import Search from '../Features/Search/Search';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import DriversEN from '../Medias/titles/Drivers - EN.jpg';
// import DriversFR from '../Medias/titles/Drivers - FR.jpg';
import { Functions, User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface DriverReportSearchProps extends RouteComponentProps {
  user: User;
}

const DriverReportSearch: FC<DriverReportSearchProps> = ({ user }) => {
  const classes = useStyles();
  // const { i18n } = useTranslation();
  const { t } = useTranslation();

  const userAccessDriverReport = doUserHaveAccessToFeature(user, Functions.driverReport);

  return (
    user && (
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContainerTitle title={t('menu.driverReport')} />
            {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? DriversEN : DriversFR} /> */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {userAccessDriverReport && <Search user={user} />}
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default withRouter(DriverReportSearch);
