import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';

import Message from '../Components/Message';
import { ApplicationPaths } from '../Features/Authorization/AuthorizationConstants';
import { clearStore } from '../Slices/NotificationSlice';

const Signout: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearStore());
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert(ApplicationPaths.LoginPage);
    } else {
      sessionStorage.clear();
      history.push(ApplicationPaths.LoginPage);
    }
  }, [dispatch, history]);

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center" data-testid="signoutContainer">
        <Grid item xs={12}>
          <Message message={`successMessage.signedOut`} severity="success" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(Signout);
