import { requests } from '../axios';
import { Unit, UnitFactory } from '../Types';

export const createUnits = (params: {
  name: string;
  organizationId: number;
  reportingCompanyId?: number;
}): Promise<Unit> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Unit>((resolve) => {
      const { name, organizationId, reportingCompanyId } = params;
      resolve(UnitFactory(1, name, name, organizationId, reportingCompanyId));
    });
  } else {
    return requests.post('/units/create', params);
  }
};

export const disableUnits = (id: number): Promise<Unit> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Unit>((resolve) => {
      resolve(UnitFactory(1, 'Unité A', 'Unit A', 1, 1));
    });
  } else {
    return requests.delete(`/units/${id}`);
  }
};

export const getUnitsByCompanyId = (
  functionId: number,
  organizationId: number,
  reportingCompanyId: number | null
): Promise<Unit[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Unit[]>((resolve) => {
      if (organizationId === 3 && reportingCompanyId === 7) {
        resolve([]);
      } else {
        resolve([UnitFactory(1, 'Unité A', 'Unit A', 1), UnitFactory(2, 'Unité B', 'Unit B', 2, 1)]);
      }
    });
  } else {
    return requests.get(
      `/units/getByOrganization/${functionId}/?organizationId=${organizationId}`.concat(
        reportingCompanyId ? `&reportingCompanyId=${reportingCompanyId}` : ''
      ),
      {}
    );
  }
};

export const listUnits = (functionId: number): Promise<Unit[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Unit[]>((resolve) => {
      resolve([UnitFactory(1, 'Unité A', 'Unit A', 1), UnitFactory(2, 'Unité B', 'Unit B', 2, 1)]);
    });
  } else {
    return requests.get(`/units/get/${functionId}`, {});
  }
};

export const updateUnits = (
  id: number,
  params: { name: string; organizationId: number; reportingCompanyId?: number }
): Promise<Unit> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Unit>((resolve) => {
      const { name, organizationId, reportingCompanyId } = params;
      resolve(UnitFactory(1, name, name, organizationId, reportingCompanyId));
    });
  } else {
    return requests.put(`/units/${id}`, params);
  }
};
