import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Autocomplete, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { DataCorrection } from '../../../../axios';
import DashCustomInput from '../../../../Components/DashCustomInput';
import Loader from '../../../../Components/Loader';
import useErrorHandler from '../../../../Hooks/UseErrorHandler';
import { fieldNameCorrection } from '../../../../Pages/Report';
import { addNotification } from '../../../../Slices/NotificationSlice';
import { setUpdatedReport } from '../../../../Slices/ReportSlice';
import theme from '../../../../theme';
import { ClaimCorrectionType, Claims, ReactLocationState, ReferenceType } from '../../../../Types';
import { ExcludedDriverEnum, ExcludedDriverEnumList, ExcludedDriverEnumOption } from '../../../../Types/Enums';

interface EditDialogClaimProps {
  title: string;
  fieldName: fieldNameCorrection;
  claim: Claims;
  reportId: string;
  operatorKey: number;
  onClose: () => void;
}

const EditDialogClaim: FC<EditDialogClaimProps> = ({ title, reportId, operatorKey, claim, fieldName, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { state } = useLocation<ReactLocationState>();

  const [newValueAutocompAtFault, setNewValueAutocompAtFault] = useState<string | null>(`${claim.atFault}`);
  const [newValueAutocompVehicle, setNewValueAutocompVehicle] = useState<ReferenceType | null>(null);
  const [newValueAutocompVehicleLoss, setNewValueAutocompVehicleLoss] = useState<ReferenceType | null>(null);
  const [newValueAutocompDriver, setNewValueAutocompDriver] = useState<ReferenceType | null>(null);
  const [newValueAutocompDriverExcluded, setNewValueAutocompDriverExcluded] = useState<ExcludedDriverEnumOption | null>(
    claim.claimDetailFirstParty.firstPartyDriverExcluded
      ? claim.claimDetailFirstParty.firstPartyDriverExcluded.descriptionEn === 'No'
        ? ExcludedDriverEnumList.find((e) => e.value === ExcludedDriverEnum.no)!
        : ExcludedDriverEnumList.find((e) => e.value === ExcludedDriverEnum.yes)!
      : null
  );

  const [atFaultOptions, setAtFaultOptions] = useState<string[] | null>(null);
  const [vehicleOptions, setVehicleOptions] = useState<ReferenceType[] | null>(null);
  const [vehicleLossOptions, setVehicleLossOptions] = useState<ReferenceType[] | null>(null);
  const [driverOptions, setDriverOptions] = useState<ReferenceType[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingVehicle, setLoadingVehicle] = useState<boolean>(false);
  const [loadingVehicleLoss, setLoadingVehicleLoss] = useState<boolean>(false);
  const [loadingDriver, setLoadingDriver] = useState<boolean>(false);

  useEffect(() => {
    setLoadingVehicle(true);
    setLoadingVehicleLoss(true);
    setLoadingDriver(true);

    DataCorrection.getVehiclesClaims(claim.id)
      .then((results) => {
        setVehicleOptions(results);
        setNewValueAutocompVehicle(results.find((r) => r.id === claim.vehicleId) ?? null);
        setLoadingVehicle(false);
      })
      .catch((error) => {
        handleError(error);
        setLoadingVehicle(false);
      });

    DataCorrection.getOperatorsClaims(claim.id)
      .then((results) => {
        const filteredResults = results.filter((r) => r.id > 0);
        setDriverOptions(filteredResults);
        setNewValueAutocompDriver(
          filteredResults.find((r) => r.id === claim.claimDetailFirstParty.firstPartyOperatorId) ?? null
        );
        setLoadingDriver(false);
      })
      .catch((error) => {
        handleError(error);
        setLoadingDriver(false);
      });

    DataCorrection.getVehicleLosses()
      .then((results) => {
        setVehicleLossOptions(results);
        setNewValueAutocompVehicleLoss(results.find((r) => r.code === claim.vehicleLoss?.code) ?? null);
        setLoadingVehicleLoss(false);
      })
      .catch((error) => {
        handleError(error);
        setLoadingVehicleLoss(false);
      });

    setAtFaultOptions(['0', '25', '50', '75', '100']);
  }, [
    claim.claimDetailFirstParty.firstPartyOperatorId,
    claim.id,
    claim.vehicleId,
    claim.vehicleLoss?.code,
    handleError,
  ]);

  const handleSave = () => {
    if (newValueAutocompVehicle) {
      const params: ClaimCorrectionType = {
        claimKey: claim.id,
        operatorKey: operatorKey,
        reportId: reportId,
        firstPartyDegreeAtFault: newValueAutocompAtFault,
        firstPartyOperatorKey: newValueAutocompDriver ? newValueAutocompDriver.id : -1,
        firstPartyVehicleKey: newValueAutocompVehicle.id,
        firstPartyVehicleLossId: newValueAutocompVehicleLoss ? newValueAutocompVehicleLoss.id : null,
        firstPartyExcludedDriverId: newValueAutocompDriverExcluded ? newValueAutocompDriverExcluded.value : null,
      };

      setIsLoading(true);
      DataCorrection.updateClaim(params)
        .then((result) => {
          dispatch(setUpdatedReport(result));
          dispatch(addNotification(200, 'error', t('report.dataCorrection.success200')));
          setIsLoading(false);
          onClose();

          history.replace(`/reports/${result.id}`, state);
        })
        .catch((error) => {
          handleError(error);
          setIsLoading(false);
        });
    }
  };

  const handleAutoCompChange = (value: ReferenceType | ExcludedDriverEnumOption | string | null) => {
    switch (fieldName) {
      case 'atFault':
        setNewValueAutocompAtFault(value as string);
        return;
      case 'fpDriver':
        setNewValueAutocompDriver(value as ReferenceType);
        return;
      case 'vehicle':
        setNewValueAutocompVehicle(value as ReferenceType);
        return;
      case 'vehicleLoss':
        setNewValueAutocompVehicleLoss(value as ReferenceType);
        return;
      case 'fpDriverExcluded':
        setNewValueAutocompDriverExcluded(value as ExcludedDriverEnumOption);
        return;
      default:
        return;
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
      >{`${title}`}</Typography>
      <DialogContent sx={{ padding: theme.spacing(4) }}>
        <Grid container>
          {fieldName === 'atFault' ? (
            <Autocomplete
              fullWidth
              value={newValueAutocompAtFault}
              getOptionLabel={(option) => `${option}%`}
              id="atFault"
              options={atFaultOptions ?? []}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  fieldGridSize={12}
                  variant="outlined"
                  color="secondary"
                />
              )}
              onChange={(_, value) => {
                handleAutoCompChange(value);
              }}
              noOptionsText={t('search.noOption')}
              data-testid="atFaultAutocomplete"
            />
          ) : fieldName === 'fpDriver' ? (
            <Autocomplete
              fullWidth
              value={newValueAutocompDriver}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
              }
              id="fpDriver"
              options={driverOptions ?? []}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  fieldGridSize={12}
                  variant="outlined"
                  color="secondary"
                />
              )}
              onChange={(_, value) => {
                handleAutoCompChange(value);
              }}
              noOptionsText={t('search.noOption')}
              loading={loadingDriver}
            />
          ) : fieldName === 'vehicle' ? (
            <Autocomplete
              fullWidth
              value={newValueAutocompVehicle}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
              }
              id="vehicle"
              options={vehicleOptions ?? []}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  fieldGridSize={12}
                  variant="outlined"
                  color="secondary"
                />
              )}
              onChange={(_, value) => {
                handleAutoCompChange(value);
              }}
              noOptionsText={t('search.noOption')}
              loading={loadingVehicle}
            />
          ) : fieldName === 'fpDriverExcluded' ? (
            <Autocomplete
              fullWidth
              value={newValueAutocompDriverExcluded}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={(option) => t(`${option.label}`)}
              id="fpDriverExcluded"
              options={ExcludedDriverEnumList}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  fieldGridSize={12}
                  variant="outlined"
                  color="secondary"
                />
              )}
              onChange={(_, value) => {
                handleAutoCompChange(value);
              }}
              noOptionsText={t('search.noOption')}
              loading={loadingVehicle}
            />
          ) : (
            fieldName === 'vehicleLoss' && (
              <Autocomplete
                fullWidth
                value={newValueAutocompVehicleLoss}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={(option) =>
                  i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
                }
                id="vehicleLoss"
                options={vehicleLossOptions ?? []}
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    fieldGridSize={12}
                    variant="outlined"
                    color="secondary"
                  />
                )}
                onChange={(_, value) => {
                  handleAutoCompChange(value);
                }}
                noOptionsText={t('search.noOption')}
                loading={loadingVehicleLoss}
                data-testid="vehicleLossAutocomplete"
              />
            )
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
          <Grid item xs={4}>
            <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={onClose}>
              {t('dialog.cancel')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              data-testid="accept"
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={
                loadingDriver ||
                loadingVehicle ||
                loadingVehicleLoss ||
                (newValueAutocompAtFault === null &&
                  newValueAutocompDriver === null &&
                  newValueAutocompVehicle === null) ||
                (fieldName === 'atFault' &&
                  (newValueAutocompAtFault === null || newValueAutocompAtFault === `${claim.atFault}`)) ||
                (fieldName === 'fpDriver' &&
                  (newValueAutocompDriver === null ||
                    newValueAutocompDriver?.id === claim.claimDetailFirstParty.firstPartyOperatorId)) ||
                (fieldName === 'vehicle' &&
                  (newValueAutocompVehicle === null || newValueAutocompVehicle?.id === claim.vehicleId)) ||
                (fieldName === 'vehicleLoss' &&
                  (newValueAutocompVehicleLoss === null ||
                    newValueAutocompVehicleLoss?.code === claim.vehicleLoss?.code)) ||
                (fieldName === 'fpDriverExcluded' &&
                  (newValueAutocompDriverExcluded === null ||
                    (!!claim.claimDetailFirstParty.firstPartyDriverExcluded &&
                      newValueAutocompDriverExcluded?.value ===
                        (claim.claimDetailFirstParty.firstPartyDriverExcluded.descriptionEn === 'No'
                          ? ExcludedDriverEnum.no
                          : ExcludedDriverEnum.yes))))
              }
            >
              {t('dialog.save')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {isLoading && <Loader open={true} />}
    </>
  );
};

export default EditDialogClaim;
