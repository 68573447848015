export interface VehicleUse {
  vehicleUseCode: string;
  vehicleUseDescriptionEn: string | null;
  vehicleUseDescriptionFr: string | null;
}

export const VehicleUseFactory = (props: VehicleUse): VehicleUse => {
  return {
    vehicleUseCode: props.vehicleUseCode,
    vehicleUseDescriptionEn: props.vehicleUseDescriptionEn,
    vehicleUseDescriptionFr: props.vehicleUseDescriptionFr,
  };
};

export default VehicleUse;
