import Claims from './Claims';
import CodeReference from './CodeReference';
import CompanyReference from './CompanyReference';
import Gaps from './Gaps';
import Inquiries from './Inquiries';
import Policy from './Policies';

export interface Report {
  id: string;
  driverId: number;
  referenceNumber: string;
  yearsOfData: number | null;
  reportDate: Date;
  ianaTimezone: string;
  lastUpdated: Date;
  requestorEn: string;
  requestorFr: string;
  disclaimerEn: string;
  disclaimerFr: string;
  company: CompanyReference;
  fullName: string;
  firstName: string;
  middleName: string;
  surName: string;
  fullAddress: string;
  addressPostalCode: string;
  dln: string;
  dlnJurisdiction: CodeReference;
  dateOfBirth: Date | null;
  yearOfBirth: number | null;
  gender: CodeReference | null;
  maritalStatus: CodeReference | null;
  numberOfClaimsLast6Years: number | null;
  atFaultAccidentsLast6Years: number | null;
  comprehensiveLossesLast6Years: number | null;
  numberOfPoliciesAtThisAddress: number | null;
  numberOfDCPDClaimsLast6Years: number | null;
  yearsLicensed: number | null;
  yearsOfContinuousInsurance: number | null;
  numberOfYearsClaimsFree: number | null;
  driverTraining: CodeReference | null;
  albertaOperatorGridLevel: number | null;
  albertaOperatorGridIndicator: number | null;
  albertaGridRatingDate: Date | null;
  policies: Array<Policy>;
  possibleCoverageGaps: Array<Gaps>;
  claims: Array<Claims>;
  previousInquiries: Array<Inquiries>;
}

export const ReportFactory = (
  id: string,
  driverId: number,
  referenceNumber: string,
  yearsOfData: number | null,
  reportDate: Date,
  ianaTimezone: string,
  lastUpdated: Date,
  requestorEn: string,
  requestorFr: string,
  disclaimerEn: string,
  disclaimerFr: string,
  company: CompanyReference,
  fullName: string,
  firstName: string,
  middleName: string,
  surName: string,
  fullAddress: string,
  addressPostalCode: string,
  dln: string,
  dlnJurisdiction: CodeReference,
  dateOfBirth: Date | null,
  yearOfBirth: number | null,
  gender: CodeReference | null,
  maritalStatus: CodeReference | null,
  numberOfClaimsLast6Years: number | null,
  atFaultAccidentsLast6Years: number | null,
  comprehensiveLossesLast6Years: number | null,
  numberOfPoliciesAtThisAddress: number | null,
  numberOfDCPDClaimsLast6Years: number | null,
  yearsLicensed: number | null,
  yearsOfContinuousInsurance: number | null,
  numberOfYearsClaimsFree: number | null,
  driverTraining: CodeReference | null,
  albertaOperatorGridLevel: number | null,
  albertaOperatorGridIndicator: number | null,
  albertaGridRatingDate: Date | null,
  policies: Array<Policy>,
  possibleCoverageGaps: Array<Gaps>,
  claims: Array<Claims>,
  previousInquiries: Array<Inquiries>
): Report => {
  return {
    id,
    driverId,
    referenceNumber,
    yearsOfData,
    reportDate,
    ianaTimezone,
    lastUpdated,
    requestorEn,
    requestorFr,
    disclaimerEn,
    disclaimerFr,
    company,
    fullName,
    firstName,
    middleName,
    surName,
    fullAddress,
    addressPostalCode,
    dln,
    dlnJurisdiction,
    dateOfBirth,
    yearOfBirth,
    gender,
    maritalStatus,
    yearsLicensed,
    yearsOfContinuousInsurance,
    numberOfYearsClaimsFree,
    driverTraining,
    numberOfClaimsLast6Years,
    atFaultAccidentsLast6Years,
    comprehensiveLossesLast6Years,
    numberOfPoliciesAtThisAddress,
    numberOfDCPDClaimsLast6Years,
    albertaOperatorGridLevel,
    albertaOperatorGridIndicator,
    albertaGridRatingDate,
    policies,
    possibleCoverageGaps,
    claims,
    previousInquiries,
  };
};

export default Report;
