import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';

import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { ReportingCompanies, Units, UsageReport } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportCsv from '../../Components/ExportCsv';
import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { DATE_FORMAT, DATE_MASK } from '../../Helpers/Constants';
import { getDateFromNow, isValidDate } from '../../Helpers/DateHelper';
import { sortOrganizations, sortRptCompanies, sortUnits } from '../../Helpers/SortHelpers';
import useAutofocus from '../../Hooks/UseAutofocus';
import { useDateValidation } from '../../Hooks/UseDateValidation';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../Slices/OrganizationSlice';
import theme from '../../theme';
import {
  Functions,
  Organization,
  ReactLocationState,
  ReportingCompany,
  Unit,
  UsageReportRequest,
  UsageReportResponse,
} from '../../Types';
import { UsageReportResponseCsv } from '../../Types/UsageReport';
import UsageReportResults from './UsageReportResults';

const UsageReportSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname } = useLocation<ReactLocationState>();

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const [companies, setCompanies] = useState<Array<ReportingCompany>>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);

  const [results, setResults] = useState<Array<UsageReportResponse> | null>(null);
  const [resultsCsv, setResultsCsv] = useState<Array<UsageReportResponseCsv> | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  // company and org are outside formValues because it requires a default value after a fetch
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<ReportingCompany | null>(null);

  const todayDate = new Date();
  const [formValues, setFormValues] = useState<Omit<UsageReportRequest, 'organizationId' | 'reportingCompanyId'>>({
    userId: '',
    userName: '',
    unitId: null,
    fromDate: todayDate,
    toDate: todayDate,
  });

  // We need those to prevent the results table to update its own dates without the system doing another search
  const [fromDateResult, setFromDateResult] = useState<Date | null>(null);
  const [toDateResult, setToDateResult] = useState<Date | null>(null);

  const [fileName, setFilename] = useState<string | undefined>();

  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);
  const [fetchedUnits, setFetchedUnits] = useState<Array<Unit> | null>(null);
  const [unitId, setUnitId] = useState<number | null>(null);

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(selectedOrg ? selectedOrg.id : null);
  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  const csvHeaders = [
    {
      key: 'organization',
      label: t('usageReport.organization'),
    },
    {
      key: 'reportingCompany',
      label: t('usageReport.company'),
    },
    {
      key: 'unit',
      label: t('usageReport.unit'),
    },
    {
      key: 'userId',
      label: t('usageReport.userId'),
    },
    {
      key: 'userName',
      label: t('usageReport.username'),
    },
    {
      key: 'numberOfRequest',
      label: t('usageReport.numberOfRequests'),
    },
  ];

  const organizationRef = useAutofocus();

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  // Fetch the correct organizations
  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.usageReport }));
  }, [dispatch]);

  // Loads the UserFunctionCompanies
  useEffect(() => {
    if (selectedOrg) {
      setIsLoadingCompanies(true);
      ReportingCompanies.getByOrganization(Functions.usageReport, selectedOrg.id)
        .then((results) => {
          setCompanies(results);
          setIsLoadingCompanies(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingCompanies(false);
        });
    }
  }, [dispatch, handleError, selectedOrg, t]);

  // Initialize selectedOrg if there is only one organizations available to the user
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setSelectedOrg(organizations[0]);
    }
  }, [organizations]);

  const sortOrganization = useCallback(
    (a: Organization, b: Organization): number => {
      return sortOrganizations(a, b, i18n);
    },
    [i18n]
  );

  // Initialize selectedCompany if there is only one company available to the user
  useEffect(() => {
    if (companies && companies.length === 1) {
      setSelectedCompany(companies[0]);
    }
  }, [companies]);

  useEffect(() => {
    if ((isIbcOrThirdPartySelected && selectedOrg && !selectedCompany) || (selectedOrg && selectedCompany)) {
      setIsLoadingUnits(true);

      Units.getUnitsByCompanyId(Functions.usageReport, selectedOrg.id, selectedCompany ? selectedCompany.id : null)
        .then((results) => {
          setFetchedUnits(results);
          setIsLoadingUnits(false);
          // if (results.length === 1) {
          //   setUnitId(results[0].id);
          // } else
          if (results.length > 1 && formValues.unitId) {
            setUnitId(formValues.unitId);
          }
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingUnits(false);
        });
    }
  }, [dispatch, formValues.unitId, handleError, isIbcOrThirdPartySelected, selectedOrg, selectedCompany, t]);

  const sortCompanies = useCallback(
    (a: ReportingCompany, b: ReportingCompany): number => {
      return sortRptCompanies(a, b, i18n);
    },
    [i18n]
  );

  const handleAutocompleteOrgChange = (value: Organization | null) => {
    setSelectedOrg(value);
    setSelectedCompany(null);
    setUnitId(null);
  };

  const handleAutocompleteCompanyChange = (value: ReportingCompany | null) => {
    setSelectedCompany(value);
    setUnitId(null);
  };

  const handleAutocompleteUnitChange = (value: Unit | null) => {
    setUnitId(value ? value.id : null);
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  const resetFormValues = () => {
    setFormValues({
      userId: '',
      userName: '',
      unitId: null,
      fromDate: todayDate,
      toDate: todayDate,
    });
    setSelectedOrg(organizations.length === 1 ? organizations[0] : null);
    setSelectedCompany(companies.length === 1 && organizations.length === 1 ? companies[0] : null);
    setResults(null);
    setResultsCsv(null);
    setUnitId(null);

    // clear errors
    setErrorFromDate(false);
    setErrorToDate(false);
  };

  const sort = useCallback(
    (a: any, b: any, type: string): number => {
      switch (type) {
        case 'organization':
          return sortOrganizations(a, b, i18n);
        case 'reportingcompany':
          return sortRptCompanies(a, b, i18n);
        case 'unit':
          return sortUnits(a, b);
        default:
          return 0;
      }
    },
    [i18n]
  );

  const handleClearFields = () => {
    resetFormValues();
    // setFetchedUnits(null);
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const isFormValid = (): boolean => {
    let error = false;

    if (!isValidDate(formValues.fromDate)) {
      setErrorFromDate(true);
      error = true;
    } else {
      setErrorFromDate(false);
    }

    if (!isValidDate(formValues.toDate)) {
      setErrorToDate(true);
      error = true;
    } else {
      setErrorToDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSearch = () => {
    if (isFormValid()) {
      setIsLoadingResults(true);

      const params = {
        organizationId: selectedOrg ? selectedOrg.id : null,
        reportingCompanyId: selectedCompany ? selectedCompany.id : null,
        ...formValues,
        unitId: unitId,
        fromDate: formValues.fromDate
          ? new Date(
              formValues.fromDate.getFullYear(),
              formValues.fromDate.getMonth(),
              formValues.fromDate.getDate(),
              0,
              0,
              0,
              0
            )
          : null,
        toDate: formValues.toDate
          ? new Date(
              formValues.toDate.getFullYear(),
              formValues.toDate.getMonth(),
              formValues.toDate.getDate(),
              23,
              59,
              59,
              59
            )
          : null,
      };

      UsageReport.find(params)
        .then((results) => {
          setResults(results.data as Array<UsageReportResponse>);
          setIsLoadingResults(false);
          setFromDateResult(params.fromDate);
          setToDateResult(params.toDate);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });
    }
  };

  const handleClickCsv = () => {
    setFilename(`${t('menu.usageReport')}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`);
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <>
      <Card elevation={3} data-testid="ninetyDaysSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} xs={10} lg={10} xl={10}>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        selectedOrg !== null && organizations.length > 0
                          ? organizations.find((oneComp) => oneComp.code === selectedOrg.code)
                          : null
                      }
                      id="organization"
                      options={organizations.sort((a: Organization, b: Organization) => sortOrganization(a, b))}
                      getOptionLabel={(option: Organization) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          autoFocus
                          label={t('usageReport.organization')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: organizationRef,
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteOrgChange(value)}
                      disableClearable={organizations && organizations.length === 1}
                      data-testid="organization"
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    {!isIbcOrThirdPartySelected ? (
                      <Autocomplete
                        fullWidth
                        noOptionsText={t('search.noOption')}
                        value={
                          selectedCompany !== null && companies.length > 0
                            ? companies.find((oneComp) => oneComp.code === selectedCompany.code)
                            : null
                        }
                        id="company"
                        options={companies.sort((a: ReportingCompany, b: ReportingCompany) => sortCompanies(a, b))}
                        getOptionLabel={(option: ReportingCompany) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('ninetyReport.rptComp')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingCompanies ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) => handleAutocompleteCompanyChange(value)}
                        loading={isLoadingCompanies}
                        loadingText={t('loading')}
                        disableClearable={companies && companies.length === 1}
                        disabled={!selectedOrg || isIbcOrThirdPartySelected}
                        data-testid="company"
                      />
                    ) : (
                      /* Dummy field for company when IBC or 3rd party */
                      <DashCustomInput
                        fullWidth
                        id="companyHolderIbc3rdPart"
                        value={
                          selectedOrg
                            ? i18n.language.startsWith('en')
                              ? organizations.find((oneOrg) => oneOrg.id === selectedOrg.id)?.nameEn
                              : organizations.find((oneOrg) => oneOrg.id === selectedOrg.id)?.nameFr
                            : ''
                        }
                        label={t('userAdmin.companyProf.rptComp')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingUnits}
                      loadingText={t('loading')}
                      noOptionsText={t('search.noOption')}
                      value={
                        unitId !== null && fetchedUnits ? fetchedUnits.find((oneUnit) => oneUnit.id === unitId) : null
                      }
                      id="unitId"
                      data-testid="unitId"
                      options={fetchedUnits ? fetchedUnits.sort((a, b) => sort(a, b, 'unit')) : []}
                      getOptionLabel={(option: Unit) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('reportHistory.unit')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={
                            fetchedUnits === null || fetchedUnits.length < 1 ? undefined : t('userAdmin.userAdmin.all')
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingCompanies ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteUnitChange(value && value.id ? value : null)}
                      disabled={
                        (!isIbcOrThirdPartySelected && selectedCompany === null) ||
                        (selectedOrg === null && selectedCompany === null) ||
                        fetchedUnits === null ||
                        fetchedUnits.length < 1
                      }
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="userId"
                      value={formValues.userId}
                      label={t('usageReport.userId')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'userId',
                        name: 'userId',
                      }}
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="userName"
                      value={formValues.userName}
                      label={t('usageReport.username')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'username',
                        name: 'userName',
                      }}
                      autoComplete="nope"
                    />
                  </Grid>

                  {/* Datepickers */}
                  <Grid container item md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          // sx={{
                          //   fontWeight: 700,
                          //   textAlign: 'end',
                          // }}
                          sx={{ fontWeight: 700, display: 'inline' }}
                        >
                          {t('usageReport.dateRange')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} paddingRight={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.fromDate}
                            onChange={(newValue) => handleDatePickerChange('fromDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'fromDatePicker',
                                  placeholder: t('datepickerPlaceholderFrom').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                helperText={(errorFromDate && t('reportHistory.invalidDate')) || (errorToDate && ' ')}
                                error={errorFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} paddingLeft={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.toDate}
                            onChange={(newValue) => handleDatePickerChange('toDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'toDatePicker',
                                  placeholder: t('datepickerPlaceholderTo').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                helperText={(errorToDate && t('reportHistory.invalidDate')) || (errorFromDate && ' ')}
                                error={errorToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END DatePicker */}

                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          variant="contained"
                          id="btnSearch"
                          data-testid="btnSearch"
                          // onClick={handleSearch}
                          type="submit"
                        >
                          {t('userAdmin.lookup')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={handleClearFields}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('search.clearFields')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <ExportCsv
                      csvData={results && results.length > 0 && resultsCsv ? resultsCsv : []}
                      csvHeaders={csvHeaders}
                      fileName={fileName}
                      onClick={() => handleClickCsv()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {results && results.length > 0 && (
                <Grid item container xs={12} padding={2}>
                  <Grid item container xs={12}>
                    <Typography fontWeight={700}>{`${results.length} ${
                      results.length === 1 ? t('ninetyReport.oneResultFound') : t('ninetyReport.manyResultsFound')
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <UsageReportResults
                      fetchedResults={results}
                      setFetchedResultsCsv={setResultsCsv}
                      fromDate={fromDateResult}
                      toDate={toDateResult}
                    />
                  </Grid>
                </Grid>
              )}
              {results && results.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('usageReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default UsageReportSearch;
