import { requests } from '../axios';
import { Response } from '../Types';
import { CorrectionReportSearchType } from '../Types/CorrectionReportTypes';
import { PaginationQuery } from '../Types/PaginationQuery';

export const searchCorrectionReport = (
  params: CorrectionReportSearchType,
  paginationQuery?: PaginationQuery
): Promise<Response> => {
  let query = `/CorrectionReport/CorrectionReportSearch`;
  if (paginationQuery) {
    query += `?PageSize=${paginationQuery.pageSize}&PageNumber=${paginationQuery.pageNumber}`;
  }
  return requests.post(query, params);
};
