import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../theme';
import dependencies from './NugetPackagesDependenciesData';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 2),
    },
    title: {
      color: theme.palette.primary.dark,
    },
    table: {
      minWidth: '800',
    },
  })
);

const NugetPackagesDependencies: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h5" className={classes.title}>
          {t('nugetPackagesDependencies.header')}
        </Typography>
        <Table stickyHeader className={classes.table} aria-label="dependency list" data-testid="tblNugetPackageDep">
          <TableHead>
            <TableRow>
              <TableCell>{t('nugetPackagesDependencies.name')}</TableCell>
              <TableCell>{t('nugetPackagesDependencies.version')}</TableCell>
              <TableCell>{t('nugetPackagesDependencies.repository')}</TableCell>
              <TableCell>{t('nugetPackagesDependencies.license')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dependencies.map((dependency) => (
              <TableRow key={dependency.name}>
                <TableCell>{dependency.name}</TableCell>
                <TableCell>{dependency.version}</TableCell>
                <TableCell>{dependency.repository}</TableCell>
                <TableCell>{dependency.license}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default NugetPackagesDependencies;
