import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { subDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { NinetyDaysReportAxios, ReportingCompanies, Units } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportAsyncCsv from '../../Components/ExportAsyncCsv';
import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { DATE_FORMAT, DATE_MASK, PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime, getDateFromNow, isValidDate } from '../../Helpers/DateHelper';
import { sortUnits, sortUserFunctionCompanies } from '../../Helpers/SortHelpers';
import { userFullNameToString } from '../../Helpers/UserHelper';
import useAutofocus from '../../Hooks/UseAutofocus';
import { useDateValidation } from '../../Hooks/UseDateValidation';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { getConnectedUser } from '../../Slices/UserSlice';
import theme from '../../theme';
import {
  DataCorrection,
  Functions,
  NinetyDaysReportStatus,
  ReactLocationState,
  Report90DaysRequest,
  Report90DaysResponse,
  Unit,
  UserFunctionCompany,
} from '../../Types';
import { PaginationQuery } from '../../Types/PaginationQuery';
import { Report90DaysResponseCsvType } from '../../Types/Report90Days';
import Report90DResults from './Report90DResults';

const Report90DSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname, state } = useLocation<ReactLocationState>();
  const savedSearchParams = state && state.ninetyDaysSearchParams;
  const savedPageNo = state && state.ninetyDaysPageNo;

  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const csvLink = useRef<any>(null);

  const [companies, setCompanies] = useState<Array<UserFunctionCompany>>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);

  const [units, setUnits] = useState<Array<Unit>>([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);

  const [pageNo, setPageNo] = useState<number | null>(state && state.ninetyDaysPageNo ? state.ninetyDaysPageNo : null);
  const [resultCount, setResultCount] = useState<number>(
    state && state.ninetyDaysSearchResultsCount ? state.ninetyDaysSearchResultsCount : 0
  );
  const [results, setResults] = useState<Array<Report90DaysResponse> | null>(
    state && state.ninetyDaysSearchResults ? state.ninetyDaysSearchResults : null
  );
  const [resultsCsv, setResultsCsv] = useState<Array<Report90DaysResponseCsvType> | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  // company and unit are outside formValues because it requires a default value after a fetch
  const [selectedCompany, setSelectedCompany] = useState<UserFunctionCompany | null>(
    savedSearchParams && savedSearchParams.company ? savedSearchParams.company : null
  );
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(
    savedSearchParams && savedSearchParams.unitId ? savedSearchParams.unitId : null
  );

  const todayDate = new Date();
  const ninetyDaysReportStatus = NinetyDaysReportStatus.all;

  const [formValues, setFormValues] = useState<Omit<Report90DaysRequest, 'company' | 'unitId'>>({
    reportRequestor: savedSearchParams ? savedSearchParams.reportRequestor || '' : userFullNameToString(user),
    fromDate: savedSearchParams ? savedSearchParams.fromDate : new Date(subDays(todayDate, 90)),
    toDate: savedSearchParams ? savedSearchParams.toDate : todayDate,
    ninetyDaysReportStatus: savedSearchParams ? savedSearchParams.ninetyDaysReportStatus : ninetyDaysReportStatus,
  });

  const [requestParams, setRequestParams] = useState<Report90DaysRequest | null>(null);
  const [fileName, setFilename] = useState<string | undefined>();

  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  // CSV headers for the CSV export
  const csvHeaders = [
    {
      label: t('ninetyReport.results.company'),
      key: 'company',
    },
    {
      label: t('ninetyReport.results.requestor'),
      key: 'reportRequestor',
    },
    {
      label: t('ninetyReport.results.reportDate'),
      key: 'reportDate',
    },
    {
      label: t('ninetyReport.results.changeDate'),
      key: 'changeDate',
    },
    {
      label: t('ninetyReport.results.ref'),
      key: 'referenceNumber',
    },
    {
      label: t('ninetyReport.results.dln'),
      key: 'driverLicenseNumber',
    },
    {
      label: t('ninetyReport.results.changes'),
      key: 'changes',
    },
    {
      label: t('ninetyReport.results.reviewedBy'),
      key: 'reviewedBy',
    },
  ];

  const companyRef = useAutofocus();

  // Search request
  const searchRequestCallback = useCallback(() => {
    setIsLoadingResults(true);

    // Create the parameters and saved them in LocalStorage
    const params: Report90DaysRequest = {
      ...formValues,
      fromDate: formValues.fromDate
        ? new Date(
            formValues.fromDate.getFullYear(),
            formValues.fromDate.getMonth(),
            formValues.fromDate.getDate(),
            0,
            0,
            0,
            0
          )
        : null,
      toDate: formValues.toDate
        ? new Date(
            formValues.toDate.getFullYear(),
            formValues.toDate.getMonth(),
            formValues.toDate.getDate(),
            23,
            59,
            59,
            59
          )
        : null,
      company: selectedCompany,
      unitId: selectedUnitId,
      ninetyDaysReportStatus: formValues.ninetyDaysReportStatus,
    };
    const paginationQuery: PaginationQuery = {
      pageSize: PAGINATION_PAGESIZE,
      pageNumber: (pageNo ?? 0) + 1,
    };

    NinetyDaysReportAxios.find(params, paginationQuery)
      .then((results) => {
        // temporary cast until count/data is removed
        const resultsTyped = results.data as Array<Report90DaysResponse>;
        setResults(resultsTyped);
        setResultCount(results.count);
        setIsLoadingResults(false);
        setRequestParams(params);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  }, [formValues, handleError, selectedCompany, selectedUnitId, pageNo]);

  const fetchCSVData = () => {
    setIsLoadingResults(true);
    const params: Report90DaysRequest = {
      ...formValues,
      fromDate: formValues.fromDate
        ? new Date(
            formValues.fromDate.getFullYear(),
            formValues.fromDate.getMonth(),
            formValues.fromDate.getDate(),
            0,
            0,
            0,
            0
          )
        : null,
      toDate: formValues.toDate
        ? new Date(
            formValues.toDate.getFullYear(),
            formValues.toDate.getMonth(),
            formValues.toDate.getDate(),
            23,
            59,
            59,
            59
          )
        : null,
      company: selectedCompany,
      unitId: selectedUnitId,
      ninetyDaysReportStatus: formValues.ninetyDaysReportStatus,
    };

    NinetyDaysReportAxios.find(params)
      .then((results) => {
        const resultsTyped = results.data as Array<Report90DaysResponse>;
        setResultsCsv(
          resultsTyped.map((oneFetchedResult) => ({
            company: i18n.language.startsWith('en') ? oneFetchedResult.company.nameEn : oneFetchedResult.company.nameFr,
            reportRequestor: oneFetchedResult.reportRequestor,
            reportDate: formatDateToLocalTime(oneFetchedResult.reportDate, true),
            changeDate: formatDateToLocalTime(oneFetchedResult.changeDate, false),
            referenceNumber: oneFetchedResult.referenceNumber,
            driverLicenseNumber: oneFetchedResult.driverLicenseNumber,
            changes: `${oneFetchedResult.changes
              .filter(
                (correction: DataCorrection, idx: number, correctionsList: DataCorrection[]) =>
                  correctionsList.findIndex((t) => t.id === correction.id) === idx
              )
              .map((correction) => (i18n.language.startsWith('en') ? `${correction.nameEn}` : `${correction.nameFr}`))
              .join(';')}`,
            reviewedBy: oneFetchedResult.reviewedBy,
          }))
        );
        setFilename(`${t('menu.90days')}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`);
        setIsLoadingResults(false);
        if (csvLink.current) csvLink.current.link.click(); // call csvLink
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  };

  // Re-fetching results when coming back on the page
  useEffect(() => {
    if (savedSearchParams) {
      setIsLoadingResults(true);

      const paginationQuery: PaginationQuery = {
        pageSize: PAGINATION_PAGESIZE,
        pageNumber: (savedPageNo ?? 0) + 1,
      };

      NinetyDaysReportAxios.find(savedSearchParams, paginationQuery)
        .then((results) => {
          // temporary cast until count/data is removed
          const resultsTyped = results.data as Array<Report90DaysResponse>;
          setResults(resultsTyped);
          setResultCount(results.count);
          setIsLoadingResults(false);
          setRequestParams(savedSearchParams);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });
    }
  }, [dispatch, formValues, handleError, savedSearchParams, savedPageNo, t]);

  // re-fetch results for new page
  useEffect(() => {
    if (pageNo !== null) handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  // Loads the UserFunctionCompanies
  useEffect(() => {
    setIsLoadingCompanies(true);
    ReportingCompanies.getUserFunctionCompanies(Functions.ninetyDaysReport)
      .then((results) => {
        setCompanies(results);
        setIsLoadingCompanies(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingCompanies(false);
      });
  }, [dispatch, handleError, t]);

  // Loads the units
  useEffect(() => {
    if (selectedCompany) {
      setIsLoadingUnits(true);
      Units.getUnitsByCompanyId(
        Functions.ninetyDaysReport,
        selectedCompany.organizationId,
        selectedCompany.reportingCompanyId
      )
        .then((results) => {
          setUnits(results);
          setIsLoadingUnits(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingUnits(false);
        });
    }
  }, [dispatch, handleError, selectedCompany, t]);

  // Initialize selectedCompany if there is only one company available to the user
  useEffect(() => {
    if (companies && companies.length === 1) {
      setSelectedCompany(companies[0]);
    }
  }, [companies]);

  const isFormValid = (): boolean => {
    let error = false;

    if (!isValidDate(formValues.fromDate)) {
      setErrorFromDate(true);
      error = true;
    } else {
      setErrorFromDate(false);
    }

    if (!isValidDate(formValues.toDate)) {
      setErrorToDate(true);
      error = true;
    } else {
      setErrorToDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSearch = () => {
    if (isFormValid()) searchRequestCallback();
  };

  const handleAutocompleteCompanyChange = (value: UserFunctionCompany | null) => {
    setSelectedCompany(value);
    if (!value) setSelectedUnitId(null);
  };

  const handleAutocompleteUnitChange = (value: number | null) => {
    setSelectedUnitId(value);
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  const resetFormValues = () => {
    setFormValues({
      reportRequestor: userFullNameToString(user),
      fromDate: new Date(subDays(todayDate, 90)),
      toDate: todayDate,
      ninetyDaysReportStatus: NinetyDaysReportStatus.all,
    });
    setSelectedCompany(companies.length === 1 ? companies[0] : null);
    setSelectedUnitId(units.length === 1 ? units[0].id : null);
    setResults(null);
    setResultCount(0);

    // clear errors
    setErrorFromDate(false);
    setErrorToDate(false);
  };

  const handleClearFields = () => {
    if (companies.length > 1) setUnits([]);
    resetFormValues();
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const sortUFCompanies = useCallback(
    (a: UserFunctionCompany, b: UserFunctionCompany): number => {
      return sortUserFunctionCompanies(a, b, i18n);
    },
    [i18n]
  );

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pageNo === 0) {
      handleSearch();
    } else {
      setPageNo(0);
    }
  };

  const report90DResultsTable = useRef(null);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPageNo(newPage);
    if (report90DResultsTable.current !== null) {
      window.scrollTo({
        top: report90DResultsTable?.current['offsetTop'] - 108,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <>
      <Card elevation={3} data-testid="ninetyDaysSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} md={10} lg={10} xl={10}>
                  <Grid item xs={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        selectedCompany?.organizationId && companies.length > 0
                          ? companies.find(
                              (oneComp) =>
                                oneComp.organizationId === selectedCompany.organizationId &&
                                oneComp.reportingCompanyId === selectedCompany.reportingCompanyId
                            )
                          : null
                      }
                      id="company"
                      options={companies.sort((a: UserFunctionCompany, b: UserFunctionCompany) =>
                        sortUFCompanies(a, b)
                      )}
                      getOptionLabel={(option: UserFunctionCompany) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('ninetyReport.rptComp')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: companyRef,
                            endAdornment: (
                              <>
                                {isLoadingCompanies ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteCompanyChange(value)}
                      loading={isLoadingCompanies}
                      loadingText={t('loading')}
                      disableClearable={companies && companies.length === 1}
                      data-testid="company"
                    />
                  </Grid>
                  <Grid item xs={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingUnits}
                      loadingText={t('loading')}
                      noOptionsText={t('search.noOption')}
                      value={
                        selectedUnitId !== null && units.length > 0
                          ? units.find((oneUnit) => oneUnit.id === selectedUnitId)
                          : null
                      }
                      id="unitId"
                      data-testid="unitId"
                      options={units.sort((a: Unit, b: Unit) => sortUnits(a, b))}
                      getOptionLabel={(option: Unit) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('ninetyReport.unit')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={units.length < 1 ? undefined : t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingUnits ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteUnitChange(value && value.id ? value.id : null)}
                      disabled={!selectedCompany || units.length < 1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="reportRequestor"
                      value={formValues.reportRequestor}
                      label={t('ninetyReport.requestor')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'reportRequestor',
                        name: 'reportRequestor',
                      }}
                    />
                  </Grid>
                  {/* Datepickers */}
                  <Grid container item xs={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          // sx={{
                          //   fontWeight: 700,
                          //   textAlign: 'end',
                          // }}
                          sx={{ fontWeight: 700, display: 'inline' }}
                        >
                          {t('ninetyReport.changeDate')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} paddingRight={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.fromDate}
                            onChange={(newValue) => handleDatePickerChange('fromDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                // label={t('ninetyReport.changeDate')}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'fromDatePicker',
                                  placeholder: t('datepickerPlaceholderFrom').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                error={errorFromDate}
                                helperText={(errorFromDate && t('reportHistory.invalidDate')) || (errorToDate && ' ')}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} paddingLeft={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.toDate}
                            onChange={(newValue) => handleDatePickerChange('toDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'toDatePicker',
                                  placeholder: t('datepickerPlaceholderTo').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                error={errorToDate}
                                helperText={(errorToDate && t('reportHistory.invalidDate')) || (errorFromDate && ' ')}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END DatePicker */}
                  <Grid item container md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            textAlign: 'end',
                          }}
                        >
                          {t('ninetyReport.status')}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Select
                          fullWidth
                          id="status"
                          size="small"
                          value={formValues.ninetyDaysReportStatus}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              ninetyDaysReportStatus: e.target.value as NinetyDaysReportStatus,
                            })
                          }
                          data-testid="selectStatus"
                          sx={{
                            textAlign: 'left',
                          }}
                        >
                          <MenuItem value={0}>{t('ninetyReport.ninetyDaysReportStatus.all')}</MenuItem>
                          <MenuItem value={1}>{t('ninetyReport.ninetyDaysReportStatus.reviewed')}</MenuItem>
                          <MenuItem value={2}>{t('ninetyReport.ninetyDaysReportStatus.notreviewed')}</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Buttons */}
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    xs={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                      <Grid item container spacing={1} xs={6}>
                        <Grid item xs={5}>
                          <Button
                            fullWidth
                            variant="contained"
                            id="btnSearch"
                            type="submit"
                            data-testid="btnSearch"
                            disabled={isLoadingCompanies || isLoadingUnits || isLoadingResults}
                          >
                            {t('userAdmin.lookup')}
                          </Button>
                        </Grid>
                        <Grid item xs={5}>
                          <Button
                            fullWidth
                            onClick={handleClearFields}
                            variant="contained"
                            color="secondary"
                            data-testid="clearFields"
                          >
                            {t('search.clearFields')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End Buttons */}
                </Grid>

                {/* Export Button */}
                <Grid item container md={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <ExportAsyncCsv
                      csvLink={csvLink}
                      hasSearchResult={resultCount > 0}
                      csvData={resultsCsv ? resultsCsv : []}
                      csvHeaders={csvHeaders}
                      fileName={fileName}
                      onClick={() => fetchCSVData()}
                    />
                  </Grid>
                </Grid>
                {/* End Export Button */}
              </Grid>

              {results && results.length > 0 && (
                <Grid item container xs={12} padding={2} ref={report90DResultsTable}>
                  <Grid item container xs={12}>
                    <Report90DResults
                      requestParams={requestParams}
                      fetchedResults={results}
                      handleChangePage={handleChangePage}
                      count={resultCount}
                      pageNo={pageNo ?? 0}
                    />
                  </Grid>
                </Grid>
              )}
              {results && results.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('ninetyReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default Report90DSearch;
