import { requests } from '../axios';
import { TermsOfService } from '../Types';

export const updateTermsOfService = (TermsOfService: TermsOfService): Promise<TermsOfService> => {
  return requests.put('/TermsOfService', TermsOfService);
};

export const getTermsOfService = (): Promise<TermsOfService> => {
  return requests.get('/TermsOfService', {});
};
