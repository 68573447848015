import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../../../theme';
import { VehiclePolicy } from '../../../../Types';
import VehicleReportPolicyItem from './VehicleReportPolicyItem';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      height: '32pt',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
      '@media print': {
        color: 'black',
        borderTop: '1px solid black',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: '16pt',
      [theme.breakpoints.down('md')]: {
        fontSize: '12pt',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '14pt',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16pt',
      },
    },
    noneContainer: {
      color: theme.palette.primary.dark,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      backgroundColor: theme.palette.white.main,
    },
    label: {
      display: 'inline',
      marginRight: '5px',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
    value: {
      display: 'inline',
      fontWeight: 'bold',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
  })
);

interface VehicleReportPoliciesProps {
  policies: Array<VehiclePolicy>;
}

const VehicleReportPolicies: FC<VehicleReportPoliciesProps> = ({ policies }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid container item data-testid="policiesHeader" alignItems="center" xs={12} className={classes.header}>
        <Grid item container xs={7} paddingLeft={1}>
          <Typography data-testid="header" className={classes.title}>
            {t('vehicleReport.report.policies.policies')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ backgroundColor: theme.palette.secondary.light }}>
        {policies.length > 0 &&
          policies.map((onePol, index) => {
            return (
              <Grid key={index} item container xs={12} marginBottom={index === policies.length - 1 ? 0 : 0.5}>
                <VehicleReportPolicyItem policy={onePol} />
              </Grid>
            );
          })}
        {policies.length === 0 && (
          <Grid item container xs={12} className={classes.noneContainer}>
            <Typography sx={{ fontWeight: 700 }}>{t('report.claim.none')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default VehicleReportPolicies;
