import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { UserAccessReport } from '../../axios';
import ContainerTitle from '../../Components/ContainerTitle';
// import ContainerGraphicTitle from '../../Components/ContainerGraphicTitle';
import Loader from '../../Components/Loader';
import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import { sortAppFunctionsString } from '../../Helpers/SortHelpers';
import doUserHaveAccessToFeature from '../../Helpers/UserHelper';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../Hooks/UseIbcOrThirdPartySelected';
// import AccessEN from '../../Medias/titles/Access - EN.jpg';
// import AccessFR from '../../Medias/titles/Access - FR.jpg';
import { GetAllAppFunctions } from '../../Slices/FunctionSlice';
import { getConnectedUser } from '../../Slices/UserSlice';
import theme from '../../theme';
import { Functions, ReactLocationState, UserAccessDetails, UserStatus } from '../../Types';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    '@media print': {
      maxWidth: '100%',
      padding: 0,
      margin: 0,
      boxShadow: 'none',
    },
  },
  cardContainer: {
    '@media print': {
      maxWidth: '100%',
      padding: 0,
      margin: 0,
      boxShadow: 'none',
    },
  },
  buttonTop: {
    '@media print': {
      display: 'none',
    },
  },
  infoText: {
    '@media print': {
      color: 'black',
    },
  },
  tableHeadCell: {
    '@media print': {
      color: 'black',
    },
  },
  tableCell: {
    whiteSpace: 'nowrap',
    '@media print': {
      color: 'black',
    },
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@media print': {
      color: 'black',
    },
    color: theme.palette.primary.dark,
  },
}));

interface HeadCell {
  id: string;
  label: string;
  width: number;
}

const UserAccessReportDetails: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const { t, i18n } = useTranslation();
  const { state } = useLocation<ReactLocationState>();
  const savedRequest = (state && state.userAccessSearchRequest) ?? null;
  const selectedUserAccessDetails = (state && state.userAccessDetailsSelected) ?? null;

  const appFunctions = useSelector((state: { appFunction: any }) => GetAllAppFunctions(state));
  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [fetchedDetails, setFetchedDetails] = useState<Array<UserAccessDetails> | null>(null);

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(selectedUserAccessDetails?.organization?.id ?? null);

  const userAccReportAccess = doUserHaveAccessToFeature(user, Functions.userAccessReport);

  const headCells: readonly HeadCell[] = [
    {
      id: 'changeDate',
      label: t('userAccessReport.details.changeDate'),
      width: 20,
    },
    {
      id: 'changeAdmin',
      label: t('userAccessReport.details.changeAdmin'),
      width: 35,
    },
    {
      id: 'changeDescription',
      label: t('userAccessReport.details.changeDescription'),
      width: 45,
    },
  ];

  // useEffect(() => {
  //   document.title =
  //     selectedUserAccessDetails && savedRequest?.asOfDate
  //       ? `${selectedUserAccessDetails.userName} - ${formatDateToLocalTime(savedRequest.asOfDate, false)}`
  //       : 'User Access details not loaded';
  // });

  useEffect(() => {
    setIsLoading(true);

    const id = selectedUserAccessDetails?.id;
    if (id) {
      UserAccessReport.getUserAccessReportDetails(id.toString())
        .then((result) => {
          setFetchedDetails(result);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoading(false);
        });
    }
  }, [dispatch, handleError, selectedUserAccessDetails, t]);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      document.getElementById('btnToPdf')?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const DisplayField = ({
    i18nNameString,
    value,
    isDate = false,
    isStatus = false,
  }: {
    i18nNameString: string;
    value: Date | UserStatus | string | null | undefined;
    isDate?: boolean;
    isStatus?: boolean;
  }): JSX.Element => {
    return (
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
        className={classes.infoText}
      >
        <Grid item marginRight={2}>
          <Typography sx={{ textAlign: 'left' }} noWrap>
            {t(`userAccessReport.details.${i18nNameString}`, {
              date: formatDateToLocalTime(savedRequest!.asOfDate!, false),
            })}
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: 'left' }}>
          <Typography sx={{ fontWeight: 700 }}>
            {isDate && value
              ? formatDateToLocalTime(value as Date, false)
              : isStatus
              ? t('userAdmin.userAdmin.status', {
                  context: value as UserStatus,
                })
              : value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const sideButtons = (
    <Grid item container xs={12} spacing={1} className={classes.buttonTop} justifyContent="flex-end">
      <Grid item xs={3}>
        <Button fullWidth id="btnToPdf" variant="contained" color="secondary" onClick={() => window.print()}>
          <Typography>{t('report.toPdf')}</Typography>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button fullWidth variant="contained" color="secondary" onClick={() => history.push(state.from, state)}>
          <Typography>{t('report.goBack')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    user && (
      <Container className={classes.root}>
        {userAccReportAccess && savedRequest && selectedUserAccessDetails && (
          <>
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.accessReport')} button={sideButtons} />
                {/* <ContainerGraphicTitle
                  titleImage={i18n.language.startsWith('en') ? AccessEN : AccessFR}
                  button={sideButtons}
                /> */}
              </Grid>
            </Grid>
            <Grid container paddingBottom={13}>
              <Grid item xs={12}>
                <Card elevation={3} data-testid="UserAccessReportSearchCard" className={classes.cardContainer}>
                  <CardContent>
                    <Grid container>
                      {/* Search */}
                      <Grid item container spacing={2} padding={2} xs={12}>
                        {!isLoading && (
                          <Grid item container xs={12} rowSpacing={1}>
                            <DisplayField i18nNameString="userId" value={selectedUserAccessDetails?.userId} />
                            <DisplayField i18nNameString="name" value={selectedUserAccessDetails?.userName} />
                            <DisplayField
                              i18nNameString="organization"
                              value={
                                i18n.language.startsWith('en')
                                  ? selectedUserAccessDetails?.organization?.nameEn
                                  : selectedUserAccessDetails?.organization?.nameFr
                              }
                            />
                            <DisplayField
                              i18nNameString="company"
                              value={
                                !isIbcOrThirdPartySelected
                                  ? i18n.language.startsWith('en')
                                    ? selectedUserAccessDetails?.reportingCompany?.nameEn
                                    : selectedUserAccessDetails?.reportingCompany?.nameFr
                                  : i18n.language.startsWith('en')
                                  ? selectedUserAccessDetails?.organization?.nameEn
                                  : selectedUserAccessDetails?.organization?.nameFr
                              }
                            />
                            <DisplayField
                              i18nNameString="status"
                              value={selectedUserAccessDetails?.userStatus}
                              isStatus={true}
                            />
                            <DisplayField
                              i18nNameString="created"
                              value={
                                selectedUserAccessDetails?.createdDate
                                  ? formatDateToLocalTime(selectedUserAccessDetails.createdDate, true)
                                  : ''
                              }
                            />
                            <DisplayField
                              i18nNameString="lastLogin"
                              value={
                                selectedUserAccessDetails?.lastLoginDate
                                  ? formatDateToLocalTime(selectedUserAccessDetails.lastLoginDate, true)
                                  : ''
                              }
                            />
                            <DisplayField
                              i18nNameString="functions"
                              value={
                                i18n.language.startsWith('en')
                                  ? selectedUserAccessDetails?.functionsEn
                                      .sort((a: string, b: string) => sortAppFunctionsString(a, b, appFunctions, i18n))
                                      .join('; ')
                                  : selectedUserAccessDetails?.functionsFr
                                      .sort((a: string, b: string) => sortAppFunctionsString(a, b, appFunctions, i18n))
                                      .join('; ')
                              }
                            />
                          </Grid>
                        )}
                        <Grid item container xs={12}>
                          {fetchedDetails && fetchedDetails.length > 0 && (
                            <TableContainer component={'div'} data-testid="tableContainer">
                              <Table
                                size="small"
                                stickyHeader
                                aria-label="table-history-result"
                                sx={{ tableLayout: 'fixed', width: '100%' }}
                              >
                                <TableHead>
                                  <TableRow>
                                    {headCells.map((headCell) => (
                                      <TableCell
                                        key={headCell.id}
                                        sx={{ width: `${headCell.width}%` }}
                                        data-testid={`headerCell-${headCell.id}`}
                                        className={classes.tableHeadCell}
                                      >
                                        {headCell.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody data-testid="tableBody">
                                  {fetchedDetails
                                    .map((oneFetchedResult) => ({
                                      changeDate: formatDateToLocalTime(oneFetchedResult.changeDate, false),
                                      changeAdmin: oneFetchedResult.changeAdministrator || '',
                                      changeDescription: i18n.language.startsWith('en')
                                        ? oneFetchedResult.changeDescriptionEn
                                          ? oneFetchedResult.changeDescriptionEn
                                          : ''
                                        : oneFetchedResult.changeDescriptionFr
                                        ? oneFetchedResult.changeDescriptionFr
                                        : '',
                                    }))
                                    .map((row, index) => (
                                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          className={classes.tableCell}
                                          sx={{ width: '20%' }}
                                          data-testid={`tableCell-changeDate-${index}`}
                                        >
                                          <Tooltip enterTouchDelay={0} title={row.changeDate}>
                                            <Typography className={classes.textCell}>{row.changeDate}</Typography>
                                          </Tooltip>
                                        </TableCell>
                                        <TableCell
                                          className={classes.tableCell}
                                          sx={{ width: '35%' }}
                                          data-testid={`tableCell-changeAdmin-${index}`}
                                        >
                                          <Tooltip enterTouchDelay={0} title={row.changeAdmin}>
                                            <Typography className={classes.textCell}>{row.changeAdmin}</Typography>
                                          </Tooltip>
                                        </TableCell>
                                        <TableCell
                                          className={classes.tableCell}
                                          sx={{ width: '45%' }}
                                          data-testid={`tableCell-changeDescription-${index}`}
                                        >
                                          <Tooltip title={row.changeDescription} enterTouchDelay={0}>
                                            <Typography className={classes.textCell}>
                                              {row.changeDescription}
                                            </Typography>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Loader open={isLoading || !savedRequest || !selectedUserAccessDetails} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    )
  );
};
export default UserAccessReportDetails;
