import { FormEvent, useCallback, useState } from 'react';

import { subDays } from 'date-fns';

import { SdcReportAxios } from '../../../axios';
import { isValidDate } from '../../../Helpers/DateHelper';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { FileStatus, Organization, ReportingCompany, SdcReport, SdcReportRequest } from '../../../Types';
import { FileType } from '../../../Types/SdcReport';

const useSdcReportForm = () => {
  const handleError = useErrorHandler();
  const todayDate = new Date();

  const initialFormValue: SdcReportRequest = {
    organizationId: null,
    reportingCompanyId: null,
    fileTypeCode: null,
    fileName: null,
    fileStatusCode: null,
    startDate: new Date(subDays(todayDate, 30)),
    endDate: todayDate,
  };

  const [formValues, setFormValues] = useState<SdcReportRequest>(initialFormValue);

  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  const [results, setResults] = useState<Array<SdcReport> | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const setOrganizationValue = useCallback((oranizationId: number) => {
    setFormValues((currentValues) => {
      return { ...currentValues, organizationId: oranizationId };
    });
  }, []);

  const setCompanyValue = useCallback((reportingCompanyId: number) => {
    setFormValues((currentValues) => {
      return { ...currentValues, reportingCompanyId: reportingCompanyId };
    });
  }, []);

  const isFormValid = (): boolean => {
    let error = false;

    if (!formValues.startDate || !isValidDate(formValues.startDate)) {
      setErrorFromDate(true);
      error = true;
    } else {
      setErrorFromDate(false);
    }

    if (!formValues.endDate || !isValidDate(formValues.endDate)) {
      setErrorToDate(true);
      error = true;
    } else {
      setErrorToDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid()) {
      setIsLoadingResults(true);
      const searchParams: SdcReportRequest = {
        ...formValues,
        startDate: new Date(
          formValues.startDate.getFullYear(),
          formValues.startDate.getMonth(),
          formValues.startDate.getDate(),
          0,
          0,
          0,
          0
        ),
        endDate: new Date(
          formValues.endDate.getFullYear(),
          formValues.endDate.getMonth(),
          formValues.endDate.getDate(),
          23,
          59,
          59,
          59
        ),
      };
      SdcReportAxios.getSdcReports(searchParams)
        .then((results) => {
          setResults(results);
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => setIsLoadingResults(false));
    }
  };

  const resetFormValues = () => {
    setFormValues(initialFormValue);
  };

  const handleClearFields = () => {
    resetFormValues();
    setErrorFromDate(false);
    setErrorToDate(false);
    setResults(null);
  };

  const handleAutocompleteOrgChange = (value: Organization | null) => {
    setFormValues((currentValues) => {
      return { ...currentValues, organizationId: value ? value.id : null, reportingCompanyId: null };
    });
  };

  const handleAutocompleteCompanyChange = (value: ReportingCompany | null) => {
    setFormValues((currentValues) => {
      return { ...currentValues, reportingCompanyId: value ? value.id : null };
    });
  };

  const handleAutocompleteStatusChange = (value: FileStatus | null) => {
    setFormValues((currentValues) => {
      return { ...currentValues, fileStatusCode: value ? value.code : null };
    });
  };

  const handleAutocompleteTypeChange = (value: FileType | null) => {
    setFormValues((currentValues) => {
      return { ...currentValues, fileTypeCode: value ? value.code : null };
    });
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  return {
    formValues,
    errorFromDate,
    errorToDate,
    results,
    isLoadingResults,
    setOrganizationValue,
    setCompanyValue,
    handleFormSubmit,
    handleClearFields,
    handleAutocompleteOrgChange,
    handleAutocompleteCompanyChange,
    handleAutocompleteTypeChange,
    handleAutocompleteStatusChange,
    handleInputChange,
    handleDatePickerChange,
  };
};

export default useSdcReportForm;
