import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { Jurisdictions } from '../axios';
import { Jurisdiction, JurisdictionFactory } from '../Types';

const language = i18next.language;
// Adapter
const jurisdictionAdapter = createEntityAdapter<Jurisdiction>({
  // Assume IDs are stored in a field id
  selectId: (province) => province.id,
  // Keep the "all IDs" array sorted based on jurisdictionCode
  sortComparer: (a, b) => {
    if (language && language.startsWith('en')) {
      if (a.jurisdictionDescriptionEn < b.jurisdictionDescriptionEn) {
        return -1;
      }
      if (a.jurisdictionDescriptionEn > b.jurisdictionDescriptionEn) {
        return 1;
      }

      if (a.jurisdictionCode < b.jurisdictionCode) {
        return -1;
      }
      if (a.jurisdictionCode > b.jurisdictionCode) {
        return 1;
      }

      return 0;
    } else {
      if (a.jurisdictionDescriptionFr > b.jurisdictionDescriptionFr) {
        return 1;
      }
      if (a.jurisdictionDescriptionFr < b.jurisdictionDescriptionFr) {
        return -1;
      }
      if (a.jurisdictionCode < b.jurisdictionCode) {
        return -1;
      }
      if (a.jurisdictionCode > b.jurisdictionCode) {
        return 1;
      }
      return 0;
    }
  },
});

// Selectors
// Get default selects that comes with entityAdapter
const jurisdictionSelectors = jurisdictionAdapter.getSelectors((state: { province: any }) => state.province);

// map selectors if you want to have them called differently
export const { selectId: getProvinceById } = jurisdictionAdapter;

export const geJurisdictionByJurisdictionCode = createSelector(
  [
    // First input selector: all reports
    jurisdictionSelectors.selectAll,
    // Second input selector: province value
    (state: any, jurisdictionCode: string) => jurisdictionCode,
  ],
  // Output selector: receives both values
  (provinces: Array<Jurisdiction>, jurisdictionCode: string) => {
    // Return provinces based on dln and province
    return provinces.filter((province) => {
      return province.jurisdictionCode === jurisdictionCode;
    });
  }
);

export const GetAllJurisdictions = createSelector(
  [jurisdictionSelectors.selectAll],
  (province: Array<Jurisdiction>) => {
    return province;
  }
);

//Test helper
export const CreateJurisdictionTestState = (isPending: boolean, errorCode = '', errorMessage = '') => {
  const error: SerializedError | null = errorMessage !== '' ? { code: errorCode, message: errorMessage } : null;
  const provinces = [
    JurisdictionFactory(1, 'Alberta', 'Alberta', 'AB'),
    JurisdictionFactory(2, 'British Columbia', 'Colombie-Britannique', 'BC'),
    JurisdictionFactory(3, 'Manitoba', 'Manitoba', 'MB'),
    JurisdictionFactory(4, 'New Brunswick', 'Nouveau-Brunswick', ''),
    JurisdictionFactory(5, 'Newfoundland and Labrador', 'Terre-Neuve-et-Labrador', 'NL'),
    JurisdictionFactory(6, 'Northwest territories', 'Territoires du Nord-Ouest', 'NT'),
    JurisdictionFactory(7, 'Nova Scotia', 'Nouvelle-Écosse', 'NS'),
    JurisdictionFactory(8, 'Nunavut', 'Nunavut', 'NU'),
    JurisdictionFactory(9, 'Ontario', 'Ontario', 'ON'),
    JurisdictionFactory(10, 'Prince Eduard Island', 'Île du Prince-Édouard', 'PE'),
    JurisdictionFactory(11, 'Quebec', 'Québec', 'QC'),
    JurisdictionFactory(13, 'Yukon', 'Territoire du Yukon', 'YT'),
  ];
  const jurisdictionState = jurisdictionAdapter.getInitialState({ isPending, error });
  provinces.forEach((province) => {
    jurisdictionState.ids.push(province.id);
    jurisdictionState.entities[province.id] = province;
  });

  return { province: jurisdictionState };
};

// extra reducers - async thunk
export const fetchJurisdictions = createAsyncThunk('/jurisdictions', async () => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    // testing - return default state
    return [JurisdictionFactory(1, 'Alberta', 'Alberta', 'AB')];
  } else {
    const result = await Jurisdictions.list();
    if (result) {
      return result;
    } else {
      return [];
    }
  }
});

// Slice
const initialState: { isPending: boolean; error: SerializedError | null } = { isPending: false, error: null };

const jurisdictionSlice = createSlice({
  name: 'provinces',
  initialState: jurisdictionAdapter.getInitialState(initialState),
  reducers: {
    removeError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJurisdictions.pending, (state) => {
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(fetchJurisdictions.fulfilled, (state, { payload: provinces }) => {
      state.isPending = false;
      if (provinces) {
        jurisdictionAdapter.removeAll(state);
        jurisdictionAdapter.addMany(state, provinces);
      }
    });
    builder.addCase(fetchJurisdictions.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { removeError } = jurisdictionSlice.actions;

export default jurisdictionSlice.reducer;
