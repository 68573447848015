import UserFunctionCompany from './UserFunctionCompany';

interface UserFunction {
  userId: number;
  functionId: number;
  reportingCompanies: Array<UserFunctionCompany>;
}

export const UserFunctionFactory = (props: UserFunction): UserFunction => {
  return {
    userId: props.userId,
    functionId: props.functionId,
    reportingCompanies: props.reportingCompanies,
  };
};

export default UserFunction;
