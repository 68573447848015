import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import ReportHistory from '../Features/ReportsHistory/ReportHistory';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import HistoryEN from '../Medias/titles/History - EN.jpg';
// import HistoryFR from '../Medias/titles/History - FR.jpg';
import { Functions, User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface ReportHistoryPageProps extends RouteComponentProps {
  user: User;
}

const ReportHistoryPage: FC<ReportHistoryPageProps> = ({ user }) => {
  const classes = useStyles();
  // const { i18n } = useTranslation();
  const { t } = useTranslation();

  const userAccessReportHistory = doUserHaveAccessToFeature(user, Functions.reportHistory);

  return (
    user && (
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContainerTitle title={t('menu.reportHistory')} />
            {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? HistoryEN : HistoryFR} /> */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {userAccessReportHistory && <ReportHistory />}
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default withRouter(ReportHistoryPage);
