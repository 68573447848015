import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { POLICY_STATUS } from '../../Helpers/Constants';
import * as DateHelper from '../../Helpers/DateHelper';
import theme from '../../theme';
import { Policy } from '../../Types';
import EditDialogPolicy from './DataCorrection/Dialogs/EditDialogPolicy';
import EditButton from './DataCorrection/EditButton';
import OperatorsList from './OperatorsList';
import VehiclesList from './VehiclesList';

interface PolicyDetailProps {
  policy?: Policy;
  reportId: string;
  operatorKey: number;
  isCorrectionMode: boolean;
  isUserAllowedToEditPolicy: () => boolean;
}

const useStyles = makeStyles({
  container: {
    background: theme.palette.white.main,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  colonneLeft: {
    paddingRight: theme.spacing(2),
  },
  colonneRight: {
    paddingLeft: theme.spacing(3),
  },
  label: {
    display: 'inline',
    color: theme.palette.primary.dark,
  },
  value: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '5px',
    padding: '5px',
    color: theme.palette.primary.dark,
  },
  info: {
    display: 'inline',
    color: theme.palette.secondary.main,
  },
  operatorVehicleContainer: {
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
  },
  typoNoOpeVehicle: {
    fontWeight: 700,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
  },
});

const PolicyDetail: FC<PolicyDetailProps> = ({
  policy,
  reportId,
  operatorKey,
  isCorrectionMode,
  isUserAllowedToEditPolicy,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dateEditDialogOpen, setDateEditDialogOpen] = useState<boolean>(false);
  const isAllowedToEditCancellationDate = useMemo(
    () =>
      policy?.status.code === POLICY_STATUS.CANCELLEDATINSUREDSREQUEST ||
      policy?.status.code === POLICY_STATUS.CANCELLEDBASEDONUNDERWRITING ||
      policy?.status.code === POLICY_STATUS.CANCELLEDDUETOPOLICYRENEWALNOTTAKEN ||
      policy?.status.code === POLICY_STATUS.CANCELLEDFORANYOTHERREASON ||
      policy?.status.code === POLICY_STATUS.CANCELLEDFORMATERIALMISREPRESENTATION ||
      policy?.status.code === POLICY_STATUS.CANCELLEDFORNONPAYMENT,
    [policy?.status.code]
  );

  return (
    <>
      {policy && (
        <Grid container>
          <Grid item container xs={12} className={classes.container}>
            <Grid item container xs={6} className={classes.colonneLeft}>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.policyNumber')}:</Typography>
                <Typography data-testid="policyNumber" className={classes.value}>
                  {policy.policyNumber ? `${policy.policyNumber}` : t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.policyholderName')}:</Typography>
                <Typography data-testid="policyholderName" className={classes.value}>
                  {policy.policyholderFullName || t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.policyholderAddress')}:</Typography>
                <Typography data-testid="policyholderAddress" className={classes.value}>
                  {policy.policyholderFullAddress || t('report.common.notAvailable')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.numberOfOperators')}:</Typography>
                <Typography data-testid="numberofoperators" className={classes.value}>
                  {policy.numberOfOperators}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.numberOfVehicles')}:</Typography>
                <Typography data-testid="numberofvehicles" className={classes.value}>
                  {policy.numberOfVehicles}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6} className={classes.colonneRight}>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.effectiveDate')}:</Typography>
                <Typography data-testid="effectiveDate" className={classes.value}>
                  {DateHelper.formatDateToLocalTime(policy.effectiveDate, false)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.expiryDate')}:</Typography>
                <Typography data-testid="expiryDate" className={classes.value}>
                  {DateHelper.formatDateToLocalTime(policy.expiryDate, false)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.label}>{t('report.policy.cancellationDate')}:</Typography>
                <Typography data-testid="cancellationDate" className={classes.value}>
                  {policy.isCancelled
                    ? policy.cancellationDate
                      ? DateHelper.formatDateToLocalTime(policy.cancellationDate, false)
                      : policy.isCancelled && t('report.common.notAvailable')
                    : t('report.common.notApplicable')}
                </Typography>
                {isCorrectionMode && isUserAllowedToEditPolicy() && isAllowedToEditCancellationDate && (
                  <EditButton
                    open={isCorrectionMode}
                    onClick={() => setDateEditDialogOpen(true)}
                    dataTestId="cancellationDateEditButton"
                  />
                )}
              </Grid>
              {/* false lines - empty space */}
              <Box height="100%" />
              <Box height="100%" />
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.operatorVehicleContainer}>
            {policy.operators.length !== 0 ? (
              <OperatorsList operators={policy.operators} vehicles={policy.vehicles} policyStatus={policy.status} />
            ) : (
              <Typography className={classes.typoNoOpeVehicle}>{t('report.policy.operator.noOperator')}</Typography>
            )}
          </Grid>

          <Grid item container xs={12} className={classes.operatorVehicleContainer}>
            {policy.vehicles && policy.vehicles.length !== 0 ? (
              <VehiclesList vehicles={policy.vehicles} showAllVehicleDetail={policy.isActivePolicy} />
            ) : (
              <Typography className={classes.typoNoOpeVehicle}>{t('report.policy.vehicle.noVehicle')}</Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={dateEditDialogOpen}
        fullWidth
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setDateEditDialogOpen(false);
          }
        }}
      >
        <EditDialogPolicy
          title={t('report.policy.cancellationDate')}
          value={
            policy && policy.isCancelled && policy.cancellationDate
              ? new Date(new Date(`${policy.cancellationDate} 00:00:00.000`)).toString()
              : null
          }
          fieldName="cancellationDate"
          policy={policy!}
          reportId={reportId}
          operatorKey={operatorKey}
          onClose={() => setDateEditDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};
export default PolicyDetail;
