import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { DriverReportOptions } from '../../../axios';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { DriverReportOption, Organization, ReportingCompany } from '../../../Types';

export interface AddDriverRptOptionDialogProps {
  selectedOrg: Organization;
  selectedRptCompany: ReportingCompany | null;
  onAction: () => void;
  onCreated: (result: DriverReportOption) => void;
}

interface FormFieldsProps {
  note: string;
  years: number | string;
}

const AddDriverRptOptionDialog: FC<AddDriverRptOptionDialogProps> = ({
  selectedOrg,
  selectedRptCompany,
  onAction,
  onCreated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [open, setOpen] = useState<boolean>(true);
  const [formFields, setFormFields] = useState<FormFieldsProps>({ note: '', years: 25 });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const optionsYearsSelect = () => {
    const years: Array<number> = [];
    for (let i = 6; i <= 25; i++) {
      years.push(i);
    }
    return years.reverse();
  };

  const handleSave = async () => {
    setIsLoading(true);
    setOpen(false);
    const params = {
      note: formFields.note,
      yearsOfData: formFields.years === 'MAX' ? null : (formFields.years as number),
      organizationId: selectedOrg.id,
      reportingCompanyId: selectedRptCompany ? selectedRptCompany.id : undefined,
    };

    await DriverReportOptions.create(params)
      .then((dro) => {
        dispatch(addNotification(200, 'success', t('successMessage.created', { resource: dro.yearsOfData })));
        onCreated(dro);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error, { error409: t('errorMessage.error409Duplicate') });
        setIsLoading(false);
      });

    onAction();
  };

  const handleClose = () => {
    setOpen(false);
    onAction();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="AddDriverRptOptionDialogComponent"
      >
        <Typography variant="h5" sx={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4) }}>
          {t('userAdmin.companyProf.add')} {t('userAdmin.companyProf.dialogTitleDro')}
        </Typography>

        <DialogContent sx={{ padding: theme.spacing(4) }}>
          <Grid container spacing={0.5}>
            <Grid item container xs={12} spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Select
                  fullWidth
                  size="small"
                  margin="none"
                  id="years-select"
                  value={formFields.years}
                  onChange={(e) => setFormFields({ ...formFields, years: e.target.value })}
                  data-testid="select"
                >
                  <MenuItem value={'MAX'}>{t('userAdmin.companyProf.maxYears')}</MenuItem>
                  {optionsYearsSelect().map((oneYear, index) => (
                    <MenuItem key={index} value={oneYear}>
                      {`${oneYear} ${t('userAdmin.companyProf.years')}`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  size="small"
                  margin="none"
                  id="name"
                  value={formFields.note}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  placeholder={t('userAdmin.companyProf.addNotePlaceholder')}
                  autoComplete="nope"
                  sx={{ paddingRight: '0' }}
                  onChange={(e) => setFormFields({ ...formFields, note: e.target.value })}
                  inputProps={{ 'data-testid': 'textfield', maxLength: 100 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
            <Grid item xs={3}>
              <Button
                fullWidth
                data-testid="cancel"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth data-testid="accept" variant="contained" color="primary" onClick={() => handleSave()}>
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading} />
    </>
  );
};

export default AddDriverRptOptionDialog;
