import { UserTypeEnum } from './Enums';
import UserDriverReportOption from './UserDriverReportOption';
import UserFunction from './UserFunction';

export interface User {
  id: number; // if creation, id needs to be 0
  userType: UserTypeEnum;
  userId: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string;
  mfaPhone: string | null;
  sub: string | null;
  termsOfUseAccepted: Date | null;
  isDisabled: boolean;
  isIbcCredentials: boolean;
  isIbc?: boolean;
  organizationId: number;
  reportingCompanyId: number | null;
  unitId: number | null;
  previousLogin: Date | null;
  assignedOrganizationsIds: Array<number>;
  userFunctions: Array<UserFunction>;
  userDriverReportOptions: Array<UserDriverReportOption>;
  isLastTermsOfServiceAccepted?: boolean;
}

export const UserFactory = (
  id: number,
  userType: UserTypeEnum,
  userId: string,
  firstName: string | null,
  middleName: string | null,
  lastName: string | null,
  email: string,
  mfaPhone: string | null,
  sub: string | null,
  termsOfUseAccepted: Date | null,
  isDisabled: boolean,
  isIbcCredentials: boolean,
  isIbc: boolean,
  organizationId: number,
  reportingCompanyId: number | null,
  unitId: number | null,
  previousLogin: Date | null,
  assignedOrganizationsIds: Array<number>,
  userFunctions: Array<UserFunction>,
  userDriverReportOptions: Array<UserDriverReportOption>,
  isLastTermsOfServiceAccepted?: boolean
): User => {
  return {
    id: id,
    userType: userType,
    userId: userId,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    email: email,
    mfaPhone: mfaPhone,
    sub: sub,
    termsOfUseAccepted: termsOfUseAccepted,
    isDisabled: isDisabled,
    isIbcCredentials: isIbcCredentials,
    isIbc: isIbc,
    organizationId: organizationId,
    reportingCompanyId: reportingCompanyId,
    unitId: unitId,
    previousLogin: previousLogin,
    assignedOrganizationsIds: assignedOrganizationsIds,
    userFunctions: userFunctions,
    userDriverReportOptions: userDriverReportOptions,
    isLastTermsOfServiceAccepted: isLastTermsOfServiceAccepted,
  };
};

export default User;
