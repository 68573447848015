import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

interface LabelKeyObject {
  label: string;
  key: string;
}

type Headers = LabelKeyObject[] | string[];

interface ExportCsvProps {
  csvData: object[];
  csvHeaders?: Headers | undefined;
  enclosingCharacter?: string | undefined;
  separator?: string | undefined;
  fileName?: string | undefined;
  onClick: () => void;
}

const ExportCsv: FC<ExportCsvProps> = ({
  csvData,
  csvHeaders,
  enclosingCharacter = '"',
  separator = ',',
  fileName,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {csvData.length === 0 ? (
        <Button fullWidth variant="contained" color="secondary" data-testid="export" disabled>
          {t('report.common.export')}
        </Button>
      ) : (
        <CSVLink
          style={{ textDecoration: 'none' }}
          data={csvData}
          headers={csvHeaders}
          filename={fileName}
          enclosingCharacter={enclosingCharacter}
          separator={separator}
          target="_self"
        >
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            data-testid="export"
            onClick={() => {
              onClick();
            }}
          >
            {t('report.common.export')}
          </Button>
        </CSVLink>
      )}
    </>
  );
};

export default ExportCsv;
