import { ChangeEvent, Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Autocomplete, Button, Card, CardContent, Grid, Typography } from '@mui/material';

import { DashClients } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportCsv from '../../Components/ExportCsv';
import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { getDateFromNow } from '../../Helpers/DateHelper';
import { compareABstrings } from '../../Helpers/SortHelpers';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { GetAllClientTypes } from '../../Slices/ClientTypeSlice';
import theme from '../../theme';
import { ReactLocationState } from '../../Types';
import {
  Client,
  ClientSearchFormValue,
  ClientSearchRequest,
  ClientType,
  DashClientResult,
} from '../../Types/ClientSponsor';
import { ClientStatusEnum, ClientStatusEnumList, ClientStatusOption } from '../../Types/Enums';
import DashClientResults from './DashClientResults';

interface DashClientSearchProps {
  setClientSearchFormValue: Dispatch<SetStateAction<ClientSearchFormValue | null>>;
}

const DashClientSearch: FC<DashClientSearchProps> = ({ setClientSearchFormValue }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname, state } = useLocation<ReactLocationState>();
  const savedRequest = (state && state.clientSearchRequest) ?? null;

  const clientTypes = useSelector((state: { clientType: any }) => GetAllClientTypes(state));
  const filteredClientTypes = [
    ...clientTypes,
    {
      id: 0,
      nameEn: 'All',
      nameFr: 'Tout',
      canHaveSponsors: true,
      isModifiableOrDeletable: true,
    },
  ]
    .filter((oneType) => oneType.isModifiableOrDeletable)
    .sort((a, b) =>
      i18n.language.startsWith('en') ? compareABstrings(a.nameEn, b.nameEn) : compareABstrings(a.nameFr, b.nameFr)
    );
  const clientStatusList = [{ value: 0, label: 'userAdmin.userAdmin.all' }, ...ClientStatusEnumList];

  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const [fetchedResults, setFetchedResults] = useState<Array<Client> | null>(null);
  const [resultsCsv, setResultsCsv] = useState<Array<DashClientResult> | null>(null);
  const [fileName, setFilename] = useState<string | undefined>();

  const [formValues, setFormValues] = useState<ClientSearchFormValue>(
    savedRequest
      ? {
          ...savedRequest,
        }
      : {
          clientId: null,
          clientName: null,
          clientTypeId: 0,
          status: 0,
        }
  );

  const csvHeaders = [
    {
      key: 'code',
      label: t('dashclients.tableColClientId'),
    },
    {
      key: 'name',
      label: t('dashclients.tableColName'),
    },
    {
      key: 'type',
      label: t('dashclients.tableColType'),
    },
    {
      key: 'status',
      label: t('dashclients.tableColStatus'),
    },
  ];

  const handleSearch = async () => {
    setIsLoadingSearch(true);

    const params: ClientSearchRequest = {
      clientId: formValues.clientId,
      clientName: formValues.clientName,
      clientTypeId: formValues.clientTypeId ? formValues.clientTypeId : null,
      isActive: formValues.status !== 0 ? formValues.status === ClientStatusEnum.active : null,
    };

    DashClients.list(params)
      .then((results) => {
        setFetchedResults(results);
        setClientSearchFormValue(formValues);
        setIsLoadingSearch(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingSearch(false);
      });
  };

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  useEffect(() => {
    if (savedRequest) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRequest]);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch();
  };

  const resetFormValues = () => {
    setFormValues({
      clientId: null,
      clientName: null,
      clientTypeId: 0,
      status: 0,
    });
    setResultsCsv(null);
  };

  const handleClearFields = () => {
    fetchedResults && setFetchedResults(null);
    resetFormValues();
  };

  const handleClickCsv = () => {
    setFilename(`${t('menu.dashclient')}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
    setFormValues({ ...formValues, [fieldName]: e.target.value });
  };

  const handleAutocompleteTypeChange = (value: ClientType | null) => {
    setFormValues((currentValues) => {
      return { ...currentValues, clientTypeId: value ? value.id : null };
    });
  };

  const handleAutocompleteStatusChange = (value: ClientStatusOption | null) => {
    setFormValues((currentValues) => {
      return { ...currentValues, status: value ? value.value : null };
    });
  };

  return (
    <>
      <Card elevation={3} data-testid="DashClientSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              {/* Search */}
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} md={10} lg={10} xl={10}>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      autoFocus
                      id="clientId"
                      value={formValues.clientId ?? ''}
                      label={t('dashclients.tableColClientId')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e, 'clientId')}
                      inputProps={{
                        'data-testid': 'clientId',
                        name: 'clientId',
                      }}
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="clientName"
                      value={formValues.clientName ?? ''}
                      label={t('dashclients.tableColName')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e, 'clientName')}
                      inputProps={{
                        'data-testid': 'clientName',
                        name: 'clientName',
                      }}
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.clientTypeId != null && filteredClientTypes.length > 0
                          ? filteredClientTypes.find((c) => c.id === formValues.clientTypeId)
                          : undefined
                      }
                      id="clientTypeId"
                      options={filteredClientTypes ?? []}
                      getOptionLabel={(option: ClientType) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('dashclients.clientDetails.clientType')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteTypeChange(value)}
                      loadingText={t('loading')}
                      disableClearable
                      data-testid="clientTypeId"
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.status !== null
                          ? clientStatusList.find((c) => c.value === formValues.status)
                          : undefined
                      }
                      id="status"
                      options={clientStatusList}
                      getOptionLabel={(option: ClientStatusOption) => t(option.label)}
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('userAdmin.userAdmin.status')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteStatusChange(value)}
                      disableClearable
                      loadingText={t('loading')}
                      data-testid="status"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button fullWidth variant="contained" id="btnSearch" type="submit" data-testid="btnSearch">
                          {t('userAccessReport.search')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={() => handleClearFields()}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('userAccessReport.reset')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* New User column */}
                <Grid item container md={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <ExportCsv
                      csvData={fetchedResults && fetchedResults.length > 0 && resultsCsv ? resultsCsv : []}
                      csvHeaders={csvHeaders}
                      fileName={fileName}
                      onClick={() => handleClickCsv()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Result */}
              {fetchedResults && fetchedResults.length > 0 && (
                <Grid item container xs={12} padding={2}>
                  <Grid item container xs={12}>
                    <Typography fontWeight={700}>{`${fetchedResults.length} ${
                      fetchedResults.length === 1
                        ? t('ninetyReport.oneResultFound')
                        : t('ninetyReport.manyResultsFound')
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <DashClientResults
                      fetchedResults={fetchedResults}
                      clientSearchFormValue={formValues}
                      handleSearch={handleSearch}
                      setResultsCsv={setResultsCsv}
                    />
                  </Grid>
                </Grid>
              )}
              {fetchedResults && fetchedResults.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('userAccessReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingSearch} />
    </>
  );
};

export default DashClientSearch;
