import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Constants from '../Helpers/Constants';
import { ReactComponent as AppLogo } from '../Medias/IBC-logo.svg';
import theme from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    navBar: {
      padding: theme.spacing(1),
    },
    appLogo: {
      marginTop: theme.spacing(2),
      fill: theme.palette.primary.dark,
      width: 40,
      height: 40,
    },
    title: {
      padding: theme.spacing(0),
      color: theme.palette.primary.dark,
    },
    header: {
      fontSize: '24pt',
      fontWeight: 'bold',
    },
    versionTitle: {
      marginTop: theme.spacing(6),
    },
    version: {
      padding: theme.spacing(0),
      color: theme.palette.text.secondary,
    },
    licenses: {
      marginTop: theme.spacing(6),
    },
    linkButton: {
      textDecoration: 'underline',
      textTransform: 'none',
      color: theme.palette.text.secondary,
    },
    chipButton: {
      marginLeft: theme.spacing(1),
      fontSize: 16,
    },
    chipIcon: {
      marginLeft: theme.spacing(1),
    },
  })
);

const About: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const handleClickFromLink = (route: string) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'route': '" + route + "' }");
    } else {
      history.push(route);
    }
  };

  return (
    <Container>
      <Grid container spacing={0} justifyContent="center">
        <Grid lg={12} xl={12} md={12} justifyContent="flex-start">
          <Box className={classes.root}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography variant="h5" className={classes.header}>
                {t('about.title')}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <AppLogo className={classes.appLogo} />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography variant="h5" className={classes.title}>
                {t('about.appTitle')}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography variant="body2" className={classes.title}>
                {t('about.appSubtitle')}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography variant="h6" className={classes.versionTitle}>
                {t('about.version')}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography variant="body2" className={classes.version}>
                {Constants.appVersion}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography variant="h6" className={classes.licenses}>
                {t('about.licenses')}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Button
                className={classes.linkButton}
                size="small"
                variant="text"
                onClick={() => handleClickFromLink('/NugetPackagesDependencies')}
                data-testid="nugetPackagesDependencies"
              >
                {t('about.nugetPackagesDependencies')}
              </Button>
              <Button
                className={classes.linkButton}
                size="small"
                variant="text"
                onClick={() => handleClickFromLink('/NpmPackagesDependencies')}
                data-testid="npmPackagesDependencies"
              >
                {t('about.npmPackagesDependencies')}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
