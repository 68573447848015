import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, GridSize, IconButton, OutlinedTextFieldProps, TextField, Tooltip, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    info: {
      display: 'inline',
      color: theme.palette.secondary.main,
    },
  })
);

interface DashCustomInputProps extends OutlinedTextFieldProps {
  labelGridSize?: GridSize;
  fieldGridSize?: GridSize;
  labelColor?: string;
  isRequired?: boolean;
  helpOutlineIconInfo?: string;
}

const DashCustomInput = ({
  labelGridSize,
  fieldGridSize,
  labelColor,
  isRequired,
  helpOutlineIconInfo,
  ...props
}: DashCustomInputProps) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" wrap="nowrap">
      {props.label && (
        <Grid item xs={labelGridSize ? labelGridSize : 4} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
          <Typography
            color={props.disabled ? theme.palette.text.disabled : labelColor}
            sx={{ fontWeight: 700, display: 'inline' }}
          >
            {props.label}
          </Typography>
          {isRequired && !props.disabled && (
            <Typography color={theme.palette.primary.main} sx={{ fontWeight: 700, display: 'inline' }}>
              &nbsp;*
            </Typography>
          )}
          {helpOutlineIconInfo && (
            <Typography sx={{ fontWeight: 700, display: 'inline', verticalAlign: 'middle' }}>
              <Tooltip placement="top" title={helpOutlineIconInfo} arrow>
                <IconButton size="small" sx={{ padding: '0 3px 3px 3px' }}>
                  <InfoOutlinedIcon fontSize="small" className={classes.info} />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={fieldGridSize ? fieldGridSize : 8}>
        <TextField {...props} label={undefined} size="small" margin="none" sx={{ ...props.sx, paddingRight: 0 }} />
      </Grid>
    </Grid>
  );
};

export default DashCustomInput;
