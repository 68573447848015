import { requests } from '../axios';
import { JurisdictionFactory, ReportHistoryRequest, ReportingCompanyFactory, Response, UnitFactory } from '../Types';
import { PaginationQuery } from '../Types/PaginationQuery';

/*
  Driver Report
*/

export const findById = (functionId: number, id: string): Promise<Response> => {
  return requests.get(`/DriverReports/GetById/${functionId}/${id}`, {});
};

export const searchReports = (params: object): Promise<Response> => {
  //return requests.get('/DriverReports/Search', params);
  return requests.post('/DriverReports/Search', params);
};

export const generateReport = (params: object): Promise<Response> => {
  return requests.post(
    `/DriverReports/GenerateReport?ianaTimezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    params
  );
};

export const regenerateReport = (functionId: string, ninetyDaysDataCorrectionId?: number): Promise<Response> => {
  let query = `/DriverReports/RegenerateReport/${functionId}/?ianaTimezone=${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
  if (ninetyDaysDataCorrectionId) {
    query += `&ninetyDaysDataCorrectionId=${ninetyDaysDataCorrectionId}`;
  }
  return requests.post(query, {});
};

/*
  Report History
*/

export const searchHistory = (params: ReportHistoryRequest, paginationQuery?: PaginationQuery): Promise<Response> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Response>((resolve) => {
      resolve({
        count: 1,
        data: [
          {
            reportId: '12345',
            reportDate: new Date(),
            referenceNumber: 'Ref123',
            reportRequestor: 'Linus Sebastian',
            unit: UnitFactory(1, 'unitFR', 'unitEN', 1, 1, false),
            company: ReportingCompanyFactory(1, 1, 'codeRpt1023', 'rptFR', 'rptEN'),
            driverLicenseNumber: 'AB1235',
            licenseJurisdiction: JurisdictionFactory(1, 'Alberta', 'Alberta', 'AB'),
            driverName: 'Hans Zimmer',
          },
        ],
      });
    });
  } else {
    let query = '/DriverReports/HistorySearch';
    if (paginationQuery) {
      query += `?PageSize=${paginationQuery.pageSize}&PageNumber=${paginationQuery.pageNumber}`;
    }
    return requests.get(query, params);
  }
};

export const driverNamesSearch = (request: string): Promise<string[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<string[]>((resolve) => {
      resolve(['john doe', 'jane doe']);
    });
  } else {
    return requests.get(`/DriverReports/DriverNameSearch/?request=${request}`, {});
  }
};

/*
  Driver Report PDF
*/
export const generatePdf = (functionId: number, params: object, lng: string): Promise<Response> => {
  return requests.post(`/DriverReports/GenerateReportPdf/${functionId}/?lng=${lng}`, params);
};
