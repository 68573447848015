import axios, { AxiosResponse, CancelToken } from 'axios';

import { list } from './Axios/AppFunctionsAxios';
import { addB2C, deleteB2C, findB2C, listB2C, updateB2C } from './Axios/B2CProviders';
import {
  createClientSetting,
  deleteClientSetting,
  listClientSetting,
  restoreClientSetting,
  upateClientSetting,
} from './Axios/ClientSettingAxios';
import {
  getByOrganizationCompanies,
  getByOrganizationsCompanies,
  getByOrganizationWithoutUserFunction,
  getCompanies,
  GetCompaniesByUserFunction,
  getUserFunctionCompanies,
  getUserFunctionCompaniesByOrganizations,
  listCompanies,
} from './Axios/CompaniesAxios';
import { getContactUs, updateContactUs } from './Axios/ContactUsAxios';
import {
  createClient,
  createSponsor,
  deleteClient,
  deleteSponsor,
  getClientDetails,
  getClientTypes,
  getSponsor,
  getSponsors,
  listDashClients,
  updateClient,
} from './Axios/DashClientAxios';
import {
  getDriverTrainings,
  getGenders,
  getMaritalStatuses,
  getOperatorsClaims,
  getPolicyStatuses,
  getVehicleLosses,
  getVehiclesClaims,
  updateClaim,
  updateDriverInfo,
  updatePolicy,
} from './Axios/DataCorrection';
import { getDisclaimer, updateDisclaimer } from './Axios/DisclaimerAxios';
import {
  createDRO,
  disableDRO,
  getDriverReportOptionsByCompanyId,
  listByUserAccessDRO,
  listDRO,
} from './Axios/DriverReportOptionsAxios';
import { getFaq, updateFaq } from './Axios/FaqAxios';
import { getIbcContent, getLocalIbcContent, updateIbcContent } from './Axios/IbcContentAxios';
import { find, getUnreadCount } from './Axios/NinetyDaysReportAxios';
import {
  createNro,
  disableNro,
  getNotificationRecipientOptionsByCompanyId,
  listNro,
  updateNro,
} from './Axios/NotificationRecipientOptionsAxios';
import { createOC, getOC, updateOC } from './Axios/OrganizationCompanyOptionsAxios';
import { getOrganizationsAxios, listOrganizations } from './Axios/OrganizationsAxios';
import {
  createPermittedDomain,
  disablePermittedDomain,
  getPermittedDomainsByCompanyId,
  listPermittedDomains,
  updatePermittedDomain,
} from './Axios/PermittedDomainsAxios';
import { getPrivacyPolicy, updatePrivacyPolicy } from './Axios/PrivacyPolicyAxios';
import {
  driverNamesSearch,
  findById,
  generatePdf,
  generateReport,
  regenerateReport,
  searchHistory,
  searchReports,
} from './Axios/ReportsAxios';
import { getSdcErrorReport, getSdcFileStatuses, getSdcFileTypes, getSdcReports } from './Axios/SdcReportAxios';
import { getSystemMessage, updateSystemMessage } from './Axios/SystemMessageAxios';
import { getTermsOfService, updateTermsOfService } from './Axios/TermsOfServiceAxios';
import { createUnits, disableUnits, getUnitsByCompanyId, listUnits, updateUnits } from './Axios/UnitsAxios';
import { findUsageReports } from './Axios/UsageReportAxios';
import { findUserAccessReport, getUserAccessReportDetails } from './Axios/UserAccessReportAxios';
import { findUserProfile } from './Axios/UserProfileAxios';
import {
  changeMyPassword,
  createUsers,
  deleteUserAccount,
  findByIdUsers,
  findByOrganizationUsers,
  findUsers,
  listUsers,
  markTermsOfUseAccepted,
  requestorNameSearch,
  sendInvitationUsers,
  updateUser,
  usersProfiles,
} from './Axios/UsersAxios';
import { generate } from './Axios/VehicleReportAxios';
import Constants from './Helpers/Constants';
import { getAccessToken } from './Helpers/SessionStorageService';
import {
  AppFunction,
  B2CProvider,
  BlobDto,
  ClaimCorrectionType,
  Client,
  ClientSetting,
  DriverReportOption,
  FileStatus,
  Jurisdiction,
  OperatorCorrectionType,
  Organization,
  PolicyCorrectionType,
  ReferenceType,
  Report,
  Report90DaysRequest,
  ReportHistoryRequest,
  ReportingCompany,
  RequestorNameSearch,
  Response,
  SystemMessage,
  Unit,
  UsageReportRequest,
  User,
  UserAccessDetails,
  UserAccessReportRequest,
  UserAccessReportResponse,
  UserCopyProfile,
  UserFunctionCompany,
  UserProfileSearchRequest,
  UserProfileSearchResult,
  VehicleReport,
} from './Types';
import { ChangeMyPassword } from './Types/ChangeMyPassword';
import { ClientSearchRequest, ClientType, Sponsor } from './Types/ClientSponsor';
import NotificationRecipientOption from './Types/NotificationRecipientOption';
import OrganizationCompany from './Types/OrganizationCompany';
import { PaginationQuery } from './Types/PaginationQuery';
import PermittedDomainOption from './Types/PermittedDomain';
import { Report90DaysUnreadCountResponse } from './Types/Report90Days';
import { FileType, SdcErrorReport, SdcReport, SdcReportRequest } from './Types/SdcReport';
import TermsOfService from './Types/TermsOfService';

export const instance = axios.create({
  baseURL: Constants.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
  };
  return config;
});

const responseBody = (response: AxiosResponse) => response.data;

const transform = (response: AxiosResponse) => new Promise<AxiosResponse>((resolve) => resolve(response));

export const requests = {
  get: (url: string, body: object, CancelToken?: CancelToken) =>
    instance.get(url, { params: body, cancelToken: CancelToken }).then(transform).then(responseBody),
  post: (url: string, body: object) => instance.post(url, body).then(transform).then(responseBody),
  put: (url: string, body: object) => instance.put(url, body).then(transform).then(responseBody),
  delete: (url: string) => instance.delete(url).then(transform).then(responseBody),
  patch: (url: string, body: object) => instance.patch(url, body).then(transform).then(responseBody),
};

/*
  Axios methods
*/

/*
  AppFunctions
*/
export const AppFunctions = {
  list: (): Promise<AppFunction[]> => list(),
};

/*
  B2CProviders
*/
export const B2CProviders = {
  list: (): Promise<B2CProvider[]> => listB2C(),

  add: (provider: { emailDomainName: string; b2cDomainName: string }): Promise<B2CProvider> => addB2C(provider),

  update: (
    id: number,
    provider: {
      emailDomainName: string;
      b2cDomainName: string;
    }
  ): Promise<B2CProvider> => updateB2C(id, provider),

  delete: (id: number): Promise<B2CProvider> => deleteB2C(id),

  find: (username: string): Promise<B2CProvider> => findB2C(username),
};

/*
  ClientSetting
*/
export const ClientSettingAxios = {
  list: async (): Promise<Array<ClientSetting>> => listClientSetting(),

  create: async (params: {
    clientId: string;
    organizationId: number;
    reportingCompanyId: number | null;
    userId: number | null;
  }): Promise<ClientSetting> => createClientSetting(params),

  update: async (
    id: number,
    params: {
      clientId: string;
      organizationId: number;
      reportingCompanyId: number | null;
      userId: number | null;
    }
  ): Promise<ClientSetting> => upateClientSetting(id, params),

  delete: (id: number) => deleteClientSetting(id),

  restore: (id: number) => restoreClientSetting(id),
};

/*
  Contact Us
*/
export const ContactUs = {
  getContactUs: (lang: string): Promise<BlobDto> => getContactUs(lang),
  updateContactUs: (lang: string, blob: BlobDto): Promise<BlobDto> => updateContactUs(lang, blob),
};

/*
  Dash Clients
*/
export const DashClients = {
  list: (params: ClientSearchRequest): Promise<Array<Client>> => listDashClients(params),

  /**
   * Get the details of a client
   * @param id Id of the Client
   * @param clientType Client type of the client that uses the Enum
   * @returns A client
   */
  getClientDetails: (id: string, clientType: string): Promise<Client> => getClientDetails(id, clientType),

  getClientTypes: (): Promise<Array<ClientType>> => getClientTypes(),

  getSponsor: (id: number): Promise<Sponsor> => getSponsor(id),

  getSponsors: (thirdPartyId: number): Promise<Sponsor[]> => getSponsors(thirdPartyId),

  createClient: (client: Client): Promise<Client> => createClient(client),

  createSponsor: (sponsor: Sponsor): Promise<Sponsor> => createSponsor(sponsor),

  updateClient: (client: Client): Promise<Client> => updateClient(client),

  deleteClient: async (id: number): Promise<Client> => deleteClient(id),

  deleteSponsor: async (id: number): Promise<Sponsor> => deleteSponsor(id),
};

/*
  Data Correction
*/
export const DataCorrection = {
  getPolicyStatuses: (): Promise<Array<ReferenceType>> => getPolicyStatuses(),

  getGenders: (): Promise<Array<ReferenceType>> => getGenders(),

  getMaritalStatuses: (): Promise<Array<ReferenceType>> => getMaritalStatuses(),

  getVehicleLosses: (): Promise<Array<ReferenceType>> => getVehicleLosses(),

  getDriverTrainings: (): Promise<Array<ReferenceType>> => getDriverTrainings(),

  getOperatorsClaims: (claimId: number): Promise<Array<ReferenceType>> => getOperatorsClaims(claimId),

  getVehiclesClaims: (claimId: number): Promise<Array<ReferenceType>> => getVehiclesClaims(claimId),

  updateDriverInfo: (driverCorrection: OperatorCorrectionType): Promise<Report> => updateDriverInfo(driverCorrection),

  updatePolicy: (policyCorrection: PolicyCorrectionType): Promise<Report> => updatePolicy(policyCorrection),

  updateClaim: (claimCorrection: ClaimCorrectionType): Promise<Report> => updateClaim(claimCorrection),
};

/*
  Disclaimers
*/
export const Disclaimers = {
  getDisclaimer: (lang: string): Promise<BlobDto> => getDisclaimer(lang),
  updateDisclaimer: (lang: string, blob: BlobDto): Promise<BlobDto> => updateDisclaimer(lang, blob),
};

/*
  DRO
*/
export const DriverReportOptions = {
  create: (params: {
    note: string;
    yearsOfData: number | null;
    organizationId: number;
    reportingCompanyId?: number;
  }): Promise<DriverReportOption> => createDRO(params),

  disable: (id: number): Promise<DriverReportOption> => disableDRO(id),

  getDriverReportOptionsByCompanyId: (
    id: number,
    params?: { reportingCompanyId: number }
  ): Promise<DriverReportOption[]> => getDriverReportOptionsByCompanyId(id, params),

  list: (userId?: number | null): Promise<DriverReportOption[]> => listDRO(userId),

  listByUserAccess: async (): Promise<DriverReportOption[]> => listByUserAccessDRO(),
};

/*
  FAQs
*/
export const FAQs = {
  getFaq: (lang: string): Promise<BlobDto> => getFaq(lang),
  updateFaq: (lang: string, blob: BlobDto): Promise<BlobDto> => updateFaq(lang, blob),
};

/*
  IBC Content
*/
export const IbcContents = {
  getIbcContent: (lang: string): Promise<BlobDto> => getIbcContent(lang),
  getLocalIbcContent: (lang: string): Promise<string> => getLocalIbcContent(lang),
  updateIbcContent: (lang: string, blob: BlobDto): Promise<BlobDto> => updateIbcContent(lang, blob),
};

/*
  Organization Company
*/
export const OrganizationCompanyOptions = {
  get: (params: { organizationId: number; reportingCompanyId?: number }): Promise<OrganizationCompany> => getOC(params),

  update: (
    id: number,
    params: { isIbcCredentials: boolean; organizationId: number; reportingCompanyId?: number }
  ): Promise<OrganizationCompany> => updateOC(id, params),

  create: (
    id: number,
    params: { isIbcCredentials: boolean; organizationId: number; reportingCompanyId?: number }
  ): Promise<OrganizationCompany> => createOC(id, params),
};

/*
  Notification Recipients
*/
export const NotificationRecipientOptions = {
  create: (params: {
    email: string;
    organizationId: number;
    reportingCompanyId?: number;
  }): Promise<NotificationRecipientOption> => createNro(params),

  disable: (id: number): Promise<NotificationRecipientOption> => disableNro(id),

  getNotificationRecipientOptionsByCompanyId: (
    id: number,
    params?: {
      organizationId: number;
      reportingCompanyId?: number;
    }
  ): Promise<NotificationRecipientOption[]> => getNotificationRecipientOptionsByCompanyId(id, params),

  list: async (functionId: number): Promise<NotificationRecipientOption[]> => listNro(functionId),

  update: (
    id: number,
    params: { email: string; organizationId: number; reportingCompanyId?: number }
  ): Promise<NotificationRecipientOption> => updateNro(id, params),
};

/*
  90 Days Report
*/
export const NinetyDaysReportAxios = {
  find: (params: Report90DaysRequest, paginationQuery?: PaginationQuery): Promise<Response> =>
    find(params, paginationQuery),

  getUnreadCount: (CancelToken?: CancelToken): Promise<Report90DaysUnreadCountResponse> => getUnreadCount(CancelToken),
};

/*
  Organizations
*/
export const Organizations = {
  list: async (): Promise<Organization[]> => listOrganizations(),

  get: (functionId: number): Promise<Organization[]> => getOrganizationsAxios(functionId),
};

/*
  Provinces
*/
export const Jurisdictions = {
  list: (): Promise<Jurisdiction[]> => requests.get('/jurisdictions', {}),
};

/*
  Companies
*/
export const ReportingCompanies = {
  get: async (functionId: number): Promise<ReportingCompany[]> => getCompanies(functionId),

  getByOrganizationWithoutUserFunction: async (organizationId: number): Promise<ReportingCompany[]> =>
    getByOrganizationWithoutUserFunction(organizationId),

  getByOrganization: async (functionId: number, organizationId: number): Promise<ReportingCompany[]> =>
    getByOrganizationCompanies(functionId, organizationId),

  getByOrganizations: async (functionId: number, organizationsIds: Array<number>): Promise<ReportingCompany[]> =>
    getByOrganizationsCompanies(functionId, organizationsIds),

  getUserFunctionCompanies: async (functionId: number): Promise<UserFunctionCompany[]> =>
    getUserFunctionCompanies(functionId),

  GetCompaniesByUserFunction: async (functionId: number): Promise<ReportingCompany[]> =>
    GetCompaniesByUserFunction(functionId),

  getUserFunctionCompaniesByOrganizations: async (
    functionId: number,
    organizationsIds: Array<number>
  ): Promise<UserFunctionCompany[]> => getUserFunctionCompaniesByOrganizations(functionId, organizationsIds),

  list: async (): Promise<ReportingCompany[]> => listCompanies(),
};

/*
  Reports
*/
export const Reports = {
  findById: (functionId: number, id: string): Promise<Response> => findById(functionId, id),

  search: (params: object): Promise<Response> => searchReports(params),

  generate: (params: object): Promise<Response> => generateReport(params),

  regenerate: (functionId: string, ninetyDaysDataCorrectionId?: number): Promise<Response> =>
    regenerateReport(functionId, ninetyDaysDataCorrectionId),
};

/*
  ReportHistory
*/
export const ReportHistory = {
  search: (params: ReportHistoryRequest, paginationQuery?: PaginationQuery): Promise<Response> =>
    searchHistory(params, paginationQuery),
  driverNamesSearch: (request: string): Promise<string[]> => driverNamesSearch(request),
};

/*
 ReportPdf
*/
export const ReportPdf = {
  generatePdf: (functionId: number, params: object, lng: string) => generatePdf(functionId, params, lng),
};

/*
  Units
*/
export const Units = {
  create: (params: { name: string; organizationId: number; reportingCompanyId?: number }): Promise<Unit> =>
    createUnits(params),

  disable: (id: number): Promise<Unit> => disableUnits(id),

  getUnitsByCompanyId: (
    functionId: number,
    organizationId: number,
    reportingCompanyId: number | null
  ): Promise<Unit[]> => getUnitsByCompanyId(functionId, organizationId, reportingCompanyId),

  list: (functionId: number): Promise<Unit[]> => listUnits(functionId),

  update: (id: number, params: { name: string; organizationId: number; reportingCompanyId?: number }): Promise<Unit> =>
    updateUnits(id, params),
};

/*
  User Access Report
*/
export const UserAccessReport = {
  findUsageReports: (params: UserAccessReportRequest): Promise<Array<UserAccessReportResponse>> =>
    findUserAccessReport(params),

  getUserAccessReportDetails: (userId: string): Promise<Array<UserAccessDetails>> => getUserAccessReportDetails(userId),
};

/*
  PermittedDomain
*/
export const PermittedDomains = {
  create: (params: {
    domain: string;
    organizationId: number;
    reportingCompanyId?: number;
  }): Promise<PermittedDomainOption> => createPermittedDomain(params),

  disable: (id: number): Promise<PermittedDomainOption> => disablePermittedDomain(id),

  getPermittedDomainByCompanyId: (
    functionId: number,
    organizationId: number,
    reportingCompanyId: number | null
  ): Promise<PermittedDomainOption[]> => getPermittedDomainsByCompanyId(functionId, organizationId, reportingCompanyId),

  list: (functionId: number): Promise<PermittedDomainOption[]> => listPermittedDomains(functionId),

  update: (
    id: number,
    params: { domain: string; organizationId: number; reportingCompanyId?: number }
  ): Promise<PermittedDomainOption> => updatePermittedDomain(id, params),
};

/*
  Privacy Policy
*/
export const PrivacyPolicies = {
  getPrivacyPolicy: (lang: string): Promise<BlobDto> => getPrivacyPolicy(lang),
  updatePrivacyPolicy: (lang: string, blob: BlobDto): Promise<BlobDto> => updatePrivacyPolicy(lang, blob),
};

/*
  SystemMessage
*/
export const SystemMessages = {
  getSystemMessage: (): Promise<SystemMessage> => getSystemMessage(),
  updateSystemMessage: (systemMessage: SystemMessage): Promise<SystemMessage> => updateSystemMessage(systemMessage),
};

/*
  TermsOfService
*/
export const TermsOfServices = {
  getTermsOfService: (): Promise<TermsOfService> => getTermsOfService(),
  updateTermsOfService: (termsOfService: TermsOfService): Promise<TermsOfService> =>
    updateTermsOfService(termsOfService),
};

/*
  Usage Report
*/

export const UsageReport = {
  find: (params: UsageReportRequest): Promise<Response> => findUsageReports(params),
};

/*
  Users
*/
export const Users = {
  create: (params: User): Promise<User> => createUsers(params),

  find: (): Promise<User> => findUsers(),

  findById: (id: number): Promise<User> => findByIdUsers(id),

  findByOrganization: (organizationId: number, reportingCompanyId: number | null): Promise<Array<User>> =>
    findByOrganizationUsers(organizationId, reportingCompanyId),

  requestorNameSearch: (request: RequestorNameSearch): Promise<string[]> => requestorNameSearch(request),

  list: (): Promise<User[]> => listUsers(),

  markTermsOfUseAccepted: (): Promise<User> => markTermsOfUseAccepted(),

  usersProfiles: (workEmail: string, CancelToken?: CancelToken): Promise<Array<UserCopyProfile>> =>
    usersProfiles(workEmail, CancelToken),

  updateUser: (params: User): Promise<User> => updateUser(params),

  sendInvitation: (email: string): Promise<Response> => sendInvitationUsers(email),

  deleteUserAccount: (id: number, hardDelete: boolean | null): Promise<User> => deleteUserAccount(id, hardDelete),

  changePassword: (params: ChangeMyPassword): Promise<Response> => changeMyPassword(params),
};

/*
  UserProfile
*/
export const UserProfile = {
  find: (params: UserProfileSearchRequest): Promise<Array<UserProfileSearchResult>> => findUserProfile(params),
};

/*
  Vehicle Report
*/
export const VehicleReportAxios = {
  generate: async (vin: string): Promise<VehicleReport> => generate(vin),
};

/*
  Vehicle Report
*/
export const SdcReportAxios = {
  getSdcFileStatuses: async (): Promise<FileStatus[]> => getSdcFileStatuses(),
  getSdcFileTypes: async (): Promise<FileType[]> => getSdcFileTypes(),
  getSdcReports: async (params: SdcReportRequest): Promise<SdcReport[]> => getSdcReports(params),
  getSdcErrorReport: async (fileId: number): Promise<SdcErrorReport> => getSdcErrorReport(fileId),
};
