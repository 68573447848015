import { FC, MutableRefObject } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

interface LabelKeyObject {
  label: string;
  key: string;
}

type Headers = LabelKeyObject[] | string[];

interface ExportCsvProps {
  csvLink: MutableRefObject<any>;
  hasSearchResult: boolean;
  csvData: object[];
  csvHeaders?: Headers | undefined;
  enclosingCharacter?: string | undefined;
  separator?: string | undefined;
  fileName?: string | undefined;
  onClick: () => void;
}

const ExportAsyncCsv: FC<ExportCsvProps> = ({
  csvLink,
  hasSearchResult,
  csvData,
  csvHeaders,
  enclosingCharacter = '"',
  separator = ',',
  fileName,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!hasSearchResult ? (
        <Button fullWidth variant="contained" color="secondary" data-testid="export" disabled>
          {t('report.common.export')}
        </Button>
      ) : (
        <>
          <Button
            fullWidth
            onClick={() => {
              onClick();
            }}
            variant="contained"
            color="secondary"
            data-testid="export"
          >
            {t('report.common.export')}
          </Button>
          <CSVLink
            ref={csvLink}
            style={{ textDecoration: 'none' }}
            data={csvData}
            headers={csvHeaders}
            filename={fileName}
            enclosingCharacter={enclosingCharacter}
            separator={separator}
            target="_blank"
          />
        </>
      )}
    </>
  );
};

export default ExportAsyncCsv;
