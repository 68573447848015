import { requests } from '../axios';
import { FileStatus, SdcErrorReport, SdcReport, SdcReportRequest } from '../Types';
import { FileType } from '../Types/SdcReport';

export const getSdcFileStatuses = (): Promise<FileStatus[]> => {
  return requests.get(`/SdcReport/GetAllFileStatus`, {});
};

export const getSdcFileTypes = (): Promise<FileType[]> => {
  return requests.get(`/SdcReport/GetAllFileTypes`, {});
};

export const getSdcReports = (params: SdcReportRequest): Promise<SdcReport[]> => {
  return requests.get(`/SdcReport/GetSdcReports`, params);
};

export const getSdcErrorReport = (fileId: number): Promise<SdcErrorReport> => {
  return requests.get(`/SdcReport/GetSdcErrorReport/${fileId}`, {});
};
