import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Grid } from '@mui/material';

// import { Chip } from '@mui/material';
import JoditEditor from 'jodit-react';

import { TermsOfServices } from '../../../axios';
import { getJoditEditorConfig } from '../../../Helpers/JoditEditorHelper';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import TermsOfService from '../../../Types/TermsOfService';

export interface TermsOfServiceEditorProps {
  placeholder: string;
}

enum TermsTabs {
  TermsEnglish = 'termsEnglish',
  TermsFrench = 'termsFrench',
}

const TermsOfServiceEditor: FC<TermsOfServiceEditorProps> = ({ placeholder }) => {
  const editorEn = useRef(null);
  const editorFr = useRef(null);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const [selectedTab, setSelectedTab] = useState<TermsTabs>(TermsTabs.TermsEnglish);
  const [termsOfServiceEn, setTermsOfServiceEn] = useState<string>('');
  const [termsOfServiceFr, setTermsOfServiceFr] = useState<string>('');

  const config = { ...getJoditEditorConfig(), placeholder };

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      TermsOfServices.getTermsOfService()
        .then((data: TermsOfService) => {
          setTermsOfServiceEn(decodeURIComponent(data.termsOfServiceEnHtml));
          setTermsOfServiceFr(decodeURIComponent(data.termsOfServiceFrHtml));
        })
        .catch((error) => {
          // if there is an error here then it's probably that the TermsOfService hasn't been set yet. don't display error message.
          setTermsOfServiceEn('');
          handleError(error);
        });
    };

    onLoadFromBlobStorage();
  }, [dispatch, handleError, i18n.language, t]);

  const onEditorChangeEn = (newContent: string) => {
    setTermsOfServiceEn(newContent);
  };

  const onEditorChangeFr = (newContent: string) => {
    setTermsOfServiceFr(newContent);
  };

  const onSaveToBlobStorage = () => {
    TermsOfServices.updateTermsOfService({
      id: 0,
      startDate: new Date(),
      termsOfServiceEnHtml: encodeURIComponent(termsOfServiceEn),
      termsOfServiceFrHtml: encodeURIComponent(termsOfServiceFr),
    })
      .then(() => {
        dispatch(addNotification(200, 'success', t('successMessage.savedChanges')));
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 0, paddingLeft: 3 }}>
              <Grid item container xs={3} md={3} lg={2} xl={2} spacing={1} justifyContent={'flex-start'}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setSelectedTab(TermsTabs.TermsEnglish)}
                    variant={'outlined'}
                    color={selectedTab === TermsTabs.TermsEnglish ? 'primary' : 'secondary'}
                    sx={{
                      borderWidth: '1px',
                      boxShadow:
                        selectedTab === TermsTabs.TermsEnglish
                          ? `0px 0px 1px 1px ${theme.palette.primary.main}`
                          : 'none',
                      backgroundColor: theme.palette.white.main,
                    }}
                  >
                    {t('settings.terms-of-service.english')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setSelectedTab(TermsTabs.TermsFrench)}
                    variant={'outlined'}
                    color={selectedTab === TermsTabs.TermsFrench ? 'primary' : 'secondary'}
                    sx={{
                      borderWidth: '1px',
                      boxShadow:
                        selectedTab === TermsTabs.TermsFrench
                          ? `0px 0px 1px 1px ${theme.palette.primary.main}`
                          : 'none',
                      backgroundColor: theme.palette.white.main,
                    }}
                  >
                    {t('settings.terms-of-service.french')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <TabPanel value={TermsTabs.TermsEnglish}>
              <JoditEditor
                ref={editorEn}
                value={termsOfServiceEn}
                config={config}
                onBlur={(newContent) => onEditorChangeEn(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </TabPanel>
            <TabPanel value={TermsTabs.TermsFrench}>
              <JoditEditor
                ref={editorFr}
                value={termsOfServiceFr}
                config={config}
                onBlur={(newContent) => onEditorChangeFr(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </TabPanel>
            <Grid item container xs={3} sx={{ marginTop: theme.spacing(2) }} />
            <Grid item container spacing={1} xs={12} justifyContent="flex-end" direction="row" paddingRight={3}>
              <Grid item xs={2}>
                <Button fullWidth onClick={onSaveToBlobStorage} variant="contained" color="primary">
                  {t('settings.terms-of-service.save')}
                </Button>
              </Grid>
            </Grid>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsOfServiceEditor;
