export interface Jurisdiction {
  id: number;
  jurisdictionDescriptionEn: string;
  jurisdictionDescriptionFr: string;
  jurisdictionCode: string;
}

export const JurisdictionFactory = (
  id: number,
  jurisdictionDescriptionFr: string,
  jurisdictionDescriptionEn: string,
  jurisdictionCode: string
): Jurisdiction => {
  return {
    id,
    jurisdictionDescriptionEn: jurisdictionDescriptionEn,
    jurisdictionDescriptionFr: jurisdictionDescriptionFr,
    jurisdictionCode: jurisdictionCode,
  };
};

export default Jurisdiction;
