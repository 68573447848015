import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import Loader from '../Components/Loader';
import CompanyProfile from '../Features/UserAdministration/CompanyProfile/CompanyProfile';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import CompanyEN from '../Medias/titles/Company - EN.jpg';
// import CompanyFR from '../Medias/titles/Company - FR.jpg';
import theme from '../theme';
import { Functions, User } from '../Types';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface CompanyProfilePageProps extends RouteComponentProps {
  user: User;
}

const CompanyProfilePage: FC<CompanyProfilePageProps> = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const userAccessCompProf = doUserHaveAccessToFeature(user, Functions.companyAdministration);

  return user ? (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContainerTitle title={t('menu.companyAdmin')} />
          {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? CompanyEN : CompanyFR} /> */}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {userAccessCompProf && <CompanyProfile />}
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Loader open={true} />
  );
};

export default withRouter(CompanyProfilePage);
