import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import theme from '../theme';

interface IdleTimerContainerProps {
  timeout: number;
  onClose: (logout: boolean) => void;
}

const IdleTimerContainer: FC<IdleTimerContainerProps> = ({ timeout, onClose }) => {
  const { t } = useTranslation();
  const promptTimeout = 1000 * 60;

  // Modal open state
  const [open, setOpen] = useState<boolean>(false);

  // Time before idle
  const [remaining, setRemaining] = useState<number>(0);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  const onMessage = (data: { action: string | number | object }) => {
    switch (data.action) {
      case 'LOGOUT_USER':
        onClose(true);
        break;
      // More actions
      default:
      // no op
    }
  };

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    message({ action: 'LOGOUT_USER' }, true);
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const handleStillHere = () => {
    setOpen(false);
    reset();
  };

  const { getRemainingTime, isPrompted, reset, message } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    debounce: 500,
    crossTab: true,
    name: 'dash',
    syncTimers: 1000,
    onMessage,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        if (getRemainingTime() === 0) {
          onIdle();
        }
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleLogOutConfirm = () => {
    setOpen(false);
    setRemaining(0);
    message({ action: 'LOGOUT_USER' }, true);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleLogOutConfirm();
        }
      }}
      data-testid="confirmDialogComponent"
    >
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
      >
        {t('timer.timerTitle')}
      </Typography>
      <DialogContent>
        <Box padding={1}>
          <Typography>{t('timer.timerContent', { time: remaining })}</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ margin: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}` }}>
        <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              size="large"
              data-testid="accept"
              variant="contained"
              color="primary"
              ref={enterKeyRef}
              onClick={() => handleStillHere()}
            >
              {t('timer.btnKeepMeSignedIn')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              size="large"
              data-testid="cancel"
              variant="contained"
              color="secondary"
              onClick={() => handleLogOutConfirm()}
            >
              {t('timer.btnLogout')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default IdleTimerContainer;
