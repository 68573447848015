import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button } from '@mui/material';

import { SdcReportAxios } from '../../../axios';
import Loader from '../../../Components/Loader';
import { getDateFromNow } from '../../../Helpers/DateHelper';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import { SdcErrorReportCsv } from '../../../Types';

interface SdcErrorReportDownloadProps {
  index: number;
  fileId: number;
  fileStatus: string;
}

const SdcErrorReportDownload = ({ index, fileId, fileStatus }: SdcErrorReportDownloadProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const csvRef = useRef<any>(null);

  const csvFileName = `${t('sdcReport.sdcErrorReport')}-${fileId}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`;
  const [csvData, setCsvData] = useState<SdcErrorReportCsv | null>(null);
  const [isCsvDataLoading, setIsCsvDataLoading] = useState<boolean>(false);
  const [isClaimError, setIsClaimError] = useState<boolean>(false);

  const csvPolicyHeaders = [
    {
      key: 'transactionType',
      label: t('sdcReport.csv.transactionType'),
    },
    {
      key: 'processingDate',
      label: t('sdcReport.csv.processingDate'),
    },
    {
      key: 'sequenceNumber',
      label: t('sdcReport.csv.sequenceNumber'),
    },
    {
      key: 'companyCode',
      label: t('sdcReport.csv.companyCode'),
    },
    {
      key: 'policyControlID',
      label: t('sdcReport.csv.policyControlID'),
    },
    {
      key: 'policyIdentificationDigit',
      label: t('sdcReport.csv.policyIdentificationDigit'),
    },
    {
      key: 'policyIdentification',
      label: t('sdcReport.csv.policyIdentification'),
    },
    {
      key: 'policyEffectiveDate',
      label: t('sdcReport.csv.policyEffectiveDate'),
    },
    {
      key: 'vin',
      label: t('sdcReport.csv.vin'),
    },
    {
      key: 'existingOperatorDLN',
      label: t('sdcReport.csv.existingOperatorDLN'),
    },
    {
      key: 'existingOperatorDateofBirth',
      label: t('sdcReport.csv.existingOperatorDateofBirth'),
    },
    {
      key: 'existingOperatorRelationshiptoPolicyHolder',
      label: t('sdcReport.csv.existingOperatorRelationshiptoPolicyHolder'),
    },
    {
      key: 'existingperatrDateAddedToThePlicy',
      label: t('sdcReport.csv.existingperatrDateAddedToThePlicy'),
    },
    {
      key: 'existingOperatorDateDeletedfromthePolicy',
      label: t('sdcReport.csv.existingOperatorDateDeletedfromthePolicy'),
    },
    {
      key: 'additionalOperatorType',
      label: t('sdcReport.csv.additionalOperatorType'),
    },
    {
      key: 'additionalOperatorSurname',
      label: t('sdcReport.csv.additionalOperatorSurname'),
    },
    {
      key: 'additionalOperatorFirstName',
      label: t('sdcReport.csv.additionalOperatorFirstName'),
    },
    {
      key: 'additionalOperatorDLN',
      label: t('sdcReport.csv.additionalOperatorDLN'),
    },
    {
      key: 'additionalOperatorDLNJurisdiction',
      label: t('sdcReport.csv.additionalOperatorDLNJurisdiction'),
    },
    {
      key: 'additionalOperatorDateofBirth',
      label: t('sdcReport.csv.additionalOperatorDateofBirth'),
    },
    {
      key: 'additionalOperatorGender',
      label: t('sdcReport.csv.additionalOperatorGender'),
    },
    {
      key: 'additionalOperatorMaritalStatus',
      label: t('sdcReport.csv.additionalOperatorMaritalStatus'),
    },
    {
      key: 'additionalOperatorRelationshiptoPolicyHolder',
      label: t('sdcReport.csv.additionalOperatorRelationshiptoPolicyHolder'),
    },
    {
      key: 'additionalOperatorAlbertaGridLevel',
      label: t('sdcReport.csv.additionalOperatorAlbertaGridLevel'),
    },
    {
      key: 'additionalOperatorAlbertaGridIndicator',
      label: t('sdcReport.csv.additionalOperatorAlbertaGridIndicator'),
    },
    {
      key: 'additionalOperatorDateAddedtothePolicy',
      label: t('sdcReport.csv.additionalOperatorDateAddedtothePolicy'),
    },
    {
      key: 'additionalOperatorDateDeletedfromthePolicy',
      label: t('sdcReport.csv.additionalOperatorDateDeletedfromthePolicy'),
    },
    {
      key: 'errors',
      label: t('sdcReport.csv.errors'),
    },
  ];

  const csvClaimHeaders = [
    {
      key: 'transactionType',
      label: t('sdcReport.csv.transactionType'),
    },
    {
      key: 'processingDate',
      label: t('sdcReport.csv.processingDate'),
    },
    {
      key: 'sequenceNumber',
      label: t('sdcReport.csv.sequenceNumber'),
    },
    {
      key: 'companyCode',
      label: t('sdcReport.csv.companyCode'),
    },
    {
      key: 'policyControlID',
      label: t('sdcReport.csv.policyControlID'),
    },
    {
      key: 'policyIdentificationDigit',
      label: t('sdcReport.csv.policyIdentificationDigit'),
    },
    {
      key: 'policyIdentification',
      label: t('sdcReport.csv.policyIdentification'),
    },
    {
      key: 'policyEffectiveDate',
      label: t('sdcReport.csv.policyEffectiveDate'),
    },
    {
      key: 'existingClaimControlID',
      label: t('sdcReport.csv.existingClaimControlID'),
    },
    {
      key: 'existingClaimIdentificationDigit',
      label: t('sdcReport.csv.existingClaimIdentificationDigit'),
    },
    {
      key: 'existingClaimIdentification',
      label: t('sdcReport.csv.existingClaimIdentification'),
    },
    {
      key: 'existingClaimAccidentDate',
      label: t('sdcReport.csv.existingClaimAccidentDate'),
    },
    {
      key: 'existingClaimStatus',
      label: t('sdcReport.csv.existingClaimStatus'),
    },
    {
      key: 'existingClaimFirstPartyAccidentDriverDateofBirth',
      label: t('sdcReport.csv.existingClaimFirstPartyAccidentDriverDateofBirth'),
    },
    {
      key: 'additionalClaimControlID',
      label: t('sdcReport.csv.additionalClaimControlID'),
    },
    {
      key: 'additionalClaimIdentificationDigit',
      label: t('sdcReport.csv.additionalClaimIdentificationDigit'),
    },
    {
      key: 'additionalClaimIdentification',
      label: t('sdcReport.csv.additionalClaimIdentification'),
    },
    {
      key: 'additionalClaimAccidentDate',
      label: t('sdcReport.csv.additionalClaimAccidentDate'),
    },
    {
      key: 'additionalClaimPolicyHolderSurname',
      label: t('sdcReport.csv.additionalClaimPolicyHolderSurname'),
    },
    {
      key: 'additionalClaimPolicyHolderFirstName',
      label: t('sdcReport.csv.additionalClaimPolicyHolderFirstName'),
    },
    {
      key: 'additionalClaimVehicleCode',
      label: t('sdcReport.csv.additionalClaimVehicleCode'),
    },
    {
      key: 'additionalClaimVehicleModelYear',
      label: t('sdcReport.csv.additionalClaimVehicleModelYear'),
    },
    {
      key: 'additionalClaimFirstPartyVIN',
      label: t('sdcReport.csv.additionalClaimFirstPartyVIN'),
    },
    {
      key: 'additionalClaimCoverageCode',
      label: t('sdcReport.csv.additionalClaimCoverageCode'),
    },
    {
      key: 'additionalClaimFirstPartyDriverSurname',
      label: t('sdcReport.csv.additionalClaimFirstPartyDriverSurname'),
    },
    {
      key: 'additionalClaimFirstPartyDriverFirstName',
      label: t('sdcReport.csv.additionalClaimFirstPartyDriverFirstName'),
    },
    {
      key: 'additionalClaimFirstPartyDriverDLN',
      label: t('sdcReport.csv.additionalClaimFirstPartyDriverDLN'),
    },
    {
      key: 'additionalClaimFirstPartyDLNJurisdiction',
      label: t('sdcReport.csv.additionalClaimFirstPartyDLNJurisdiction'),
    },
    {
      key: 'additionalClaimFirstPartyAccidentDriverExcludedDriver',
      label: t('sdcReport.csv.additionalClaimFirstPartyAccidentDriverExcludedDriver'),
    },
    {
      key: 'additionalClaimFirstPartyAccidentDriverPercentageatFault',
      label: t('sdcReport.csv.additionalClaimFirstPartyAccidentDriverPercentageatFault'),
    },
    {
      key: 'errors',
      label: t('sdcReport.csv.errors'),
    },
  ];

  useEffect(() => {
    if (csvData) {
      if (csvRef.current) {
        csvRef.current.link.click();
        dispatch(addNotification(200, 'success', t('sdcReport.downloadInitiated')));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData]);

  const handleClickDownload = () => {
    setIsCsvDataLoading(true);
    SdcReportAxios.getSdcErrorReport(fileId)
      .then((result) => {
        if (result.claimErrorReport) {
          setIsClaimError(true);
          setCsvData(result.claimErrorReport);
        } else {
          setCsvData(result.policyErrorReport);
        }
      })
      .catch((error) => {
        setCsvData(null);
        handleError(error);
      })
      .finally(() => setIsCsvDataLoading(false));
  };

  return (
    <>
      <Button
        sx={{ fontWeight: 700, padding: 0, minWidth: 0, margin: 0 }}
        size="small"
        variant="text"
        color="primary"
        id={`btnDownloadReport${index}`}
        onClick={handleClickDownload}
        endIcon={<ArrowRightIcon />}
        disabled={fileStatus !== 'Processed' && fileStatus !== 'File Error'}
        data-testid={`btnDowloadReport${index}`}
      >
        {t('sdcReport.download')}
      </Button>
      <CSVLink
        ref={csvRef}
        data={csvData ?? []}
        headers={isClaimError ? csvClaimHeaders : csvPolicyHeaders}
        filename={csvFileName}
        enclosingCharacter='"'
        separator=","
        target="_blank"
        style={{ textDecoration: 'none' }}
      />
      <Loader open={isCsvDataLoading} />
    </>
  );
};

export default SdcErrorReportDownload;
