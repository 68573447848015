import Organization from './Organization';
import ReportingCompany from './ReportingCompany';
import User from './Users';

export interface ClientSetting {
  id: number;
  clientId: string;
  disabled: boolean;
  organization: Organization;
  reportingCompany: ReportingCompany | null;
  user: User | null;
  isVendor: boolean;
}

export interface ClientSettingFormValueProps {
  id?: number;
  clientId: string;
  organizationId: number | null;
  rptCompanyId: number | null;
  userId: number | null;
  isVendor: boolean;
}

export const ClientSettingFactory = (props: ClientSetting): ClientSetting => {
  return {
    id: props.id,
    clientId: props.clientId,
    disabled: props.disabled,
    organization: props.organization,
    reportingCompany: props.reportingCompany,
    user: props.user,
    isVendor: props.isVendor,
  };
};

export default ClientSetting;
