import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createDOMPurify from 'dompurify';

import { ContactUs } from '../axios';
import { BlobDto } from '../Types';

export const GetContactUsContent = (): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [contactUs, setContactUs] = useState('');

  const DOMPurify = createDOMPurify(window);

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      ContactUs.getContactUs(i18n.language.substring(0, 2))
        .then((data: BlobDto) => {
          setContactUs(decodeURIComponent(data.blobHtml));
        })
        .catch(() => {
          // if there is an error here then it's probably that the ContactUs hasn't been set yet. don't display error message.
          setContactUs('');
        });
    };

    onLoadFromBlobStorage();
  }, [i18n.language, t]);

  const sanitizedContactUs = DOMPurify.sanitize(contactUs);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContactUs }} />
    </>
  );
};
