import { useEffect, useState } from 'react';

import { SdcReportAxios } from '../../../axios';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { FileType } from '../../../Types/SdcReport';

const useSdcFileType = () => {
  const handleError = useErrorHandler();

  const [fileTypeList, setFileTypeList] = useState<Array<FileType>>([]);
  const [isLoadingFileType, setIsLoadingFileType] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingFileType(true);
    SdcReportAxios.getSdcFileTypes()
      .then((results) => {
        setFileTypeList(results);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setIsLoadingFileType(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { fileTypeList, isLoadingFileType };
};

export default useSdcFileType;
