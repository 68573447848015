import { useCallback, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, GridSize, OutlinedTextFieldProps, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import theme from '../theme';

interface DashCustomPasswordInputProps extends OutlinedTextFieldProps {
  labelGridSize?: GridSize;
  fieldGridSize?: GridSize;
  labelColor?: string;
  isRequired?: boolean;
}

const DashCustomPasswordInput = ({
  labelGridSize,
  fieldGridSize,
  labelColor,
  isRequired,
  ...props
}: DashCustomPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <Grid container alignItems="center" wrap="nowrap">
      {props.label && (
        <Grid item xs={labelGridSize ? labelGridSize : 4} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
          <Typography
            color={props.disabled ? theme.palette.text.disabled : labelColor}
            sx={{ fontWeight: 700, display: 'inline' }}
          >
            {props.label}
          </Typography>
          {isRequired && !props.disabled && (
            <Typography color={theme.palette.primary.main} sx={{ fontWeight: 700, display: 'inline' }}>
              &nbsp;*
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={fieldGridSize ? fieldGridSize : 8}>
        <TextField
          {...props}
          label={undefined}
          size="small"
          margin="none"
          sx={{ ...props.sx, paddingRight: 0 }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DashCustomPasswordInput;
