import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportingCompanies } from '../../../axios';
import { sortRptCompanies } from '../../../Helpers/SortHelpers';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { Functions, ReportingCompany } from '../../../Types';

const useSdcCompany = (organizationId: number | null, setCompanyValue: (reportingCompanyId: number) => void) => {
  const { i18n } = useTranslation();
  const handleError = useErrorHandler();

  const [companies, setCompanies] = useState<Array<ReportingCompany>>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);

  const sortCompanies = useCallback(
    (a: ReportingCompany, b: ReportingCompany): number => {
      return sortRptCompanies(a, b, i18n);
    },
    [i18n]
  );

  // Loads the UserFunctionCompanies
  useEffect(() => {
    if (organizationId) {
      setIsLoadingCompanies(true);
      ReportingCompanies.getByOrganization(Functions.sdcReport, organizationId)
        .then((results) => {
          setCompanies(results);
          if (results && results.length === 1) {
            setCompanyValue(results[0].id);
          }
          setIsLoadingCompanies(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingCompanies(false);
        });
    } else {
      setIsLoadingCompanies(true);
      ReportingCompanies.GetCompaniesByUserFunction(Functions.sdcReport)
        .then((results) => {
          setCompanies(results);
          if (results && results.length === 1) {
            setCompanyValue(results[0].id);
          }
          setIsLoadingCompanies(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingCompanies(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, setCompanyValue]);

  return { companies, isLoadingCompanies, sortCompanies };
};

export default useSdcCompany;
