import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { Grid } from '@mui/material';

import { utcToZonedTime } from 'date-fns-tz';

import { SystemMessages } from '../axios';
import RedBand from '../Components/RedBand';
import SigninForm from '../Features/Authentication/SigninForm';
import { compareDates } from '../Helpers/DateHelper';
import { GetIbcContent } from '../Helpers/IbcContentHelper';
import useErrorHandler from '../Hooks/UseErrorHandler';
import { setInvitationId } from '../Slices/InvitationIdSlice';
import theme from '../theme';
import { SystemMessage } from '../Types';

const Signin: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const invitationId: string = useParams<{ invitationId: string }>()?.invitationId;

  const [systemMessage, setSystemMessage] = useState<SystemMessage>();
  const [isRedBandActive, setIsRedBandActive] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Setting invitationId in the store if present
  useEffect(() => {
    if (invitationId) {
      dispatch(setInvitationId(invitationId));
    } else {
      dispatch(setInvitationId(''));
    }
  }, [dispatch, invitationId]);

  useEffect(() => {
    if (systemMessage && systemMessage.startDate && systemMessage.endDate) {
      const isInTimeBracket =
        compareDates(new Date(), systemMessage.startDate) === 1 &&
        compareDates(new Date(), systemMessage.endDate) === -1;
      setIsRedBandActive(isInTimeBracket);
    }
  }, [systemMessage]);

  useEffect(() => {
    const hostTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    SystemMessages.getSystemMessage()
      .then((response) => {
        if (response) {
          const localizedResult: SystemMessage = {
            ...response,
            startDate: response.startDate ? utcToZonedTime(response.startDate, hostTimezone) : null,
            endDate: response.endDate ? utcToZonedTime(response.endDate, hostTimezone) : null,
          };
          setSystemMessage(localizedResult);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [handleError]);

  return (
    <Grid container data-testid="signinContainer">
      {/* Red band for showing system messages */}
      {systemMessage && isRedBandActive && <RedBand {...systemMessage} />}

      {/* IBC Space Content */}
      <Grid
        item
        container
        xs={12}
        sx={{
          backgroundColor: `${theme.palette.white.main}`,
        }}
        data-testid="signinContent"
      >
        <Grid item container>
          <Grid
            item
            container
            alignContent="center"
            justifyContent="center"
            sx={{ height: isRedBandActive ? 'calc(100vh - 118px)' : 'calc(100vh - 58px)', width: '100%' }}
          >
            {/* {GetIbcContent()} */}
            <SigninForm />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(Signin);
