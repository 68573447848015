import { useCallback, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import Cookies from 'js-cookie';

import AxiosInterceptor from './Components/AxiosInterceptor';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { CustomNavigationClient } from './Components/NavigationClient';
import Snack from './Components/Snack';
import authService from './Features/Authorization/AuthorizeService';
import { reactPlugin } from './Helpers/AppInsights';
import doUserHaveAccessToFeature from './Helpers/UserHelper';
import history from './History/history';
import Routes from './Routes';
import { GetAllClientTypes, getClientTypes } from './Slices/ClientTypeSlice';
import { GetAllAppFunctions, getAppFunctions } from './Slices/FunctionSlice';
import { fetchJurisdictions, GetAllJurisdictions } from './Slices/JurisdictionSlice';
import { GetAllNotifications, removeNotifications } from './Slices/NotificationSlice';
import { getConnectedUser, GetProfile } from './Slices/UserSlice';
import theme from './theme';
import { Functions } from './Types/Enums';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface appProps {
  pca: PublicClientApplication;
}

const App = ({ pca }: appProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const provinces = useSelector((state: { province: any }) => GetAllJurisdictions(state));
  const appFunctions = useSelector((state: { appFunction: any }) => GetAllAppFunctions(state));
  const clientTypes = useSelector((state: { clientType: any }) => GetAllClientTypes(state));
  const user = useSelector((state: { user: any }) => getConnectedUser(state));
  const notifications = useSelector((state: { notification: any }) => GetAllNotifications(state));

  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  const handleNotificationClose = () => {
    dispatch(removeNotifications());
  };

  const populateAuth = useCallback(async () => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      return;
    }
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const userAuth: { sub: string; name: string; given_name: string; family_name: string } =
        await authService.getUser();

      if (userAuth) {
        let language = Cookies.get('userId-language');
        if (language === undefined) {
          language = i18n.language;
          Cookies.set('userId-language', language);
        }

        if (!user) {
          dispatch(GetProfile());
        }
      } else {
        throw new Error('user is authenticated but userAuth is null');
      }
    }
  }, [dispatch, i18n.language, user]);

  useEffect(() => {
    const subscription = authService.subscribe(() => populateAuth());
    populateAuth();
    // returned function will be called on component unmount
    return () => {
      authService.unsubscribe(subscription);
    };
  }, [populateAuth]);

  useEffect(() => {
    if ((!provinces || provinces.length === 0) && user) {
      dispatch(fetchJurisdictions());
    }
  }, [provinces, dispatch, user]);

  useEffect(() => {
    if ((!appFunctions || appFunctions.length === 0) && user) {
      dispatch(getAppFunctions());
    }
  }, [appFunctions, dispatch, user]);

  useEffect(() => {
    if (
      (!clientTypes || clientTypes.length === 0) &&
      user &&
      doUserHaveAccessToFeature(user, Functions.manageDashClients)
    ) {
      dispatch(getClientTypes());
    }
  }, [clientTypes, dispatch, user]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppInsightsErrorBoundary onError={() => <h1>{t('errorMessage.connectionLost')}</h1>} appInsights={reactPlugin}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <Router history={history}>
              <MsalProvider instance={pca}>
                <AxiosInterceptor>
                  <div
                    style={{
                      backgroundColor: theme.palette.secondary.light,
                      minHeight: '100vh',
                    }}
                  >
                    <CssBaseline />
                    <Header user={user} />
                    {/* {(!user && window.location.href.includes('terms')) || (user && !user.isLastTermsOfServiceAccepted) ? (
                    <Terms user={user} />
                  ) : (
                    <Routes user={user} />
                  )} */}
                    <Routes user={user} />
                    {notifications && notifications.length > 0 && (
                      <Snack message={notifications} onClose={handleNotificationClose} />
                    )}
                    <Footer />
                  </div>
                </AxiosInterceptor>
              </MsalProvider>
            </Router>
          </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>

        <CookieConsent
          location="bottom"
          style={{ background: '#333333', zIndex: 9999, marginBottom: theme.spacing(6) }}
          buttonStyle={{
            background: '#EEEEEE',
            color: '#333333',
            fontSize: '18px',
            borderRadius: 0,
            paddingLeft: '26px',
            paddingRight: '26px',
          }}
          buttonText={t('footer.gotIt')}
        >
          {t('footer.thisWebsiteCookies')}
          {/* <a href="/privacy" data-testid="cookiePrivacy" style={{ color: 'white' }}>
            {t('footer.privacy')}
          </a> */}
        </CookieConsent>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
