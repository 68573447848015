import { UserStatus } from './Enums';
import Organization from './Organization';
import ReportingCompany from './ReportingCompany';

export interface UserAccessReportRequest {
  organizationId: number | null;
  companyId: number | null;
  userId: string | null;
  userName: string | null;
  userStatus: UserStatus;
  functionId: number | null;
  asOfDate: Date | null;
}

export const UserAccessReportRequestFactory = (props: UserAccessReportRequest): UserAccessReportRequest => {
  return {
    organizationId: props.organizationId,
    companyId: props.companyId,
    userId: props.userId,
    userName: props.userName,
    userStatus: props.userStatus,
    functionId: props.functionId,
    asOfDate: props.asOfDate,
  };
};

export interface UserAccessReportResponse {
  id: number;
  userId: string;
  organization: Organization | null;
  reportingCompany: ReportingCompany | null;
  userName: string | null;
  createdDate: Date | null;
  userStatus: UserStatus;
  lastLoginDate: Date | null;
  functionsEn: Array<string>;
  functionsFr: Array<string>;
}

export const UserAccessReportResponseFactory = (props: UserAccessReportResponse): UserAccessReportResponse => {
  return {
    id: props.id,
    userId: props.userId,
    organization: props.organization,
    reportingCompany: props.reportingCompany,
    userName: props.userName,
    createdDate: props.createdDate,
    userStatus: props.userStatus,
    lastLoginDate: props.lastLoginDate,
    functionsEn: props.functionsEn,
    functionsFr: props.functionsFr,
  };
};

export interface UserAccessReportResponseCsv {
  id: number;
  userId: string;
  organization: string | null;
  reportingCompany: string | null;
  userName: string | null;
  createdDate: string | null;
  userStatus: string;
  lastLoginDate: string | null;
  functions: string;
}

export interface UserAccessDetails {
  changeDate: Date;
  changeAdministrator: string | null;
  changeDescriptionEn: string | null;
  changeDescriptionFr: string | null;
}

export const UserAccessDetailsFactory = (props: UserAccessDetails): UserAccessDetails => {
  return {
    changeDate: props.changeDate,
    changeAdministrator: props.changeAdministrator,
    changeDescriptionEn: props.changeDescriptionEn,
    changeDescriptionFr: props.changeDescriptionFr,
  };
};
