import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Grid } from '@mui/material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { unwrapResult } from '@reduxjs/toolkit';

import Loader from '../../Components/Loader';
import { PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { regenerateReport } from '../../Slices/ReportSlice';
import theme from '../../theme';
import { DataCorrection, ReactLocationState, Report, Report90DaysRequest, Report90DaysResponse } from '../../Types';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
  textBoldCell: {
    fontWeight: 600,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface Report90DResultsProps {
  requestParams: Report90DaysRequest | null;
  fetchedResults: Array<Report90DaysResponse>;
  handleChangePage: (event: unknown, newPage: number) => void;
  count: number;
  pageNo: number;
}

interface HeadCell {
  id: keyof Report90DaysResponse;
  label: string;
  width: number;
}

const Report90DResults: FC<Report90DResultsProps> = ({
  requestParams,
  fetchedResults,
  handleChangePage,
  count,
  pageNo,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

  const headCells: readonly HeadCell[] = [
    {
      id: 'company',
      label: t('ninetyReport.results.company'),
      width: 10,
    },
    {
      id: 'reportRequestor',
      label: t('ninetyReport.results.requestor'),
      width: 10,
    },
    {
      id: 'reportDate',
      label: t('ninetyReport.results.reportDate'),
      width: 13,
    },
    {
      id: 'changeDate',
      label: t('ninetyReport.results.changeDate'),
      width: 10,
    },
    {
      id: 'referenceNumber',
      label: t('ninetyReport.results.ref'),
      width: 10,
    },
    {
      id: 'driverLicenseNumber',
      label: t('ninetyReport.results.dln'),
      width: 12,
    },
    {
      id: 'changes',
      label: t('ninetyReport.results.changes'),
      width: 18,
    },
    {
      id: 'reviewedBy',
      label: t('ninetyReport.results.reviewedBy'),
      width: 10,
    },
  ];

  const handleClickReport = async (id: string, ninetyDaysDataCorrectionId?: number) => {
    setIsLoadingReport(true);
    try {
      const resultReport = await dispatch(
        regenerateReport({ id: id, ninetyDaysDataCorrectionId: ninetyDaysDataCorrectionId })
      );
      const promiseReport = unwrapResult<any>(resultReport) as Report;
      if (promiseReport) {
        history.push({
          pathname: `/reports/${promiseReport.id}`,
          state: {
            from: '/ninetydays-report',
            ninetyDaysSearchParams: requestParams,
            ninetyDaysSearchResults: fetchedResults,
            ninetyDaysSearchResultsCount: count,
            ninetyDaysPageNo: pageNo,
          } as ReactLocationState,
        });
      }
    } catch (error) {
      handleError(error);
      setIsLoadingReport(false);
    }
  };

  return (
    <>
      <Grid item container xs={12}>
        <Grid item container xs={6} sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', paddingLeft: '8px' }}>
          <Typography fontWeight={700} sx={{ margin: 'auto 0px' }}>
            {`${count} ${
              fetchedResults.length === 1 ? t('ninetyReport.oneResultFound') : t('ninetyReport.manyResultsFound')
            }`}
          </Typography>
        </Grid>
        <Grid item container xs={6}>
          <TablePagination
            sx={{ width: '100%' }}
            count={count}
            rowsPerPageOptions={[PAGINATION_PAGESIZE]}
            rowsPerPage={PAGINATION_PAGESIZE}
            page={pageNo}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => {
              return '' + from + '-' + to + ` ${t('of')} ` + count;
            }}
          />
        </Grid>
      </Grid>
      <TableContainer component={'div'} data-testid="tableContainer">
        <Table size="small" stickyHeader aria-label="table-90days-result" sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sx={{ width: `${headCell.width}%` }}
                  data-testid={`headerCell-${headCell.id}`}
                >
                  {headCell.label}
                </TableCell>
              ))}
              <TableCell align="right" sx={{ width: '7%' }} />
            </TableRow>
          </TableHead>
          <TableBody data-testid="tableBody">
            {fetchedResults
              .map((oneFetchedResult) => ({
                ninetyDaysDataCorrectionId: oneFetchedResult.ninetyDaysDataCorrectionId,
                reportId: oneFetchedResult.reportId,
                company: i18n.language.startsWith('en')
                  ? oneFetchedResult.company.nameEn
                  : oneFetchedResult.company.nameFr,
                reportRequestor: oneFetchedResult.reportRequestor,
                reportDate: formatDateToLocalTime(oneFetchedResult.reportDate, true),
                ianaTimezone: oneFetchedResult.ianaTimezone,
                changeDate: formatDateToLocalTime(oneFetchedResult.changeDate, false),
                referenceNumber: oneFetchedResult.referenceNumber,
                driverLicenseNumber: oneFetchedResult.driverLicenseNumber,
                changes: `${oneFetchedResult.changes
                  .filter(
                    (correction: DataCorrection, idx: number, correctionsList: DataCorrection[]) =>
                      correctionsList.findIndex((t) => t.id === correction.id) === idx
                  )
                  .map((correction) =>
                    i18n.language.startsWith('en') ? `${correction.nameEn}` : `${correction.nameFr}`
                  )
                  .join(';')}`,
                reviewedBy: oneFetchedResult.reviewedBy,
              }))
              .map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-company-${index}`}
                  >
                    <Tooltip title={row.company} enterTouchDelay={0}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.company}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-reportRequestor-${index}`}
                  >
                    <Tooltip title={row.reportRequestor} enterTouchDelay={0}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.reportRequestor}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '13%' }}
                    data-testid={`tableCell-reportDate-${index}`}
                  >
                    <Tooltip enterTouchDelay={0} title={row.reportDate}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.reportDate}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-changeDate-${index}`}
                  >
                    <Tooltip enterTouchDelay={0} title={row.changeDate}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.changeDate}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-referenceNumber-${index}`}
                  >
                    <Tooltip enterTouchDelay={0} title={row.referenceNumber}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.referenceNumber}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '12%' }}
                    data-testid={`tableCell-driverLicenseNumber-${index}`}
                  >
                    <Tooltip enterTouchDelay={0} title={row.driverLicenseNumber}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.driverLicenseNumber}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '18%' }}
                    data-testid={`tableCell-changes-${index}`}
                  >
                    <Tooltip enterTouchDelay={0} title={row.changes}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.changes}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    sx={{ width: '10%' }}
                    data-testid={`tableCell-reviewedBy-${index}`}
                  >
                    <Tooltip enterTouchDelay={0} title={row.reviewedBy}>
                      <Typography className={row.reviewedBy == '' ? classes.textBoldCell : classes.textCell}>
                        {row.reviewedBy}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell} sx={{ width: '10%' }}>
                    <Button
                      className={classes.buttonText}
                      variant="text"
                      color="primary"
                      id={`btnViewReport${index}`}
                      onClick={() => handleClickReport(row.reportId, row.ninetyDaysDataCorrectionId)}
                      endIcon={<ArrowRightIcon />}
                      data-testid={`btnViewReport${index}`}
                    >
                      {t('ninetyReport.results.view')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TablePagination
            count={count}
            rowsPerPageOptions={[PAGINATION_PAGESIZE]}
            rowsPerPage={PAGINATION_PAGESIZE}
            page={pageNo}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => {
              return '' + from + '-' + to + ` ${t('of')} ` + count;
            }}
          />
        </Table>
      </TableContainer>
      <Loader open={isLoadingReport} />
    </>
  );
};

export default Report90DResults;
