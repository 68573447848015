import CodeReference from './CodeReference';
import UserFunctionCompany from './UserFunctionCompany';
import VehicleClaim from './VehicleClaim';
import VehiclePolicy from './VehiclePolicy';

export interface VehicleReport {
  reportDate: Date;
  lastUpdated: Date;
  requestor: string;
  company: UserFunctionCompany;
  vin: string;
  vehicleCode: string | null;
  modelYear: number;
  makeEn: string;
  makeFr: string;
  modelEn: string;
  modelFr: string;
  branding: CodeReference | null;
  grossVehicleWeight: number | null;
  policies: Array<VehiclePolicy>;
  claims: Array<VehicleClaim>;
  totalClaimsLoss: number;
  totalClaimsExpense: number;
}

export const VehicleReportFactory = (props: VehicleReport): VehicleReport => {
  return {
    reportDate: props.reportDate,
    lastUpdated: props.lastUpdated,
    requestor: props.requestor,
    company: props.company,
    vin: props.vin,
    vehicleCode: props.vehicleCode,
    modelYear: props.modelYear,
    makeEn: props.makeEn,
    makeFr: props.makeFr,
    modelEn: props.modelEn,
    modelFr: props.modelFr,
    branding: props.branding,
    grossVehicleWeight: props.grossVehicleWeight,
    policies: props.policies,
    claims: props.claims,
    totalClaimsExpense: props.totalClaimsExpense,
    totalClaimsLoss: props.totalClaimsLoss,
  };
};

export default VehicleReport;
