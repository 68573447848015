import { PublicClientApplication } from '@azure/msal-browser';

import { loginScopes, msalConfig } from '../Features/IbccrAuthorization/authConfig';

export const getToken = async (): Promise<string> => {
  const pca = new PublicClientApplication(msalConfig);
  const accounts = pca.getAllAccounts();
  const account = accounts[0];

  const resp = await pca.acquireTokenSilent({
    scopes: loginScopes,
    account,
  });

  return resp.accessToken;
};
