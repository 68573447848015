import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GetAllOrganizations } from '../Slices/OrganizationSlice';

const useIbcOrThirdPartySelected = (organizationId: number | null, listOrgId?: Array<number>): boolean => {
  const [isIbcOrThirdPartySelected, setIsIbcOrThirdPartySelected] = useState<boolean>(false);

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const checkOrgType = useCallback(
    (orgId: number) => {
      const orgSelectedType = organizations.find((oneOrg) => oneOrg.id === orgId)?.organizationType;
      if (orgSelectedType && (orgSelectedType === 'IBC' || orgSelectedType === 'ThirdParty')) {
        setIsIbcOrThirdPartySelected(true);
      } else {
        setIsIbcOrThirdPartySelected(false);
      }
    },
    [organizations]
  );

  useEffect(() => {
    if (organizationId && !listOrgId) {
      checkOrgType(organizationId);
    } else if (listOrgId && !organizationId) {
      const foundOrgs = organizations.filter((oneOrg) => listOrgId.some((o) => o === oneOrg.id));
      const arrayTypeFoundOrgs = foundOrgs.map((oneFoundOrg) => oneFoundOrg.organizationType);
      const isOnlyIbcOrThird = arrayTypeFoundOrgs.every((type) => type === 'IBC' || type === 'ThirdParty');
      if (isOnlyIbcOrThird) {
        setIsIbcOrThirdPartySelected(true);
      } else {
        setIsIbcOrThirdPartySelected(false);
      }
    } else if (!organizationId && !listOrgId) {
      setIsIbcOrThirdPartySelected(false);
    }
  }, [checkOrgType, listOrgId, organizationId, organizations]);

  return isIbcOrThirdPartySelected;
};

export default useIbcOrThirdPartySelected;
