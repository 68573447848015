import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';

import ClientTypeSlice from './Slices/ClientTypeSlice';
import functionSlice from './Slices/FunctionSlice';
import InvitationIdSlice from './Slices/InvitationIdSlice';
import jurisdictionSlice from './Slices/JurisdictionSlice';
import notificationSlice from './Slices/NotificationSlice';
import organizationSlice from './Slices/OrganizationSlice';
import reportSlice from './Slices/ReportSlice';
import userSlice from './Slices/UserSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: sessionStorage,
};

const appReducer = combineReducers({
  notification: notificationSlice,
  province: jurisdictionSlice,
  report: reportSlice,
  organization: organizationSlice,
  appFunction: functionSlice,
  user: userSlice,
  clientType: ClientTypeSlice,
  invitationId: InvitationIdSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'notifications/clearStore') {
    // this applies to all keys defined in persistConfig(s)
    sessionStorage.removeItem('persist:root');
    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

//Test helper
export const configureTestStore = (preloadedState: any) => {
  return configureStore({
    reducer: {
      notification: notificationSlice,
      province: jurisdictionSlice,
      report: reportSlice,
      user: userSlice,
      organization: organizationSlice,
      appFunction: functionSlice,
      clientType: ClientTypeSlice,
      invitationId: InvitationIdSlice,
    },
    preloadedState,
  });
};

export { store, persistor };
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
