import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, Container, Grid } from '@mui/material';

import Loader from '../Components/Loader';
import { GetTermsContent } from '../Helpers/TermsOfUseHelper';
import theme from '../theme';
import { User } from '../Types';

interface TermsOfUsePageProps {
  user: User | undefined;
}

const Terms: FC<TermsOfUsePageProps> = () => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <Container data-testid="termsContainer" sx={{ paddingBottom: theme.spacing(10) }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={5} data-testid="termsOfUseCard">
              <CardContent>
                <Grid container padding={5}>
                  <Grid item container>
                    {GetTermsContent(i18n.language.substring(0, 2), setIsLoading)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {isLoading && <Loader open={isLoading} />}
    </>
  );
};

export default Terms;
