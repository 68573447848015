import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import HelperText from '../../../../Components/HelperText';
import { sortUserFunctionCompanies } from '../../../../Helpers/SortHelpers';
import doUserHaveAccessToFeature from '../../../../Helpers/UserHelper';
import { GetAllAppFunctions } from '../../../../Slices/FunctionSlice';
import { getConnectedUser } from '../../../../Slices/UserSlice';
import theme from '../../../../theme';
import { AppFunction, Functions, UserFunctionCompany, UserFunctionFactory } from '../../../../Types';
import { FormValuesCreateEditUserProfileProps } from './CreateEditUserProfile';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.white.main,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: `1px solid #e0e0e0`,
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  textfield: {
    '& .MuiAutocomplete-endAdornment': {
      position: 'absolute',
      right: '0',
      top: '8px',
    },
  },
}));

interface UserFunctionsProps {
  formValues: FormValuesCreateEditUserProfileProps;
  setFormValues: Dispatch<SetStateAction<FormValuesCreateEditUserProfileProps>>;
  assignedOrganizationsIds: Array<number>;
  isLoadingRpt: boolean;
  userToEditId: number | null;
  isUserFuncError: boolean;
}

const UserFunctions: FC<UserFunctionsProps> = ({
  formValues,
  setFormValues,
  assignedOrganizationsIds,
  isLoadingRpt,
  userToEditId,
  isUserFuncError,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const appFunctions = useSelector((state: { appFunction: any }) => GetAllAppFunctions(state));
  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const noCompanyError = formValues.userFunctions.some(
    (uf) =>
      uf.functionId !== Functions.vehicleReport &&
      uf.functionId != Functions.manageDashClients &&
      uf.functionId !== Functions.manageSystemFunction &&
      uf.reportingCompanies.length === 0
  );

  const noUserFunctionError = useMemo(() => formValues.userFunctions.length === 0, [formValues.userFunctions.length]);

  const sortUFCompanies = useCallback(
    (a: UserFunctionCompany, b: UserFunctionCompany): number => {
      return sortUserFunctionCompanies(a, b, i18n);
    },
    [i18n]
  );

  const reportingCompaniesByFunction = useCallback(
    (functionId: number) => {
      const rptCompaniesByFunction =
        user.userFunctions.find((uf) => uf.functionId === functionId)?.reportingCompanies ?? [];

      return rptCompaniesByFunction
        .filter((oneRpt) => assignedOrganizationsIds?.some((id) => id === oneRpt.organizationId) ?? false)
        .sort((a: UserFunctionCompany, b: UserFunctionCompany) => sortUFCompanies(a, b));
    },
    [assignedOrganizationsIds, sortUFCompanies, user.userFunctions]
  );

  const isAllChecked = useCallback(
    (funcId: number) => {
      const currentUserFunc = formValues.userFunctions.find((uf) => uf.functionId === funcId);
      return currentUserFunc?.reportingCompanies
        ? currentUserFunc?.reportingCompanies.length === reportingCompaniesByFunction(funcId).length
        : false;
    },
    [formValues.userFunctions, reportingCompaniesByFunction]
  );

  const handleClickCheckboxFunc = (currentFunc: AppFunction, isChecked: boolean) => {
    if (
      formValues.userFunctions.length > 0 &&
      formValues.userFunctions.some((uf) => uf.functionId === currentFunc.id)
    ) {
      setFormValues({
        ...formValues,
        userFunctions: formValues.userFunctions.filter((uf) => uf.functionId !== currentFunc.id),
        userDriverReportOptions: currentFunc.id === Functions.driverReport ? [] : formValues.userDriverReportOptions,
      });
    } else if (isChecked) {
      const userFunc = formValues.userFunctions;
      userFunc.push({
        userId: userToEditId ? userToEditId : 0,
        functionId: currentFunc.id,
        reportingCompanies:
          reportingCompaniesByFunction(currentFunc.id).length === 1 ? reportingCompaniesByFunction(currentFunc.id) : [],
      });
      setFormValues({
        ...formValues,
        userFunctions: userFunc,
      });
    }
  };

  const handleAllOnClick = (isChecked: boolean, func: AppFunction) => {
    const newUserFunctions = formValues.userFunctions.filter((oneUserFunc) => oneUserFunc.functionId !== func.id);

    if (!isChecked) {
      setFormValues({
        ...formValues,
        userFunctions: newUserFunctions,
        userDriverReportOptions: func.id === Functions.driverReport ? [] : formValues.userDriverReportOptions,
      });
    } else {
      newUserFunctions.push(
        UserFunctionFactory({
          userId: userToEditId ? userToEditId : 0,
          functionId: func.id,
          reportingCompanies: isChecked ? reportingCompaniesByFunction(func.id) : [],
        })
      );
      setFormValues({ ...formValues, userFunctions: newUserFunctions });
    }
  };

  const handleRptCompanyAutocompleteChange = (value: Array<UserFunctionCompany>, func: AppFunction) => {
    const rptCompaniesByFunction = reportingCompaniesByFunction(func.id).filter((oneRpt) =>
      value.some(
        (v) => v.organizationId === oneRpt.organizationId && v.reportingCompanyId === oneRpt.reportingCompanyId
      )
    );

    const newUserFunctions = formValues.userFunctions.filter((oneUserFunc) => oneUserFunc.functionId !== func.id);
    if (value.length === 0) {
      setFormValues({
        ...formValues,
        userFunctions: newUserFunctions,
        userDriverReportOptions: func.id === Functions.driverReport ? [] : formValues.userDriverReportOptions,
      });
    } else {
      newUserFunctions.push(
        UserFunctionFactory({
          userId: userToEditId ? userToEditId : 0,
          functionId: func.id,
          reportingCompanies: rptCompaniesByFunction,
        })
      );
      setFormValues({
        ...formValues,
        userFunctions: newUserFunctions,
        userDriverReportOptions:
          func.id === Functions.driverReport
            ? formValues.userDriverReportOptions.filter((udro) =>
                value.some(
                  (oneRpt) =>
                    oneRpt.organizationId === udro.driverReportOption.organizationId &&
                    oneRpt.reportingCompanyId === udro.driverReportOption.reportingCompanyId
                )
              )
            : formValues.userDriverReportOptions,
      });
    }
  };

  return (
    <>
      <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
        <Grid item xs={5}>
          <Typography align="left" sx={{ fontWeight: 700 }}>
            {t('userProfile.functions.lblFunctions')}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography align="left" sx={{ fontWeight: 700 }}>
            {t('userProfile.functions.lblCompanies')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        sx={{
          border: isUserFuncError && (noCompanyError || noUserFunctionError) ? '1px solid #F44336' : undefined,
        }}
      >
        {appFunctions.map((func, index) => (
          <Grid key={index} item container xs={12} alignItems="top" className={classes.tableRow}>
            <Grid item xs={5}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography sx={{ fontWeight: 700 }}>
                    {i18n.language.startsWith('en') ? func.nameEn : func.nameFr}
                  </Typography>
                }
                checked={formValues.userFunctions.some((uf) => uf.functionId === func.id)}
                onChange={(_, checked) => handleClickCheckboxFunc(func, checked)}
                data-testid={`checkbox-function-${index}`}
                disabled={formValues.isDisabled || !doUserHaveAccessToFeature(user, func.id)}
              />
            </Grid>

            <Grid item xs={2}>
              {func.id !== Functions.vehicleReport &&
                func.id !== Functions.manageDashClients &&
                func.id !== Functions.manageSystemFunction && (
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t('userProfile.functions.lblAll').toString()}
                    onChange={(_, checked) => handleAllOnClick(checked, func)}
                    checked={isAllChecked(func.id)}
                    data-testid={`checkbox-all-${index}`}
                    disabled={formValues.isDisabled || !doUserHaveAccessToFeature(user, func.id)}
                  />
                )}
            </Grid>
            <Grid item xs={5} alignItems="flex-start">
              {func.id !== Functions.vehicleReport &&
                func.id !== Functions.manageDashClients &&
                func.id !== Functions.manageSystemFunction &&
                formValues.userFunctions.find((uf) => uf.functionId === func.id) && (
                  <Autocomplete
                    fullWidth
                    value={formValues.userFunctions.find((uf) => uf.functionId === func.id)?.reportingCompanies ?? []}
                    loading={isLoadingRpt}
                    loadingText={t('loading')}
                    noOptionsText={t('search.noOption')}
                    multiple={true}
                    limitTags={3}
                    id={`selectedCompanies_${index}`}
                    data-testid={`selectedCompanies-${index}`}
                    options={reportingCompaniesByFunction(func.id)}
                    getOptionLabel={(option: UserFunctionCompany) =>
                      i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.organizationId === value.organizationId &&
                      option.reportingCompanyId === value.reportingCompanyId
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        sx={{ paddingRight: 0 }}
                        placeholder={t('userProfile.functions.lblPlaceholderAssignOrg')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingRpt ? (
                                <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          classes: {
                            adornedEnd: classes.textfield,
                          },
                        }}
                      />
                    )}
                    renderTags={(_, getTagProps) =>
                      formValues.userFunctions
                        .find((sc) => sc.functionId === func.id)
                        ?.reportingCompanies.map((option, index) => {
                          return (
                            <Chip
                              {...getTagProps({ index })}
                              key={index}
                              variant="outlined"
                              label={option.code}
                              size="small"
                              data-testid={`rpt-tag-${func.id}-${index}`}
                            />
                          );
                        })
                    }
                    onChange={(_, value) => handleRptCompanyAutocompleteChange(value, func)}
                    disabled={!doUserHaveAccessToFeature(user, func.id)}
                  />
                )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {isUserFuncError &&
        ((noCompanyError && <HelperText testid="errorMessage" message="userProfile.functions.noCompanySelected" />) ||
          (noUserFunctionError && (
            <HelperText testid="errorMessage" message="userProfile.errorMessage.firstNameRequired" />
          )))}
    </>
  );
};

export default UserFunctions;
