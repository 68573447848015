export interface VehicleClass {
  typeOfUseCode: string | null;
  descriptionEn: string | null;
  descriptionFr: string | null;
}

export const VehicleClassFactory = (props: VehicleClass): VehicleClass => {
  return {
    typeOfUseCode: props.typeOfUseCode,
    descriptionEn: props.descriptionEn,
    descriptionFr: props.descriptionFr,
  };
};

export default VehicleClass;
