import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';

import { Units } from '../../../axios';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { ModeEnum, Unit } from '../../../Types';
import { AddRenameDialogProps } from './AddDialogProps';

const AddRenameUnitDialog: FC<AddRenameDialogProps<Unit>> = ({
  mode,
  item,
  selectedOrgId,
  selectedRptCompanyId,
  onAction,
  onCreated,
  onUpdated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [open, setOpen] = useState<boolean>(true);
  const [field, setField] = useState<string>(item && item.nameEn ? item.nameEn : '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isFieldEmpty = useCallback(() => {
    return !field.trim();
  }, [field]);

  const handleSave = async () => {
    setIsLoading(true);
    setOpen(false);
    const params = {
      name: field,
      organizationId: selectedOrgId,
      reportingCompanyId: selectedRptCompanyId,
    };

    if (mode === ModeEnum.Create) {
      await Units.create(params)
        .then((departement) => {
          dispatch(addNotification(200, 'success', t('successMessage.created', { resource: departement.nameEn })));
          onCreated(departement);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error, { error409: t('errorMessage.error409Duplicate') });
          setIsLoading(false);
        });
    } else if (item) {
      await Units.update(item.id, params)
        .then((unit) => {
          dispatch(addNotification(200, 'success', t('successMessage.updated', { resource: unit.nameEn })));
          onUpdated(unit);
          setIsLoading(false);
        })
        .catch((error) => {
          handleError(error, { error409: t('errorMessage.error409Duplicate') });
          setIsLoading(false);
        });
    }
    onAction();
  };

  const handleClose = () => {
    setOpen(false);
    onAction();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="addRenameDialogComponent"
      >
        <Typography variant="h5" sx={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4) }}>
          {mode === ModeEnum.Create
            ? `${t('userAdmin.companyProf.add')} ${t('userAdmin.companyProf.dialogTitleUnit')}`
            : `${t('userAdmin.companyProf.rename')} ${t('userAdmin.companyProf.dialogTitleUnit')}`}
        </Typography>
        <DialogContent sx={{ padding: theme.spacing(4) }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
              <TextField
                autoFocus
                size="small"
                margin="none"
                id="name"
                value={field}
                fullWidth
                variant="outlined"
                color="secondary"
                placeholder={
                  mode === ModeEnum.Create
                    ? t('userAdmin.companyProf.addFieldPlaceholder')
                    : t('userAdmin.companyProf.renameFieldPlaceholder')
                }
                autoComplete="nope"
                sx={{ paddingRight: '0' }}
                onChange={(e) => setField(e.target.value)}
                inputProps={{ 'data-testid': 'textfield', maxLength: 60 }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
            <Grid item xs={4}>
              <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={handleClose}>
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid="accept"
                variant="contained"
                color="primary"
                disabled={isFieldEmpty()}
                ref={enterKeyRef}
                onClick={handleSave}
              >
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading} />
    </>
  );
};

export default AddRenameUnitDialog;
