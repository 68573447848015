import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';

import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import { sortAppFunctionsString, sortUserAccessReportResponse } from '../../Helpers/SortHelpers';
import { getComparator, Order } from '../../Helpers/TableHelper';
import theme from '../../theme';
import { AppFunction, ReactLocationState, UserAccessReportResponseCsv } from '../../Types';
import { UserAccessReportRequest, UserAccessReportResponse } from '../../Types';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface UserAccessReportResultsProps {
  fetchedResults: Array<UserAccessReportResponse>;
  requestUserAccessSearch: UserAccessReportRequest;
  appFunctions: Array<AppFunction>;
  setResultsCsv: Dispatch<SetStateAction<UserAccessReportResponseCsv[] | null>>;
}

interface HeadCell {
  id: keyof UserAccessReportResponse | string;
  label: string;
  width: number;
}

/*
  File is "work in progress" - will be finished in next story 3523
*/
const UserAccessReportResults: FC<UserAccessReportResultsProps> = ({
  fetchedResults,
  requestUserAccessSearch,
  appFunctions,
  setResultsCsv,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state } = useLocation<ReactLocationState>();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserAccessReportResponse | string>('organization');

  const [sortedResults, setSortedResults] = useState<Array<UserAccessReportResponse> | null>(null);

  const headCells: readonly HeadCell[] = [
    {
      id: 'organization',
      label: t('userAccessReport.results.organization'),
      width: 12,
    },
    {
      id: 'reportingCompany',
      label: t('userAccessReport.results.company'),
      width: 12,
    },
    {
      id: 'userId',
      label: t('userAccessReport.results.userId'),
      width: 12,
    },
    {
      id: 'userName',
      label: t('userAccessReport.results.name'),
      width: 9,
    },
    {
      id: 'userStatus',
      label: t('userAccessReport.results.status'),
      width: 9,
    },
    {
      id: 'createdDate',
      label: t('userAccessReport.results.created'),
      width: 9,
    },
    {
      id: 'lastLoginDate',
      label: t('userAccessReport.results.lastLogin'),
      width: 9,
    },
    {
      id: 'functions',
      label: t('userAccessReport.results.functions'),
      width: 19,
    },
  ];

  // According to specification, table should have a default sort before user input
  useEffect(() => {
    setSortedResults(fetchedResults ? fetchedResults.sort((a, b) => sortUserAccessReportResponse(a, b, i18n)) : null);
  }, [fetchedResults, i18n]);

  // This is used to update a specific CSV state of the results to save the CSV with the order of the table
  useEffect(() => {
    setResultsCsv(
      sortedResults
        ? sortedResults
            .map((oneFetchedResult) => ({
              id: oneFetchedResult.id,
              organization: i18n.language.startsWith('en')
                ? oneFetchedResult.organization?.nameEn
                  ? oneFetchedResult.organization?.nameEn
                  : ''
                : oneFetchedResult.organization?.nameFr
                ? oneFetchedResult.organization?.nameFr
                : '',
              reportingCompany:
                oneFetchedResult.organization && oneFetchedResult.reportingCompany
                  ? i18n.language.startsWith('en')
                    ? oneFetchedResult.reportingCompany?.nameEn
                      ? oneFetchedResult.reportingCompany?.nameEn
                      : ''
                    : oneFetchedResult.reportingCompany?.nameFr
                    ? oneFetchedResult.reportingCompany?.nameFr
                    : ''
                  : i18n.language.startsWith('en')
                  ? oneFetchedResult.organization?.nameEn
                    ? oneFetchedResult.organization?.nameEn
                    : ''
                  : oneFetchedResult.organization?.nameFr
                  ? oneFetchedResult.organization?.nameFr
                  : '',
              userId: oneFetchedResult.userId,
              userName: oneFetchedResult.userName ?? '',
              userStatus: t('userAdmin.userAdmin.status', {
                context: oneFetchedResult.userStatus,
              }).toString(),
              createdDate: oneFetchedResult.createdDate
                ? formatDateToLocalTime(oneFetchedResult.createdDate, false)
                : '',
              lastLoginDate: oneFetchedResult.lastLoginDate
                ? formatDateToLocalTime(oneFetchedResult.lastLoginDate, false)
                : '',
              functions: i18n.language.startsWith('en')
                ? oneFetchedResult.functionsEn
                    .sort((a: string, b: string) => sortAppFunctionsString(a, b, appFunctions, i18n))
                    .join('; ')
                : oneFetchedResult.functionsFr
                    .sort((a: string, b: string) => sortAppFunctionsString(a, b, appFunctions, i18n))
                    .join('; '),
            }))
            .sort(getComparator(order, orderBy))
        : []
    );
  }, [appFunctions, i18n, order, orderBy, setResultsCsv, sortedResults, t]);

  const handleClickViewUser = async (id: number) => {
    const newState: ReactLocationState = {
      ...state,
      from: '/user-access-report',
      userAccessSearchRequest: requestUserAccessSearch,
      userAccessDetailsSelected: fetchedResults.find((oneRes) => oneRes.id === id),
    };
    history.push(`/user-access-report-details/${id}`, newState);
  };

  const handleRequestSort = (property: keyof UserAccessReportResponse | string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof UserAccessReportResponse | string) => () => {
    handleRequestSort(property);
  };

  return (
    <TableContainer component={'div'} data-testid="tableContainer">
      <Table size="small" stickyHeader aria-label="table-history-result" sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ width: `${headCell.width}%` }}
                data-testid={`headerCell-${headCell.id}`}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="right" sx={{ width: '9%' }}>
              {t('userAccessReport.results.history')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="tableBody">
          {fetchedResults
            .map((oneFetchedResult) => ({
              id: oneFetchedResult.id,
              organization: i18n.language.startsWith('en')
                ? oneFetchedResult.organization?.nameEn
                  ? oneFetchedResult.organization?.nameEn
                  : ''
                : oneFetchedResult.organization?.nameFr
                ? oneFetchedResult.organization?.nameFr
                : '',
              reportingCompany:
                oneFetchedResult.organization && oneFetchedResult.reportingCompany
                  ? i18n.language.startsWith('en')
                    ? oneFetchedResult.reportingCompany?.nameEn
                      ? oneFetchedResult.reportingCompany?.nameEn
                      : ''
                    : oneFetchedResult.reportingCompany?.nameFr
                    ? oneFetchedResult.reportingCompany?.nameFr
                    : ''
                  : i18n.language.startsWith('en')
                  ? oneFetchedResult.organization?.nameEn
                    ? oneFetchedResult.organization?.nameEn
                    : ''
                  : oneFetchedResult.organization?.nameFr
                  ? oneFetchedResult.organization?.nameFr
                  : '',
              userId: oneFetchedResult.userId,
              userName: oneFetchedResult.userName ?? '',
              userStatus: oneFetchedResult.userStatus,
              createdDate: oneFetchedResult.createdDate
                ? formatDateToLocalTime(oneFetchedResult.createdDate, false)
                : '',
              lastLoginDate: oneFetchedResult.lastLoginDate
                ? formatDateToLocalTime(oneFetchedResult.lastLoginDate, false)
                : '',
              functionsEn: oneFetchedResult.functionsEn
                .sort((a: string, b: string) => sortAppFunctionsString(a, b, appFunctions, i18n))
                .join('; '),
              functionsFr: oneFetchedResult.functionsFr
                .sort((a: string, b: string) => sortAppFunctionsString(a, b, appFunctions, i18n))
                .join('; '),
            }))
            .sort(getComparator(order, orderBy))
            .map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                  sx={{ width: '12%' }}
                  data-testid={`tableCell-organization-${index}`}
                >
                  <Tooltip enterTouchDelay={0} title={row.organization}>
                    <Typography className={classes.textCell}>{row.organization}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} sx={{ width: '12%' }} data-testid={`tableCell-rpt-${index}`}>
                  <Tooltip enterTouchDelay={0} title={row.reportingCompany ?? ''}>
                    <Typography className={classes.textCell}>{row.reportingCompany}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '12%' }}
                  data-testid={`tableCell-userId-${index}`}
                >
                  <Tooltip title={row.userId} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.userId}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '9%' }}
                  data-testid={`tableCell-userName-${index}`}
                >
                  <Tooltip title={row.userName} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.userName}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} sx={{ width: '9%' }} data-testid={`tableCell-status-${index}`}>
                  <Tooltip
                    enterTouchDelay={0}
                    title={t('userAdmin.userAdmin.status', { context: row.userStatus }).toString()}
                  >
                    <Typography className={classes.textCell}>
                      {t('userAdmin.userAdmin.status', { context: row.userStatus })}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '9%' }}
                  data-testid={`tableCell-created-${index}`}
                >
                  <Tooltip title={formatDateToLocalTime(row.createdDate, false)} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>
                      {formatDateToLocalTime(row.createdDate, false)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '9%' }}
                  data-testid={`tableCell-lastLogin-${index}`}
                >
                  <Tooltip title={formatDateToLocalTime(row.lastLoginDate, false)} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>
                      {formatDateToLocalTime(row.lastLoginDate, false)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '9%' }}
                  data-testid={`tableCell-functions-${index}`}
                >
                  <Tooltip
                    title={i18n.language.startsWith('en') ? row.functionsEn : row.functionsFr}
                    enterTouchDelay={0}
                  >
                    <Typography className={classes.textCell}>
                      {i18n.language.startsWith('en') ? row.functionsEn : row.functionsFr}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right" className={classes.tableCell} sx={{ width: '9%' }}>
                  <Button
                    className={classes.buttonText}
                    size="small"
                    variant="text"
                    color="primary"
                    id={`btnViewProfile${index}`}
                    onClick={() => handleClickViewUser(row.id)}
                    endIcon={<ArrowRightIcon />}
                    data-testid={`btnViewProfile${index}`}
                  >
                    {t('userAdmin.userAdmin.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserAccessReportResults;
