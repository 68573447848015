export enum ClaimStatus {
  closed,
  open,
}

export enum CoverageListEnum {
  TP,
  TP_BI,
  TP_PD,
  DCPD,
  UA,
  UM,
  AB,
  CL,
  CM,
}

export enum Functions {
  driverReport = 1,
  vehicleReport = 2,
  reportHistory = 3,
  ninetyDaysReport = 4,
  dataCorrection = 5,
  userAccessReport = 6,
  companyAdministration = 7,
  userAdministration = 8,
  usageReport = 9,
  manageSystemFunction = 10,
  manageDashClients = 11,
  correctionReport = 12,
  sdcReport = 13,
}

export enum UserStatus {
  all,
  active,
  disable,
}

export enum NinetyDaysReportStatus {
  all,
  reviewed,
  notreviewed,
}

export enum ClientTypeEnum {
  Brokerage = 1,
  CommercialThirdParty = 2,
  Other = 3,
  InsuranceCompany = 4,
  InsuranceOrganization = 5,
  IBC = 6,
}

export enum VehicleLoss {
  none,
  partial,
  total,
}

export enum ModeEnum {
  Create,
  Update,
}

export enum UserTypeEnum {
  company = 1,
  ibccr = 2,
}

export type UserTypeOption = {
  value: UserTypeEnum;
  label: string;
};

export const UserTypeEnumList: UserTypeOption[] = Object.keys(UserTypeEnum)
  .filter((v) => isNaN(Number(v)))
  .map((name) => {
    return {
      value: UserTypeEnum[name as keyof typeof UserTypeEnum],
      label: 'userType.' + name.toLowerCase(),
    };
  });

export type ModeType = ModeEnum.Create | ModeEnum.Update;

export enum ClientStatusEnum {
  active = 1,
  inactive = 2,
}

export type ClientStatusOption = {
  value: ClientStatusEnum;
  label: string;
};

export const ClientStatusEnumList: ClientStatusOption[] = Object.keys(ClientStatusEnum)
  .filter((v) => isNaN(Number(v)))
  .map((name) => {
    return {
      value: ClientStatusEnum[name as keyof typeof ClientStatusEnum],
      label: 'dashclients.clientDetails.status_' + name.toLowerCase(),
    };
  });

export enum ExcludedDriverEnum {
  no = 1,
  yes = 2,
}

export type ExcludedDriverEnumOption = {
  value: ExcludedDriverEnum;
  label: string;
};

export const ExcludedDriverEnumList: ExcludedDriverEnumOption[] = Object.keys(ExcludedDriverEnum)
  .filter((v) => isNaN(Number(v)))
  .map((name) => {
    return {
      value: ExcludedDriverEnum[name as keyof typeof ExcludedDriverEnum],
      label: 'dialog.' + name.toLowerCase(),
    };
  });
