export interface Organization {
  id: number;
  organizationType: 'IBC' | 'Carrier' | 'ThirdParty';
  nameEn: string;
  nameFr: string;
  code: string;
}

export const OrganizationFactory = (
  id: number,
  organizationType: 'IBC' | 'Carrier' | 'ThirdParty',
  nameEn: string,
  nameFr: string,
  code: string
): Organization => {
  return {
    id,
    organizationType,
    nameEn,
    nameFr,
    code,
  };
};

export default Organization;
