import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { ApplicationPaths } from '../Features/Authorization/AuthorizationConstants';
import Constants from '../Helpers/Constants';
import { GetAllNotifications, removeNotifications } from '../Slices/NotificationSlice';
import theme from '../theme';
import { User } from '../Types';
import IdleTimerContainer from './IdleTimerContainer';
import NavBar from './NavBar';
import Snack from './Snack';

const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      '@media print': {
        display: 'none',
      },
    },
  })
);

interface HeaderProps extends RouteComponentProps<any> {
  user: User | undefined;
}

const Header: FC<HeaderProps> = ({ user, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ user });

  const timeout: number =
    (window as any).Cypress && (window as any).Cypress.testingType === 'component'
      ? 1000 * 60 * 15
      : 1000 * 60 * Number.parseInt(Constants.sessionTimeout);

  const notifications = useSelector((state: { notification: any }) => GetAllNotifications(state));

  const handleNotificationClose = () => {
    dispatch(removeNotifications());
  };

  // notification with error code 0 indicates eigther user decided to logout after timeout prompt or user did not choose any action and session timed out
  const handleLogOutConfirm = (logout: boolean) => {
    if (logout) {
      history.push({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
    }
  };

  return (
    <>
      {!user && location.href.includes('login') ? null : (
        <div
          color="white"
          className={classes.appBar}
          style={{
            backgroundColor: theme.palette.white.main,
            position: 'fixed',
            width: '100%',
            zIndex: 999,
            borderBottom: `8px solid ${theme.palette.accentDark.main}`,
          }}
        >
          <Grid container>
            <Grid item container xs={12} justifyContent="flex-start" paddingLeft={1} paddingRight={1}>
              <Grid item container>
                <NavBar user={user} history={history} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {notifications && notifications.length > 0 && <Snack message={notifications} onClose={handleNotificationClose} />}
      {user && <IdleTimerContainer timeout={timeout} onClose={handleLogOutConfirm} />}
    </>
  );
};

export default withRouter(Header);
