import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';

import { Users } from '../axios';
import { Functions, User, UserFactory, UserFunctionCompanyFactory, UserFunctionFactory } from '../Types';

// Adapter
const userAdapter = createEntityAdapter<User>({
  // Assume IDs are stored in a field id
  selectId: (user) => user.id,
  // Keep the "all IDs" array sorted based on acronym
  sortComparer: (a, b) => {
    if (!a.firstName < !b.firstName) {
      return -1;
    } else if (!a.lastName > !b.lastName) {
      return 1;
    }
    return 0;
  },
});

// Selectors
// Get default selects that comes with entityAdapter
const userSelectors = userAdapter.getSelectors((state: { user: any }) => state.user);

// map selectors if you want to have them called differently
export const { selectId: getUserById } = userAdapter;

export const getUserByEmail = createSelector(
  [
    // First input selector: all users
    userSelectors.selectAll,
    // Second input selector: email value
    (state: any, email: string) => email,
  ],
  // Output selector: receives both values
  (users: Array<User>, email: string) => {
    // Return provinces based on dln and province
    return users.filter((user) => {
      return user.email === email;
    });
  }
);

export const getConnectedUser = createSelector(
  [
    // First input selector: all users
    userSelectors.selectAll,
    // Second input selector: email value
    (state: any) => state,
  ],
  // Output selector: receives both values
  (users: Array<User>) => {
    // Return provinces based on dln and province
    return users[0];
  }
);

export const GetAllUsers = createSelector([userSelectors.selectAll], (users: Array<User>) => {
  return users;
});

//Test helper
export const CreateUserTestState = () => {
  const users = [
    UserFactory(
      1,
      1,
      '',
      'John',
      null,
      'Doe',
      'john.doe@dash.com',
      null,
      '000000',
      null,
      false,
      false,
      false,
      1,
      null,
      null,
      new Date(),
      [1],
      [
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.driverReport,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.vehicleReport,
          reportingCompanies: [],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.reportHistory,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.ninetyDaysReport,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.dataCorrection,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.userAccessReport,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.companyAdministration,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.userAdministration,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
        UserFunctionFactory({
          userId: 1,
          functionId: Functions.usageReport,
          reportingCompanies: [
            UserFunctionCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
            UserFunctionCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
            UserFunctionCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
            UserFunctionCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
            UserFunctionCompanyFactory(5, 2, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
            UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
            UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
          ],
        }),
      ],
      [],
      false
    ),
  ];
  const userState = userAdapter.getInitialState();
  users.forEach((user) => {
    userState.ids.push(user.id);
    userState.entities[user.id] = user;
  });

  return { user: userState };
};

// extra reducers - async thunk
export const GetProfile = createAsyncThunk('/getProfile', async () => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    // testing - return default state
    return [
      UserFactory(
        1,
        1,
        '',
        'John',
        null,
        'Doe',
        'john.doe@dash.com',
        null,
        '000000',
        null,
        false,
        false,
        false,
        1,
        null,
        null,
        new Date(),
        [1],
        [],
        [],
        false
      ),
    ];
  } else {
    const result = await Users.find();
    if (result) {
      return [result];
    } else {
      return [];
    }
  }
});

// Slice
const initialState: { isPending: boolean; error: SerializedError | null } = { isPending: false, error: null };

const userSlice = createSlice({
  name: 'users',
  initialState: userAdapter.getInitialState(initialState),
  reducers: {
    removeError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetProfile.pending, (state) => {
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(GetProfile.fulfilled, (state, { payload: users }) => {
      state.isPending = false;
      if (users) {
        userAdapter.removeAll(state);
        userAdapter.addMany(state, users);
      }
    });
    builder.addCase(GetProfile.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { removeError } = userSlice.actions;

export default userSlice.reducer;
