import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';

import { Button, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
import DashClientSearch from '../Features/DashClients/DashClientSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
import { Functions, ModeEnum, ReactLocationState, User } from '../Types';
import { ClientSearchFormValue } from '../Types/ClientSponsor';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface DashClientsPageProps extends RouteComponentProps {
  user: User;
}

const DashClientsPage: FC<DashClientsPageProps> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = useLocation<ReactLocationState>();

  const userAccessDashClient = doUserHaveAccessToFeature(user, Functions.manageDashClients);

  const [clientSearchFormValue, setClientSearchFormValue] = useState<ClientSearchFormValue | null>(null);

  const handleOnClickNewClient = () => {
    history.push({
      pathname: `/dash-client/0`,
      state: {
        ...state,
        from: '/dash-client',
        clientSearchRequest: clientSearchFormValue,
        clientToEdit: undefined,
        clientDetailsMode: ModeEnum.Create,
      } as ReactLocationState,
    });
  };

  const newClientButton = (
    <Grid item container xs={3}>
      <Button
        fullWidth
        variant="contained"
        id="btnNewClient"
        data-testid="btnNewClient"
        onClick={handleOnClickNewClient}
      >
        {t('dashclients.newClient')}
      </Button>
    </Grid>
  );

  return user ? (
    <Container className={classes.root}>
      {userAccessDashClient && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerTitle title={t('menu.dashclient')} button={newClientButton} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DashClientSearch setClientSearchFormValue={setClientSearchFormValue} />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  ) : (
    <></>
  );
};

export default withRouter(DashClientsPage);
