import { requests } from '../axios';
import {
  OrganizationFactory,
  ReportingCompanyFactory,
  UserAccessDetails,
  UserAccessDetailsFactory,
  UserAccessReportRequest,
  UserAccessReportResponse,
  UserAccessReportResponseFactory,
  UserStatus,
} from '../Types';

export const findUserAccessReport = (params: UserAccessReportRequest): Promise<Array<UserAccessReportResponse>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<UserAccessReportResponse>>((resolve) => {
      resolve([
        UserAccessReportResponseFactory({
          id: 1,
          reportingCompany: ReportingCompanyFactory(1, 1, '1231', 'CompanyNameFr', 'CompanyNameEn'),
          createdDate: new Date(),
          functionsEn: ['Driver Report', 'Report History', '90 Days Reports', 'Company Profile'],
          functionsFr: ['Rapport conducteur', 'Historique des Rapports', 'Rapport 90 jours', 'Company Profile'],
          lastLoginDate: new Date(),
          organization: OrganizationFactory(1, 'IBC', 'OrgNameEn', 'OrgNameFr', '123'),
          userId: 'louis.armstrong@ibc.com',
          userName: 'Louis Armstrong',
          userStatus: UserStatus.active,
        }),
      ]);
    });
  } else {
    return requests.post('/UserAccessReport', params);
  }
};

export const getUserAccessReportDetails = (userId: string): Promise<Array<UserAccessDetails>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<UserAccessDetails>>((resolve) => {
      resolve([
        UserAccessDetailsFactory({
          changeDate: new Date(),
          changeAdministrator: 'jack.oneill@stargate.com',
          changeDescriptionEn: 'Account Created',
          changeDescriptionFr: 'Création du compte',
        }),
        UserAccessDetailsFactory({
          changeDate: new Date(),
          changeAdministrator: 'jack.oneill@stargate.com',
          changeDescriptionEn: 'Driver Report - Access Granted',
          changeDescriptionFr: 'Rapport Conducteur - Accès autorisé',
        }),
      ]);
    });
  } else {
    return requests.get(`/UserAccessReport/${userId}`, {});
  }
};
