import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';

import ContainerTitle from '../Components/ContainerTitle';
import { MsalErrorComponent } from '../Components/MsalErrorComponent';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import ChangePassword from '../Features/ChangePassword/ChangePassword';
import { loginScopes } from '../Features/IbccrAuthorization/authConfig';
// import PasswordEN from '../Medias/titles/Password - EN.jpg';
// import PasswordFR from '../Medias/titles/Password - FR.jpg';
import theme from '../theme';
import { User } from '../Types';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
}));

interface ChangePasswordPageProps extends RouteComponentProps {
  user: User;
}

const ChangePasswordPage: FC<ChangePasswordPageProps> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const loginRequest = {
    scopes: loginScopes,
    extraQueryParameters: { login_hint: user.userId },
  };

  return user && user.isIbcCredentials ? (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={MsalErrorComponent}
      //loadingComponent={Loader}
    >
      <Container className={classes.root}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerTitle title={t('changePassword.title')} />
              {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? PasswordEN : PasswordFR} /> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ChangePassword />
            </Grid>
          </Grid>
        </>
      </Container>
    </MsalAuthenticationTemplate>
  ) : null;
};

export default withRouter(ChangePasswordPage);
