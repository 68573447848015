export interface UserFunctionCompany {
  reportingCompanyId: number | null;
  organizationId: number;
  code: string;
  nameEn: string;
  nameFr: string;
}

export const UserFunctionCompanyFactory = (
  reportingCompanyId: number | null,
  organizationId: number,
  code: string,
  nameFr: string,
  nameEn: string
): UserFunctionCompany => {
  return {
    reportingCompanyId,
    organizationId,
    code,
    nameEn,
    nameFr,
  };
};

export default UserFunctionCompany;
