export interface CompanyReference {
  code: string | null;
  nameEn: string;
  nameFr: string;
}

export const CompanyReferenceFactory = (code: string | null, nameFr: string, nameEn: string): CompanyReference => {
  return {
    code,
    nameEn,
    nameFr,
  };
};

export default CompanyReference;
