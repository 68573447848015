import { requests } from '../axios';
import OrganizationCompany, { OrganizationCompanyFactory } from '../Types/OrganizationCompany';

export const getOC = (params: {
  organizationId: number;
  reportingCompanyId?: number;
}): Promise<OrganizationCompany> => {
  const { organizationId, reportingCompanyId } = params;
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<OrganizationCompany>((resolve) => {
      if (organizationId === 3 && reportingCompanyId === 7) {
        // resolve();
      } else {
        resolve(OrganizationCompanyFactory(1, true, 1, 2));
      }
    });
  } else {
    return requests.get(
      `/organizationCompany/get/?organizationId=${organizationId}`.concat(
        reportingCompanyId ? `&reportingCompanyId=${reportingCompanyId}` : ''
      ),
      {}
    );
  }
};

export const updateOC = (
  id: number,
  params: { isIbcCredentials: boolean; organizationId: number; reportingCompanyId?: number }
): Promise<OrganizationCompany> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<OrganizationCompany>((resolve) => {
      const { isIbcCredentials, organizationId, reportingCompanyId } = params;
      resolve(OrganizationCompanyFactory(1, isIbcCredentials, organizationId, reportingCompanyId));
    });
  } else {
    return requests.put(`/organizationCompany/update/${id}`, params);
  }
};

export const createOC = (
  id: number,
  params: { isIbcCredentials: boolean; organizationId: number; reportingCompanyId?: number }
): Promise<OrganizationCompany> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<OrganizationCompany>((resolve) => {
      const { isIbcCredentials, organizationId, reportingCompanyId } = params;
      resolve(OrganizationCompanyFactory(1, isIbcCredentials, organizationId, reportingCompanyId));
    });
  } else {
    return requests.post(`/organizationCompany/create/${id}`, params);
  }
};
