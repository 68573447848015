import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, Container, Grid, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import Loader from '../Components/Loader';
// import B2CSettings from '../Features/Settings/B2CSettings/B2CSettings';
// import ClientSettings from '../Features/Settings/ClientSettings/index';
import ContactUsEditor from '../Features/Settings/ContactUs/ContactUsEditor';
// import DisclaimerEditor from '../Features/Settings/Disclaimer/DisclaimerEditor';
// import IbcContentEditor from '../Features/Settings/IbcContent/IbcContentEditor';
// import PrivacyPolicyEditor from '../Features/Settings/PrivacyPolicy/PrivacyPolicyEditor';
import SystemMessageEditor from '../Features/Settings/SystemMEssage/SystemMessageEditor';
import TermsOfServiceEditor from '../Features/Settings/TermsOfService/TermsOfServiceEditor';
// import SettingsEN from '../Medias/titles/Settings - EN.jpg';
// import SettingsFR from '../Medias/titles/Settings - FR.jpg';
import { User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  tab: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
    fontSize: '1rem',
    '&.Mui-selected': {
      fontWeight: 'bold !important',
    },
  },
}));

interface SettingsProps extends RouteComponentProps {
  user: User;
}

enum SelectedTabEnum {
  // b2cSettings = 'b2cSettings',
  // clientSettings = 'clientSettings',
  contactUs = 'contactUs',
  disclaimer = 'disclaimer',
  faq = 'faq',
  ibcContent = 'ibcContent',
  privacyPolicy = 'privacyPolicy',
  systemMessage = 'systemMessage',
  termsOfService = 'termsOfService',
}

const Settings: FC<SettingsProps> = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<SelectedTabEnum>(SelectedTabEnum.systemMessage);

  const handleTabClick = (event: React.SyntheticEvent, newValue: SelectedTabEnum) => {
    setSelectedTab(SelectedTabEnum[newValue]);
  };

  return user ? (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} className={classes.title}>
          <ContainerTitle title={t('settings.title')} />
          {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? SettingsEN : SettingsFR} /> */}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} className={classes.title}>
          <Card elevation={3}>
            <Grid item container xs={12} className={classes.title} justifyContent="space-between">
              <Grid item xs={12}>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Stack direction="row">
                      <TabList
                        orientation="horizontal"
                        variant="scrollable"
                        value={selectedTab}
                        onChange={handleTabClick}
                        aria-label="settings-screen-tabs"
                        data-testid="firstTabList"
                      >
                        {/* <Tab
                            className={classes.tab}
                            label={t('settings.disclaimer.title')}
                            value={SelectedTabEnum.disclaimer}
                            data-testid="tabDisclaimer"
                          />
                          <Tab
                            className={classes.tab}
                            label={t('settings.privacy-policy.title')}
                            value={SelectedTabEnum.privacyPolicy}
                            data-testid="tabPrivacyPolicy"
                          />
                          <Tab
                            className={classes.tab}
                            label={t('settings.ibc-content.title')}
                            value={SelectedTabEnum.ibcContent}
                            data-testid="tabIbcContent"
                          /> */}
                        <Tab
                          className={classes.tab}
                          label={t('settings.system-message.title')}
                          value={SelectedTabEnum.systemMessage}
                          data-testid="tabSystemMessage"
                        />
                        <Tab
                          className={classes.tab}
                          label={t('settings.contact-us.title')}
                          value={SelectedTabEnum.contactUs}
                          data-testid="tabContactUs"
                        />
                        <Tab
                          className={classes.tab}
                          label={t('settings.terms-of-service.title')}
                          value={SelectedTabEnum.termsOfService}
                          data-testid="tabTermsOfService"
                        />
                        {/* <Tab
                          className={classes.tab}
                          label={t('settings.b2cSettings.title')}
                          value={SelectedTabEnum.b2cSettings}
                          data-testid="tabB2cSettings"
                        />
                        <Tab
                          className={classes.tab}
                          label={t('settings.clientSettings.title')}
                          value={SelectedTabEnum.clientSettings}
                          data-testid="tabClientSettings"
                        /> */}
                      </TabList>
                    </Stack>
                  </Box>
                  {/* <TabPanel value={SelectedTabEnum.disclaimer}>
                      <DisclaimerEditor placeholder={t('settings.disclaimer.placeholder')} />
                    </TabPanel>
                    <TabPanel value={SelectedTabEnum.privacyPolicy}>
                      <PrivacyPolicyEditor placeholder={t('settings.privacy-policy.placeholder')} />
                    </TabPanel>
                    <TabPanel value={SelectedTabEnum.ibcContent}>
                      <IbcContentEditor placeholder={t('settings.ibc-content.placeholder')} />
                    </TabPanel> */}
                  <TabPanel value={SelectedTabEnum.systemMessage}>
                    <SystemMessageEditor />
                  </TabPanel>
                  <TabPanel value={SelectedTabEnum.contactUs}>
                    <ContactUsEditor placeholder={t('settings.contact-us.placeholder')} />
                  </TabPanel>
                  <TabPanel value={SelectedTabEnum.termsOfService}>
                    <TermsOfServiceEditor placeholder={t('settings.terms-of-service.placeholder')} />
                  </TabPanel>
                  {/* <TabPanel value={SelectedTabEnum.b2cSettings}>
                    <B2CSettings />
                  </TabPanel>
                  <TabPanel value={SelectedTabEnum.clientSettings}>
                    <ClientSettings />
                  </TabPanel> */}
                </TabContext>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Loader open={true} />
  );
};

export default withRouter(Settings);
