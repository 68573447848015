import { requests } from '../axios';
import { B2CProvider, B2CProviderFactory } from '../Types';

export const addB2C = async (provider: { emailDomainName: string; b2cDomainName: string }): Promise<B2CProvider> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<B2CProvider>((resolve) => {
      resolve(B2CProviderFactory(1, provider.emailDomainName, provider.b2cDomainName));
    });
  }
  return requests.post('/B2cProviders/create', provider);
};

export const deleteB2C = async (id: number): Promise<B2CProvider> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<B2CProvider>((resolve) => {
      resolve(B2CProviderFactory(id, 'hello', 'hello'));
    });
  }
  return requests.delete(`/B2cProviders/${id}`);
};

export const findB2C = async (username: string): Promise<B2CProvider> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<B2CProvider>((resolve) => {
      resolve(B2CProviderFactory(1, username.split('@')[1], username.split('@')[1]));
    });
  }
  return requests.get(`/B2cProviders/find?email=${username}`, {});
};

export const listB2C = async (): Promise<B2CProvider[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<B2CProvider[]>((resolve) => {
      resolve([B2CProviderFactory(1, 'systematix.com', 'systematix.com')]);
    });
  }
  return requests.get('/B2cProviders', {});
};

export const updateB2C = async (
  id: number,
  provider: {
    emailDomainName: string;
    b2cDomainName: string;
  }
): Promise<B2CProvider> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<B2CProvider>((resolve) => {
      resolve(B2CProviderFactory(id, provider.emailDomainName, provider.b2cDomainName));
    });
  }
  return requests.put(`/B2cProviders/${id}`, provider);
};
