import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Autocomplete, Card, CardContent, CircularProgress, Grid } from '@mui/material';

import {
  DriverReportOptions,
  NotificationRecipientOptions,
  PermittedDomains,
  ReportingCompanies,
  Units,
} from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import { sortOrganizations, sortRptCompanies } from '../../../Helpers/SortHelpers';
import useAutofocus from '../../../Hooks/UseAutofocus';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../../Slices/OrganizationSlice';
import theme from '../../../theme';
import { DriverReportOption, Functions, Organization, ReportingCompany, Unit } from '../../../Types';
import NotificationRecipientOption from '../../../Types/NotificationRecipientOption';
import PermittedDomainOption from '../../../Types/PermittedDomain';
import ReactLocationState from '../../../Types/ReactLocationState';
import CompanyProfileResults from './CompanyProfileResults';

interface FormValuesProps {
  organizationId: number | null;
  rptCompanyId: number | null;
}

const CompanyProfile: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname } = useLocation<ReactLocationState>();

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));
  const isLoadingOrganizations = useSelector(
    (state: { organization: { isPending: boolean } }) => state.organization.isPending
  );

  const [formValues, setFormValues] = useState<FormValuesProps>({ organizationId: null, rptCompanyId: null });
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [selectedRptCompany, setSelectedRptCompany] = useState<ReportingCompany | null>(null);

  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);
  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);

  const [fetchedUnits, setFetchedUnits] = useState<Array<Unit>>([]);
  const [fetchedDro, setFetchedDro] = useState<Array<DriverReportOption>>([]);
  const [fetchedNro, setFetchedNro] = useState<Array<NotificationRecipientOption>>([]);
  const [fetchedDomains, setFetchedDomains] = useState<Array<PermittedDomainOption>>([]);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(formValues.organizationId);

  const organizationRef = useAutofocus();

  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.companyAdministration }));
  }, [dispatch]);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname, fromTab: 'companyProfile' });
  }, [history, pathname]);

  // Fetch all rpt companies of a carrier when a carrier is selected to populate the autocomplete
  useEffect(() => {
    if (!isIbcOrThirdPartySelected && formValues.organizationId) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganization(Functions.companyAdministration, formValues.organizationId)
        .then((results) => {
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
  }, [dispatch, formValues.organizationId, handleError, isIbcOrThirdPartySelected, t]);

  // Initialize selectedOrganization if there is only one company available to the user
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setSelectedOrganization(organizations[0]);
      setFormValues({ organizationId: organizations[0].id, rptCompanyId: null });
    }
  }, [organizations]);

  // Initialize selectedCompany if there is only one company available to the user
  useEffect(() => {
    if (fetchedRptCompanies && fetchedRptCompanies.length === 1) {
      setSelectedRptCompany(fetchedRptCompanies[0]);
    }
  }, [fetchedRptCompanies]);

  const sortOrganization = useCallback(
    (a: Organization, b: Organization): number => {
      return sortOrganizations(a, b, i18n);
    },
    [i18n]
  );

  const sortCompanies = useCallback(
    (a: ReportingCompany, b: ReportingCompany): number => {
      return sortRptCompanies(a, b, i18n);
    },
    [i18n]
  );

  const handleSearch = useCallback(
    async (orgId: number, companyId: number | null) => {
      setIsLoadingResults(true);

      await Units.getUnitsByCompanyId(Functions.companyAdministration, orgId, companyId)
        .then((results) => {
          setFetchedUnits(results);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });

      await DriverReportOptions.getDriverReportOptionsByCompanyId(
        orgId,
        companyId
          ? {
              reportingCompanyId: companyId,
            }
          : undefined
      )
        .then((results) => {
          setFetchedDro(results);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });

      await NotificationRecipientOptions.getNotificationRecipientOptionsByCompanyId(Functions.companyAdministration, {
        organizationId: orgId,
        reportingCompanyId: companyId ? companyId : undefined,
      })
        .then((results) => {
          setFetchedNro(results);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });

      await PermittedDomains.getPermittedDomainByCompanyId(Functions.companyAdministration, orgId, companyId)
        .then((results) => {
          setFetchedDomains(results);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });

      setIsLoadingResults(false);
    },
    [handleError]
  );

  useEffect(() => {
    if (formValues.organizationId) {
      handleSearch(formValues.organizationId, selectedRptCompany ? selectedRptCompany.id : null);
    }
  }, [fetchedRptCompanies?.length, formValues.organizationId, handleSearch, selectedRptCompany]);

  const handleOrgAutocompleteChange = (value: Organization | null) => {
    setFormValues({ organizationId: value ? value.id : null, rptCompanyId: null });
    setSelectedOrganization(value);
    setSelectedRptCompany(null);
    clearResults();

    if (value && value.organizationType !== 'Carrier') {
      handleSearch(value.id, null);
    }
  };

  const handleRptAutocompleteChange = (value: ReportingCompany | null) => {
    setFormValues({ ...formValues, rptCompanyId: value ? value.id : null });
    setSelectedRptCompany(value);
    clearResults();

    if (value && formValues.organizationId) {
      handleSearch(formValues.organizationId, value.id);
    }
  };

  const clearResults = () => {
    setFetchedUnits([]);
    setFetchedDro([]);
    setFetchedNro([]);
    setFetchedDomains([]);
  };

  return (
    <>
      <Card elevation={3} data-testid="companyProfileCard">
        <CardContent>
          <Grid container>
            <Grid item container xs={12} spacing={2} padding={2}>
              <Grid item container md={10} lg={10} xl={10} spacing={1}>
                <Grid item md={12} lg={11} xl={8}>
                  <Autocomplete
                    fullWidth
                    loading={isLoadingOrganizations}
                    loadingText={t('loading')}
                    noOptionsText={t('search.noOption')}
                    value={
                      selectedOrganization
                        ? selectedOrganization
                        : formValues.organizationId !== null
                        ? organizations.find((oneOrg: Organization) => oneOrg.id === formValues.organizationId)
                        : null
                    }
                    id="organizationId"
                    data-testid="organizationId"
                    options={organizations.sort((a: Organization, b: Organization) => sortOrganization(a, b))}
                    getOptionLabel={(option: Organization) =>
                      i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                    }
                    renderInput={(params) => (
                      <DashCustomInput
                        {...params}
                        label={t('userAdmin.companyProf.parent')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        sx={{ paddingRight: 0 }}
                        placeholder={t('userAdmin.companyProf.parentCompPlaceholder')}
                        InputProps={{
                          ...params.InputProps,
                          inputRef: organizationRef,
                          endAdornment: (
                            <>
                              {isLoadingOrganizations ? (
                                <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    onChange={(_, value) => handleOrgAutocompleteChange(value)}
                  />
                </Grid>
                <Grid item md={12} lg={11} xl={8}>
                  {!isIbcOrThirdPartySelected ? (
                    <Autocomplete
                      fullWidth
                      loading={isLoadingRpt}
                      loadingText={t('loading')}
                      noOptionsText={t('search.noOption')}
                      value={
                        selectedRptCompany !== null && fetchedRptCompanies
                          ? fetchedRptCompanies.find((oneRpt) => oneRpt.id === selectedRptCompany.id)
                          : null
                      }
                      id="rptCompanyId"
                      data-testid="rptCompanyId"
                      options={
                        fetchedRptCompanies?.sort((a: ReportingCompany, b: ReportingCompany) => sortCompanies(a, b)) ??
                        []
                      }
                      getOptionLabel={(option: ReportingCompany) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('userAdmin.companyProf.rptComp')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          sx={{ paddingRight: 0 }}
                          placeholder={t('userAdmin.companyProf.rptCompPlaceholder')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingRpt ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleRptAutocompleteChange(value)}
                      disabled={isIbcOrThirdPartySelected || !formValues.organizationId}
                    />
                  ) : (
                    /* Dummy field for company when IBC or 3rd party */
                    <DashCustomInput
                      fullWidth
                      id="companyHolderIbc3rdPart"
                      value={
                        selectedOrganization
                          ? i18n.language.startsWith('en')
                            ? selectedOrganization.nameEn
                            : selectedOrganization.nameFr
                          : formValues.organizationId !== null
                          ? i18n.language.startsWith('en')
                            ? organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameEn
                            : organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameFr
                          : null
                      }
                      label={t('userAdmin.companyProf.rptComp')}
                      labelGridSize={3}
                      fieldGridSize={8}
                      variant="outlined"
                      color="secondary"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              {selectedOrganization && (isIbcOrThirdPartySelected || selectedRptCompany) && !isLoadingResults && (
                <CompanyProfileResults
                  selectedOrg={selectedOrganization}
                  selectedRptCompany={selectedRptCompany ? selectedRptCompany : null}
                  units={fetchedUnits}
                  driverRepOpt={fetchedDro}
                  permittedDomains={fetchedDomains}
                  recipients={fetchedNro}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default CompanyProfile;
