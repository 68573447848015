import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import ibcLogo from '../Medias/IBC_LOGO_FULL_TRANSPARENT.png';
import theme from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.secondary.main,
      borderTop: `8px solid ${theme.palette.primary.main}`,
      height: '58px',
    },
    linkButton: {
      color: theme.palette.white.main,
      fontWeight: 500,
      marginRight: theme.spacing(2),
      '@media print': {
        display: 'none',
      },
    },
  })
);

const Footer: FC<RouteComponentProps> = ({ location, history }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const handleClickFromFooter = (route: string) => {
    if (location.pathname !== route) {
      if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
        alert('{ "route": "' + route + '" }');
      } else {
        history.push(route);
      }
    }
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div style={{ position: 'fixed', zIndex: 3 }} className={classes.toolbar}>
      <Grid container justifyContent="space-between" paddingLeft={'30px'} paddingRight={'30px'} height="100%">
        <Grid item container xs={1} alignItems="center" paddingBottom={1} paddingTop={1}>
          <img src={ibcLogo} alt="logoIBC" height={30} />
        </Grid>
        <Grid item container xs={11} alignItems="center" justifyContent="flex-end" columnGap={2}>
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.white.main,
                marginRight: theme.spacing(2),
                fontWeight: 500,
                fontSize: !isSmallScreen ? '11pt' : i18n.language.startsWith('en') ? '9pt' : '8pt',
                '@media print': {
                  fontSize: '9pt',
                  color: 'black',
                },
              }}
            >
              {t('footer.copyright', {
                fullYear: new Date().getFullYear(),
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              size={isSmallScreen ? 'medium' : 'small'}
              sx={{ fontSize: !isSmallScreen ? '11pt' : i18n.language.startsWith('en') ? '9pt' : '8pt' }}
              className={classes.linkButton}
              variant="text"
              onClick={() => handleClickFromFooter('/terms')}
              data-testid="termsButton"
            >
              {t('footer.terms')}
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              fullWidth
              size={isSmallScreen ? 'medium' : 'small'}
              sx={{ fontSize: !isSmallScreen ? '11pt' : i18n.language.startsWith('en') ? '9pt' : '8pt' }}
              className={classes.linkButton}
              variant="text"
              onClick={() => handleClickFromFooter('/privacy')}
              data-testid="privacyButton"
            >
              {t('footer.privacy')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              size={isSmallScreen ? 'medium' : 'small'}
              sx={{ fontSize: !isSmallScreen ? '11pt' : i18n.language.startsWith('en') ? '9pt' : '8pt' }}
              className={classes.linkButton}
              variant="text"
              onClick={() => handleClickFromFooter('/disclaimer')}
              data-testid="disclaimerButton"
            >
              {t('footer.disclaimer')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              size={isSmallScreen ? 'medium' : 'small'}
              sx={{ fontSize: !isSmallScreen ? '11pt' : i18n.language.startsWith('en') ? '9pt' : '8pt' }}
              className={classes.linkButton}
              variant="text"
              onClick={() => handleClickFromFooter('/about')}
              data-testid="aboutusButton"
            >
              {t('footer.about')}
            </Button>
          </Grid> */}
          <Grid item>
            <Button
              fullWidth
              size={isSmallScreen ? 'medium' : 'small'}
              sx={{ fontSize: !isSmallScreen ? '11pt' : i18n.language.startsWith('en') ? '9pt' : '8pt' }}
              className={classes.linkButton}
              variant="text"
              onClick={() => handleClickFromFooter('/contactUs')}
              data-testid="contactUsButton"
            >
              {t('footer.contactUs')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Footer);
