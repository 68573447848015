import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';

import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { ReportingCompanies } from '../../axios';
import { searchCorrectionReport } from '../../Axios/CorrectionReportAxios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportAsyncCsv from '../../Components/ExportAsyncCsv';
import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { DATE_FORMAT, DATE_MASK, PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime, getDateFromNow, isValidDate } from '../../Helpers/DateHelper';
import { sortOrganizations, sortRptCompanies } from '../../Helpers/SortHelpers';
import useAutofocus from '../../Hooks/UseAutofocus';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../Slices/OrganizationSlice';
import theme from '../../theme';
import { Functions, Organization, ReactLocationState, ReportingCompany } from '../../Types';
import {
  CorrectionReportResponseCsvType,
  CorrectionReportResponseType,
  CorrectionReportSearchFormValueType,
  CorrectionReportSearchType,
} from '../../Types/CorrectionReportTypes';
import { PaginationQuery } from '../../Types/PaginationQuery';
import CorrectionReportResults from './CorrectionReportResults';

const CorrectionReportSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname } = useLocation<ReactLocationState>();

  const csvLink = useRef<any>(null);

  const [companies, setCompanies] = useState<Array<ReportingCompany>>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);

  const [pageNo, setPageNo] = useState<number | null>(null);
  const [resultCount, setResultCount] = useState<number>(0);
  const [results, setResults] = useState<Array<CorrectionReportResponseType> | null>(null);
  const [resultsCsv, setResultsCsv] = useState<Array<CorrectionReportResponseCsvType> | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));
  const isLoadingOrganizations = useSelector(
    (state: { organization: { isPending: boolean } }) => state.organization.isPending
  );

  // company and org are outside formValues because it requires a default value after a fetch
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<ReportingCompany | null>(null);

  const todayDate = new Date();
  const [formValues, setFormValues] = useState<
    Omit<CorrectionReportSearchFormValueType, 'organizationId' | 'reportingCompanyId'>
  >({
    fromDate: todayDate,
    toDate: todayDate,
  });

  const [fileName, setFilename] = useState<string | undefined>();

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(selectedOrg ? selectedOrg.id : null);
  const [errorFromDate, setErrorFromDate] = useState<boolean>(false);
  const [errorToDate, setErrorToDate] = useState<boolean>(false);

  const organizationRef = useAutofocus();

  const csvHeaders = [
    {
      key: 'company',
      label: t('correctionReport.company'),
    },
    {
      key: 'correctionDate',
      label: t('correctionReport.correctionDate'),
    },
    {
      key: 'correctorUserName',
      label: t('correctionReport.correctorUserName'),
    },
    {
      key: 'fieldName',
      label: t('correctionReport.fieldName'),
    },
    {
      key: 'dln',
      label: t('correctionReport.DLN'),
    },
    {
      key: 'policyId',
      label: t('correctionReport.policyId'),
    },
    {
      key: 'policyEffectiveDate',
      label: t('correctionReport.policyEffectiveDate'),
    },
    {
      key: 'claimId',
      label: t('correctionReport.claimId'),
    },
    {
      key: 'accidentDate',
      label: t('correctionReport.accidentDate'),
    },
    {
      key: 'oldValue',
      label: t('correctionReport.oldValue'),
    },
    {
      key: 'newValue',
      label: t('correctionReport.newValue'),
    },
  ];

  // Fetch organisation by user access
  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.correctionReport }));
  }, [dispatch]);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  const sortOrganization = useCallback(
    (a: Organization, b: Organization): number => {
      return sortOrganizations(a, b, i18n);
    },
    [i18n]
  );

  // Loads the UserFunctionCompanies
  useEffect(() => {
    if (selectedOrg) {
      setIsLoadingCompanies(true);
      ReportingCompanies.getByOrganization(Functions.correctionReport, selectedOrg.id)
        .then((results) => {
          setCompanies(results);
          setIsLoadingCompanies(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingCompanies(false);
        });
    }
  }, [dispatch, handleError, selectedOrg, t]);

  // Initialize selectedCompany if there is only one company available to the user
  useEffect(() => {
    if (companies && companies.length === 1) {
      setSelectedCompany(companies[0]);
    }
  }, [companies]);

  const isFormValid = (): boolean => {
    let error = false;

    if (!isValidDate(formValues.fromDate)) {
      setErrorFromDate(true);
      error = true;
    } else {
      setErrorFromDate(false);
    }

    if (!isValidDate(formValues.toDate)) {
      setErrorToDate(true);
      error = true;
    } else {
      setErrorToDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSearch = () => {
    if (isFormValid()) {
      setIsLoadingResults(true);
      const searchParams: CorrectionReportSearchType = {
        ...formValues,
        organizationId: selectedOrg ? selectedOrg.id : null,
        reportingCompanyId: selectedCompany ? selectedCompany.id : null,
        fromDate: formValues.fromDate
          ? new Date(
              formValues.fromDate.getFullYear(),
              formValues.fromDate.getMonth(),
              formValues.fromDate.getDate(),
              0,
              0,
              0,
              0
            )
          : null,
        toDate: formValues.toDate
          ? new Date(
              formValues.toDate.getFullYear(),
              formValues.toDate.getMonth(),
              formValues.toDate.getDate(),
              23,
              59,
              59,
              59
            )
          : null,
      };
      const paginationQuery: PaginationQuery = {
        pageSize: PAGINATION_PAGESIZE,
        pageNumber: (pageNo ?? 0) + 1,
      };
      searchCorrectionReport(searchParams, paginationQuery)
        .then((results) => {
          setResults(results.data);
          setResultCount(results.count);
          setIsLoadingResults(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingResults(false);
        });
    }
  };

  const fetchCSVData = () => {
    setIsLoadingResults(true);
    const searchParams: CorrectionReportSearchType = {
      ...formValues,
      organizationId: selectedOrg ? selectedOrg.id : null,
      reportingCompanyId: selectedCompany ? selectedCompany.id : null,
      fromDate: formValues.fromDate
        ? new Date(
            formValues.fromDate.getFullYear(),
            formValues.fromDate.getMonth(),
            formValues.fromDate.getDate(),
            0,
            0,
            0,
            0
          )
        : null,
      toDate: formValues.toDate
        ? new Date(
            formValues.toDate.getFullYear(),
            formValues.toDate.getMonth(),
            formValues.toDate.getDate(),
            23,
            59,
            59,
            59
          )
        : null,
    };
    searchCorrectionReport(searchParams)
      .then((fetchedResults) => {
        const result = fetchedResults.data.map((oneResult: CorrectionReportResponseType) => ({
          company: i18n.language.startsWith('en')
            ? oneResult.company
              ? oneResult.company.nameEn
              : oneResult.organization.nameEn
            : oneResult.company
            ? oneResult.company.nameFr
            : oneResult.organization.nameFr,
          correctionDate: formatDateToLocalTime(oneResult.correctionDate, true),
          correctorUserName: oneResult.correctorUserName,
          fieldName: `${t(`correctionReport.${oneResult.correction.fieldName}`)}`,
          dln: oneResult.dln,
          policyId: oneResult.policyId,
          policyEffectiveDate: oneResult.policyEffectiveDate
            ? formatDateToLocalTime(oneResult.policyEffectiveDate, false)
            : null,
          claimId: oneResult.claimId,
          accidentDate: oneResult.accidentDate ? formatDateToLocalTime(oneResult.accidentDate, false) : null,
          oldValue: i18n.language.startsWith('en')
            ? oneResult.correction.oldValue.valueEn
            : oneResult.correction.oldValue.valueFr,
          newValue: i18n.language.startsWith('en')
            ? oneResult.correction.newValue.valueEn
            : oneResult.correction.newValue.valueFr,
        }));
        setResultsCsv(result);
        setIsLoadingResults(false);
        setFilename(`${t('menu.correctionReport')}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`);
        if (csvLink.current) csvLink.current.link.click(); // call csvLink
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingResults(false);
      });
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (pageNo === 0) {
      handleSearch();
    } else {
      setPageNo(0);
    }
  };

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPageNo(newPage);
  }, []);

  // re-fetch results for new page
  useEffect(() => {
    if (pageNo !== null) handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const sortCompanies = useCallback(
    (a: ReportingCompany, b: ReportingCompany): number => {
      return sortRptCompanies(a, b, i18n);
    },
    [i18n]
  );

  const handleAutocompleteOrgChange = (value: Organization | null) => {
    setSelectedOrg(value);
    setSelectedCompany(null);
  };

  const handleAutocompleteCompanyChange = (value: ReportingCompany | null) => {
    setSelectedCompany(value);
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  const resetFormValues = () => {
    setFormValues({
      fromDate: todayDate,
      toDate: todayDate,
    });
    setResultCount(0);
    setSelectedOrg(null);
    setSelectedCompany(null);
    setResults(null);
    setResultsCsv(null);

    // clear errors
    setErrorFromDate(false);
    setErrorToDate(false);
  };

  const handleClearFields = () => {
    resetFormValues();
  };

  return (
    <>
      <Card elevation={3} data-testid="ninetyDaysSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} xs={10} lg={10} xl={10}>
                  <Grid item md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingOrganizations}
                      noOptionsText={t('search.noOption')}
                      value={
                        selectedOrg !== null && organizations.length > 0
                          ? organizations.find((oneComp) => oneComp.code === selectedOrg.code)
                          : null
                      }
                      id="organization"
                      options={organizations.sort((a: Organization, b: Organization) => sortOrganization(a, b))}
                      getOptionLabel={(option: Organization) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          autoFocus
                          label={t('usageReport.organization')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: organizationRef,
                            endAdornment: (
                              <>
                                {isLoadingOrganizations ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteOrgChange(value)}
                      disableClearable={organizations && organizations.length === 1}
                      data-testid="organization"
                    />
                  </Grid>
                  <Grid item md={12} lg={11} xl={8}>
                    {!isIbcOrThirdPartySelected ? (
                      <Autocomplete
                        fullWidth
                        noOptionsText={t('search.noOption')}
                        value={
                          selectedCompany !== null && companies.length > 0
                            ? companies.find((oneComp) => oneComp.code === selectedCompany.code)
                            : null
                        }
                        id="company"
                        options={companies.sort((a: ReportingCompany, b: ReportingCompany) => sortCompanies(a, b))}
                        getOptionLabel={(option: ReportingCompany) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('ninetyReport.rptComp')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingCompanies ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) => handleAutocompleteCompanyChange(value)}
                        loading={isLoadingCompanies}
                        loadingText={t('loading')}
                        disableClearable={companies && companies.length === 1}
                        data-testid="company"
                        disabled={!selectedOrg}
                      />
                    ) : (
                      /* Dummy field for company when IBC or 3rd party */
                      <DashCustomInput
                        fullWidth
                        id="companyHolderIbc3rdPart"
                        value={
                          selectedOrg !== null && organizations.length > 0
                            ? i18n.language.startsWith('en')
                              ? organizations.find((oneOrg) => oneOrg.id === selectedOrg.id)?.nameEn
                              : organizations.find((oneOrg) => oneOrg.id === selectedOrg.id)?.nameFr
                            : ''
                        }
                        label={t('ninetyReport.rptComp')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                        disabled={!selectedOrg}
                      />
                    )}
                  </Grid>

                  {/* Datepickers */}
                  <Grid container item md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography sx={{ fontWeight: 700, display: 'inline' }}>
                          {t('correctionReport.dateRange')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} paddingRight={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.fromDate}
                            onChange={(newValue) => handleDatePickerChange('fromDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'fromDatePicker',
                                  placeholder: t('datepickerPlaceholderFrom').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                helperText={(errorFromDate && t('reportHistory.invalidDate')) || (errorToDate && ' ')}
                                error={errorFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} paddingLeft={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.toDate}
                            onChange={(newValue) => handleDatePickerChange('toDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'toDatePicker',
                                  placeholder: t('datepickerPlaceholderTo').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                helperText={(errorToDate && t('reportHistory.invalidDate')) || (errorFromDate && ' ')}
                                error={errorToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END DatePicker */}

                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button fullWidth type="submit" variant="contained" id="btnSearch" data-testid="btnSearch">
                          {t('userAdmin.lookup')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={handleClearFields}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('search.clearFields')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <ExportAsyncCsv
                      csvLink={csvLink}
                      hasSearchResult={resultCount > 0}
                      csvData={resultsCsv ? resultsCsv : []}
                      csvHeaders={csvHeaders}
                      fileName={fileName}
                      onClick={() => fetchCSVData()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {results && results.length > 0 && (
                <Grid item container xs={12} padding={2}>
                  <Grid item container xs={12}>
                    <Typography fontWeight={700}>{`${resultCount} ${
                      resultCount === 1 ? t('ninetyReport.oneResultFound') : t('ninetyReport.manyResultsFound')
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <CorrectionReportResults
                      fetchedResults={results}
                      handleChangePage={handleChangePage}
                      count={resultCount}
                      pageNo={pageNo ?? 0}
                    />
                  </Grid>
                </Grid>
              )}
              {results && results.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('correctionReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default CorrectionReportSearch;
