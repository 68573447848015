import { FC, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import theme from '../theme';
import { Notification } from '../Types';

const useStyles = makeStyles(() =>
  createStyles({
    iconAlert: {
      width: '100%',
      '& .MuiAlert-icon': {
        fontSize: 26,
      },
      borderStyle: 'solid',
      borderWidth: '2px',
      alignItems: 'center',
      marginBottom: theme.spacing(0),
      '& .MuiAlert-action': { padding: '0', marginRight: '0' },
      backgroundColor: 'black',
      color: 'white',
      borderColor: '#000000',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3.75),
      paddingRight: theme.spacing(2.75),
    },
  })
);

interface SnackProps {
  message: Array<Notification>;
  duration?: 'short' | 'long';
  onClose?: () => void;
}

const Snack: FC<SnackProps> = ({ message, duration, onClose }) => {
  const [open, setOpen] = useState(true);

  const vertical = 'bottom';
  const horizontal = 'left';

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const classes = useStyles();
  return (
    <>
      {message
        .filter((n) => n.code !== 0)
        .map((notification, index) => (
          <Snackbar
            open={open}
            autoHideDuration={duration && duration === 'long' ? 12000 : 6000}
            anchorOrigin={{ vertical, horizontal }}
            key={index}
            sx={{ marginBottom: theme.spacing(8), marginLeft: theme.spacing(0.75) }}
            onClose={onClose}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12} alignItems="center">
                <Alert
                  elevation={2}
                  className={classes.iconAlert}
                  severity={notification.severity}
                  data-testid="errorMessage"
                  icon={false}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="medium"
                      onClick={handleClose}
                      sx={{ marginLeft: theme.spacing(2) }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography>{notification.message}</Typography>
                </Alert>
              </Grid>
            </Grid>
          </Snackbar>
        ))}
    </>
  );
};

export default Snack;
