import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';

import { AppFunctions } from '../axios';
import { AppFunction, AppFunctionFactory } from '../Types';

// Adapter
const AppFunctionAdapter = createEntityAdapter<AppFunction>({
  // Assume IDs are stored in a field id
  selectId: (appFunction) => appFunction.id,
  // Keep the "all IDs" array sorted based on SortOrder
  sortComparer: (a, b) => {
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    return 0;
  },
});

// Selectors
// Get default selects that comes with entityAdapter
const AppFunctionSelectors = AppFunctionAdapter.getSelectors((state: { appFunction: any }) => state.appFunction);

// map selectors if you want to have them called differently
export const { selectId: getAppFunctionById } = AppFunctionAdapter;

export const GetAllAppFunctions = createSelector(
  [AppFunctionSelectors.selectAll],
  (appFunctions: Array<AppFunction>) => {
    return appFunctions;
  }
);

//Test helper
export const CreateAppFunctionTestState = (isPending: boolean, errorCode = '', errorMessage = '') => {
  const error: SerializedError | null = errorMessage !== '' ? { code: errorCode, message: errorMessage } : null;

  const appFunctions = [
    AppFunctionFactory(1, 'Rapport Conducteur', 'Driver Report', 1),
    AppFunctionFactory(2, 'Rapport Véhicule', 'Vehicle Report', 2),
    AppFunctionFactory(3, 'Rapport 90 Jours', '90 Days Report', 3),
    AppFunctionFactory(4, 'Corrections', 'Corrections', 4),
    AppFunctionFactory(5, 'Company Profile', 'Company Profile', 5),
    AppFunctionFactory(6, 'Administration Utilisateur', 'User Administration', 6),
  ];

  const AppFunctionState = AppFunctionAdapter.getInitialState({ isPending, error });
  appFunctions.forEach((appFunction) => {
    AppFunctionState.ids.push(appFunction.id);
    AppFunctionState.entities[appFunction.id] = appFunction;
  });

  return { appFunction: AppFunctionState };
};

// extra reducers - async thunk
export const getAppFunctions = createAsyncThunk('/AppFunctions', async () => {
  const result = await AppFunctions.list();

  if (result) {
    return result;
  } else {
    return [];
  }
});

// Slice
const initialState: { isPending: boolean; error: SerializedError | null } = { isPending: false, error: null };

const AppFunctionSlice = createSlice({
  name: 'AppFunction',
  initialState: AppFunctionAdapter.getInitialState(initialState),
  reducers: {
    removeError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppFunctions.pending, (state) => {
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(getAppFunctions.fulfilled, (state, { payload: appFunctions }) => {
      state.isPending = false;
      if (appFunctions) {
        AppFunctionAdapter.removeAll(state);
        AppFunctionAdapter.addMany(state, appFunctions);
      }
    });
    builder.addCase(getAppFunctions.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { removeError } = AppFunctionSlice.actions;

export default AppFunctionSlice.reducer;
