import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatDateToLocalTime } from '../../../Helpers/DateHelper';
import theme from '../../../theme';
import { SdcReport } from '../../../Types';
import SdcErrorReportDownload from './SdcErrorReportDownload';

const useStyles = makeStyles(() => ({
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface SdcReportResultsProps {
  fetchedResults: Array<SdcReport>;
}

interface HeadCell {
  id: keyof SdcReport;
  label: string;
  width: number;
}

const SdcReportResults = ({ fetchedResults }: SdcReportResultsProps) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'reportingCompanyNameEn',
      label: t('sdcReport.reportingCompany'),
      width: 30,
    },
    {
      id: 'fileReceived',
      label: t('sdcReport.fileReceivedDate'),
      width: 10,
    },
    {
      id: 'fileName',
      label: t('sdcReport.file'),
      width: 23,
    },
    {
      id: 'fileStatusEn',
      label: t('sdcReport.fileStatus'),
      width: 13,
    },
    {
      id: 'accepted',
      label: t('sdcReport.accepted'),
      width: 7,
    },
    {
      id: 'rejected',
      label: t('sdcReport.rejected'),
      width: 7,
    },
  ];

  return (
    <TableContainer component={'div'} data-testid="tableContainer">
      <Table size="small" stickyHeader aria-label="table-sdc-result" sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{ width: `${headCell.width}%` }}
                data-testid={`headerCell-${headCell.id}`}
              >
                {headCell.label}
              </TableCell>
            ))}
            <TableCell align="right" sx={{ width: '10%' }}>
              {t('sdcReport.errorReport')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="tableBody">
          {fetchedResults
            .map((oneResult) => ({
              fileId: oneResult.fileID,
              company: i18n.language.startsWith('en')
                ? oneResult.reportingCompanyNameEn
                : oneResult.reportingCompanyNameFr,
              fileReceived: oneResult.fileReceived ? formatDateToLocalTime(oneResult.fileReceived, false) : '',
              file: oneResult.fileName,
              status: i18n.language.startsWith('en') ? oneResult.fileStatusEn : oneResult.fileStatusFr,
              accepted: oneResult.accepted,
              rejected: oneResult.rejected,
            }))
            .map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                  sx={{ width: '30%' }}
                  data-testid={`tableCell-company-${index}`}
                >
                  <Tooltip title={row.company} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.company}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '10%' }}
                  data-testid={`tableCell-fileReceived-${index}`}
                >
                  <Tooltip title={row.fileReceived} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.fileReceived}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '23%' }}
                  data-testid={`tableCell-fileName-${index}`}
                >
                  <Tooltip title={row.file} enterTouchDelay={0}>
                    <Typography className={classes.textCell}>{row.file}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '13%' }}
                  data-testid={`tableCell-status-${index}`}
                >
                  <Tooltip enterTouchDelay={0} title={row.status}>
                    <Typography className={classes.textCell}>{row.status}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '7%' }}
                  data-testid={`tableCell-accepted-${index}`}
                >
                  <Tooltip enterTouchDelay={0} title={row.accepted ?? ''}>
                    <Typography className={classes.textCell}>{row.accepted}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  sx={{ width: '7%' }}
                  data-testid={`tableCell-rejected-${index}`}
                >
                  <Tooltip enterTouchDelay={0} title={row.rejected ?? ''}>
                    <Typography className={classes.textCell}>{row.rejected}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right" className={classes.tableCell} sx={{ width: '10%' }}>
                  <SdcErrorReportDownload index={index} fileId={row.fileId} fileStatus={row.status} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SdcReportResults;
