import Cookies from 'js-cookie';

export const getAccessToken = () => {
  const keys = Object.keys(sessionStorage);
  const key = keys.find((k) => k.startsWith('xxuser'));
  if (key) {
    const tokenString = sessionStorage.getItem(key);
    if (tokenString) {
      const token = JSON.parse(tokenString);
      return token.access_token;
    }
  }
};

export const setAccessToken = (accessToken: string) => {
  const keys = Object.keys(sessionStorage);
  const key = keys.find((k) => k.startsWith('xxuser'));
  if (key) {
    const tokenString = sessionStorage.getItem(key);
    if (tokenString) {
      const token = JSON.parse(tokenString);
      token.access_token = accessToken;
      sessionStorage.setItem(key, token.toString());
    }
  }
};

export const getRefreshToken = () => {
  const keys = Object.keys(sessionStorage);
  const key = keys.find((k) => k.startsWith('xxuser'));
  if (key) {
    const tokenString = sessionStorage.getItem(key);
    if (tokenString) {
      const token = JSON.parse(tokenString);
      return token.refresh_token;
    }
  }
};

export const setRefreshToken = (refreshToken: string) => {
  const keys = Object.keys(sessionStorage);
  const key = keys.find((k) => k.startsWith('xxuser'));
  if (key) {
    const tokenString = sessionStorage.getItem(key);
    if (tokenString) {
      const token = JSON.parse(tokenString);
      token.refresh_token = refreshToken;
      sessionStorage.setItem(key, token.toString());
    }
  }
};

export const sessionStore = (key: string, value: string) => {
  // localStorage.setItem(key, JSON.stringify(value));
  Cookies.set(key, value, { expires: 365 }); //valid for 1 year
};

export const sessionRemoveData = (key: string) => {
  // localStorage.removeItem(key);
  Cookies.remove(key);
};

export const sessionGetData = (key: string) => {
  return Cookies.get(key);
  // const itemStr = localStorage.getItem(key);
  // // if the item doesn't exist, return null
  // if (!itemStr) {
  //   return null;
  // }

  // const item = JSON.parse(itemStr);

  // return item;
};
