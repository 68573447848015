import { requests } from '../axios';
import { AppFunction, AppFunctionFactory } from '../Types';

export const list = (): Promise<AppFunction[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<AppFunction[]>((resolve) => {
      resolve([
        AppFunctionFactory(1, 'Rapport Conducteur', 'Driver Report', 1),
        AppFunctionFactory(2, 'Rapport Véhicule', 'Vehicle Report', 2),
        AppFunctionFactory(3, 'Rapport 90 Jours', '90 Days Report', 3),
        AppFunctionFactory(4, 'Corrections', 'Corrections', 4),
        AppFunctionFactory(5, 'Company Profile', 'Company Profile', 5),
        AppFunctionFactory(6, 'Administration Utilisateur', 'User Administration', 6),
      ]);
    });
  }
  return requests.get('/Functions', {});
};
