import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FastForward } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Claims } from '../../Types';
import ClaimItem from './ClaimItem';

interface ClaimListProps {
  claims?: Array<Claims>;
  isCorrectionMode: boolean;
  reportId: string;
  operatorKey: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      height: '32pt',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
    },
    buttonHeader: {
      color: theme.palette.white.main,
      fontWeight: 700,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '16pt',
      [theme.breakpoints.down('md')]: {
        fontSize: '12pt',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '14pt',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16pt',
      },
    },
    noneContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.dark,
    },
    expandAll: {
      transform: 'scale(1)',
      color: theme.palette.white.main,
    },
    collapseAll: {
      transform: 'rotate(90deg)',
      color: theme.palette.white.main,
    },
  })
);

const ClaimList: React.FC<ClaimListProps> = ({ claims = [], isCorrectionMode, reportId, operatorKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [allExpanded, setAllExpanded] = useState<boolean>(false);
  const accordionCount = claims.length;

  const handleExpandAll = () => {
    setAllExpanded(!allExpanded);
  };

  const handleAccordionStateChanged = useCallback(() => {
    const getCollapsedAccordionSections = document.querySelectorAll('#claimList [aria-expanded="true"]');
    setAllExpanded(accordionCount === getCollapsedAccordionSections.length);
  }, [accordionCount]);

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center" className={classes.header}>
        <Grid item>
          <Typography data-testid="claimsheader" className={classes.title}>
            {t('report.claim.header')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleExpandAll} data-testid="btnExpandAll">
            <FastForward className={clsx(!allExpanded && classes.expandAll, allExpanded && classes.collapseAll)} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container item rowSpacing={0.5} padding={0.5} data-testid="claims" xs={12} id="claimList">
        {claims &&
          claims.map((c, index) => {
            return (
              <Grid key={index} data-testid="claims" item xs={12}>
                <ClaimItem
                  claim={c}
                  expand={allExpanded}
                  isCorrectionMode={isCorrectionMode}
                  onAccordionStateChanged={handleAccordionStateChanged}
                  reportId={reportId}
                  operatorKey={operatorKey}
                />
              </Grid>
            );
          })}
        {!claims ||
          (claims.length === 0 && (
            <Grid item container xs={12} className={classes.noneContainer}>
              <Typography sx={{ fontWeight: 700 }}>{t('report.claim.none')}</Typography>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};
export default ClaimList;
