import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ClientSettingAxios } from '../../../axios';
import ConfirmDialog, { ConfirmDialogProps } from '../../../Components/ConfirmDialog';
import Loader from '../../../Components/Loader';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { ClientSetting, ModeEnum } from '../../../Types';
import { CreateProps } from './Create';
import CreateSetting from './Create';

interface ClientSettingProps {
  settings: Array<ClientSetting>;
  fetchClientSettings: () => void;
}

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.white.main,
    marginBottom: '6px',
    padding: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: theme.palette.secondary.light,
    marginBottom: '6px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonText: {
    fontWeight: 700,
  },
}));

const Result: FC<ClientSettingProps> = ({ settings, fetchClientSettings }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const [listSettings, setListSettings] = useState<Array<ClientSetting>>(settings);
  const [CreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [createProps, setCreateProps] = useState<CreateProps | undefined>(undefined);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | undefined>(undefined);

  useEffect(() => {
    setListSettings(settings);
  }, [settings]);

  const handleAddClick = () => {
    setCreateProps({
      mode: ModeEnum.Create,
      onCreated: handleOnCreated,
      onUpdated: handleOnUpdated,
      onClose: handleOnDialogClose,
    });
    setCreateDialogOpen(true);
  };

  const handleOnCreated = () => {
    fetchClientSettings();
    setCreateDialogOpen(false);
    setCreateProps(undefined);
  };

  const handleOnUpdated = () => {
    fetchClientSettings();
    setCreateDialogOpen(false);
    setCreateProps(undefined);
  };

  const handleOnDialogClose = () => {
    setCreateDialogOpen(false);
    setCreateProps(undefined);
  };

  const handleRenameClick = (result: ClientSetting) => {
    setCreateProps({
      item: {
        id: result.id,
        clientId: result.clientId,
        organizationId: result.organization.id,
        rptCompanyId: result.reportingCompany?.id ?? null,
        userId: result.user?.id ?? null,
        isVendor: result.isVendor,
      },
      mode: ModeEnum.Update,
      onCreated: handleOnCreated,
      onUpdated: handleOnUpdated,
      onClose: handleOnDialogClose,
    });
    setCreateDialogOpen(true);
  };

  const handleDeleteClick = (item: ClientSetting) => {
    setConfirmDialogProps({
      id: item.id,
      isDelete: true,
      title: t('settings.clientSettings.confirmDeleteSettingDialogTitle'),
      contentText: `${t('settings.clientSettings.confirmDeleteSetting', {
        ressource: i18n.language.startsWith('fr') ? item.organization.nameFr : item.organization.nameEn,
      })}`,
      onAction: handleOnDeleteConfirm,
    });
    setConfirmDialogOpen(true);
  };

  const handleOnDeleteConfirm = (confirm: boolean, id?: number) => {
    if (confirm) {
      setLoading(true);
      ClientSettingAxios.delete(id!)
        .then((result) => {
          setListSettings(listSettings.filter((x) => x.id !== id));
          dispatch(
            addNotification(
              200,
              'success',
              t('settings.clientSettings.deleted', {
                ressource: i18n.language.startsWith('fr') ? result.organization.nameFr : result.organization.nameEn,
              })
            )
          );
        })
        .catch(() => {
          dispatch(addNotification(500, 'error', t('errorMessage.error500')));
        })
        .finally(() => {
          setConfirmDialogOpen(false);
          setConfirmDialogProps(undefined);
          setLoading(false);
        });
    } else {
      setConfirmDialogOpen(false);
      setConfirmDialogProps(undefined);
    }
  };

  return (
    <>
      <Grid container spacing={2} paddingTop={2} data-testid="parentGridResults">
        <Grid item container xs={12} sx={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
          <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
            <Grid item container xs={10} spacing={2}>
              <Grid item xs={4}>
                <Typography align="left" sx={{ fontWeight: 700 }}>
                  {t('settings.clientSettings.organization')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="left" sx={{ fontWeight: 700 }}>
                  {t('settings.clientSettings.company')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="left" sx={{ fontWeight: 700 }}>
                  {t('settings.clientSettings.clientId')}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="left" sx={{ fontWeight: 700 }}>
                  {t('settings.clientSettings.isVendor')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid item container xs={12} data-testid="settingsList">
            {listSettings &&
              listSettings.map((setting, index) => (
                <Grid key={index} item container xs={12} alignItems="center" className={classes.tableRow}>
                  <Grid item container xs={10} spacing={2}>
                    <Grid item xs={4}>
                      <Typography align="left">
                        {i18n.language.startsWith('fr') ? setting.organization.nameFr : setting.organization.nameEn}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography align="left">
                        {i18n.language.startsWith('fr')
                          ? setting.reportingCompany?.nameFr || 'N/A'
                          : setting.reportingCompany?.nameEn || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography align="left">{setting.clientId}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align="left">{setting.isVendor ? 'Vendor' : 'Non-vendor'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        fullWidth
                        className={classes.buttonText}
                        variant="text"
                        color="primary"
                        id={`btnRename${index}`}
                        onClick={() => handleRenameClick(setting)}
                        endIcon={<ArrowRightIcon />}
                        data-testid={`btnRename${index}`}
                      >
                        {t('settings.clientSettings.btnRename')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        className={classes.buttonText}
                        variant="text"
                        id={setting.disabled ? `btnRestore${index}` : `btnDelete${index}`}
                        endIcon={<ArrowRightIcon />}
                        color={setting.disabled ? 'white' : 'primary'}
                        data-testid={`btnDelete${index}`}
                        onClick={() => handleDeleteClick(setting)}
                      >
                        {setting.disabled
                          ? t('settings.clientSettings.btnRestore')
                          : t('settings.clientSettings.btnDelete')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            <Grid item container xs={12} alignItems="center" className={classes.tableRow}>
              <Button
                className={classes.buttonText}
                variant="text"
                id="btnAdd"
                onClick={() => handleAddClick()}
                endIcon={<ArrowRightIcon />}
                data-testid="btnAdd"
              >
                {t('settings.clientSettings.btnAddMapping')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {CreateDialogOpen && createProps && <CreateSetting {...createProps} />}
      {confirmDialogOpen && confirmDialogProps && <ConfirmDialog {...confirmDialogProps} />}
      <Loader open={loading} />
    </>
  );
};

export default Result;
