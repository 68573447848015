import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: PaletteColor;
    accentDark: PaletteColor;
    accentLight: PaletteColor;
    white: PaletteColor;
  }

  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    accentDark?: PaletteColorOptions;
    accentLight?: PaletteColorOptions;
    white?: PaletteColorOptions;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

// Update the IconButton's color prop options
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

// Update the AppBar's color prop options
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    white: true;
  }
}

// Update the Checkbox's color prop options
declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    white: true;
  }
}

// Update the CircularProgess's color prop options
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true;
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#882345',
      dark: '#000000',
    },
    secondary: {
      main: '#54565b',
      light: '#f3f3f3',
    },
    tertiary: {
      main: '#41748e',
      contrastText: '#FFF',
    },
    accentDark: {
      main: '#7ca7ad',
    },
    accentLight: {
      main: '#cedfd7',
      light: '#e7efeb',
    },
    white: {
      main: '#fff',
    },
    warning: {
      main: '#FF9800',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: '#000000DE',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      contrastText: '#FFF',
    },
    info: {
      main: '#34B0BE',
      dark: '#236D75',
      light: '#61E4F2',
      contrastText: '#FFF',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrastText: '#FFF',
    },
    text: {
      primary: '#54565b',
      secondary: '#882345',
      disabled: '#b5bbc7',
    },
  },
});

theme.components = {
  MuiTab: {
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        textTransform: 'none',
        fontWeight: 700,
      },
      endIcon: {
        marginLeft: 0,
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingTop: theme.spacing(18),
        height: '100%',
        maxWidth: '1648px !important',
        minWidth: '1080px',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        boxShadow: undefined,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        backgroundColor: theme.palette.white.main,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        flexGrow: 1,
        textAlign: 'left',
        verticalAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'end',
        padding: theme.spacing(2),
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperScrollPaper: {
        maxWidth: 'none',
        width: '600px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: theme.palette.white.main,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderStyle: 'dotted',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.main,
        },
        borderRadius: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        minWidth: '90%',
        paddingRight: '15px',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h5: {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
      h4: {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
      h3: {
        color: theme.palette.primary.main,
      },
      h2: {
        color: theme.palette.primary.main,
      },
      h1: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderSpacing: `0px ${theme.spacing(0.5)}`,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontWeight: 700,
      },
      head: {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.white.main,
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&.Mui-active ': {
          color: theme.palette.white.main,
        },
        '&:hover': {
          color: theme.palette.white.main,
        },
        '&:focus': {
          color: theme.palette.accentLight.main,
        },
      },
      icon: {
        color: 'inherit !important',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      // root: {
      //   backgroundColor: theme.palette.secondary.main,
      //   color: theme.palette.white.main,
      // },
      toolbar: {
        minHeight: '34px!important',
      },
      displayedRows: {
        margin: '6px 16px',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        border: '0px',
        boxShadow: 'none',
        '&.MuiAccordion-root.Mui-expanded': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.secondary.light,
        borderBottom: `1px solid #e0e0e0`,
        paddingTop: theme.spacing(1),
        minHeight: '40px',
        height: '32pt',
        flexDirection: 'row-reverse',
        padding: '8px 10px 8px 10px',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '&.MuiAccordionSummary-root.Mui-expanded': {
          minHeight: '40px',
          height: '40px',
        },
      },
      content: {
        margin: '4px 0',
        textAlign: 'center',
        alignContent: 'center',
        '&$expanded': {
          margin: '4px 0',
          textAlign: 'center',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0,
        paddingBottom: '20px',
        border: 0,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontWeight: 700,
      },
    },
  },
};

export default theme;
