import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DashClients } from '../../axios';
// import ContainerGraphicTitle from '../../Components/ContainerGraphicTitle';
import ContainerTitle from '../../Components/ContainerTitle';
import DashCustomInput from '../../Components/DashCustomInput';
import Loader from '../../Components/Loader';
import { compareABstrings, sortSponsors } from '../../Helpers/SortHelpers';
import doUserHaveAccessToFeature from '../../Helpers/UserHelper';
import { GetAllClientTypes } from '../../Slices/ClientTypeSlice';
import { addNotification } from '../../Slices/NotificationSlice';
import { GetProfile } from '../../Slices/UserSlice';
import theme from '../../theme';
import {
  Client,
  ClientType,
  ClientTypeEnum,
  ClientTypeFactory,
  Functions,
  ModeEnum,
  ReactLocationState,
  Sponsor,
  User,
} from '../../Types';
import { ClientStatusEnum, ClientStatusEnumList } from '../../Types/Enums';
import AddSponsorDialog from './Dialogs/AddSponsorDialog';
import DeleteSponsorDialog from './Dialogs/DeleteSponsorDialog';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
  root: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface HeadCell {
  id: 'name' | 'type';
  label: string;
  width: number;
}

interface DashClientsDetailsPageProps extends RouteComponentProps {
  user: User;
}

export interface FormValuesDashClientDetails {
  nameEn: string;
  nameFr: string;
  clientType: ClientType;
  sponsors: Array<Sponsor>;
  status: ClientStatusEnum;
}

const DashClientDetails: FC<DashClientsDetailsPageProps> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { state } = useLocation<ReactLocationState>();

  const mode = state && state.clientDetailsMode ? state.clientDetailsMode : ModeEnum.Create;

  const clientTypes = useSelector((state: { clientType: any }) => GetAllClientTypes(state));
  const filteredClientTypes = clientTypes
    .filter((oneType) => oneType.isModifiableOrDeletable)
    .sort((a, b) =>
      i18n.language.startsWith('en') ? compareABstrings(a.nameEn, b.nameEn) : compareABstrings(a.nameFr, b.nameFr)
    );

  const [clientToEdit, setClientToEdit] = useState<Client | null>(
    state && state.clientToEdit ? state.clientToEdit : null
  );
  const [formValues, setFormValues] = useState<FormValuesDashClientDetails>(
    clientToEdit
      ? {
          nameEn: clientToEdit.nameEn,
          nameFr: clientToEdit.nameFr,
          clientType: clientToEdit.clientType,
          sponsors: clientToEdit.sponsors,
          status: clientToEdit.isActive ? ClientStatusEnum.active : ClientStatusEnum.inactive,
        }
      : {
          nameEn: '',
          nameFr: '',
          clientType:
            filteredClientTypes.find((oneType) => oneType.id === 1) ??
            ClientTypeFactory({
              id: 1,
              nameEn: 'Brokerage',
              nameFr: 'Courtage',
              canHaveSponsors: true,
              isModifiableOrDeletable: true,
            }),
          sponsors: [],
          status: ClientStatusEnum.active,
        }
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const [errorRequiredNameEn, setErrorRequiredNameEn] = useState<string | null>(null);
  const [errorRequiredNameFr, setErrorRequiredNameFr] = useState<string | null>(null);

  const [errorDuplicateName, setErrorDuplicateName] = useState<string | null>(null);

  const userAccessDashClient = doUserHaveAccessToFeature(user, Functions.manageDashClients);

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      label: t('dashclients.clientDetails.sponsorOrg'),
      width: 50,
    },
    {
      id: 'type',
      label: t('dashclients.clientDetails.sponsorComp'),
      width: 38,
    },
  ];

  const handleSave = () => {
    const isNameEnEqualZero = formValues.nameEn.trim().length === 0;
    const isNameFrEqualZero = formValues.nameFr.trim().length === 0;
    // const isNameEnHaveSpecial = SPECIAL_CHAR_REGEX.test(formValues.nameEn);
    // const isNameFrHaveSpecial = SPECIAL_CHAR_REGEX.test(formValues.nameFr);

    if (isNameEnEqualZero || isNameFrEqualZero) {
      if (isNameEnEqualZero && !isNameFrEqualZero) {
        setErrorRequiredNameEn(t('dashclients.clientDetails.clientNameEnRequired'));
        setErrorRequiredNameFr(null);
      }
      if (isNameFrEqualZero && !isNameEnEqualZero) {
        setErrorRequiredNameEn(null);
        setErrorRequiredNameFr(t('dashclients.clientDetails.clientNameFrRequired'));
      }
      if (isNameEnEqualZero && isNameFrEqualZero) {
        setErrorRequiredNameEn(t('dashclients.clientDetails.clientNameEnRequired'));
        setErrorRequiredNameFr(t('dashclients.clientDetails.clientNameFrRequired'));
      }
      return;
    } else {
      setErrorRequiredNameEn(null);
      setErrorRequiredNameFr(null);

      setIsLoading(true);

      if (mode === ModeEnum.Update) {
        const params: Client = {
          ...formValues,
          id: clientToEdit!.id,
          hasUser: clientToEdit!.hasUser,
          hasEmployedUser: clientToEdit!.hasEmployedUser,
          nameEn: formValues.nameEn.trim(),
          nameFr: formValues.nameFr.trim(),
          isActive: formValues.status === ClientStatusEnum.active,
        };
        DashClients.updateClient(params)
          .then(() => {
            setIsLoading(false);
            dispatch(addNotification(200, 'success', t('dashclients.clientDetails.ok200Update')));
            history.push(state.from, state);
          })
          .catch(() => {
            setIsLoading(false);
            setErrorDuplicateName(t('dashclients.clientDetails.errorDuplicateName'));
          });
      } else if (mode === ModeEnum.Create) {
        const params: Client = {
          ...formValues,
          id: 0,
          hasUser: false,
          hasEmployedUser: false,
          nameEn: formValues.nameEn.trim(),
          nameFr: formValues.nameFr.trim(),
          isActive: formValues.status === ClientStatusEnum.active,
        };
        DashClients.createClient(params)
          .then(() => {
            // re-fetch user profile to get the latest reporting companies associated with functions
            dispatch(GetProfile());
            setIsLoading(false);
            dispatch(addNotification(200, 'success', t('dashclients.clientDetails.ok200Create')));
            history.push(state.from, state);
          })
          .catch(() => {
            setIsLoading(false);
            setErrorDuplicateName(t('dashclients.clientDetails.errorDuplicateName'));
          });
      }
    }
  };

  const handleCancel = () => {
    history.push(state.from, state);
  };

  const handleAutocompleteChange = (value: ClientType) => {
    setFormValues({ ...formValues, clientType: value });
  };

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
    setFormValues({ ...formValues, [fieldName]: e.target.value });
  };

  const handleClickDeleteSponsor = (sponsor: Sponsor) => {
    setSelectedSponsor(sponsor);
    setOpenDeleteDialog(true);
  };

  return (
    <Container className={classes.root}>
      {userAccessDashClient && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerTitle title={t('menu.dashclientProfile')} />
              {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? ClientsEN : ClientsFR} /> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={5} data-testid="dashclientcard">
                <CardContent>
                  <Grid container padding={2}>
                    <Grid item container xs={12} rowSpacing={4}>
                      {/* Client infos when modifiable or in create mode */}
                      {(mode === ModeEnum.Create ||
                        (clientToEdit && clientToEdit.clientType.isModifiableOrDeletable)) && (
                        <Grid item container xs={12} rowSpacing={1}>
                          <Grid item xs={7}>
                            {mode === ModeEnum.Create ? (
                              <Autocomplete
                                fullWidth
                                noOptionsText={t('search.noOption')}
                                value={formValues.clientType}
                                id="clientType"
                                options={filteredClientTypes ?? []}
                                getOptionLabel={(option: ClientType) =>
                                  i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                                }
                                renderInput={(params) => (
                                  <DashCustomInput
                                    {...params}
                                    autoFocus
                                    label={t('dashclients.clientDetails.clientType')}
                                    labelGridSize={4}
                                    fieldGridSize={8}
                                    variant="outlined"
                                    color="secondary"
                                    placeholder={t('userAdmin.userAdmin.all')}
                                  />
                                )}
                                onChange={(_, value) => handleAutocompleteChange(value)}
                                loadingText={t('loading')}
                                disableClearable={true}
                                data-testid="clientType"
                              />
                            ) : (
                              <Grid container wrap="nowrap" padding={0.75}>
                                <Grid item xs={4} sx={{ marginRight: theme.spacing(2) }}>
                                  <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                                    {t('dashclients.clientDetails.clientType')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography sx={{ textAlign: 'start' }} data-testid="clientTypeReadOnly">
                                    {i18n.language.startsWith('en')
                                      ? clientToEdit?.clientType.nameEn
                                      : clientToEdit?.clientType.nameFr}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={7}>
                              <DashCustomInput
                                label={t('dashclients.clientDetails.clientNameEn')}
                                value={formValues.nameEn}
                                fullWidth
                                labelGridSize={4}
                                fieldGridSize={8}
                                variant="outlined"
                                autoComplete="nope"
                                color="secondary"
                                sx={{ paddingRight: 0 }}
                                data-testid="clientNameEn"
                                placeholder={t('dashclients.clientDetails.clientNameEnPlaceholder')}
                                onChange={(e) => handleTextFieldChange(e, 'nameEn')}
                                error={!!errorRequiredNameEn || !!errorDuplicateName}
                                helperText={errorRequiredNameEn}
                                isRequired={true}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={7}>
                              <DashCustomInput
                                label={t('dashclients.clientDetails.clientNameFr')}
                                value={formValues.nameFr}
                                fullWidth
                                labelGridSize={4}
                                fieldGridSize={8}
                                variant="outlined"
                                autoComplete="nope"
                                color="secondary"
                                sx={{ paddingRight: 0 }}
                                data-testid="clientNameFr"
                                placeholder={t('dashclients.clientDetails.clientNameFrPlaceholder')}
                                onChange={(e) => handleTextFieldChange(e, 'nameFr')}
                                error={!!errorRequiredNameFr || !!errorDuplicateName}
                                helperText={errorRequiredNameFr || errorDuplicateName}
                                isRequired={true}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center" wrap="nowrap" xs={7}>
                              <Grid item xs={4} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    textAlign: 'end',
                                  }}
                                >
                                  {t('userAdmin.userAdmin.status')}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Select
                                  fullWidth
                                  id="status"
                                  size="small"
                                  value={formValues.status}
                                  onChange={(e) =>
                                    setFormValues({ ...formValues, status: e.target.value as ClientStatusEnum })
                                  }
                                  data-testid="selectStatus"
                                  sx={{
                                    textAlign: 'left',
                                  }}
                                >
                                  {ClientStatusEnumList.map((status) => (
                                    <MenuItem key={`clientstatus-${status.value}`} value={status.value}>
                                      {t(status.label)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {mode === ModeEnum.Update && clientToEdit && !clientToEdit.clientType.isModifiableOrDeletable && (
                        <Grid item container xs={12} rowSpacing={1}>
                          <Grid item xs={7}>
                            <Grid container wrap="nowrap" padding={0.75}>
                              <Grid item xs={4} sx={{ marginRight: theme.spacing(2) }}>
                                <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                                  {t('dashclients.clientDetails.clientType')}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography sx={{ textAlign: 'start' }}>
                                  {i18n.language.startsWith('en')
                                    ? clientToEdit?.clientType.nameEn
                                    : clientToEdit?.clientType.nameFr}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={7}>
                              <Grid container wrap="nowrap" padding={0.75}>
                                <Grid item xs={4} sx={{ marginRight: theme.spacing(2) }}>
                                  <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                                    {t('dashclients.clientDetails.clientNameEn')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography sx={{ textAlign: 'start' }}>{clientToEdit?.nameEn}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item xs={7}>
                              <Grid container wrap="nowrap" padding={0.75}>
                                <Grid item xs={4} sx={{ marginRight: theme.spacing(2) }}>
                                  <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                                    {t('dashclients.clientDetails.clientNameFr')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography sx={{ textAlign: 'start' }}>{clientToEdit?.nameFr}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {/* Client Sponsors */}
                      {formValues.clientType.canHaveSponsors && (
                        <Grid item container xs={12}>
                          <TableContainer component={'div'} data-testid="tableContainer">
                            <Table
                              size="small"
                              stickyHeader
                              aria-label="table-90days-result"
                              sx={{ tableLayout: 'fixed', width: '100%' }}
                            >
                              <TableHead>
                                <TableRow>
                                  {headCells.map((headCell) => (
                                    <TableCell
                                      key={headCell.id}
                                      sx={{ width: `${headCell.width}%` }}
                                      data-testid={`headerCell-${headCell.id}`}
                                    >
                                      {headCell.label}
                                    </TableCell>
                                  ))}
                                  <TableCell sx={{ width: '12%' }} data-testid="headerCell-viewButton" align="right" />
                                </TableRow>
                              </TableHead>
                              <TableBody data-testid="tableBody">
                                {formValues.sponsors
                                  .sort((a, b) => sortSponsors(a, b, i18n))
                                  .map((oneSponsor) => ({
                                    id: oneSponsor.id,
                                    organization: i18n.language.startsWith('en')
                                      ? oneSponsor.organization.nameEn
                                      : oneSponsor.organization.nameFr,
                                    reportingCompanySponsor: i18n.language.startsWith('en')
                                      ? oneSponsor.reportingCompany.nameEn
                                      : oneSponsor.reportingCompany.nameFr,
                                  }))
                                  .map((row, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableCell}
                                        sx={{ width: '50%' }}
                                        data-testid={`tableCell-org-${index}`}
                                      >
                                        <Tooltip title={row.organization} enterTouchDelay={0}>
                                          <Typography className={classes.textCell}>{row.organization}</Typography>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                        sx={{ width: '38%' }}
                                        data-testid={`tableCell-rptComp-${index}`}
                                      >
                                        <Tooltip title={row.reportingCompanySponsor ?? ''} enterTouchDelay={0}>
                                          <Typography className={classes.textCell}>
                                            {row.reportingCompanySponsor ?? ''}
                                          </Typography>
                                        </Tooltip>
                                      </TableCell>

                                      <TableCell align="right" className={classes.tableCell} sx={{ width: '12%' }}>
                                        {formValues.sponsors.length !== 1 ||
                                        (formValues.sponsors.length == 1 && !formValues.sponsors[index].hasUser) ? (
                                          <Button
                                            className={classes.buttonText}
                                            variant="text"
                                            color="primary"
                                            id={`btnSponsorDelete${index}`}
                                            onClick={() => handleClickDeleteSponsor(formValues.sponsors[index])}
                                            endIcon={<ArrowRightIcon />}
                                            data-testid={`btnSponsorDelete${index}`}
                                          >
                                            {t('dashclients.delete')}
                                          </Button>
                                        ) : (
                                          <Grid item container xs={12} alignItems="center" justifyContent="flex-start">
                                            <Tooltip
                                              key={index}
                                              title={t(
                                                'dashclients.clientDetails.deleteSponsorButtonDisabled'
                                              ).toString()}
                                            >
                                              <Grid container>
                                                <Grid item xs={9} data-testid={`btnDeleteClient${index}`}>
                                                  <Typography sx={{ fontSize: '10.5pt', fontWeight: 700 }}>
                                                    {t('dashclients.delete')}
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  container
                                                  xs={3}
                                                  paddingLeft={
                                                    i18n.language.startsWith('en')
                                                      ? theme.spacing(0.5)
                                                      : theme.spacing(1)
                                                  }
                                                >
                                                  <HelpOutlineIcon fontSize="small" />
                                                </Grid>
                                              </Grid>
                                            </Tooltip>
                                          </Grid>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                              <TableFooter>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell colSpan={3}>
                                    <Button
                                      className={classes.buttonText}
                                      variant="text"
                                      color="primary"
                                      id={`btnSponsorAdd`}
                                      onClick={() => setOpenAddDialog(true)}
                                      endIcon={<ArrowRightIcon />}
                                      data-testid={`btnSponsorAdd`}
                                    >
                                      {t('dashclients.clientDetails.addSponsor')}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </Grid>
                      )}
                      {/* Bottom Buttons */}
                      <Grid item container xs={12} spacing={1} justifyContent="flex-start">
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            id="btnSave"
                            onClick={handleSave}
                            data-testid="btnSave"
                            disabled={
                              isLoading ||
                              formValues.nameEn.trim().length === 0 ||
                              formValues.nameFr.trim().length === 0 ||
                              (mode === ModeEnum.Update && !clientToEdit?.clientType.isModifiableOrDeletable)
                            }
                          >
                            {t('userProfile.create.lblBtnSave')}
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            onClick={handleCancel}
                            variant="contained"
                            color="secondary"
                            data-testid="btnCancel"
                          >
                            {t('userProfile.create.lblBtnCancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Loader open={isLoading} />
              <Dialog
                open={openDeleteDialog}
                fullWidth
                data-testid="AddSponsorDialog"
                onClose={(_, reason) => {
                  if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setOpenDeleteDialog(false);
                  }
                }}
              >
                <DeleteSponsorDialog
                  mode={mode}
                  sponsorToDelete={selectedSponsor!}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  idClient={clientToEdit?.id ?? 0}
                  clientTypeOfClientEnum={clientToEdit ? ClientTypeEnum[clientToEdit.clientType.id] : ClientTypeEnum[1]}
                  setClientToDelete={setClientToEdit}
                  setOpen={setOpenDeleteDialog}
                />
              </Dialog>
              <Dialog
                open={openAddDialog}
                fullWidth
                data-testid="AddSponsorDialog"
                onClose={(_, reason) => {
                  if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setOpenAddDialog(false);
                  }
                }}
              >
                <AddSponsorDialog
                  formValues={formValues}
                  setFormValues={setFormValues}
                  mode={mode}
                  setOpen={setOpenAddDialog}
                  clientId={clientToEdit?.id}
                  clientTypeId={clientToEdit?.clientType?.id}
                  hasUser={clientToEdit?.hasUser ?? false}
                />
              </Dialog>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default withRouter(DashClientDetails);
