import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';

import ContainerTitle from '../Components/ContainerTitle';
import SdcReportSearch from '../Features/SdcReport/Components/SdcReportSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
import theme from '../theme';
import { Functions, User } from '../Types';

interface SystemReportPageProps extends RouteComponentProps {
  user: User;
}

const SdcReportPage: FC<SystemReportPageProps> = ({ user }) => {
  const { t } = useTranslation();

  const sdcReportAccess = doUserHaveAccessToFeature(user, Functions.sdcReport);

  return (
    user && (
      <Container sx={{ textAlign: 'center', paddingBottom: theme.spacing(13) }}>
        {sdcReportAccess && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.sdc')} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SdcReportSearch />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    )
  );
};

export default withRouter(SdcReportPage);
