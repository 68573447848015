import { FC } from 'react';

import { Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import theme from '../theme';

const useStyles = makeStyles(() => ({
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'left',
  },
  card: {
    marginBottom: theme.spacing(1),
    borderLeft: '40px solid',
    borderColor: theme.palette.primary.main,
  },
}));

interface ContainerTitleProps {
  title: string;
  button?: JSX.Element;
}

const ContainerTitle: FC<ContainerTitleProps> = ({ title, button }) => {
  const classes = useStyles();

  return (
    <Card elevation={2} className={classes.card}>
      <Grid item container xs={12} className={classes.title} justifyContent="space-between">
        <Grid item container xs={4} alignItems="center">
          <Typography variant="h5" data-testid="title" sx={{ color: theme.palette.secondary.main }}>
            {title}
          </Typography>
        </Grid>
        <Grid item container xs={8} justifyContent="flex-end">
          {button}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ContainerTitle;
