export interface PermittedDomainOption {
  id: number;
  organizationId: number;
  reportingCompanyId?: number;
  domain: string;
  hasUsers: boolean;
}

export const PermittedDomainOptionFactory = (
  id: number,
  domain: string,
  organizationId: number,
  hasUsers: boolean,
  reportingCompanyId?: number
): PermittedDomainOption => {
  return {
    id,
    domain,
    organizationId,
    reportingCompanyId,
    hasUsers,
  };
};

export default PermittedDomainOption;
