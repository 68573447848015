import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Container, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { utcToZonedTime } from 'date-fns-tz';

import { NinetyDaysReportAxios, SystemMessages } from '../axios';
import Loader from '../Components/Loader';
import MenuTile from '../Components/MenuTile';
import RedBand from '../Components/RedBand';
import StyledBadge from '../Components/StyledBadge';
import WelcomeTile from '../Components/WelcomeTile';
import { compareDates } from '../Helpers/DateHelper';
import { sortAppFunctions } from '../Helpers/SortHelpers';
import useErrorHandler from '../Hooks/UseErrorHandler';
import { GetAllAppFunctions } from '../Slices/FunctionSlice';
import theme from '../theme';
import { AppFunction, Functions, SystemMessage, User } from '../Types';
import doUserHaveAccessToFeature from './../Helpers/UserHelper';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      color: 'black',
    },
    title: {
      padding: theme.spacing(1),
      textAlign: 'left',
    },
  })
);

interface HomeProps {
  user: User;
}

const Home: FC<HomeProps> = ({ user }) => {
  const classes = useStyles();
  const handleError = useErrorHandler();

  const appFunctions = useSelector((state: { appFunction: any }) => GetAllAppFunctions(state));

  const [redBandData, setRedBandData] = useState<SystemMessage | null>(null);
  const [isRedBandActive, setIsRedBandActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ninetyDaysUnreadCount, setNinetyDaysUnreadCount] = useState<number>(0);
  const [isNinetyDaysLoading, setIsNinetyDaysLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const hostTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    SystemMessages.getSystemMessage()
      .then((result) => {
        const localizedResult: SystemMessage = {
          ...result,
          startDate: result.startDate ? utcToZonedTime(result.startDate, hostTimezone) : null,
          endDate: result.endDate ? utcToZonedTime(result.endDate, hostTimezone) : null,
        };

        setRedBandData(localizedResult);

        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoading(false);
      });
  }, [handleError]);

  useEffect(() => {
    if (redBandData && redBandData.startDate && redBandData.endDate) {
      const isInTimeBracket =
        compareDates(new Date(), redBandData.startDate) === 1 && compareDates(new Date(), redBandData.endDate) === -1;
      setIsRedBandActive(isInTimeBracket);
    }
  }, [redBandData]);

  // Loading 90 days unread count
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (doUserHaveAccessToFeature(user, Functions.ninetyDaysReport)) {
      setIsNinetyDaysLoading(true);
      NinetyDaysReportAxios.getUnreadCount(source.token)
        .then((results) => {
          setNinetyDaysUnreadCount(results.unreadCount);
          setIsNinetyDaysLoading(false);
        })
        .catch((error) => {
          handleError(error);
          setIsNinetyDaysLoading(false);
        });
    }

    return () => {
      source.cancel('Cancelled due to stale request');
    };
  }, [handleError, user]);

  return (
    <>
      {user && user !== null && (
        <>
          {/* RedBand */}
          {redBandData && isRedBandActive && (
            <Grid item container xs={12} paddingTop="108px">
              {/* IBC red band */}
              <RedBand {...redBandData} />
            </Grid>
          )}
          {/* Height is calculated with screen size and header (108px), footer (58px) and redrubban(60px) if it exists */}
          <Container
            className={classes.root}
            sx={{
              paddingTop: isRedBandActive ? '0px' : '108px',
              maxWidth: '100vw !important',
              height: isRedBandActive ? 'calc(100vh - 226px)' : 'calc(100vh - 58px)',
              overflow: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <Grid container alignItems="center" justifyContent="center" height="100%">
              {/* The width is calculated with size of the tiles (200px) times 5 + 32px of padding due to spacing */}
              <Grid
                item
                container
                width={'1040px'}
                justifyContent="flex-start"
                paddingBottom={'60px'}
                paddingTop={'60px'}
              >
                <Grid item container width="624px">
                  <WelcomeTile user={user} />
                </Grid>
                {appFunctions
                  .sort((a: AppFunction, b: AppFunction) => sortAppFunctions(a, b))
                  .map((oneFunc, index) => {
                    switch (oneFunc.id) {
                      case Functions.driverReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.driverReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="driverReport" route="/driver-report" />
                            </Grid>
                          )
                        );
                      case Functions.vehicleReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.vehicleReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="vehicleReport" route="/vehicle-report" />
                            </Grid>
                          )
                        );
                      case Functions.reportHistory:
                        return (
                          doUserHaveAccessToFeature(user, Functions.reportHistory) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="reportHistory" route="/report-history" />
                            </Grid>
                          )
                        );
                      case Functions.ninetyDaysReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.ninetyDaysReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <StyledBadge
                                color="secondary"
                                badgeContent={isNinetyDaysLoading ? 0 : ninetyDaysUnreadCount}
                                max={9}
                              >
                                <MenuTile icon="ninetyDays" route="/ninetydays-report" />
                              </StyledBadge>
                            </Grid>
                          )
                        );
                      case Functions.dataCorrection:
                        return undefined;
                      case Functions.userAccessReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.userAccessReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="accessReport" route="/user-access-report" />
                            </Grid>
                          )
                        );
                      case Functions.companyAdministration:
                        return (
                          doUserHaveAccessToFeature(user, Functions.companyAdministration) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="companyProfile" route="/company-profile" />
                            </Grid>
                          )
                        );
                      case Functions.userAdministration:
                        return (
                          doUserHaveAccessToFeature(user, Functions.userAdministration) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="userAdmin" route="/user-administration" />
                            </Grid>
                          )
                        );
                      case Functions.usageReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.usageReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="usageReport" route="/usage-report" />
                            </Grid>
                          )
                        );
                      case Functions.manageSystemFunction:
                        return (
                          doUserHaveAccessToFeature(user, Functions.manageSystemFunction) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="settings" route="/settings" />
                            </Grid>
                          )
                        );
                      case Functions.manageDashClients:
                        return (
                          doUserHaveAccessToFeature(user, Functions.manageDashClients) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="client" route="/dash-client" />
                            </Grid>
                          )
                        );
                      case Functions.correctionReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.correctionReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="correctionReport" route="/correction-report" />
                            </Grid>
                          )
                        );
                      case Functions.sdcReport:
                        return (
                          doUserHaveAccessToFeature(user, Functions.sdcReport) && (
                            <Grid key={index} item container justifyContent="flex-start" width="208px">
                              <MenuTile icon="sdc" route="/sdc" />
                            </Grid>
                          )
                        );
                    }
                  })}
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {(!user || isLoading) && <Loader open={true} />}
    </>
  );
};

export default Home;
