import { CancelToken } from 'axios';

import { subDays } from 'date-fns';

import { requests } from '../axios';
import {
  DataCorrectionFactory,
  Report90DaysRequest,
  Report90DaysResponseFactory,
  Response,
  UserFunctionCompanyFactory,
} from '../Types';
import { PaginationQuery } from '../Types/PaginationQuery';
import { Report90DaysUnreadCountResponse } from '../Types/Report90Days';

export const find = (params: Report90DaysRequest, paginationQuery?: PaginationQuery): Promise<Response> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Response>((resolve) => {
      const { company, reportRequestor, fromDate, toDate } = params;
      resolve({
        count: 2,
        data: [
          Report90DaysResponseFactory({
            ninetyDaysDataCorrectionId: 1,
            reportId: '12354',
            company: company ?? UserFunctionCompanyFactory(1, 1, '123', 'NameFr', 'nameEn'),
            reportRequestor: reportRequestor && reportRequestor.length > 0 ? reportRequestor : 'Gérard Bouchard',
            reportDate: toDate ?? new Date(),
            ianaTimezone: 'America/Montreal',
            changeDate: fromDate ?? subDays(new Date(), 90),
            referenceNumber: 'Ref123',
            driverLicenseNumber: 'AB1235',
            changes: [
              DataCorrectionFactory({ id: 1, nameEn: 'nameEn', nameFr: 'nameFr' }),
              DataCorrectionFactory({ id: 2, nameEn: 'nameEn2', nameFr: 'nameFr2' }),
            ],
            reviewedBy: 'John Powell',
          }),
          Report90DaysResponseFactory({
            ninetyDaysDataCorrectionId: 1,
            reportId: '45321',
            company: company ?? UserFunctionCompanyFactory(1, 2, '123', 'NameFr2', 'nameEn2'),
            reportRequestor: reportRequestor && reportRequestor.length > 0 ? reportRequestor : 'Linus Sebastien',
            reportDate: toDate ?? new Date(),
            ianaTimezone: 'America/Montreal',
            changeDate: fromDate ?? subDays(new Date(), 120),
            referenceNumber: '321fer',
            driverLicenseNumber: 'AB8956',
            changes: [
              DataCorrectionFactory({ id: 1, nameEn: 'nameEn3', nameFr: 'nameFr3' }),
              DataCorrectionFactory({ id: 2, nameEn: 'nameEn4', nameFr: 'nameFr4' }),
            ],
            reviewedBy: 'Sebastien Loeb',
          }),
        ],
      });
    });
  } else {
    let query = '/DriverReports/NinetyDaysReportSearch';
    if (paginationQuery) {
      query += `?PageSize=${paginationQuery.pageSize}&PageNumber=${paginationQuery.pageNumber}`;
    }
    return requests.post(query, params);
  }
};

export const getUnreadCount = (CancelToken?: CancelToken): Promise<Report90DaysUnreadCountResponse> => {
  return requests.get('/DriverReports/NinetyDaysReportUnreadCount', {}, CancelToken);
};
