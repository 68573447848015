import { User } from '../Types';

const doUserHaveAccessToFeature = (user: User, functionId: number): boolean => {
  const hasFunction = user && user.userFunctions && user.userFunctions.some((uf) => uf.functionId === functionId);
  return hasFunction;
};

export const userFullNameToString = (user: User): string => {
  return `${user.firstName}${user.middleName ? ` ${user.middleName}` : ''} ${user.lastName}`;
};

export default doUserHaveAccessToFeature;
