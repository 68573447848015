import Organization from './Organization';
import PolicyStatus from './PolicyStatus';
import VehicleClass from './VehicleClass';
import VehicleUse from './VehicleUse';

export interface VehiclePolicy {
  carrier: Organization;
  policyStatus: PolicyStatus | null;
  vehicleUse: VehicleUse;
  vehicleClass: VehicleClass;
  startDate: Date;
  stopDate: Date;
  policyNumber: string | null;
  commutingDistance: string | null;
  annualDrivingDistance: string | null;
  businessUsePercent: string | null;
  coveragePeriod: string | null;
}

export const VehiclePolicyFactory = (props: VehiclePolicy): VehiclePolicy => {
  return {
    carrier: props.carrier,
    policyStatus: props.policyStatus,
    vehicleUse: props.vehicleUse,
    vehicleClass: props.vehicleClass,
    startDate: props.startDate,
    stopDate: props.stopDate,
    policyNumber: props.policyNumber,
    commutingDistance: props.commutingDistance,
    annualDrivingDistance: props.annualDrivingDistance,
    businessUsePercent: props.businessUsePercent,
    coveragePeriod: props.coveragePeriod,
  };
};

export default VehiclePolicy;
