import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { ReportingCompanies, Units, UserProfile } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import Message from '../../../Components/Message';
import { sortOrganizations, sortRptCompanies, sortUnits } from '../../../Helpers/SortHelpers';
import useAutofocus from '../../../Hooks/UseAutofocus';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../../Slices/OrganizationSlice';
import theme from '../../../theme';
import {
  Functions,
  ModeEnum,
  Organization,
  ReactLocationState,
  ReportingCompany,
  Unit,
  UserProfileSearchRequest,
  UserProfileSearchResult,
  UserStatus,
} from '../../../Types';
import UserProfileSearchResults from './UserProfileSearchResults';

interface FormValuesProps {
  organizationId: number | null;
  reportingCompanyId: number | null;
  unitId: number | null;
  status: UserStatus;
  userId: string;
  userName: string;
}

const UserProfileSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const { pathname, state } = useLocation<ReactLocationState>();
  const savedRequest = (state && state.userSearchRequest) ?? null;

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);
  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);

  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);
  const [fetchedUnits, setFetchedUnits] = useState<Array<Unit> | null>(null);

  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const [fetchedResults, setFetchedResults] = useState<Array<UserProfileSearchResult> | null>(null);

  const [formValues, setFormValues] = useState<FormValuesProps>(
    savedRequest
      ? {
          ...savedRequest,
          userId: savedRequest.userId ? savedRequest.userId : '',
          userName: savedRequest.userName ? savedRequest.userName : '',
        }
      : {
          organizationId: null,
          reportingCompanyId: null,
          unitId: null,
          status: 1,
          userId: '',
          userName: '',
        }
  );

  // This external state for the form is necessary for default values
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [reportingCompanyId, setReportingCompanyId] = useState<number | null>(null);
  const [unitId, setUnitId] = useState<number | null>(null);

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(organizationId);

  const organizationRef = useAutofocus();

  // Fetch organisation by user access when loading page because they are not the same rights in different views
  useEffect(() => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      return;
    }
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.userAdministration }));
  }, [dispatch]);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname, fromTab: 'userAdmin' });
  }, [history, pathname]);

  // Initialize selectedOrg if there is only one organizations available to the user
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setOrganizationId(organizations[0].id);
    } else if (organizations && organizations.length > 1 && formValues.organizationId) {
      setOrganizationId(formValues.organizationId);
    }
  }, [formValues.organizationId, organizations]);

  // Re-fetching results when coming back on the page
  useEffect(() => {
    if (state && state.userSearchRequest) {
      setIsLoadingSearch(true);
      UserProfile.find(state.userSearchRequest)
        .then((results) => {
          setFetchedResults(results);
          setIsLoadingSearch(false);
        })
        .catch((error) => {
          handleError(error, { error401: t('errorMessage.error401') });
          setIsLoadingSearch(false);
        });
    }
  }, [dispatch, handleError, state, t]);

  // Fetch Reporting Companies
  useEffect(() => {
    if (!isIbcOrThirdPartySelected && organizationId) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganization(Functions.userAdministration, organizationId)
        .then((results) => {
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
          if (results.length === 1) {
            setReportingCompanyId(results[0].id);
          } else if (results.length > 1 && formValues.reportingCompanyId) {
            setReportingCompanyId(formValues.reportingCompanyId);
          }
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleError, isIbcOrThirdPartySelected, organizationId, t]);

  // Fetch Units
  useEffect(() => {
    if (
      (isIbcOrThirdPartySelected && organizationId && !reportingCompanyId) ||
      (organizationId && reportingCompanyId)
    ) {
      setIsLoadingUnits(true);

      Units.getUnitsByCompanyId(Functions.userAdministration, organizationId, reportingCompanyId)
        .then((results) => {
          setFetchedUnits(results);
          setIsLoadingUnits(false);
          if (results.length > 1 && formValues.unitId) {
            setUnitId(formValues.unitId);
          }
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingUnits(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleError, isIbcOrThirdPartySelected, organizationId, reportingCompanyId, t]);

  const sort = useCallback(
    (a: any, b: any, type: string): number => {
      switch (type) {
        case 'organization':
          return sortOrganizations(a, b, i18n);
        case 'reportingcompany':
          return sortRptCompanies(a, b, i18n);
        case 'unit':
          return sortUnits(a, b);
        default:
          return 0;
      }
    },
    [i18n]
  );

  const resetFormValues = () => {
    setFormValues({
      organizationId: null,
      reportingCompanyId: null,
      unitId: null,
      status: 1,
      userId: '',
      userName: '',
    });
    setOrganizationId(null);
    setReportingCompanyId(null);
    setUnitId(null);
  };

  const handleClearFields = () => {
    fetchedResults && setFetchedResults(null);
    setFetchedRptCompanies(null);
    setFetchedUnits(null);
    resetFormValues();
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSearch = async () => {
    // userId and userName are empty string when reseted but should send null to BE (textfield issue with null)
    setIsLoadingSearch(true);
    const params: UserProfileSearchRequest = {
      ...formValues,
      organizationId: organizationId,
      reportingCompanyId: reportingCompanyId,
      unitId: unitId,
      userId: formValues.userId.length === 0 ? null : formValues.userId,
      userName: formValues.userName.length === 0 ? null : formValues.userName,
    };
    await UserProfile.find(params)
      .then((results) => {
        setFetchedResults(results);
        setIsLoadingSearch(false);
      })
      .catch((error) => {
        handleError(error, { error401: t('errorMessage.error401') });
        setIsLoadingSearch(false);
      });
  };

  const handleClickNewUser = () => {
    history.push({
      pathname: '/user-administration/userProfile',
      state: {
        ...state,
        userProfileMode: ModeEnum.Create,
      } as ReactLocationState,
    });
  };

  const handleAutocompleteChange = (name: string, value: number | null) => {
    if (name === 'organizationId') {
      setFormValues({
        ...formValues,
        organizationId: value,
        reportingCompanyId: null,
        unitId: null,
      });
      setOrganizationId(value);
      setReportingCompanyId(null);
      setUnitId(null);
    } else if (name === 'reportingCompanyId') {
      setFormValues({ ...formValues, reportingCompanyId: value, unitId: null });
      setReportingCompanyId(value);
      setUnitId(null);
    } else if (name === 'unitId') {
      setFormValues({ ...formValues, unitId: value });
      setUnitId(value);
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <>
      <Card elevation={3} data-testid="userProfileSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              {/* Search */}
              <Grid item container padding={2} spacing={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} md={10} lg={10} xl={10}>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        organizationId !== null
                          ? organizations.find((oneOrg: Organization) => oneOrg.id === organizationId)
                          : null
                      }
                      id="organizationId"
                      data-testid="organizationId"
                      options={organizations.sort((a, b) => sort(a, b, 'organization'))}
                      getOptionLabel={(option: Organization) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('userProfile.create.lblEmployedAtOrg')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: organizationRef,
                          }}
                        />
                      )}
                      onChange={(_, value) =>
                        handleAutocompleteChange('organizationId', value && value.id ? value.id : null)
                      }
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    {!isIbcOrThirdPartySelected ? (
                      <Autocomplete
                        fullWidth
                        loading={isLoadingRpt}
                        loadingText={t('loading')}
                        noOptionsText={t('search.noOption')}
                        value={
                          reportingCompanyId !== null && fetchedRptCompanies
                            ? fetchedRptCompanies.find((oneRpt) => oneRpt.id === reportingCompanyId)
                            : null
                        }
                        id="reportingCompanyId"
                        data-testid="reportingCompanyId"
                        options={
                          fetchedRptCompanies ? fetchedRptCompanies.sort((a, b) => sort(a, b, 'reportingcompany')) : []
                        }
                        getOptionLabel={(option: ReportingCompany) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('userProfile.create.lblEmployedAtCompany')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingRpt ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) =>
                          handleAutocompleteChange('reportingCompanyId', value && value.id ? value.id : null)
                        }
                        disabled={isIbcOrThirdPartySelected || !organizationId}
                        disableClearable={fetchedRptCompanies != null && fetchedRptCompanies.length === 1}
                      />
                    ) : (
                      /* Dummy field for company when IBC or 3rd party */
                      <DashCustomInput
                        fullWidth
                        id="companyHolderIbc3rdPart"
                        value={
                          organizationId
                            ? i18n.language.startsWith('en')
                              ? organizations.find((oneOrg) => oneOrg.id === organizationId)?.nameEn
                              : organizations.find((oneOrg) => oneOrg.id === organizationId)?.nameFr
                            : ''
                        }
                        label={t('userProfile.create.lblEmployedAtCompany')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingUnits}
                      loadingText={t('loading')}
                      noOptionsText={t('search.noOption')}
                      value={
                        unitId !== null && fetchedUnits ? fetchedUnits.find((oneUnit) => oneUnit.id === unitId) : null
                      }
                      id="unitId"
                      data-testid="unitId"
                      options={fetchedUnits ? fetchedUnits.sort((a, b) => sort(a, b, 'unit')) : []}
                      getOptionLabel={(option: Unit) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('userAdmin.userAdmin.unit')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={
                            fetchedUnits === null || fetchedUnits.length < 1 ? undefined : t('userAdmin.userAdmin.all')
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingUnits ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteChange('unitId', value && value.id ? value.id : null)}
                      disabled={
                        (!isIbcOrThirdPartySelected && reportingCompanyId === null) ||
                        (organizationId === null && reportingCompanyId === null) ||
                        fetchedUnits === null ||
                        fetchedUnits.length < 1
                      }
                    />
                  </Grid>

                  <Grid item container md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            textAlign: 'end',
                          }}
                        >
                          {t('userAdmin.userAdmin.status')}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Select
                          fullWidth
                          id="status"
                          size="small"
                          value={formValues.status}
                          onChange={(e) => setFormValues({ ...formValues, status: e.target.value as UserStatus })}
                          data-testid="selectStatus"
                          sx={{
                            textAlign: 'left',
                          }}
                        >
                          <MenuItem value={0}>{t('userStatus.all')}</MenuItem>
                          <MenuItem value={1}>{t('userStatus.active')}</MenuItem>
                          <MenuItem value={2}>{t('userStatus.disabled')}</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="userId"
                      value={formValues.userId}
                      label={t('userAdmin.userAdmin.userId')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'userId',
                        name: 'userId',
                      }}
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="userName"
                      value={formValues.userName}
                      label={t('userAdmin.userAdmin.name')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'userName',
                        name: 'userName',
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          variant="contained"
                          id="btnSearch"
                          // onClick={() => handleSearch()}
                          type="submit"
                          data-testid="btnSearch"
                        >
                          {t('userAdmin.lookup')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={() => handleClearFields()}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('search.clearFields')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* New User column */}
                <Grid item container md={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <Button
                      fullWidth
                      onClick={() => handleClickNewUser()}
                      variant="contained"
                      color="primary"
                      data-testid="buttonNewUser"
                    >
                      {t('userAdmin.userAdmin.newUser')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Result */}
              {fetchedResults && fetchedResults.length > 0 && (
                <Grid item container xs={12} padding={2} marginTop={theme.spacing(2)}>
                  <Grid item container xs={12}>
                    <Typography fontWeight={700}>{`${fetchedResults.length} ${
                      fetchedResults.length === 1
                        ? t('userAdmin.userAdmin.oneResultFound')
                        : t('userAdmin.userAdmin.manyResultsFound')
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <UserProfileSearchResults
                      fetchedResults={fetchedResults}
                      requestUserSearch={{
                        ...formValues,
                        userId: formValues.userId.length === 0 ? null : formValues.userId,
                        userName: formValues.userName.length === 0 ? null : formValues.userName,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {fetchedResults && fetchedResults.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('userAdmin.userAdmin.noFound')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingSearch} />
    </>
  );
};

export default UserProfileSearch;
