export interface ReportingCompany {
  id: number;
  organizationId: number;
  code: string;
  nameEn: string;
  nameFr: string;
}

export const ReportingCompanyFactory = (
  id: number,
  organizationId: number,
  code: string,
  nameFr: string,
  nameEn: string
): ReportingCompany => {
  return {
    id,
    organizationId,
    code,
    nameEn,
    nameFr,
  };
};

export default ReportingCompany;
