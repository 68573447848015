import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import UsageReportSearch from '../Features/UsageReport/UsageReportSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import UsageEN from '../Medias/titles/Usage - EN.jpg';
// import UsageFR from '../Medias/titles/Usage - FR.jpg';
import { Functions, User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface UsageReportPageProps extends RouteComponentProps {
  user: User;
}

const UsageReportPage: FC<UsageReportPageProps> = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const usageReportAccess = doUserHaveAccessToFeature(user, Functions.usageReport);

  return (
    user && (
      <Container className={classes.root}>
        {usageReportAccess && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.usageReport')} />
                {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? UsageEN : UsageFR} /> */}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsageReportSearch />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    )
  );
};

export default withRouter(UsageReportPage);
