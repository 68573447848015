import { FC } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import theme from '../theme';
import { ReactLocationState } from '../Types';

const useStyles = makeStyles(() => ({
  root: {
    height: '50px',
    width: '350px',
    paddingLeft: '50px',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  semiroot: {
    height: '50px',
    width: '350px',
    paddingLeft: '50px',
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface MenuCardProps extends RouteComponentProps<any> {
  title: string;
  icon:
    | 'home'
    | 'driverReport'
    | 'vehicleReport'
    | 'reportHistory'
    | 'ninetyDays'
    | 'companyProfile'
    | 'userAdmin'
    | 'usageReport'
    | 'accessReport'
    | 'settings'
    | 'dashClients'
    | 'correctionReport'
    | 'sdc';
  route: string;
  buttonClicked: () => void;
}

const MenuCard: FC<MenuCardProps> = ({ title, icon, route, buttonClicked, history }) => {
  const classes = useStyles();
  const handleClick = () => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "route": "' + route + '" }');
    }
    history.push(route);
    buttonClicked();
  };

  const state = useLocation<ReactLocationState>();

  return (
    <Card className={state.pathname === route ? classes.semiroot : classes.root}>
      <CardActionArea data-testid={`squareButton-${icon}`} onClick={() => handleClick()}>
        <CardContent sx={{ padding: 0 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container xs={12} justifyContent="flex-start">
              <Typography
                color={theme.palette.white.main}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: 16,
                  paddingTop: '13px',
                  paddingBottom: '13px',
                }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default withRouter(MenuCard);
