import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Grid } from '@mui/material';

import { utcToZonedTime } from 'date-fns-tz';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { SystemMessages } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import {
  DATE_FORMAT_TIME_EN,
  DATE_FORMAT_TIME_FR,
  DATE_MASK_TIME_EN,
  DATE_MASK_TIME_FR,
} from '../../../Helpers/Constants';
import { compareDates } from '../../../Helpers/DateHelper';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import { SystemMessage } from '../../../Types';

const SystemMessageEditor: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const [hasError, setHasError] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<SystemMessage>({
    id: 0,
    messageEn: '',
    messageFr: '',
    startDate: new Date(),
    endDate: new Date(),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const hostTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    SystemMessages.getSystemMessage()
      .then((result) => {
        const localizedResult: SystemMessage = {
          ...result,
          startDate: result.startDate ? utcToZonedTime(result.startDate, hostTimezone) : null,
          endDate: result.endDate ? utcToZonedTime(result.endDate, hostTimezone) : null,
        };
        setFormValues(localizedResult);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoading(false);
      });
  }, [handleError]);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleDatePickerChange = (fieldName: string, newValue: Date | null) => {
    setFormValues({ ...formValues, [fieldName]: newValue });
  };

  const isFormValid = (): boolean => {
    let error = false;

    if (
      formValues.endDate &&
      formValues.startDate &&
      (compareDates(formValues.startDate, formValues.endDate) >= 0 ||
        compareDates(formValues.endDate, formValues.startDate) <= 0)
    ) {
      setHasError(true);
      error = true;
    } else {
      setHasError(false);
    }

    if (error) {
      return false;
    }
    return true;
  };

  const onSaveSystemMessage = () => {
    if (isFormValid()) {
      SystemMessages.updateSystemMessage(formValues)
        .then(() => {
          dispatch(addNotification(200, 'success', t('successMessage.savedChanges')));
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const handleClearFields = () => {
    setFormValues({
      id: 0,
      messageEn: '',
      messageFr: '',
      startDate: new Date(),
      endDate: new Date(),
    });
  };

  return (
    <>
      <Grid container>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={9} md={9} lg={8} xl={7}>
            <DashCustomInput
              fullWidth
              id="messageEn"
              value={formValues.messageEn}
              label={t('settings.system-message.message-en')}
              labelGridSize={3}
              fieldGridSize={8}
              variant="outlined"
              color="secondary"
              onChange={(e) => handleInputChange(e)}
              inputProps={{
                'data-testid': 'messageEn',
                name: 'messageEn',
              }}
            />
          </Grid>
          <Grid item xs={9} md={9} lg={8} xl={7}>
            <DashCustomInput
              fullWidth
              id="messageFr"
              value={formValues.messageFr}
              label={t('settings.system-message.message-fr')}
              labelGridSize={3}
              fieldGridSize={8}
              variant="outlined"
              color="secondary"
              onChange={(e) => handleInputChange(e)}
              inputProps={{
                'data-testid': 'messageFr',
                name: 'messageFr',
              }}
            />
          </Grid>
          <Grid item xs={9} md={9} lg={8} xl={7}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={i18n.language.startsWith('en') ? enLocale : frLocale}
            >
              <DateTimePicker
                clearable
                mask={i18n.language.startsWith('en') ? DATE_MASK_TIME_EN : DATE_MASK_TIME_FR}
                inputFormat={i18n.language.startsWith('en') ? DATE_FORMAT_TIME_EN : DATE_FORMAT_TIME_FR}
                value={formValues.startDate}
                onChange={(newValue) => handleDatePickerChange('startDate', newValue)}
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    fullWidth
                    label={t('settings.system-message.startDate')}
                    labelGridSize={3}
                    fieldGridSize={4}
                    error={hasError}
                    helperText={hasError ? t('settings.system-message.dateError') : ''}
                    variant="outlined"
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'fromDatePicker',
                      placeholder: t('datepickerPlaceholderFrom').toString(),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={9} md={9} lg={8} xl={7}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={i18n.language.startsWith('en') ? enLocale : frLocale}
            >
              <DateTimePicker
                clearable
                mask={i18n.language.startsWith('en') ? DATE_MASK_TIME_EN : DATE_MASK_TIME_FR}
                inputFormat={i18n.language.startsWith('en') ? DATE_FORMAT_TIME_EN : DATE_FORMAT_TIME_FR}
                value={formValues.endDate}
                onChange={(newValue) => handleDatePickerChange('endDate', newValue)}
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    fullWidth
                    label={t('settings.system-message.endDate')}
                    labelGridSize={3}
                    fieldGridSize={4}
                    variant="outlined"
                    error={hasError}
                    helperText={hasError ? t('settings.system-message.dateError') : ''}
                    color="secondary"
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'fromDatePicker',
                      placeholder: t('datepickerPlaceholderTo').toString(),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item container xs={9} md={9} lg={8} xl={7} marginTop={2} spacing={1}>
            <Grid item xs={3} marginRight={2} />
            <Grid item xs={2}>
              <Button
                fullWidth
                onClick={onSaveSystemMessage}
                variant="contained"
                color="primary"
                disabled={
                  !formValues.messageEn || !formValues.messageFr || !formValues.startDate || !formValues.endDate
                }
              >
                {t('settings.system-message.save')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                onClick={handleClearFields}
                variant="contained"
                color="secondary"
                data-testid="clearFields"
              >
                {t('search.clearFields')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <Loader open={isLoading} />}
    </>
  );
};

export default SystemMessageEditor;
