const dependencies = [
  {
    name: '@ampproject/remapping',
    version: '2.2.0',
    repository: 'https://github.com/ampproject/remapping',
    license: 'Apache',
  },
  {
    name: '@babel/code-frame',
    version: '7.10.4',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/compat-data',
    version: '7.18.13',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/core',
    version: '7.12.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/generator',
    version: '7.13.9',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/helper-annotate-as-pure',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-builder-binary-assignment-operator-visitor',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-compilation-targets',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-create-class-features-plugin',
    version: '7.18.13',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-create-regexp-features-plugin',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-define-polyfill-provider',
    version: '0.2.4',
    repository: 'https://github.com/babel/babel-polyfills',
    license: 'MIT',
  },
  {
    name: '@babel/helper-environment-visitor',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-explode-assignable-expression',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-function-name',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-hoist-variables',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-member-expression-to-functions',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-module-imports',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-module-transforms',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-optimise-call-expression',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-plugin-utils',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-remap-async-to-generator',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-replace-supers',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-simple-access',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-skip-transparent-expression-wrappers',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-split-export-declaration',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-string-parser',
    version: '7.18.10',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-validator-identifier',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-validator-option',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helper-wrap-function',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/helpers',
    version: '7.18.9',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/highlight',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/parser',
    version: '7.14.6',
    repository: '(none)',
    license: '',
  },
  {
    name: '@babel/plugin-bugfix-safari-id-destructuring-collision-in-function-expression',
    version: '7.16.2',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-bugfix-v8-spread-parameters-in-optional-chaining',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-async-generator-functions',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-class-properties',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-class-static-block',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-decorators',
    version: '7.12.1',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-dynamic-import',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-export-namespace-from',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-json-strings',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-logical-assignment-operators',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-nullish-coalescing-operator',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-numeric-separator',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-object-rest-spread',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-optional-catch-binding',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-optional-chaining',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-private-methods',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-private-property-in-object',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-proposal-unicode-property-regex',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-async-generators',
    version: '7.8.4',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-async-generators',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-bigint',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-bigint',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-class-properties',
    version: '7.12.13',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-class-static-block',
    version: '7.14.5',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-decorators',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-dynamic-import',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-dynamic-import',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-export-namespace-from',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-export-namespace-from',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-flow',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-import-meta',
    version: '7.10.4',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-json-strings',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-json-strings',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-jsx',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-logical-assignment-operators',
    version: '7.10.4',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-nullish-coalescing-operator',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-nullish-coalescing-operator',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-numeric-separator',
    version: '7.10.4',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-object-rest-spread',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-object-rest-spread',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-optional-catch-binding',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-optional-catch-binding',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-optional-chaining',
    version: '7.8.3',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-optional-chaining',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-private-property-in-object',
    version: '7.14.5',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-top-level-await',
    version: '7.14.5',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-syntax-typescript',
    version: '7.18.6',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-arrow-functions',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-async-to-generator',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-block-scoped-functions',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-block-scoping',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-classes',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-computed-properties',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-destructuring',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-dotall-regex',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-duplicate-keys',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-exponentiation-operator',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-flow-strip-types',
    version: '7.12.1',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-for-of',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-function-name',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-literals',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-member-expression-literals',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-modules-amd',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-modules-commonjs',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-modules-systemjs',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-modules-umd',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-named-capturing-groups-regex',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-new-target',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-object-super',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-parameters',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-property-literals',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-constant-elements',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-display-name',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-jsx',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-jsx-development',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-jsx-self',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-jsx-source',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-react-pure-annotations',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-regenerator',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-reserved-words',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-runtime',
    version: '7.12.1',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-shorthand-properties',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-spread',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-sticky-regex',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-template-literals',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-typeof-symbol',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-typescript',
    version: '7.18.12',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-unicode-escapes',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/plugin-transform-unicode-regex',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/preset-env',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/preset-flow',
    version: '7.16.7',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/preset-modules',
    version: '0.1.5',
    repository: 'https://github.com/babel/preset-modules',
    license: 'MIT',
  },
  {
    name: '@babel/preset-react',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/preset-typescript',
    version: '7.12.1',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/register',
    version: '7.16.7',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/runtime',
    version: '7.12.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/runtime-corejs3',
    version: '7.16.0',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/template',
    version: '7.18.10',
    repository: 'https://github.com/babel/babel',
    license: 'MIT',
  },
  {
    name: '@babel/traverse',
    version: '7.13.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@babel/types',
    version: '7.13.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@bcoe/v8-coverage',
    version: '0.2.3',
    repository: 'http://github.com/demurgos/v8-coverage',
    license: 'MIT',
  },
  {
    name: '@cnakazawa/watch',
    version: '1.0.4',
    repository: 'http://github.com/mikeal/watch',
    license: 'Apache',
  },
  {
    name: '@csstools/convert-colors',
    version: '1.4.0',
    repository: 'jonathantneal/convert-colors',
    license: '',
  },
  {
    name: '@csstools/normalize.css',
    version: '10.1.0',
    repository: 'csstools/normalize.css',
    license: '',
  },
  {
    name: '@cypress/mount-utils',
    version: '1.0.2',
    repository: 'https://github.com/cypress-io/cypress',
    license: '',
  },
  {
    name: '@cypress/react',
    version: '5.10.3',
    repository: 'https://github.com/cypress-io/cypress',
    license: '',
  },
  {
    name: '@cypress/request',
    version: '2.88.6',
    repository: 'https://github.com/cypress-io/request',
    license: 'Apache',
  },
  {
    name: '@cypress/webpack-dev-server',
    version: '1.7.0',
    repository: 'https://github.com/cypress-io/cypress',
    license: '',
  },
  {
    name: '@cypress/xvfb',
    version: '1.2.4',
    repository: 'https://github.com/cypress-io/xvfb',
    license: '',
  },
  {
    name: '@date-io/core',
    version: '2.11.0',
    repository: 'https://github.com/dmtrKovalenko/date-io',
    license: 'MIT',
  },
  {
    name: '@date-io/date-fns',
    version: '2.11.0',
    repository: 'https://github.com/dmtrKovalenko/date-io',
    license: 'MIT',
  },
  {
    name: '@date-io/dayjs',
    version: '2.11.0',
    repository: 'https://github.com/dmtrKovalenko/date-io',
    license: 'MIT',
  },
  {
    name: '@date-io/luxon',
    version: '2.11.1',
    repository: 'https://github.com/dmtrKovalenko/date-io',
    license: 'MIT',
  },
  {
    name: '@date-io/moment',
    version: '2.11.0',
    repository: 'https://github.com/dmtrKovalenko/date-io',
    license: 'MIT',
  },
  {
    name: '@emotion/babel-plugin',
    version: '11.7.2',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/babel-plugin',
    license: 'MIT',
  },
  {
    name: '@emotion/cache',
    version: '11.7.1',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/cache',
    license: 'MIT',
  },
  {
    name: '@emotion/hash',
    version: '0.8.0',
    repository: 'https://github.com/emotion-js/emotion/tree/master/packages/hash',
    license: 'MIT',
  },
  {
    name: '@emotion/is-prop-valid',
    version: '1.1.1',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/is-prop-valid',
    license: 'MIT',
  },
  {
    name: '@emotion/memoize',
    version: '0.7.5',
    repository: 'https://github.com/emotion-js/emotion/tree/master/packages/memoize',
    license: 'MIT',
  },
  {
    name: '@emotion/react',
    version: '11.7.1',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/react',
    license: 'MIT',
  },
  {
    name: '@emotion/serialize',
    version: '1.0.2',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/serialize',
    license: 'MIT',
  },
  {
    name: '@emotion/sheet',
    version: '1.1.0',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/sheet',
    license: 'MIT',
  },
  {
    name: '@emotion/styled',
    version: '11.6.0',
    repository: 'https://github.com/emotion-js/emotion/tree/main/packages/styled',
    license: 'MIT',
  },
  {
    name: '@emotion/unitless',
    version: '0.7.5',
    repository: 'https://github.com/emotion-js/emotion/tree/master/packages/unitless',
    license: 'MIT',
  },
  {
    name: '@emotion/utils',
    version: '1.0.0',
    repository: 'https://github.com/emotion-js/emotion/tree/master/packages/utils',
    license: 'MIT',
  },
  {
    name: '@emotion/weak-memoize',
    version: '0.2.5',
    repository: 'https://github.com/emotion-js/emotion/tree/master/packages/weak-memoize',
    license: 'MIT',
  },
  {
    name: '@eslint/eslintrc',
    version: '0.4.3',
    repository: 'eslint/eslintrc',
    license: 'MIT',
  },
  {
    name: '@gar/promisify',
    version: '1.1.2',
    repository: 'https://github.com/wraithgar/gar-promisify',
    license: '',
  },
  {
    name: '@hapi/address',
    version: '2.1.4',
    repository: 'http://github.com/hapijs/address',
    license: '',
  },
  {
    name: '@hapi/bourne',
    version: '1.3.2',
    repository: 'http://github.com/hapijs/bourne',
    license: '',
  },
  {
    name: '@hapi/hoek',
    version: '8.5.1',
    repository: 'http://github.com/hapijs/hoek',
    license: '',
  },
  {
    name: '@hapi/joi',
    version: '15.1.1',
    repository: 'http://github.com/hapijs/joi',
    license: '',
  },
  {
    name: '@hapi/topo',
    version: '3.1.6',
    repository: 'http://github.com/hapijs/topo',
    license: '',
  },
  {
    name: '@humanwhocodes/config-array',
    version: '0.5.0',
    repository: 'https://github.com/humanwhocodes/config-array',
    license: 'Apache',
  },
  {
    name: '@humanwhocodes/object-schema',
    version: '1.2.1',
    repository: 'https://github.com/humanwhocodes/object-schema',
    license: 'BSD',
  },
  {
    name: '@istanbuljs/load-nyc-config',
    version: '1.1.0',
    repository: 'https://github.com/istanbuljs/load-nyc-config',
    license: 'ISC',
  },
  {
    name: '@istanbuljs/schema',
    version: '0.1.3',
    repository: 'https://github.com/istanbuljs/schema',
    license: 'MIT',
  },
  {
    name: '@jest/console',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/core',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/environment',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/fake-timers',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/globals',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/reporters',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/source-map',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/test-result',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/test-sequencer',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/transform',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jest/types',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: '@jridgewell/gen-mapping',
    version: '0.1.1',
    repository: 'https://github.com/jridgewell/gen-mapping',
    license: '',
  },
  {
    name: '@jridgewell/resolve-uri',
    version: '3.1.0',
    repository: 'https://github.com/jridgewell/resolve-uri',
    license: '',
  },
  {
    name: '@jridgewell/set-array',
    version: '1.1.2',
    repository: 'https://github.com/jridgewell/set-array',
    license: '',
  },
  {
    name: '@jridgewell/sourcemap-codec',
    version: '1.4.14',
    repository: 'https://github.com/jridgewell/sourcemap-codec',
    license: 'MIT',
  },
  {
    name: '@jridgewell/trace-mapping',
    version: '0.3.15',
    repository: 'https://github.com/jridgewell/trace-mapping',
    license: '',
  },
  {
    name: '@material-ui/core',
    version: '4.12.3',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@material-ui/icons',
    version: '4.11.2',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@material-ui/lab',
    version: '4.0.0-alpha.60',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@material-ui/styles',
    version: '4.11.4',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@material-ui/system',
    version: '4.12.1',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@material-ui/types',
    version: '5.1.0',
    repository: 'https://github.com/mui-org/material-ui',
    license: '',
  },
  {
    name: '@material-ui/utils',
    version: '4.11.2',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-analytics-js',
    version: '2.7.1',
    repository:
      'https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-analytics-js',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-channel-js',
    version: '2.7.1',
    repository: 'github:Microsoft/applicationinsights-js',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-common',
    version: '2.7.1',
    repository: 'https://github.com/microsoft/ApplicationInsights-JS/tree/master/shared/AppInsightsCommon',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-core-js',
    version: '2.7.1',
    repository: 'https://github.com/microsoft/ApplicationInsights-JS/tree/master/shared/AppInsightsCore',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-dependencies-js',
    version: '2.7.1',
    repository:
      'https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-dependencies-js',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-properties-js',
    version: '2.7.1',
    repository:
      'https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-properties-js',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-react-js',
    version: '3.2.1',
    repository:
      'https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-react-js',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-shims',
    version: '2.0.0',
    repository: 'https://github.com/microsoft/ApplicationInsights-JS/tree/master/tools/shims',
    license: 'MIT',
  },
  {
    name: '@microsoft/applicationinsights-web',
    version: '2.7.1',
    repository: 'https://github.com/microsoft/ApplicationInsights-JS',
    license: 'MIT',
  },
  {
    name: '@microsoft/dynamicproto-js',
    version: '1.1.4',
    repository: 'https://github.com/microsoft/DynamicProto-JS',
    license: 'MIT',
  },
  {
    name: '@mui/base',
    version: '5.0.0-alpha.63',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/codemod',
    version: '5.2.2',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/icons-material',
    version: '5.2.5',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/lab',
    version: '5.0.0-alpha.63',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/material',
    version: '5.2.7',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/private-theming',
    version: '5.2.3',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/styled-engine',
    version: '5.2.6',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/styles',
    version: '5.2.3',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/system',
    version: '5.2.6',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/types',
    version: '7.1.0',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@mui/utils',
    version: '5.2.3',
    repository: 'https://github.com/mui-org/material-ui',
    license: 'MIT',
  },
  {
    name: '@nodelib/fs.scandir',
    version: '2.1.5',
    repository: 'https://github.com/nodelib/nodelib/tree/master/packages/fs/fs.scandir',
    license: 'MIT',
  },
  {
    name: '@nodelib/fs.stat',
    version: '2.0.5',
    repository: 'https://github.com/nodelib/nodelib/tree/master/packages/fs/fs.stat',
    license: 'MIT',
  },
  {
    name: '@nodelib/fs.walk',
    version: '1.2.8',
    repository: 'https://github.com/nodelib/nodelib/tree/master/packages/fs/fs.walk',
    license: 'MIT',
  },
  {
    name: '@npmcli/fs',
    version: '1.0.0',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: '@npmcli/move-file',
    version: '1.1.2',
    repository: 'https://github.com/npm/move-file',
    license: 'MIT',
  },
  {
    name: '@pmmmwh/react-refresh-webpack-plugin',
    version: '0.4.3',
    repository: 'https://github.com/pmmmwh/react-refresh-webpack-plugin',
    license: 'MIT',
  },
  {
    name: '@popperjs/core',
    version: '2.11.2',
    repository: 'github:popperjs/popper-core',
    license: 'MIT',
  },
  {
    name: '@reduxjs/toolkit',
    version: '1.6.2',
    repository: 'https://github.com/reduxjs/redux-toolkit',
    license: 'MIT',
  },
  {
    name: '@rollup/plugin-node-resolve',
    version: '7.1.3',
    repository: 'rollup/plugins',
    license: 'MIT',
  },
  {
    name: '@rollup/plugin-replace',
    version: '2.4.2',
    repository: 'rollup/plugins',
    license: 'MIT',
  },
  {
    name: '@rollup/pluginutils',
    version: '3.1.0',
    repository: 'rollup/plugins',
    license: 'MIT',
  },
  {
    name: '@sinonjs/commons',
    version: '1.8.3',
    repository: 'https://github.com/sinonjs/commons',
    license: 'BSD',
  },
  {
    name: '@sinonjs/fake-timers',
    version: '6.0.1',
    repository: 'http://github.com/sinonjs/fake-timers',
    license: '',
  },
  {
    name: '@surma/rollup-plugin-off-main-thread',
    version: '1.4.2',
    repository: 'https://github.com/surma/rollup-plugin-off-main-thread',
    license: 'Apache',
  },
  {
    name: '@svgr/babel-plugin-add-jsx-attribute',
    version: '5.4.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-add-jsx-attribute',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-remove-jsx-attribute',
    version: '5.4.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-remove-jsx-attribute',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-remove-jsx-empty-expression',
    version: '5.0.1',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-remove-jsx-empty-expression',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-replace-jsx-attribute-value',
    version: '5.0.1',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-replace-jsx-attribute-value',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-svg-dynamic-title',
    version: '5.4.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-svg-dynamic-title',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-svg-em-dimensions',
    version: '5.4.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-svg-em-dimensions',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-transform-react-native-svg',
    version: '5.4.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-transform-react-native-svg',
    license: '',
  },
  {
    name: '@svgr/babel-plugin-transform-svg-component',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-plugin-transform-svg-component',
    license: '',
  },
  {
    name: '@svgr/babel-preset',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/babel-preset',
    license: '',
  },
  {
    name: '@svgr/core',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/core',
    license: '',
  },
  {
    name: '@svgr/hast-util-to-babel-ast',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/hast-util-to-babel-ast',
    license: '',
  },
  {
    name: '@svgr/plugin-jsx',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/plugin-jsx',
    license: '',
  },
  {
    name: '@svgr/plugin-svgo',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/plugin-svgo',
    license: '',
  },
  {
    name: '@svgr/webpack',
    version: '5.5.0',
    repository: 'https://github.com/gregberge/svgr/tree/master/packages/webpack',
    license: '',
  },
  {
    name: '@testing-library/dom',
    version: '8.11.0',
    repository: 'https://github.com/testing-library/dom-testing-library',
    license: 'MIT',
  },
  {
    name: '@testing-library/jest-dom',
    version: '5.15.0',
    repository: 'https://github.com/testing-library/jest-dom',
    license: 'MIT',
  },
  {
    name: '@testing-library/react',
    version: '12.1.2',
    repository: 'https://github.com/testing-library/react-testing-library',
    license: 'MIT',
  },
  {
    name: '@testing-library/user-event',
    version: '13.5.0',
    repository: 'https://github.com/testing-library/user-event',
    license: 'MIT',
  },
  {
    name: '@tootallnate/once',
    version: '1.1.2',
    repository: 'http://github.com/TooTallNate/once',
    license: '',
  },
  {
    name: '@trivago/prettier-plugin-sort-imports',
    version: '3.1.1',
    repository: 'https://github.com/trivago/prettier-plugin-sort-imports',
    license: 'Apache',
  },
  {
    name: '@types/aria-query',
    version: '4.2.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/babel__core',
    version: '7.1.16',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/babel__generator',
    version: '7.6.3',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/babel__template',
    version: '7.4.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/babel__traverse',
    version: '7.14.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/dompurify',
    version: '2.3.4',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/eslint',
    version: '7.28.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/estree',
    version: '0.0.39',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@types/glob',
    version: '7.2.0',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/graceful-fs',
    version: '4.1.5',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/history',
    version: '4.7.9',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/hoist-non-react-statics',
    version: '3.3.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/html-minifier-terser',
    version: '5.1.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/istanbul-lib-coverage',
    version: '2.0.3',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/istanbul-lib-report',
    version: '3.0.0',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/istanbul-reports',
    version: '3.0.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/jest',
    version: '26.0.24',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/js-cookie',
    version: '3.0.0',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/json-schema',
    version: '7.0.9',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/json5',
    version: '0.0.29',
    repository: 'https://www.github.com/DefinitelyTyped/DefinitelyTyped',
    license: '',
  },
  {
    name: '@types/lodash',
    version: '4.14.182',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/lodash.debounce',
    version: '4.0.7',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/material-ui',
    version: '0.21.12',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/minimatch',
    version: '3.0.5',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/node',
    version: '14.17.32',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@types/normalize-package-data',
    version: '2.4.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/parse-json',
    version: '4.0.0',
    repository: 'https://www.github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/prettier',
    version: '2.4.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/prop-types',
    version: '15.7.4',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/q',
    version: '1.5.5',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react',
    version: '17.0.34',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-addons-linked-state-mixin',
    version: '0.14.22',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-csv',
    version: '1.1.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-dom',
    version: '17.0.11',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-is',
    version: '17.0.3',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-redux',
    version: '7.1.20',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-router',
    version: '5.1.17',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-router-dom',
    version: '5.3.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/react-transition-group',
    version: '4.4.4',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/resolve',
    version: '0.0.8',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped.git',
    license: 'MIT',
  },
  {
    name: '@types/scheduler',
    version: '0.16.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/sinonjs__fake-timers',
    version: '6.0.4',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/sizzle',
    version: '2.3.3',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/source-list-map',
    version: '0.1.2',
    repository: 'https://www.github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/stack-utils',
    version: '2.0.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/tapable',
    version: '1.0.8',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/testing-library__jest-dom',
    version: '5.14.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/trusted-types',
    version: '2.0.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/uglify-js',
    version: '3.13.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/uuid',
    version: '8.3.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/webpack',
    version: '4.41.31',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/webpack-sources',
    version: '3.2.0',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/yargs',
    version: '15.0.14',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/yargs-parser',
    version: '20.2.1',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@types/yauzl',
    version: '2.9.2',
    repository: 'https://github.com/DefinitelyTyped/DefinitelyTyped',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/eslint-plugin',
    version: '4.33.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/experimental-utils',
    version: '3.10.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/parser',
    version: '4.33.0',
    repository: '(none)',
    license: '',
  },
  {
    name: '@typescript-eslint/scope-manager',
    version: '4.33.0',
    repository: 'https://github.com/typescript-eslint/typescript-eslint',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/type-utils',
    version: '5.10.2',
    repository: 'https://github.com/typescript-eslint/typescript-eslint',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/types',
    version: '3.10.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/typescript-estree',
    version: '3.10.1',
    repository: '(none)',
    license: '',
  },
  {
    name: '@typescript-eslint/utils',
    version: '5.10.2',
    repository: 'https://github.com/typescript-eslint/typescript-eslint',
    license: 'MIT',
  },
  {
    name: '@typescript-eslint/visitor-keys',
    version: '3.10.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/ast',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/floating-point-hex-parser',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-api-error',
    version: '1.9.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-buffer',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-code-frame',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-fsm',
    version: '1.9.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-module-context',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-wasm-bytecode',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/helper-wasm-section',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/ieee754',
    version: '1.9.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/leb128',
    version: '1.9.0',
    repository: '(none)',
    license: 'Apache',
  },
  {
    name: '@webassemblyjs/utf8',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/wasm-edit',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/wasm-gen',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/wasm-opt',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/wasm-parser',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/wast-parser',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@webassemblyjs/wast-printer',
    version: '1.9.0',
    repository: 'https://github.com/xtuc/webassemblyjs',
    license: 'MIT',
  },
  {
    name: '@xtuc/ieee754',
    version: '1.2.0',
    repository: 'http://github.com/feross/ieee754',
    license: '',
  },
  {
    name: '@xtuc/long',
    version: '4.2.2',
    repository: 'https://github.com/dcodeIO/long.js',
    license: 'Apache',
  },
  {
    name: 'abab',
    version: '2.0.5',
    repository: 'https://github.com/jsdom/abab',
    license: 'BSD',
  },
  {
    name: 'accepts',
    version: '1.3.7',
    repository: 'jshttp/accepts',
    license: 'MIT',
  },
  {
    name: 'acorn',
    version: '6.4.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'acorn-globals',
    version: '6.0.0',
    repository: 'https://github.com/ForbesLindesay/acorn-globals',
    license: '',
  },
  {
    name: 'acorn-jsx',
    version: '5.3.2',
    repository: 'https://github.com/acornjs/acorn-jsx',
    license: '',
  },
  {
    name: 'acorn-walk',
    version: '7.2.0',
    repository: 'https://github.com/acornjs/acorn',
    license: '',
  },
  {
    name: 'address',
    version: '1.1.2',
    repository: 'http://github.com/node-modules/address',
    license: 'MIT',
  },
  {
    name: 'adjust-sourcemap-loader',
    version: '3.0.0',
    repository: 'https://github.com/bholloway/adjust-sourcemap-loader',
    license: 'MIT',
  },
  {
    name: 'agent-base',
    version: '6.0.2',
    repository: 'http://github.com/TooTallNate/node-agent-base',
    license: '',
  },
  {
    name: 'aggregate-error',
    version: '3.1.0',
    repository: 'sindresorhus/aggregate-error',
    license: 'MIT',
  },
  {
    name: 'ajv',
    version: '6.12.6',
    repository: 'https://github.com/ajv-validator/ajv',
    license: 'MIT',
  },
  {
    name: 'ajv-errors',
    version: '1.0.1',
    repository: 'https://github.com/epoberezkin/ajv-errors',
    license: 'MIT',
  },
  {
    name: 'ajv-keywords',
    version: '3.5.2',
    repository: 'https://github.com/epoberezkin/ajv-keywords',
    license: 'MIT',
  },
  {
    name: 'alphanum-sort',
    version: '1.0.2',
    repository: 'https://github.com/TrySound/alphanum-sort',
    license: '',
  },
  {
    name: 'ansi-colors',
    version: '3.2.4',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'ansi-escapes',
    version: '4.3.2',
    repository: 'sindresorhus/ansi-escapes',
    license: 'MIT',
  },
  {
    name: 'ansi-html',
    version: '0.0.7',
    repository: 'http://github.com/Tjatse/ansi-html',
    license: 'Apache',
  },
  {
    name: 'ansi-regex',
    version: '2.1.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'ansi-styles',
    version: '3.2.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'anymatch',
    version: '2.0.0',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'aproba',
    version: '1.2.0',
    repository: 'https://github.com/iarna/aproba',
    license: '',
  },
  {
    name: 'arch',
    version: '2.2.0',
    repository: 'http://github.com/feross/arch',
    license: 'MIT',
  },
  {
    name: 'argparse',
    version: '1.0.10',
    repository: 'nodeca/argparse',
    license: 'MIT',
  },
  {
    name: 'aria-query',
    version: '4.2.2',
    repository: 'https://github.com/A11yance/aria-query',
    license: 'Apache',
  },
  {
    name: 'arity-n',
    version: '1.0.4',
    repository: 'http://github.com/stoeffel/arityN',
    license: 'MIT',
  },
  {
    name: 'arr-diff',
    version: '4.0.0',
    repository: 'jonschlinkert/arr-diff',
    license: 'MIT',
  },
  {
    name: 'arr-flatten',
    version: '1.1.0',
    repository: 'jonschlinkert/arr-flatten',
    license: 'MIT',
  },
  {
    name: 'arr-union',
    version: '3.1.0',
    repository: 'jonschlinkert/arr-union',
    license: 'MIT',
  },
  {
    name: 'array-flatten',
    version: '1.1.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'array-includes',
    version: '3.1.4',
    repository: 'http://github.com/es-shims/array-includes',
    license: 'MIT',
  },
  {
    name: 'array-union',
    version: '1.0.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'array-uniq',
    version: '1.0.3',
    repository: 'sindresorhus/array-uniq',
    license: 'MIT',
  },
  {
    name: 'array-unique',
    version: '0.3.2',
    repository: 'jonschlinkert/array-unique',
    license: 'MIT',
  },
  {
    name: 'array.prototype.flat',
    version: '1.2.5',
    repository: 'http://github.com/es-shims/Array.prototype.flat',
    license: 'MIT',
  },
  {
    name: 'array.prototype.flatmap',
    version: '1.2.5',
    repository: 'http://github.com/es-shims/Array.prototype.flatMap',
    license: 'MIT',
  },
  {
    name: 'arrify',
    version: '2.0.1',
    repository: 'sindresorhus/arrify',
    license: 'MIT',
  },
  {
    name: 'asap',
    version: '2.0.6',
    repository: 'https://github.com/kriskowal/asap',
    license: '',
  },
  {
    name: 'asn1',
    version: '0.2.6',
    repository: 'https://github.com/joyent/node-asn1',
    license: '',
  },
  {
    name: 'asn1.js',
    version: '5.4.1',
    repository: 'git@github.com:indutny/asn1.js',
    license: 'MIT',
  },
  {
    name: 'assert',
    version: '1.5.0',
    repository: 'http://github.com/browserify/commonjs-assert',
    license: '',
  },
  {
    name: 'assert-plus',
    version: '1.0.0',
    repository: 'https://github.com/mcavage/node-assert-plus',
    license: '',
  },
  {
    name: 'assign-symbols',
    version: '1.0.0',
    repository: 'jonschlinkert/assign-symbols',
    license: 'MIT',
  },
  {
    name: 'ast-types',
    version: '0.14.2',
    repository: 'http://github.com/benjamn/ast-types',
    license: '',
  },
  {
    name: 'ast-types-flow',
    version: '0.0.7',
    repository: 'https://github.com/kyldvs/ast-types-flow',
    license: '',
  },
  {
    name: 'astral-regex',
    version: '2.0.0',
    repository: 'kevva/astral-regex',
    license: 'MIT',
  },
  {
    name: 'async',
    version: '2.6.3',
    repository: 'https://github.com/caolan/async',
    license: '',
  },
  {
    name: 'async-each',
    version: '1.0.3',
    repository: 'http://github.com/paulmillr/async-each',
    license: '',
  },
  {
    name: 'async-limiter',
    version: '1.0.1',
    repository: 'https://github.com/strml/async-limiter',
    license: 'MIT',
  },
  {
    name: 'asynckit',
    version: '0.4.0',
    repository: 'https://github.com/alexindigo/asynckit',
    license: 'MIT',
  },
  {
    name: 'at-least-node',
    version: '1.0.0',
    repository: 'https://github.com/RyanZim/at-least-node',
    license: 'ISC',
  },
  {
    name: 'atob',
    version: '2.1.2',
    repository: 'http://git.coolaj86.com/coolaj86/atob.js',
    license: 'Apache;MIT',
  },
  {
    name: 'autobind-decorator',
    version: '2.4.0',
    repository: 'https://github.com/andreypopp/autobind-decorator',
    license: 'MIT',
  },
  {
    name: 'autoprefixer',
    version: '9.8.8',
    repository: 'postcss/autoprefixer',
    license: 'MIT',
  },
  {
    name: 'aws-sign2',
    version: '0.7.0',
    repository: 'https://github.com/mikeal/aws-sign',
    license: 'Apache',
  },
  {
    name: 'aws4',
    version: '1.11.0',
    repository: 'github:mhart/aws4',
    license: '',
  },
  {
    name: 'axe-core',
    version: '4.3.5',
    repository: 'https://github.com/dequelabs/axe-core',
    license: 'MPL',
  },
  {
    name: 'axios',
    version: '0.24.0',
    repository: 'https://github.com/axios/axios',
    license: '',
  },
  {
    name: 'axobject-query',
    version: '2.2.0',
    repository: 'https://github.com/A11yance/axobject-query',
    license: 'Apache',
  },
  {
    name: 'babel-core',
    version: '7.0.0-bridge.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'babel-eslint',
    version: '10.1.0',
    repository: 'https://github.com/babel/babel-eslint',
    license: 'MIT',
  },
  {
    name: 'babel-extract-comments',
    version: '1.0.0',
    repository: 'jonschlinkert/babel-extract-comments',
    license: 'MIT',
  },
  {
    name: 'babel-jest',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'babel-loader',
    version: '8.1.0',
    repository: 'https://github.com/babel/babel-loader',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-dynamic-import-node',
    version: '2.3.3',
    repository: 'https://github.com/airbnb/babel-plugin-dynamic-import-node',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-istanbul',
    version: '6.1.1',
    repository: 'https://github.com/istanbuljs/babel-plugin-istanbul',
    license: '',
  },
  {
    name: 'babel-plugin-jest-hoist',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-macros',
    version: '2.8.0',
    repository: 'https://github.com/kentcdodds/babel-plugin-macros',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-named-asset-import',
    version: '0.3.7',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-polyfill-corejs2',
    version: '0.2.3',
    repository: 'https://github.com/babel/babel-polyfills',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-polyfill-corejs3',
    version: '0.3.0',
    repository: 'https://github.com/babel/babel-polyfills',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-polyfill-regenerator',
    version: '0.2.3',
    repository: 'https://github.com/babel/babel-polyfills',
    license: 'MIT',
  },
  {
    name: 'babel-plugin-syntax-object-rest-spread',
    version: '6.13.0',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-syntax-object-rest-spread',
    license: '',
  },
  {
    name: 'babel-plugin-transform-object-rest-spread',
    version: '6.26.0',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-plugin-transform-object-rest-spread',
    license: '',
  },
  {
    name: 'babel-plugin-transform-react-remove-prop-types',
    version: '0.4.24',
    repository: 'https://github.com/oliviertassinari/babel-plugin-transform-react-remove-prop-types',
    license: 'MIT',
  },
  {
    name: 'babel-preset-current-node-syntax',
    version: '1.0.1',
    repository: 'https://github.com/nicolo-ribaudo/babel-preset-current-node-syntax',
    license: 'MIT',
  },
  {
    name: 'babel-preset-jest',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'babel-preset-react-app',
    version: '10.0.0',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'babel-runtime',
    version: '6.26.0',
    repository: 'https://github.com/babel/babel/tree/master/packages/babel-runtime',
    license: '',
  },
  {
    name: 'babylon',
    version: '6.18.0',
    repository: 'https://github.com/babel/babylon',
    license: '',
  },
  {
    name: 'balanced-match',
    version: '1.0.2',
    repository: 'http://github.com/juliangruber/balanced-match',
    license: 'MIT',
  },
  {
    name: 'base',
    version: '0.11.2',
    repository: 'node-base/base',
    license: 'MIT',
  },
  {
    name: 'base16',
    version: '1.0.0',
    repository: 'https://github.com/gaearon/base16-js',
    license: 'MIT',
  },
  {
    name: 'base64-js',
    version: '1.5.1',
    repository: 'http://github.com/beatgammit/base64-js',
    license: 'MIT',
  },
  {
    name: 'batch',
    version: '0.6.1',
    repository: 'https://github.com/visionmedia/batch',
    license: 'MIT',
  },
  {
    name: 'bcrypt-pbkdf',
    version: '1.0.2',
    repository: 'http://github.com/joyent/node-bcrypt-pbkdf',
    license: '',
  },
  {
    name: 'bfj',
    version: '7.0.2',
    repository: 'https://gitlab.com/philbooth/bfj',
    license: '',
  },
  {
    name: 'big.js',
    version: '5.2.2',
    repository: 'https://github.com/MikeMcl/big.js',
    license: 'MIT',
  },
  {
    name: 'binary-extensions',
    version: '1.13.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'bindings',
    version: '1.5.0',
    repository: 'http://github.com/TooTallNate/node-bindings',
    license: 'MIT',
  },
  {
    name: 'blob-util',
    version: '2.0.2',
    repository: 'http://github.com/nolanlawson/blob-util',
    license: 'Apache',
  },
  {
    name: 'bluebird',
    version: '3.7.2',
    repository: 'http://github.com/petkaantonov/bluebird',
    license: 'MIT',
  },
  {
    name: 'bn.js',
    version: '4.12.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'body-parser',
    version: '1.19.0',
    repository: 'expressjs/body-parser',
    license: 'MIT',
  },
  {
    name: 'bonjour',
    version: '3.5.0',
    repository: 'https://github.com/watson/bonjour',
    license: 'MIT',
  },
  {
    name: 'boolbase',
    version: '1.0.0',
    repository: 'https://github.com/fb55/boolbase',
    license: '',
  },
  {
    name: 'brace-expansion',
    version: '1.1.11',
    repository: 'http://github.com/juliangruber/brace-expansion',
    license: 'MIT',
  },
  {
    name: 'braces',
    version: '2.3.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'brorand',
    version: '1.1.0',
    repository: 'git@github.com:indutny/brorand',
    license: '',
  },
  {
    name: 'browser-process-hrtime',
    version: '1.0.0',
    repository: 'http://github.com/kumavis/browser-process-hrtime',
    license: '',
  },
  {
    name: 'browserify-aes',
    version: '1.2.0',
    repository: 'http://github.com/crypto-browserify/browserify-aes',
    license: 'MIT',
  },
  {
    name: 'browserify-cipher',
    version: '1.0.1',
    repository: 'git@github.com:crypto-browserify/browserify-cipher',
    license: 'MIT',
  },
  {
    name: 'browserify-des',
    version: '1.0.2',
    repository: 'https://github.com/crypto-browserify/browserify-des',
    license: 'MIT',
  },
  {
    name: 'browserify-rsa',
    version: '4.1.0',
    repository: 'https://github.com:crypto-browserify/browserify-rsa',
    license: 'MIT',
  },
  {
    name: 'browserify-sign',
    version: '4.2.1',
    repository: 'https://github.com/crypto-browserify/browserify-sign',
    license: '',
  },
  {
    name: 'browserify-zlib',
    version: '0.2.0',
    repository: 'https://github.com/devongovett/browserify-zlib',
    license: 'MIT',
  },
  {
    name: 'browserslist',
    version: '4.14.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'bser',
    version: '2.1.1',
    repository: 'https://github.com/facebook/watchman',
    license: '',
  },
  {
    name: 'buffer',
    version: '4.9.2',
    repository: 'http://github.com/feross/buffer',
    license: 'MIT',
  },
  {
    name: 'buffer-crc32',
    version: '0.2.13',
    repository: 'http://github.com/brianloveswords/buffer-crc32',
    license: 'MIT',
  },
  {
    name: 'buffer-from',
    version: '1.1.2',
    repository: 'LinusU/buffer-from',
    license: 'MIT',
  },
  {
    name: 'buffer-indexof',
    version: '1.1.1',
    repository: 'http://github.com/soldair/node-buffer-indexof',
    license: 'MIT',
  },
  {
    name: 'buffer-xor',
    version: '1.0.3',
    repository: 'https://github.com/crypto-browserify/buffer-xor',
    license: 'MIT',
  },
  {
    name: 'builtin-modules',
    version: '3.2.0',
    repository: 'sindresorhus/builtin-modules',
    license: 'MIT',
  },
  {
    name: 'builtin-status-codes',
    version: '3.0.0',
    repository: 'bendrucker/builtin-status-codes',
    license: 'MIT',
  },
  {
    name: 'bytes',
    version: '3.0.0',
    repository: 'visionmedia/bytes.js',
    license: 'MIT',
  },
  {
    name: 'cacache',
    version: '12.0.4',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'cache-base',
    version: '1.0.1',
    repository: 'jonschlinkert/cache-base',
    license: 'MIT',
  },
  {
    name: 'cachedir',
    version: '2.3.0',
    repository: 'LinusU/node-cachedir',
    license: 'MIT',
  },
  {
    name: 'call-bind',
    version: '1.0.2',
    repository: 'https://github.com/ljharb/call-bind',
    license: 'MIT',
  },
  {
    name: 'caller-callsite',
    version: '2.0.0',
    repository: 'sindresorhus/caller-callsite',
    license: 'MIT',
  },
  {
    name: 'caller-path',
    version: '2.0.0',
    repository: 'sindresorhus/caller-path',
    license: 'MIT',
  },
  {
    name: 'callsites',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'camel-case',
    version: '4.1.2',
    repository: 'http://github.com/blakeembrey/change-case',
    license: 'MIT',
  },
  {
    name: 'camelcase',
    version: '5.3.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'caniuse-api',
    version: '3.0.0',
    repository: 'https://github.com/nyalab/caniuse-api',
    license: 'MIT',
  },
  {
    name: 'caniuse-lite',
    version: '1.0.30001382',
    repository: 'browserslist/caniuse-lite',
    license: 'Public Domain',
  },
  {
    name: 'capture-exit',
    version: '2.0.0',
    repository: 'https://github.com/stefanpenner/capture-exit',
    license: '',
  },
  {
    name: 'case-sensitive-paths-webpack-plugin',
    version: '2.3.0',
    repository: 'https://github.com/Urthen/case-sensitive-paths-webpack-plugin',
    license: 'MIT',
  },
  {
    name: 'caseless',
    version: '0.12.0',
    repository: 'https://github.com/mikeal/caseless',
    license: 'Apache',
  },
  {
    name: 'chalk',
    version: '2.4.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'char-regex',
    version: '1.0.2',
    repository: 'https://github.com/Richienb/char-regex',
    license: 'MIT',
  },
  {
    name: 'check-more-types',
    version: '2.24.0',
    repository: 'https://github.com/kensho/check-more-types',
    license: 'MIT',
  },
  {
    name: 'check-types',
    version: '11.1.2',
    repository: 'https://gitlab.com/philbooth/check-types.js',
    license: '',
  },
  {
    name: 'chokidar',
    version: '2.1.8',
    repository: '(none)',
    license: '',
  },
  {
    name: 'chownr',
    version: '1.1.4',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'chrome-trace-event',
    version: '1.0.3',
    repository: 'github:samccone/chrome-trace-event',
    license: 'MIT',
  },
  {
    name: 'ci-info',
    version: '2.0.0',
    repository: 'https://github.com/watson/ci-info',
    license: 'MIT',
  },
  {
    name: 'cipher-base',
    version: '1.0.4',
    repository: 'https://github.com/crypto-browserify/cipher-base',
    license: 'MIT',
  },
  {
    name: 'cjs-module-lexer',
    version: '0.6.0',
    repository: 'https://github.com/guybedford/cjs-module-lexer',
    license: 'MIT',
  },
  {
    name: 'class-utils',
    version: '0.3.6',
    repository: 'jonschlinkert/class-utils',
    license: 'MIT',
  },
  {
    name: 'clean-css',
    version: '4.2.4',
    repository: 'https://github.com/jakubpawlowicz/clean-css',
    license: '',
  },
  {
    name: 'clean-stack',
    version: '2.2.0',
    repository: 'sindresorhus/clean-stack',
    license: 'MIT',
  },
  {
    name: 'cli-cursor',
    version: '3.1.0',
    repository: 'sindresorhus/cli-cursor',
    license: 'MIT',
  },
  {
    name: 'cli-table3',
    version: '0.6.0',
    repository: 'https://github.com/cli-table/cli-table3',
    license: 'MIT',
  },
  {
    name: 'cli-truncate',
    version: '2.1.0',
    repository: 'sindresorhus/cli-truncate',
    license: 'MIT',
  },
  {
    name: 'cliui',
    version: '5.0.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'clone',
    version: '2.1.2',
    repository: 'http://github.com/pvorb/node-clone',
    license: '',
  },
  {
    name: 'clone-deep',
    version: '4.0.1',
    repository: 'jonschlinkert/clone-deep',
    license: 'MIT',
  },
  {
    name: 'clsx',
    version: '1.1.1',
    repository: 'lukeed/clsx',
    license: 'MIT',
  },
  {
    name: 'co',
    version: '4.6.0',
    repository: 'tj/co',
    license: 'MIT',
  },
  {
    name: 'coa',
    version: '2.0.2',
    repository: 'http://github.com/veged/coa',
    license: 'MIT',
  },
  {
    name: 'collect-v8-coverage',
    version: '1.0.1',
    repository: 'SimenB/collect-v8-coverage',
    license: 'MIT',
  },
  {
    name: 'collection-visit',
    version: '1.0.0',
    repository: 'jonschlinkert/collection-visit',
    license: 'MIT',
  },
  {
    name: 'color',
    version: '3.2.1',
    repository: 'Qix-/color',
    license: '',
  },
  {
    name: 'color-convert',
    version: '1.9.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'color-name',
    version: '1.1.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'color-string',
    version: '1.6.0',
    repository: 'Qix-/color-string',
    license: '',
  },
  {
    name: 'colorette',
    version: '2.0.16',
    repository: 'jorgebucaran/colorette',
    license: '',
  },
  {
    name: 'colors',
    version: '1.4.0',
    repository: 'http://github.com/Marak/colors.js',
    license: 'MIT',
  },
  {
    name: 'combined-stream',
    version: '1.0.8',
    repository: 'http://github.com/felixge/node-combined-stream',
    license: '',
  },
  {
    name: 'commander',
    version: '2.20.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'common-tags',
    version: '1.8.0',
    repository: 'https://github.com/declandewet/common-tags',
    license: 'MIT',
  },
  {
    name: 'commondir',
    version: '1.0.1',
    repository: 'http://github.com/substack/node-commondir',
    license: 'MIT',
  },
  {
    name: 'component-emitter',
    version: '1.3.0',
    repository: 'https://github.com/component/emitter',
    license: 'MIT',
  },
  {
    name: 'compose-function',
    version: '3.0.3',
    repository: 'http://github.com/stoeffel/compose-function',
    license: '',
  },
  {
    name: 'compressible',
    version: '2.0.18',
    repository: 'jshttp/compressible',
    license: 'MIT',
  },
  {
    name: 'compression',
    version: '1.7.4',
    repository: 'expressjs/compression',
    license: 'MIT',
  },
  {
    name: 'concat-map',
    version: '0.0.1',
    repository: 'http://github.com/substack/node-concat-map',
    license: 'MIT',
  },
  {
    name: 'concat-stream',
    version: '1.6.2',
    repository: 'http://github.com/maxogden/concat-stream',
    license: 'MIT',
  },
  {
    name: 'confusing-browser-globals',
    version: '1.0.10',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'connect-history-api-fallback',
    version: '1.6.0',
    repository: 'http://github.com/bripkens/connect-history-api-fallback',
    license: 'MIT',
  },
  {
    name: 'console-browserify',
    version: '1.2.0',
    repository: 'http://github.com/browserify/console-browserify',
    license: '',
  },
  {
    name: 'constants-browserify',
    version: '1.0.0',
    repository: 'http://github.com/juliangruber/constants-browserify',
    license: '',
  },
  {
    name: 'content-disposition',
    version: '0.5.3',
    repository: 'jshttp/content-disposition',
    license: 'MIT',
  },
  {
    name: 'content-type',
    version: '1.0.4',
    repository: 'jshttp/content-type',
    license: 'MIT',
  },
  {
    name: 'convert-source-map',
    version: '0.3.5',
    repository: '(none)',
    license: '',
  },
  {
    name: 'cookie',
    version: '0.4.0',
    repository: 'jshttp/cookie',
    license: 'MIT',
  },
  {
    name: 'cookie-signature',
    version: '1.0.6',
    repository: 'https://github.com/visionmedia/node-cookie-signature',
    license: '',
  },
  {
    name: 'copy-concurrently',
    version: '1.0.5',
    repository: 'https://github.com/npm/copy-concurrently',
    license: '',
  },
  {
    name: 'copy-descriptor',
    version: '0.1.1',
    repository: 'jonschlinkert/copy-descriptor',
    license: 'MIT',
  },
  {
    name: 'core-js',
    version: '2.6.12',
    repository: '(none)',
    license: '',
  },
  {
    name: 'core-js-compat',
    version: '3.19.1',
    repository: 'https://github.com/zloirock/core-js',
    license: '',
  },
  {
    name: 'core-js-pure',
    version: '3.19.1',
    repository: 'https://github.com/zloirock/core-js',
    license: '',
  },
  {
    name: 'core-util-is',
    version: '1.0.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'cosmiconfig',
    version: '5.2.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'create-ecdh',
    version: '4.0.4',
    repository: 'https://github.com/crypto-browserify/createECDH',
    license: 'MIT',
  },
  {
    name: 'create-hash',
    version: '1.2.0',
    repository: 'git@github.com:crypto-browserify/createHash',
    license: 'MIT',
  },
  {
    name: 'create-hmac',
    version: '1.1.7',
    repository: 'https://github.com/crypto-browserify/createHmac',
    license: 'MIT',
  },
  {
    name: 'cross-fetch',
    version: '3.1.4',
    repository: 'https://github.com/lquixada/cross-fetch',
    license: 'MIT',
  },
  {
    name: 'cross-spawn',
    version: '6.0.5',
    repository: 'git@github.com:moxystudio/node-cross-spawn',
    license: 'MIT',
  },
  {
    name: 'crypto-browserify',
    version: '3.12.0',
    repository: 'http://github.com/crypto-browserify/crypto-browserify',
    license: 'MIT',
  },
  {
    name: 'crypto-js',
    version: '4.1.1',
    repository: 'http://github.com/brix/crypto-js',
    license: 'MIT',
  },
  {
    name: 'crypto-random-string',
    version: '1.0.0',
    repository: 'sindresorhus/crypto-random-string',
    license: 'MIT',
  },
  {
    name: 'css',
    version: '2.2.4',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'css-blank-pseudo',
    version: '0.1.4',
    repository: 'csstools/css-blank-pseudo',
    license: '',
  },
  {
    name: 'css-color-names',
    version: '0.0.4',
    repository: 'http://github.com/bahamas10/css-color-names',
    license: '',
  },
  {
    name: 'css-declaration-sorter',
    version: '4.0.1',
    repository: 'https://github.com/Siilwyn/css-declaration-sorter',
    license: 'MIT',
  },
  {
    name: 'css-has-pseudo',
    version: '0.10.0',
    repository: 'csstools/css-has-pseudo',
    license: '',
  },
  {
    name: 'css-loader',
    version: '4.3.0',
    repository: 'webpack-contrib/css-loader',
    license: '',
  },
  {
    name: 'css-prefers-color-scheme',
    version: '3.1.1',
    repository: 'csstools/css-prefers-color-scheme',
    license: '',
  },
  {
    name: 'css-select',
    version: '2.1.0',
    repository: 'http://github.com/fb55/css-select',
    license: '',
  },
  {
    name: 'css-select-base-adapter',
    version: '0.1.1',
    repository: 'https://github.com/nrkn/css-select-base-adapter',
    license: 'MIT',
  },
  {
    name: 'css-tree',
    version: '1.0.0-alpha.37',
    repository: 'csstree/csstree',
    license: '',
  },
  {
    name: 'css-vendor',
    version: '2.0.8',
    repository: 'git@github.com:cssinjs/css-vendor',
    license: 'MIT',
  },
  {
    name: 'css-what',
    version: '3.4.2',
    repository: 'https://github.com/fb55/css-what',
    license: '',
  },
  {
    name: 'css.escape',
    version: '1.5.1',
    repository: 'https://github.com/mathiasbynens/CSS.escape',
    license: '',
  },
  {
    name: 'cssdb',
    version: '4.4.0',
    repository: 'csstools/cssdb',
    license: '',
  },
  {
    name: 'cssesc',
    version: '2.0.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'cssnano',
    version: '4.1.11',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'cssnano-preset-default',
    version: '4.0.8',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'cssnano-util-get-arguments',
    version: '4.0.0',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'cssnano-util-get-match',
    version: '4.0.0',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'cssnano-util-raw-cache',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'cssnano-util-same-parent',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'csso',
    version: '4.2.0',
    repository: 'css/csso',
    license: '',
  },
  {
    name: 'cssom',
    version: '0.3.8',
    repository: '(none)',
    license: '',
  },
  {
    name: 'cssstyle',
    version: '2.3.0',
    repository: 'jsdom/cssstyle',
    license: '',
  },
  {
    name: 'csstype',
    version: '2.6.18',
    repository: 'https://github.com/frenic/csstype',
    license: '',
  },
  {
    name: 'cyclist',
    version: '1.0.1',
    repository: 'http://github.com/mafintosh/cyclist',
    license: 'MIT',
  },
  {
    name: 'cypress',
    version: '8.7.0',
    repository: 'https://github.com/cypress-io/cypress',
    license: '',
  },
  {
    name: 'd',
    version: '1.0.1',
    repository: 'http://github.com/medikoo/d',
    license: 'ISC',
  },
  {
    name: 'damerau-levenshtein',
    version: '1.0.7',
    repository: 'https://github.com/tad-lispy/node-damerau-levenshtein',
    license: 'BSD',
  },
  {
    name: 'dashdash',
    version: '1.14.1',
    repository: 'http://github.com/trentm/node-dashdash',
    license: 'MIT',
  },
  {
    name: 'data-urls',
    version: '2.0.0',
    repository: 'jsdom/data-urls',
    license: '',
  },
  {
    name: 'date-fns',
    version: '2.28.0',
    repository: 'https://github.com/date-fns/date-fns',
    license: 'MIT',
  },
  {
    name: 'date-fns-tz',
    version: '1.2.2',
    repository: 'https://github.com/marnusw/date-fns-tz',
    license: 'MIT',
  },
  {
    name: 'dayjs',
    version: '1.10.7',
    repository: 'https://github.com/iamkun/dayjs',
    license: 'MIT',
  },
  {
    name: 'debug',
    version: '2.6.9',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'decamelize',
    version: '1.2.0',
    repository: 'sindresorhus/decamelize',
    license: 'MIT',
  },
  {
    name: 'decimal.js',
    version: '10.3.1',
    repository: 'https://github.com/MikeMcl/decimal.js',
    license: 'MIT',
  },
  {
    name: 'decode-uri-component',
    version: '0.2.0',
    repository: 'SamVerschueren/decode-uri-component',
    license: 'MIT',
  },
  {
    name: 'dedent',
    version: '0.7.0',
    repository: 'http://github.com/dmnd/dedent',
    license: 'MIT',
  },
  {
    name: 'deep-equal',
    version: '1.1.1',
    repository: 'http://github.com/substack/node-deep-equal',
    license: 'MIT',
  },
  {
    name: 'deep-is',
    version: '0.1.4',
    repository: 'http://github.com/thlorenz/deep-is',
    license: 'MIT',
  },
  {
    name: 'deepmerge',
    version: '4.2.2',
    repository: 'http://github.com/TehShrike/deepmerge',
    license: 'MIT',
  },
  {
    name: 'default-gateway',
    version: '4.2.0',
    repository: 'silverwind/default-gateway',
    license: '',
  },
  {
    name: 'define-properties',
    version: '1.1.3',
    repository: 'http://github.com/ljharb/define-properties',
    license: 'MIT',
  },
  {
    name: 'define-property',
    version: '0.2.5',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'del',
    version: '4.1.1',
    repository: 'sindresorhus/del',
    license: 'MIT',
  },
  {
    name: 'delayed-stream',
    version: '1.0.0',
    repository: 'http://github.com/felixge/node-delayed-stream',
    license: '',
  },
  {
    name: 'depd',
    version: '1.1.2',
    repository: 'dougwilson/nodejs-depd',
    license: 'MIT',
  },
  {
    name: 'des.js',
    version: '1.0.1',
    repository: 'ssh://git@github.com/indutny/des.js',
    license: '',
  },
  {
    name: 'destroy',
    version: '1.0.4',
    repository: 'stream-utils/destroy',
    license: 'MIT',
  },
  {
    name: 'detect-newline',
    version: '3.1.0',
    repository: 'sindresorhus/detect-newline',
    license: 'MIT',
  },
  {
    name: 'detect-node',
    version: '2.1.0',
    repository: 'https://github.com/iliakan/detect-node',
    license: 'MIT',
  },
  {
    name: 'detect-port-alt',
    version: '1.1.6',
    repository: 'http://github.com/node-modules/detect-port',
    license: 'MIT',
  },
  {
    name: 'diff-sequences',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'diffie-hellman',
    version: '5.0.3',
    repository: 'https://github.com/crypto-browserify/diffie-hellman',
    license: '',
  },
  {
    name: 'dir-glob',
    version: '3.0.1',
    repository: 'kevva/dir-glob',
    license: 'MIT',
  },
  {
    name: 'dns-equal',
    version: '1.0.0',
    repository: 'https://github.com/watson/dns-equal',
    license: 'MIT',
  },
  {
    name: 'dns-packet',
    version: '1.3.4',
    repository: 'https://github.com/mafintosh/dns-packet',
    license: 'MIT',
  },
  {
    name: 'dns-txt',
    version: '2.0.2',
    repository: 'https://github.com/watson/dns-txt',
    license: 'MIT',
  },
  {
    name: 'doctrine',
    version: '2.1.0',
    repository: '(none)',
    license: 'Apache',
  },
  {
    name: 'dom-accessibility-api',
    version: '0.5.10',
    repository: 'https://github.com/eps1lon/dom-accessibility-api',
    license: 'MIT',
  },
  {
    name: 'dom-converter',
    version: '0.2.0',
    repository: 'https://github.com/AriaMinaei/dom-converter',
    license: 'MIT',
  },
  {
    name: 'dom-helpers',
    version: '5.2.1',
    repository: 'https://github.com/react-bootstrap/dom-helpers',
    license: 'MIT',
  },
  {
    name: 'dom-serializer',
    version: '0.2.2',
    repository: 'http://github.com/cheeriojs/dom-renderer',
    license: 'MIT',
  },
  {
    name: 'domain-browser',
    version: '1.2.0',
    repository: 'https://github.com/bevry/domain-browser',
    license: '',
  },
  {
    name: 'domelementtype',
    version: '1.3.1',
    repository: 'http://github.com/fb55/domelementtype',
    license: '',
  },
  {
    name: 'domexception',
    version: '2.0.1',
    repository: 'jsdom/domexception',
    license: 'MIT',
  },
  {
    name: 'domhandler',
    version: '4.2.2',
    repository: 'http://github.com/fb55/domhandler',
    license: '',
  },
  {
    name: 'dompurify',
    version: '2.4.0',
    repository: 'http://github.com/cure53/DOMPurify',
    license: 'Apache;MPL',
  },
  {
    name: 'domutils',
    version: '1.7.0',
    repository: 'http://github.com/FB55/domutils',
    license: '',
  },
  {
    name: 'dot-case',
    version: '3.0.4',
    repository: 'http://github.com/blakeembrey/change-case',
    license: 'MIT',
  },
  {
    name: 'dot-prop',
    version: '5.3.0',
    repository: 'sindresorhus/dot-prop',
    license: 'MIT',
  },
  {
    name: 'dotenv',
    version: '8.2.0',
    repository: 'http://github.com/motdotla/dotenv',
    license: '',
  },
  {
    name: 'dotenv-expand',
    version: '5.1.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'duplexer',
    version: '0.1.2',
    repository: 'http://github.com/Raynos/duplexer',
    license: '',
  },
  {
    name: 'duplexify',
    version: '3.7.1',
    repository: 'http://github.com/mafintosh/duplexify',
    license: 'MIT',
  },
  {
    name: 'ecc-jsbn',
    version: '0.1.2',
    repository: 'https://github.com/quartzjer/ecc-jsbn',
    license: 'MIT',
  },
  {
    name: 'ee-first',
    version: '1.1.1',
    repository: 'jonathanong/ee-first',
    license: 'MIT',
  },
  {
    name: 'ejs',
    version: '2.7.4',
    repository: 'http://github.com/mde/ejs',
    license: 'Apache',
  },
  {
    name: 'electron-to-chromium',
    version: '1.4.227',
    repository: 'https://github.com/kilian/electron-to-chromium/',
    license: '',
  },
  {
    name: 'elliptic',
    version: '6.5.4',
    repository: 'git@github.com:indutny/elliptic',
    license: '',
  },
  {
    name: 'emittery',
    version: '0.7.2',
    repository: 'sindresorhus/emittery',
    license: 'MIT',
  },
  {
    name: 'emoji-regex',
    version: '7.0.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'emojis-list',
    version: '2.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'encodeurl',
    version: '1.0.2',
    repository: 'pillarjs/encodeurl',
    license: 'MIT',
  },
  {
    name: 'end-of-stream',
    version: '1.4.4',
    repository: 'http://github.com/mafintosh/end-of-stream',
    license: 'MIT',
  },
  {
    name: 'enhanced-resolve',
    version: '4.5.0',
    repository: 'http://github.com/webpack/enhanced-resolve',
    license: '',
  },
  {
    name: 'enquirer',
    version: '2.3.6',
    repository: 'enquirer/enquirer',
    license: 'MIT',
  },
  {
    name: 'entities',
    version: '2.2.0',
    repository: 'http://github.com/fb55/entities',
    license: '',
  },
  {
    name: 'errno',
    version: '0.1.8',
    repository: 'https://github.com/rvagg/node-errno',
    license: '',
  },
  {
    name: 'error-ex',
    version: '1.3.2',
    repository: 'qix-/node-error-ex',
    license: 'MIT',
  },
  {
    name: 'error-stack-parser',
    version: '2.0.6',
    repository: 'http://github.com/stacktracejs/error-stack-parser',
    license: '',
  },
  {
    name: 'es-abstract',
    version: '1.19.1',
    repository: 'http://github.com/ljharb/es-abstract',
    license: 'MIT',
  },
  {
    name: 'es-to-primitive',
    version: '1.2.1',
    repository: 'http://github.com/ljharb/es-to-primitive',
    license: 'MIT',
  },
  {
    name: 'es5-ext',
    version: '0.10.53',
    repository: 'medikoo/es5-ext',
    license: 'ISC',
  },
  {
    name: 'es6-iterator',
    version: '2.0.3',
    repository: 'http://github.com/medikoo/es6-iterator',
    license: 'MIT',
  },
  {
    name: 'es6-symbol',
    version: '3.1.3',
    repository: 'http://github.com/medikoo/es6-symbol',
    license: 'ISC',
  },
  {
    name: 'escalade',
    version: '3.1.1',
    repository: 'lukeed/escalade',
    license: 'MIT',
  },
  {
    name: 'escape-html',
    version: '1.0.3',
    repository: 'component/escape-html',
    license: 'MIT',
  },
  {
    name: 'escape-string-regexp',
    version: '1.0.5',
    repository: 'sindresorhus/escape-string-regexp',
    license: 'MIT',
  },
  {
    name: 'escodegen',
    version: '2.0.0',
    repository: 'http://github.com/estools/escodegen',
    license: '',
  },
  {
    name: 'eslint',
    version: '7.32.0',
    repository: 'eslint/eslint',
    license: '',
  },
  {
    name: 'eslint-config-prettier',
    version: '8.3.0',
    repository: 'prettier/eslint-config-prettier',
    license: 'MIT',
  },
  {
    name: 'eslint-config-react-app',
    version: '6.0.0',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'eslint-import-resolver-node',
    version: '0.3.6',
    repository: 'https://github.com/import-js/eslint-plugin-import',
    license: 'MIT',
  },
  {
    name: 'eslint-module-utils',
    version: '2.7.1',
    repository: 'https://github.com/import-js/eslint-plugin-import',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-flowtype',
    version: '5.10.0',
    repository: 'https://github.com/gajus/eslint-plugin-flowtype',
    license: '',
  },
  {
    name: 'eslint-plugin-import',
    version: '2.25.2',
    repository: 'https://github.com/import-js/eslint-plugin-import',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-jest',
    version: '24.7.0',
    repository: 'jest-community/eslint-plugin-jest',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-jsx-a11y',
    version: '6.4.1',
    repository: 'https://github.com/evcohen/eslint-plugin-jsx-a11y',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-prettier',
    version: '4.0.0',
    repository: 'https://github.com/prettier/eslint-plugin-prettier',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-react',
    version: '7.28.0',
    repository: 'https://github.com/yannickcr/eslint-plugin-react',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-react-hooks',
    version: '4.6.0',
    repository: 'https://github.com/facebook/react',
    license: 'MIT',
  },
  {
    name: 'eslint-plugin-testing-library',
    version: '3.10.2',
    repository: 'https://github.com/testing-library/eslint-plugin-testing-library',
    license: 'MIT',
  },
  {
    name: 'eslint-scope',
    version: '4.0.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'eslint-utils',
    version: '2.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'eslint-visitor-keys',
    version: '1.3.0',
    repository: '(none)',
    license: 'Apache',
  },
  {
    name: 'eslint-webpack-plugin',
    version: '2.5.4',
    repository: 'webpack-contrib/eslint-webpack-plugin',
    license: '',
  },
  {
    name: 'espree',
    version: '7.3.1',
    repository: 'eslint/espree',
    license: 'BSD',
  },
  {
    name: 'esprima',
    version: '4.0.1',
    repository: 'https://github.com/jquery/esprima',
    license: '',
  },
  {
    name: 'esquery',
    version: '1.4.0',
    repository: 'https://github.com/estools/esquery',
    license: '',
  },
  {
    name: 'esrecurse',
    version: '4.3.0',
    repository: 'https://github.com/estools/esrecurse',
    license: '',
  },
  {
    name: 'estraverse',
    version: '4.3.0',
    repository: 'http://github.com/estools/estraverse',
    license: '',
  },
  {
    name: 'estree-walker',
    version: '0.6.1',
    repository: '(none)',
    license: '',
  },
  {
    name: 'esutils',
    version: '2.0.3',
    repository: 'http://github.com/estools/esutils',
    license: '',
  },
  {
    name: 'etag',
    version: '1.8.1',
    repository: 'jshttp/etag',
    license: 'MIT',
  },
  {
    name: 'eventemitter2',
    version: '6.4.5',
    repository: 'http://github.com/hij1nx/EventEmitter2',
    license: 'MIT',
  },
  {
    name: 'eventemitter3',
    version: '4.0.7',
    repository: 'http://github.com/primus/eventemitter3',
    license: 'MIT',
  },
  {
    name: 'events',
    version: '3.3.0',
    repository: 'http://github.com/Gozala/events',
    license: 'MIT',
  },
  {
    name: 'eventsource',
    version: '1.1.0',
    repository: 'http://github.com/EventSource/eventsource',
    license: 'MIT',
  },
  {
    name: 'evp_bytestokey',
    version: '1.0.3',
    repository: 'https://github.com/crypto-browserify/EVP_BytesToKey',
    license: 'MIT',
  },
  {
    name: 'exec-sh',
    version: '0.3.6',
    repository: 'git@github.com:tsertkov/exec-sh',
    license: 'MIT',
  },
  {
    name: 'execa',
    version: '1.0.0',
    repository: 'sindresorhus/execa',
    license: 'MIT',
  },
  {
    name: 'executable',
    version: '4.1.1',
    repository: 'kevva/executable',
    license: 'MIT',
  },
  {
    name: 'exit',
    version: '0.1.2',
    repository: 'http://github.com/cowboy/node-exit',
    license: '',
  },
  {
    name: 'expand-brackets',
    version: '2.1.4',
    repository: 'jonschlinkert/expand-brackets',
    license: 'MIT',
  },
  {
    name: 'expect',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'express',
    version: '4.17.1',
    repository: 'expressjs/express',
    license: 'MIT',
  },
  {
    name: 'ext',
    version: '1.6.0',
    repository: 'https://github.com/medikoo/es5-ext#ext',
    license: 'ISC',
  },
  {
    name: 'extend',
    version: '3.0.2',
    repository: 'https://github.com/justmoon/node-extend',
    license: 'MIT',
  },
  {
    name: 'extend-shallow',
    version: '2.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'extglob',
    version: '2.0.4',
    repository: 'micromatch/extglob',
    license: 'MIT',
  },
  {
    name: 'extract-zip',
    version: '2.0.1',
    repository: 'maxogden/extract-zip',
    license: '',
  },
  {
    name: 'extsprintf',
    version: '1.3.0',
    repository: 'http://github.com/davepacheco/node-extsprintf',
    license: '',
  },
  {
    name: 'fast-deep-equal',
    version: '3.1.3',
    repository: 'https://github.com/epoberezkin/fast-deep-equal',
    license: 'MIT',
  },
  {
    name: 'fast-diff',
    version: '1.2.0',
    repository: 'https://github.com/jhchen/fast-diff',
    license: '',
  },
  {
    name: 'fast-glob',
    version: '3.2.7',
    repository: 'mrmlnc/fast-glob',
    license: 'MIT',
  },
  {
    name: 'fast-json-stable-stringify',
    version: '2.1.0',
    repository: 'http://github.com/epoberezkin/fast-json-stable-stringify',
    license: 'MIT',
  },
  {
    name: 'fast-levenshtein',
    version: '2.0.6',
    repository: 'https://github.com/hiddentao/fast-levenshtein',
    license: '',
  },
  {
    name: 'fastq',
    version: '1.13.0',
    repository: 'https://github.com/mcollina/fastq',
    license: '',
  },
  {
    name: 'faye-websocket',
    version: '0.11.4',
    repository: 'http://github.com/faye/faye-websocket-node',
    license: '',
  },
  {
    name: 'fb-watchman',
    version: '2.0.1',
    repository: 'git@github.com:facebook/watchman',
    license: '',
  },
  {
    name: 'fbemitter',
    version: '3.0.0',
    repository: 'facebook/emitter',
    license: 'BSD',
  },
  {
    name: 'fbjs',
    version: '3.0.1',
    repository: 'facebook/fbjs',
    license: 'MIT',
  },
  {
    name: 'fbjs-css-vars',
    version: '1.0.2',
    repository: 'facebook/fbjs',
    license: 'MIT',
  },
  {
    name: 'fd-slicer',
    version: '1.1.0',
    repository: 'http://github.com/andrewrk/node-fd-slicer',
    license: '',
  },
  {
    name: 'figgy-pudding',
    version: '3.5.2',
    repository: 'https://github.com/npm/figgy-pudding',
    license: 'ISC',
  },
  {
    name: 'figures',
    version: '3.2.0',
    repository: 'sindresorhus/figures',
    license: 'MIT',
  },
  {
    name: 'file-entry-cache',
    version: '6.0.1',
    repository: 'royriojas/file-entry-cache',
    license: 'MIT',
  },
  {
    name: 'file-loader',
    version: '6.1.1',
    repository: 'webpack-contrib/file-loader',
    license: '',
  },
  {
    name: 'file-uri-to-path',
    version: '1.0.0',
    repository: 'http://github.com/TooTallNate/file-uri-to-path',
    license: '',
  },
  {
    name: 'filesize',
    version: '6.1.0',
    repository: 'http://github.com/avoidwork/filesize.js',
    license: '',
  },
  {
    name: 'fill-range',
    version: '4.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'finalhandler',
    version: '1.1.2',
    repository: 'pillarjs/finalhandler',
    license: 'MIT',
  },
  {
    name: 'find-cache-dir',
    version: '2.1.0',
    repository: 'avajs/find-cache-dir',
    license: 'MIT',
  },
  {
    name: 'find-root',
    version: '1.1.0',
    repository: 'git@github.com:js-n/find-root',
    license: '',
  },
  {
    name: 'find-up',
    version: '2.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'find-webpack',
    version: '2.2.1',
    repository: 'https://github.com/bahmutov/find-webpack',
    license: 'MIT',
  },
  {
    name: 'find-yarn-workspace-root',
    version: '1.2.1',
    repository: '(none)',
    license: 'Apache',
  },
  {
    name: 'flat-cache',
    version: '3.0.4',
    repository: 'royriojas/flat-cache',
    license: 'MIT',
  },
  {
    name: 'flatted',
    version: '3.2.5',
    repository: 'https://github.com/WebReflection/flatted',
    license: 'ISC',
  },
  {
    name: 'flatten',
    version: '1.0.3',
    repository: 'https://github.com/mk-pmb/flatten-js',
    license: 'MIT',
  },
  {
    name: 'flow-parser',
    version: '0.168.0',
    repository: 'https://github.com/facebook/flow',
    license: '',
  },
  {
    name: 'flush-write-stream',
    version: '1.1.1',
    repository: 'https://github.com/mafintosh/flush-write-stream',
    license: 'MIT',
  },
  {
    name: 'flux',
    version: '4.0.2',
    repository: 'https://github.com/facebook/flux',
    license: 'BSD',
  },
  {
    name: 'follow-redirects',
    version: '1.14.5',
    repository: 'git@github.com:follow-redirects/follow-redirects',
    license: '',
  },
  {
    name: 'for-in',
    version: '1.0.2',
    repository: 'jonschlinkert/for-in',
    license: 'MIT',
  },
  {
    name: 'forever-agent',
    version: '0.6.1',
    repository: 'https://github.com/mikeal/forever-agent',
    license: 'Apache',
  },
  {
    name: 'fork-ts-checker-webpack-plugin',
    version: '4.1.6',
    repository: 'https://github.com/TypeStrong/fork-ts-checker-webpack-plugin',
    license: 'MIT',
  },
  {
    name: 'form-data',
    version: '2.3.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'forwarded',
    version: '0.2.0',
    repository: 'jshttp/forwarded',
    license: 'MIT',
  },
  {
    name: 'fragment-cache',
    version: '0.2.1',
    repository: 'jonschlinkert/fragment-cache',
    license: 'MIT',
  },
  {
    name: 'fresh',
    version: '0.5.2',
    repository: 'jshttp/fresh',
    license: 'MIT',
  },
  {
    name: 'from2',
    version: '2.3.0',
    repository: 'http://github.com/hughsk/from2',
    license: 'MIT',
  },
  {
    name: 'fs-extra',
    version: '4.0.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'fs-minipass',
    version: '2.1.0',
    repository: 'https://github.com/npm/fs-minipass',
    license: 'ISC',
  },
  {
    name: 'fs-write-stream-atomic',
    version: '1.0.10',
    repository: 'https://github.com/npm/fs-write-stream-atomic',
    license: 'ISC',
  },
  {
    name: 'fs.realpath',
    version: '1.0.0',
    repository: 'https://github.com/isaacs/fs.realpath',
    license: 'ISC;MIT',
  },
  {
    name: 'function-bind',
    version: '1.1.1',
    repository: 'http://github.com/Raynos/function-bind',
    license: '',
  },
  {
    name: 'functional-red-black-tree',
    version: '1.0.1',
    repository: 'http://github.com/mikolalysenko/functional-red-black-tree',
    license: 'MIT',
  },
  {
    name: 'gensync',
    version: '1.0.0-beta.2',
    repository: 'https://github.com/loganfsmyth/gensync',
    license: '',
  },
  {
    name: 'get-caller-file',
    version: '2.0.5',
    repository: 'https://github.com/stefanpenner/get-caller-file',
    license: 'ISC',
  },
  {
    name: 'get-intrinsic',
    version: '1.1.1',
    repository: 'https://github.com/ljharb/get-intrinsic',
    license: 'MIT',
  },
  {
    name: 'get-own-enumerable-property-symbols',
    version: '3.0.2',
    repository: 'https://github.com/mightyiam/get-own-enumerable-property-symbols',
    license: '',
  },
  {
    name: 'get-package-type',
    version: '0.1.0',
    repository: 'https://github.com/cfware/get-package-type',
    license: 'MIT',
  },
  {
    name: 'get-stream',
    version: '4.1.0',
    repository: 'sindresorhus/get-stream',
    license: 'MIT',
  },
  {
    name: 'get-symbol-description',
    version: '1.0.0',
    repository: 'https://github.com/inspect-js/get-symbol-description',
    license: 'MIT',
  },
  {
    name: 'get-value',
    version: '2.0.6',
    repository: 'jonschlinkert/get-value',
    license: 'MIT',
  },
  {
    name: 'getos',
    version: '3.2.1',
    repository: 'https://github.com/retrohacker/getos',
    license: 'MIT',
  },
  {
    name: 'getpass',
    version: '0.1.7',
    repository: 'https://github.com/arekinath/node-getpass',
    license: '',
  },
  {
    name: 'glob',
    version: '7.2.0',
    repository: 'http://github.com/isaacs/node-glob',
    license: 'ISC',
  },
  {
    name: 'glob-parent',
    version: '3.1.0',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'global-dirs',
    version: '3.0.0',
    repository: 'sindresorhus/global-dirs',
    license: 'MIT',
  },
  {
    name: 'global-modules',
    version: '2.0.0',
    repository: 'jonschlinkert/global-modules',
    license: 'MIT',
  },
  {
    name: 'global-prefix',
    version: '3.0.0',
    repository: 'jonschlinkert/global-prefix',
    license: 'MIT',
  },
  {
    name: 'globals',
    version: '11.12.0',
    repository: 'sindresorhus/globals',
    license: 'MIT',
  },
  {
    name: 'globby',
    version: '6.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'graceful-fs',
    version: '4.2.8',
    repository: 'https://github.com/isaacs/node-graceful-fs',
    license: 'ISC',
  },
  {
    name: 'growly',
    version: '1.3.0',
    repository: 'http://github.com/theabraham/growly',
    license: '',
  },
  {
    name: 'gzip-size',
    version: '5.1.1',
    repository: 'sindresorhus/gzip-size',
    license: 'MIT',
  },
  {
    name: 'handle-thing',
    version: '2.0.1',
    repository: 'ssh://git@github.com/indutny/handle-thing',
    license: '',
  },
  {
    name: 'har-schema',
    version: '2.0.0',
    repository: 'https://github.com/ahmadnassri/har-schema',
    license: '',
  },
  {
    name: 'har-validator',
    version: '5.1.5',
    repository: 'https://github.com/ahmadnassri/node-har-validator',
    license: 'MIT',
  },
  {
    name: 'harmony-reflect',
    version: '1.6.2',
    repository: 'https://tvcutsem@github.com/tvcutsem/harmony-reflect',
    license: '',
  },
  {
    name: 'has',
    version: '1.0.3',
    repository: 'http://github.com/tarruda/has',
    license: '',
  },
  {
    name: 'has-bigints',
    version: '1.0.1',
    repository: 'https://github.com/ljharb/has-bigints',
    license: 'MIT',
  },
  {
    name: 'has-flag',
    version: '3.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'has-symbols',
    version: '1.0.2',
    repository: 'http://github.com/inspect-js/has-symbols',
    license: 'MIT',
  },
  {
    name: 'has-tostringtag',
    version: '1.0.0',
    repository: 'https://github.com/inspect-js/has-tostringtag',
    license: 'MIT',
  },
  {
    name: 'has-value',
    version: '0.3.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'has-values',
    version: '0.1.4',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'hash-base',
    version: '3.1.0',
    repository: 'https://github.com/crypto-browserify/hash-base',
    license: 'MIT',
  },
  {
    name: 'hash.js',
    version: '1.1.7',
    repository: 'git@github.com:indutny/hash.js',
    license: '',
  },
  {
    name: 'he',
    version: '1.2.0',
    repository: 'https://github.com/mathiasbynens/he',
    license: '',
  },
  {
    name: 'hex-color-regex',
    version: '1.1.0',
    repository: 'regexps/hex-color-regex',
    license: 'MIT',
  },
  {
    name: 'history',
    version: '4.10.1',
    repository: 'ReactTraining/history',
    license: 'MIT',
  },
  {
    name: 'hmac-drbg',
    version: '1.0.1',
    repository: 'ssh://git@github.com/indutny/hmac-drbg',
    license: '',
  },
  {
    name: 'hoist-non-react-statics',
    version: '3.3.2',
    repository: 'http://github.com/mridgway/hoist-non-react-statics',
    license: 'BSD',
  },
  {
    name: 'hoopy',
    version: '0.1.4',
    repository: 'https://gitlab.com/philbooth/hoopy',
    license: '',
  },
  {
    name: 'hosted-git-info',
    version: '2.8.9',
    repository: 'https://github.com/npm/hosted-git-info',
    license: '',
  },
  {
    name: 'hpack.js',
    version: '2.1.6',
    repository: 'ssh://git@github.com/indutny/hpack.js',
    license: '',
  },
  {
    name: 'hsl-regex',
    version: '1.0.0',
    repository: 'https://github.com/regexps/hsl-regex',
    license: 'MIT',
  },
  {
    name: 'hsla-regex',
    version: '1.0.0',
    repository: 'https://github.com/regexps/hsla-regex',
    license: 'MIT',
  },
  {
    name: 'html-encoding-sniffer',
    version: '2.0.1',
    repository: 'jsdom/html-encoding-sniffer',
    license: '',
  },
  {
    name: 'html-entities',
    version: '1.4.0',
    repository: 'https://github.com/mdevils/node-html-entities',
    license: '',
  },
  {
    name: 'html-escaper',
    version: '2.0.2',
    repository: 'https://github.com/WebReflection/html-escaper',
    license: '',
  },
  {
    name: 'html-minifier-terser',
    version: '5.1.1',
    repository: 'https://github.com/DanielRuf/html-minifier-terser',
    license: '',
  },
  {
    name: 'html-parse-stringify',
    version: '3.0.1',
    repository: 'https://github.com/henrikjoreteg/html-parse-stringify',
    license: '',
  },
  {
    name: 'html-webpack-plugin',
    version: '4.5.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'htmlparser2',
    version: '6.1.0',
    repository: 'http://github.com/fb55/htmlparser2',
    license: '',
  },
  {
    name: 'http-deceiver',
    version: '1.2.7',
    repository: 'ssh://git@github.com/indutny/http-deceiver',
    license: '',
  },
  {
    name: 'http-errors',
    version: '1.6.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'http-parser-js',
    version: '0.5.3',
    repository: 'http://github.com/creationix/http-parser-js',
    license: '',
  },
  {
    name: 'http-proxy',
    version: '1.18.1',
    repository: 'https://github.com/http-party/node-http-proxy',
    license: '',
  },
  {
    name: 'http-proxy-agent',
    version: '4.0.1',
    repository: 'http://github.com/TooTallNate/node-http-proxy-agent',
    license: '',
  },
  {
    name: 'http-proxy-middleware',
    version: '0.19.1',
    repository: 'https://github.com/chimurai/http-proxy-middleware',
    license: 'MIT',
  },
  {
    name: 'http-signature',
    version: '1.2.0',
    repository: 'http://github.com/joyent/node-http-signature',
    license: '',
  },
  {
    name: 'https-browserify',
    version: '1.0.0',
    repository: 'http://github.com/substack/https-browserify',
    license: 'MIT',
  },
  {
    name: 'https-proxy-agent',
    version: '5.0.0',
    repository: 'http://github.com/TooTallNate/node-https-proxy-agent',
    license: '',
  },
  {
    name: 'human-signals',
    version: '1.1.1',
    repository: 'ehmicky/human-signals',
    license: 'Apache',
  },
  {
    name: 'hyphenate-style-name',
    version: '1.0.4',
    repository: 'ssh://git@github.com/rexxars/hyphenate-style-name',
    license: '',
  },
  {
    name: 'i18next',
    version: '21.5.2',
    repository: 'https://github.com/i18next/i18next',
    license: 'MIT',
  },
  {
    name: 'i18next-browser-languagedetector',
    version: '6.1.2',
    repository: 'https://github.com/i18next/i18next-browser-languageDetector',
    license: 'MIT',
  },
  {
    name: 'ibc-dash-app-webreact',
    version: '0.1.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'iconv-lite',
    version: '0.4.24',
    repository: 'http://github.com/ashtuchkin/iconv-lite',
    license: '',
  },
  {
    name: 'icss-utils',
    version: '4.1.1',
    repository: 'https://github.com/css-modules/icss-utils',
    license: 'ISC',
  },
  {
    name: 'identity-obj-proxy',
    version: '3.0.0',
    repository: 'https://github.com/keyanzhang/identity-obj-proxy',
    license: 'MIT',
  },
  {
    name: 'ieee754',
    version: '1.2.1',
    repository: 'http://github.com/feross/ieee754',
    license: '',
  },
  {
    name: 'iferr',
    version: '0.1.5',
    repository: 'https://github.com/shesek/iferr',
    license: 'MIT',
  },
  {
    name: 'ignore',
    version: '4.0.6',
    repository: '(none)',
    license: '',
  },
  {
    name: 'immer',
    version: '8.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'import-cwd',
    version: '2.1.0',
    repository: 'sindresorhus/import-cwd',
    license: 'MIT',
  },
  {
    name: 'import-fresh',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'import-from',
    version: '2.1.0',
    repository: 'sindresorhus/import-from',
    license: 'MIT',
  },
  {
    name: 'import-local',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'imurmurhash',
    version: '0.1.4',
    repository: 'https://github.com/jensyt/imurmurhash-js',
    license: '',
  },
  {
    name: 'indent-string',
    version: '4.0.0',
    repository: 'sindresorhus/indent-string',
    license: 'MIT',
  },
  {
    name: 'indexes-of',
    version: '1.0.1',
    repository: 'http://github.com/dominictarr/indexes-of',
    license: '',
  },
  {
    name: 'infer-owner',
    version: '1.0.4',
    repository: 'https://github.com/npm/infer-owner',
    license: 'ISC',
  },
  {
    name: 'inflight',
    version: '1.0.6',
    repository: 'https://github.com/npm/inflight',
    license: 'ISC',
  },
  {
    name: 'inherits',
    version: '2.0.1',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'ini',
    version: '1.3.8',
    repository: 'http://github.com/isaacs/ini',
    license: 'ISC',
  },
  {
    name: 'internal-ip',
    version: '4.3.0',
    repository: 'sindresorhus/internal-ip',
    license: 'MIT',
  },
  {
    name: 'internal-slot',
    version: '1.0.3',
    repository: 'https://github.com/ljharb/internal-slot',
    license: 'MIT',
  },
  {
    name: 'ip',
    version: '1.1.5',
    repository: 'http://github.com/indutny/node-ip',
    license: '',
  },
  {
    name: 'ip-regex',
    version: '2.1.0',
    repository: 'sindresorhus/ip-regex',
    license: 'MIT',
  },
  {
    name: 'ipaddr.js',
    version: '1.9.1',
    repository: 'http://github.com/whitequark/ipaddr.js',
    license: '',
  },
  {
    name: 'is-absolute-url',
    version: '2.1.0',
    repository: 'sindresorhus/is-absolute-url',
    license: 'MIT',
  },
  {
    name: 'is-accessor-descriptor',
    version: '0.1.6',
    repository: 'jonschlinkert/is-accessor-descriptor',
    license: 'MIT',
  },
  {
    name: 'is-arguments',
    version: '1.1.1',
    repository: 'http://github.com/inspect-js/is-arguments',
    license: 'MIT',
  },
  {
    name: 'is-arrayish',
    version: '0.2.1',
    repository: 'https://github.com/qix-/node-is-arrayish',
    license: 'MIT',
  },
  {
    name: 'is-bigint',
    version: '1.0.4',
    repository: 'https://github.com/inspect-js/is-bigint',
    license: 'MIT',
  },
  {
    name: 'is-binary-path',
    version: '1.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'is-boolean-object',
    version: '1.1.2',
    repository: 'http://github.com/inspect-js/is-boolean-object',
    license: 'MIT',
  },
  {
    name: 'is-buffer',
    version: '1.1.6',
    repository: 'http://github.com/feross/is-buffer',
    license: 'MIT',
  },
  {
    name: 'is-callable',
    version: '1.2.4',
    repository: 'http://github.com/inspect-js/is-callable',
    license: 'MIT',
  },
  {
    name: 'is-ci',
    version: '2.0.0',
    repository: 'https://github.com/watson/is-ci',
    license: 'MIT',
  },
  {
    name: 'is-color-stop',
    version: '1.1.0',
    repository: 'https://github.com/pigcan/is-color-stop',
    license: 'MIT',
  },
  {
    name: 'is-core-module',
    version: '2.8.0',
    repository: 'https://github.com/inspect-js/is-core-module',
    license: 'MIT',
  },
  {
    name: 'is-data-descriptor',
    version: '0.1.4',
    repository: 'jonschlinkert/is-data-descriptor',
    license: 'MIT',
  },
  {
    name: 'is-date-object',
    version: '1.0.5',
    repository: 'http://github.com/inspect-js/is-date-object',
    license: 'MIT',
  },
  {
    name: 'is-descriptor',
    version: '0.1.6',
    repository: 'jonschlinkert/is-descriptor',
    license: 'MIT',
  },
  {
    name: 'is-directory',
    version: '0.3.1',
    repository: 'jonschlinkert/is-directory',
    license: 'MIT',
  },
  {
    name: 'is-docker',
    version: '2.2.1',
    repository: 'sindresorhus/is-docker',
    license: 'MIT',
  },
  {
    name: 'is-extendable',
    version: '0.1.1',
    repository: 'jonschlinkert/is-extendable',
    license: 'MIT',
  },
  {
    name: 'is-extglob',
    version: '2.1.1',
    repository: 'jonschlinkert/is-extglob',
    license: 'MIT',
  },
  {
    name: 'is-fullwidth-code-point',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'is-generator-fn',
    version: '2.1.0',
    repository: 'sindresorhus/is-generator-fn',
    license: 'MIT',
  },
  {
    name: 'is-glob',
    version: '3.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'is-in-browser',
    version: '1.1.3',
    repository: 'https://github.com/tuxsudo/is-in-browser',
    license: '',
  },
  {
    name: 'is-installed-globally',
    version: '0.4.0',
    repository: 'sindresorhus/is-installed-globally',
    license: 'MIT',
  },
  {
    name: 'is-module',
    version: '1.0.0',
    repository: 'component/is-module',
    license: '',
  },
  {
    name: 'is-negative-zero',
    version: '2.0.1',
    repository: 'http://github.com/inspect-js/is-negative-zero',
    license: 'MIT',
  },
  {
    name: 'is-number',
    version: '3.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'is-number-object',
    version: '1.0.6',
    repository: 'http://github.com/inspect-js/is-number-object',
    license: 'MIT',
  },
  {
    name: 'is-obj',
    version: '1.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'is-path-cwd',
    version: '2.2.0',
    repository: 'sindresorhus/is-path-cwd',
    license: 'MIT',
  },
  {
    name: 'is-path-in-cwd',
    version: '2.1.0',
    repository: 'sindresorhus/is-path-in-cwd',
    license: 'MIT',
  },
  {
    name: 'is-path-inside',
    version: '2.1.0',
    repository: 'sindresorhus/is-path-inside',
    license: 'MIT',
  },
  {
    name: 'is-plain-obj',
    version: '1.1.0',
    repository: 'sindresorhus/is-plain-obj',
    license: 'MIT',
  },
  {
    name: 'is-plain-object',
    version: '2.0.4',
    repository: 'jonschlinkert/is-plain-object',
    license: 'MIT',
  },
  {
    name: 'is-potential-custom-element-name',
    version: '1.0.1',
    repository: 'https://github.com/mathiasbynens/is-potential-custom-element-name',
    license: '',
  },
  {
    name: 'is-regex',
    version: '1.1.4',
    repository: 'http://github.com/inspect-js/is-regex',
    license: 'MIT',
  },
  {
    name: 'is-regexp',
    version: '1.0.0',
    repository: 'sindresorhus/is-regexp',
    license: '',
  },
  {
    name: 'is-resolvable',
    version: '1.1.0',
    repository: 'shinnn/is-resolvable',
    license: 'ISC',
  },
  {
    name: 'is-root',
    version: '2.1.0',
    repository: 'sindresorhus/is-root',
    license: 'MIT',
  },
  {
    name: 'is-shared-array-buffer',
    version: '1.0.1',
    repository: 'https://github.com/inspect-js/is-shared-array-buffer',
    license: 'MIT',
  },
  {
    name: 'is-stream',
    version: '1.1.0',
    repository: 'sindresorhus/is-stream',
    license: 'MIT',
  },
  {
    name: 'is-string',
    version: '1.0.7',
    repository: 'http://github.com/ljharb/is-string',
    license: 'MIT',
  },
  {
    name: 'is-symbol',
    version: '1.0.4',
    repository: 'http://github.com/inspect-js/is-symbol',
    license: 'MIT',
  },
  {
    name: 'is-typedarray',
    version: '1.0.0',
    repository: 'http://github.com/hughsk/is-typedarray',
    license: 'MIT',
  },
  {
    name: 'is-unicode-supported',
    version: '0.1.0',
    repository: 'sindresorhus/is-unicode-supported',
    license: 'MIT',
  },
  {
    name: 'is-weakref',
    version: '1.0.1',
    repository: 'https://github.com/inspect-js/is-weakref',
    license: 'MIT',
  },
  {
    name: 'is-windows',
    version: '1.0.2',
    repository: 'jonschlinkert/is-windows',
    license: 'MIT',
  },
  {
    name: 'is-wsl',
    version: '1.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'isarray',
    version: '0.0.1',
    repository: 'http://github.com/juliangruber/isarray',
    license: '',
  },
  {
    name: 'isexe',
    version: '2.0.0',
    repository: 'https://github.com/isaacs/isexe',
    license: 'ISC',
  },
  {
    name: 'isobject',
    version: '2.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'isstream',
    version: '0.1.2',
    repository: 'https://github.com/rvagg/isstream',
    license: 'MIT',
  },
  {
    name: 'istanbul-lib-coverage',
    version: '3.2.0',
    repository: 'ssh://git@github.com/istanbuljs/istanbuljs',
    license: '',
  },
  {
    name: 'istanbul-lib-instrument',
    version: '4.0.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'istanbul-lib-report',
    version: '3.0.0',
    repository: 'ssh://git@github.com/istanbuljs/istanbuljs',
    license: '',
  },
  {
    name: 'istanbul-lib-source-maps',
    version: '4.0.1',
    repository: 'ssh://git@github.com/istanbuljs/istanbuljs',
    license: '',
  },
  {
    name: 'istanbul-reports',
    version: '3.0.5',
    repository: 'ssh://git@github.com/istanbuljs/istanbuljs',
    license: '',
  },
  {
    name: 'javascript-natural-sort',
    version: '0.7.1',
    repository: 'https://github.com/Bill4Time/javascript-natural-sort',
    license: '',
  },
  {
    name: 'jest',
    version: '26.6.0',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-changed-files',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-circus',
    version: '26.6.0',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-cli',
    version: '26.6.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'jest-config',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-diff',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-docblock',
    version: '26.0.0',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-each',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-environment-jsdom',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-environment-node',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-get-type',
    version: '26.3.0',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-haste-map',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-jasmine2',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-leak-detector',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-matcher-utils',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-message-util',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-mock',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-pnp-resolver',
    version: '1.2.2',
    repository: 'https://github.com/arcanis/jest-pnp-resolver',
    license: '',
  },
  {
    name: 'jest-regex-util',
    version: '26.0.0',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-resolve',
    version: '26.6.0',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-resolve-dependencies',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-runner',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-runtime',
    version: '26.6.3',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-serializer',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-snapshot',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-util',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-validate',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-watch-typeahead',
    version: '0.6.1',
    repository: 'https://github.com/jest-community/jest-watch-typeahead',
    license: 'MIT',
  },
  {
    name: 'jest-watcher',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'jest-worker',
    version: '24.9.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'jodit',
    version: '3.19.5',
    repository: 'https://github.com/xdan/jodit',
    license: 'MIT',
  },
  {
    name: 'jodit-react',
    version: '1.3.15',
    repository: 'https://github.com/jodit/jodit-react',
    license: 'MIT',
  },
  {
    name: 'js-cookie',
    version: '2.2.1',
    repository: 'http://github.com/js-cookie/js-cookie',
    license: 'MIT',
  },
  {
    name: 'js-tokens',
    version: '4.0.0',
    repository: 'lydell/js-tokens',
    license: 'MIT',
  },
  {
    name: 'js-yaml',
    version: '3.14.1',
    repository: 'nodeca/js-yaml',
    license: 'MIT',
  },
  {
    name: 'jsbn',
    version: '0.1.1',
    repository: 'https://github.com/andyperlitch/jsbn',
    license: '',
  },
  {
    name: 'jscodeshift',
    version: '0.11.0',
    repository: 'https://github.com/facebook/jscodeshift',
    license: 'MIT',
  },
  {
    name: 'jscodeshift-add-imports',
    version: '1.0.10',
    repository: 'https://github.com/codemodsquad/jscodeshift-add-imports',
    license: 'MIT',
  },
  {
    name: 'jscodeshift-find-imports',
    version: '2.0.4',
    repository: 'https://github.com/codemodsquad/jscodeshift-find-imports',
    license: 'MIT',
  },
  {
    name: 'jsdom',
    version: '16.7.0',
    repository: 'jsdom/jsdom',
    license: '',
  },
  {
    name: 'jsesc',
    version: '0.5.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'json-parse-better-errors',
    version: '1.0.2',
    repository: 'https://github.com/zkat/json-parse-better-errors',
    license: '',
  },
  {
    name: 'json-parse-even-better-errors',
    version: '2.3.1',
    repository: 'https://github.com/npm/json-parse-even-better-errors',
    license: 'MIT',
  },
  {
    name: 'json-schema',
    version: '0.2.3',
    repository: 'http://github.com/kriszyp/json-schema',
    license: '',
  },
  {
    name: 'json-schema-traverse',
    version: '0.4.1',
    repository: 'https://github.com/epoberezkin/json-schema-traverse',
    license: 'MIT',
  },
  {
    name: 'json-stable-stringify-without-jsonify',
    version: '1.0.1',
    repository: 'http://github.com/samn/json-stable-stringify',
    license: 'MIT',
  },
  {
    name: 'json-stringify-safe',
    version: '5.0.1',
    repository: 'http://github.com/isaacs/json-stringify-safe',
    license: 'ISC',
  },
  {
    name: 'json3',
    version: '3.3.3',
    repository: 'http://github.com/bestiejs/json3',
    license: '',
  },
  {
    name: 'json5',
    version: '1.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'jsonfile',
    version: '4.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'jsprim',
    version: '1.4.1',
    repository: 'http://github.com/joyent/node-jsprim',
    license: '',
  },
  {
    name: 'jss',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-camel-case',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-default-unit',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-global',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-nested',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-props-sort',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-rule-value-function',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jss-plugin-vendor-prefixer',
    version: '10.8.2',
    repository: 'https://github.com/cssinjs/jss',
    license: 'MIT',
  },
  {
    name: 'jsx-ast-utils',
    version: '3.2.1',
    repository: 'https://github.com/jsx-eslint/jsx-ast-utils',
    license: 'MIT',
  },
  {
    name: 'killable',
    version: '1.0.1',
    repository: 'https://github.com/marten-de-vries/killable',
    license: '',
  },
  {
    name: 'kind-of',
    version: '3.2.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'kleur',
    version: '3.0.3',
    repository: 'lukeed/kleur',
    license: 'MIT',
  },
  {
    name: 'klona',
    version: '2.0.5',
    repository: 'lukeed/klona',
    license: 'MIT',
  },
  {
    name: 'language-subtag-registry',
    version: '0.3.21',
    repository: 'https://github.com/mattcg/language-subtag-registry',
    license: '',
  },
  {
    name: 'language-tags',
    version: '1.0.5',
    repository: 'http://github.com/mattcg/language-tags',
    license: '',
  },
  {
    name: 'last-call-webpack-plugin',
    version: '3.0.0',
    repository: 'http://github.com/NMFR/last-call-webpack-plugin',
    license: 'MIT',
  },
  {
    name: 'lazy-ass',
    version: '1.6.0',
    repository: 'https://github.com/bahmutov/lazy-ass',
    license: '',
  },
  {
    name: 'leven',
    version: '3.1.0',
    repository: 'sindresorhus/leven',
    license: 'MIT',
  },
  {
    name: 'levn',
    version: '0.3.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'lines-and-columns',
    version: '1.1.6',
    repository: 'https://github.com/eventualbuddha/lines-and-columns',
    license: 'MIT',
  },
  {
    name: 'listr2',
    version: '3.13.3',
    repository: 'https://github.com/cenk1cenk2/listr2',
    license: 'MIT',
  },
  {
    name: 'loader-runner',
    version: '2.4.0',
    repository: 'https://github.com/webpack/loader-runner',
    license: 'MIT',
  },
  {
    name: 'loader-utils',
    version: '1.2.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'locate-path',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'lodash',
    version: '4.17.21',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash._reinterpolate',
    version: '3.0.0',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.curry',
    version: '4.1.1',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.debounce',
    version: '4.0.8',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.flow',
    version: '3.5.0',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.memoize',
    version: '4.1.2',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.merge',
    version: '4.6.2',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.once',
    version: '4.1.1',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.template',
    version: '4.5.0',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.templatesettings',
    version: '4.2.0',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.truncate',
    version: '4.4.2',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'lodash.uniq',
    version: '4.5.0',
    repository: 'lodash/lodash',
    license: '',
  },
  {
    name: 'log-symbols',
    version: '4.1.0',
    repository: 'sindresorhus/log-symbols',
    license: 'MIT',
  },
  {
    name: 'log-update',
    version: '4.0.0',
    repository: 'sindresorhus/log-update',
    license: 'MIT',
  },
  {
    name: 'loglevel',
    version: '1.7.1',
    repository: 'http://github.com/pimterry/loglevel',
    license: '',
  },
  {
    name: 'loose-envify',
    version: '1.4.0',
    repository: 'http://github.com/zertosh/loose-envify',
    license: 'MIT',
  },
  {
    name: 'lower-case',
    version: '2.0.2',
    repository: 'http://github.com/blakeembrey/change-case',
    license: 'MIT',
  },
  {
    name: 'lru-cache',
    version: '5.1.1',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'lz-string',
    version: '1.4.4',
    repository: 'https://github.com/pieroxy/lz-string',
    license: 'WTFPL',
  },
  {
    name: 'magic-string',
    version: '0.25.7',
    repository: 'https://github.com/rich-harris/magic-string',
    license: '',
  },
  {
    name: 'make-dir',
    version: '2.1.0',
    repository: 'sindresorhus/make-dir',
    license: 'MIT',
  },
  {
    name: 'makeerror',
    version: '1.0.12',
    repository: 'https://github.com/daaku/nodejs-makeerror',
    license: 'BSD',
  },
  {
    name: 'map-cache',
    version: '0.2.2',
    repository: 'jonschlinkert/map-cache',
    license: 'MIT',
  },
  {
    name: 'map-visit',
    version: '1.0.0',
    repository: 'jonschlinkert/map-visit',
    license: 'MIT',
  },
  {
    name: 'md5.js',
    version: '1.3.5',
    repository: 'https://github.com/crypto-browserify/md5.js',
    license: 'MIT',
  },
  {
    name: 'mdn-data',
    version: '2.0.4',
    repository: 'https://github.com/mdn/data',
    license: '',
  },
  {
    name: 'media-typer',
    version: '0.3.0',
    repository: 'jshttp/media-typer',
    license: 'MIT',
  },
  {
    name: 'memory-fs',
    version: '0.4.1',
    repository: 'https://github.com/webpack/memory-fs',
    license: '',
  },
  {
    name: 'merge-descriptors',
    version: '1.0.1',
    repository: 'component/merge-descriptors',
    license: 'MIT',
  },
  {
    name: 'merge-stream',
    version: '2.0.0',
    repository: 'grncdr/merge-stream',
    license: 'MIT',
  },
  {
    name: 'merge2',
    version: '1.4.1',
    repository: 'git@github.com:teambition/merge2',
    license: 'MIT',
  },
  {
    name: 'methods',
    version: '1.1.2',
    repository: 'jshttp/methods',
    license: 'MIT',
  },
  {
    name: 'microevent.ts',
    version: '0.1.1',
    repository: 'https://github.com/DirtyHairy/microevent',
    license: 'MIT',
  },
  {
    name: 'micromatch',
    version: '3.1.10',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'miller-rabin',
    version: '4.0.1',
    repository: 'git@github.com:indutny/miller-rabin',
    license: '',
  },
  {
    name: 'mime',
    version: '1.6.0',
    repository: 'https://github.com/broofa/node-mime',
    license: 'MIT',
  },
  {
    name: 'mime-db',
    version: '1.50.0',
    repository: 'jshttp/mime-db',
    license: 'MIT',
  },
  {
    name: 'mime-types',
    version: '2.1.33',
    repository: 'jshttp/mime-types',
    license: 'MIT',
  },
  {
    name: 'mimic-fn',
    version: '2.1.0',
    repository: 'sindresorhus/mimic-fn',
    license: 'MIT',
  },
  {
    name: 'min-indent',
    version: '1.0.1',
    repository: 'https://github.com/thejameskyle/min-indent',
    license: 'MIT',
  },
  {
    name: 'mini-create-react-context',
    version: '0.4.1',
    repository: 'https://github.com/StringEpsilon/mini-create-react-context',
    license: '',
  },
  {
    name: 'mini-css-extract-plugin',
    version: '0.11.3',
    repository: 'webpack-contrib/mini-css-extract-plugin',
    license: '',
  },
  {
    name: 'minimalistic-assert',
    version: '1.0.1',
    repository: 'https://github.com/calvinmetcalf/minimalistic-assert',
    license: '',
  },
  {
    name: 'minimalistic-crypto-utils',
    version: '1.0.1',
    repository: 'ssh://git@github.com/indutny/minimalistic-crypto-utils',
    license: '',
  },
  {
    name: 'minimatch',
    version: '3.0.4',
    repository: 'http://github.com/isaacs/minimatch',
    license: 'ISC',
  },
  {
    name: 'minimist',
    version: '1.2.5',
    repository: 'http://github.com/substack/minimist',
    license: 'MIT',
  },
  {
    name: 'minipass',
    version: '3.1.5',
    repository: 'https://github.com/isaacs/minipass',
    license: 'ISC',
  },
  {
    name: 'minipass-collect',
    version: '1.0.2',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'minipass-flush',
    version: '1.0.5',
    repository: 'https://github.com/isaacs/minipass-flush',
    license: 'ISC',
  },
  {
    name: 'minipass-pipeline',
    version: '1.2.4',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'minizlib',
    version: '2.1.2',
    repository: 'https://github.com/isaacs/minizlib',
    license: '',
  },
  {
    name: 'mississippi',
    version: '3.0.0',
    repository: 'https://github.com/maxogden/mississippi',
    license: '',
  },
  {
    name: 'mixin-deep',
    version: '1.3.2',
    repository: 'jonschlinkert/mixin-deep',
    license: 'MIT',
  },
  {
    name: 'mkdirp',
    version: '0.5.5',
    repository: 'https://github.com/substack/node-mkdirp',
    license: '',
  },
  {
    name: 'mocked-env',
    version: '1.3.2',
    repository: 'https://github.com/bahmutov/mocked-env',
    license: '',
  },
  {
    name: 'move-concurrently',
    version: '1.0.1',
    repository: 'https://github.com/npm/move-concurrently',
    license: '',
  },
  {
    name: 'ms',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'multicast-dns',
    version: '6.2.3',
    repository: 'https://github.com/mafintosh/multicast-dns',
    license: 'MIT',
  },
  {
    name: 'multicast-dns-service-types',
    version: '1.1.0',
    repository: 'https://github.com/mafintosh/multicast-dns-service-types',
    license: 'MIT',
  },
  {
    name: 'nan',
    version: '2.15.0',
    repository: 'http://github.com/nodejs/nan',
    license: 'MIT',
  },
  {
    name: 'nanoid',
    version: '3.1.30',
    repository: 'ai/nanoid',
    license: 'MIT',
  },
  {
    name: 'nanomatch',
    version: '1.2.13',
    repository: 'micromatch/nanomatch',
    license: 'MIT',
  },
  {
    name: 'native-url',
    version: '0.2.6',
    repository: 'https://github.com/GoogleChromeLabs/native-url',
    license: 'Apache',
  },
  {
    name: 'natural-compare',
    version: '1.4.0',
    repository: 'http://github.com/litejs/natural-compare-lite',
    license: '',
  },
  {
    name: 'negotiator',
    version: '0.6.2',
    repository: 'jshttp/negotiator',
    license: 'MIT',
  },
  {
    name: 'neo-async',
    version: '2.6.2',
    repository: 'git@github.com:suguru03/neo-async',
    license: 'MIT',
  },
  {
    name: 'next-tick',
    version: '1.0.0',
    repository: 'http://github.com/medikoo/next-tick',
    license: 'MIT',
  },
  {
    name: 'nice-try',
    version: '1.0.5',
    repository: 'https://github.com/electerious/nice-try',
    license: 'MIT',
  },
  {
    name: 'no-case',
    version: '3.0.4',
    repository: 'http://github.com/blakeembrey/change-case',
    license: 'MIT',
  },
  {
    name: 'node-dir',
    version: '0.1.17',
    repository: 'https://github.com/fshost/node-dir',
    license: 'MIT',
  },
  {
    name: 'node-fetch',
    version: '2.6.1',
    repository: 'https://github.com/bitinn/node-fetch',
    license: 'MIT',
  },
  {
    name: 'node-forge',
    version: '0.10.0',
    repository: 'https://github.com/digitalbazaar/forge',
    license: 'BSD;GPL',
  },
  {
    name: 'node-int64',
    version: '0.4.0',
    repository: 'https://github.com/broofa/node-int64',
    license: '',
  },
  {
    name: 'node-libs-browser',
    version: '2.2.1',
    repository: 'https://github.com/webpack/node-libs-browser',
    license: 'MIT',
  },
  {
    name: 'node-modules-regexp',
    version: '1.0.0',
    repository: 'jamestalmage/node-modules-regexp',
    license: 'MIT',
  },
  {
    name: 'node-notifier',
    version: '8.0.2',
    repository: 'ssh://git@github.com/mikaelbr/node-notifier',
    license: 'GPL;MIT',
  },
  {
    name: 'node-releases',
    version: '1.1.77',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'normalize-package-data',
    version: '2.5.0',
    repository: 'http://github.com/npm/normalize-package-data',
    license: 'BSD',
  },
  {
    name: 'normalize-path',
    version: '2.1.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'normalize-range',
    version: '0.1.2',
    repository: 'jamestalmage/normalize-range',
    license: 'MIT',
  },
  {
    name: 'normalize-url',
    version: '1.9.1',
    repository: 'sindresorhus/normalize-url',
    license: 'MIT',
  },
  {
    name: 'npm-run-path',
    version: '2.0.2',
    repository: 'sindresorhus/npm-run-path',
    license: 'MIT',
  },
  {
    name: 'nth-check',
    version: '1.0.2',
    repository: 'https://github.com/fb55/nth-check',
    license: '',
  },
  {
    name: 'num2fraction',
    version: '1.2.2',
    repository: 'git@github.com:yisibl/num2fraction',
    license: 'MIT',
  },
  {
    name: 'nwsapi',
    version: '2.2.0',
    repository: 'http://github.com/dperini/nwsapi',
    license: '',
  },
  {
    name: 'object-assign',
    version: '4.1.1',
    repository: 'sindresorhus/object-assign',
    license: 'MIT',
  },
  {
    name: 'object-copy',
    version: '0.1.0',
    repository: 'jonschlinkert/object-copy',
    license: 'MIT',
  },
  {
    name: 'object-inspect',
    version: '1.11.0',
    repository: 'http://github.com/inspect-js/object-inspect',
    license: 'MIT',
  },
  {
    name: 'object-is',
    version: '1.1.5',
    repository: 'http://github.com/es-shims/object-is',
    license: 'MIT',
  },
  {
    name: 'object-keys',
    version: '1.1.1',
    repository: 'http://github.com/ljharb/object-keys',
    license: 'MIT',
  },
  {
    name: 'object-visit',
    version: '1.0.1',
    repository: 'jonschlinkert/object-visit',
    license: 'MIT',
  },
  {
    name: 'object.assign',
    version: '4.1.2',
    repository: 'http://github.com/ljharb/object.assign',
    license: 'MIT',
  },
  {
    name: 'object.entries',
    version: '1.1.5',
    repository: 'http://github.com/es-shims/Object.entries',
    license: 'MIT',
  },
  {
    name: 'object.fromentries',
    version: '2.0.5',
    repository: 'http://github.com/es-shims/Object.fromEntries',
    license: 'MIT',
  },
  {
    name: 'object.getownpropertydescriptors',
    version: '2.1.3',
    repository: 'http://github.com/es-shims/object.getownpropertydescriptors',
    license: 'MIT',
  },
  {
    name: 'object.hasown',
    version: '1.1.0',
    repository: 'https://github.com/es-shims/Object.hasOwn',
    license: 'MIT',
  },
  {
    name: 'object.pick',
    version: '1.3.0',
    repository: 'jonschlinkert/object.pick',
    license: 'MIT',
  },
  {
    name: 'object.values',
    version: '1.1.5',
    repository: 'http://github.com/es-shims/Object.values',
    license: 'MIT',
  },
  {
    name: 'obuf',
    version: '1.1.2',
    repository: 'git@github.com:indutny/offset-buffer',
    license: '',
  },
  {
    name: 'oidc-client',
    version: '1.11.5',
    repository: 'https://github.com/IdentityModel/oidc-client-js',
    license: 'Apache;BSD;MIT',
  },
  {
    name: 'on-finished',
    version: '2.3.0',
    repository: 'jshttp/on-finished',
    license: 'MIT',
  },
  {
    name: 'on-headers',
    version: '1.0.2',
    repository: 'jshttp/on-headers',
    license: 'MIT',
  },
  {
    name: 'once',
    version: '1.4.0',
    repository: 'http://github.com/isaacs/once',
    license: 'ISC',
  },
  {
    name: 'onetime',
    version: '5.1.2',
    repository: 'sindresorhus/onetime',
    license: 'MIT',
  },
  {
    name: 'open',
    version: '7.4.2',
    repository: 'sindresorhus/open',
    license: 'MIT',
  },
  {
    name: 'opn',
    version: '5.5.0',
    repository: 'sindresorhus/opn',
    license: 'MIT',
  },
  {
    name: 'optimize-css-assets-webpack-plugin',
    version: '5.0.4',
    repository: 'http://github.com/NMFR/optimize-css-assets-webpack-plugin',
    license: 'MIT',
  },
  {
    name: 'optionator',
    version: '0.8.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'original',
    version: '1.0.2',
    repository: 'https://github.com/unshiftio/original',
    license: 'MIT',
  },
  {
    name: 'os-browserify',
    version: '0.3.0',
    repository: 'http://github.com/CoderPuppy/os-browserify',
    license: 'MIT',
  },
  {
    name: 'ospath',
    version: '1.2.2',
    repository: 'https://github.com/jprichardson/ospath',
    license: '',
  },
  {
    name: 'p-each-series',
    version: '2.2.0',
    repository: 'sindresorhus/p-each-series',
    license: 'MIT',
  },
  {
    name: 'p-finally',
    version: '1.0.0',
    repository: 'sindresorhus/p-finally',
    license: 'MIT',
  },
  {
    name: 'p-limit',
    version: '1.3.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'p-locate',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'p-map',
    version: '2.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'p-retry',
    version: '3.0.1',
    repository: 'sindresorhus/p-retry',
    license: 'MIT',
  },
  {
    name: 'p-try',
    version: '1.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'pako',
    version: '1.0.11',
    repository: 'nodeca/pako',
    license: 'MIT',
  },
  {
    name: 'parallel-transform',
    version: '1.2.0',
    repository: 'http://github.com/mafintosh/parallel-transform',
    license: '',
  },
  {
    name: 'param-case',
    version: '3.0.4',
    repository: 'http://github.com/blakeembrey/change-case',
    license: 'MIT',
  },
  {
    name: 'parent-module',
    version: '1.0.1',
    repository: 'sindresorhus/parent-module',
    license: 'MIT',
  },
  {
    name: 'parse-asn1',
    version: '5.1.6',
    repository: 'http://github.com/crypto-browserify/parse-asn1',
    license: '',
  },
  {
    name: 'parse-json',
    version: '4.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'parse5',
    version: '6.0.1',
    repository: 'http://github.com/inikulin/parse5',
    license: '',
  },
  {
    name: 'parseurl',
    version: '1.3.3',
    repository: 'pillarjs/parseurl',
    license: 'MIT',
  },
  {
    name: 'pascal-case',
    version: '3.1.2',
    repository: 'http://github.com/blakeembrey/change-case',
    license: 'MIT',
  },
  {
    name: 'pascalcase',
    version: '0.1.1',
    repository: 'jonschlinkert/pascalcase',
    license: 'MIT',
  },
  {
    name: 'path-browserify',
    version: '0.0.1',
    repository: 'http://github.com/substack/path-browserify',
    license: 'MIT',
  },
  {
    name: 'path-dirname',
    version: '1.0.2',
    repository: 'es128/path-dirname',
    license: 'MIT',
  },
  {
    name: 'path-exists',
    version: '3.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'path-is-absolute',
    version: '1.0.1',
    repository: 'sindresorhus/path-is-absolute',
    license: 'MIT',
  },
  {
    name: 'path-is-inside',
    version: '1.0.2',
    repository: 'domenic/path-is-inside',
    license: 'MIT;WTFPL',
  },
  {
    name: 'path-key',
    version: '2.0.1',
    repository: 'sindresorhus/path-key',
    license: 'MIT',
  },
  {
    name: 'path-parse',
    version: '1.0.7',
    repository: 'https://github.com/jbgutierrez/path-parse',
    license: 'MIT',
  },
  {
    name: 'path-to-regexp',
    version: '0.1.7',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'path-type',
    version: '4.0.0',
    repository: 'sindresorhus/path-type',
    license: 'MIT',
  },
  {
    name: 'pbkdf2',
    version: '3.1.2',
    repository: 'https://github.com/crypto-browserify/pbkdf2',
    license: 'MIT',
  },
  {
    name: 'pend',
    version: '1.2.0',
    repository: 'http://github.com/andrewrk/node-pend',
    license: 'MIT',
  },
  {
    name: 'performance-now',
    version: '2.1.0',
    repository: 'http://github.com/braveg1rl/performance-now',
    license: '',
  },
  {
    name: 'picocolors',
    version: '0.2.1',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'picomatch',
    version: '2.3.0',
    repository: 'micromatch/picomatch',
    license: 'MIT',
  },
  {
    name: 'pify',
    version: '2.3.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'pinkie',
    version: '2.0.4',
    repository: 'floatdrop/pinkie',
    license: 'MIT',
  },
  {
    name: 'pinkie-promise',
    version: '2.0.1',
    repository: 'floatdrop/pinkie-promise',
    license: 'MIT',
  },
  {
    name: 'pirates',
    version: '4.0.1',
    repository: 'https://github.com/ariporad/pirates',
    license: 'MIT',
  },
  {
    name: 'pkg-dir',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'pkg-up',
    version: '3.1.0',
    repository: 'sindresorhus/pkg-up',
    license: 'MIT',
  },
  {
    name: 'pnp-webpack-plugin',
    version: '1.6.4',
    repository: 'https://github.com/arcanis/pnp-webpack-plugin',
    license: '',
  },
  {
    name: 'popper.js',
    version: '1.16.1-lts',
    repository: 'https://github.com/FezVrasta/popper.js',
    license: '',
  },
  {
    name: 'portfinder',
    version: '1.0.28',
    repository: 'git@github.com:http-party/node-portfinder',
    license: '',
  },
  {
    name: 'posix-character-classes',
    version: '0.1.1',
    repository: 'jonschlinkert/posix-character-classes',
    license: 'MIT',
  },
  {
    name: 'postcss',
    version: '7.0.36',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'postcss-attribute-case-insensitive',
    version: '4.0.2',
    repository: 'https://github.com/Semigradsky/postcss-attribute-case-insensitive',
    license: 'MIT',
  },
  {
    name: 'postcss-browser-comments',
    version: '3.0.0',
    repository: 'csstools/postcss-browser-comments',
    license: '',
  },
  {
    name: 'postcss-calc',
    version: '7.0.5',
    repository: 'https://github.com/postcss/postcss-calc',
    license: 'MIT',
  },
  {
    name: 'postcss-color-functional-notation',
    version: '2.0.1',
    repository: 'jonathantneal/postcss-color-functional-notation',
    license: '',
  },
  {
    name: 'postcss-color-gray',
    version: '5.0.0',
    repository: 'postcss/postcss-color-gray',
    license: 'ISC',
  },
  {
    name: 'postcss-color-hex-alpha',
    version: '5.0.3',
    repository: 'postcss/postcss-color-hex-alpha',
    license: 'MIT',
  },
  {
    name: 'postcss-color-mod-function',
    version: '3.0.3',
    repository: 'jonathantneal/postcss-color-mod-function',
    license: '',
  },
  {
    name: 'postcss-color-rebeccapurple',
    version: '4.0.1',
    repository: 'https://github.com/postcss/postcss-color-rebeccapurple',
    license: 'MIT',
  },
  {
    name: 'postcss-colormin',
    version: '4.0.3',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-convert-values',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-custom-media',
    version: '7.0.8',
    repository: 'postcss/postcss-custom-media',
    license: 'MIT',
  },
  {
    name: 'postcss-custom-properties',
    version: '8.0.11',
    repository: 'postcss/postcss-custom-properties',
    license: 'MIT',
  },
  {
    name: 'postcss-custom-selectors',
    version: '5.1.2',
    repository: 'postcss/postcss-custom-selectors',
    license: 'MIT',
  },
  {
    name: 'postcss-dir-pseudo-class',
    version: '5.0.0',
    repository: 'jonathantneal/postcss-dir-pseudo-class',
    license: '',
  },
  {
    name: 'postcss-discard-comments',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-discard-duplicates',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-discard-empty',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-discard-overridden',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: 'MIT',
  },
  {
    name: 'postcss-double-position-gradients',
    version: '1.0.0',
    repository: 'jonathantneal/postcss-double-position-gradients',
    license: '',
  },
  {
    name: 'postcss-env-function',
    version: '2.0.2',
    repository: 'jonathantneal/postcss-env-function',
    license: '',
  },
  {
    name: 'postcss-flexbugs-fixes',
    version: '4.2.1',
    repository: 'https://github.com/luisrudge/postcss-flexbugs-fixes',
    license: 'MIT',
  },
  {
    name: 'postcss-focus-visible',
    version: '4.0.0',
    repository: 'jonathantneal/postcss-focus-visible',
    license: '',
  },
  {
    name: 'postcss-focus-within',
    version: '3.0.0',
    repository: 'jonathantneal/postcss-focus-within',
    license: '',
  },
  {
    name: 'postcss-font-variant',
    version: '4.0.1',
    repository: 'https://github.com/postcss/postcss-font-variant',
    license: 'MIT',
  },
  {
    name: 'postcss-gap-properties',
    version: '2.0.0',
    repository: 'jonathantneal/postcss-gap-properties',
    license: '',
  },
  {
    name: 'postcss-image-set-function',
    version: '3.0.1',
    repository: 'jonathantneal/postcss-image-set-function',
    license: '',
  },
  {
    name: 'postcss-initial',
    version: '3.0.4',
    repository: 'https://github.com/maximkoretskiy/postcss-initial',
    license: 'MIT',
  },
  {
    name: 'postcss-lab-function',
    version: '2.0.1',
    repository: 'jonathantneal/postcss-lab-function',
    license: '',
  },
  {
    name: 'postcss-load-config',
    version: '2.1.2',
    repository: 'postcss/postcss-load-config',
    license: 'MIT',
  },
  {
    name: 'postcss-loader',
    version: '3.0.0',
    repository: 'https://github.com/postcss/postcss-loader',
    license: 'MIT',
  },
  {
    name: 'postcss-logical',
    version: '3.0.0',
    repository: 'jonathantneal/postcss-logical',
    license: '',
  },
  {
    name: 'postcss-media-minmax',
    version: '4.0.0',
    repository: 'https://github.com/postcss/postcss-media-minmax',
    license: 'MIT',
  },
  {
    name: 'postcss-merge-longhand',
    version: '4.0.11',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-merge-rules',
    version: '4.0.3',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-minify-font-values',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-minify-gradients',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-minify-params',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: 'MIT',
  },
  {
    name: 'postcss-minify-selectors',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-modules-extract-imports',
    version: '2.0.0',
    repository: 'https://github.com/css-modules/postcss-modules-extract-imports',
    license: '',
  },
  {
    name: 'postcss-modules-local-by-default',
    version: '3.0.3',
    repository: 'https://github.com/css-modules/postcss-modules-local-by-default',
    license: 'MIT',
  },
  {
    name: 'postcss-modules-scope',
    version: '2.2.0',
    repository: 'https://github.com/css-modules/postcss-modules-scope',
    license: 'ISC',
  },
  {
    name: 'postcss-modules-values',
    version: '3.0.0',
    repository: 'https://github.com/css-modules/postcss-modules-values',
    license: 'ISC',
  },
  {
    name: 'postcss-nesting',
    version: '7.0.1',
    repository: 'jonathantneal/postcss-nesting',
    license: '',
  },
  {
    name: 'postcss-normalize',
    version: '8.0.1',
    repository: 'csstools/postcss-normalize',
    license: '',
  },
  {
    name: 'postcss-normalize-charset',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: 'MIT',
  },
  {
    name: 'postcss-normalize-display-values',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-positions',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-repeat-style',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-string',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-timing-functions',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-unicode',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-url',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-normalize-whitespace',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-ordered-values',
    version: '4.1.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-overflow-shorthand',
    version: '2.0.0',
    repository: 'jonathantneal/postcss-overflow-shorthand',
    license: '',
  },
  {
    name: 'postcss-page-break',
    version: '2.0.0',
    repository: 'shrpne/postcss-page-break',
    license: 'MIT',
  },
  {
    name: 'postcss-place',
    version: '4.0.1',
    repository: 'jonathantneal/postcss-place',
    license: '',
  },
  {
    name: 'postcss-preset-env',
    version: '6.7.0',
    repository: 'csstools/postcss-preset-env',
    license: '',
  },
  {
    name: 'postcss-pseudo-class-any-link',
    version: '6.0.0',
    repository: 'jonathantneal/postcss-pseudo-class-any-link',
    license: '',
  },
  {
    name: 'postcss-reduce-initial',
    version: '4.0.3',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-reduce-transforms',
    version: '4.0.2',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-replace-overflow-wrap',
    version: '3.0.0',
    repository: 'MattDiMu/postcss-replace-overflow-wrap',
    license: 'MIT',
  },
  {
    name: 'postcss-safe-parser',
    version: '5.0.2',
    repository: 'postcss/postcss-safe-parser',
    license: 'MIT',
  },
  {
    name: 'postcss-selector-matches',
    version: '4.0.0',
    repository: 'https://github.com/postcss/postcss-selector-matches',
    license: 'MIT',
  },
  {
    name: 'postcss-selector-not',
    version: '4.0.1',
    repository: 'https://github.com/postcss/postcss-selector-not',
    license: 'MIT',
  },
  {
    name: 'postcss-selector-parser',
    version: '3.1.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'postcss-svgo',
    version: '4.0.3',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-unique-selectors',
    version: '4.0.1',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'postcss-value-parser',
    version: '3.3.1',
    repository: '(none)',
    license: '',
  },
  {
    name: 'postcss-values-parser',
    version: '2.0.1',
    repository: 'lesshint/postcss-values-parser',
    license: '',
  },
  {
    name: 'prelude-ls',
    version: '1.1.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'prepend-http',
    version: '1.0.4',
    repository: 'sindresorhus/prepend-http',
    license: 'MIT',
  },
  {
    name: 'prettier',
    version: '2.5.1',
    repository: 'prettier/prettier',
    license: '',
  },
  {
    name: 'prettier-linter-helpers',
    version: '1.0.0',
    repository: 'https://github.com/prettier/prettier-linter-helpers',
    license: 'MIT',
  },
  {
    name: 'pretty-bytes',
    version: '5.6.0',
    repository: 'sindresorhus/pretty-bytes',
    license: 'MIT',
  },
  {
    name: 'pretty-error',
    version: '2.1.2',
    repository: 'https://github.com/AriaMinaei/pretty-error',
    license: 'MIT',
  },
  {
    name: 'pretty-format',
    version: '26.6.2',
    repository: 'https://github.com/facebook/jest',
    license: 'MIT',
  },
  {
    name: 'process',
    version: '0.11.10',
    repository: 'http://github.com/shtylman/node-process',
    license: 'MIT',
  },
  {
    name: 'process-nextick-args',
    version: '2.0.1',
    repository: 'https://github.com/calvinmetcalf/process-nextick-args',
    license: '',
  },
  {
    name: 'progress',
    version: '2.0.3',
    repository: 'http://github.com/visionmedia/node-progress',
    license: 'MIT',
  },
  {
    name: 'promise',
    version: '7.3.1',
    repository: '(none)',
    license: '',
  },
  {
    name: 'promise-inflight',
    version: '1.0.1',
    repository: 'https://github.com/iarna/promise-inflight',
    license: '',
  },
  {
    name: 'prompts',
    version: '2.4.0',
    repository: 'terkelg/prompts',
    license: 'MIT',
  },
  {
    name: 'prop-types',
    version: '15.8.1',
    repository: 'facebook/prop-types',
    license: 'MIT',
  },
  {
    name: 'proxy-addr',
    version: '2.0.7',
    repository: 'jshttp/proxy-addr',
    license: 'MIT',
  },
  {
    name: 'proxy-from-env',
    version: '1.0.0',
    repository: 'https://github.com/Rob--W/proxy-from-env',
    license: '',
  },
  {
    name: 'prr',
    version: '1.0.1',
    repository: 'https://github.com/rvagg/prr',
    license: 'MIT',
  },
  {
    name: 'psl',
    version: '1.8.0',
    repository: 'git@github.com:lupomontero/psl',
    license: 'MIT',
  },
  {
    name: 'public-encrypt',
    version: '4.0.3',
    repository: 'https://github.com/crypto-browserify/publicEncrypt',
    license: '',
  },
  {
    name: 'pump',
    version: '2.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'pumpify',
    version: '1.5.1',
    repository: 'http://github.com/mafintosh/pumpify',
    license: 'MIT',
  },
  {
    name: 'punycode',
    version: '1.3.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'pure-color',
    version: '1.3.0',
    repository: 'http://github.com/WickyNilliams/pure-color',
    license: 'MIT',
  },
  {
    name: 'q',
    version: '1.5.1',
    repository: 'http://github.com/kriskowal/q',
    license: '',
  },
  {
    name: 'qs',
    version: '6.5.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'query-string',
    version: '4.3.4',
    repository: 'sindresorhus/query-string',
    license: 'MIT',
  },
  {
    name: 'querystring',
    version: '0.2.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'querystring-es3',
    version: '0.2.1',
    repository: 'http://github.com/mike-spainhower/querystring',
    license: '',
  },
  {
    name: 'querystringify',
    version: '2.2.0',
    repository: 'https://github.com/unshiftio/querystringify',
    license: 'MIT',
  },
  {
    name: 'queue-microtask',
    version: '1.2.3',
    repository: 'http://github.com/feross/queue-microtask',
    license: 'MIT',
  },
  {
    name: 'raf',
    version: '3.4.1',
    repository: 'http://github.com/chrisdickinson/raf',
    license: '',
  },
  {
    name: 'ramda',
    version: '0.26.1',
    repository: 'http://github.com/ramda/ramda',
    license: 'MIT',
  },
  {
    name: 'randombytes',
    version: '2.1.0',
    repository: 'git@github.com:crypto-browserify/randombytes',
    license: 'MIT',
  },
  {
    name: 'randomfill',
    version: '1.0.4',
    repository: 'https://github.com/crypto-browserify/randomfill',
    license: 'MIT',
  },
  {
    name: 'range-parser',
    version: '1.2.1',
    repository: 'jshttp/range-parser',
    license: 'MIT',
  },
  {
    name: 'raw-body',
    version: '2.4.0',
    repository: 'stream-utils/raw-body',
    license: 'MIT',
  },
  {
    name: 'react',
    version: '17.0.2',
    repository: 'https://github.com/facebook/react',
    license: 'MIT',
  },
  {
    name: 'react-app-polyfill',
    version: '2.0.0',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'react-base16-styling',
    version: '0.6.0',
    repository: 'https://github.com/alexkuz/react-base16-styling',
    license: 'MIT',
  },
  {
    name: 'react-cookie-consent',
    version: '6.4.1',
    repository: 'https://github.com/Mastermindzh/react-cookie-consent',
    license: 'MIT',
  },
  {
    name: 'react-csv',
    version: '2.2.2',
    repository: 'https://github.com/react-csv/react-csv',
    license: 'MIT',
  },
  {
    name: 'react-dev-utils',
    version: '11.0.4',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'react-dom',
    version: '17.0.2',
    repository: 'https://github.com/facebook/react',
    license: 'MIT',
  },
  {
    name: 'react-error-overlay',
    version: '6.0.9',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'react-i18next',
    version: '11.13.0',
    repository: 'https://github.com/i18next/react-i18next',
    license: 'MIT',
  },
  {
    name: 'react-idle-timer',
    version: '5.2.0',
    repository: 'https://github.com/supremetechnopriest/react-idle-timer',
    license: 'MIT',
  },
  {
    name: 'react-infinite-scroll-component',
    version: '6.1.0',
    repository: 'https://github.com/ankeetmaini/react-infinite-scroll-component',
    license: 'MIT',
  },
  {
    name: 'react-is',
    version: '16.13.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'react-json-view',
    version: '1.21.3',
    repository: 'https://github.com/mac-s-g/react-json-view',
    license: 'MIT',
  },
  {
    name: 'react-lifecycles-compat',
    version: '3.0.4',
    repository: 'reactjs/react-lifecycles-compat',
    license: 'MIT',
  },
  {
    name: 'react-redux',
    version: '7.2.6',
    repository: 'github:reduxjs/react-redux',
    license: 'MIT',
  },
  {
    name: 'react-refresh',
    version: '0.8.3',
    repository: 'https://github.com/facebook/react',
    license: 'MIT',
  },
  {
    name: 'react-router',
    version: '5.2.1',
    repository: 'ReactTraining/react-router',
    license: '',
  },
  {
    name: 'react-router-dom',
    version: '5.3.0',
    repository: 'ReactTraining/react-router',
    license: '',
  },
  {
    name: 'react-scripts',
    version: '4.0.3',
    repository: 'https://github.com/facebook/create-react-app',
    license: 'MIT',
  },
  {
    name: 'react-textarea-autosize',
    version: '8.3.3',
    repository: 'https://github.com/Andarist/react-textarea-autosize',
    license: 'MIT',
  },
  {
    name: 'react-ticker',
    version: '1.3.2',
    repository: 'https://github.com/AndreasFaust/react-ticker',
    license: '',
  },
  {
    name: 'react-transition-group',
    version: '4.4.2',
    repository: 'https://github.com/reactjs/react-transition-group',
    license: 'BSD',
  },
  {
    name: 'read-pkg',
    version: '5.2.0',
    repository: 'sindresorhus/read-pkg',
    license: 'MIT',
  },
  {
    name: 'read-pkg-up',
    version: '7.0.1',
    repository: 'sindresorhus/read-pkg-up',
    license: 'MIT',
  },
  {
    name: 'readable-stream',
    version: '2.3.7',
    repository: 'http://github.com/nodejs/readable-stream',
    license: '',
  },
  {
    name: 'readdirp',
    version: '2.2.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'recast',
    version: '0.20.5',
    repository: 'http://github.com/benjamn/recast',
    license: '',
  },
  {
    name: 'recursive-readdir',
    version: '2.2.2',
    repository: 'http://github.com/jergason/recursive-readdir',
    license: 'MIT',
  },
  {
    name: 'redent',
    version: '3.0.0',
    repository: 'sindresorhus/redent',
    license: 'MIT',
  },
  {
    name: 'redux',
    version: '4.1.2',
    repository: 'github:reduxjs/redux',
    license: 'MIT',
  },
  {
    name: 'redux-persist',
    version: '6.0.0',
    repository: 'rt2zz/redux-persist',
    license: 'MIT',
  },
  {
    name: 'redux-thunk',
    version: '2.4.0',
    repository: 'github:reduxjs/redux-thunk',
    license: 'MIT',
  },
  {
    name: 'regenerate',
    version: '1.4.2',
    repository: 'https://github.com/mathiasbynens/regenerate',
    license: '',
  },
  {
    name: 'regenerate-unicode-properties',
    version: '9.0.0',
    repository: 'https://github.com/mathiasbynens/regenerate-unicode-properties',
    license: '',
  },
  {
    name: 'regenerator-runtime',
    version: '0.11.1',
    repository: '(none)',
    license: '',
  },
  {
    name: 'regenerator-transform',
    version: '0.14.5',
    repository: 'https://github.com/facebook/regenerator/tree/master/packages/regenerator-transform',
    license: 'MIT',
  },
  {
    name: 'regex-not',
    version: '1.0.2',
    repository: 'jonschlinkert/regex-not',
    license: 'MIT',
  },
  {
    name: 'regex-parser',
    version: '2.2.11',
    repository: 'git@github.com:IonicaBizau/regex-parser.js',
    license: 'MIT',
  },
  {
    name: 'regexp.prototype.flags',
    version: '1.3.1',
    repository: 'http://github.com/es-shims/RegExp.prototype.flags',
    license: 'MIT',
  },
  {
    name: 'regexpp',
    version: '3.2.0',
    repository: 'https://github.com/mysticatea/regexpp',
    license: 'MIT',
  },
  {
    name: 'regexpu-core',
    version: '4.8.0',
    repository: 'https://github.com/mathiasbynens/regexpu-core',
    license: '',
  },
  {
    name: 'regjsgen',
    version: '0.5.2',
    repository: 'https://github.com/bnjmnt4n/regjsgen',
    license: 'MIT',
  },
  {
    name: 'regjsparser',
    version: '0.7.0',
    repository: 'git@github.com:jviereck/regjsparser',
    license: '',
  },
  {
    name: 'relateurl',
    version: '0.2.7',
    repository: 'http://github.com/stevenvachon/relateurl',
    license: 'MIT',
  },
  {
    name: 'remove-trailing-separator',
    version: '1.1.0',
    repository: 'https://github.com/darsain/remove-trailing-separator',
    license: '',
  },
  {
    name: 'renderkid',
    version: '2.0.7',
    repository: 'https://github.com/AriaMinaei/RenderKid',
    license: 'MIT',
  },
  {
    name: 'repeat-element',
    version: '1.1.4',
    repository: 'jonschlinkert/repeat-element',
    license: 'MIT',
  },
  {
    name: 'repeat-string',
    version: '1.6.1',
    repository: 'jonschlinkert/repeat-string',
    license: 'MIT',
  },
  {
    name: 'request-progress',
    version: '3.0.0',
    repository: 'http://github.com/IndigoUnited/node-request-progress',
    license: '',
  },
  {
    name: 'require-directory',
    version: '2.1.1',
    repository: 'http://github.com/troygoode/node-require-directory',
    license: 'MIT',
  },
  {
    name: 'require-from-string',
    version: '2.0.2',
    repository: 'floatdrop/require-from-string',
    license: 'MIT',
  },
  {
    name: 'require-main-filename',
    version: '2.0.0',
    repository: 'ssh://git@github.com/yargs/require-main-filename',
    license: '',
  },
  {
    name: 'requires-port',
    version: '1.0.0',
    repository: 'https://github.com/unshiftio/requires-port',
    license: 'MIT',
  },
  {
    name: 'reselect',
    version: '4.1.2',
    repository: 'https://github.com/reduxjs/reselect',
    license: 'MIT',
  },
  {
    name: 'resolve',
    version: '1.18.1',
    repository: 'http://github.com/browserify/resolve',
    license: 'MIT',
  },
  {
    name: 'resolve-cwd',
    version: '2.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'resolve-from',
    version: '3.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'resolve-pathname',
    version: '3.0.0',
    repository: 'mjackson/resolve-pathname',
    license: 'MIT',
  },
  {
    name: 'resolve-url',
    version: '0.2.1',
    repository: 'lydell/resolve-url',
    license: 'MIT',
  },
  {
    name: 'resolve-url-loader',
    version: '3.1.4',
    repository: 'https://github.com/bholloway/resolve-url-loader',
    license: 'MIT',
  },
  {
    name: 'restore-cursor',
    version: '3.1.0',
    repository: 'sindresorhus/restore-cursor',
    license: 'MIT',
  },
  {
    name: 'ret',
    version: '0.1.15',
    repository: 'http://github.com/fent/ret.js',
    license: '',
  },
  {
    name: 'retry',
    version: '0.12.0',
    repository: 'http://github.com/tim-kos/node-retry',
    license: '',
  },
  {
    name: 'reusify',
    version: '1.0.4',
    repository: 'https://github.com/mcollina/reusify',
    license: 'MIT',
  },
  {
    name: 'rework',
    version: '1.0.1',
    repository: 'http://github.com/reworkcss/rework',
    license: '',
  },
  {
    name: 'rework-visit',
    version: '1.0.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'rgb-regex',
    version: '1.0.1',
    repository: 'https://github.com/regexps/rgb-regex',
    license: 'MIT',
  },
  {
    name: 'rgba-regex',
    version: '1.0.0',
    repository: 'https://github.com/johnotander/rgba-regex',
    license: 'MIT',
  },
  {
    name: 'rifm',
    version: '0.12.1',
    repository: 'https://github.com/istarkov/rifm',
    license: 'MIT',
  },
  {
    name: 'rimraf',
    version: '2.6.3',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'ripemd160',
    version: '2.0.2',
    repository: 'https://github.com/crypto-browserify/ripemd160',
    license: 'MIT',
  },
  {
    name: 'rollup',
    version: '1.32.1',
    repository: 'rollup/rollup',
    license: 'Apache;ISC;MIT',
  },
  {
    name: 'rollup-plugin-babel',
    version: '4.4.0',
    repository: 'rollup/rollup-plugin-babel',
    license: 'MIT',
  },
  {
    name: 'rollup-plugin-terser',
    version: '5.3.1',
    repository: 'https://github.com/TrySound/rollup-plugin-terser',
    license: 'MIT',
  },
  {
    name: 'rollup-pluginutils',
    version: '2.8.2',
    repository: 'rollup/rollup-pluginutils',
    license: '',
  },
  {
    name: 'rsvp',
    version: '4.8.5',
    repository: 'https://github.com/tildeio/rsvp.js',
    license: '',
  },
  {
    name: 'run-parallel',
    version: '1.2.0',
    repository: 'http://github.com/feross/run-parallel',
    license: 'MIT',
  },
  {
    name: 'run-queue',
    version: '1.0.3',
    repository: 'https://github.com/iarna/run-queue',
    license: '',
  },
  {
    name: 'rxjs',
    version: '7.4.0',
    repository: 'https://github.com/reactivex/rxjs',
    license: 'Apache',
  },
  {
    name: 'safe-buffer',
    version: '5.1.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'safe-regex',
    version: '1.1.0',
    repository: 'http://github.com/substack/safe-regex',
    license: 'MIT',
  },
  {
    name: 'safer-buffer',
    version: '2.1.2',
    repository: 'https://github.com/ChALkeR/safer-buffer',
    license: 'MIT',
  },
  {
    name: 'sane',
    version: '4.1.0',
    repository: 'https://github.com/amasad/sane',
    license: '',
  },
  {
    name: 'sanitize.css',
    version: '10.0.0',
    repository: 'csstools/sanitize.css',
    license: '',
  },
  {
    name: 'sass-loader',
    version: '10.2.0',
    repository: 'webpack-contrib/sass-loader',
    license: '',
  },
  {
    name: 'sax',
    version: '1.2.4',
    repository: 'http://github.com/isaacs/sax-js',
    license: 'ISC;MIT',
  },
  {
    name: 'saxes',
    version: '5.0.1',
    repository: 'https://github.com/lddubeau/saxes',
    license: '',
  },
  {
    name: 'scheduler',
    version: '0.20.2',
    repository: 'https://github.com/facebook/react',
    license: 'MIT',
  },
  {
    name: 'schema-utils',
    version: '1.0.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'select-hose',
    version: '2.0.0',
    repository: 'ssh://git@github.com/indutny/select-hose',
    license: '',
  },
  {
    name: 'selfsigned',
    version: '1.10.11',
    repository: 'http://github.com/jfromaniello/selfsigned',
    license: 'MIT',
  },
  {
    name: 'semver',
    version: '5.7.1',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'send',
    version: '0.17.1',
    repository: 'pillarjs/send',
    license: 'MIT',
  },
  {
    name: 'serialize-javascript',
    version: '4.0.0',
    repository: 'https://github.com/yahoo/serialize-javascript',
    license: '',
  },
  {
    name: 'serve-index',
    version: '1.9.1',
    repository: 'expressjs/serve-index',
    license: 'MIT',
  },
  {
    name: 'serve-static',
    version: '1.14.1',
    repository: 'expressjs/serve-static',
    license: 'MIT',
  },
  {
    name: 'set-blocking',
    version: '2.0.0',
    repository: 'https://github.com/yargs/set-blocking',
    license: '',
  },
  {
    name: 'set-value',
    version: '2.0.1',
    repository: 'jonschlinkert/set-value',
    license: 'MIT',
  },
  {
    name: 'setimmediate',
    version: '1.0.5',
    repository: 'YuzuJS/setImmediate',
    license: '',
  },
  {
    name: 'setprototypeof',
    version: '1.1.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'sha.js',
    version: '2.4.11',
    repository: 'http://github.com/crypto-browserify/sha.js',
    license: '',
  },
  {
    name: 'shallow-clone',
    version: '3.0.1',
    repository: 'jonschlinkert/shallow-clone',
    license: 'MIT',
  },
  {
    name: 'shebang-command',
    version: '1.2.0',
    repository: 'kevva/shebang-command',
    license: 'MIT',
  },
  {
    name: 'shebang-regex',
    version: '1.0.0',
    repository: 'sindresorhus/shebang-regex',
    license: 'MIT',
  },
  {
    name: 'shell-quote',
    version: '1.7.2',
    repository: 'http://github.com/substack/node-shell-quote',
    license: 'MIT',
  },
  {
    name: 'shellwords',
    version: '0.1.1',
    repository: 'http://github.com/jimmycuadra/shellwords',
    license: '',
  },
  {
    name: 'side-channel',
    version: '1.0.4',
    repository: 'https://github.com/ljharb/side-channel',
    license: 'MIT',
  },
  {
    name: 'signal-exit',
    version: '3.0.5',
    repository: 'https://github.com/tapjs/signal-exit',
    license: 'ISC',
  },
  {
    name: 'simple-swizzle',
    version: '0.2.2',
    repository: 'qix-/node-simple-swizzle',
    license: 'MIT',
  },
  {
    name: 'sisteransi',
    version: '1.0.5',
    repository: 'https://github.com/terkelg/sisteransi',
    license: 'MIT',
  },
  {
    name: 'slash',
    version: '3.0.0',
    repository: 'sindresorhus/slash',
    license: 'MIT',
  },
  {
    name: 'slice-ansi',
    version: '3.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'snapdragon',
    version: '0.8.2',
    repository: 'jonschlinkert/snapdragon',
    license: 'MIT',
  },
  {
    name: 'snapdragon-node',
    version: '2.1.1',
    repository: 'jonschlinkert/snapdragon-node',
    license: 'MIT',
  },
  {
    name: 'snapdragon-util',
    version: '3.0.1',
    repository: 'jonschlinkert/snapdragon-util',
    license: 'MIT',
  },
  {
    name: 'sockjs',
    version: '0.3.21',
    repository: 'https://github.com/sockjs/sockjs-node',
    license: 'MIT',
  },
  {
    name: 'sockjs-client',
    version: '1.5.2',
    repository: 'https://github.com/sockjs/sockjs-client',
    license: 'MIT',
  },
  {
    name: 'sort-keys',
    version: '1.1.2',
    repository: 'sindresorhus/sort-keys',
    license: 'MIT',
  },
  {
    name: 'source-list-map',
    version: '2.0.1',
    repository: 'https://github.com/webpack/source-list-map',
    license: '',
  },
  {
    name: 'source-map',
    version: '0.5.7',
    repository: '(none)',
    license: '',
  },
  {
    name: 'source-map-js',
    version: '0.6.2',
    repository: 'http://github.com/7rulnik/source-map',
    license: '',
  },
  {
    name: 'source-map-resolve',
    version: '0.5.3',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'source-map-support',
    version: '0.5.20',
    repository: 'https://github.com/evanw/node-source-map-support',
    license: 'MIT',
  },
  {
    name: 'source-map-url',
    version: '0.4.1',
    repository: 'lydell/source-map-url',
    license: 'MIT',
  },
  {
    name: 'sourcemap-codec',
    version: '1.4.8',
    repository: 'https://github.com/Rich-Harris/sourcemap-codec',
    license: 'MIT',
  },
  {
    name: 'spdx-correct',
    version: '3.1.1',
    repository: 'jslicense/spdx-correct.js',
    license: 'Apache',
  },
  {
    name: 'spdx-exceptions',
    version: '2.3.0',
    repository: 'kemitchell/spdx-exceptions.json',
    license: '',
  },
  {
    name: 'spdx-expression-parse',
    version: '3.0.1',
    repository: 'jslicense/spdx-expression-parse.js',
    license: 'MIT',
  },
  {
    name: 'spdx-license-ids',
    version: '3.0.10',
    repository: 'jslicense/spdx-license-ids',
    license: '',
  },
  {
    name: 'spdy',
    version: '4.0.2',
    repository: 'http://github.com/indutny/node-spdy',
    license: '',
  },
  {
    name: 'spdy-transport',
    version: '3.0.0',
    repository: 'http://github.com/spdy-http2/spdy-transport',
    license: '',
  },
  {
    name: 'split-string',
    version: '3.1.0',
    repository: 'jonschlinkert/split-string',
    license: 'MIT',
  },
  {
    name: 'sprintf-js',
    version: '1.0.3',
    repository: 'https://github.com/alexei/sprintf.js',
    license: '',
  },
  {
    name: 'sshpk',
    version: '1.16.1',
    repository: 'https://github.com/joyent/node-sshpk',
    license: '',
  },
  {
    name: 'ssri',
    version: '6.0.2',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'stable',
    version: '0.1.8',
    repository: 'https://github.com/Two-Screen/stable',
    license: '',
  },
  {
    name: 'stack-utils',
    version: '2.0.5',
    repository: 'tapjs/stack-utils',
    license: 'MIT',
  },
  {
    name: 'stackframe',
    version: '1.2.0',
    repository: 'http://github.com/stacktracejs/stackframe',
    license: '',
  },
  {
    name: 'static-extend',
    version: '0.1.2',
    repository: 'jonschlinkert/static-extend',
    license: 'MIT',
  },
  {
    name: 'statuses',
    version: '1.5.0',
    repository: 'jshttp/statuses',
    license: 'MIT',
  },
  {
    name: 'stream-browserify',
    version: '2.0.2',
    repository: 'http://github.com/browserify/stream-browserify',
    license: 'MIT',
  },
  {
    name: 'stream-each',
    version: '1.2.3',
    repository: 'https://github.com/mafintosh/stream-each',
    license: 'MIT',
  },
  {
    name: 'stream-http',
    version: '2.8.3',
    repository: 'http://github.com/jhiesey/stream-http',
    license: 'MIT',
  },
  {
    name: 'stream-shift',
    version: '1.0.1',
    repository: 'https://github.com/mafintosh/stream-shift',
    license: 'MIT',
  },
  {
    name: 'strict-uri-encode',
    version: '1.1.0',
    repository: 'kevva/strict-uri-encode',
    license: 'MIT',
  },
  {
    name: 'string-length',
    version: '4.0.2',
    repository: 'sindresorhus/string-length',
    license: 'MIT',
  },
  {
    name: 'string-natural-compare',
    version: '3.0.1',
    repository: 'github:nwoltman/string-natural-compare',
    license: 'MIT',
  },
  {
    name: 'string-width',
    version: '3.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'string.prototype.matchall',
    version: '4.0.6',
    repository: 'https://github.com/es-shims/String.prototype.matchAll',
    license: 'MIT',
  },
  {
    name: 'string.prototype.trimend',
    version: '1.0.4',
    repository: 'http://github.com/es-shims/String.prototype.trimEnd',
    license: 'MIT',
  },
  {
    name: 'string.prototype.trimstart',
    version: '1.0.4',
    repository: 'http://github.com/es-shims/String.prototype.trimStart',
    license: 'MIT',
  },
  {
    name: 'string_decoder',
    version: '1.1.1',
    repository: 'http://github.com/nodejs/string_decoder',
    license: '',
  },
  {
    name: 'stringify-object',
    version: '3.3.0',
    repository: 'yeoman/stringify-object',
    license: '',
  },
  {
    name: 'strip-ansi',
    version: '3.0.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'strip-bom',
    version: '3.0.0',
    repository: 'sindresorhus/strip-bom',
    license: 'MIT',
  },
  {
    name: 'strip-comments',
    version: '1.0.2',
    repository: 'jonschlinkert/strip-comments',
    license: 'MIT',
  },
  {
    name: 'strip-eof',
    version: '1.0.0',
    repository: 'sindresorhus/strip-eof',
    license: 'MIT',
  },
  {
    name: 'strip-final-newline',
    version: '2.0.0',
    repository: 'sindresorhus/strip-final-newline',
    license: 'MIT',
  },
  {
    name: 'strip-indent',
    version: '3.0.0',
    repository: 'sindresorhus/strip-indent',
    license: 'MIT',
  },
  {
    name: 'strip-json-comments',
    version: '3.1.1',
    repository: 'sindresorhus/strip-json-comments',
    license: 'MIT',
  },
  {
    name: 'style-loader',
    version: '1.3.0',
    repository: 'webpack-contrib/style-loader',
    license: '',
  },
  {
    name: 'stylehacks',
    version: '4.0.3',
    repository: 'cssnano/cssnano',
    license: '',
  },
  {
    name: 'stylis',
    version: '4.0.13',
    repository: 'https://github.com/thysultan/stylis.js',
    license: 'MIT',
  },
  {
    name: 'supports-color',
    version: '5.5.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'supports-hyperlinks',
    version: '2.2.0',
    repository: 'jamestalmage/supports-hyperlinks',
    license: 'MIT',
  },
  {
    name: 'svg-parser',
    version: '2.0.4',
    repository: 'https://github.com/Rich-Harris/svg-parser',
    license: '',
  },
  {
    name: 'svgo',
    version: '1.3.2',
    repository: 'http://github.com/svg/svgo',
    license: 'MIT',
  },
  {
    name: 'symbol-tree',
    version: '3.2.4',
    repository: 'https://github.com/jsdom/js-symbol-tree',
    license: 'MIT',
  },
  {
    name: 'table',
    version: '6.8.0',
    repository: 'https://github.com/gajus/table',
    license: '',
  },
  {
    name: 'tapable',
    version: '1.1.3',
    repository: 'http://github.com/webpack/tapable',
    license: 'MIT',
  },
  {
    name: 'tar',
    version: '6.1.11',
    repository: 'https://github.com/npm/node-tar',
    license: 'ISC',
  },
  {
    name: 'temp',
    version: '0.8.4',
    repository: 'http://github.com/bruce/node-temp',
    license: 'MIT',
  },
  {
    name: 'temp-dir',
    version: '1.0.0',
    repository: 'sindresorhus/temp-dir',
    license: 'MIT',
  },
  {
    name: 'tempy',
    version: '0.3.0',
    repository: 'sindresorhus/tempy',
    license: 'MIT',
  },
  {
    name: 'terminal-link',
    version: '2.1.1',
    repository: 'sindresorhus/terminal-link',
    license: 'MIT',
  },
  {
    name: 'terser',
    version: '4.8.0',
    repository: 'https://github.com/terser/terser',
    license: 'BSD',
  },
  {
    name: 'terser-webpack-plugin',
    version: '1.4.5',
    repository: '(none)',
    license: '',
  },
  {
    name: 'test-exclude',
    version: '6.0.0',
    repository: 'https://github.com/istanbuljs/test-exclude',
    license: '',
  },
  {
    name: 'text-table',
    version: '0.2.0',
    repository: 'http://github.com/substack/text-table',
    license: 'MIT',
  },
  {
    name: 'throat',
    version: '5.0.0',
    repository: 'https://github.com/ForbesLindesay/throat',
    license: '',
  },
  {
    name: 'throttle-debounce',
    version: '2.3.0',
    repository: 'https://github.com/niksy/throttle-debounce',
    license: '',
  },
  {
    name: 'throttleit',
    version: '1.0.0',
    repository: 'http://github.com/component/throttle',
    license: '',
  },
  {
    name: 'through',
    version: '2.3.8',
    repository: 'https://github.com/dominictarr/through',
    license: 'MIT',
  },
  {
    name: 'through2',
    version: '2.0.5',
    repository: 'https://github.com/rvagg/through2',
    license: 'MIT',
  },
  {
    name: 'thunky',
    version: '1.1.0',
    repository: 'http://github.com/mafintosh/thunky',
    license: 'MIT',
  },
  {
    name: 'timers-browserify',
    version: '2.0.12',
    repository: 'http://github.com/jryans/timers-browserify',
    license: '',
  },
  {
    name: 'timsort',
    version: '0.3.0',
    repository: 'https://github.com/mziccard/node-timsort',
    license: 'MIT',
  },
  {
    name: 'tiny-invariant',
    version: '1.2.0',
    repository: 'https://github.com/alexreardon/tiny-invariant',
    license: 'MIT',
  },
  {
    name: 'tiny-warning',
    version: '1.0.3',
    repository: 'https://github.com/alexreardon/tiny-warning',
    license: 'MIT',
  },
  {
    name: 'tmp',
    version: '0.2.1',
    repository: 'https://github.com/raszi/node-tmp',
    license: 'MIT',
  },
  {
    name: 'tmpl',
    version: '1.0.5',
    repository: 'https://github.com/daaku/nodejs-tmpl',
    license: 'BSD',
  },
  {
    name: 'to-arraybuffer',
    version: '1.0.1',
    repository: 'http://github.com/jhiesey/to-arraybuffer',
    license: 'MIT',
  },
  {
    name: 'to-fast-properties',
    version: '2.0.0',
    repository: 'sindresorhus/to-fast-properties',
    license: 'MIT',
  },
  {
    name: 'to-object-path',
    version: '0.3.0',
    repository: 'jonschlinkert/to-object-path',
    license: 'MIT',
  },
  {
    name: 'to-regex',
    version: '3.0.2',
    repository: 'jonschlinkert/to-regex',
    license: 'MIT',
  },
  {
    name: 'to-regex-range',
    version: '2.1.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'toidentifier',
    version: '1.0.0',
    repository: 'component/toidentifier',
    license: 'MIT',
  },
  {
    name: 'tough-cookie',
    version: '2.5.0',
    repository: '(none)',
    license: '',
  },
  {
    name: 'tr46',
    version: '2.1.0',
    repository: 'https://github.com/jsdom/tr46',
    license: 'MIT',
  },
  {
    name: 'tryer',
    version: '1.0.1',
    repository: 'https://gitlab.com/philbooth/tryer',
    license: '',
  },
  {
    name: 'ts-essentials',
    version: '2.0.12',
    repository: 'git@github.com:krzkaczor/ts-essentials',
    license: '',
  },
  {
    name: 'ts-md5',
    version: '1.2.9',
    repository: 'https://github.com/cotag/ts-md5',
    license: '',
  },
  {
    name: 'ts-pnp',
    version: '1.2.0',
    repository: 'https://github.com/arcanis/ts-pnp',
    license: '',
  },
  {
    name: 'tsconfig-paths',
    version: '3.11.0',
    repository: 'https://github.com/dividab/tsconfig-paths',
    license: 'MIT',
  },
  {
    name: 'tslib',
    version: '1.14.1',
    repository: 'https://github.com/Microsoft/tslib',
    license: '',
  },
  {
    name: 'tsutils',
    version: '3.21.0',
    repository: 'https://github.com/ajafff/tsutils',
    license: 'MIT',
  },
  {
    name: 'tty-browserify',
    version: '0.0.0',
    repository: 'http://github.com/substack/tty-browserify',
    license: 'MIT',
  },
  {
    name: 'tunnel-agent',
    version: '0.6.0',
    repository: 'https://github.com/mikeal/tunnel-agent',
    license: 'Apache',
  },
  {
    name: 'tweetnacl',
    version: '0.14.5',
    repository: 'https://github.com/dchest/tweetnacl-js',
    license: '',
  },
  {
    name: 'type',
    version: '1.2.0',
    repository: 'medikoo/type',
    license: 'ISC',
  },
  {
    name: 'type-check',
    version: '0.3.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'type-detect',
    version: '4.0.8',
    repository: 'ssh://git@github.com/chaijs/type-detect',
    license: '',
  },
  {
    name: 'type-fest',
    version: '0.3.1',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'type-is',
    version: '1.6.18',
    repository: 'jshttp/type-is',
    license: 'MIT',
  },
  {
    name: 'typedarray',
    version: '0.0.6',
    repository: 'http://github.com/substack/typedarray',
    license: '',
  },
  {
    name: 'typedarray-to-buffer',
    version: '3.1.5',
    repository: 'http://github.com/feross/typedarray-to-buffer',
    license: 'MIT',
  },
  {
    name: 'typescript',
    version: '4.4.4',
    repository: 'https://github.com/Microsoft/TypeScript',
    license: 'Apache',
  },
  {
    name: 'ua-parser-js',
    version: '0.7.31',
    repository: 'https://github.com/faisalman/ua-parser-js',
    license: 'MIT',
  },
  {
    name: 'unbox-primitive',
    version: '1.0.1',
    repository: 'https://github.com/ljharb/unbox-primitive',
    license: 'MIT',
  },
  {
    name: 'unicode-canonical-property-names-ecmascript',
    version: '2.0.0',
    repository: 'https://github.com/mathiasbynens/unicode-canonical-property-names-ecmascript',
    license: '',
  },
  {
    name: 'unicode-match-property-ecmascript',
    version: '2.0.0',
    repository: 'https://github.com/mathiasbynens/unicode-match-property-ecmascript',
    license: '',
  },
  {
    name: 'unicode-match-property-value-ecmascript',
    version: '2.0.0',
    repository: 'https://github.com/mathiasbynens/unicode-match-property-value-ecmascript',
    license: '',
  },
  {
    name: 'unicode-property-aliases-ecmascript',
    version: '2.0.0',
    repository: 'https://github.com/mathiasbynens/unicode-property-aliases-ecmascript',
    license: '',
  },
  {
    name: 'union-value',
    version: '1.0.1',
    repository: 'jonschlinkert/union-value',
    license: 'MIT',
  },
  {
    name: 'uniq',
    version: '1.0.1',
    repository: 'http://github.com/mikolalysenko/uniq',
    license: 'MIT',
  },
  {
    name: 'uniqs',
    version: '2.0.0',
    repository: 'http://github.com/fgnass/uniqs',
    license: '',
  },
  {
    name: 'unique-filename',
    version: '1.1.1',
    repository: 'https://github.com/iarna/unique-filename',
    license: '',
  },
  {
    name: 'unique-slug',
    version: '2.0.2',
    repository: 'http://github.com/iarna/unique-slug',
    license: 'ISC',
  },
  {
    name: 'unique-string',
    version: '1.0.0',
    repository: 'sindresorhus/unique-string',
    license: 'MIT',
  },
  {
    name: 'universalify',
    version: '0.1.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'unpipe',
    version: '1.0.0',
    repository: 'stream-utils/unpipe',
    license: 'MIT',
  },
  {
    name: 'unquote',
    version: '1.1.1',
    repository: 'https://github.com/lakenen/node-unquote',
    license: 'MIT',
  },
  {
    name: 'unset-value',
    version: '1.0.0',
    repository: 'jonschlinkert/unset-value',
    license: 'MIT',
  },
  {
    name: 'untildify',
    version: '4.0.0',
    repository: 'sindresorhus/untildify',
    license: 'MIT',
  },
  {
    name: 'upath',
    version: '1.2.0',
    repository: 'http://github.com/anodynos/upath',
    license: '',
  },
  {
    name: 'update-browserslist-db',
    version: '1.0.5',
    repository: 'browserslist/update-db',
    license: 'MIT',
  },
  {
    name: 'uri-js',
    version: '4.4.1',
    repository: 'http://github.com/garycourt/uri-js',
    license: '',
  },
  {
    name: 'urix',
    version: '0.1.0',
    repository: 'lydell/urix',
    license: 'MIT',
  },
  {
    name: 'url',
    version: '0.11.0',
    repository: 'https://github.com/defunctzombie/node-url',
    license: 'MIT',
  },
  {
    name: 'url-loader',
    version: '4.1.1',
    repository: 'webpack-contrib/url-loader',
    license: '',
  },
  {
    name: 'url-parse',
    version: '1.5.3',
    repository: 'https://github.com/unshiftio/url-parse',
    license: 'MIT',
  },
  {
    name: 'use',
    version: '3.1.1',
    repository: 'jonschlinkert/use',
    license: 'MIT',
  },
  {
    name: 'use-composed-ref',
    version: '1.1.0',
    repository: 'https://github.com/Andarist/use-composed-ref',
    license: '',
  },
  {
    name: 'use-isomorphic-layout-effect',
    version: '1.1.1',
    repository: 'https://github.com/Andarist/use-isomorphic-layout-effect',
    license: 'MIT',
  },
  {
    name: 'use-latest',
    version: '1.2.0',
    repository: 'https://github.com/Andarist/use-latest',
    license: '',
  },
  {
    name: 'util',
    version: '0.10.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'util-deprecate',
    version: '1.0.2',
    repository: 'http://github.com/TooTallNate/util-deprecate',
    license: 'MIT',
  },
  {
    name: 'util.promisify',
    version: '1.0.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'utila',
    version: '0.4.0',
    repository: 'https://github.com/AriaMinaei/utila',
    license: 'MIT',
  },
  {
    name: 'utils-merge',
    version: '1.0.1',
    repository: 'http://github.com/jaredhanson/utils-merge',
    license: 'MIT',
  },
  {
    name: 'uuid',
    version: '3.4.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'v8-compile-cache',
    version: '2.3.0',
    repository: 'https://github.com/zertosh/v8-compile-cache',
    license: 'MIT',
  },
  {
    name: 'v8-to-istanbul',
    version: '7.1.2',
    repository: 'istanbuljs/v8-to-istanbul',
    license: '',
  },
  {
    name: 'validate-npm-package-license',
    version: '3.0.4',
    repository: 'kemitchell/validate-npm-package-license.js',
    license: 'Apache',
  },
  {
    name: 'value-equal',
    version: '1.0.1',
    repository: 'mjackson/value-equal',
    license: 'MIT',
  },
  {
    name: 'vary',
    version: '1.1.2',
    repository: 'jshttp/vary',
    license: 'MIT',
  },
  {
    name: 'vendors',
    version: '1.0.4',
    repository: 'wooorm/vendors',
    license: 'MIT',
  },
  {
    name: 'verror',
    version: '1.10.0',
    repository: 'http://github.com/davepacheco/node-verror',
    license: '',
  },
  {
    name: 'vm-browserify',
    version: '1.1.2',
    repository: 'http://github.com/substack/vm-browserify',
    license: 'MIT',
  },
  {
    name: 'void-elements',
    version: '3.1.0',
    repository: 'pugjs/void-elements',
    license: 'MIT',
  },
  {
    name: 'w3c-hr-time',
    version: '1.0.2',
    repository: 'https://github.com/jsdom/w3c-hr-time',
    license: 'MIT',
  },
  {
    name: 'w3c-xmlserializer',
    version: '2.0.0',
    repository: 'jsdom/w3c-xmlserializer',
    license: 'MIT',
  },
  {
    name: 'walker',
    version: '1.0.8',
    repository: 'https://github.com/daaku/nodejs-walker',
    license: '',
  },
  {
    name: 'watchpack',
    version: '1.7.5',
    repository: 'https://github.com/webpack/watchpack',
    license: '',
  },
  {
    name: 'watchpack-chokidar2',
    version: '2.0.1',
    repository: 'https://github.com/webpack/watchpack',
    license: '',
  },
  {
    name: 'wbuf',
    version: '1.7.3',
    repository: 'git@github.com:indutny/wbuf',
    license: '',
  },
  {
    name: 'web-vitals',
    version: '2.1.2',
    repository: 'https://github.com/GoogleChrome/web-vitals',
    license: 'Apache',
  },
  {
    name: 'webidl-conversions',
    version: '5.0.0',
    repository: '(none)',
    license: 'BSD',
  },
  {
    name: 'webpack',
    version: '4.44.2',
    repository: 'https://github.com/webpack/webpack',
    license: '',
  },
  {
    name: 'webpack-dev-middleware',
    version: '3.7.3',
    repository: 'webpack/webpack-dev-middleware',
    license: '',
  },
  {
    name: 'webpack-dev-server',
    version: '3.11.1',
    repository: 'https://github.com/webpack/webpack-dev-server',
    license: '',
  },
  {
    name: 'webpack-log',
    version: '2.0.0',
    repository: 'https://github.com/webpack-contrib/webpack-log',
    license: 'MIT',
  },
  {
    name: 'webpack-manifest-plugin',
    version: '2.2.0',
    repository: 'https://github.com/danethurber/webpack-manifest-plugin',
    license: 'MIT',
  },
  {
    name: 'webpack-merge',
    version: '5.8.0',
    repository: 'https://github.com/survivejs/webpack-merge',
    license: '',
  },
  {
    name: 'webpack-sources',
    version: '1.4.3',
    repository: 'https://github.com/webpack/webpack-sources',
    license: 'MIT',
  },
  {
    name: 'websocket-driver',
    version: '0.7.4',
    repository: 'http://github.com/faye/websocket-driver-node',
    license: '',
  },
  {
    name: 'websocket-extensions',
    version: '0.1.4',
    repository: 'http://github.com/faye/websocket-extensions-node',
    license: '',
  },
  {
    name: 'whatwg-encoding',
    version: '1.0.5',
    repository: 'jsdom/whatwg-encoding',
    license: '',
  },
  {
    name: 'whatwg-fetch',
    version: '3.6.2',
    repository: 'github/fetch',
    license: '',
  },
  {
    name: 'whatwg-mimetype',
    version: '2.3.0',
    repository: 'jsdom/whatwg-mimetype',
    license: '',
  },
  {
    name: 'whatwg-url',
    version: '8.7.0',
    repository: 'jsdom/whatwg-url',
    license: 'MIT',
  },
  {
    name: 'which',
    version: '1.3.1',
    repository: 'http://github.com/isaacs/node-which',
    license: 'ISC',
  },
  {
    name: 'which-boxed-primitive',
    version: '1.0.2',
    repository: 'https://github.com/inspect-js/which-boxed-primitive',
    license: 'MIT',
  },
  {
    name: 'which-module',
    version: '2.0.0',
    repository: 'https://github.com/nexdrew/which-module',
    license: '',
  },
  {
    name: 'wildcard',
    version: '2.0.0',
    repository: 'http://github.com/DamonOehlman/wildcard',
    license: '',
  },
  {
    name: 'word-wrap',
    version: '1.2.3',
    repository: 'jonschlinkert/word-wrap',
    license: 'MIT',
  },
  {
    name: 'workbox-background-sync',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-broadcast-update',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-build',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-cacheable-response',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-core',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-expiration',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-google-analytics',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-navigation-preload',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-precaching',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-range-requests',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-routing',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-strategies',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-streams',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-sw',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-webpack-plugin',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'workbox-window',
    version: '5.1.4',
    repository: 'googlechrome/workbox',
    license: '',
  },
  {
    name: 'worker-farm',
    version: '1.7.0',
    repository: 'https://github.com/rvagg/node-worker-farm',
    license: 'MIT',
  },
  {
    name: 'worker-rpc',
    version: '0.1.1',
    repository: 'https://github.com/DirtyHairy/worker-rpc',
    license: 'MIT',
  },
  {
    name: 'wrap-ansi',
    version: '5.1.0',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'wrappy',
    version: '1.0.2',
    repository: 'https://github.com/npm/wrappy',
    license: 'ISC',
  },
  {
    name: 'write-file-atomic',
    version: '2.4.3',
    repository: '(none)',
    license: '',
  },
  {
    name: 'ws',
    version: '6.2.2',
    repository: '(none)',
    license: 'MIT',
  },
  {
    name: 'xml-name-validator',
    version: '3.0.0',
    repository: 'jsdom/xml-name-validator',
    license: 'Apache',
  },
  {
    name: 'xmlchars',
    version: '2.2.0',
    repository: 'https://github.com/lddubeau/xmlchars',
    license: '',
  },
  {
    name: 'xtend',
    version: '4.0.2',
    repository: 'http://github.com/Raynos/xtend',
    license: 'MIT',
  },
  {
    name: 'y18n',
    version: '4.0.3',
    repository: 'git@github.com:yargs/y18n',
    license: '',
  },
  {
    name: 'yallist',
    version: '3.1.1',
    repository: '(none)',
    license: 'ISC',
  },
  {
    name: 'yaml',
    version: '1.10.2',
    repository: 'github:eemeli/yaml',
    license: '',
  },
  {
    name: 'yargs',
    version: '13.3.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'yargs-parser',
    version: '13.1.2',
    repository: '(none)',
    license: '',
  },
  {
    name: 'yauzl',
    version: '2.10.0',
    repository: 'https://github.com/thejoshwolfe/yauzl',
    license: 'MIT',
  },
  {
    name: 'yocto-queue',
    version: '0.1.0',
    repository: 'sindresorhus/yocto-queue',
    license: 'MIT',
  },
];
export default dependencies;
