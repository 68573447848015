import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../../../theme';
import { VehiclePolicy } from '../../../../Types';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      display: 'inline',
      color: theme.palette.primary.dark,
      marginRight: '5px',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
        color: 'black',
      },
    },
    value: {
      display: 'inline',
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
        color: 'black',
      },
    },
  })
);

interface VehicleReportPolicyItemProps {
  policy: VehiclePolicy;
}

const VehicleReportPolicyItem: FC<VehicleReportPolicyItemProps> = ({ policy }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid
      container
      sx={{ backgroundColor: theme.palette.white.main }}
      paddingLeft={3}
      paddingRight={3}
      paddingTop={2}
      paddingBottom={2}
    >
      {/* Col 1 */}
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.coveragePeriod')}:</Typography>
          <Typography className={classes.value}>
            {policy.coveragePeriod ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.insurer')}:</Typography>
          <Typography className={classes.value}>
            {i18n.language.startsWith('en') ? policy.carrier.nameEn : policy.carrier.nameFr}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.policyNumber')}:</Typography>
          <Typography className={classes.value}>
            {policy.policyNumber ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.policyStatus')}:</Typography>
          <Typography className={classes.value}>
            {(i18n.language.startsWith('en')
              ? policy.policyStatus?.descriptionEn
              : policy.policyStatus?.descriptionFr) ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
      </Grid>
      {/* Col 2 */}
      <Grid item container xs={6} paddingLeft={3}>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.vehicleUse')}:</Typography>
          <Typography className={classes.value}>
            {(i18n.language.startsWith('en')
              ? policy.vehicleUse?.vehicleUseDescriptionEn
              : policy.vehicleUse?.vehicleUseDescriptionFr) ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.typeOfUse')}:</Typography>
          <Typography className={classes.value}>
            {`${policy.vehicleClass.typeOfUseCode} / ${
              i18n.language.startsWith('en') ? policy.vehicleClass.descriptionEn : policy.vehicleClass.descriptionFr
            }`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.businessUse')}:</Typography>
          <Typography className={classes.value}>
            {policy.businessUsePercent ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.policies.commutingDistance')}:</Typography>
          <Typography className={classes.value}>
            {policy.commutingDistance
              ? `${parseInt(policy.commutingDistance).toString()}km`
              : t('vehicleReport.report.notAvailable')}
            {' / '}
            {policy.annualDrivingDistance
              ? `${parseInt(policy.annualDrivingDistance).toString()}km`
              : t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleReportPolicyItem;
