import { useEffect, useState } from 'react';

import { SdcReportAxios } from '../../../axios';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { FileStatus } from '../../../Types';

const useSdcFileStatus = () => {
  const handleError = useErrorHandler();

  const [fileStatusList, setFileStatusList] = useState<Array<FileStatus>>([]);
  const [isLoadingFileStatus, setIsLoadingFileStatus] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingFileStatus(true);
    SdcReportAxios.getSdcFileStatuses()
      .then((results) => {
        setFileStatusList(results);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setIsLoadingFileStatus(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { fileStatusList, isLoadingFileStatus };
};

export default useSdcFileStatus;
