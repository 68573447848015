import { requests } from '../axios';
import PermittedDomainOption, { PermittedDomainOptionFactory } from '../Types/PermittedDomain';

export const createPermittedDomain = (params: {
  domain: string;
  organizationId: number;
  reportingCompanyId?: number;
}): Promise<PermittedDomainOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<PermittedDomainOption>((resolve) => {
      const { domain, organizationId, reportingCompanyId } = params;
      resolve(PermittedDomainOptionFactory(1, domain, organizationId, false, reportingCompanyId));
    });
  } else {
    return requests.post('/permittedDomain/create', params);
  }
};

export const disablePermittedDomain = (id: number): Promise<PermittedDomainOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<PermittedDomainOption>((resolve) => {
      resolve(PermittedDomainOptionFactory(1, '@testdomain1.com', 1, false, 1));
    });
  } else {
    return requests.delete(`/permittedDomain/${id}`);
  }
};

export const getPermittedDomainsByCompanyId = (
  functionId: number,
  organizationId: number,
  reportingCompanyId: number | null
): Promise<PermittedDomainOption[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<PermittedDomainOption[]>((resolve) => {
      if (organizationId === 3 && reportingCompanyId === 7) {
        resolve([]);
      } else {
        resolve([
          PermittedDomainOptionFactory(1, '@testdomain1.com', 1, false, 1),
          PermittedDomainOptionFactory(1, '@testdomain2.com', 1, false, 1),
        ]);
      }
    });
  } else {
    return requests.get(
      `/permittedDomain/getByOrganization/${functionId}/?organizationId=${organizationId}`.concat(
        reportingCompanyId ? `&reportingCompanyId=${reportingCompanyId}` : ''
      ),
      {}
    );
  }
};

export const listPermittedDomains = (functionId: number): Promise<PermittedDomainOption[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<PermittedDomainOption[]>((resolve) => {
      resolve([
        PermittedDomainOptionFactory(1, '@testdomain1.com', 1, false, 1),
        PermittedDomainOptionFactory(1, '@testdomain2.com', 1, false, 1),
      ]);
    });
  } else {
    return requests.get(`/permittedDomain/get/${functionId}`, {});
  }
};

export const updatePermittedDomain = (
  id: number,
  params: { domain: string; organizationId: number; reportingCompanyId?: number }
): Promise<PermittedDomainOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<PermittedDomainOption>((resolve) => {
      const { domain, organizationId, reportingCompanyId } = params;
      resolve(PermittedDomainOptionFactory(1, domain, organizationId, false, reportingCompanyId));
    });
  } else {
    return requests.put(`/permittedDomain/${id}`, params);
  }
};
