import { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Card, CardContent, Dialog, Grid } from '@mui/material';

import { VehicleReportAxios } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import Loader from '../../Components/Loader';
import useAutofocus from '../../Hooks/UseAutofocus';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import theme from '../../theme';
import { ReactLocationState } from '../../Types';
import NoResultDialog from './Dialogs/NoResultDialog';

interface VehicleReportFormValues {
  vin: string;
}

const VehicleReportSearch: FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const { state, pathname } = useLocation<ReactLocationState>();

  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

  const [vinError, setVinError] = useState<string | null>(null);

  const [isNoResultDialogOpen, setIsNotResultDialogOpen] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<VehicleReportFormValues>({
    vin: '',
  });

  const vinAutofocus = useAutofocus();

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  // Update the translation if the user changes the language
  useEffect(() => {
    if (vinError) {
      setVinError(t('vehicleReport.search.vinError'));
    }
  }, [t, vinError, i18n]);

  const resetFormValues = () => {
    setFormValues({
      vin: '',
    });
    setVinError(null);
  };

  const handleInputChange = (e: any) => {
    const trimValue = e.target.value.trim().replace(/[^A-HJ-NPR-Za-hj-npr-z0-9]/gi, '');
    if (trimValue.length <= 17) {
      setFormValues({ ...formValues, [e.target.name]: trimValue });
    }
  };

  const handleSearch = async () => {
    if (formValues.vin.length === 0) {
      setVinError(t('vehicleReport.search.vinError'));
    } else {
      setVinError(null);
      setIsLoadingReport(true);
      await VehicleReportAxios.generate(formValues.vin)
        .then((result) => {
          const newState: ReactLocationState = { ...state, vehicleReport: result };
          history.push(`/vehicle-report/${result.vin}`, newState);

          setIsLoadingReport(false);
        })
        .catch((error) => {
          if (error.message.includes('404')) {
            setIsNotResultDialogOpen(true);
          } else {
            handleError(error);
          }
          setIsLoadingReport(false);
        });
    }
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <>
      <Card elevation={3} data-testid="ninetyDaysSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} md={10} lg={10} xl={10}>
                  <Grid item md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="vin"
                      value={formValues.vin}
                      label={t('vehicleReport.search.vin')}
                      labelGridSize={5}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'vin',
                        name: 'vin',
                        maxLength: 17,
                      }}
                      InputProps={{
                        inputRef: vinAutofocus,
                      }}
                      error={!!vinError}
                      helperText={vinError}
                      autoComplete="nope"
                      helpOutlineIconInfo={t('vehicleReport.search.vinIconInfo')}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(0) }}
                  ></Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={5} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          variant="contained"
                          id="btnSearch"
                          data-testid="btnSearch"
                          // onClick={handleSearch}
                          type="submit"
                        >
                          {t('vehicleReport.search.search')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={resetFormValues}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('search.clearFields')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingReport} />
      <Dialog open={isNoResultDialogOpen} data-testid="noResultDialog">
        <NoResultDialog onDismiss={() => setIsNotResultDialogOpen(false)} />
      </Dialog>
    </>
  );
};

export default VehicleReportSearch;
