import { requests } from '../axios';
import { BlobDto } from '../Types';

export const updateDisclaimer = (lang: string, disclaimer: BlobDto): Promise<BlobDto> => {
  return requests.put(`/disclaimer?lang=${lang}`, disclaimer);
};

export const getDisclaimer = (lang: string): Promise<BlobDto> => {
  return requests.get(`/disclaimer?lang=${lang}`, {});
};
