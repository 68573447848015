import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Button, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';

import { isAfter, isBefore, subYears } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { DataCorrection } from '../../../../axios';
import DashCustomInput from '../../../../Components/DashCustomInput';
import Loader from '../../../../Components/Loader';
import { DATE_FORMAT, DATE_MASK } from '../../../../Helpers/Constants';
import { formatDateToLocalTime, isSameDate } from '../../../../Helpers/DateHelper';
import useErrorHandler from '../../../../Hooks/UseErrorHandler';
import { fieldNameCorrection } from '../../../../Pages/Report';
import { addNotification } from '../../../../Slices/NotificationSlice';
import { setUpdatedReport } from '../../../../Slices/ReportSlice';
import theme from '../../../../theme';
import { CodeReference, OperatorCorrectionType, ReactLocationState, ReferenceType, Report } from '../../../../Types';

interface EditDialogDriverProps {
  title: string;
  value: string | number | CodeReference | null;
  fieldName: fieldNameCorrection;
  report: Report;
  onClose: () => void;
}

const EditDialogDriver: FC<EditDialogDriverProps> = ({ title, value, report, fieldName, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { state } = useLocation<ReactLocationState>();

  const [newValue, setNewValue] = useState<string | number | CodeReference | null>(value);
  const [newValueAutocompGender, setNewValueAutocompGender] = useState<ReferenceType | null>(null);
  const [newValueAutocompMarital, setNewValueAutocompMarital] = useState<ReferenceType | null>(null);
  const [newValueAutocompTraining, setNewValueAutocompTraining] = useState<ReferenceType | null>(null);

  const [genderOptions, setGenderOptions] = useState<ReferenceType[] | null>(null);
  const [maritalOptions, setMaritalOptions] = useState<ReferenceType[] | null>(null);
  const [trainingOptions, setTrainingOptions] = useState<ReferenceType[] | null>(null);

  const [errorField, setErrorField] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    DataCorrection.getGenders()
      .then((results) => {
        setGenderOptions(results);
        setNewValueAutocompGender(results.find((oneGen) => oneGen.code === report.gender?.code) ?? null);
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });

    DataCorrection.getMaritalStatuses()
      .then((results) => {
        setMaritalOptions(results);
        setNewValueAutocompMarital(results.find((oneMar) => oneMar.code === report.maritalStatus?.code) ?? null);
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });

    DataCorrection.getDriverTrainings()
      .then((results) => {
        setTrainingOptions(results);
        setNewValueAutocompTraining(results.find((oneTrain) => oneTrain.code === report.driverTraining?.code) ?? null);
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  }, [handleError, report.driverTraining?.code, report.gender?.code, report.maritalStatus?.code]);

  const validateValue = useCallback(() => {
    if (fieldName === 'yearsLicensed') {
      // validate Years Licensed must be >= Years Claims Free
      // if (report.numberOfYearsClaimsFree != null && (newValue as number) < report.numberOfYearsClaimsFree) {
      //   setErrorField(t('report.dataCorrection.errors.yearsLicensedGreaterYearsClaimsFree'));
      //   return false;
      // } else {
      //   setErrorField(null);
      // }

      // validate Years Licensed must be numeric less than 99
      if ((newValue as number) < 0 || (newValue as number) > 99) {
        setErrorField(t('report.dataCorrection.errors.yearsLicensedGreater99'));
        return false;
      } else {
        setErrorField(null);
      }

      if (!Number.isInteger(Number(newValue))) {
        setErrorField(t('report.dataCorrection.errors.fractionYearsLicense'));
        return false;
      } else {
        setErrorField(null);
      }

      //validate against dob
      if (report.dlnJurisdiction.code === 'AB') {
        if (report.dateOfBirth) {
          if ((newValue as number) > new Date().getFullYear() - new Date(report.dateOfBirth).getFullYear() - 14) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedGreater14'));
            return false;
          } else if ((newValue as number) < 0) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedLower0'));
            return false;
          } else {
            setErrorField(null);
            return true;
          }
        } else {
          if (report.yearOfBirth && (newValue as number) > new Date().getFullYear() - report.yearOfBirth - 14) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedGreater14'));
            return false;
          } else if ((newValue as number) < 0) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedLower0'));
            return false;
          } else {
            setErrorField(null);
            return true;
          }
        }
      } else {
        if (report.dateOfBirth) {
          if ((newValue as number) > new Date().getFullYear() - new Date(report.dateOfBirth).getFullYear() - 16) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedGreater16'));
            return false;
          } else if ((newValue as number) < 0) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedLower0'));
            return false;
          } else {
            setErrorField(null);
            return true;
          }
        } else {
          if (report.yearOfBirth && (newValue as number) > new Date().getFullYear() - report.yearOfBirth - 16) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedGreater16'));
            return false;
          } else if ((newValue as number) < 0) {
            setErrorField(t('report.dataCorrection.errors.yearsLicensedLower0'));
            return false;
          } else {
            setErrorField(null);
            return true;
          }
        }
      }
    } else if (fieldName === 'dateOfBirth') {
      if (report.dlnJurisdiction.code === 'AB') {
        if (newValue === null || isNaN(new Date(newValue as string).valueOf())) {
          setErrorField(t('report.dataCorrection.errors.invalidDate'));
          return false;
        } else if (isAfter(new Date(newValue as string), subYears(new Date(), 14))) {
          setErrorField(t('report.dataCorrection.errors.dateOfBirth'));
          return false;
        } else if (isBefore(new Date(newValue as string), subYears(new Date(), 14))) {
          setErrorField(null);
          return true;
        } else {
          return false;
        }
      } else {
        if (newValue === null || isNaN(new Date(newValue as string).valueOf())) {
          setErrorField(t('report.dataCorrection.errors.invalidDate'));
          return false;
        } else if (isAfter(new Date(newValue as string), subYears(new Date(), 16))) {
          setErrorField(t('report.dataCorrection.errors.dateOfBirth'));
          return false;
        } else if (isBefore(new Date(newValue as string), subYears(new Date(), 16))) {
          setErrorField(null);
          return true;
        } else {
          return false;
        }
      }
    } else if (fieldName === 'name') {
      if ((newValue as string).trim().length === 0) {
        setErrorField(t('report.dataCorrection.errors.fullNameEmpty'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else if (fieldName === 'address') {
      if ((newValue as string).trim().length === 0) {
        setErrorField(t('report.dataCorrection.errors.addressEmpty'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else if (fieldName === 'gender') {
      if (newValueAutocompGender === null) {
        setErrorField(t('report.dataCorrection.errors.genderEmpty'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else if (fieldName === 'maritalStatus') {
      if (newValueAutocompMarital === null) {
        setErrorField(t('report.dataCorrection.errors.maritalStatusEmpty'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else if (fieldName === 'training') {
      if (newValueAutocompTraining === null) {
        setErrorField(t('report.dataCorrection.errors.driverTrainingEmpty'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else if (fieldName === 'gridRating') {
      if (isNaN(new Date(newValue as string).valueOf())) {
        setErrorField(t('report.dataCorrection.errors.albertaGridRatingDate'));
        return false;
      }

      const gridRatingDte = new Date(newValue as string);
      const firstDte = new Date(1997, 9, 1);
      const tday = new Date();
      if (
        isAfter(gridRatingDte, tday) ||
        isBefore(gridRatingDte, firstDte) //between today and 1997, Oct, 1st
      ) {
        setErrorField(t('report.dataCorrection.errors.albertaGridRatingDate'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else {
      return true;
    }
  }, [
    fieldName,
    report.numberOfYearsClaimsFree,
    report.dlnJurisdiction.code,
    report.dateOfBirth,
    report.yearOfBirth,
    newValue,
    t,
    newValueAutocompGender,
    newValueAutocompMarital,
    newValueAutocompTraining,
  ]);

  const handleSave = () => {
    if (validateValue()) {
      const params: OperatorCorrectionType = {
        dateOfBirth:
          fieldName === 'dateOfBirth'
            ? formatDateToLocalTime(newValue as string, false)
            : report.dateOfBirth
            ? formatDateToLocalTime(report.dateOfBirth, false)
            : null,
        driverTrainingId: newValueAutocompTraining ? newValueAutocompTraining.id : 0,
        fullAddress: fieldName === 'address' ? (newValue as string).trim() : report.fullAddress,
        fullName: fieldName === 'name' ? (newValue as string).trim() : report.fullName,
        genderId: newValueAutocompGender ? newValueAutocompGender.id : 0,
        gridRatingDate:
          fieldName === 'gridRating'
            ? formatDateToLocalTime(newValue as string, false)
            : report.albertaGridRatingDate
            ? formatDateToLocalTime(report.albertaGridRatingDate, false)
            : null,
        maritalStatusId: newValueAutocompMarital ? newValueAutocompMarital.id : 0,
        operatorKey: report.driverId,
        reportId: report.id,
        yearLicensed: fieldName === 'yearsLicensed' ? Number(newValue as string) : report.yearsLicensed,
      };

      setLoading(true);
      DataCorrection.updateDriverInfo(params)
        .then(async (result) => {
          dispatch(setUpdatedReport(result));
          dispatch(addNotification(200, 'error', t('report.dataCorrection.success200')));
          setLoading(false);
          onClose();

          history.replace(`/reports/${result.id}`, { ...state });
        })
        .catch((error) => {
          handleError(error);
          setLoading(false);
        });
    }
  };

  const handleAutoCompChange = (value: ReferenceType | boolean | null, field: 'gender' | 'marital' | 'training') => {
    if (field === 'gender') {
      setNewValueAutocompGender(value as ReferenceType);
    } else if (field === 'marital') {
      setNewValueAutocompMarital(value as ReferenceType);
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
      >{`${title}`}</Typography>
      <DialogContent sx={{ padding: theme.spacing(4) }}>
        <Grid container>
          {fieldName === 'address' || fieldName === 'yearsLicensed' ? (
            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
              {fieldName === 'yearsLicensed' ? (
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  value={newValue}
                  color="secondary"
                  sx={{ paddingRight: '0' }}
                  type="number"
                  // inputProps={{ pattern: '[0-9]*' }}
                  InputProps={{ inputProps: { min: 0, max: 99 } }}
                  onChange={(e) => {
                    setNewValue(e.target.value);
                  }}
                  onKeyDown={(evt) =>
                    (evt.key === 'e' || evt.key === '.' || evt.key === '+' || evt.key === '-') && evt.preventDefault()
                  }
                  error={!!errorField}
                  helperText={errorField}
                  data-testid="numberTextField"
                />
              ) : (
                <TextField
                  fullWidth
                  size="small"
                  margin="none"
                  value={newValue}
                  color="secondary"
                  sx={{ paddingRight: '0' }}
                  onChange={(e) => {
                    setNewValue(e.target.value);
                  }}
                  error={!!errorField}
                  helperText={errorField}
                  data-testid="normalTextField"
                  placeholder={t('report.summary.fullAddress')}
                />
              )}
            </Grid>
          ) : fieldName === 'name' ? (
            <Grid container alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
              <Grid item xs={12}>
                <TextField
                  value={newValue}
                  fullWidth
                  label={undefined}
                  size="small"
                  margin="none"
                  variant="outlined"
                  autoComplete="nope"
                  color="secondary"
                  sx={{ paddingRight: 0 }}
                  data-testid="firstName"
                  name="firstName"
                  placeholder={t('report.summary.fullName')}
                  onChange={(e) => {
                    setNewValue(e.target.value);
                  }}
                  error={!!errorField}
                  helperText={errorField}
                />
              </Grid>
            </Grid>
          ) : fieldName === 'dateOfBirth' || fieldName === 'gridRating' ? (
            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={i18n.language.startsWith('en') ? enLocale : frLocale}
              >
                <DatePicker
                  clearable
                  disableFuture
                  mask={DATE_MASK}
                  inputFormat={DATE_FORMAT}
                  value={newValue as string}
                  onChange={(newValue) => {
                    setNewValue(newValue);
                  }}
                  renderInput={(params) => (
                    <DashCustomInput
                      {...params}
                      fullWidth
                      label={undefined}
                      fieldGridSize={12}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      variant="outlined"
                      color="secondary"
                      error={!!errorField}
                      helperText={errorField}
                      data-testid="datePickerDialog"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          ) : fieldName === 'gender' ? (
            <Autocomplete
              fullWidth
              value={newValueAutocompGender}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              getOptionLabel={(option) =>
                i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
              }
              id="gender"
              options={genderOptions ?? []}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  fieldGridSize={12}
                  variant="outlined"
                  color="secondary"
                  data-testid="genderAutocomplete"
                  error={!!errorField}
                  helperText={errorField}
                />
              )}
              onChange={(_, value) => {
                handleAutoCompChange(value, 'gender');
              }}
              noOptionsText={t('search.noOption')}
              loading={loading}
            />
          ) : fieldName === 'maritalStatus' ? (
            <Autocomplete
              fullWidth
              value={newValueAutocompMarital}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              getOptionLabel={(option) =>
                i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
              }
              id="maritalStatus"
              options={maritalOptions ?? []}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  fieldGridSize={12}
                  variant="outlined"
                  color="secondary"
                  error={!!errorField}
                  helperText={errorField}
                />
              )}
              onChange={(_, value) => {
                handleAutoCompChange(value, 'marital');
              }}
              noOptionsText={t('search.noOption')}
              loading={loading}
            />
          ) : (
            fieldName === 'training' && (
              <Autocomplete
                fullWidth
                value={newValueAutocompTraining}
                getOptionLabel={(option) =>
                  i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
                }
                id="training"
                options={trainingOptions ?? []}
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    fieldGridSize={12}
                    variant="outlined"
                    color="secondary"
                    error={!!errorField}
                    helperText={errorField}
                  />
                )}
                onChange={(_, value) => {
                  setNewValueAutocompTraining(value);
                }}
                noOptionsText={t('search.noOption')}
              />
            )
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
          <Grid item xs={4}>
            <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={() => onClose()}>
              {t('dialog.cancel')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              data-testid="accept"
              variant="contained"
              color="primary"
              onClick={handleSave}
              //disabled={loading || !newValueAutocompGender || !newValueAutocompMarital}
              disabled={
                loading ||
                ((fieldName === 'name' || fieldName === 'address' || fieldName === 'yearsLicensed') &&
                  (!newValue || newValue === `${value}`)) ||
                ((fieldName === 'dateOfBirth' || fieldName === 'gridRating') &&
                  (!newValue || isSameDate(new Date(newValue as string), new Date(value as string)))) ||
                (fieldName === 'gender' &&
                  (!newValueAutocompGender || newValueAutocompGender?.code === report.gender?.code)) ||
                (fieldName === 'maritalStatus' &&
                  (!newValueAutocompMarital || newValueAutocompMarital?.code === report.maritalStatus?.code)) ||
                (fieldName === 'training' &&
                  (!newValueAutocompTraining || newValueAutocompTraining?.code === report.driverTraining?.code))
              }
            >
              {t('dialog.save')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {loading && <Loader open={true} />}
    </>
  );
};

export default EditDialogDriver;
