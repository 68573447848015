import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import theme from '../theme';

type HelperTextProps = {
  message: string;
  testid?: string;
};

const HelperText: FC<HelperTextProps> = ({ message, testid }) => {
  const { t } = useTranslation();
  return (
    <Typography
      sx={{
        color: '#F44336',
        fontSize: '0.75rem',
        fontWeight: '400',
        marginTop: theme.spacing(0.5),
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
      }}
      data-testid={testid ?? undefined}
    >
      {t(message)}
    </Typography>
  );
};

export default HelperText;
