/* eslint-disable no-alert */
import { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import authService from './AuthorizeService';

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
    };
  }
  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    if (!ready) {
      return <div>Waiting for authentication...</div>;
    } else {
      const { component: Component, user: User, ...rest } = this.props;
      return (
        <Route
          {...rest}
          render={(props) => {
            if (authenticated) {
              return <Component {...props} user={User} />;
            } else {
              var link = document.createElement('a');
              link.href = this.props.path;
              //const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
              //const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
              return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
            }
          }}
        />
      );
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
