import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ApplicationPaths } from '../Features/Authorization/AuthorizationConstants';
import { sortAppFunctions } from '../Helpers/SortHelpers';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
import dashLogoEn from '../Medias/DASH_LOGO.png';
import dashLogoFr from '../Medias/DASH_LOGO_FR.png';
import { GetAllAppFunctions } from '../Slices/FunctionSlice';
import { clearStore } from '../Slices/NotificationSlice';
import theme from '../theme';
import { AppFunction, Functions, ReactLocationState, User } from '../Types';
import ChangePasswordMenu from './ChangePasswordMenu';
import LanguageToggle from './LanguageToggle';
import MenuCard from './MenuCard';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100px',
    '@media print': {
      display: 'none',
    },
  },
  welcomeButton: {
    padding: 0,
  },
  drawer: {
    background: theme.palette.primary.dark,
    width: '350px',
  },
  boldText: {
    fontWeight: 500,
    '&:disabled': {
      color: theme.palette.primary.main,
    },
    padding: 0,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(4),
  },
}));

interface NavBarProps {
  user: User | undefined;
  history: any;
}

const NavBar: FC<NavBarProps> = ({ user, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { state } = useLocation<ReactLocationState>();
  const isEnglish = i18n.language.startsWith('en');

  const appFunctions = useSelector((state: { appFunction: any }) => GetAllAppFunctions(state));

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleSignoutClick = () => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "route": "' + `${ApplicationPaths.LogOut}` + '" }');
    } else {
      history.push({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
      dispatch(clearStore());
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const renderMenuCard = (oneFunc: AppFunction, user: User) => {
    switch (oneFunc.id) {
      case Functions.driverReport:
        return (
          doUserHaveAccessToFeature(user, Functions.driverReport) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.driverReport')}
                icon="driverReport"
                route="/driver-report"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.vehicleReport:
        return (
          doUserHaveAccessToFeature(user, Functions.vehicleReport) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.vehicleReport')}
                icon="vehicleReport"
                route="/vehicle-report"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.reportHistory:
        return (
          doUserHaveAccessToFeature(user, Functions.reportHistory) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.reportHistory')}
                icon="reportHistory"
                route="/report-history"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.ninetyDaysReport:
        return (
          doUserHaveAccessToFeature(user, Functions.ninetyDaysReport) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.90days')}
                icon="ninetyDays"
                route="/ninetydays-report"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.dataCorrection:
        return undefined;
      case Functions.userAccessReport:
        return (
          doUserHaveAccessToFeature(user, Functions.userAccessReport) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.accessReport')}
                icon="accessReport"
                route="/user-access-report"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.companyAdministration:
        return (
          doUserHaveAccessToFeature(user, Functions.companyAdministration) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.companyAdmin')}
                icon="companyProfile"
                route="/company-profile"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.userAdministration:
        return (
          doUserHaveAccessToFeature(user, Functions.userAdministration) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.userAdmin')}
                icon="userAdmin"
                route="/user-administration"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.usageReport:
        return (
          doUserHaveAccessToFeature(user, Functions.usageReport) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.usageReport')}
                icon="usageReport"
                route="/usage-report"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.manageSystemFunction:
        return (
          doUserHaveAccessToFeature(user, Functions.manageSystemFunction) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.settings')}
                icon="settings"
                route="/settings"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.manageDashClients:
        return (
          doUserHaveAccessToFeature(user, Functions.manageDashClients) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.dashclient')}
                icon="dashClients"
                route="/dash-client"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.correctionReport:
        return (
          doUserHaveAccessToFeature(user, Functions.correctionReport) && (
            <Grid item xs={12}>
              <MenuCard
                title={t('hamburgerMenu.correctionReport')}
                icon="correctionReport"
                route="/correction-report"
                buttonClicked={toggleDrawer}
              />
            </Grid>
          )
        );
      case Functions.sdcReport:
        return (
          doUserHaveAccessToFeature(user, Functions.sdcReport) && (
            <Grid item xs={12}>
              <MenuCard title={t('hamburgerMenu.sdc')} icon="sdc" route="/sdc" buttonClicked={toggleDrawer} />
            </Grid>
          )
        );
    }
  };

  return (
    <Grid container className={classes.root} paddingLeft={'5px'} paddingRight={'22px'}>
      <Grid item container xs={5} alignItems="center">
        {user && (
          <Grid item>
            <Button
              aria-label="menu"
              data-testid="openMenuButton"
              onClick={toggleDrawer}
              className={classes.welcomeButton}
              color="secondary"
            >
              <MenuIcon sx={{ color: theme.palette.secondary.main, fontSize: '30pt' }} fontSize="inherit" />
            </Button>
          </Grid>
        )}
        <img
          src={isEnglish ? dashLogoEn : dashLogoFr}
          alt="logoDASH"
          height={70}
          style={{ paddingLeft: theme.spacing(2), cursor: 'pointer' }}
          onClick={() => history.push('/', state)}
        />
      </Grid>
      <Grid item container xs={7} justifyContent="flex-end" alignItems="center" spacing={2}>
        {user && (
          <>
            {user.isIbcCredentials && !location.href.includes('change-password') && (
              <Grid item>
                <ChangePasswordMenu />
              </Grid>
            )}
            <Grid item>
              <LanguageToggle />
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="large"
                sx={{ marginRight: '16px' }}
                color="secondary"
                onClick={() => handleSignoutClick()}
                data-testid="signOutButton"
                className={classes.boldText}
              >
                {t('header.signout')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      {/* user && user.isLastTermsOfServiceAccepted && */}
      {user && (
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawer }}
          open={isDrawerOpen}
          onClose={toggleDrawer}
          transitionDuration={150}
        >
          <Grid item container paddingTop={'35px'} alignItems="flex-start" xs={12} data-testid="drawerGrid">
            <Grid item container xs={12} alignItems="flex-start">
              <Grid item xs={12}>
                <MenuCard title={t('menu.home')} icon="home" route="/" buttonClicked={toggleDrawer} />
              </Grid>
              {appFunctions
                .sort((a: AppFunction, b: AppFunction) => sortAppFunctions(a, b))
                .map((oneFunc: AppFunction) => (
                  <div key={oneFunc.id}>{renderMenuCard(oneFunc, user)}</div>
                ))}
            </Grid>
          </Grid>
        </Drawer>
      )}
    </Grid>
  );
};

export default NavBar;
