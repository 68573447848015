export interface Notification {
  message: string;
  code: number;
  severity: 'info' | 'success' | 'warning' | 'error';
}

export const NotificationFactory = (
  code: number,
  severity: 'info' | 'success' | 'warning' | 'error',
  message: string
): Notification => {
  return {
    code,
    severity,
    message,
  };
};

export default Notification;
