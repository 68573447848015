import axios from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addNotification } from '../Slices/NotificationSlice';

export interface ErrorHandlerConfig {
  error400?: string;
  error401?: string;
  error403?: string;
  error404?: string;
  error409?: string;
  error429?: string;
  error405?: string;
  error500?: string;
}

const useErrorHandler = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleError = useCallback(
    (error: any, config?: ErrorHandlerConfig) => {
      if (!axios.isCancel(error)) {
        if (error.message.includes('400')) {
          dispatch(addNotification(400, 'error', config?.error400 ? config.error400 : t('errorMessage.error400')));
        } else if (error.message.includes('401')) {
          dispatch(addNotification(401, 'error', config?.error401 ? config.error401 : t('errorMessage.error401')));
        } else if (error.message.includes('404')) {
          dispatch(addNotification(404, 'error', config?.error404 ? config.error404 : t('errorMessage.error404')));
        } else if (error.message.includes('409')) {
          dispatch(addNotification(409, 'error', config?.error409 ? config.error409 : t('errorMessage.error409')));
        } else if (error.message.includes('429')) {
          dispatch(addNotification(429, 'error', config?.error429 ? config.error429 : t('errorMessage.error429')));
        } else if (error.message.includes('405')) {
          dispatch(addNotification(405, 'error', config?.error405 ? config.error405 : t('errorMessage.error405')));
        }
        // else if (typeof error.response.data == 'string') {
        //   dispatch(addNotification(400, 'error', error.response.data));
        // }
        else {
          dispatch(addNotification(500, 'error', config?.error500 ? config.error500 : t('errorMessage.error500')));
        }
        console.error(error);
      } else if (error.message.includes('403') && config?.error403) {
        dispatch(addNotification(403, 'error', config.error403));
      }
    },
    [dispatch, t]
  );
  return handleError;
};

export default useErrorHandler;
