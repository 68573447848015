import { requests } from '../axios';
import { ClientSetting, ClientSettingFactory, OrganizationFactory } from '../Types';

export const createClientSetting = async (params: {
  clientId: string;
  organizationId: number;
  reportingCompanyId: number | null;
  userId: number | null;
}): Promise<ClientSetting> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ClientSetting>((resolve) => {
      resolve(
        ClientSettingFactory({
          id: 1,
          clientId: params.clientId,
          disabled: false,
          organization: OrganizationFactory(params.organizationId, 'IBC', 'test', 'test', '0000'),
          reportingCompany: null,
          user: null,
          isVendor: false,
        })
      );
    });
  }
  return requests.post('/B2CClientOrganizations', params);
};

export const upateClientSetting = async (
  id: number,
  params: {
    clientId: string;
    organizationId: number;
    reportingCompanyId: number | null;
    userId: number | null;
  }
): Promise<ClientSetting> => {
  return requests.put(`/B2CClientOrganizations/${id}`, params);
};

export const deleteClientSetting = async (id: number) => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ClientSetting>((resolve) => {
      resolve(
        ClientSettingFactory({
          id: id,
          clientId: '11111',
          disabled: true,
          organization: OrganizationFactory(1, 'IBC', 'test', 'test', '0000'),
          reportingCompany: null,
          user: null,
          isVendor: false,
        })
      );
    });
  }
  return requests.delete(`/B2CClientOrganizations/${id}`);
};

export const listClientSetting = async (): Promise<Array<ClientSetting>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<ClientSetting>>((resolve) => {
      resolve([
        ClientSettingFactory({
          id: 1,
          clientId: '111111',
          disabled: false,
          organization: OrganizationFactory(1, 'IBC', 'IBC EN', 'IBC FR', '0000'),
          reportingCompany: null,
          user: null,
          isVendor: false,
        }),
        ClientSettingFactory({
          id: 2,
          clientId: '222222',
          disabled: false,
          organization: OrganizationFactory(1, 'Carrier', 'Carrier EN', 'Carrier FR', '1111'),
          reportingCompany: null,
          user: null,
          isVendor: false,
        }),
      ]);
    });
  } else {
    return requests.get('/B2CClientOrganizations/', {});
  }
};

export const restoreClientSetting = async (id: number) => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ClientSetting>((resolve) => {
      resolve(
        ClientSettingFactory({
          id: id,
          clientId: '11111',
          disabled: false,
          organization: OrganizationFactory(1, 'IBC', 'test', 'test', '0000'),
          reportingCompany: null,
          user: null,
          isVendor: false,
        })
      );
    });
  }
  return requests.patch(`/B2CClientOrganizations/restore/${id}`, {});
};
