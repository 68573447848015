export interface PolicyStatus {
  statusCode: string;
  descriptionEn: string | null;
  descriptionFr: string | null;
}

export const PolicyStatusFactory = (props: PolicyStatus): PolicyStatus => {
  return {
    statusCode: props.statusCode,
    descriptionEn: props.descriptionEn,
    descriptionFr: props.descriptionFr,
  };
};

export default PolicyStatus;
