const dependencies = [
  {
    name: 'AspNetCore.HealthChecks.AzureStorage',
    version: '6.0.4',
    repository: 'https://github.com/Xabaril/AspNetCore.Diagnostics.HealthChecks',
    license: 'Apache-2.0',
  },
  {
    name: 'AspNetCore.HealthChecks.SqlServer',
    version: '6.0.2',
    repository: 'https://github.com/Xabaril/AspNetCore.Diagnostics.HealthChecks',
    license: 'Apache-2.0',
  },
  {
    name: 'AspNetCore.HealthChecks.System',
    version: '5.0.1',
    repository: 'http://github.com/xabaril/AspNetCore.Diagnostics.HealthChecks',
    license: 'Apache-2.0',
  },
  {
    name: 'AspNetCore.HealthChecks.UI.Client',
    version: '6.0.1-rc2.4',
    repository: 'https://github.com/Xabaril/AspNetCore.Diagnostics.HealthChecks',
    license: 'Apache-2.0',
  },
  {
    name: 'AspNetCoreRateLimit',
    version: '4.0.1',
    repository: 'https://github.com/stefanprodan/AspNetCoreRateLimit',
    license: '',
  },
  {
    name: 'Aspose.PDF',
    version: '22.1.0',
    repository: 'https://products.aspose.com/pdf/net',
    license: 'license\\aspose_end-user-license-agreement.txt',
  },
  {
    name: 'AutoMapper.Extensions.Microsoft.DependencyInjection',
    version: '8.1.1',
    repository: 'https://automapper.org/',
    license: 'MIT',
  },
  {
    name: 'Azure.Extensions.AspNetCore.Configuration.Secrets',
    version: '1.2.1',
    repository:
      'https://github.com/Azure/azure-sdk-for-net/blob/Azure.Extensions.AspNetCore.Configuration.Secrets_1.2.1/sdk/extensions/Azure.Extensions.AspNetCore.Configuration.Secrets/README.md',
    license: 'MIT',
  },
  {
    name: 'Azure.Identity',
    version: '1.5.0',
    repository:
      'https://github.com/Azure/azure-sdk-for-net/blob/Azure.Identity_1.5.0/sdk/identity/Azure.Identity/README.md',
    license: 'MIT',
  },
  {
    name: 'Azure.Security.KeyVault.Secrets',
    version: '4.1.0',
    repository:
      'https://github.com/Azure/azure-sdk-for-net/blob/2d11c6664e68c145a988729e6598449bba130694/sdk/keyvault/Azure.Security.KeyVault.Secrets/README.md',
    license: 'MIT',
  },
  {
    name: 'BenchmarkDotNet',
    version: '0.13.2',
    repository: 'https://github.com/dotnet/BenchmarkDotNet',
    license: 'MIT',
  },
  {
    name: 'coverlet.collector',
    version: '3.1.0',
    repository: 'https://github.com/coverlet-coverage/coverlet',
    license: 'MIT',
  },
  {
    name: 'EFCore.BulkExtensions',
    version: '6.0.0',
    repository: 'https://github.com/borisdj/EFCore.BulkExtensions',
    license: 'MIT',
  },
  {
    name: 'Hangfire.AspNetCore',
    version: '1.7.24',
    repository: 'https://www.hangfire.io/',
    license: '',
  },
  {
    name: 'Hangfire.Core',
    version: '1.7.24',
    repository: 'https://www.hangfire.io/',
    license: '',
  },
  {
    name: 'Hangfire.InMemory',
    version: '0.3.4',
    repository: 'https://www.hangfire.io/',
    license: '',
  },
  {
    name: 'Hangfire.SqlServer',
    version: '1.7.24',
    repository: 'https://www.hangfire.io/',
    license: '',
  },
  {
    name: 'MailKit',
    version: '2.14.0',
    repository: 'http://www.mimekit.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.ApplicationInsights.AspNetCore',
    version: '2.18.0',
    repository: 'https://go.microsoft.com/fwlink/?LinkId=392727',
    license: 'MIT',
  },
  {
    name: 'Microsoft.ApplicationInsights.Kubernetes',
    version: '2.0.1',
    repository: 'https://github.com/Microsoft/ApplicationInsights-Kubernetes/',
    license: 'LICENSE',
  },
  {
    name: 'Microsoft.AspNetCore.Authentication.JwtBearer',
    version: '6.0.0',
    repository: 'https://asp.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.AspNetCore.Authentication.OpenIdConnect',
    version: '6.0.6',
    repository: 'https://asp.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.AspNetCore.DataProtection.EntityFrameworkCore',
    version: '6.0.0',
    repository: 'https://asp.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.AspNetCore.Identity.EntityFrameworkCore',
    version: '6.0.0',
    repository: 'https://asp.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.AspNetCore.Mvc.Abstractions',
    version: '2.2.0',
    repository: 'https://asp.net/',
    license: '',
  },
  {
    name: 'Microsoft.AspNetCore.Mvc.Versioning',
    version: '5.0.0',
    repository: 'https://github.com/Microsoft/aspnet-api-versioning/wiki',
    license: 'MIT',
  },
  {
    name: 'Microsoft.AspNetCore.Mvc.Versioning.ApiExplorer',
    version: '5.0.0',
    repository: 'https://github.com/Microsoft/aspnet-api-versioning/wiki',
    license: 'MIT',
  },
  {
    name: 'Microsoft.EntityFrameworkCore',
    version: '6.0.0',
    repository: 'https://docs.microsoft.com/ef/core/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.EntityFrameworkCore.Design',
    version: '6.0.0',
    repository: 'https://docs.microsoft.com/ef/core/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.EntityFrameworkCore.InMemory',
    version: '6.0.0',
    repository: 'https://docs.microsoft.com/ef/core/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.EntityFrameworkCore.SqlServer',
    version: '6.0.0',
    repository: 'https://docs.microsoft.com/ef/core/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.Configuration',
    version: '6.0.0',
    repository: 'https://dot.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.DependencyInjection',
    version: '6.0.0',
    repository: 'https://dot.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.Diagnostics.HealthChecks',
    version: '6.0.5',
    repository: 'https://asp.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.Diagnostics.HealthChecks.EntityFrameworkCore',
    version: '6.0.0',
    repository: 'https://asp.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.Hosting',
    version: '5.0.0',
    repository: 'https://github.com/dotnet/runtime',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.Localization',
    version: '5.0.14',
    repository: 'https://asp.net/',
    license: 'Apache-2.0',
  },
  {
    name: 'Microsoft.Extensions.Logging',
    version: '6.0.0',
    repository: 'https://dot.net/',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Extensions.Logging.ApplicationInsights',
    version: '2.18.0',
    repository: 'https://go.microsoft.com/fwlink/?LinkId=392727',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Graph',
    version: '4.22.0',
    repository: 'https://developer.microsoft.com/graph',
    license: 'LICENSE.txt',
  },
  {
    name: 'Microsoft.Identity.Web',
    version: '1.25.0',
    repository: 'https://github.com/AzureAD/microsoft-identity-web',
    license: 'MIT',
  },
  {
    name: 'Microsoft.Identity.Web.UI',
    version: '1.25.0',
    repository: 'https://github.com/AzureAD/microsoft-identity-web',
    license: 'MIT',
  },
  {
    name: 'Microsoft.NET.Test.Sdk',
    version: '16.10.0',
    repository: 'https://github.com/microsoft/vstest/',
    license: 'LICENSE_NET.txt',
  },
  {
    name: 'OpenIddict.EntityFrameworkCore',
    version: '3.1.0',
    repository: 'https://github.com/openiddict/openiddict-core',
    license: 'Apache-2.0',
  },
  {
    name: 'SendGrid',
    version: '9.24.1',
    repository: 'https://sendgrid.com/',
    license: 'MIT',
  },
  {
    name: 'Serilog.AspNetCore',
    version: '4.1.0',
    repository: 'https://github.com/serilog/serilog-aspnetcore',
    license: 'Apache-2.0',
  },
  {
    name: 'Serilog.Sinks.MSSqlServer',
    version: '5.6.0',
    repository: 'https://github.com/serilog/serilog-sinks-mssqlserver',
    license: 'Apache-2.0',
  },
  {
    name: 'Swashbuckle.AspNetCore',
    version: '6.2.2',
    repository: 'https://github.com/domaindrivendev/Swashbuckle.AspNetCore',
    license: 'MIT',
  },
  {
    name: 'System.CommandLine',
    version: '2.0.0-beta1.21308.1',
    repository: 'https://github.com/dotnet/command-line-api',
    license: 'MIT',
  },
  {
    name: 'TimeZoneNames',
    version: '5.0.1',
    repository: 'https://github.com/mattjohnsonpint/TimeZoneNames',
    license: 'MIT',
  },
  {
    name: 'WindowsAzure.Storage',
    version: '9.3.3',
    repository: 'https://github.com/Azure/azure-storage-net',
    license: '',
  },
  {
    name: 'xunit',
    version: '2.4.1',
    repository: 'https://github.com/xunit/xunit',
    license: '',
  },
  {
    name: 'xunit.runner.visualstudio',
    version: '2.4.3',
    repository: 'https://github.com/xunit/visualstudio.xunit',
    license: 'MIT',
  },
];
export default dependencies;
