import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Grid } from '@mui/material';

import JoditEditor from 'jodit-react';

import { ContactUs } from '../../../axios';
import { getJoditEditorConfig } from '../../../Helpers/JoditEditorHelper';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { BlobDto } from '../../../Types';

export interface ContactUsEditorProps {
  placeholder: string;
}

enum ContactUsTabs {
  ContactUsEnglish = 'contactUsEnglish',
  ContactUsFrench = 'contactUsFrench',
}

const ContactUsEditor: FC<ContactUsEditorProps> = ({ placeholder }) => {
  const editor = useRef(null);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const [selectedTab, setSelectedTab] = useState<ContactUsTabs>(ContactUsTabs.ContactUsEnglish);
  const [contactUsEn, setContactUsEn] = useState('');
  const [contactUsFr, setContactUsFr] = useState('');

  const config = { ...getJoditEditorConfig(), placeholder };

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      ContactUs.getContactUs('en')
        .then((data: BlobDto) => {
          setContactUsEn(decodeURIComponent(data.blobHtml));
        })
        .catch((error) => {
          // if there is an error here then it's probably that the ContactUs hasn't been set yet. don't display error message.
          setContactUsEn('');
          handleError(error);
        });
      ContactUs.getContactUs('fr')
        .then((data: BlobDto) => {
          setContactUsFr(decodeURIComponent(data.blobHtml));
        })
        .catch((error) => {
          // if there is an error here then it's probably that the ContactUs hasn't been set yet. don't display error message.
          setContactUsFr('');
          handleError(error);
        });
    };

    onLoadFromBlobStorage();
  }, [dispatch, handleError, i18n.language, t]);

  const onEditorChangeEn = (newContent: string) => {
    setContactUsEn(newContent);
  };

  const onEditorChangeFr = (newContent: string) => {
    setContactUsFr(newContent);
  };

  const onSaveToBlobStorage = () => {
    ContactUs.updateContactUs('en', { blobHtml: encodeURIComponent(contactUsEn) })
      .then(() => {
        dispatch(addNotification(200, 'success', t('successMessage.savedChanges')));
      })
      .catch((error) => {
        handleError(error);
      });
    ContactUs.updateContactUs('fr', { blobHtml: encodeURIComponent(contactUsFr) })
      .then(() => {
        dispatch(addNotification(200, 'success', t('successMessage.savedChanges')));
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 0, paddingLeft: 3 }}>
              <Grid item container xs={3} md={3} lg={2} xl={2} spacing={1} justifyContent={'flex-start'}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setSelectedTab(ContactUsTabs.ContactUsEnglish)}
                    variant={'outlined'}
                    color={selectedTab === ContactUsTabs.ContactUsEnglish ? 'primary' : 'secondary'}
                    sx={{
                      borderWidth: '1px',
                      boxShadow:
                        selectedTab === ContactUsTabs.ContactUsEnglish
                          ? `0px 0px 1px 1px ${theme.palette.primary.main}`
                          : 'none',
                      backgroundColor: theme.palette.white.main,
                    }}
                  >
                    {t('settings.terms-of-service.english')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setSelectedTab(ContactUsTabs.ContactUsFrench)}
                    variant={'outlined'}
                    color={selectedTab === ContactUsTabs.ContactUsFrench ? 'primary' : 'secondary'}
                    sx={{
                      borderWidth: '1px',
                      boxShadow:
                        selectedTab === ContactUsTabs.ContactUsFrench
                          ? `0px 0px 1px 1px ${theme.palette.primary.main}`
                          : 'none',
                      backgroundColor: theme.palette.white.main,
                    }}
                  >
                    {t('settings.terms-of-service.french')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <TabPanel value={ContactUsTabs.ContactUsEnglish}>
              <JoditEditor
                ref={editor}
                value={contactUsEn}
                config={config}
                onBlur={(newContent) => onEditorChangeEn(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </TabPanel>
            <TabPanel value={ContactUsTabs.ContactUsFrench}>
              <JoditEditor
                ref={editor}
                value={contactUsFr}
                config={config}
                onBlur={(newContent) => onEditorChangeFr(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </TabPanel>
            <Grid item container xs={3} sx={{ marginTop: theme.spacing(2) }} />
            <Grid item container spacing={1} xs={12} justifyContent="flex-end" direction="row" paddingRight={3}>
              <Grid item xs={2}>
                <Button fullWidth onClick={onSaveToBlobStorage} variant="contained" color="primary">
                  {t('settings.contact-us.save')}
                </Button>
              </Grid>
            </Grid>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUsEditor;
