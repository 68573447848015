import { requests } from '../axios';
import NotificationRecipientOption, { NotificationRecipientOptionFactory } from '../Types/NotificationRecipientOption';

export const createNro = (params: {
  email: string;
  organizationId: number;
  reportingCompanyId?: number;
}): Promise<NotificationRecipientOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<NotificationRecipientOption>((resolve) => {
      const { email, organizationId, reportingCompanyId } = params;
      resolve(NotificationRecipientOptionFactory(1, email, organizationId, reportingCompanyId));
    });
  } else {
    return requests.post('/notificationRecipient/create', params);
  }
};

export const disableNro = (id: number): Promise<NotificationRecipientOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<NotificationRecipientOption>((resolve) => {
      resolve(NotificationRecipientOptionFactory(1, 'notificationRecipient A', 1, 1));
    });
  } else {
    return requests.delete(`/notificationRecipient/${id}`);
  }
};

export const getNotificationRecipientOptionsByCompanyId = (
  functionId: number,
  params?: { organizationId: number; reportingCompanyId?: number }
): Promise<NotificationRecipientOption[]> => {
  const { organizationId, reportingCompanyId } = params!;
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<NotificationRecipientOption[]>((resolve) => {
      if (organizationId === 3 && reportingCompanyId === 7) {
        resolve([]);
      } else {
        resolve([
          NotificationRecipientOptionFactory(1, 'NotificationRecipientOption A', 1),
          NotificationRecipientOptionFactory(2, 'NotificationRecipientOption B', 2, 1),
        ]);
      }
    });
  } else {
    return requests.get(
      `/notificationRecipient/getByOrganization/${functionId}/?organizationId=${organizationId}`.concat(
        reportingCompanyId ? `&reportingCompanyId=${reportingCompanyId}` : ''
      ),
      {}
    );
  }
};

export const listNro = (functionId: number): Promise<NotificationRecipientOption[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<NotificationRecipientOption[]>((resolve) => {
      resolve([
        NotificationRecipientOptionFactory(1, 'NotificationRecipientOption A', 1),
        NotificationRecipientOptionFactory(2, 'NotificationRecipientOption B', 2, 1),
      ]);
    });
  } else {
    return requests.get(`/notificationRecipient/get/${functionId}`, {});
  }
};

export const updateNro = (
  id: number,
  params: { email: string; organizationId: number; reportingCompanyId?: number }
): Promise<NotificationRecipientOption> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<NotificationRecipientOption>((resolve) => {
      const { email, organizationId, reportingCompanyId } = params;
      resolve(NotificationRecipientOptionFactory(1, email, organizationId, reportingCompanyId));
    });
  } else {
    return requests.put(`/notificationRecipient/${id}`, params);
  }
};
