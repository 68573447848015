import { CancelToken } from 'axios';

import { requests } from '../axios';
import { RequestorNameSearch, Response, User, UserCopyProfile, UserCopyProfileFactory, UserFactory } from '../Types';
import { ChangeMyPassword } from '../Types/ChangeMyPassword';

export const createUsers = (params: User): Promise<User> => {
  return requests.post('/Users/Create', params);
};

export const findByIdUsers = (id: number): Promise<User> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<User>((resolve) => {
      resolve(
        UserFactory(
          1,
          1,
          'userId',
          'John',
          'Hercules',
          'Doe',
          'test@email.test',
          null,
          'subTest',
          new Date(),
          false,
          false,
          false,
          1,
          2,
          1,
          new Date(),
          [],
          [],
          [],
          true
        )
      );
    });
  } else {
    return requests.get(`/Users/${id}`, {});
  }
};

export const deleteUserAccount = (id: number, hardDelete: boolean | null): Promise<User> => {
  return requests.delete(`Users/${id}?hardDelete=${hardDelete}`);
};

export const findByOrganizationUsers = (
  organizationId: number,
  reportingCompanyId: number | null
): Promise<Array<User>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<User>>((resolve) => {
      // Resolving an empty array because we will never going to test this case
      resolve([]);
    });
  } else {
    return requests.get(
      `/Users/FindByOrganization/${organizationId}`.concat(
        reportingCompanyId ? `/?reportingCompanyId=${reportingCompanyId}` : ''
      ),
      {}
    );
  }
};

export const findUsers = (): Promise<User> => {
  return requests.get('/Users/GetUserConnected', {});
};

export const requestorNameSearch = (request: RequestorNameSearch): Promise<string[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<string[]>((resolve) => {
      resolve(['john doe', 'jane doe']);
    });
  } else {
    return requests.get(
      `/Users/RequestorNameSearch/?name=${request.name}&organizationId=${request.organizationId}&reportingCompanyId=${request.reportingCompanyId}&unitId=${request.unitId}`,
      {}
    );
  }
};

export const listUsers = (): Promise<User[]> => {
  return requests.get('/Users', {});
};

export const markTermsOfUseAccepted = (): Promise<User> => {
  return requests.post('/Users/MarkTermsofUseAsAccepted', {});
};

export const updateUser = (params: User): Promise<User> => {
  return requests.patch('/Users/'.concat(params.id.toString()), params);
};

export const usersProfiles = (workEmail: string, CancelToken?: CancelToken): Promise<Array<UserCopyProfile>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<UserCopyProfile>>((resolve) => {
      resolve([UserCopyProfileFactory(1, 'gerard.bouchard@ibc.com', 'Gérard Bouchard')]);
    });
  } else {
    return requests.get('/Users/GetUsersCopyProfiles/'.concat(workEmail), {}, CancelToken);
  }
};

export const sendInvitationUsers = (email: string): Promise<Response> => {
  return requests.post(`Users/Invite/${email}`, {});
};

export const changeMyPassword = (params: ChangeMyPassword): Promise<Response> => {
  return requests.post(`Users/ChangeMyPassword`, params);
};
