import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createDOMPurify from 'dompurify';

import { PrivacyPolicies } from '../axios';
import { BlobDto } from '../Types';

export const GetPrivacyPolicyContent = (): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const DOMPurify = createDOMPurify(window);

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      PrivacyPolicies.getPrivacyPolicy(i18n.language.substring(0, 2))
        .then((data: BlobDto) => {
          setPrivacyPolicy(data.blobHtml);
        })
        .catch(() => {
          // if there is an error here then it's probably that the PrivacyPolicies hasn't been set yet. don't display error message.
          setPrivacyPolicy('');
        });
    };

    onLoadFromBlobStorage();
  }, [i18n.language, t]);

  const sanitizedPrivacyPolicy = DOMPurify.sanitize(privacyPolicy);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: sanitizedPrivacyPolicy }} />
    </>
  );
};
