import { subMonths } from 'date-fns';

import { requests } from '../axios';
import {
  ClaimKindOfLossesFactory,
  ClaimStatusFactory,
  OrganizationFactory,
  PolicyStatusFactory,
  UserFunctionCompanyFactory,
  VehicleClaimFactory,
  VehicleClassFactory,
  VehicleLossFactory,
  VehiclePolicyFactory,
  VehicleReport,
  VehicleReportFactory,
  VehicleUseFactory,
} from '../Types';

const claimKindofLosses = [
  ClaimKindOfLossesFactory(
    '16',
    'Other propert damaged to the insured vehicle',
    'Autre dommage de propriété sur le véhicule assuré',
    10000000,
    200,
    false,
    '20/06'
  ),
  ClaimKindOfLossesFactory(null, 'Loss of Use', "Perte d'utilisation", 100, 200, false, '20/06'),
];

const claim = VehicleClaimFactory({
  claimKindOfLosses: claimKindofLosses,
  claimNumber: 'SRT-666',
  dateOfLoss: subMonths(new Date(), 2),
  insurer: OrganizationFactory(1, 'Carrier', 'Insurance CAA', 'Assurance CAA', 'code'),
  policyNumber: 'POLICY-42',
  status: ClaimStatusFactory({ code: 'O', descriptionEn: 'Open', descriptionFr: 'Ouvert' }),
  totalExpense: 549000.0,
  totalLoss: 1245890.15,
  vehicleLoss: VehicleLossFactory({ code: 'P', descriptionEn: 'Partial', descriptionFr: 'Perte partielle' }),
});

const policy = VehiclePolicyFactory({
  annualDrivingDistance: '42000',
  businessUsePercent: '70%',
  carrier: OrganizationFactory(1, 'Carrier', 'Insurance CAA', 'Assurance CAA', 'code'),
  commutingDistance: '69',
  coveragePeriod: '2022-05-31 - 2022-04-11',
  policyNumber: 'DRT-56984',
  policyStatus: PolicyStatusFactory({ descriptionEn: 'Active', descriptionFr: 'Activée', statusCode: '1' }),
  startDate: subMonths(new Date(), 3),
  stopDate: new Date(),
  vehicleClass: VehicleClassFactory({ descriptionEn: 'En class', descriptionFr: 'Fr class', typeOfUseCode: '01' }),
  vehicleUse: VehicleUseFactory({
    vehicleUseCode: '01',
    vehicleUseDescriptionEn: 'DescriptionEn',
    vehicleUseDescriptionFr: 'DescriptionFr',
  }),
});

/**
 * Axios methods
 */

export const generate = async (vin: string): Promise<VehicleReport> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<VehicleReport>((resolve) => {
      resolve(
        VehicleReportFactory({
          branding: null,
          claims: [claim],
          company: UserFunctionCompanyFactory(1, 1, 'code', 'CAA Assurances', 'Insurance CAA'),
          grossVehicleWeight: 1780,
          lastUpdated: subMonths(new Date(), 3),
          makeEn: 'Koenigsegg',
          makeFr: 'KoenigseggFr',
          modelEn: 'Gemera AWD Hybrid GT',
          modelFr: 'Gemera 4RM Hybride GT',
          modelYear: 2022,
          policies: [policy],
          reportDate: new Date(),
          requestor: 'Richard Hammond',
          vehicleCode: '420666',
          vin: vin,
          totalClaimsExpense: 1450900.69,
          totalClaimsLoss: 2420999.66,
        })
      );
    }).then((results) => {
      return results;
    });
  } else {
    return requests.post(`/vehicleReport/generate/${vin}`, {});
  }
};
