import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { unwrapResult } from '@reduxjs/toolkit';

import Loader from '../../Components/Loader';
import * as DateHelper from '../../Helpers/DateHelper';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { generateReport } from '../../Slices/ReportSlice';
import theme from '../../theme';
import { Report, Response } from '../../Types';
import { DroRefPayloadType } from './Search';

const useStyles = makeStyles(() =>
  createStyles({
    buttonOk: {
      width: '100%',
    },
    resultContent: {
      fontWeight: 700,
    },
  })
);

interface SearchResultProps {
  result?: Response | null;
  droRefPayload: DroRefPayloadType;
  displayReport: (reportId: string) => void;
  enableFields: (enable: boolean) => void;
}

const SearchResult: React.FC<SearchResultProps> = ({ displayReport, droRefPayload, enableFields, result = null }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  const isPending = useSelector((state: { report: { isPending: boolean } }) => state.report.isPending);
  const dispatch = useDispatch();

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleGetReport = async (prov: string, dln: string) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      // we call display report with id = 1 and return because durig test we will set report data to store directly with report id  = 1
      displayReport('1');
      return;
    }
    //Do real stuff
    try {
      const resultAction = await dispatch(
        generateReport({
          prov,
          dln,
          comment: droRefPayload.comment,
          driverReportOption: droRefPayload.dro,
        })
      );
      const promiseReport = unwrapResult<any>(resultAction) as Report;
      displayReport(promiseReport.id);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Card sx={{ padding: theme.spacing(2), minWidth: '500px' }}>
        {result && result.count > 1 && (
          <>
            <CardContent data-testid="manyRecordsFound" style={{ textAlign: 'left' }}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('searchResult.manyRecordsFound', { count: result.count })}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{t('searchResult.multipleSearchResult')}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  ref={enterKeyRef}
                  onClick={() => {
                    enableFields(true);
                  }}
                  data-testid="okBtn"
                  className={classes.buttonOk}
                >
                  {t('searchResult.ok')}
                </Button>
              </Grid>
            </CardActions>
          </>
        )}
        {result && result.count === 1 && (
          <>
            <CardContent style={{ alignContent: 'left' }} data-testid="oneRecordFound">
              <Grid item container xs={12}>
                <Grid item container xs={12} marginBottom={2}>
                  <Typography variant="h5">{`${result.data!.fullName || ''}`}</Typography>
                </Grid>
                {/* <Grid item container xs={12}>
                  <Typography className={classes.resultContent} variant="h6" component="div">
                    {`${result.data!.fullName || ''}`}
                  </Typography>
                </Grid> */}
                <Grid item container xs={12}>
                  <Typography variant="h6">{t('searchResult.ddlLicenseIdPlaceholder')}&nbsp;</Typography>
                  <Typography variant="h6" className={classes.resultContent}>
                    {`${result.data!.licenseNumber} - ${
                      i18n.language.startsWith('en')
                        ? result.data!.jurisdiction.jurisdictionDescriptionEn
                        : result.data!.jurisdiction.jurisdictionDescriptionFr
                    }`}
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Typography variant="h6">{`${t('searchResult.monthYearOfDate')}`}&nbsp;</Typography>
                  <Typography variant="h6" className={classes.resultContent} data-testid="monthYearOfBirth">
                    {result.data!.dateOfBirth
                      ? DateHelper.formatMonthYearBirthDateString(result.data!.dateOfBirth)
                      : result.data!.yearOfBirth || t('report.common.notAvailable')}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      enableFields(true);
                    }}
                    data-testid="okBtn"
                    className={classes.buttonOk}
                  >
                    {t('searchResult.cancel')}
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    ref={enterKeyRef}
                    onClick={() =>
                      handleGetReport(
                        result.data!.jurisdiction.jurisdictionCode || '',
                        result.data!.licenseNumber || ''
                      )
                    }
                    data-testid="getReportBtn"
                    className={classes.buttonOk}
                  >
                    {t('searchResult.getReport')}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </>
        )}
        {(!result || !result.count) && (
          <>
            <CardContent data-testid="zeroRecordFound" style={{ textAlign: 'left' }}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('searchResult.emptySearch', { count: 0 })}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{t('searchResult.noSearchResult')}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  ref={enterKeyRef}
                  onClick={() => {
                    enableFields(true);
                  }}
                  data-testid="okBtn"
                  className={classes.buttonOk}
                >
                  {t('searchResult.ok')}
                </Button>
              </Grid>
            </CardActions>
          </>
        )}
      </Card>
      {isPending && <Loader open={true} />}
    </>
  );
};
export default SearchResult;
