import { FC, FormEvent, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';

import { Users } from '../../axios';
import DashCustomPasswordInput from '../../Components/DashCustomPasswordInput';
import Loader from '../../Components/Loader';
import { PASSWORD_CHANGE_FAILED } from '../../Helpers/Constants';
import { getToken } from '../../Helpers/IbccrTokenHelper';
import { passwordRegexMatch } from '../../Helpers/Regex';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { addNotification } from '../../Slices/NotificationSlice';
import theme from '../../theme';
import { ChangeMyPassword } from '../../Types/ChangeMyPassword';

const ChangePassword: FC = () => {
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [currentPass, setCurrentPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [confirmPass, setConfirmPass] = useState<string>('');

  const [isNewPassError, setIsNewPassError] = useState<boolean>(false);
  const [isConfirmPassError, setIsConfirmPassError] = useState<boolean>(false);

  const handleCancel = () => {
    history.push('/');
  };

  const resetForm = () => {
    setCurrentPass('');
    setNewPass('');
    setConfirmPass('');
    setIsNewPassError(false);
    setIsConfirmPassError(false);
  };

  const validateNewPassword = (password: string) => {
    if (password && !passwordRegexMatch(password)) {
      setIsNewPassError(true);
    } else {
      setIsNewPassError(false);
    }
  };

  const validateConfirmPassword = (password: string) => {
    if (password && password !== newPass) {
      setIsConfirmPassError(true);
    } else {
      setIsConfirmPassError(false);
    }
  };

  const handleNewPassChange = (password: string) => {
    setNewPass(password);
    if (isNewPassError) {
      validateNewPassword(password);
    }
    // check with confirm pass again if new password is changed after confirm pass
    if (confirmPass) {
      if (confirmPass !== password) {
        setIsConfirmPassError(true);
      } else {
        setIsConfirmPassError(false);
      }
    }
  };

  const handleConfirmPassChange = (password: string) => {
    setConfirmPass(password);
    if (isConfirmPassError) {
      validateConfirmPassword(password);
    }
  };

  const handleError400 = useCallback(
    (error: any) => {
      if (
        (typeof error.response.data == 'string' || typeof error.response.data == 'object') &&
        !error.response.data.status
      ) {
        if (error.response.data.includes(PASSWORD_CHANGE_FAILED)) {
          dispatch(addNotification(400, 'error', t('changePassword.error.passwordChangeFailed')));
        } else {
          dispatch(addNotification(400, 'error', t('errorMessage.error400')));
        }
      }
    },
    [dispatch, t]
  );

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoadingSave(true);
    const request: ChangeMyPassword = {
      IbccrToken: await getToken(),
      CurrentPassword: currentPass,
      NewPassword: newPass,
    };
    Users.changePassword(request)
      .then(() => {
        dispatch(addNotification(200, 'error', t('changePassword.ok200')));
        resetForm();
        history.push('/');
      })
      .catch((error) => {
        if (error.message.includes('400')) {
          handleError400(error);
        } else {
          handleError(error);
        }
      })
      .finally(() => {
        setIsLoadingSave(false);
      });
  };

  return (
    <>
      <Card elevation={3}>
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                <Grid item md={12} lg={11} xl={8}>
                  <DashCustomPasswordInput
                    label={t('changePassword.currentPassword')}
                    value={currentPass}
                    onChange={(e) => setCurrentPass(e.target.value)}
                    fullWidth
                    autoFocus
                    labelGridSize={3}
                    fieldGridSize={6}
                    variant="outlined"
                    autoComplete="nope"
                    color="secondary"
                    sx={{ paddingRight: 0 }}
                    placeholder={t('changePassword.currentPasswordPlaceholder')}
                    isRequired={true}
                  />
                </Grid>
                <Grid item md={12} lg={11} xl={8}>
                  <DashCustomPasswordInput
                    label={t('changePassword.newPassword')}
                    value={newPass}
                    onChange={(e) => handleNewPassChange(e.target.value.replace(/\s/g, ''))}
                    onBlur={(e) => validateNewPassword(e.target.value)}
                    error={isNewPassError}
                    helperText={isNewPassError && t('changePassword.error.regexError')}
                    fullWidth
                    labelGridSize={3}
                    fieldGridSize={6}
                    variant="outlined"
                    autoComplete="nope"
                    color="secondary"
                    sx={{ paddingRight: 0 }}
                    placeholder={t('changePassword.newPasswordPlaceholder')}
                    isRequired={true}
                  />
                </Grid>
                <Grid item md={12} lg={11} xl={8}>
                  <DashCustomPasswordInput
                    label={t('changePassword.confirmPassword')}
                    value={confirmPass}
                    onChange={(e) => handleConfirmPassChange(e.target.value.replace(/\s/g, ''))}
                    onBlur={(e) => validateConfirmPassword(e.target.value)}
                    error={isConfirmPassError}
                    helperText={isConfirmPassError && t('changePassword.error.passwordMismatch')}
                    fullWidth
                    labelGridSize={3}
                    fieldGridSize={6}
                    variant="outlined"
                    autoComplete="nope"
                    color="secondary"
                    sx={{ paddingRight: 0 }}
                    placeholder={t('changePassword.confirmPasswordPlaceholder')}
                    isRequired={true}
                  />
                </Grid>

                {/* Buttons */}
                <Grid
                  item
                  container
                  alignItems="center"
                  wrap="nowrap"
                  md={12}
                  lg={11}
                  xl={8}
                  sx={{ marginTop: theme.spacing(2) }}
                >
                  <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                  <Grid item container spacing={1} xs={6}>
                    <Grid item xs={5}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        id="btnSearch"
                        data-testid="btnSearch"
                        disabled={
                          !!isNewPassError ||
                          !!isConfirmPassError ||
                          !currentPass ||
                          !confirmPass ||
                          !newPass ||
                          newPass !== confirmPass
                        }
                      >
                        {t('userProfile.create.lblBtnSave')}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        fullWidth
                        onClick={handleCancel}
                        variant="contained"
                        color="secondary"
                        data-testid="clearFields"
                      >
                        {t('userProfile.create.lblBtnCancel')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Buttons */}

                <Grid item container xs={12}>
                  <Grid item container xs={10}>
                    <Grid item xs={2}>
                      <Box width="100%" />
                    </Grid>
                    {/* Grid is made that way because it's not inside the correct column count of the other fields */}
                    <Grid item container xs={10} paddingLeft={7.5}>
                      <Typography align="justify" sx={{ fontSize: '9pt' }}>
                        <Trans i18nKey="changePassword.validationMsg">
                          Password must contain characters from ateast three of the following groups:
                          <ul>
                            <li>Uppercase letters</li>
                            <li>Lowercase letters</li>
                            <li>Numbers</li>
                            <li>Special characters</li>
                          </ul>
                          Password must be between 8 and 64 characters long
                        </Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingSave} />
    </>
  );
};

export default ChangePassword;
