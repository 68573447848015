import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import { Dialog } from '@mui/material';

import Cookies from 'js-cookie';

import { DriverReportOptions, ReportingCompanies, Reports } from '../../axios';
import Loader from '../../Components/Loader';
import { sortDriverReportOptions, sortRptCompanies } from '../../Helpers/SortHelpers';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../Slices/OrganizationSlice';
import {
  DriverReportOption,
  Functions,
  JurisdictionFactory,
  ReactLocationState,
  ReportingCompany,
  Response,
  User,
} from '../../Types';
import FullSearch from './FullSearch';
import SearchResult from './SearchResult';

interface SearchProps extends RouteComponentProps {
  user: User;
}
export interface FormValuesProps {
  driverLicenceNumber: string;
  jurisdictionCode: string;
  fullName: string;
  fullAddress: string;
  yearOfBirth: string | null;
  vehicleIdentificationNumber: string;
  policyNumber: string;
  claimNumber: string;
  policyReportingCompanyCode: string | null;
  claimReportingCompanyCode: string | null;
  comment: string;
}

export interface SearchRef {
  enableFields: (enable: boolean) => void;
}

export type DroRefPayloadType = {
  comment: string;
  dro: DriverReportOption;
};

const Search: FC<SearchProps> = ({ user, history }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname } = useLocation<ReactLocationState>();

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const [companies, setCompanies] = useState<Array<ReportingCompany> | null>(null);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);

  const [dros, setDros] = useState<Array<DriverReportOption>>([]);
  const [defaultDro, setDefaultDro] = useState<DriverReportOption | null>(null);
  const [isLoadingDros, setIsLoadingDros] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<FormValuesProps>({
    driverLicenceNumber: '',
    jurisdictionCode: Cookies.get('userId-jurisdiction') ?? '',
    fullName: '',
    fullAddress: '',
    yearOfBirth: null,
    vehicleIdentificationNumber: '',
    policyNumber: '',
    claimNumber: '',
    policyReportingCompanyCode: null,
    claimReportingCompanyCode: null,
    comment: '',
  });
  const [isPending, setIsPending] = useState(false);
  const [fieldsEnabled, setFieldsEnabled] = useState(true);

  const [showResult, setShowResult] = useState(false);
  const [driverSearchResult, setDriverSearchResult] = useState<Response | null>(null);

  const [droRefPayload, setDroRefPayload] = useState<DroRefPayloadType | null>(null);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname, fromTab: 'driverReport' });
  }, [history, pathname]);

  // Fetch the correct organizations
  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.driverReport }));
  }, [dispatch]);

  // Fetching Reporting Companies
  const sortCompanies = useCallback(
    (a: ReportingCompany, b: ReportingCompany): number => {
      return sortRptCompanies(a, b, i18n);
    },
    [i18n]
  );

  useEffect(() => {
    if (!companies || companies.length === 0) {
      setIsLoadingCompanies(true);
      ReportingCompanies.list()
        .then((results) => {
          setCompanies(results.sort((a: ReportingCompany, b: ReportingCompany) => sortCompanies(a, b)));
          setIsLoadingCompanies(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingCompanies(false);
        });
    }
  }, [companies, dispatch, handleError, sortCompanies, t]);

  // Fetching the DROs
  useEffect(() => {
    if (organizations.length > 0) {
      setIsLoadingDros(true);
      // Fetching Driver Report Options
      DriverReportOptions.listByUserAccess()
        .then((results) => {
          // By design, the default DRO of the user is always the first in the list
          // However, the sort will scramble the order so we need to save the default
          // value in a different state and pass it to FullSearch.tsx
          setDefaultDro(results[0]);
          const sortedResults = results.sort((a, b) =>
            sortDriverReportOptions(a, b, i18n, organizations, companies, true)
          );
          setDros(sortedResults);
          setIsLoadingDros(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingDros(false);
        });
    }
  }, [dispatch, i18n, organizations, companies, t, handleError]);

  const handleSearch = (numberOfYears: DriverReportOption) => {
    setFieldsEnabled(false);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      if (formValues && formValues.driverLicenceNumber.length > 0 && formValues.driverLicenceNumber.endsWith('0')) {
        setDriverSearchResult(null);
        setShowResult(true);
      } else if (
        formValues &&
        formValues.driverLicenceNumber.length > 0 &&
        formValues.driverLicenceNumber.endsWith('2')
      ) {
        setDriverSearchResult({
          count: 2,
          data: null,
        });
        setShowResult(true);
      } else if (
        formValues &&
        formValues.driverLicenceNumber.length > 0 &&
        formValues.driverLicenceNumber.endsWith('8')
      ) {
        setIsPending(true);
      } else if (formValues && formValues.driverLicenceNumber.length > 0) {
        setDriverSearchResult({
          count: 1,
          data: {
            fullName: 'John Doe',
            middleName: '',
            dateOfBirth: '2021-11-15',
            yearOfBirth: '',
            jurisdiction: JurisdictionFactory(1, 'Ontario', 'Ontario', 'ON'),
            licenseNumber: 'S1234-67891-12345',
            userId: '2',
          },
        });
        setShowResult(true);
      } else {
        setShowResult(false);
      }
      return;
    }

    // Do real stuff
    setIsPending(true);

    Reports.search({
      ...formValues,
      yearOfBirth: formValues.yearOfBirth ? formValues.yearOfBirth : null,
      driverReportOption: numberOfYears,
    })
      .then((result) => {
        if (result) {
          setDriverSearchResult(result);
          setDroRefPayload({ comment: formValues.comment, dro: numberOfYears! });
        } else {
          setDriverSearchResult(null);
          setDroRefPayload(null);
        }
        setShowResult(true);
        setIsPending(false);
        setFieldsEnabled(false);
      })
      .catch((error) => {
        handleError(error);
        setIsPending(false);
        setFieldsEnabled(true);
      });
  };

  const handleDisplayReport = (reportId: string) => {
    // Reseting fields because user has navigated to Report
    setFormValues({
      driverLicenceNumber: '',
      jurisdictionCode: Cookies.get('userId-jurisdiction') ?? '',
      fullName: '',
      fullAddress: '',
      yearOfBirth: null,
      vehicleIdentificationNumber: '',
      policyNumber: '',
      claimNumber: '',
      policyReportingCompanyCode: null,
      claimReportingCompanyCode: null,
      comment: '',
    });
    history.push({
      pathname: `/reports/${reportId}`,
      state: { from: '/driver-report' } as ReactLocationState,
    });
  };

  const enableFields = (enable: boolean) => {
    setShowResult(false);
    setFieldsEnabled(enable);
  };

  return (
    <>
      {user && defaultDro ? (
        <FullSearch
          formValues={formValues}
          setFormValues={setFormValues}
          fieldsEnabled={fieldsEnabled}
          companies={companies}
          isLoadingCompanies={isLoadingCompanies}
          dros={dros}
          defaultDro={defaultDro}
          isLoadingDros={isLoadingDros}
          onLoading={setIsPending}
          onSearch={handleSearch}
        />
      ) : (
        <Loader open={isLoadingDros || !defaultDro} />
      )}
      <Dialog open={!isPending && !!driverSearchResult && showResult}>
        <SearchResult
          result={driverSearchResult}
          droRefPayload={droRefPayload!}
          displayReport={handleDisplayReport}
          enableFields={enableFields}
        />
      </Dialog>
      {isPending && <Loader open={isPending} />}
    </>
  );
};

export default withRouter(Search);
