import { requests } from '../axios';
import { BlobDto } from '../Types';

export const updateContactUs = (lang: string, contactUs: BlobDto): Promise<BlobDto> => {
  return requests.put(`/contactUs?lang=${lang}`, contactUs);
};

export const getContactUs = (lang: string): Promise<BlobDto> => {
  return requests.get(`/contactUs?lang=${lang}`, {});
};
