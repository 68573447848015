import { requests } from '../axios';
import { Organization, OrganizationFactory } from '../Types';

export const getOrganizationsAxios = async (functionId: number): Promise<Organization[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Organization[]>((resolve) => {
      resolve([
        OrganizationFactory(1, 'Carrier', 'NameEn', 'NameFr', 'code'),
        OrganizationFactory(2, 'Carrier', 'NameEn1', 'NameFr1', 'code1'),
        OrganizationFactory(3, 'Carrier', 'NameEn2', 'NameFr2', 'code2'),
        OrganizationFactory(4, 'IBC', 'NameEn3', 'NameFr3', 'code3'),
        OrganizationFactory(5, 'ThirdParty', 'NameEn4', 'NameFr4', 'code4'),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.get(`/organizations/get/${functionId}`, {});
  }
};

export const listOrganizations = async (): Promise<Organization[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Organization[]>((resolve) => {
      resolve([
        OrganizationFactory(1, 'Carrier', 'NameEn', 'NameFr', 'code'),
        OrganizationFactory(2, 'Carrier', 'NameEn1', 'NameFr1', 'code1'),
        OrganizationFactory(3, 'Carrier', 'NameEn2', 'NameFr2', 'code2'),
        OrganizationFactory(4, 'IBC', 'NameEn3', 'NameFr3', 'code3'),
        OrganizationFactory(5, 'ThirdParty', 'NameEn4', 'NameFr4', 'code4'),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.get('/organizations/list', {});
  }
};
