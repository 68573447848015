interface UserCopyProfile {
  id: number;
  userId: string;
  userName: string;
}

export const UserCopyProfileFactory = (id: number, userId: string, userName: string): UserCopyProfile => {
  return {
    id: id,
    userId: userId,
    userName: userName,
  };
};

export default UserCopyProfile;
