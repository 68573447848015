import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createDOMPurify from 'dompurify';

import { TermsOfServices } from '../axios';
import { TermsOfService } from '../Types';

export const GetTermsContent = (lang: string, setIsLoading: Dispatch<SetStateAction<boolean>>): ReactNode => {
  const { t, i18n } = useTranslation();
  const [termsOfService, setTermsOfService] = useState('');

  const DOMPurify = createDOMPurify(window);

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      setIsLoading(true);
      TermsOfServices.getTermsOfService()
        .then((data: TermsOfService) => {
          lang === 'en'
            ? setTermsOfService(decodeURIComponent(data.termsOfServiceEnHtml))
            : setTermsOfService(decodeURIComponent(data.termsOfServiceFrHtml));
        })
        .catch(() => {
          // if there is an error here then it's probably that the TermsOfServices hasn't been set yet. don't display error message.
          setTermsOfService('');
        })
        .finally(() => setIsLoading(false));
    };

    onLoadFromBlobStorage();
  }, [i18n.language, lang, setIsLoading, t]);

  const sanitizedTermsOfService = DOMPurify.sanitize(termsOfService);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: sanitizedTermsOfService }} />
    </>
  );
};
