import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Card, CardActionArea, Grid } from '@mui/material';

import ninetyDaysTileEn from '../Medias/tiles/90Days-EN.jpg';
import ninetyDaysTileFr from '../Medias/tiles/90Days-FR.jpg';
import accessTileEn from '../Medias/tiles/Access-EN.jpg';
import accessTileFr from '../Medias/tiles/Access-FR.jpg';
import clientsTileEn from '../Medias/tiles/Clients-EN.jpg';
import clientsTileFr from '../Medias/tiles/Clients-FR.jpg';
import companyTileEn from '../Medias/tiles/Company-EN.jpg';
import companyTileFr from '../Medias/tiles/Company-FR.jpg';
import correctionReportEn from '../Medias/tiles/Corrections-EN.jpg';
import correctionReportFr from '../Medias/tiles/Corrections-FR.jpg';
import driverTileEn from '../Medias/tiles/Drivers-EN.jpg';
import driverTileFr from '../Medias/tiles/Drivers-FR.jpg';
import historyTileEn from '../Medias/tiles/History-EN.jpg';
import historyTileFr from '../Medias/tiles/History-FR.jpg';
import sdcEn from '../Medias/tiles/SDC-EN.jpg';
import sdcFr from '../Medias/tiles/SDC-FR.jpg';
import settingsTileEn from '../Medias/tiles/Settings-EN.jpg';
import settingsTileFr from '../Medias/tiles/Settings-FR.jpg';
import usageTileEn from '../Medias/tiles/Usage-EN.jpg';
import usageTileFr from '../Medias/tiles/Usage-FR.jpg';
import usersTileEn from '../Medias/tiles/Users-EN.jpg';
import usersTileFr from '../Medias/tiles/Users-FR.jpg';
import vehiclesTileEn from '../Medias/tiles/Vehicles-EN.jpg';
import vehiclesTileFr from '../Medias/tiles/Vehicles-FR.jpg';
import theme from '../theme';

interface MenuTileProps {
  icon:
    | 'client'
    | 'driverReport'
    | 'vehicleReport'
    | 'reportHistory'
    | 'ninetyDays'
    | 'companyProfile'
    | 'userAdmin'
    | 'usageReport'
    | 'accessReport'
    | 'settings'
    | 'correctionReport'
    | 'sdc';
  route: string;
}

const MenuTile: FC<MenuTileProps> = ({ icon, route }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const isEnglish = i18n.language.startsWith('en');

  const handleClick = () => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert(`click: ${route}`);
    }

    history.push(route);
  };

  return (
    <Card
      sx={{
        height: '200px',
        width: '200px',
        backgroundColor: theme.palette.secondary.main,
        margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
        boxShadow: '#8f939c 1.95px 1.95px 5px',
      }}
    >
      <CardActionArea data-testid={`menuTile-${icon}`} onClick={() => handleClick()}>
        <Grid
          container
          sx={{
            height: '200px',
            width: '200px',
          }}
        >
          <Grid item container xs={12} justifyContent="center">
            {icon === 'driverReport' && (
              <img src={isEnglish ? driverTileEn : driverTileFr} width={200} height={200} alt="driverReport" />
            )}
            {icon === 'vehicleReport' && (
              <img src={isEnglish ? vehiclesTileEn : vehiclesTileFr} width={200} height={200} alt="vehicleReport" />
            )}
            {icon === 'reportHistory' && (
              <img src={isEnglish ? historyTileEn : historyTileFr} width={200} height={200} alt="reportHistory" />
            )}
            {icon === 'ninetyDays' && (
              <img src={isEnglish ? ninetyDaysTileEn : ninetyDaysTileFr} width={200} height={200} alt="ninetyDays" />
            )}
            {icon === 'companyProfile' && (
              <img src={isEnglish ? companyTileEn : companyTileFr} width={200} height={200} alt="companyProfile" />
            )}
            {icon === 'userAdmin' && (
              <img src={isEnglish ? usersTileEn : usersTileFr} width={200} height={200} alt="userAdmin" />
            )}
            {icon === 'usageReport' && (
              <img src={isEnglish ? usageTileEn : usageTileFr} width={200} height={200} alt="usageReport" />
            )}
            {icon === 'accessReport' && (
              <img src={isEnglish ? accessTileEn : accessTileFr} width={200} height={200} alt="accessReport" />
            )}
            {icon === 'settings' && (
              <img src={isEnglish ? settingsTileEn : settingsTileFr} width={200} height={200} alt="settings" />
            )}
            {icon === 'client' && (
              <img src={isEnglish ? clientsTileEn : clientsTileFr} width={200} height={200} alt="client" />
            )}
            {icon === 'correctionReport' && (
              <img
                src={isEnglish ? correctionReportEn : correctionReportFr}
                width={200}
                height={200}
                alt="correctionReport"
              />
            )}
            {icon === 'sdc' && <img src={isEnglish ? sdcEn : sdcFr} width={200} height={200} alt="sdc" />}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default MenuTile;
