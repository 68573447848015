import { FC } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderProps {
  open?: boolean;
  size?: number;
}

const Loader: FC<LoaderProps> = ({ open = false, size = 100 }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 100 }}>
      <CircularProgress size={size} color="primary" data-testid="progress" />
    </Backdrop>
  );
};

export default Loader;
