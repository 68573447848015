import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CardContent, Grid } from '@mui/material';

import { ClientSettingAxios } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { ClientSetting } from '../../../Types';
import Results from './Results';

interface FormValuesProps {
  name: string | null;
}

const ClientSettings: FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const handleError = useErrorHandler();
  const [formValues, setFormValues] = useState<FormValuesProps>({ name: null });
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
  const [settings, setSettings] = useState<Array<ClientSetting> | null>(null);

  const fetchClientSettings = useCallback(() => {
    setIsLoadingResults(true);
    ClientSettingAxios.list()
      .then((results) => {
        setIsLoadingResults(false);
        setSettings(results);
      })
      .catch((error) => {
        setIsLoadingResults(false);
        setSettings([]);
        handleError(error, { error500: t('settings.b2cSettings.error500') });
      });
  }, [handleError, t]);

  useEffect(() => {
    if (!settings) {
      fetchClientSettings();
    }
  }, [dispatch, fetchClientSettings, handleError, settings, t]);

  const handleSearch = async (search: string) => {
    setFormValues({ ...formValues, name: search || null });
    if (search && settings) {
      if (settings.length === 0) {
        setIsLoadingResults(true);
        ClientSettingAxios.list()
          .then((results) => {
            setIsLoadingResults(false);
            setSettings(
              results.filter((p) =>
                i18n.language.startsWith('en')
                  ? p.organization.nameEn.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()) === true
                  : p.organization.nameFr.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()) === true
              )
            );
          })
          .catch((error) => {
            setIsLoadingResults(false);
            setSettings([]);
            handleError(error);
          });
        return;
      } else {
        const filteredSettings = settings.filter((s) =>
          i18n.language.startsWith('en')
            ? s.organization.nameEn.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()) === true
            : s.organization.nameFr.toLocaleLowerCase().startsWith(search.toLocaleLowerCase()) === true
        );
        setSettings(filteredSettings);
      }
    } else {
      setSettings(null);
    }
  };

  return (
    <>
      <CardContent sx={{ paddingRight: '0px' }}>
        <Grid container>
          <Grid item container xs={12} padding={2} alignItems="flex-start">
            <Grid item xs={9} md={9} lg={8} xl={7}>
              <DashCustomInput
                fullWidth
                label={t('settings.clientSettings.search')}
                labelGridSize={3}
                fieldGridSize={10}
                variant="outlined"
                color="secondary"
                sx={{ paddingRight: 0 }}
                value={formValues.name || ''}
                autoComplete="nope"
                onChange={(e) => handleSearch(e.target.value)}
                inputProps={{ 'data-testid': 'name' }}
                placeholder={t('settings.clientSettings.searchPlaceholder')}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            {settings && !isLoadingResults && <Results settings={settings} fetchClientSettings={fetchClientSettings} />}
          </Grid>
        </Grid>
      </CardContent>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default ClientSettings;
