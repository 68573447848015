import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    buttonOk: {
      width: '100%',
    },
    resultContent: {
      fontWeight: 700,
    },
  })
);

interface NoResultDialogProps {
  onDismiss: () => void;
}

const NoResultDialog: FC<NoResultDialogProps> = ({ onDismiss }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      event.preventDefault();
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Card sx={{ padding: theme.spacing(2), minWidth: '500px' }}>
      <CardContent data-testid="zeroRecordFound" style={{ textAlign: 'left' }}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t('searchResult.emptySearch', { count: 0 })}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            ref={enterKeyRef}
            onClick={onDismiss}
            data-testid="okBtn"
            className={classes.buttonOk}
          >
            {t('searchResult.ok')}
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};
export default NoResultDialog;
