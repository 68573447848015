export const getJoditEditorConfig = () => {
  return {
    readonly: false,
    toolbar: true,
    hidePoweredByJodit: true,
    disablePlugins: 'video,file,print',
    removeButtons: [
      'spellcheck',
      'classSpan',
      'copyformat',
      'superscript',
      'subscript',
      'file',
      'video',
      'table',
      'link',
      'symbols',
      'brush',
      'find',
      'fullsize',
      'print',
      'preview',
      'about',
    ],
  };
};
