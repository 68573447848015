export interface B2CProvider {
  id: number;
  emailDomainName: string;
  b2CDomainName: string;
}

export const B2CProviderFactory = (id: number, emailDomainName: string, b2CDomainName: string): B2CProvider => {
  return {
    id,
    emailDomainName,
    b2CDomainName,
  };
};

export default B2CProvider;
