import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { DashClients } from '../../../axios';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { Client } from '../../../Types';

interface DeleteClientDialogProps {
  clientToDelete: Client;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSearch: () => Promise<void>;
}

const DeleteClientDialog: FC<DeleteClientDialogProps> = ({ clientToDelete, handleSearch, setOpen }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleDelete = async () => {
    setIsLoading(true);
    DashClients.deleteClient(clientToDelete.id)
      .then(() => {
        dispatch(addNotification(200, 'success', t('dashclients.clientDetails.ok200DeleteClient')));
        handleSearch();
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        handleError(error, { error409: t('dashclients.clientDetails.error409ClientAssignedToUser') });
        setIsLoading(false);
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
        data-testid="deleteDialogClient"
      >{`${t('dialog.delete')} ${
        i18n.language.startsWith('en') ? clientToDelete.clientType.nameEn : clientToDelete.clientType.nameFr
      }`}</Typography>
      <DialogContent sx={{ padding: theme.spacing(4) }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t('dashclients.deleteDialogContentOther')}</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
          <Grid item xs={4}>
            <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={handleClose}>
              {t('dialog.no')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              data-testid="delete"
              variant="contained"
              color="primary"
              ref={enterKeyRef}
              onClick={handleDelete}
            >
              {t('dialog.yes')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Loader open={isLoading} />
    </>
  );
};

export default DeleteClientDialog;
