import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { PermittedDomains } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import { DOMAIN_REGEX } from '../../../Helpers/Regex';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { ModeEnum } from '../../../Types';
import PermittedDomainOption from '../../../Types/PermittedDomain';
import { AddDialogProps } from './AddDialogProps';

const AddDomainDialog: FC<AddDialogProps<PermittedDomainOption>> = ({
  mode,
  item,
  selectedOrgId,
  selectedRptCompanyId,
  onAction,
  onCreated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [open, setOpen] = useState<boolean>(true);
  const [field, setField] = useState<string>(item && item.domain ? item.domain : '');
  const [isDomainFormatError, setIsDomainFormatError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isFieldEmpty = useCallback(() => {
    return field.length === 0;
  }, [field]);

  const handleSave = async () => {
    const fieldValue = field.toLowerCase();
    if (!fieldValue.match(DOMAIN_REGEX)) {
      setIsDomainFormatError(true);
    } else {
      setIsDomainFormatError(false);
      setIsLoading(true);
      setOpen(false);
      const params = {
        domain: field,
        organizationId: selectedOrgId,
        reportingCompanyId: selectedRptCompanyId,
      };

      if (mode === ModeEnum.Create) {
        await PermittedDomains.create(params)
          .then((domain) => {
            dispatch(addNotification(200, 'success', t('successMessage.created', { resource: domain.domain })));
            onCreated(domain);
            setIsLoading(false);
          })
          .catch((error) => {
            handleError(error, { error409: t('errorMessage.error409Duplicate') });
            setIsLoading(false);
          });
      }
      onAction();
    }
  };

  const handleClose = () => {
    setOpen(false);
    onAction();
  };

  const handleDomainChange = (e: any) => {
    const value = e.target.value.toLowerCase() as string;
    if (!value.match(DOMAIN_REGEX)) {
      setField(e.target.value);
    } else {
      setIsDomainFormatError(false);
      setField(e.target.value);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="addRenameDialogComponent"
      >
        <Typography variant="h5" sx={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4) }}>
          {t('userAdmin.companyProf.add')} {t('userAdmin.companyProf.dialogTitleDomain')}
        </Typography>
        <DialogContent sx={{ padding: theme.spacing(4) }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
              <DashCustomInput
                autoFocus
                id="name"
                value={field}
                labelGridSize={0}
                fieldGridSize={12}
                fullWidth
                variant="outlined"
                color="secondary"
                placeholder={t('userAdmin.companyProf.addDomainPlaceholder')}
                autoComplete="nope"
                sx={{ paddingRight: '0' }}
                onChange={(e) => handleDomainChange(e)}
                error={isDomainFormatError}
                helperText={isDomainFormatError && t('userAdmin.companyProf.domainError')}
                inputProps={{ 'data-testid': 'textfield', maxLength: 60 }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
            <Grid item xs={4}>
              <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={handleClose}>
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid="accept"
                variant="contained"
                color="primary"
                disabled={isFieldEmpty() || isDomainFormatError}
                ref={enterKeyRef}
                onClick={handleSave}
              >
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading} />
    </>
  );
};

export default AddDomainDialog;
