export type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  //Case insensitive comparison for string
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    const a1 = String(a[orderBy] ? a[orderBy] : '');
    const b1 = String(b[orderBy] ? b[orderBy] : '');
    if (b1.toLowerCase() < a1.toLowerCase()) {
      return -1;
    }
    if (b1.toLowerCase() > a1.toLowerCase()) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
