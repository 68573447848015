import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import Loader from '../Components/Loader';
import UserProfileSearch from '../Features/UserAdministration/UserProfile/UserProfileSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import UsersEN from '../Medias/titles/Users - EN.jpg';
// import UsersFR from '../Medias/titles/Users - FR.jpg';
import { Functions, User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface UserAdministrationProps extends RouteComponentProps {
  user: User;
}

const UserAdministration: FC<UserAdministrationProps> = ({ user }) => {
  const classes = useStyles();
  // const { i18n } = useTranslation();
  const { t } = useTranslation();

  const userAccessUserAdmin = doUserHaveAccessToFeature(user, Functions.userAdministration);

  return user ? (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContainerTitle title={t('userAdmin.title')} />
          {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? UsersEN : UsersFR} /> */}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {userAccessUserAdmin && <UserProfileSearch />}
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Loader open={true} />
  );
};

export default withRouter(UserAdministration);
