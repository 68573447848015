import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import theme from '../../theme';
import { Vehicle } from '../../Types';

interface VehicleListProps {
  showAllVehicleDetail: boolean;
  vehicles: Array<Vehicle>;
}

const useStyles = makeStyles({
  box: {
    p: 2,
    backgroundColor: theme.palette.secondary.light,
    display: 'grid',
    gap: 2,
    width: '100%',
    margin: '15px 20px 0px 20px',
  },
  container: {
    backgroundColor: theme.palette.white.main,
  },
  subContainer: {
    padding: theme.spacing(3),
  },
  colonneLeft: {
    paddingRight: theme.spacing(2),
  },
  colonneRight: {
    paddingLeft: theme.spacing(3),
  },
  vehiculeDivider: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  label: {
    display: 'inline',
    color: theme.palette.primary.dark,
    marginRight: '5px',
  },
  value: {
    display: 'inline',
    fontWeight: 'bold',
    marginRight: '5px',
    marginTop: '5px',
  },
  info: {
    display: 'inline',
    color: theme.palette.primary.dark,
  },
});
const VehiclesList: React.FC<VehicleListProps> = ({ showAllVehicleDetail, vehicles }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid container item>
        {vehicles.length === 0 && (
          <Grid item sm={12}>
            <Box className={classes.box} justifyContent="center">
              <Typography data-testid="noneVehicle" className={classes.value}>
                {t('report.common.none')}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {vehicles.length !== 0 &&
        vehicles.map((v, i) => {
          return (
            <Grid container key={i}>
              {i !== 0 && (
                <Grid item xs={12} className={classes.vehiculeDivider}>
                  <Divider variant="fullWidth" />
                </Grid>
              )}
              <Grid container item xs={12} className={classes.subContainer}>
                <Grid container item xs={6} data-testid="vehicles" className={classes.colonneLeft}>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      {t('report.policy.vehicle.vehicleNumber', { count: i + 1 }).toString()} :
                    </Typography>
                    <Typography data-testid="reference" className={classes.value}>{`${
                      !v.isTrailer ? `${v.modelYear === 0 ? t('report.common.notAvailable') : v.modelYear} ` : ''
                    }${(i18n.language.startsWith('fr') ? `${v.makeFr}` : `${v.makeEn}`) || ''}${
                      (
                        i18n.language.startsWith('fr')
                          ? v.makeFr && (v.modelFr || v.vin)
                          : v.makeEn && (v.modelEn || v.vin)
                      )
                        ? ' - '
                        : ''
                    }${(i18n.language.startsWith('fr') ? v.modelFr : v.modelEn) || ''}${
                      v.vin && (i18n.language.startsWith('fr') ? v.modelFr : v.modelEn) ? ' - ' : ''
                    }${v.vin}`}</Typography>
                  </Grid>
                  {showAllVehicleDetail && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>{t('report.policy.vehicle.vehicleCode')}:</Typography>
                        <Typography data-testid="vehicleCode" className={classes.value}>
                          {v.vehicleCode || t('report.common.notAvailable')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>
                          {t('report.policy.vehicle.vehicleLocation')}
                          <Tooltip
                            placement="top"
                            title={t('report.policy.vehicle.vehicleLocationTooltip').toString()}
                            arrow
                          >
                            <IconButton
                              aria-label="info number of operators"
                              size="small"
                              sx={{ padding: '0 1px 0 3px' }}
                            >
                              <InfoOutlinedIcon fontSize="small" className={classes.info} />
                              {':'}
                            </IconButton>
                          </Tooltip>
                        </Typography>
                        <Typography data-testid="vehicleLocation" className={classes.value}>
                          {v.vehicleLocation || t('report.common.notAvailable')}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography className={classes.label}>{t('report.policy.vehicle.vehicleUse')}:</Typography>
                    <Typography data-testid="vehicleUse" className={classes.value}>
                      {(i18n.language.startsWith('fr') ? v.vehicleUse?.descriptionFr : v.vehicleUse?.descriptionEn) ||
                        t('report.common.notAvailable')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      {t('report.policy.vehicle.typeOfUseVehicleClass')}:
                    </Typography>
                    <Typography data-testid="typeOfUseVehicleClass" className={classes.value}>
                      {`${v.typeOfUse?.code} / ${
                        i18n.language.startsWith('fr') ? v.vehicleClass?.descriptionFr : v.vehicleClass?.descriptionEn
                      }` || t('report.common.notAvailable')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      {t('report.policy.vehicle.drivingRecord')}
                      <Tooltip placement="top" title={t('report.policy.vehicle.drivingRecordTooltip').toString()} arrow>
                        <IconButton aria-label="info number of vehicles" size="small" sx={{ padding: '0 1px 0 3px' }}>
                          <InfoOutlinedIcon fontSize="small" className={classes.info} />
                          {':'}
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography data-testid="drivingRecord" className={classes.value}>
                      {v.drivingRecord?.code || t('report.common.notAvailable')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={6} className={classes.colonneRight}>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>{t('report.policy.vehicle.coverage')}:</Typography>
                    <Typography data-testid="coverage" className={classes.value}>
                      {(v?.majorCoverages &&
                        v.majorCoverages.length > 0 &&
                        v.majorCoverages.map((c, index) =>
                          v.majorCoverages.length - 1 === index
                            ? `${i18n.language.startsWith('fr') ? c.descriptionFr : c.descriptionEn}`
                            : `${i18n.language.startsWith('fr') ? c.descriptionFr : c.descriptionEn}, `
                        )) ||
                        t('report.common.notAvailable')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      {t('report.policy.vehicle.commutingDistanceOneWayAnnual')}:
                    </Typography>
                    <Typography data-testid="commutingDistanceOneWayAnnual" className={classes.value}>
                      {v.commutingDistanceOneWay ? `${v.commutingDistanceOneWay}` : t('report.common.notAvailable')}
                      {' / '}
                      {v.commutingDistanceAnnual ? `${v.commutingDistanceAnnual}` : t('report.common.notAvailable')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>{t('report.policy.vehicle.businessUse')}: </Typography>
                    <Typography data-testid="businessUse" className={classes.value}>
                      {(v.businessUsePercentage == null && t('report.common.notAvailable')) ||
                        `${v.businessUsePercentage}%`}
                    </Typography>
                  </Grid>
                  {showAllVehicleDetail && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>{t('report.policy.vehicle.branding')}: </Typography>
                        <Typography data-testid="branding" className={classes.value}>
                          {(i18n.language.startsWith('fr') ? v.branding?.descriptionFr : v.branding?.descriptionEn) ||
                            t('report.common.none')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>
                          {t('report.policy.vehicle.grossVehicleWeight')}:
                        </Typography>
                        <Typography data-testid="grossVehicleWeight" className={classes.value}>
                          {(!v.grossVehicleWeightInKG &&
                            v.grossVehicleWeightInKG !== 0 &&
                            t('report.common.notAvailable')) ||
                            `${v.grossVehicleWeightInKG}kg`}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {/* false lines - empty space */}
                  <Box height="100%" />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default VehiclesList;
