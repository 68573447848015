import { requests } from '../axios';
import {
  OrganizationFactory,
  ReportingCompanyFactory,
  Response,
  UnitFactory,
  UsageReportRequest,
  UsageReportResponseFactory,
} from '../Types';

export const findUsageReports = (params: UsageReportRequest): Promise<Response> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Response>((resolve) => {
      resolve({
        count: 6,
        data: [
          UsageReportResponseFactory({
            userId: 'john.snow@winterfell.com',
            userName: 'John Snow',
            organization: OrganizationFactory(1, 'Carrier', 'Org 1 EN', 'Org 1 FR', 'code org 1'),
            reportingCompany: ReportingCompanyFactory(1, 1, 'code rpt 1', 'rpt Comp 1 FR', 'rpt Comp 1 EN'),
            unit: 'test',
            numberOfRequest: 15,
          }),
          UsageReportResponseFactory({
            userId: 'john.snow@winterfell.com',
            userName: 'John Snow',
            organization: OrganizationFactory(2, 'IBC', 'Org 2 EN', 'Org 2 FR', 'code org 2'),
            reportingCompany: null,
            unit: 'test',
            numberOfRequest: 11589665,
          }),
          UsageReportResponseFactory({
            userId: 'jean.luc.picard@starfleet.space',
            userName: 'Jean Luc Picard',
            organization: OrganizationFactory(3, 'ThirdParty', 'Org 3 EN', 'Org 3 FR', 'code org 3'),
            reportingCompany: null,
            unit: 'test',
            numberOfRequest: 1,
          }),
          UsageReportResponseFactory({
            userId: 'james.may@thegrandtour.uk',
            userName: 'James May',
            organization: OrganizationFactory(1, 'Carrier', 'Org 1 EN', 'Org 1 FR', 'code org 1'),
            reportingCompany: ReportingCompanyFactory(2, 1, 'code rpt 2', 'rpt Comp 2 FR', 'rpt Comp 2 EN'),
            unit: 'test',
            numberOfRequest: 1256,
          }),
          UsageReportResponseFactory({
            userId: 'linus.sebastian@lttmediagroup.ca',
            userName: 'Linus Sebastian',
            organization: OrganizationFactory(2, 'IBC', 'Org 2 EN', 'Org 2 FR', 'code org 2'),
            reportingCompany: null,
            unit: 'test',
            numberOfRequest: 985,
          }),
          UsageReportResponseFactory({
            userId: 'sigourney.weaver@weyland.yutani.corp',
            userName: 'Sigourney Weaver',
            organization: OrganizationFactory(1, 'Carrier', 'Org 1 EN', 'Org 1 FR', 'code org 1'),
            reportingCompany: ReportingCompanyFactory(2, 1, 'code rpt 2', 'rpt Comp 2 FR', 'rpt Comp 2 EN'),
            unit: 'test',
            numberOfRequest: 78563,
          }),
        ],
      });
    });
  } else {
    return requests.post('/DriverReports/UsageReport', params);
  }
};
