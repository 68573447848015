import { useEffect, useRef } from 'react';

const useAutofocus = () => {
  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current.focus();
  }, []);

  return ref;
};

export default useAutofocus;
