export interface ClaimStatus {
  code: string | null;
  descriptionEn: string | null;
  descriptionFr: string | null;
}

export const ClaimStatusFactory = (props: ClaimStatus): ClaimStatus => {
  return {
    code: props.code,
    descriptionEn: props.descriptionEn,
    descriptionFr: props.descriptionFr,
  };
};

export default ClaimStatus;
