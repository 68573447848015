/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityAdapter,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';

import { Reports } from '../axios';
import {
  CodeReferenceFactory,
  CompanyReferenceFactory,
  DriverReportOption,
  Inquiries,
  Policy,
  Report,
  ReportFactory,
} from '../Types';

// Adapter
const reportAdapter: EntityAdapter<Report> = createEntityAdapter<Report>({
  // Assume IDs are stored in a field id
  selectId: (report) => report.id,
  // Keep the "all IDs" array sorted based on creation timestamp
  sortComparer: (a, b) => {
    if (a.dln < b.dln) {
      return -1;
    }
    if (a.dln > b.dln) {
      return 1;
    }

    if (a.reportDate < b.reportDate) {
      return -1;
    }
    if (a.reportDate > b.reportDate) {
      return 1;
    }
    return 0;
  },
});

// Selectors
// Get default selects that comes with entityAdapter
const reportSelectors = reportAdapter.getSelectors((state: { report: any }) => state.report);

// map selectors if you want to have them called differently
export const { selectAll: getReport } = reportSelectors;

export const getReportsByProvinceDln = createSelector(
  [
    // First input selector: all reports
    reportSelectors.selectAll,
    // Second input selector: province value
    (state: any, province: string, dln: string) => province,
    // Third input selector: dln value
    (state: any, province: string, dln: string) => dln,
  ],
  // Output selector: receives both values
  (allReports: Array<Report>, province: string, dln: string) => {
    // Return reports based on dln and province
    return allReports.filter((report) => {
      return report.dlnJurisdiction.code === province && report.dln === dln;
    });
  }
);

//Test helper
export const CreateReportTestState = (
  reports: Array<Report>,
  isPending: boolean,
  errorCode = '',
  errorMessage = ''
) => {
  const error: SerializedError | null = errorMessage !== '' ? { code: errorCode, message: errorMessage } : null;

  const reportState = reportAdapter.getInitialState({ isPending, error });
  reports.forEach((report) => {
    reportState.ids.push(report.id);
    reportState.entities[report.id] = report;
  });

  return { report: reportState };
};

// extra reducers - async thunk
export const generateReport = createAsyncThunk(
  '/DriverReports/GenerateReport',
  async (payload: { prov: string; dln: string; comment: string; driverReportOption: DriverReportOption }) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      // testing - return default state
      return ReportFactory(
        '123',
        1,
        '1',
        25,
        new Date('2021-08-22'),
        'America/Montreal',
        new Date('2021-08-02'),
        'Jack Jackson / ABX Brokers',
        'Jack Jackson / Courtier ABX',
        'John Doe / IBC Brokers',
        'John Doe / Courtier IBC',
        CompanyReferenceFactory('12345', 'Assurances ABC', 'ABC Insurance'),
        'John A. Smith',
        'John',
        'A.',
        'Smith',
        '45 First Street, Smalltown, ON, B7B 7B7',
        'B7B 7B7',
        'S1234-15678-19123',
        CodeReferenceFactory('ON', 'Ontario', 'Ontario'),
        new Date('1980-01-31'),
        2002,
        CodeReferenceFactory('1', 'male', 'homme'),
        CodeReferenceFactory('1', 'married', 'marrié'),
        12,
        11,
        0,
        4,
        3,
        0,
        1,
        0,
        CodeReferenceFactory('1', 'yes', 'oui'),
        0,
        0,
        new Date('2021-11-15'),
        [],
        [],
        [],
        []
      );
    } else {
      const result = await Reports.generate({
        jurisdictionCode: payload.prov,
        driverLicenceNumber: payload.dln,
        comment: payload.comment,
        driverReportOption: payload.driverReportOption,
      });
      if (result.data) {
        const report = result.data as Report;
        if (report.previousInquiries) {
          report.previousInquiries = report.previousInquiries.sort((a: Inquiries, b: Inquiries) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
        }

        const sortedPolicies = report.policies.map((p: Policy) => {
          p.vehicles = p.vehicles.sort((v1, v2) => {
            return v2.modelYear - v1.modelYear;
          });
          return p;
        });
        report.policies = sortedPolicies;
        return report;
      } else {
        return null;
      }
    }
  }
);

export const regenerateReport = createAsyncThunk(
  '/DriverReports/RegenerateReport',
  async (payload: { id: string; ninetyDaysDataCorrectionId?: number }) => {
    const result = await Reports.regenerate(payload.id, payload.ninetyDaysDataCorrectionId);
    if (result.data) {
      const report = result.data as Report;
      report.previousInquiries = report.previousInquiries.sort((a: Inquiries, b: Inquiries) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      const sortedPolicies = report.policies.map((p: Policy) => {
        p.vehicles = p.vehicles.sort((v1, v2) => {
          return v2.modelYear - v1.modelYear;
        });
        return p;
      });
      report.policies = sortedPolicies;
      return report;
    } else {
      return null;
    }
  }
);

export const findReportById = createAsyncThunk(
  '/DriverReports/GetById',
  async (payload: { functionId: number; id: string }) => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      // testing - return default state
      return ReportFactory(
        '123',
        1,
        '1',
        25,
        new Date('2021-08-22'),
        'America/Montreal',
        new Date('2021-08-02'),
        'Jack Jackson / ABX Brokers',
        'Jack Jackson / Courtier ABX',
        'John Doe / IBC Brokers',
        'John Doe / Courtier IBC',
        CompanyReferenceFactory('12345', 'Assurances ABC', 'ABC Insurance'),
        'John A. Smith',
        'John',
        'A.',
        'Smith',
        '45 First Street, Smalltown, ON, B7B 7B7',
        'B7B 7B7',
        'S1234-15678-19123',
        CodeReferenceFactory('ON', 'Ontario', 'Ontario'),
        new Date('1980-01-31'),
        2002,
        CodeReferenceFactory('1', 'male', 'homme'),
        CodeReferenceFactory('1', 'married', 'marrié'),
        12,
        11,
        0,
        4,
        3,
        0,
        1,
        0,
        CodeReferenceFactory('1', 'yes', 'oui'),
        0,
        0,
        new Date('2021-11-15'),
        [],
        [],
        [],
        []
      );
    } else {
      const result = await Reports.findById(payload.functionId, payload.id);
      if (result.data) {
        const report = result.data as Report;

        report.previousInquiries = report.previousInquiries.sort((a: Inquiries, b: Inquiries) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        return report;
      } else {
        return null;
      }
    }
  }
);

// Slice
const initialState: { report: Report | null; isPending: boolean; error: SerializedError | null } = {
  report: null,
  isPending: false,
  error: null,
};

const reportSlice = createSlice({
  name: 'report',
  initialState: reportAdapter.getInitialState(initialState),
  reducers: {
    removeError(state) {
      state.error = null;
    },
    setUpdatedReport(state, action: PayloadAction<Report>) {
      reportAdapter.removeAll(state);
      reportAdapter.setOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateReport.pending, (state) => {
      // clear out previous error (if any)
      state.error = null;
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(regenerateReport.pending, (state) => {
      state.error = null;
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(findReportById.pending, (state) => {
      state.error = null;
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(generateReport.fulfilled, (state, { payload: report }) => {
      state.isPending = false;
      if (report) {
        reportAdapter.removeAll(state);
        reportAdapter.addOne(state, report);
      }
    });
    builder.addCase(regenerateReport.fulfilled, (state, { payload: report }) => {
      state.isPending = false;
      if (report) {
        reportAdapter.removeAll(state);
        reportAdapter.addOne(state, report);
      }
    });
    builder.addCase(findReportById.fulfilled, (state, { payload: report }) => {
      state.isPending = false;
      if (report) {
        reportAdapter.removeAll(state);
        reportAdapter.addOne(state, report);
      }
    });
    builder.addCase(generateReport.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }

      // TODO : Use snackbar for all error
      if (
        !action.error.message ||
        (action.error.message &&
          !action.error.message.includes('401') &&
          !action.error.message.includes('403') &&
          !action.error.message.includes('500'))
      )
        state.error = action.error;
    });
    builder.addCase(regenerateReport.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }

      // TODO : Use snackbar for all error
      if (
        !action.error.message ||
        (action.error.message &&
          !action.error.message.includes('401') &&
          !action.error.message.includes('403') &&
          !action.error.message.includes('500'))
      )
        state.error = action.error;
    });
    builder.addCase(findReportById.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { removeError, setUpdatedReport } = reportSlice.actions;

export default reportSlice.reducer;
