import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import VehicleReportSearch from '../Features/VehicleReport/VehicleReportSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import VehiclesEN from '../Medias/titles/Vehicles - EN.jpg';
// import VehiclesFR from '../Medias/titles/Vehicles - FR.jpg';
import { Functions, User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface VehicleReportProps extends RouteComponentProps {
  user: User;
}

const VehicleReport: FC<VehicleReportProps> = ({ user }) => {
  const classes = useStyles();
  // const { i18n } = useTranslation();
  const { t } = useTranslation();

  const userAccessVehicleReport = doUserHaveAccessToFeature(user, Functions.vehicleReport);

  return user ? (
    <Container className={classes.root}>
      {userAccessVehicleReport && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContainerTitle title={t('hamburgerMenu.vehicleReport')} />
              {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? VehiclesEN : VehiclesFR} /> */}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <VehicleReportSearch />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  ) : (
    <></>
  );
};

export default withRouter(VehicleReport);
