import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';

import { DashClients } from '../axios';
import { ClientType, ClientTypeFactory } from '../Types';

// Adapter
const ClientTypeAdapter = createEntityAdapter<ClientType>({
  // Assume IDs are stored in a field id
  selectId: (clientType) => clientType.id,
});

// Selectors
// Get default selects that comes with entityAdapter
const ClientTypeSelectors = ClientTypeAdapter.getSelectors((state: { clientType: any }) => state.clientType);

// map selectors if you want to have them called differently
export const { selectId: getClientTypeById } = ClientTypeAdapter;

export const GetAllClientTypes = createSelector([ClientTypeSelectors.selectAll], (clientTypes: Array<ClientType>) => {
  return clientTypes;
});

//Test helper
export const CreateClientTypeTestState = (isPending: boolean, errorCode = '', errorMessage = '') => {
  const error: SerializedError | null = errorMessage !== '' ? { code: errorCode, message: errorMessage } : null;

  const clientTypes = [
    ClientTypeFactory({ id: 6, nameEn: 'IBC', nameFr: 'BAC', canHaveSponsors: false, isModifiableOrDeletable: false }),
    ClientTypeFactory({
      id: 5,
      nameEn: 'Organization',
      nameFr: 'Organisation',
      canHaveSponsors: false,
      isModifiableOrDeletable: false,
    }),
    ClientTypeFactory({
      id: 4,
      nameEn: 'Company',
      nameFr: 'Entreprise',
      canHaveSponsors: false,
      isModifiableOrDeletable: false,
    }),
    ClientTypeFactory({
      id: 2,
      nameEn: 'Third Party',
      nameFr: 'Tiers partie',
      canHaveSponsors: false,
      isModifiableOrDeletable: true,
    }),
    ClientTypeFactory({
      id: 3,
      nameEn: 'Other',
      nameFr: 'Autres',
      canHaveSponsors: false,
      isModifiableOrDeletable: true,
    }),
    ClientTypeFactory({
      id: 1,
      nameEn: 'Broker',
      nameFr: 'Courtier',
      canHaveSponsors: true,
      isModifiableOrDeletable: true,
    }),
  ];

  const ClientTypeState = ClientTypeAdapter.getInitialState({ isPending, error });
  clientTypes.forEach((clientType) => {
    ClientTypeState.ids.push(clientType.id);
    ClientTypeState.entities[clientType.id] = clientType;
  });

  return { clientType: ClientTypeState };
};

// extra reducers - async thunk
export const getClientTypes = createAsyncThunk('/Client/ClientTypes', async () => {
  const result = await DashClients.getClientTypes();

  if (result) {
    return result;
  } else {
    return [];
  }
});

// Slice
const initialState: { isPending: boolean; error: SerializedError | null } = { isPending: false, error: null };

const ClientTypeSlice = createSlice({
  name: 'ClientType',
  initialState: ClientTypeAdapter.getInitialState(initialState),
  reducers: {
    removeError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientTypes.pending, (state) => {
      if (state.isPending === false) {
        state.isPending = true;
      }
    });
    builder.addCase(getClientTypes.fulfilled, (state, { payload: clientTypes }) => {
      state.isPending = false;
      if (clientTypes) {
        ClientTypeAdapter.removeAll(state);
        ClientTypeAdapter.addMany(state, clientTypes);
      }
    });
    builder.addCase(getClientTypes.rejected, (state, action) => {
      state.isPending = false;
      if (action.error.code === '') {
        // Create new state error and pass it to state.error
      }
      state.error = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { removeError } = ClientTypeSlice.actions;

export default ClientTypeSlice.reducer;
