import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { ClientSettingAxios, ReportingCompanies } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import { GetAllOrganizations, getOrganizations } from '../../../Slices/OrganizationSlice';
import theme from '../../../theme';
import { ModeEnum, Organization, ReportingCompany } from '../../../Types';
import { ClientSettingFormValueProps } from '../../../Types/ClientSetting';

export interface CreateProps {
  mode: ModeEnum.Create | ModeEnum.Update;
  item?: ClientSettingFormValueProps;
  onClose: () => void;
  onCreated: () => void;
  onUpdated: () => void;
}

const Create: FC<CreateProps> = ({ mode, item, onClose, onCreated, onUpdated }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));
  const [rptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);
  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);
  const [showRptCompany, setShowRptCompany] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ClientSettingFormValueProps>({
    clientId: item && item.clientId ? item.clientId : '',
    organizationId: (item && item.organizationId) || null,
    rptCompanyId: item && item.rptCompanyId ? item.rptCompanyId : null,
    userId: item && item.userId ? item.userId : null,
    isVendor: item ? item.isVendor : false,
  });
  // Fetch Orgs
  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  useEffect(() => {
    if (showRptCompany) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganizationWithoutUserFunction(formValues.organizationId!)
        .then((results) => {
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
        })
        .catch(() => {
          dispatch(addNotification(500, 'error', t('errorMessage.error500')));
          setIsLoadingRpt(false);
        });
    }
  }, [dispatch, t, formValues.organizationId, showRptCompany]);

  const isFormValid = useCallback(() => {
    if (formValues.organizationId && formValues.clientId) {
      return true;
    }
    return false;
  }, [formValues.clientId, formValues.organizationId]);

  const handleSave = async () => {
    setLoading(true);

    const params = {
      clientId: formValues.clientId,
      organizationId: formValues.organizationId!,
      reportingCompanyId: formValues.rptCompanyId,
      userId: formValues.userId,
      isVendor: formValues.isVendor,
    };

    if (mode === ModeEnum.Create) {
      await ClientSettingAxios.create(params)
        .then((result) => {
          dispatch(addNotification(200, 'success', t('successMessage.created', { resource: result.clientId })));
          onCreated();
        })
        .catch((error) => {
          const config = {
            error400: t('settings.clientSettings.error400', {
              client: params.clientId,
              org: params.reportingCompanyId
                ? i18n.language.startsWith('en')
                  ? rptCompanies?.find((x) => x.id === params.reportingCompanyId)?.nameEn
                  : rptCompanies?.find((x) => x.id === params.reportingCompanyId)?.nameFr
                : i18n.language.startsWith('en')
                ? organizations.find((x) => x.id === params.organizationId)?.nameEn
                : organizations.find((x) => x.id === params.organizationId)?.nameFr,
            }),
          };

          handleError(error, config);
        })
        .finally(() => setLoading(false));
    } else if (item) {
      await ClientSettingAxios.update(item.id ?? 0, params)
        .then((result) => {
          dispatch(addNotification(200, 'success', t('successMessage.updated', { resource: result.clientId })));
          onUpdated();
        })
        .catch(() => {
          dispatch(addNotification(500, 'error', t('errorMessage.error500')));
        })
        .finally(() => setLoading(false));
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleOrgAutocompleteChange = useCallback(
    (orgId: number | undefined) => {
      const isCarrier = organizations.find((x) => x.id === orgId)?.organizationType === 'Carrier';
      setShowRptCompany(isCarrier);
      setFormValues({ ...formValues, organizationId: orgId || null, rptCompanyId: null });
    },
    [formValues, organizations]
  );

  const handleRptAutocompleteChange = (value: ReportingCompany | null) => {
    setFormValues({ ...formValues, rptCompanyId: value ? value.id : null });
  };

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      document.getElementById('btnSave')?.click();
    }
  };

  useEffect(() => {
    if (mode === ModeEnum.Update && item) {
      handleOrgAutocompleteChange(item.organizationId ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, mode]);

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="addRenameDialogComponent"
      >
        <Typography
          variant="h5"
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
        >
          {mode === ModeEnum.Create
            ? t('settings.clientSettings.createMappingTitle')
            : t('userAdmin.companyProf.rename')}
        </Typography>
        <DialogContent sx={{ padding: theme.spacing(4) }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                noOptionsText={t('search.noOption')}
                value={
                  organizations && organizations.length > 0 && formValues.organizationId !== null
                    ? organizations?.find((org) => org.id === formValues.organizationId)
                    : null
                }
                id="organizationId"
                data-testid="organizationId"
                options={organizations}
                getOptionLabel={(option: Organization) =>
                  i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                }
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    label={t('settings.clientSettings.lblOrganization')}
                    labelGridSize={3}
                    fieldGridSize={9}
                    variant="outlined"
                    autoComplete="nope"
                    color="secondary"
                    sx={{ paddingRight: 0 }}
                    placeholder={t('settings.clientSettings.lblPlaceholderOrg')}
                  />
                )}
                onChange={(e, value) => handleOrgAutocompleteChange(value?.id)}
              />
            </Grid>
            {showRptCompany && (
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  loading={isLoadingRpt}
                  noOptionsText={t('search.noOption')}
                  value={
                    rptCompanies && rptCompanies.length > 0 && formValues.rptCompanyId !== null
                      ? rptCompanies.find((oneRpt) => oneRpt.id === formValues.rptCompanyId)
                      : null
                  }
                  id="rptCompanyId"
                  data-testid="rptCompanyId"
                  options={rptCompanies ?? []}
                  getOptionLabel={(option: ReportingCompany) =>
                    i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                  }
                  renderInput={(params) => (
                    <DashCustomInput
                      {...params}
                      label={t('settings.clientSettings.lblCompany')}
                      labelGridSize={3}
                      fieldGridSize={9}
                      variant="outlined"
                      autoComplete="nope"
                      color="secondary"
                      sx={{ paddingRight: 0 }}
                      placeholder={t('settings.clientSettings.lblPlaceholderCompany')}
                    />
                  )}
                  onChange={(e, value) => handleRptAutocompleteChange(value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <DashCustomInput
                label={t('settings.clientSettings.lblClientId')}
                labelGridSize={3}
                fieldGridSize={9}
                id="hint"
                value={formValues.clientId}
                fullWidth
                variant="outlined"
                color="secondary"
                placeholder={t('settings.clientSettings.clientIdPlaceholder')}
                autoComplete="nope"
                sx={{ paddingRight: '0' }}
                onChange={(e) => setFormValues({ ...formValues, clientId: e.target.value })}
                inputProps={{ 'data-testid': 'clientId' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      textAlign: 'end',
                    }}
                  >
                    {t('settings.clientSettings.isVendor')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Checkbox
                    id="isVendor"
                    size="small"
                    checked={formValues.isVendor}
                    onChange={(e) => setFormValues({ ...formValues, isVendor: e.target.checked })}
                    data-testid="isVendor"
                    sx={{
                      textAlign: 'left',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid="cancel"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid="save"
                variant="contained"
                color="primary"
                id="btnSave"
                disabled={!isFormValid()}
                onClick={() => handleSave()}
              >
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {loading && <Loader open={true} />}
      </Dialog>
    </>
  );
};

export default Create;
