import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ReportingCompanies, Units, Users } from '../../../../axios';
import ConfirmDialogCommon from '../../../../Components/ConfirmDialog';
import { ConfirmDialogProps as ConfirmDialogPropsCommon } from '../../../../Components/ConfirmDialog';
import DashCustomInput from '../../../../Components/DashCustomInput';
import DashCustomInputTopAlign from '../../../../Components/DashCustomInputTopAlign';
import Loader from '../../../../Components/Loader';
import myInitObject from '../../../../Helpers/Constants';
import { EMAIL_REGEX } from '../../../../Helpers/Regex';
import { sortOrganizations, sortRptCompanies, sortUnits } from '../../../../Helpers/SortHelpers';
import { formatPhone } from '../../../../Helpers/StringHelper';
import useErrorHandler from '../../../../Hooks/UseErrorHandler';
import { getConnectedUser } from '../../../../Slices/UserSlice';
import theme from '../../../../theme';
import {
  Functions,
  ModeEnum,
  ModeType,
  Organization,
  ReportingCompany,
  Unit,
  UserCopyProfile,
  UserFunction,
} from '../../../../Types';
import { UserTypeEnum, UserTypeEnumList, UserTypeOption } from '../../../../Types/Enums';
import { FormValuesCreateEditUserProfileProps } from './CreateEditUserProfile';

const useStyles = makeStyles(() => ({
  customLabel: {
    '& .MuiAutocomplete-endAdornment': {
      position: 'absolute',
      right: '2',
      top: '7px',
    },
  },
}));
interface UserInfosProps {
  mode: ModeType;
  formValues: FormValuesCreateEditUserProfileProps;
  setFormValues: Dispatch<SetStateAction<FormValuesCreateEditUserProfileProps>>;
  organizations: Array<Organization>;
  isLoadingOrganizations: boolean;
  isIbcOrThirdPartySelected: boolean;
  firstNameFieldError: string;
  middleNameFieldError: string;
  lastNameFieldError: string;
  organizationFieldError: string;
  employedAtCompanyFieldError: string;
  employedAtComp: number | null;
  setEmployedAtComp: Dispatch<SetStateAction<number | null>>;
  employedAtUnit: number | null;
  setEmployedAtUnit: Dispatch<SetStateAction<number | null>>;
  isUserIdError: boolean;
  isEmailError: boolean;
  isDuplicateUserIdError: boolean;
  isDuplicateEmailError: boolean;
  setIsNoSponsorError: Dispatch<SetStateAction<boolean>>;
  isPermittedEmailDomainError: boolean;
  isPermittedUserIdDomainError: boolean;
  isCompanyNotIbccrError: boolean;
  isCompanyNotCompanycrError: boolean;
  isNoSponsorError: boolean;
  isMfaFieldError: boolean;
}

const UserInfos: FC<UserInfosProps> = ({
  mode,
  formValues,
  setFormValues,
  organizations,
  isLoadingOrganizations,
  isIbcOrThirdPartySelected,
  firstNameFieldError,
  middleNameFieldError,
  lastNameFieldError,
  organizationFieldError,
  employedAtCompanyFieldError,
  employedAtComp,
  setEmployedAtComp,
  employedAtUnit,
  setEmployedAtUnit,
  isUserIdError,
  isEmailError,
  isDuplicateUserIdError,
  isDuplicateEmailError,
  isNoSponsorError,
  isPermittedEmailDomainError,
  isPermittedUserIdDomainError,
  isCompanyNotIbccrError,
  isCompanyNotCompanycrError,
  isMfaFieldError,
}) => {
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const connectedUser = useSelector((state: { user: any }) => getConnectedUser(state));

  const [rptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);
  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);

  const [fetchedUnits, setFetchedUnits] = useState<Array<Unit> | null>(null);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);

  const [usersCopyForProfile, setUsersForCopyForProfile] = useState<Array<UserCopyProfile> | null>(null);
  const [isLoadingUsersForCopyProfile, setIsLoadingUsersForCopyProfile] = useState<boolean>(false);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);

  const [confirmDialogCommonOpen, setConfirmDialogCommonOpen] = useState<boolean>(false);
  const [confirmDialogPropsCommon, setConfirmDialogPropsCommon] = useState<ConfirmDialogPropsCommon | undefined>(
    undefined
  );
  const [userCopyProfile, setUserCopyProfile] = useState<UserCopyProfile | null>(null);

  const isFirstRender = useRef<boolean>(true);
  const classes = useStyles();

  // Fetch all rpt companies of a carrier when a carrier organization is selected to populate the autocomplete
  useEffect(() => {
    if (!isIbcOrThirdPartySelected && formValues.organizationId) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganization(Functions.userAdministration, formValues.organizationId)
        .then((results) => {
          if (results.length === 1) setEmployedAtComp(results[0].id);
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
  }, [formValues.organizationId, handleError, isIbcOrThirdPartySelected, setEmployedAtComp]);

  // Fetch the units
  useEffect(() => {
    if (formValues.organizationId && (isIbcOrThirdPartySelected || (!isIbcOrThirdPartySelected && employedAtComp))) {
      setIsLoadingUnits(true);
      Units.getUnitsByCompanyId(Functions.userAdministration, formValues.organizationId!, formValues.rptCompanyId)
        .then((results) => {
          if (mode === ModeEnum.Update && !formValues.unitId && isFirstRender.current) {
            isFirstRender.current = false;
          }
          if (isFirstRender.current) {
            isFirstRender.current = false;
          }
          setFetchedUnits(results);
          setIsLoadingUnits(false);
        })
        .catch((error) => {
          handleError(error, { error401: t('userProfile.errorMessage.userInfos.error401Units') });
          setIsLoadingUnits(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employedAtComp, formValues.organizationId, formValues.rptCompanyId, isIbcOrThirdPartySelected]);

  const sort = useCallback(
    (a: any, b: any, type: string): number => {
      switch (type) {
        case 'organization':
          return sortOrganizations(a, b, i18n);
        case 'reportingcompany':
          return sortRptCompanies(a, b, i18n);
        case 'unit':
          return sortUnits(a, b);
        default:
          return 0;
      }
    },
    [i18n]
  );

  const generateIbccrUserId = (email: string): string => {
    return `${email.replace('@', '.')}@${myInitObject.ibccrDomain}`;
  };

  const handleUserTypeChange = (value: UserTypeOption | null) => {
    if (value) {
      if (value.value === UserTypeEnum.ibccr) {
        setFormValues({
          ...formValues,
          userId: formValues.email ? generateIbccrUserId(formValues.email) : '',
          userType: value.value,
        });
      } else {
        setFormValues({
          ...formValues,
          userId: '',
          userType: value.value,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        userType: null,
      });
    }
  };

  // Loading profiles for CopyProfile field
  const handleEmailOnBlur = useCallback(() => {
    if (
      formValues.email &&
      (formValues.email.match(/@/g) || []).length === 1 &&
      formValues.email.includes('.') &&
      formValues.email.split(' ').length - 1 === 0 &&
      EMAIL_REGEX.test(formValues.email)
    ) {
      setIsLoadingUsersForCopyProfile(true);
      Users.usersProfiles(formValues.email)
        .then((results) => {
          setUsersForCopyForProfile(results);
          setIsLoadingUsersForCopyProfile(false);
        })
        .catch((error) => {
          handleError(error, { error401: t('userProfile.errorMessage.userInfos.error401CopyProf') });
          setIsLoadingUsersForCopyProfile(false);
        });
    }
  }, [formValues.email, handleError, t]);

  useEffect(() => {
    if (mode === ModeEnum.Update) {
      handleEmailOnBlur();
    }
  }, [handleEmailOnBlur, mode]);

  const handleUserCopyProfileChange = (value: UserCopyProfile | null) => {
    setUserCopyProfile(value);
    if (value) {
      setConfirmDialogPropsCommon({
        contentText: t('userProfile.create.contentConfirmCopyProfile'),
        isDelete: false,
        buttonText: '',
        title: t('userProfile.create.titleConfirmCopyProfile'),
        onAction: (isConfirmed: boolean) => {
          if (isConfirmed) {
            setIsLoadingUser(true);
            Users.findById(value.id)
              .then((result) => {
                result.userFunctions.forEach((u) => {
                  u.userId = formValues.id;
                });
                result.userDriverReportOptions.forEach((u) => {
                  u.userId = formValues.id;
                });
                setFormValues({
                  ...formValues,
                  assignedOrganizationsIds: result.assignedOrganizationsIds,
                  organizationId: result.organizationId,
                  rptCompanyId: result.reportingCompanyId,
                  unitId: result.unitId,
                  userFunctions: result.userFunctions,
                  userDriverReportOptions: result.userDriverReportOptions,
                });
                setEmployedAtComp(result.reportingCompanyId);
                setEmployedAtUnit(result.unitId);
                setIsLoadingUser(false);
              })
              .catch((error) => {
                handleError(error, { error403: t('userAdmin.error403') });
                setIsLoadingUser(false);
              });
          } else {
            setUserCopyProfile(null);
          }

          setConfirmDialogCommonOpen(false);
          setConfirmDialogPropsCommon(undefined);
        },
      });
      setConfirmDialogCommonOpen(true);
    }
  };

  const handleRptAutocompleteChange = (value: ReportingCompany | null) => {
    setFormValues({ ...formValues, rptCompanyId: value ? value.id : null, unitId: null });
    setEmployedAtComp(value ? value.id : null);
    setEmployedAtUnit(null);
  };

  const handleOrgAutocompleteChange = (value: Organization | null) => {
    setFormValues({
      ...formValues,
      organizationId: value ? value.id : null,
      rptCompanyId: null,
      unitId: null,
    });
    setEmployedAtComp(null);
    setEmployedAtUnit(null);
  };

  const handleUnitAutocompleteChange = (value: Unit | null) => {
    setFormValues({ ...formValues, unitId: value ? value.id : null });
    setEmployedAtUnit(value ? value.id : null);
  };

  const handleUserIdChange = (e: any) => {
    const value = e.target.value?.trim() as string;
    setFormValues({ ...formValues, userId: value });
  };

  const isValidEmail = useCallback((value: string) => {
    if (value && !EMAIL_REGEX.test(value)) {
      return false;
    } else {
      return true;
    }
  }, []);

  const handleEmailChange = (e: any) => {
    const value = e.target.value?.trim() as string;

    if (formValues.userType === UserTypeEnum.ibccr) {
      setFormValues({
        ...formValues,
        userId: generateIbccrUserId(value),
        email: value,
      });
    } else {
      setFormValues({ ...formValues, email: value });
    }
  };

  const isValidMfaPhone = useCallback((value: string | null) => {
    if (value && value.length < 12) {
      return false;
    } else {
      return true;
    }
  }, []);

  const handleAssignedOrganizationsAutocompleteChange = (value: Array<Organization>) => {
    if (formValues.userFunctions && formValues.userFunctions.length > 0) {
      if (value.length > 0) {
        const newUserFunctions: Array<UserFunction> = [];
        formValues.userFunctions.forEach((oneUserFunc) => {
          newUserFunctions.push({
            ...oneUserFunc,
            reportingCompanies: oneUserFunc.reportingCompanies
              ? oneUserFunc.reportingCompanies.filter((rc) => value.some((val) => val.id === rc.organizationId))
              : [],
          });
        });
        setFormValues({
          ...formValues,
          userFunctions: newUserFunctions,
          assignedOrganizationsIds: value.map((o) => o.id),
          userDriverReportOptions: formValues.userDriverReportOptions.filter((udro) =>
            value.some((oneOrg) => oneOrg.id === udro.driverReportOption.organizationId)
          ),
        });
      } else {
        setFormValues({
          ...formValues,
          userFunctions: [],
          assignedOrganizationsIds: [],
          userDriverReportOptions: [],
        });
      }
    } else {
      setFormValues({ ...formValues, assignedOrganizationsIds: value.map((o) => o.id) });
    }
  };

  const handleInputChange = (e: any) => {
    if (e.target.name === 'mfaPhone') {
      setFormValues({ ...formValues, [e.target.name]: formatPhone(e.target.value) });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid container item xs={9} md={11} lg={10} xl={8} spacing={1}>
          <Grid item xs={12}>
            {mode === ModeEnum.Create ? (
              <Autocomplete
                fullWidth
                noOptionsText={t('search.noOption')}
                value={
                  formValues.userType !== null ? UserTypeEnumList.find((u) => u.value === formValues.userType) : null
                }
                id="userType"
                data-testid="userType"
                options={UserTypeEnumList}
                getOptionLabel={(option: UserTypeOption) => t(option.label)}
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    label={t('userProfile.create.lblAccessType')}
                    labelGridSize={3}
                    fieldGridSize={6}
                    variant="outlined"
                    autoComplete="nope"
                    color="secondary"
                    sx={{ paddingRight: 0 }}
                    placeholder={t('userProfile.create.lblPlaceholderAccessType')}
                    isRequired={true}
                  />
                )}
                onChange={(_, value) => handleUserTypeChange(value)}
                disabled={formValues.isDisabled || !connectedUser.isIbc}
              />
            ) : (
              <Grid item xs={12} margin={1}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item xs={3} sx={{ marginRight: theme.spacing(2) }}>
                    <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                      {t('userProfile.create.lblAccessType')}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>
                      {t(UserTypeEnumList.find((u) => u.value === formValues.userType)?.label ?? '')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {mode === ModeEnum.Create ? (
              <DashCustomInput
                label={t('userProfile.create.lblUserId')}
                value={formValues.userId}
                fullWidth
                autoFocus
                labelGridSize={3}
                fieldGridSize={6}
                variant="outlined"
                autoComplete="nope"
                color="secondary"
                sx={{ paddingRight: 0 }}
                data-testid="userId"
                placeholder={t('userProfile.create.lblPlaceholderUserId')}
                onChange={(e) => handleUserIdChange(e)}
                error={
                  (isUserIdError && (!formValues.userId?.trim() || !isValidEmail(formValues.userId))) ||
                  isPermittedUserIdDomainError ||
                  isDuplicateUserIdError
                }
                helperText={
                  (isUserIdError &&
                    ((!formValues.userId?.trim() && t('userProfile.errorMessage.blankFieldError')) ||
                      (!isValidEmail(formValues.userId) && t('userProfile.errorMessage.invalidUserIdError')))) ||
                  (isPermittedUserIdDomainError && t('userProfile.errorMessage.permittedUserIdDomainError')) ||
                  (isDuplicateUserIdError && t('userProfile.errorMessage.duplicateUserIdError'))
                }
                inputProps={{
                  maxLength: 100,
                }}
                isRequired={true}
                disabled={formValues.userType === UserTypeEnum.ibccr}
              />
            ) : (
              <Grid item xs={12} margin={1}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item xs={3} sx={{ marginRight: theme.spacing(2) }}>
                    <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                      {t('userProfile.create.lblUserId')}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl error={isPermittedUserIdDomainError || isDuplicateUserIdError}>
                      <Typography>{formValues.userId}</Typography>
                      <FormHelperText sx={{ marginLeft: 0 }}>
                        {(isPermittedUserIdDomainError && t('userProfile.errorMessage.permittedUserIdDomainError')) ||
                          (isDuplicateUserIdError && t('userProfile.errorMessage.duplicateUserIdError'))}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                <Typography
                  color={formValues.isDisabled ? theme.palette.text.disabled : undefined}
                  sx={{ fontWeight: 700, display: 'inline' }}
                >
                  {t('userProfile.create.lblNames')}
                </Typography>
                {!formValues.isDisabled && (
                  <Typography color={theme.palette.primary.main} sx={{ fontWeight: 700, display: 'inline' }}>
                    &nbsp;*
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item xs={4}>
                    <TextField
                      value={formValues.firstName}
                      fullWidth
                      autoFocus={mode === ModeEnum.Update ? true : false}
                      label={undefined}
                      size="small"
                      margin="none"
                      variant="outlined"
                      autoComplete="nope"
                      color="secondary"
                      sx={{ paddingRight: 0.5 }}
                      data-testid="firstName"
                      name="firstName"
                      error={!!firstNameFieldError.trim()}
                      helperText={firstNameFieldError}
                      placeholder={t('userProfile.create.lblPlaceholderFirstName')}
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        maxLength: 50,
                      }}
                      disabled={formValues.isDisabled}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={formValues.middleName}
                      fullWidth
                      label={undefined}
                      size="small"
                      margin="none"
                      variant="outlined"
                      autoComplete="nope"
                      color="secondary"
                      sx={{ paddingRight: 0.5 }}
                      data-testid="middleName"
                      name="middleName"
                      helperText={middleNameFieldError}
                      placeholder={t('userProfile.create.lblPlaceholderMiddleName')}
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        maxLength: 50,
                      }}
                      disabled={formValues.isDisabled}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={formValues.lastName}
                      fullWidth
                      label={undefined}
                      size="small"
                      margin="none"
                      variant="outlined"
                      autoComplete="nope"
                      color="secondary"
                      sx={{ paddingRight: 0 }}
                      data-testid="lastName"
                      name="lastName"
                      error={!!lastNameFieldError.trim()}
                      helperText={lastNameFieldError}
                      placeholder={t('userProfile.create.lblPlaceholderLastName')}
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        maxLength: 50,
                      }}
                      disabled={formValues.isDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {mode === ModeEnum.Create ? (
              <DashCustomInput
                label={t('userProfile.create.lblEmail')}
                value={formValues.email}
                fullWidth
                id="email"
                labelGridSize={3}
                fieldGridSize={6}
                variant="outlined"
                autoComplete="nope"
                color="secondary"
                sx={{ paddingRight: 0 }}
                data-testid="workEmail"
                placeholder={t('userProfile.create.lblPlaceholderEmail')}
                onChange={(e) => handleEmailChange(e)}
                onBlur={handleEmailOnBlur}
                error={
                  (isEmailError && (!formValues.email?.trim() || !isValidEmail(formValues.email))) ||
                  isPermittedEmailDomainError ||
                  isDuplicateEmailError
                }
                helperText={
                  (isEmailError &&
                    ((!formValues.email?.trim() && t('userProfile.errorMessage.blankFieldError')) ||
                      (!isValidEmail(formValues.email) && t('userProfile.errorMessage.invalidEmailError')))) ||
                  (isPermittedEmailDomainError && t('userProfile.errorMessage.permittedEmailDomainError')) ||
                  (isDuplicateEmailError && t('userProfile.errorMessage.emailInUse'))
                }
                inputProps={{
                  maxLength: 100,
                }}
                isRequired={true}
                disabled={formValues.isDisabled}
              />
            ) : (
              <Grid item xs={12} margin={1}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item xs={3} sx={{ marginRight: theme.spacing(2) }}>
                    <Typography sx={{ fontWeight: 700, textAlign: 'end' }}>
                      {t('userProfile.create.lblEmail')}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl error={isPermittedEmailDomainError || isDuplicateEmailError}>
                      <Typography>{formValues.email}</Typography>
                      <FormHelperText sx={{ marginLeft: 0 }}>
                        {(isPermittedEmailDomainError && t('userProfile.errorMessage.permittedEmailDomainError')) ||
                          (isDuplicateEmailError && t('userProfile.errorMessage.emailInUse'))}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {formValues.userType === UserTypeEnum.ibccr && (
            <Grid item xs={12}>
              <DashCustomInput
                label={t('userProfile.create.lblmfaPhone')}
                value={formValues.mfaPhone}
                fullWidth
                id="mfaPhone"
                name="mfaPhone"
                labelGridSize={3}
                fieldGridSize={6}
                inputProps={{ maxLength: 12 }}
                variant="outlined"
                autoComplete="nope"
                color="secondary"
                sx={{ paddingRight: 0 }}
                data-testid="mfaPhone"
                placeholder={t('userProfile.create.lblPlaceholderMfaPhone')}
                onChange={(e) => handleInputChange(e)}
                error={isMfaFieldError && (!formValues.mfaPhone?.trim() || !isValidMfaPhone(formValues.mfaPhone))}
                helperText={
                  isMfaFieldError &&
                  ((!formValues.mfaPhone?.trim() && t('userProfile.errorMessage.blankFieldError')) ||
                    (!isValidMfaPhone(formValues.mfaPhone) && t('userProfile.errorMessage.mfaPhoneError')))
                }
                isRequired={formValues.userType === UserTypeEnum.ibccr ? true : false}
                disabled={formValues.isDisabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              loading={isLoadingUser}
              loadingText={t('loading')}
              value={userCopyProfile}
              noOptionsText={t('search.noOption')}
              id="copyFrom"
              data-testid="copyFrom"
              options={usersCopyForProfile ?? []}
              getOptionLabel={(option: UserCopyProfile) => `${option.userId}`}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  label={t('userProfile.create.lblCopyFrom')}
                  fullWidth
                  labelColor={theme.palette.primary.main}
                  labelGridSize={3}
                  fieldGridSize={6}
                  variant="outlined"
                  autoComplete="nope"
                  color="secondary"
                  sx={{ paddingRight: 0 }}
                  placeholder={t('userProfile.create.lblPlaceholderCopy')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingUsersForCopyProfile ? (
                          <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, value) => handleUserCopyProfileChange(value)}
              disabled={!formValues.email?.trim() || !isValidEmail || formValues.isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              loading={isLoadingOrganizations}
              loadingText={t('loading')}
              noOptionsText={t('search.noOption')}
              value={
                formValues.organizationId !== null
                  ? organizations.find((oneOrg: Organization) => oneOrg.id === formValues.organizationId)
                  : null
              }
              id="organizationId"
              data-testid="organizationId"
              options={organizations.sort((a, b) => sort(a, b, 'organization'))}
              getOptionLabel={(option: Organization) =>
                i18n.language.startsWith('en') ? option.nameEn : option.nameFr
              }
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  label={t('userProfile.create.lblEmployedAtOrg')}
                  labelGridSize={3}
                  fieldGridSize={8}
                  variant="outlined"
                  autoComplete="nope"
                  color="secondary"
                  sx={{ paddingRight: 0 }}
                  placeholder={t('userProfile.create.lblPlaceholderEmpAtOrg')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingOrganizations ? (
                          <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  error={!!organizationFieldError || isNoSponsorError}
                  helperText={
                    (organizationFieldError && t(organizationFieldError)) ||
                    (isNoSponsorError && t('userProfile.errorMessage.noSponsor'))
                  }
                  isRequired={true}
                />
              )}
              onChange={(_, value) => handleOrgAutocompleteChange(value)}
              disabled={formValues.isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            {!isIbcOrThirdPartySelected ? (
              <Autocomplete
                fullWidth
                loading={isLoadingRpt}
                loadingText={t('loading')}
                noOptionsText={t('search.noOption')}
                value={
                  employedAtComp !== null && rptCompanies
                    ? rptCompanies.find((oneRpt) => oneRpt.id === employedAtComp)
                    : null
                }
                id="rptCompanyId"
                data-testid="rptCompanyId"
                options={!rptCompanies ? [] : rptCompanies.sort((a, b) => sort(a, b, 'reportingcompany'))}
                getOptionLabel={(option: ReportingCompany) =>
                  i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                }
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    label={t('userProfile.create.lblEmployedAtCompany')}
                    labelGridSize={3}
                    fieldGridSize={8}
                    variant="outlined"
                    autoComplete="nope"
                    color="secondary"
                    sx={{ paddingRight: 0 }}
                    placeholder={t('userProfile.create.lblPlaceholderEmpAtCom')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingRpt ? (
                            <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    error={!!employedAtCompanyFieldError || isCompanyNotIbccrError || isCompanyNotCompanycrError}
                    helperText={
                      (employedAtCompanyFieldError && t(employedAtCompanyFieldError)) ||
                      (isCompanyNotIbccrError && t('userProfile.errorMessage.companyNotIbccrError')) ||
                      (isCompanyNotCompanycrError && t('userProfile.errorMessage.companyNotCompanycrError'))
                    }
                    isRequired={!isIbcOrThirdPartySelected}
                  />
                )}
                onChange={(_, value) => handleRptAutocompleteChange(value)}
                disabled={isIbcOrThirdPartySelected || !formValues.organizationId || formValues.isDisabled}
                disableClearable={rptCompanies != null && rptCompanies.length === 1}
              />
            ) : (
              /* Dummy field for company when IBC or 3rd party */
              <DashCustomInput
                fullWidth
                id="companyHolderIbc3rdPart"
                value={
                  formValues.organizationId
                    ? i18n.language.startsWith('en')
                      ? organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameEn
                      : organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameFr
                    : ''
                }
                label={t('userProfile.create.lblEmployedAtCompany')}
                labelGridSize={3}
                fieldGridSize={8}
                variant="outlined"
                color="secondary"
                inputProps={{
                  readOnly: true,
                }}
                error={isCompanyNotIbccrError || isCompanyNotCompanycrError}
                helperText={
                  (isCompanyNotIbccrError && t('userProfile.errorMessage.companyNotIbccrError')) ||
                  (isCompanyNotCompanycrError && t('userProfile.errorMessage.companyNotCompanycrError'))
                }
                disabled={!formValues.organizationId || formValues.isDisabled}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              loading={isLoadingUnits}
              loadingText={t('loading')}
              noOptionsText={t('search.noOption')}
              value={
                employedAtUnit !== null && fetchedUnits
                  ? fetchedUnits.find((oneUnit) => oneUnit.id === employedAtUnit)
                  : !employedAtUnit && formValues.unitId !== null && fetchedUnits
                  ? fetchedUnits.find((oneUnit) => oneUnit.id === formValues.unitId)
                  : null
              }
              id="unitId"
              data-testid="unitId"
              options={fetchedUnits ? fetchedUnits.sort((a, b) => sort(a, b, 'unit')) : []}
              getOptionLabel={(option: Unit) => (i18n.language.startsWith('en') ? option.nameEn : option.nameFr)}
              renderInput={(params) => (
                <DashCustomInput
                  {...params}
                  label={t('userProfile.create.lblEmployedAtUnit')}
                  labelGridSize={3}
                  fieldGridSize={8}
                  variant="outlined"
                  autoComplete="nope"
                  color="secondary"
                  sx={{ paddingRight: 0 }}
                  placeholder={
                    fetchedUnits === null || fetchedUnits.length < 1
                      ? undefined
                      : t('userProfile.create.lblPlaceholderEmpAtUnit')
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingUnits ? (
                          <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(e, value) => handleUnitAutocompleteChange(value)}
              disabled={
                !formValues.organizationId ||
                (!isIbcOrThirdPartySelected && !employedAtComp) ||
                formValues.isDisabled ||
                fetchedUnits === null ||
                fetchedUnits.length < 1
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              className={classes.customLabel}
              fullWidth
              size="small"
              loading={isLoadingRpt}
              loadingText={t('loading')}
              autoComplete={false}
              noOptionsText={t('search.noOption')}
              multiple={true}
              limitTags={3}
              value={organizations.filter(
                (oneOrg: Organization) => formValues.assignedOrganizationsIds.indexOf(oneOrg.id) != -1
              )}
              id="assignedOrganizationId"
              data-testid="assignedOrganizationId"
              options={organizations ?? []}
              getOptionLabel={(option: Organization) =>
                i18n.language.startsWith('en') ? option.nameEn : option.nameFr
              }
              renderInput={(params) => (
                <DashCustomInputTopAlign
                  {...params}
                  label={t('userProfile.create.lblAssignedOrg')}
                  labelGridSize={3}
                  fieldGridSize={8}
                  variant="outlined"
                  autoComplete="nope"
                  color="secondary"
                  sx={{ paddingRight: 0 }}
                  placeholder={t('userProfile.create.lblPlaceholderAssignOrg')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingRpt ? (
                          <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    classes: {
                      adornedEnd: classes.customLabel,
                    },
                  }}
                />
              )}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  // eslint-disable-next-line
                  <Chip
                    {...getTagProps({ index })}
                    variant="outlined"
                    label={option.code}
                    size="small"
                    data-testid={`assign-tag-${index}`}
                  />
                ))
              }
              onChange={(e, value) => handleAssignedOrganizationsAutocompleteChange(value)}
              disabled={formValues.isDisabled}
            />
          </Grid>
        </Grid>
      </Grid>
      {confirmDialogCommonOpen && confirmDialogPropsCommon && <ConfirmDialogCommon {...confirmDialogPropsCommon} />}
      <Loader open={isLoadingUser} />
    </>
  );
};

export default UserInfos;
