import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { isAfter, isBefore, isSameDay } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import { DataCorrection } from '../../../../axios';
import DashCustomInput from '../../../../Components/DashCustomInput';
import Loader from '../../../../Components/Loader';
import { DATE_FORMAT, DATE_MASK } from '../../../../Helpers/Constants';
import {
  createDateIgnoreTime,
  formatDateToLocalTime,
  getDateIgnoreTime,
  getDateStringWithoutTime,
} from '../../../../Helpers/DateHelper';
import useErrorHandler from '../../../../Hooks/UseErrorHandler';
import { fieldNameCorrection } from '../../../../Pages/Report';
import { addNotification } from '../../../../Slices/NotificationSlice';
import { setUpdatedReport } from '../../../../Slices/ReportSlice';
import theme from '../../../../theme';
import { Policy, PolicyCorrectionType, ReactLocationState, ReferenceType } from '../../../../Types';

interface EditDialogPolicyProps {
  title: string;
  value?: string | null;
  fieldName: fieldNameCorrection;
  policy: Policy;
  reportId: string;
  operatorKey: number;
  onClose: () => void;
}

const EditDialogPolicy: FC<EditDialogPolicyProps> = ({
  title,
  value,
  reportId,
  operatorKey,
  policy,
  fieldName,
  onClose,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { state } = useLocation<ReactLocationState>();

  const [newValue, setNewValue] = useState<string | Date | null>(value ?? null);
  const [newValueAutocompStatus, setNewValueAutocompStatus] = useState<ReferenceType | null>(null);

  const [policyStatusesOptions, setPolicyStatusesOptions] = useState<ReferenceType[] | null>(null);

  const [errorField, setErrorField] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    DataCorrection.getPolicyStatuses()
      .then((results) => {
        const filteredOptions = results.filter(
          (oneRes) => oneRes.descriptionEn.toLowerCase().includes('cancel') || oneRes.code === policy.status.code
        );
        setPolicyStatusesOptions(filteredOptions);

        setNewValueAutocompStatus(results.find((onePol) => onePol.code === policy.status.code) ?? null);

        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  }, [handleError, policy.status.code]);

  const validateValue = useCallback(() => {
    if (fieldName === 'cancellationDate') {
      const cancelDte = getDateIgnoreTime(new Date(getDateStringWithoutTime(newValue as Date)));
      const effectiveDte = getDateIgnoreTime(createDateIgnoreTime(policy.effectiveDate));
      const expiryDte = getDateIgnoreTime(createDateIgnoreTime(policy.expiryDate));
      if (newValue === null) {
        setErrorField(t('report.dataCorrection.errors.cancellationDateEmpty'));
        return false;
      } else if (isNaN(new Date(newValue as string).valueOf())) {
        setErrorField(t('report.dataCorrection.errors.cancellationDateInvalid'));
        return false;
      } else if (policy.effectiveDate && isBefore(cancelDte, effectiveDte)) {
        setErrorField(t('report.dataCorrection.errors.cancelDteBeforeEffDte'));
        return false;
      } else if (policy.expiryDate && isAfter(cancelDte, expiryDte)) {
        setErrorField(t('report.dataCorrection.errors.cancelDteAfterExpDte'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else if (fieldName === 'status') {
      if (newValueAutocompStatus === null) {
        setErrorField(t('report.dataCorrection.errors.policyStatusEmpty'));
        return false;
      } else {
        setErrorField(null);
        return true;
      }
    } else return true;
  }, [fieldName, newValue, policy.effectiveDate, policy.expiryDate, newValueAutocompStatus, t]);

  const handleSave = () => {
    if (validateValue()) {
      const params: PolicyCorrectionType = {
        reportId: reportId,
        operatorKey: operatorKey,
        policyKey: policy.policyKey,
        cancellationDate:
          fieldName === 'cancellationDate'
            ? formatDateToLocalTime(newValue as string, false)
            : policy.cancellationDate
            ? formatDateToLocalTime(policy.cancellationDate, false)
            : null,
        policyStatusCode: newValueAutocompStatus ? newValueAutocompStatus.code : '0',
        policyStatusId: newValueAutocompStatus ? newValueAutocompStatus.id : 0,
      };

      setLoading(true);
      DataCorrection.updatePolicy(params)
        .then(async (result) => {
          dispatch(setUpdatedReport(result));
          dispatch(addNotification(200, 'error', t('report.dataCorrection.success200')));
          setLoading(false);
          onClose();

          history.replace(`/reports/${result.id}`, state);
        })
        .catch((error) => {
          handleError(error);
          setLoading(false);
        });
    }
  };

  const handleAutoCompChange = (value: ReferenceType | null) => {
    setNewValueAutocompStatus(value);
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
      >{`${title}`}</Typography>
      <DialogContent sx={{ padding: theme.spacing(4) }}>
        <Grid container>
          {fieldName === 'cancellationDate' ? (
            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={i18n.language.startsWith('en') ? enLocale : frLocale}
              >
                <DatePicker
                  clearable
                  mask={DATE_MASK}
                  inputFormat={DATE_FORMAT}
                  minDate={getDateIgnoreTime(createDateIgnoreTime(policy.effectiveDate))}
                  maxDate={getDateIgnoreTime(createDateIgnoreTime(policy.expiryDate))}
                  value={newValue}
                  onChange={(newValue) => {
                    setNewValue(newValue);
                  }}
                  renderInput={(params) => (
                    <DashCustomInput
                      {...params}
                      fullWidth
                      label={undefined}
                      fieldGridSize={12}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      variant="outlined"
                      color="secondary"
                      error={!!errorField}
                      helperText={errorField}
                      data-testid="datePickerDialog"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            fieldName === 'status' && (
              <Autocomplete
                fullWidth
                value={newValueAutocompStatus}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                getOptionLabel={(option) =>
                  i18n.language.startsWith('en') ? option.descriptionEn : option.descriptionFr
                }
                id="status"
                options={policyStatusesOptions ?? []}
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    fieldGridSize={12}
                    variant="outlined"
                    color="secondary"
                    data-testid="genderAutocomplete"
                    error={!!errorField}
                    helperText={errorField}
                  />
                )}
                onChange={(_, value) => {
                  handleAutoCompChange(value);
                }}
                noOptionsText={t('search.noOption')}
                loading={loading}
                data-testid="statusAutocomplete"
              />
            )
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
          <Grid item xs={4}>
            <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={() => onClose()}>
              {t('dialog.cancel')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              data-testid="accept"
              variant="contained"
              color="primary"
              onClick={handleSave}
              // disabled={loading || !newValueAutocompStatus}
              disabled={
                loading ||
                (fieldName === 'status' &&
                  (!newValueAutocompStatus || newValueAutocompStatus?.code === policy.status.code)) ||
                (fieldName === 'cancellationDate' &&
                  (!newValue ||
                    (!!policy.cancellationDate &&
                      isSameDay(
                        getDateIgnoreTime(new Date(getDateStringWithoutTime(newValue as Date))),
                        getDateIgnoreTime(createDateIgnoreTime(policy.cancellationDate))
                      ))))
              }
            >
              {t('dialog.save')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {loading && <Loader open={true} />}
    </>
  );
};

export default EditDialogPolicy;
