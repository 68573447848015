import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

// import debounce from 'lodash.debounce';
import { ReportingCompanies, UserAccessReport } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import ExportCsv from '../../Components/ExportCsv';
import Loader from '../../Components/Loader';
import Message from '../../Components/Message';
import { DATE_FORMAT, DATE_MASK } from '../../Helpers/Constants';
import { getDateFromNow, isValidDate } from '../../Helpers/DateHelper';
import { sortOrganizations, sortRptCompanies } from '../../Helpers/SortHelpers';
import useAutofocus from '../../Hooks/UseAutofocus';
import useErrorHandler from '../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../Hooks/UseIbcOrThirdPartySelected';
import { GetAllAppFunctions } from '../../Slices/FunctionSlice';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../Slices/OrganizationSlice';
import theme from '../../theme';
import {
  AppFunction,
  Functions,
  Organization,
  ReactLocationState,
  ReportingCompany,
  UserAccessReportRequest,
  UserAccessReportResponse,
  UserAccessReportResponseCsv,
  UserStatus,
} from '../../Types';
import UserAccessReportResults from './UserAccessReportResults';

const UserAccessReportSearch: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { pathname, state } = useLocation<ReactLocationState>();
  const savedRequest = (state && state.userAccessSearchRequest) ?? null;

  const appFunctions = useSelector((state: { appFunction: any }) => GetAllAppFunctions(state));
  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));

  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);
  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);

  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);

  const [fetchedResults, setFetchedResults] = useState<Array<UserAccessReportResponse> | null>(null);
  const [resultsCsv, setResultsCsv] = useState<Array<UserAccessReportResponseCsv> | null>(null);

  const [formValues, setFormValues] = useState<UserAccessReportRequest>(
    savedRequest
      ? {
          ...savedRequest,
          userId: savedRequest.userId ? savedRequest.userId : '',
          userName: savedRequest.userName ? savedRequest.userName : '',
        }
      : {
          organizationId: null,
          companyId: null,
          userStatus: UserStatus.active,
          userId: '',
          userName: null,
          functionId: null,
          asOfDate: new Date(),
        }
  );

  // This external state for the form is necessary for default values
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [reportingCompanyId, setReportingCompanyId] = useState<number | null>(null);

  const [errorDate, setErrorDate] = useState<boolean>(false);

  const [fileName, setFilename] = useState<string | undefined>();

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(organizationId);

  const csvHeaders = [
    {
      key: 'organization',
      label: t('userAccessReport.results.organization'),
    },
    {
      key: 'reportingCompany',
      label: t('userAccessReport.results.company'),
    },
    {
      key: 'userId',
      label: t('userAccessReport.results.userId'),
    },
    {
      key: 'userName',
      label: t('userAccessReport.results.name'),
    },
    {
      key: 'userStatus',
      label: t('userAccessReport.results.status'),
    },
    {
      key: 'createdDate',
      label: t('userAccessReport.results.created'),
    },
    {
      key: 'lastLoginDate',
      label: t('userAccessReport.results.lastLogin'),
    },
    {
      key: 'functions',
      label: t('userAccessReport.results.functions'),
    },
  ];

  const organizationRef = useAutofocus();

  // Fetch organisation by user access
  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.userAccessReport }));
  }, [dispatch]);

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname });
  }, [history, pathname]);

  // Initialize selectedOrg if there is only one organizations available to the user
  useEffect(() => {
    if (organizations && organizations.length === 1) {
      setOrganizationId(organizations[0].id);
    } else if (organizations && organizations.length > 1 && formValues.organizationId) {
      setOrganizationId(formValues.organizationId);
    }
  }, [formValues.organizationId, organizations]);

  // Re-fetching results when coming back on the page
  useEffect(() => {
    if (savedRequest) {
      setIsLoadingSearch(true);

      const params: UserAccessReportRequest = {
        ...savedRequest,
        companyId: savedRequest.companyId,
        userId: savedRequest.userId && savedRequest.userId.length === 0 ? null : savedRequest.userId,
        userName: savedRequest.userName && savedRequest.userName.length === 0 ? null : savedRequest.userName,
        asOfDate: formValues.asOfDate ? formValues.asOfDate : null,
      };

      UserAccessReport.findUsageReports(params)
        .then((results) => {
          setFetchedResults(results);
          setIsLoadingSearch(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingSearch(false);
        });
    }
  }, [dispatch, formValues, handleError, reportingCompanyId, savedRequest, t]);

  // Fetch Reporting Companies
  useEffect(() => {
    if (!isIbcOrThirdPartySelected && organizationId) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganization(Functions.userAccessReport, organizationId)
        .then((results) => {
          setFetchedRptCompanies(results);
          setIsLoadingRpt(false);
          if (results.length === 1) {
            setReportingCompanyId(results[0].id);
          } else if (results.length > 1 && formValues.companyId) {
            setReportingCompanyId(formValues.companyId);
          }
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
  }, [dispatch, formValues.companyId, handleError, isIbcOrThirdPartySelected, organizationId, t]);

  const sort = useCallback(
    (a: any, b: any, type: string): number => {
      switch (type) {
        case 'organization':
          return sortOrganizations(a, b, i18n);
        case 'reportingcompany':
          return sortRptCompanies(a, b, i18n);
        default:
          return 0;
      }
    },
    [i18n]
  );

  const resetFormValues = () => {
    setFormValues({
      organizationId: null,
      companyId: null,
      userStatus: 1,
      userId: '',
      userName: '',
      functionId: null,
      asOfDate: new Date(),
    });
    setOrganizationId(null);
    setReportingCompanyId(null);
    setResultsCsv(null);
    setErrorDate(false);
  };

  const handleClearFields = () => {
    fetchedResults && setFetchedResults(null);
    setFetchedRptCompanies(null);
    resetFormValues();
  };

  const handleInputChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const isFormValid = (): boolean => {
    let error = false;

    // Validation for asOfDate that cannot be set in the future
    if (
      formValues.asOfDate &&
      (!isValidDate(formValues.asOfDate) || formValues.asOfDate.getTime() > new Date().getTime())
    ) {
      setErrorDate(true);
      error = true;
    } else {
      setErrorDate(false);
    }

    if (error) {
      return false;
    }

    return true;
  };

  const handleSearch = async () => {
    if (isFormValid()) {
      setIsLoadingSearch(true);

      const params: UserAccessReportRequest = {
        ...formValues,
        companyId: reportingCompanyId,
        userId: formValues.userId && formValues.userId.length === 0 ? null : formValues.userId,
        userName: formValues.userName && formValues.userName.length === 0 ? null : formValues.userName,
      };

      await UserAccessReport.findUsageReports(params)
        .then((results) => {
          setFetchedResults(results);
          setIsLoadingSearch(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingSearch(false);
        });
    }
  };

  const handleAutocompleteChange = (name: string, value: number | null) => {
    if (name === 'organizationId') {
      setFormValues({
        ...formValues,
        organizationId: value,
        companyId: null,
      });
      setOrganizationId(value);
      setReportingCompanyId(null);
    } else if (name === 'companyId') {
      setFormValues({ ...formValues, companyId: value });
      setReportingCompanyId(value);
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  // const handleNameOnChange = (value: string | null) => {
  //   setFormValues({ ...formValues, userName: value ? value : '' });
  // };

  const handleDatePickerChange = (newValue: Date | null) => {
    setFormValues({ ...formValues, asOfDate: newValue });
  };

  const handleClickCsv = () => {
    setFilename(`${t('menu.accessReport')}-${getDateFromNow().replace(/[^a-zA-Z0-9]/g, '')}`);
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <>
      <Card elevation={3} data-testid="UserAccessReportSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              {/* Search */}
              <Grid item container spacing={2} padding={2} xs={12}>
                {/* Field column */}
                <Grid item container spacing={1} md={10} lg={10} xl={10}>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        organizationId !== null
                          ? organizations.find((oneOrg: Organization) => oneOrg.id === organizationId)
                          : null
                      }
                      id="organizationId"
                      data-testid="organizationId"
                      options={organizations.sort((a, b) => sort(a, b, 'organization'))}
                      getOptionLabel={(option: Organization) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          autoFocus
                          label={t('userAdmin.companyProf.parent')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: organizationRef,
                          }}
                        />
                      )}
                      onChange={(_, value) =>
                        handleAutocompleteChange('organizationId', value && value.id ? value.id : null)
                      }
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    {!isIbcOrThirdPartySelected ? (
                      <Autocomplete
                        fullWidth
                        loading={isLoadingRpt}
                        loadingText={t('loading')}
                        noOptionsText={t('search.noOption')}
                        value={
                          reportingCompanyId !== null && fetchedRptCompanies
                            ? fetchedRptCompanies.find((oneRpt) => oneRpt.id === reportingCompanyId)
                            : null
                        }
                        id="reportingCompanyId"
                        data-testid="reportingCompanyId"
                        options={
                          fetchedRptCompanies ? fetchedRptCompanies.sort((a, b) => sort(a, b, 'reportingcompany')) : []
                        }
                        getOptionLabel={(option: ReportingCompany) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('userAdmin.companyProf.rptComp')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingRpt ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) =>
                          handleAutocompleteChange('companyId', value && value.id ? value.id : null)
                        }
                        disabled={isIbcOrThirdPartySelected || !organizationId}
                        disableClearable={fetchedRptCompanies != null && fetchedRptCompanies.length === 1}
                      />
                    ) : (
                      /* Dummy field for company when IBC or 3rd party */
                      <DashCustomInput
                        fullWidth
                        id="companyHolderIbc3rdPart"
                        value={
                          formValues.organizationId
                            ? i18n.language.startsWith('en')
                              ? organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameEn
                              : organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameFr
                            : ''
                        }
                        label={t('userAdmin.companyProf.rptComp')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          'data-testid': 'userId',
                          name: 'userId',
                          readOnly: true,
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="userId"
                      value={formValues.userId}
                      label={t('userAdmin.userAdmin.userId')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'userId',
                        name: 'userId',
                      }}
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      id="userName"
                      value={formValues.userName}
                      label={t('userAccessReport.username')}
                      labelGridSize={3}
                      fieldGridSize={5}
                      variant="outlined"
                      color="secondary"
                      onChange={(e) => handleInputChange(e)}
                      inputProps={{
                        'data-testid': 'userName',
                        name: 'userName',
                      }}
                    />
                    {/* <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={formValues.userName}
                      id="userName"
                      data-testid="userName"
                      filterOptions={(x) => x}
                      options={requestorNames}
                      getOptionLabel={(option: string) => option}
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('userAccessReport.username')}
                          variant="outlined"
                          color="secondary"
                          labelGridSize={3}
                          fieldGridSize={5}
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingRequestorNames ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleNameOnChange(value)}
                      onInputChange={(_, value) => {
                        handleReqNameInputChange(value);
                      }}
                      open={(!formValues.userName && lengthUserNameField > 0) || (formValues.userName ? false : false)}
                      forcePopupIcon={false}
                      loading={isLoadingRequestorNames}
                      loadingText={t('loading')}
                    /> */}
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            textAlign: 'end',
                          }}
                        >
                          {t('userAdmin.userAdmin.status')}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Select
                          fullWidth
                          id="status"
                          size="small"
                          value={formValues.userStatus}
                          onChange={(e) => setFormValues({ ...formValues, userStatus: e.target.value as UserStatus })}
                          data-testid="selectStatus"
                          sx={{
                            textAlign: 'left',
                          }}
                        >
                          <MenuItem value={0}>{t('userStatus.all')}</MenuItem>
                          <MenuItem value={1}>{t('userStatus.active')}</MenuItem>
                          <MenuItem value={2}>{t('userStatus.disabled')}</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.functionId !== null
                          ? appFunctions.find((oneFunc: AppFunction) => oneFunc.id === formValues.functionId)
                          : null
                      }
                      id="functionId"
                      data-testid="functionId"
                      options={appFunctions.sort((a, b) => {
                        return a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0;
                      })}
                      getOptionLabel={(option: AppFunction) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          label={t('userAccessReport.function')}
                          labelGridSize={3}
                          fieldGridSize={5}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAccessReport.functionPlaceholder')}
                        />
                      )}
                      onChange={(_, value) =>
                        handleAutocompleteChange('functionId', value && value.id ? value.id : null)
                      }
                    />
                  </Grid>
                  <Grid item container md={12} lg={11} xl={8}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                    >
                      <DatePicker
                        clearable
                        disableFuture
                        mask={DATE_MASK}
                        inputFormat={DATE_FORMAT}
                        value={formValues.asOfDate}
                        onChange={(newValue) => handleDatePickerChange(newValue)}
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('userAccessReport.asOfDate')}
                            labelGridSize={3}
                            fieldGridSize={5}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              'data-testid': 'ofDatePicker',
                            }}
                            variant="outlined"
                            color="secondary"
                            error={errorDate}
                            helperText={errorDate && t('reportHistory.invalidDate')}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          variant="contained"
                          id="btnSearch"
                          // onClick={handleSearch}
                          type="submit"
                          data-testid="btnSearch"
                        >
                          {t('userAccessReport.search')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={() => handleClearFields()}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('userAccessReport.reset')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* New User column */}
                <Grid item container md={2} lg={2} xl={2} alignContent="flex-end" justifyContent="flex-end">
                  <Grid item md={12} lg={12} xl={9}>
                    <ExportCsv
                      csvData={fetchedResults && fetchedResults.length > 0 && resultsCsv ? resultsCsv : []}
                      csvHeaders={csvHeaders}
                      fileName={fileName}
                      onClick={() => handleClickCsv()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Result */}
              {fetchedResults && fetchedResults.length > 0 && (
                <Grid item container xs={12} padding={2}>
                  <Grid item container xs={12}>
                    <Typography fontWeight={700}>{`${fetchedResults.length} ${
                      fetchedResults.length === 1
                        ? t('ninetyReport.oneResultFound')
                        : t('ninetyReport.manyResultsFound')
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <UserAccessReportResults
                      fetchedResults={fetchedResults}
                      requestUserAccessSearch={formValues}
                      appFunctions={appFunctions}
                      setResultsCsv={setResultsCsv}
                    />
                  </Grid>
                </Grid>
              )}
              {fetchedResults && fetchedResults.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('userAccessReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingSearch} />
    </>
  );
};

export default UserAccessReportSearch;
