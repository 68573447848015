import { requests } from '../axios';
import { BlobDto } from '../Types';

export const updatePrivacyPolicy = (lang: string, privacyPolicy: BlobDto): Promise<BlobDto> => {
  return requests.put(`/privacyPolicy?lang=${lang}`, privacyPolicy);
};

export const getPrivacyPolicy = (lang: string): Promise<BlobDto> => {
  return requests.get(`/privacyPolicy?lang=${lang}`, {});
};
