import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sortOrganizations } from '../../../Helpers/SortHelpers';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../../Slices/OrganizationSlice';
import { AppDispatch } from '../../../Store';
import { Functions, Organization } from '../../../Types';

const useSdcOrganization = (setOrganizationValue: (oranizationId: number) => void) => {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));
  const isLoadingOrganizations = useSelector(
    (state: { organization: { isPending: boolean } }) => state.organization.isPending
  );

  // Fetch organisation by user access
  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.sdcReport }))
      .unwrap()
      .then((results) => {
        if (results && results.length === 1) {
          setOrganizationValue(results[0].id);
        }
      });
  }, [dispatch, setOrganizationValue]);

  const sortOrganization = useCallback(
    (a: Organization, b: Organization): number => {
      return sortOrganizations(a, b, i18n);
    },
    [i18n]
  );

  return { organizations, isLoadingOrganizations, sortOrganization };
};

export default useSdcOrganization;
