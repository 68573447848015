import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PAGINATION_PAGESIZE } from '../../Helpers/Constants';
import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import theme from '../../theme';
import { CorrectionReportResponseCsvType, CorrectionReportResponseType } from '../../Types/CorrectionReportTypes';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface SystemReportResultsProps {
  fetchedResults: Array<CorrectionReportResponseType>;
  handleChangePage: (event: unknown, newPage: number) => void;
  count: number;
  pageNo: number;
}

interface HeadCell {
  id: keyof CorrectionReportResponseCsvType;
  label: string;
  width: number;
  align?: 'left' | 'right';
}

const CorrectionReportResults: FC<SystemReportResultsProps> = ({ fetchedResults, handleChangePage, count, pageNo }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'company',
      label: t('correctionReport.company'),
      width: 7,
    },
    {
      id: 'correctionDate',
      label: t('correctionReport.correctionDate'),
      width: 9,
    },
    {
      id: 'correctorUserName',
      label: t('correctionReport.correctorUserName'),
      width: 9,
    },
    {
      id: 'fieldName',
      label: t('correctionReport.fieldName'),
      width: 9,
    },
    {
      id: 'dln',
      label: t('correctionReport.DLN'),
      width: 9,
    },
    {
      id: 'policyId',
      label: t('correctionReport.policyId'),
      width: 8,
    },
    {
      id: 'policyEffectiveDate',
      label: t('correctionReport.policyEffectiveDate'),
      width: 10,
    },
    {
      id: 'claimId',
      label: t('correctionReport.claimId'),
      width: 9,
    },
    {
      id: 'accidentDate',
      label: t('correctionReport.accidentDate'),
      width: 10,
    },
    {
      id: 'oldValue',
      label: t('correctionReport.oldValue'),
      width: 10,
    },
    {
      id: 'newValue',
      label: t('correctionReport.newValue'),
      width: 10,
    },
  ];

  return (
    <>
      {fetchedResults.length > 0 && (
        <>
          <TableContainer component={'div'} data-testid="tableContainer">
            <Table
              size="small"
              stickyHeader
              aria-label="table-90days-result"
              sx={{ tableLayout: 'fixed', width: '100%' }}
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sx={{ width: `${headCell.width}%` }}
                      data-testid={`headerCell-${headCell.id}`}
                      align={headCell.align ? headCell.align : 'left'}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="tableBody">
                {fetchedResults
                  .map((oneResult) => ({
                    company: i18n.language.startsWith('en')
                      ? oneResult.company
                        ? oneResult.company.nameEn
                        : oneResult.organization.nameEn
                      : oneResult.company
                      ? oneResult.company.nameFr
                      : oneResult.organization.nameFr,
                    correctionDate: formatDateToLocalTime(oneResult.correctionDate, true),
                    correctorUserName: oneResult.correctorUserName,
                    fieldName: `${t(`correctionReport.${oneResult.correction.fieldName}`)}`,
                    dln: oneResult.dln,
                    policyId: oneResult.policyId ?? '',
                    policyEffectiveDate: oneResult.policyEffectiveDate
                      ? formatDateToLocalTime(oneResult.policyEffectiveDate, false)
                      : '',
                    claimId: oneResult.claimId ?? '',
                    accidentDate: oneResult.accidentDate ? formatDateToLocalTime(oneResult.accidentDate, false) : '',
                    oldValue: i18n.language.startsWith('en')
                      ? oneResult.correction.oldValue.valueEn
                      : oneResult.correction.oldValue.valueFr,
                    newValue: i18n.language.startsWith('en')
                      ? oneResult.correction.newValue.valueEn
                      : oneResult.correction.newValue.valueFr,
                  }))
                  .map((row, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                        sx={{ width: '7%' }}
                        data-testid={`tableCell-company-${index}`}
                      >
                        <Tooltip title={row.company} enterTouchDelay={0}>
                          <Typography className={classes.textCell}>{row.company}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '9%' }}
                        data-testid={`tableCell-correctionDate-${index}`}
                      >
                        <Tooltip title={row.correctionDate ?? ''} enterTouchDelay={0}>
                          <Typography className={classes.textCell}>{row.correctionDate ?? ''}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '9%' }}
                        data-testid={`tableCell-correctorUserName-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.correctorUserName ?? ''}>
                          <Typography className={classes.textCell}>{row.correctorUserName}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '9%' }}
                        data-testid={`tableCell-fieldName-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.fieldName}>
                          <Typography className={classes.textCell}>{row.fieldName}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '9%' }}
                        data-testid={`tableCell-DLN-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.dln ?? ''}>
                          <Typography className={classes.textCell}>{row.dln}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '8%' }}
                        data-testid={`tableCell-policyId-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.policyId ?? ''}>
                          <Typography className={classes.textCell}>{row.policyId}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '10%' }}
                        data-testid={`tableCell-policyEffectiveDate-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.policyEffectiveDate ?? ''}>
                          <Typography className={classes.textCell}>{row.policyEffectiveDate}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '9%' }}
                        data-testid={`tableCell-claimId-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.claimId ?? ''}>
                          <Typography className={classes.textCell}>{row.claimId}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '10%' }}
                        data-testid={`tableCell-accidentDate-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.accidentDate ?? ''}>
                          <Typography className={classes.textCell}>{row.accidentDate}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '10%' }}
                        data-testid={`tableCell-oldValue-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.oldValue ?? ''}>
                          <Typography className={classes.textCell}>{row.oldValue}</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: '10%' }}
                        data-testid={`tableCell-newValue-${index}`}
                      >
                        <Tooltip enterTouchDelay={0} title={row.newValue ?? ''}>
                          <Typography className={classes.textCell}>{row.newValue}</Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TablePagination
                count={count}
                rowsPerPageOptions={[PAGINATION_PAGESIZE]}
                rowsPerPage={PAGINATION_PAGESIZE}
                page={pageNo}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => {
                  return '' + from + '-' + to + ` ${t('of')} ` + count;
                }}
              />
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default CorrectionReportResults;
