import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { Notification, NotificationFactory } from '../Types';

// Adapter
const notificationAdapter = createEntityAdapter<Notification>({
  // Assume IDs are stored in a field id
  selectId: (notification) => notification.code,
  // Keep the "all IDs" array sorted based on acronym
  sortComparer: (a, b) => {
    if (a.code > b.code) {
      return 1;
    }
    if (a.code < b.code) {
      return -1;
    }
    return 0;
  },
});

// Selectors
// Get default selects that comes with entityAdapter
const notificationSelectors = notificationAdapter.getSelectors((state: { notification: any }) => state.notification);

// map selectors if you want to have them called differently
export const { selectId: getErrorCode } = notificationAdapter;

export const getNotificationByCode = createSelector(
  [
    // First input selector: all reports
    notificationSelectors.selectAll,
    // Second input selector: province value
    (state: any, code: number) => code,
  ],
  // Output selector: receives both values
  (notifications: Array<Notification>, code: number) => {
    // Return provinces based on dln and province
    return notifications.filter((notification) => {
      return notification.code === code;
    });
  }
);

export const GetAllNotifications = createSelector(
  [notificationSelectors.selectAll],
  (notification: Array<Notification>) => {
    return notification;
  }
);

//Test helper
export const CreateNotificationTestState = () => {
  const notifications = [
    NotificationFactory(500, 'error', 'Internal server error'),
    NotificationFactory(404, 'error', 'Resource not found'),
  ];
  const notificationState = notificationAdapter.getInitialState();
  notifications.forEach((notification) => {
    notificationState.ids.push(notification.code);
    notificationState.entities[notification.code] = notification;
  });

  return { notification: notificationState };
};

export const addNotification = createAction(
  'addNotification',
  function prepare(code: number, severity: 'info' | 'success' | 'warning' | 'error', message: string) {
    return {
      payload: {
        error: NotificationFactory(code, severity, message),
      },
    };
  }
);

export const removeNotifications = createAction('removeNotifications');

// Slice

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: notificationAdapter.getInitialState(),
  reducers: {
    clearStore() {
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNotification, (state, action) => {
      notificationAdapter.removeAll(state);
      notificationAdapter.addOne(state, action.payload.error);
    });

    builder.addCase(removeNotifications, (state) => {
      notificationAdapter.removeAll(state);
    });
  },
});

export const { clearStore } = notificationSlice.actions;

export default notificationSlice.reducer;
