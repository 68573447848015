import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { CardContent, Grid } from '@mui/material';

import { B2CProviders } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import useAutofocus from '../../../Hooks/UseAutofocus';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { B2CProvider } from '../../../Types';
import ReactLocationState from '../../../Types/ReactLocationState';
import B2CProvidersResults from './B2CProvidersResults';

interface FormValuesProps {
  name: string | null;
}

const B2CSettings: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const { pathname } = useLocation<ReactLocationState>();

  const [formValues, setFormValues] = useState<FormValuesProps>({ name: null });

  const [providers, setProviders] = useState<Array<B2CProvider> | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const searchB2CRef = useAutofocus();

  // Reseting the state of the location object at render
  useEffect(() => {
    history.replace(pathname, { from: pathname, fromTab: 'b2cSettings' });
  }, [history, pathname]);

  useEffect(() => {
    if (!providers) {
      setIsLoadingResults(true);
      B2CProviders.list()
        .then((results) => {
          setIsLoadingResults(false);
          setProviders(results);
        })
        .catch((error) => {
          setIsLoadingResults(false);
          setProviders([]);
          handleError(error, { error500: t('settings.b2cSettings.error500') });
        });
    }
  }, [dispatch, handleError, providers, t]);

  const handleSearch = async (search: string) => {
    setFormValues({ ...formValues, name: search || null });
    if (search && providers) {
      if (providers.length === 0) {
        setIsLoadingResults(true);
        B2CProviders.list()
          .then((results) => {
            setIsLoadingResults(false);
            setProviders(results.filter((p: B2CProvider) => p.b2CDomainName.startsWith(search.toLowerCase()) === true));
          })
          .catch((error) => {
            setIsLoadingResults(false);
            setProviders([]);
            handleError(error);
          });
        return;
      } else {
        const filteredProviders = providers.filter(
          (p: B2CProvider) => p.b2CDomainName.startsWith(search.toLowerCase()) === true
        );
        setProviders(filteredProviders);
      }
    } else {
      setProviders(null);
    }
  };

  return (
    <>
      <CardContent sx={{ paddingRight: '0px' }}>
        <Grid container>
          <Grid item container xs={12} padding={2} alignItems="flex-start">
            <Grid item xs={9} md={9} lg={8} xl={7}>
              <DashCustomInput
                fullWidth
                label={t('settings.b2cSettings.search')}
                labelGridSize={3}
                fieldGridSize={10}
                variant="outlined"
                color="secondary"
                sx={{ paddingRight: 0 }}
                value={formValues.name || ''}
                autoComplete="nope"
                onChange={(e) => handleSearch(e.target.value)}
                inputProps={{ 'data-testid': 'name' }}
                placeholder={t('settings.b2cSettings.searchPlaceholder')}
                InputProps={{
                  inputRef: searchB2CRef,
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            {providers && !isLoadingResults && <B2CProvidersResults providers={providers} />}
          </Grid>
        </Grid>
      </CardContent>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default B2CSettings;
