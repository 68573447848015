import { ClaimKindOfLosses, ClaimStatus, VehicleLoss } from '.';
import Organization from './Organization';

export interface VehicleClaim {
  dateOfLoss: Date;
  policyNumber: string | null;
  claimNumber: string | null;
  status: ClaimStatus;
  totalLoss: number;
  totalExpense: number;
  vehicleLoss: VehicleLoss;
  claimKindOfLosses: Array<ClaimKindOfLosses> | null;
  insurer: Organization;
}

export const VehicleClaimFactory = (props: VehicleClaim): VehicleClaim => {
  return {
    dateOfLoss: props.dateOfLoss,
    policyNumber: props.policyNumber,
    claimNumber: props.claimNumber,
    status: props.status,
    totalLoss: props.totalLoss,
    totalExpense: props.totalExpense,
    vehicleLoss: props.vehicleLoss,
    claimKindOfLosses: props.claimKindOfLosses,
    insurer: props.insurer,
  };
};

export default VehicleClaim;
