/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { B2CProviders } from '../../../axios';
import ConfirmDialog from '../../../Components/ConfirmDialog';
import { ConfirmDialogProps } from '../../../Components/ConfirmDialog';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { B2CProvider, ModeEnum } from '../../../Types';
import AddRenameProvider, { AddRenameProviderProps } from './AddRenameProvider';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.white.main,
    marginBottom: '6px',
    padding: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: theme.palette.secondary.light,
    marginBottom: '6px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonText: {
    fontWeight: 700,
  },
}));

interface B2CProvidersResultsProps {
  providers: Array<B2CProvider>;
}

const B2CProvidersResults: FC<B2CProvidersResultsProps> = ({ providers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    setListProviders(providers);
  }, [providers]);

  const [listProviders, setListProviders] = useState<B2CProvider[]>(providers);

  const [addRenameProviderDialogOpen, setAddRenameProviderDialogOpen] = useState<boolean>(false);
  const [addRenameProviderProps, setAddRenameProviderProps] = useState<AddRenameProviderProps | undefined>(undefined);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | undefined>(undefined);

  const handleAddClick = () => {
    setAddRenameProviderProps({
      mode: ModeEnum.Create,
      onCreated: handleOnCreated,
      onUpdated: handleOnUpdated,
      onClose: handleOnDialogClose,
    });
    setAddRenameProviderDialogOpen(true);
  };

  const handleRenameClick = (item: B2CProvider) => {
    setAddRenameProviderProps({
      mode: ModeEnum.Update,
      item: item,
      onCreated: handleOnCreated,
      onUpdated: handleOnUpdated,
      onClose: handleOnDialogClose,
    });
    setAddRenameProviderDialogOpen(true);
  };

  const handleDeleteClick = (item: B2CProvider) => {
    setConfirmDialogProps({
      id: item.id,
      isDelete: true,
      title: t('settings.b2cSettings.confirmDeleteProviderDialogTitle'),
      contentText: `${t('settings.b2cSettings.confirmDeleteProvider', { domain: item.emailDomainName })}`,
      onAction: handleOnDeleteConfirm,
    });
    setConfirmDialogOpen(true);
  };

  const handleOnCreated = (result: B2CProvider) => {
    setListProviders([...listProviders, result]);
    setAddRenameProviderDialogOpen(false);
    setAddRenameProviderProps(undefined);
  };

  const handleOnUpdated = (result: B2CProvider) => {
    setListProviders([...listProviders.filter((p) => p.id !== result.id), result]);
    setAddRenameProviderDialogOpen(false);
    setAddRenameProviderProps(undefined);
  };

  const handleOnDialogClose = () => {
    setAddRenameProviderDialogOpen(false);
    setAddRenameProviderProps(undefined);
  };

  const handleOnDeleteConfirm = (confirm: boolean, id?: number) => {
    if (confirm) {
      B2CProviders.delete(id!)
        .then((provider) => {
          setListProviders(listProviders.filter((d) => d.id !== id));
          dispatch(
            addNotification(200, 'success', t('successMessage.deleted', { resource: provider.emailDomainName }))
          );
          setConfirmDialogOpen(false);
          setConfirmDialogProps(undefined);
        })
        .catch(() => {
          dispatch(addNotification(500, 'error', t('errorMessage.error500')));
        });
    } else {
      setConfirmDialogOpen(false);
      setConfirmDialogProps(undefined);
    }
  };

  return (
    <>
      <Grid container spacing={2} paddingTop={2} data-testid="parentGridResults">
        <Grid item container spacing={4} xs={12}>
          <Grid item container xs={12} sx={{ paddingLeft: '16px !important', paddingTop: '16px !important' }}>
            <Grid item container xs={12} alignItems="center" className={classes.tableHeader}>
              <Grid item container xs={8}>
                <Grid item xs={6}>
                  <Typography align="left" sx={{ fontWeight: 700 }}>
                    {t('settings.b2cSettings.keys')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="left" sx={{ fontWeight: 700 }}>
                    {t('settings.b2cSettings.name')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} data-testid="b2cProvidersList">
              {listProviders &&
                listProviders.map((provider, index) => (
                  <Grid key={index} item container xs={12} alignItems="center" className={classes.tableRow}>
                    <Grid item container xs={8}>
                      <Grid item xs={6}>
                        <Typography sx={{ fontWeight: '500' }} align="left">
                          {provider.b2CDomainName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontWeight: '500' }} align="left">
                          {provider.emailDomainName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={4} spacing={4} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          fullWidth
                          className={classes.buttonText}
                          variant="text"
                          color="primary"
                          id={`btnRename${index}`}
                          onClick={() => handleRenameClick(provider)}
                          endIcon={<ArrowRightIcon />}
                          data-testid={`btnRename${index}`}
                        >
                          {t('settings.b2cSettings.btnRename')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          className={classes.buttonText}
                          variant="text"
                          id={`btnDelete${index}`}
                          onClick={() => handleDeleteClick(provider)}
                          endIcon={<ArrowRightIcon />}
                          data-testid={`btnDelete${index}`}
                        >
                          {t('settings.b2cSettings.btnDelete')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              <Grid item container xs={12} alignItems="center" className={classes.tableRow}>
                <Button
                  className={classes.buttonText}
                  variant="text"
                  id="btnAdd"
                  onClick={() => handleAddClick()}
                  endIcon={<ArrowRightIcon />}
                  data-testid="btnAdd"
                >
                  {t('settings.b2cSettings.btnAddProvider')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {addRenameProviderDialogOpen && addRenameProviderProps && <AddRenameProvider {...addRenameProviderProps} />}
      {confirmDialogOpen && confirmDialogProps && <ConfirmDialog {...confirmDialogProps} />}
    </>
  );
};

export default B2CProvidersResults;
