import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { ReportPdf } from '../axios';
import ContainerTitle from '../Components/ContainerTitle';
import Loader from '../Components/Loader';
import ClaimList from '../Features/Reports/ClaimList';
import EditDialogDriver from '../Features/Reports/DataCorrection/Dialogs/EditDialogDriver';
import EditButton from '../Features/Reports/DataCorrection/EditButton';
import PolicyList from '../Features/Reports/PolicyList';
import PreviousInquiries from '../Features/Reports/PreviousInquiries';
import { formatDateToLocalTime } from '../Helpers/DateHelper';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
import useErrorHandler from '../Hooks/UseErrorHandler';
import { getReport } from '../Slices/ReportSlice';
import { getConnectedUser } from '../Slices/UserSlice';
import theme from '../theme';
import { CodeReference, Functions, ReactLocationState } from '../Types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('lg')]: {
        maxWidth: '90%',
      },
    },
    header: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(3),
    },
    headerFirstColumn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(3),
    },
    headerSecondColumn: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(3),
    },
    subHeader: {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
    },
    label: {
      display: 'inline',
      marginRight: '5px',
      marginTop: '5px',
    },
    value: {
      display: 'inline',
      fontWeight: 'bold',
      marginTop: '5px',
    },
    info: {
      display: 'inline',
      color: theme.palette.primary.dark,
    },
    noteLabel: {
      display: 'inline',
      fontWeight: 'bold',
      marginRight: '5px',
    },
    noteValue: {
      display: 'inline',
    },
  })
);

export type fieldNameCorrection =
  | 'name'
  | 'address'
  | 'dateOfBirth'
  | 'gender'
  | 'maritalStatus'
  | 'yearsLicensed'
  | 'training'
  | 'gridRating'
  | 'status'
  | 'cancellationDate'
  | 'atFault'
  | 'vehicle'
  | 'vehicleLoss'
  | 'fpDriver'
  | 'fpDriverExcluded';

const Report: FC<RouteComponentProps> = () => {
  // Fix for bug 3497 - tested in Chrome, FF, and Edge
  window.scrollTo(0, 0);

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  let { state } = useLocation<ReactLocationState>();
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    state = { ...state, from: '/driver-report' };
  }

  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  const [isCorrectionMode, setIsCorrectionMode] = useState<boolean>(false);

  const [titleToEdit, setTitleToEdit] = useState<string>('');
  const [valueToEdit, setValueToEdit] = useState<string | number | CodeReference | null>(null);
  const [fieldNameToEdit, setFieldNameToEdit] = useState<fieldNameCorrection>('address');

  const [driverEditDialogOpen, setDriverDialogOpen] = useState<boolean>(false);

  const user = useSelector((state: { user: any }) => getConnectedUser(state));
  const report = useSelector((state: { report: any }) => getReport(state))[0];
  const isPending = useSelector((state: { report: { isPending: boolean } }) => state.report.isPending);

  const isUserAllowedToEditPolicy = useCallback(() => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      return true;
    } else {
      // Policies are ordered from most recent to old - the company of the most recent policy is the one "owning" the user info
      const currentPolicyCompany = report.policies[0]?.carrier;

      const userCompanies = user.userFunctions.find(
        (oneUf) => oneUf.functionId === Functions.dataCorrection
      )?.reportingCompanies;

      return !!userCompanies?.find((oneComp) => parseInt(oneComp.code) === parseInt(currentPolicyCompany?.code ?? '0'));
    }
  }, [report.policies, user.userFunctions]);

  const handlePrint = () => {
    setIsLoadingPdf(true);
    ReportPdf.generatePdf(
      state.from === '/report-history' ? Functions.reportHistory : Functions.driverReport,
      { reportId: report.id },
      i18n.language.startsWith('en') ? 'english' : 'french'
    )
      .then((response) => {
        const url = response.data as string;
        window.open(url);
        setIsLoadingPdf(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoadingPdf(false);
      });
  };

  const handleClickEditButton = (
    title: string,
    value: string | number | CodeReference | null,
    fieldName: fieldNameCorrection
  ) => {
    setTitleToEdit(title);
    setValueToEdit(value);
    setFieldNameToEdit(fieldName);
    setDriverDialogOpen(true);
  };

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      document.getElementById('btnGoBack')?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const buttonsReport = (
    <Grid item container xs={12} spacing={2} justifyContent="flex-end">
      {state && state.from === '/driver-report' && doUserHaveAccessToFeature(user, Functions.dataCorrection) && (
        <Grid item xs={6}>
          <Grid item container xs={12} justifyContent="flex-end" data-testid="correctionSwitch">
            <FormControlLabel
              control={<Switch />}
              label={t('report.dataCorrection.correctionMode').toString()}
              labelPlacement="start"
              checked={isCorrectionMode}
              onChange={(_, value) => setIsCorrectionMode(value)}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={3}>
        <Button fullWidth variant="contained" color="secondary" onClick={() => handlePrint()}>
          <Typography>{t('report.toPdf')}</Typography>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          id="btnGoBack"
          variant="contained"
          color="secondary"
          onClick={() => history.push(state.from, state)}
        >
          <Typography>{t('report.goBack')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Container disableGutters fixed className={classes.container}>
        <Grid container>
          <Grid item container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} data-testid="reportTitle">
              <ContainerTitle title={t('report.summary.title')} button={buttonsReport} />
              {/* <ContainerGraphicTitle
                titleImage={i18n.language.startsWith('en') ? DriverReportEN : DriverReportFR}
                button={buttonsReport}
              /> */}
            </Grid>
          </Grid>
          {report && (
            <Grid container sx={{ boxShadow: 5, backgroundColor: theme.palette.white.main }}>
              {/* Driver info */}
              <Grid item container>
                {/* Bleu header */}
                <Grid item container xs={12}>
                  {/* Col 1 */}
                  <Grid item container xs={6} className={classes.headerFirstColumn}>
                    <Grid item container sx={{ marginBottom: theme.spacing(8) }} alignItems="center">
                      <Typography variant="h5" sx={{ fontWeight: 'bold', color: theme.palette.white.main }}>
                        {report.fullName}
                      </Typography>
                      {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                        <EditButton
                          open={isCorrectionMode}
                          onClick={() =>
                            handleClickEditButton(t('correctionReport.DriverName'), report.fullName, 'name')
                          }
                          dataTestId="nameEditButton"
                        />
                      )}
                    </Grid>
                    <Grid item container>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.label}>
                          {t('report.summary.dln')}:
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                          {report.dln || t('report.common.notAvailable')}{' '}
                          {(i18n.language.startsWith('fr')
                            ? report.dlnJurisdiction?.descriptionFr
                            : report.dlnJurisdiction?.descriptionEn) || t('report.common.notAvailable')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.label}>
                          {t('report.summary.dateOfBirth')}:
                        </Typography>
                        <Typography variant="body1" className={classes.value}>
                          {report.dateOfBirth
                            ? formatDateToLocalTime(report.dateOfBirth, false)
                            : report.yearOfBirth || t('report.common.notAvailable')}
                        </Typography>
                        {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                          <EditButton
                            open={isCorrectionMode}
                            onClick={() => {
                              handleClickEditButton(
                                t('report.summary.dateOfBirth'),
                                report.dateOfBirth
                                  ? new Date(new Date(`${report.dateOfBirth} 00:00:00.000`)).toString()
                                  : report.yearOfBirth
                                  ? new Date(new Date(report.yearOfBirth, 0, 1, 0, 0, 0)).toString()
                                  : null,
                                'dateOfBirth'
                              );
                            }}
                            dataTestId="dobEditButton"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Col 2 */}
                  <Grid item container xs={6} className={classes.headerSecondColumn}>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.reportDate')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {formatDateToLocalTime(report.reportDate, true, false, report.ianaTimezone)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.requestor')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {i18n.language.startsWith('fr') ? report.requestorFr : report.requestorEn}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.company')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {i18n.language.startsWith('fr') ? report.company.nameFr : report.company.nameEn}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.reference')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {!report.referenceNumber || (report.referenceNumber && report.referenceNumber.length === 0)
                          ? t('report.common.notAvailable')
                          : report.referenceNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.lastUpdate')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {formatDateToLocalTime(report.lastUpdated, false)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.yearsOfData')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {report.yearsOfData || t('report.summary.maxYears')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Grey sub-header */}
                <Grid item container xs={12} className={classes.subHeader}>
                  {/* Col 1 */}
                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.address')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {report.fullAddress || t('report.common.notAvailable')}
                      </Typography>
                      {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                        <EditButton
                          open={isCorrectionMode}
                          onClick={() => handleClickEditButton('Address', report.fullAddress, 'address')}
                          dataTestId="addressEditButton"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.numOfPolicies')}
                        <Tooltip placement="top" title={t('report.summary.numOfPoliciesTooltip').toString()} arrow>
                          <IconButton aria-label="info number of policies" size="small" sx={{ padding: '0 1px 0 3px' }}>
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(!report.numberOfPoliciesAtThisAddress &&
                          report.numberOfPoliciesAtThisAddress !== 0 &&
                          t('report.common.notAvailable')) ||
                          report.numberOfPoliciesAtThisAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.gender')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(i18n.language.startsWith('fr')
                          ? report.gender?.descriptionFr
                          : report.gender?.descriptionEn) || t('report.common.notAvailable')}
                      </Typography>
                      {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                        <EditButton
                          open={isCorrectionMode}
                          onClick={() =>
                            handleClickEditButton(t('report.summary.gender'), report.gender ?? null, 'gender')
                          }
                          dataTestId="genderEditButton"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.maritalStatus')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(i18n.language.startsWith('fr')
                          ? report.maritalStatus?.descriptionFr
                          : report.maritalStatus?.descriptionEn) || t('report.common.notAvailable')}
                      </Typography>
                      {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                        <EditButton
                          open={isCorrectionMode}
                          onClick={() =>
                            handleClickEditButton(
                              t('report.summary.maritalStatus'),
                              (i18n.language.startsWith('fr')
                                ? report.maritalStatus?.descriptionFr
                                : report.maritalStatus?.descriptionEn) || 'null',
                              'maritalStatus'
                            )
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.yearsLicensed')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(!report.yearsLicensed && report.yearsLicensed !== 0 && t('report.common.notAvailable')) ||
                          report.yearsLicensed}
                      </Typography>
                      {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                        <EditButton
                          open={isCorrectionMode}
                          onClick={() =>
                            handleClickEditButton(
                              t('report.summary.yearsLicensed').toString(),
                              report.yearsLicensed,
                              'yearsLicensed'
                            )
                          }
                          dataTestId="yearsLicensedEditButton"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.yearsOfConInsurance')}:
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(!report.yearsOfContinuousInsurance &&
                          report.yearsOfContinuousInsurance !== 0 &&
                          t('report.common.notAvailable')) ||
                          report.yearsOfContinuousInsurance}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.yearsClaimsFree')}
                        <Tooltip placement="top" title={t('report.summary.yearsClaimsFreeTooltip').toString()} arrow>
                          <IconButton aria-label="info years claimns free" size="small" sx={{ padding: '0 1px 0 3px' }}>
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(!report.numberOfYearsClaimsFree &&
                          report.numberOfYearsClaimsFree !== 0 &&
                          t('report.common.notAvailable')) ||
                          report.numberOfYearsClaimsFree}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.driverTraining')}
                        <Tooltip placement="top" title={t('report.summary.driverTrainingTooltip').toString()} arrow>
                          <IconButton
                            aria-label="info years of continuous insurance"
                            size="small"
                            sx={{ padding: '0 1px 0 3px' }}
                          >
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {(i18n.language.startsWith('fr')
                          ? report.driverTraining?.descriptionFr
                          : report.driverTraining?.descriptionEn) || t('report.common.notAvailable')}
                      </Typography>
                      {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                        <EditButton
                          open={isCorrectionMode}
                          onClick={() =>
                            handleClickEditButton(
                              t('report.summary.driverTraining'),
                              (i18n.language.startsWith('fr')
                                ? report.driverTraining?.descriptionFr
                                : report.driverTraining?.descriptionEn) || t('report.common.notAvailable').toString(),
                              'training'
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* Col 2 */}
                  <Grid item container xs={6} paddingLeft={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.numberOfClaimsLast6Years')}
                        <Tooltip
                          placement="top"
                          title={t('report.summary.numberOfClaimsLast6YearsTooltip').toString()}
                          arrow
                        >
                          <IconButton
                            aria-label="info number of claims in last 6 years"
                            size="small"
                            sx={{ padding: '0 1px 0 3px' }}
                          >
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {report.numberOfClaimsLast6Years !== null
                          ? report.numberOfClaimsLast6Years
                          : t('report.common.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.numberOfAtFaultAccidents')}
                        <Tooltip
                          placement="top"
                          title={t('report.summary.numberOfAtFaultClaimsLast6YearsTooltip').toString()}
                          arrow
                        >
                          <IconButton
                            aria-label="info number of at-fault claims in last 6 years"
                            size="small"
                            sx={{ padding: '0 1px 0 3px' }}
                          >
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {report.atFaultAccidentsLast6Years !== null
                          ? report.atFaultAccidentsLast6Years
                          : t('report.common.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.numberOfComprehensiveLosses')}
                        <Tooltip
                          placement="top"
                          title={t('report.summary.numberOfComprehensiveLossesTooltip').toString()}
                          arrow
                        >
                          <IconButton
                            aria-label="info number of comprehensive losses in last 6 years"
                            size="small"
                            sx={{ padding: '0 1px 0 3px' }}
                          >
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {report.comprehensiveLossesLast6Years !== null
                          ? report.comprehensiveLossesLast6Years
                          : t('report.common.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.label}>
                        {t('report.summary.numberOfDCPDClaims')}
                        <Tooltip placement="top" title={t('report.summary.numberOfDCPDClaimsTooltip').toString()} arrow>
                          <IconButton
                            aria-label="info number of DCPD claims in last 6 years"
                            size="small"
                            sx={{ padding: '0 1px 0 3px' }}
                          >
                            <InfoOutlinedIcon fontSize="small" className={classes.info} />
                            {':'}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body1" className={classes.value}>
                        {report.numberOfDCPDClaimsLast6Years !== null
                          ? report.numberOfDCPDClaimsLast6Years
                          : t('report.common.notAvailable')}
                      </Typography>
                    </Grid>
                    {report.dlnJurisdiction.code === 'AB' && (
                      <>
                        <Grid item xs={12} style={{ marginTop: '20px' }}>
                          <Typography variant="body1" className={classes.label}>
                            {t('report.summary.albertaOperatorGridLevel')}:
                          </Typography>
                          <Typography variant="body1" className={classes.value}>
                            {report.albertaOperatorGridLevel === null
                              ? t('report.common.notAvailable')
                              : report.albertaOperatorGridLevel}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.label}>
                            {t('report.summary.albertaOperatorGridIndicator')}:
                          </Typography>
                          <Typography variant="body1" className={classes.value}>
                            {report.albertaOperatorGridIndicator === null
                              ? t('report.common.notAvailable')
                              : report.albertaOperatorGridIndicator}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.label}>
                            {t('report.summary.albertaGridRatingDate')}:
                          </Typography>
                          <Typography variant="body1" className={classes.value}>
                            {report.albertaGridRatingDate === null
                              ? t('report.common.notAvailable')
                              : formatDateToLocalTime(report.albertaGridRatingDate, false)}
                          </Typography>
                          {report.policies.length > 0 && isCorrectionMode && isUserAllowedToEditPolicy() && (
                            <EditButton
                              open={isCorrectionMode}
                              onClick={() =>
                                handleClickEditButton(
                                  t('report.summary.albertaGridRatingDate'),
                                  new Date(new Date(`${report.albertaGridRatingDate} 00:00:00.000`)).toString(),
                                  'gridRating'
                                )
                              }
                            />
                          )}
                        </Grid>
                      </>
                    )}
                    {report.dlnJurisdiction.code !== 'AB' && (
                      <>
                        <Box height="100%" />
                        <Box height="100%" />
                        <Box height="100%" />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Policies*/}
              <Grid item container>
                <PolicyList
                  policies={report.policies}
                  gaps={report.possibleCoverageGaps}
                  isCorrectionMode={isCorrectionMode}
                  reportId={report.id}
                  operatorKey={report.driverId}
                />
              </Grid>

              {/* Claims */}
              <Grid item container>
                <ClaimList
                  claims={report.claims}
                  isCorrectionMode={isCorrectionMode}
                  reportId={report.id}
                  operatorKey={report.driverId}
                />
              </Grid>

              {/* Previous Inq */}
              <Grid item container>
                {report.previousInquiries && <PreviousInquiries inquiries={report.previousInquiries} />}
              </Grid>
            </Grid>
          )}
        </Grid>

        {(isPending || isLoadingPdf) && <Loader open={true} />}
      </Container>

      {/* Edit dialogs for the Correction Mode */}
      <Dialog
        open={driverEditDialogOpen}
        fullWidth
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setDriverDialogOpen(false);
          }
        }}
      >
        <EditDialogDriver
          title={titleToEdit}
          value={valueToEdit as string | number | null}
          fieldName={fieldNameToEdit}
          report={report!}
          onClose={() => setDriverDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default withRouter(Report);
