import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { Button, Container, Grid } from '@mui/material';

import Message from '../Components/Message';
import { ApplicationPaths } from '../Features/Authorization/AuthorizationConstants';

const LoginError: FC<RouteComponentProps> = ({ history }) => {
  const errorCode: string = useParams<{ code: string }>().code;
  const { t } = useTranslation();

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center" data-testid="signinContainer">
        <Grid item xs={12}>
          <Message message={`signin.error${errorCode}`} severity="error" />
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Grid item container xs={6}>
            <Button variant="contained" size="large" onClick={() => history.push(ApplicationPaths.LoginPage)}>
              {t('signin.lbBack')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(LoginError);
