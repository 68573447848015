import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Cookies from 'js-cookie';

import theme from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    boldText: {
      fontWeight: 500,
      '&:disabled': {
        color: theme.palette.primary.main,
      },
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(4),
    },
    loginPageText: {
      fontWeight: 400,
      '&:disabled': {
        color: theme.palette.primary.main,
      },
      padding: theme.spacing(0),
    },
  })
);

type LanguageToggleProps = {
  isLoginPage?: boolean;
};

const LanguageToggle: FC<LanguageToggleProps> = ({ isLoginPage }) => {
  const { i18n } = useTranslation();
  // Defaults to users last selected language or browsers default language when user is not connected
  const [isEnglish, setIsEnglish] = useState(
    Cookies.get('userId-language')
      ? Cookies.get('userId-language')!.startsWith('en')
        ? true
        : false
      : i18n.language.startsWith('en')
  );
  const classes = useStyles(isEnglish);

  const handleClick = () => {
    if (isEnglish) {
      i18n.changeLanguage('fr');
      Cookies.set('userId-language', i18n.language);
      setIsEnglish(false);
    } else {
      i18n.changeLanguage('en');
      Cookies.set('userId-language', i18n.language);
      setIsEnglish(true);
    }
  };

  return (
    <>
      {isLoginPage ? (
        <>
          {isEnglish && (
            <Button
              size="large"
              variant="text"
              color="white"
              onClick={handleClick}
              className={classes.loginPageText}
              data-testid="francaisBtn"
              endIcon={<KeyboardArrowRightIcon />}
            >
              Français
            </Button>
          )}
          {!isEnglish && (
            <Button
              size="large"
              variant="text"
              color="white"
              onClick={handleClick}
              className={classes.loginPageText}
              data-testid="englishBtn"
              endIcon={<KeyboardArrowRightIcon />}
            >
              English
            </Button>
          )}
        </>
      ) : (
        <>
          {isEnglish && (
            <Button
              size="large"
              variant="text"
              color="secondary"
              onClick={handleClick}
              className={classes.boldText}
              data-testid="francaisBtn"
            >
              Français
            </Button>
          )}
          {!isEnglish && (
            <Button
              size="large"
              variant="text"
              color="secondary"
              onClick={handleClick}
              className={classes.boldText}
              data-testid="englishBtn"
            >
              English
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default LanguageToggle;
