import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as DateHelper from '../../Helpers/DateHelper';
import theme from '../../theme';
import { CodeReference, PolicyOperator, Vehicle } from '../../Types';

interface OperatorListProps {
  operators?: Array<PolicyOperator>;
  vehicles?: Array<Vehicle>;
  policyStatus: CodeReference;
}

const useStyles = makeStyles({
  label: {
    display: 'inline',
    color: theme.palette.primary.dark,
    marginRight: '5px',
  },
  container: {
    backgroundColor: theme.palette.white.main,
  },
  subContainer: {
    padding: theme.spacing(3),
  },
  colonneLeft: {
    paddingRight: theme.spacing(2),
  },
  colonneRight: {
    paddingLeft: theme.spacing(3),
  },
  operatorDivider: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  value: {
    display: 'inline',
    fontWeight: 'bold',
    marginRight: '5px',
    marginTop: '5px',
  },
  grid: {
    marginTop: '20px',
    alignContent: 'center',
    padding: '5px',
  },
});

export const OperatorsList: React.FC<OperatorListProps> = ({ operators = [], vehicles = [], policyStatus = null }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      {operators &&
        operators.length > 0 &&
        operators.map((o, index) => {
          return (
            <Grid key={index} container>
              {index !== 0 && (
                <Grid item xs={12} className={classes.operatorDivider}>
                  <Divider variant="fullWidth" />
                </Grid>
              )}
              <Grid container item xs={12} className={classes.subContainer}>
                <Grid container item xs={6} className={classes.colonneLeft}>
                  <Box sx={{ pl: 1, paddingLeft: '0' }}>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('report.policy.operator.label')}:</Typography>
                      <Typography data-testid="name" className={classes.value}>
                        {o.fullName || t('report.common.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('report.policy.operator.dln')}:</Typography>
                      <Typography data-testid="dln" className={classes.value}>
                        {o.dln}{' '}
                        {o.dln
                          ? (i18n.language.startsWith('fr')
                              ? o.dlnJurisdiction?.descriptionFr
                              : o.dlnJurisdiction?.descriptionEn) || ''
                          : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>
                        {t('report.policy.operator.relationshipToPolicyholder')}:
                      </Typography>
                      <Typography data-testid="relationshipToPolicyholder" className={classes.value}>
                        {(i18n.language.startsWith('fr')
                          ? o.relationShipToPolicyHolder?.descriptionFr
                          : o.relationShipToPolicyHolder?.descriptionEn) || t('report.common.notAvailable')}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography className={classes.label}>{t('report.policy.operator.yearsLicensed')}:</Typography>
                      <Typography data-testid="yearLicensed" className={classes.value}>
                        {(!o.yearsLicensed && o.yearsLicensed !== 0 && t('report.common.notAvailable')) ||
                          o.yearsLicensed}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('report.policy.operator.yearOfBirth')}:</Typography>
                      <Typography data-testid="yearOfBirth" className={classes.value}>
                        {o.onHold
                          ? '******'
                          : (!o.yearOfBirth && o.yearOfBirth !== 0 && t('report.common.notAvailable')) || o.yearOfBirth}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid container item xs={6} className={classes.colonneRight}>
                  {o.vehicles &&
                    o.vehicles.length > 0 &&
                    o.vehicles.map((ov, j) => {
                      return (
                        <Grid item xs={12} key={j}>
                          <Typography className={classes.label}>
                            {t('report.policy.vehicle.vehicleNumber', {
                              count: vehicles.findIndex((v) => v.id === ov.policyVehicleId) + 1,
                            })}
                            :
                          </Typography>
                          <Typography data-testid={`vehicleNumber${j + 1}`} className={classes.value}>
                            {(i18n.language.startsWith('fr')
                              ? ov.operatorType?.descriptionFr
                              : ov.operatorType?.descriptionEn) ||
                              t('report.common.notAvailable') ||
                              t('report.common.notAvailable')}
                          </Typography>
                        </Grid>
                      );
                    })}
                  <Grid item xs={12}>
                    <Typography className={classes.label}>{t('report.policy.operator.dateAddedToPolicy')}:</Typography>
                    <Typography data-testid="dateAddedToPolicy" className={classes.value}>
                      {o.startEarliestTerm
                        ? DateHelper.formatDateToLocalTime(o.startEarliestTerm, false)
                        : t('report.common.notAvailable')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      {t('report.policy.operator.dateDeletedFromPolicy')}:
                    </Typography>
                    <Typography data-testid="dateDeletedFromPolicy" className={classes.value}>
                      {o.endLatestTerm
                        ? DateHelper.formatDateToLocalTime(o.endLatestTerm, false)
                        : t('report.common.notApplicable')}
                    </Typography>
                  </Grid>
                  {/* false lines - empty space */}
                  <Box height="100%" />
                  <Box height="100%" />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default OperatorsList;
