import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertTitle, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import theme from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: '2px',
      color: 'white',
      background: 'black',
      borderColor: '#000000',
    },
  })
);

interface MessageProps {
  message: string;
  title?: string;
  severity: 'error' | 'success' | 'warning' | 'info';
  compact?: boolean;
}

const Message: FC<MessageProps> = ({ message, severity, compact = false, title = '' }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid item container justifyContent="flex-start" xs={12}>
      <Alert
        severity={severity}
        className={classes.alert}
        icon={false}
        data-testid="errorMessage"
        sx={{
          marginTop: compact ? theme.spacing(0) : theme.spacing(2),
          marginBottom: compact ? theme.spacing(0) : theme.spacing(2),
        }}
      >
        {title !== '' && <AlertTitle style={{ fontWeight: 'bold', fontSize: '11pts' }}>{t(title)}</AlertTitle>}
        {t(message)}
      </Alert>
    </Grid>
  );
};

export default Message;
