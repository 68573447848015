import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createDOMPurify from 'dompurify';

import { Disclaimers } from '../axios';
import { BlobDto } from '../Types';

export const GetDisclaimerContent = (): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [disclaimer, setDisclaimer] = useState('');

  const DOMPurify = createDOMPurify(window);

  useEffect(() => {
    const onLoadFromBlobStorage = () => {
      Disclaimers.getDisclaimer(i18n.language.substring(0, 2))
        .then((data: BlobDto) => {
          setDisclaimer(data.blobHtml);
        })
        .catch(() => {
          // if there is an error here then it's probably that the disclaimer hasn't been set yet. don't display error message.
          setDisclaimer('');
        });
    };

    onLoadFromBlobStorage();
  }, [i18n.language, t]);

  const sanitizedDisclaimer = DOMPurify.sanitize(disclaimer);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: sanitizedDisclaimer }} />
    </>
  );
};
