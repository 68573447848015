export interface VehicleLoss {
  code: string | null;
  descriptionEn: string | null;
  descriptionFr: string | null;
}

export const VehicleLossFactory = (props: VehicleLoss): VehicleLoss => {
  return {
    code: props.code,
    descriptionEn: props.descriptionEn,
    descriptionFr: props.descriptionFr,
  };
};

export default VehicleLoss;
