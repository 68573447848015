import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { instance } from '../axios';
import { ApplicationPaths } from '../Features/Authorization/AuthorizationConstants';
import {
  ERROR_403_IS_ACCOUNT_ENABLED,
  ERROR_403_IS_ALLOWED_TO_MANAGE,
  ERROR_403_IS_ALLOWED_TO_MANAGE_COMPANIES,
  ERROR_403_IS_ALLOWED_TO_MANAGE_FUNCTION,
} from '../Helpers/Constants';
import { addNotification, clearStore } from '../Slices/NotificationSlice';
import { ReactLocationState } from '../Types';
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog';

interface AxiosInterceptorProps {
  children: JSX.Element;
}

const AxiosInterceptor = ({ children }: AxiosInterceptorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation<ReactLocationState>();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | undefined>(undefined);

  useEffect(() => {
    const handleOnLogoutConfirm = () => {
      dispatch(clearStore());
      history.replace({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
      setConfirmDialogOpen(false);
      setConfirmDialogProps(undefined);
    };

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status && error.response?.status === 403) {
          switch (error.response.data) {
            case ERROR_403_IS_ALLOWED_TO_MANAGE:
              dispatch(addNotification(403, 'error', t('errorMessage.error403.isAllowedToManage')));
              throw new axios.Cancel('Operation canceled due to error 403.');
            case ERROR_403_IS_ALLOWED_TO_MANAGE_COMPANIES:
              dispatch(addNotification(403, 'error', t('errorMessage.error403.isAllowedToManageCompanies')));
              throw new axios.Cancel('Operation canceled due to error 403.');
            case ERROR_403_IS_ALLOWED_TO_MANAGE_FUNCTION:
              dispatch(addNotification(403, 'error', t('errorMessage.error403.isAllowedToManageFunction')));
              history.push(`/`, state);
              throw new axios.Cancel('Operation canceled due to error 403.');
            case ERROR_403_IS_ACCOUNT_ENABLED:
              // force logout
              setConfirmDialogProps({
                title: t('errorMessage.error403.isAccountEnabledDialogTitle'),
                contentText: `${t('errorMessage.error403.isAccountEnabled')}`,
                isDelete: false,
                isOkButton: true,
                buttonText: 'OK',
                onAction: handleOnLogoutConfirm,
              });
              setConfirmDialogOpen(true);
              throw new axios.Cancel('Operation canceled due to error 403.');
            default:
              dispatch(addNotification(403, 'error', t('errorMessage.error403.default')));
              throw new axios.Cancel('Operation canceled due to error 403.');
          }
        } else {
          throw error;
        }
      }
    );
  }, [dispatch, history, state, t]);

  return (
    <>
      {children}
      {confirmDialogOpen && confirmDialogProps && <ConfirmDialog {...confirmDialogProps} />}
    </>
  );
};

export default AxiosInterceptor;
