import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Typography } from '@mui/material';

import { GetDisclaimerContent } from '../Helpers/DisclaimerHelper';

const Disclaimer: FC = () => {
  const { t } = useTranslation();

  return (
    <Container data-testid="disclaimerContainer">
      <Box alignContent="flex-start" justifyContent="flex-start">
        <Typography variant="h4" color="textPrimary" sx={{ fontWeight: 'bold' }} align="justify">
          {t('disclaimer.title')}
        </Typography>
        {GetDisclaimerContent()}
      </Box>
    </Container>
  );
};

export default Disclaimer;
