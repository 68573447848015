import Organization from './Organization';
import ReportingCompany from './ReportingCompany';
import Unit from './Unit';

export interface UsageReportRequest {
  organizationId: number | null;
  reportingCompanyId: number | null;
  unitId: number | null;
  userId: string;
  userName: string;
  fromDate: Date | null;
  toDate: Date | null;
}

export const UsageReportRequestFactory = (props: UsageReportRequest): UsageReportRequest => {
  return {
    organizationId: props.organizationId,
    reportingCompanyId: props.reportingCompanyId,
    userId: props.userId,
    unitId: props.unitId,
    userName: props.userName,
    fromDate: props.fromDate,
    toDate: props.toDate,
  };
};

export interface UsageReportResponse {
  userId: string;
  userName: string | null;
  organization: Organization;
  reportingCompany: ReportingCompany | null;
  unit: string | null;
  numberOfRequest: number;
}

export const UsageReportResponseFactory = (props: UsageReportResponse): UsageReportResponse => {
  return {
    userId: props.userId,
    userName: props.userName,
    organization: props.organization,
    reportingCompany: props.reportingCompany,
    unit: props.unit,
    numberOfRequest: props.numberOfRequest,
  };
};

export interface UsageReportResponseCsv {
  userId: string;
  userName: string | null;
  organization: string;
  reportingCompany: string | null;
  numberOfRequest: number;
}

export default UsageReportRequest;
