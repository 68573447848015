import { requests } from '../axios';
import { ReportingCompany, ReportingCompanyFactory, UserFunctionCompany, UserFunctionCompanyFactory } from '../Types';

export const getByOrganizationCompanies = async (
  functionId: number,
  organizationId: number
): Promise<ReportingCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ReportingCompany[]>((resolve) => {
      resolve([
        ReportingCompanyFactory(1, organizationId, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        ReportingCompanyFactory(2, organizationId, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        ReportingCompanyFactory(3, organizationId, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        ReportingCompanyFactory(4, organizationId, 'INF', 'Intact Financial', 'Intact Financier'),
        ReportingCompanyFactory(5, organizationId, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        ReportingCompanyFactory(6, organizationId, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
        ReportingCompanyFactory(7, organizationId, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.get(`/reportingCompanies/getByOrganization/${functionId}/?organizationId=${organizationId}`, {});
  }
};

export const getByOrganizationWithoutUserFunction = async (organizationId: number): Promise<ReportingCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ReportingCompany[]>((resolve) => {
      resolve([
        ReportingCompanyFactory(1, organizationId, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        ReportingCompanyFactory(2, organizationId, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        ReportingCompanyFactory(3, organizationId, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        ReportingCompanyFactory(4, organizationId, 'INF', 'Intact Financial', 'Intact Financier'),
        ReportingCompanyFactory(5, organizationId, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        ReportingCompanyFactory(6, organizationId, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
        ReportingCompanyFactory(7, organizationId, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.get(
      `/reportingCompanies/getByOrganizationWithoutUserFunction/?organizationId=${organizationId}`,
      {}
    );
  }
};

export const getByOrganizationsCompanies = async (
  functionId: number,
  organizationsIds: Array<number>
): Promise<ReportingCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ReportingCompany[]>((resolve) => {
      resolve([
        ReportingCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        ReportingCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        ReportingCompanyFactory(3, 1, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        ReportingCompanyFactory(4, 1, 'INF', 'Intact Financial', 'Intact Financier'),
        ReportingCompanyFactory(5, 1, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        ReportingCompanyFactory(6, 1, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
        ReportingCompanyFactory(7, 1, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.post(
      `/reportingCompanies/getByOrganizations/${functionId}`,
      organizationsIds.map((o) => o)
    );
  }
};

export const getCompanies = async (functionId: number): Promise<ReportingCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ReportingCompany[]>((resolve) => {
      resolve([
        ReportingCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        ReportingCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        ReportingCompanyFactory(3, 1, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        ReportingCompanyFactory(4, 1, 'INF', 'Intact Financial', 'Intact Financier'),
        ReportingCompanyFactory(5, 1, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        ReportingCompanyFactory(6, 1, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
        ReportingCompanyFactory(7, 1, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    });
  } else {
    return requests.get(`/reportingCompanies/get/${functionId}`, {});
  }
};

export const getUserFunctionCompanies = async (functionId: number): Promise<UserFunctionCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<UserFunctionCompany[]>((resolve) => {
      resolve([
        UserFunctionCompanyFactory(null, 1, 'IBC', 'IBC', 'IBC'),
        UserFunctionCompanyFactory(null, 2, 'Broker', 'Broker Insurance', 'Assurances Broker'),
        UserFunctionCompanyFactory(1, 3, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        UserFunctionCompanyFactory(2, 3, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        UserFunctionCompanyFactory(3, 3, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        UserFunctionCompanyFactory(4, 3, 'INF', 'Intact Financial', 'Intact Financier'),
        UserFunctionCompanyFactory(5, 3, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Cgroix bleue du Manitoba'),
        UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.get(`/reportingCompanies/getUserFunctionCompanies/${functionId}`, {});
  }
};

export const GetCompaniesByUserFunction = async (functionId: number): Promise<ReportingCompany[]> => {
  return requests.get(`/reportingCompanies/getCompaniesByUserFunction/${functionId}`, {});
};

export const getUserFunctionCompaniesByOrganizations = async (
  functionId: number,
  organizationsIds: Array<number>
): Promise<UserFunctionCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<UserFunctionCompany[]>((resolve) => {
      resolve([
        UserFunctionCompanyFactory(null, 1, 'IBC', 'IBC', 'IBC'),
        UserFunctionCompanyFactory(null, 2, 'Broker', 'Broker Insurance', 'Assurances Broker'),
        UserFunctionCompanyFactory(1, 3, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        UserFunctionCompanyFactory(2, 3, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        UserFunctionCompanyFactory(3, 3, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        UserFunctionCompanyFactory(4, 3, 'INF', 'Intact Financial', 'Intact Financier'),
        UserFunctionCompanyFactory(5, 3, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        UserFunctionCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Cgroix bleue du Manitoba'),
        UserFunctionCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.post(`/ReportingCompanies/GetUserFunctionCompaniesByOrganizations/${functionId}`, organizationsIds);
  }
};

export const listCompanies = async (): Promise<ReportingCompany[]> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<ReportingCompany[]>((resolve) => {
      resolve([
        ReportingCompanyFactory(1, 1, 'DSI', 'Desjardins Insurance', 'Assurances Desjardins'),
        ReportingCompanyFactory(2, 1, 'ECI', 'Economical Insurance', 'Assurance économiques'),
        ReportingCompanyFactory(3, 2, 'FRF', 'Foresters Financial', 'Financière Foresters'),
        ReportingCompanyFactory(4, 2, 'INF', 'Intact Financial', 'Intact Financier'),
        ReportingCompanyFactory(5, 3, 'SNF', 'Sun Life Financial', 'Financière Sun Life'),
        ReportingCompanyFactory(6, 3, 'MBC', 'Manitoba Blue Cross', 'Croix bleue du Manitoba'),
        ReportingCompanyFactory(7, 3, 'CAA', 'CAA Insurance Company', "Compagnie d'assurance CAA"),
      ]);
    }).then((results) => {
      return results;
    });
  } else {
    return requests.get('/reportingCompanies/list', {});
  }
};
