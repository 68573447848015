import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Typography } from '@mui/material';

import { GetFaqContent } from '../Helpers/FaqHelper';

const Faq: FC = () => {
  const { t } = useTranslation();

  return (
    <Container data-testid="faqContainer">
      <Box alignContent="flex-start" justifyContent="flex-start">
        <Typography variant="h4" color="textPrimary" sx={{ fontWeight: 'bold' }} align="justify">
          {t('settings.faq.title')}
        </Typography>
        {GetFaqContent()}
      </Box>
    </Container>
  );
};

export default Faq;
