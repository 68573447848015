import { Organization, ReportingCompany, Unit, UserStatus } from './index';

export interface UserProfileSearchRequest {
  organizationId: number | null;
  reportingCompanyId: number | null;
  unitId: number | null;
  status: UserStatus;
  userId: string | null;
  userName: string | null;
}

export const UserProfileSearchRequestFactory = (props: UserProfileSearchRequest): UserProfileSearchRequest => {
  return {
    organizationId: props.organizationId,
    reportingCompanyId: props.reportingCompanyId,
    unitId: props.unitId,
    status: props.status,
    userId: props.userId,
    userName: props.userName,
  };
};

export interface UserProfileSearchResult {
  id: number;
  userId: string;
  userName: string;
  organization: Organization;
  reportingCompany: ReportingCompany | null;
  unit: Unit | null;
  status: UserStatus;
}

export const UserProfileSearchResultFactory = (props: UserProfileSearchResult): UserProfileSearchResult => {
  return {
    id: props.id,
    userId: props.userId,
    userName: props.userName,
    organization: props.organization,
    reportingCompany: props.reportingCompany,
    unit: props.unit,
    status: props.status,
  };
};
