export interface DataCorrection {
  id: number;
  nameEn: string;
  nameFr: string;
}

export const DataCorrectionFactory = (props: DataCorrection): DataCorrection => {
  return {
    id: props.id,
    nameEn: props.nameEn,
    nameFr: props.nameFr,
  };
};

export default DataCorrection;
