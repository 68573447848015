export interface OrganizationCompany {
  id: number;
  organizationId: number;
  reportingCompanyId?: number;
  isIbcCredentials: boolean;
}

export const OrganizationCompanyFactory = (
  id: number,
  isIbcCredentials: boolean,
  organizationId: number,
  reportingCompanyId?: number
): OrganizationCompany => {
  return {
    id,
    isIbcCredentials,
    organizationId,
    reportingCompanyId,
  };
};

export default OrganizationCompany;
