import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TabList } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, Container, Grid, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';

import ContainerTitle from '../Components/ContainerTitle';
import Loader from '../Components/Loader';
import B2CSettings from '../Features/Settings/B2CSettings/B2CSettings';
import ClientSettings from '../Features/Settings/ClientSettings';
import { User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  tab: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
    fontSize: '1rem',
    '&.Mui-selected': {
      fontWeight: 'bold !important',
    },
  },
}));

interface B2CSetupProps extends RouteComponentProps {
  user: User;
}

enum SelectedTabEnum {
  clientSettings = 'clientSettings',
  b2cSettings = 'b2cSettings',
}

const B2CSetup: FC<B2CSetupProps> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<SelectedTabEnum>(SelectedTabEnum.clientSettings);

  const handleTabClick = (event: React.SyntheticEvent, newValue: SelectedTabEnum) => {
    setSelectedTab(SelectedTabEnum[newValue]);
  };

  return user ? (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} className={classes.title}>
          <ContainerTitle title={t('settings.title')} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} className={classes.title}>
          <Card elevation={3}>
            <Grid item container xs={12} className={classes.title} justifyContent="space-between">
              <Grid item xs={12}>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Stack direction="row">
                      <TabList
                        orientation="horizontal"
                        variant="scrollable"
                        value={selectedTab}
                        onChange={handleTabClick}
                        aria-label="settings-screen-tabs"
                        data-testid="firstTabList"
                      >
                        <Tab
                          className={classes.tab}
                          label={t('settings.clientSettings.title')}
                          value={SelectedTabEnum.clientSettings}
                          data-testid="tabB2cSettings"
                        />
                        <Tab
                          className={classes.tab}
                          label={t('settings.b2cSettings.title')}
                          value={SelectedTabEnum.b2cSettings}
                          data-testid="tabB2cSettings"
                        />
                      </TabList>
                    </Stack>
                  </Box>
                  <TabPanel value={SelectedTabEnum.clientSettings}>
                    <ClientSettings />
                  </TabPanel>
                  <TabPanel value={SelectedTabEnum.b2cSettings}>
                    <B2CSettings />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Loader open={true} />
  );
};

export default withRouter(B2CSetup);
