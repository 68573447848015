import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../theme';

const useStyles = makeStyles(() =>
  createStyles({
    boldText: {
      fontWeight: 500,
      '&:disabled': {
        color: theme.palette.primary.main,
      },
      padding: 0,
    },
  })
);

const ChangePasswordMenu: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push('/change-password');
  };

  return (
    <Button
      size="large"
      variant="text"
      color="secondary"
      onClick={handleClick}
      className={classes.boldText}
      data-testid="changePassword"
    >
      {t('navbar.changePassword')}
    </Button>
  );
};

export default ChangePasswordMenu;
