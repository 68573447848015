export interface NotificationRecipientOption {
  id: number;
  organizationId: number;
  reportingCompanyId?: number;
  email: string;
}

export const NotificationRecipientOptionFactory = (
  id: number,
  email: string,
  organizationId: number,
  reportingCompanyId?: number
): NotificationRecipientOption => {
  return {
    id,
    email,
    organizationId,
    reportingCompanyId,
  };
};

export default NotificationRecipientOption;
