import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { Button, Card, CardContent, Container, Grid } from '@mui/material';

import { ReportingCompanies, Users } from '../../../../axios';
import ConfirmDialogCommon, {
  ConfirmDialogProps as ConfirmDialogPropsCommon,
} from '../../../../Components/ConfirmDialog';
import ContainerTitle from '../../../../Components/ContainerTitle';
import Loader from '../../../../Components/Loader';
import {
  ERROR_400_BROKER_NO_SPONSOR,
  ERROR_400_COMPANY_NOT_COMPANYCR,
  ERROR_400_COMPANY_NOT_IBCCR,
  ERROR_400_DOMAIN_NAME_NOT_PERMITTED_EMAIL,
  ERROR_400_DOMAIN_NAME_NOT_PERMITTED_USERID,
  ERROR_400_EMAIL_IN_USE,
  ERROR_400_USERID_IN_USE,
} from '../../../../Helpers/Constants';
import { EMAIL_REGEX } from '../../../../Helpers/Regex';
import useErrorHandler from '../../../../Hooks/UseErrorHandler';
import useIbcOrThirdPartySelected from '../../../../Hooks/UseIbcOrThirdPartySelected';
import { addNotification } from '../../../../Slices/NotificationSlice';
import { GetAllOrganizations, getOrganizationsByUserAccess } from '../../../../Slices/OrganizationSlice';
import { getConnectedUser, GetProfile } from '../../../../Slices/UserSlice';
import { AppDispatch } from '../../../../Store';
import theme from '../../../../theme';
import {
  Functions,
  ModeEnum,
  ReactLocationState,
  ReportingCompany,
  User,
  UserDriverReportOption,
  UserFunction,
} from '../../../../Types';
import { UserTypeEnum } from '../../../../Types/Enums';
import ConfirmDialog, { ConfirmDialogProps } from '../../Dialogs/ConfirmDialog';
import UserDriverOptions from './UserDriverOptions';
import UserFunctions from './UserFunctions';
import UserInfos from './UserInfo';

export interface FormValuesCreateEditUserProfileProps {
  id: number;
  userType: UserTypeEnum | null;
  userId: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string;
  mfaPhone: string | null;
  organizationId: number | null;
  rptCompanyId: number | null;
  unitId: number | null;
  assignedOrganizationsIds: Array<number>;
  userFunctions: Array<UserFunction>;
  userDriverReportOptions: Array<UserDriverReportOption>;
  isDisabled: boolean;
  sub: string | null;
  isLastTermsOfServiceAccepted?: boolean;
}

export interface UserPatch {
  id: number;
  userId: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string;
  isDisabled: boolean;
  organizationId: number;
  reportingCompanyId: number | null;
  unitId: number | null;
  userFunctions: Array<UserFunctionPatch>;
  userDriverReportOptions: Array<UserDriverReportOptionPatch>;
}

export interface UserFunctionPatch {
  userId: number;
  functionId: number;
  organizationId: number | null;
  reportingCompanyId: number | null;
}

export interface UserDriverReportOptionPatch {
  userId: number;
  driverReportOptionId: number;
  isDefault: boolean;
}

const CreateEditUserProfile: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const { state } = useLocation<ReactLocationState>();

  const mode = state && state.userProfileMode ? state.userProfileMode : ModeEnum.Create;
  const userProfileToEdit = state && state.userProfileToEdit ? state.userProfileToEdit : null;

  const connectedUser = useSelector((state: { user: any }) => getConnectedUser(state));
  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state));
  const isLoadingOrganizations = useSelector(
    (state: { organization: { isPending: boolean } }) => state.organization.isPending
  );

  const [formValues, setFormValues] = useState<FormValuesCreateEditUserProfileProps>(
    userProfileToEdit
      ? {
          id: userProfileToEdit.id,
          userType: UserTypeEnum[userProfileToEdit.userType as unknown as keyof typeof UserTypeEnum],
          userId: userProfileToEdit.userId,
          firstName: userProfileToEdit.firstName,
          middleName: userProfileToEdit.middleName,
          lastName: userProfileToEdit.lastName,
          email: userProfileToEdit.email,
          mfaPhone: userProfileToEdit.mfaPhone
            ? t('phonenumber', { val: userProfileToEdit.mfaPhone.replace('+1', '') })
            : '',
          organizationId: userProfileToEdit.organizationId,
          rptCompanyId: userProfileToEdit.reportingCompanyId,
          unitId: userProfileToEdit.unitId,
          assignedOrganizationsIds: userProfileToEdit.assignedOrganizationsIds,
          userFunctions: userProfileToEdit.userFunctions,
          userDriverReportOptions: userProfileToEdit.userDriverReportOptions,
          isDisabled: userProfileToEdit.isDisabled,
          sub: userProfileToEdit.sub,
          isLastTermsOfServiceAccepted: userProfileToEdit.isLastTermsOfServiceAccepted,
        }
      : {
          id: 0,
          userType: connectedUser.isIbcCredentials ? UserTypeEnum.ibccr : UserTypeEnum.company,
          userId: '',
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          mfaPhone: '',
          organizationId: organizations && organizations.length === 1 ? organizations[0].id : null,
          rptCompanyId: null,
          unitId: null,
          assignedOrganizationsIds: [],
          userFunctions: [],
          userDriverReportOptions: [],
          isDisabled: false,
          sub: null,
          isLastTermsOfServiceAccepted: undefined,
        }
  );

  const enableOrDisableUser = useRef(false);

  // rpt companies for the dros (based on assignedOrgs)
  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany>>([]);
  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);

  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [isNeedToSave, setIsNeedToSave] = useState<boolean>(false);

  const [firstNameFieldError, setFirstNameFieldError] = useState<string>('');
  const [lastNameFieldError, setLastNameFieldError] = useState<string>('');
  const [middleNameFieldError, setMiddleNameFieldError] = useState<string>('');
  const [organizationFieldError, setOrganizationFieldError] = useState<string>('');
  const [employedAtCompanyFieldError, setEmployedAtCompanyFieldError] = useState<string>('');
  const [isUserIdError, setIsUserIdError] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isDuplicateUserIdError, setIsDuplicateUserIdError] = useState<boolean>(false);
  const [isDuplicateEmailError, setIsDuplicateEmailError] = useState<boolean>(false);
  const [isNoSponsorError, setIsNoSponsorError] = useState<boolean>(false);
  const [isPermittedEmailDomainError, setIsPermittedEmailDomainError] = useState<boolean>(false);
  const [isPermittedUserIdDomainError, setIsPermittedUserIdDomainError] = useState<boolean>(false);
  const [isCompanyNotIbccrError, setIsCompanyNotIbccrError] = useState<boolean>(false);
  const [isCompanyNotCompanycrError, setIsCompanyNotCompanycrError] = useState<boolean>(false);
  const [isMfaFieldError, setIsMfaFieldError] = useState<boolean>(false);
  const [isUserFuncError, setIsUserFuncError] = useState<boolean>(false);
  const [isDroError, setIsDroError] = useState<boolean>(false);
  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(formValues.organizationId);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | undefined>(undefined);
  const [confirmDialogCommonOpen, setConfirmDialogCommonOpen] = useState<boolean>(false);
  const [confirmDialogPropsCommon, setConfirmDialogPropsCommon] = useState<ConfirmDialogPropsCommon | undefined>(
    undefined
  );

  // Needs to be handled outside formValues because of automatic selection when only 1 element
  const [employedAtComp, setEmployedAtComp] = useState<number | null>(
    userProfileToEdit ? userProfileToEdit.reportingCompanyId : null
  );
  // Needs to be handled outside formValues because of automatic selection when only 1 element
  const [employedAtUnit, setEmployedAtUnit] = useState<number | null>(
    userProfileToEdit ? userProfileToEdit.unitId : null
  );

  const scrollToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  // When you were scroll at the search, the stack does not reset the scroll, so we need to force it
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(getOrganizationsByUserAccess({ functionId: Functions.userAdministration }));
  }, [dispatch]);

  // Fetch all rpt companies that the user can select in general base on assignedOrgs
  useEffect(() => {
    if (formValues.assignedOrganizationsIds.length > 0) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganizations(Functions.userAdministration, formValues.assignedOrganizationsIds)
        .then((organizations) => {
          setFetchedRptCompanies(organizations);
          setIsLoadingRpt(false);
        })
        .catch((error) => {
          handleError(error, { error403: t('userAdmin.error403') });
          setIsLoadingRpt(false);

          if (error.message.includes('403')) {
            history.push(state.from, state);
          }
        });
    }
  }, [formValues.assignedOrganizationsIds, handleError, history, state, t]);

  // Update the translation if the user changes the language
  useEffect(() => {
    if (firstNameFieldError && !formValues.firstName?.trim()) {
      setFirstNameFieldError(t('userProfile.errorMessage.firstNameRequired'));
      if (lastNameFieldError && !formValues.lastName?.trim()) {
        setLastNameFieldError(t('userProfile.errorMessage.lastNameRequired'));
      } else {
        setLastNameFieldError(' ');
      }
      setMiddleNameFieldError(' ');
    }
    if (lastNameFieldError && !formValues.lastName?.trim()) {
      setLastNameFieldError(t('userProfile.errorMessage.lastNameRequired'));
      if (firstNameFieldError && !formValues.firstName?.trim()) {
        setFirstNameFieldError(t('userProfile.errorMessage.firstNameRequired'));
      } else {
        setFirstNameFieldError(' ');
      }
      setMiddleNameFieldError(' ');
    }
    if (organizationFieldError) {
      setOrganizationFieldError(t('userProfile.errorMessage.organizationRequired'));
    }
    if (employedAtCompanyFieldError) {
      setEmployedAtCompanyFieldError(t('userProfile.errorMessage.employedAtCompanyRequired'));
    }
  }, [
    t,
    i18n,
    firstNameFieldError,
    lastNameFieldError,
    formValues.firstName,
    formValues.lastName,
    organizationFieldError,
    employedAtCompanyFieldError,
  ]);

  // Delete the message error if user change value
  useEffect(() => {
    if (firstNameFieldError && !!formValues.firstName?.trim()) {
      if (lastNameFieldError && !!formValues.lastName?.trim()) {
        setFirstNameFieldError('');
        setLastNameFieldError('');
        setMiddleNameFieldError('');
      }
    }
    if (lastNameFieldError && !!formValues.lastName?.trim()) {
      if (firstNameFieldError && !!formValues.firstName?.trim()) {
        setLastNameFieldError('');
        setFirstNameFieldError('');
        setMiddleNameFieldError('');
      }
    }
    if (organizationFieldError && formValues.organizationId) {
      setOrganizationFieldError('');
    }
    if (employedAtCompanyFieldError && (!formValues.organizationId || formValues.rptCompanyId)) {
      setEmployedAtCompanyFieldError('');
    }
  }, [
    t,
    firstNameFieldError,
    organizationFieldError,
    lastNameFieldError,
    middleNameFieldError,
    employedAtCompanyFieldError,
    formValues.organizationId,
    formValues.firstName,
    formValues.lastName,
    formValues.rptCompanyId,
    formValues.email,
    isNoSponsorError,
  ]);

  const handleResetValidationErrors = () => {
    setIsPermittedEmailDomainError(false);
    setIsPermittedUserIdDomainError(false);
    setIsCompanyNotIbccrError(false);
    setIsCompanyNotCompanycrError(false);
    setIsNoSponsorError(false);
    setIsEmailError(false);
    setIsUserFuncError(false);
    setIsDroError(false);
  };

  const showNotSavedMessage = useCallback(() => {
    dispatch(addNotification(400, 'error', t('userProfile.errorMessage.notSaved')));
  }, [dispatch, t]);

  const handleError400 = useCallback(
    (error: any) => {
      if (
        (typeof error.response.data == 'string' || typeof error.response.data == 'object') &&
        !error.response.data.status
      ) {
        if (error.response.data.includes(ERROR_400_DOMAIN_NAME_NOT_PERMITTED_USERID)) {
          setIsPermittedUserIdDomainError(true);
          scrollToTop();
        }
        if (error.response.data.includes(ERROR_400_DOMAIN_NAME_NOT_PERMITTED_EMAIL)) {
          setIsPermittedEmailDomainError(true);
          scrollToTop();
        }
        if (error.response.data.includes(ERROR_400_COMPANY_NOT_IBCCR)) {
          setIsCompanyNotIbccrError(true);
          scrollToTop();
        }
        if (error.response.data.includes(ERROR_400_COMPANY_NOT_COMPANYCR)) {
          setIsCompanyNotCompanycrError(true);
          scrollToTop();
        }
        if (error.response.data.includes(ERROR_400_BROKER_NO_SPONSOR)) {
          setIsNoSponsorError(true);
          scrollToTop();
        }
        if (error.response.data.includes(ERROR_400_EMAIL_IN_USE)) {
          setIsDuplicateEmailError(true);
          scrollToTop();
        }
        if (error.response.data.includes(ERROR_400_USERID_IN_USE)) {
          setIsDuplicateUserIdError(true);
          scrollToTop();
        }
      } else {
        dispatch(addNotification(400, 'error', t('errorMessage.error400')));
      }
    },
    [dispatch, t]
  );

  const handleSave = useCallback(() => {
    let showErrorMessage = false;

    if (mode === ModeEnum.Create && (!formValues.userId?.trim() || !EMAIL_REGEX.test(formValues.userId))) {
      setIsUserIdError(true);
      showErrorMessage = true;
    }

    if (!formValues.firstName?.trim()) {
      if (!formValues.lastName?.trim()) {
        setLastNameFieldError(t('userProfile.errorMessage.lastNameRequired'));
      } else {
        setLastNameFieldError('');
      }
      setFirstNameFieldError(t('userProfile.errorMessage.firstNameRequired'));
      setMiddleNameFieldError(' ');
      showErrorMessage = true;
    } else if (!formValues.lastName?.trim()) {
      setLastNameFieldError(t('userProfile.errorMessage.lastNameRequired'));
      setMiddleNameFieldError(' ');
      if (!formValues.firstName?.trim()) {
        setFirstNameFieldError(t('userProfile.errorMessage.firstNameRequired'));
      } else {
        setFirstNameFieldError(' ');
      }
      showErrorMessage = true;
    } else {
      setLastNameFieldError('');
      setFirstNameFieldError('');
      setMiddleNameFieldError('');
    }

    if (mode === ModeEnum.Create && (!formValues.email?.trim() || !EMAIL_REGEX.test(formValues.email))) {
      setIsEmailError(true);
      showErrorMessage = true;
    }
    if (formValues.organizationId === null) {
      setOrganizationFieldError(t('userProfile.errorMessage.organizationRequired'));
      showErrorMessage = true;
    } else {
      setOrganizationFieldError('');
    }
    if (!isIbcOrThirdPartySelected && formValues.organizationId && employedAtComp === null) {
      setEmployedAtCompanyFieldError(t('userProfile.errorMessage.employedAtCompanyRequired'));
      showErrorMessage = true;
    } else {
      setEmployedAtCompanyFieldError('');
    }
    if (
      formValues.userType === UserTypeEnum.ibccr &&
      (!formValues.mfaPhone?.trim() || formValues.mfaPhone.length < 12)
    ) {
      setIsMfaFieldError(true);
      showErrorMessage = true;
    }
    if (
      !enableOrDisableUser.current &&
      (formValues.userFunctions.length === 0 ||
        formValues.userFunctions.some(
          (uf) =>
            uf.functionId !== Functions.vehicleReport &&
            uf.functionId != Functions.manageDashClients &&
            uf.functionId !== Functions.manageSystemFunction &&
            uf.reportingCompanies.length === 0
        ))
    ) {
      setIsUserFuncError(true);
      showErrorMessage = true;
    }
    if (
      (formValues.userDriverReportOptions.length > 0 &&
        !formValues.userDriverReportOptions.some((udro) => udro.isDefault)) ||
      (formValues.userFunctions.some((uf) => uf.functionId === Functions.driverReport) &&
        formValues.userDriverReportOptions.length === 0)
    ) {
      setIsDroError(true);
      showErrorMessage = true;
    }

    if (showErrorMessage) {
      showNotSavedMessage();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    setIsLoadingSave(true);
    handleResetValidationErrors();

    const isDisabled = enableOrDisableUser.current ? !formValues.isDisabled : formValues.isDisabled;
    const user: User = {
      id: state.userProfileToEdit?.id ?? 0,
      userType: connectedUser.isIbcCredentials ? UserTypeEnum.ibccr : formValues.userType ?? UserTypeEnum.company,
      userId: formValues.userId,
      firstName: formValues.firstName,
      middleName: formValues.middleName ?? null,
      lastName: formValues.lastName,
      sub: state.userProfileToEdit?.sub ?? null,
      termsOfUseAccepted: state.userProfileToEdit?.termsOfUseAccepted ?? null,
      email: formValues.email,
      mfaPhone:
        formValues.userType === UserTypeEnum.ibccr
          ? formValues.mfaPhone
            ? '+1' + formValues.mfaPhone.replace(/ /g, '')
            : null
          : null,
      isDisabled: isDisabled,
      isIbcCredentials: false,
      assignedOrganizationsIds: enableOrDisableUser.current && isDisabled ? [] : formValues.assignedOrganizationsIds,
      organizationId: formValues.organizationId ?? 0,
      reportingCompanyId: employedAtComp,
      unitId: employedAtUnit,
      previousLogin: null,
      userFunctions: enableOrDisableUser.current && isDisabled ? [] : formValues.userFunctions,
      userDriverReportOptions: enableOrDisableUser.current && isDisabled ? [] : formValues.userDriverReportOptions,
      isLastTermsOfServiceAccepted: formValues.isLastTermsOfServiceAccepted,
    };

    if (mode === ModeEnum.Update) {
      const handleSuccessResponse = () => {
        if (enableOrDisableUser.current) {
          if (formValues.isDisabled) {
            dispatch(addNotification(200, 'success', t('userProfile.update.enabled')));
          } else {
            dispatch(addNotification(200, 'success', t('userProfile.update.disabled')));
          }
        } else {
          dispatch(addNotification(200, 'success', t('userProfile.update.ok200')));
        }

        // if user is being enabled, stay on the same page and update the isDisabled status
        if (enableOrDisableUser.current && formValues.isDisabled) {
          setFormValues({
            ...formValues,
            isDisabled: false,
          });
        } else {
          history.push(state.from, state);
        }
      };

      const handleOwnAccountUpdate = () => {
        dispatch(GetProfile())
          .unwrap()
          .then((data) => {
            setIsLoadingSave(false);
            if (data.length > 0) {
              const updatedUser = data[0];
              if (updatedUser.userFunctions.some((f) => f.functionId === Functions.userAdministration)) {
                handleSuccessResponse();
              } else {
                dispatch(addNotification(200, 'success', t('userProfile.update.ok200')));
                history.push('/', state);
              }
            }
          })
          .catch((error) => {
            handleError(error, { error403: t('userAdmin.error403') });
            if (error.message.includes('403')) {
              history.push(state.from, state);
            }
          });
      };

      Users.updateUser(user)
        .then((results) => {
          state.userProfileToEdit = results;
          setIsNeedToSave(false);

          const userUpdatedOwnAccount =
            !enableOrDisableUser.current && connectedUser.id === state.userProfileToEdit?.id;

          if (userUpdatedOwnAccount) {
            handleOwnAccountUpdate();
          } else {
            setIsLoadingSave(false);
            handleSuccessResponse();
          }
        })
        .catch((error) => {
          if (error.message.includes('400')) {
            showNotSavedMessage();
            handleError400(error);
          } else {
            handleError(error, { error403: t('userAdmin.error403') });
            if (error.message.includes('403')) {
              history.push(state.from, state);
            }
          }
          setIsNeedToSave(false);
          setIsLoadingSave(false);
        })
        .finally(() => {
          enableOrDisableUser.current = false;
        });
    } else {
      Users.create(user)
        .then((results) => {
          state.userProfileToEdit = results;
          setIsLoadingSave(false);
          dispatch(addNotification(200, 'error', t('userProfile.create.ok200')));
          history.push(state.from, state);
        })
        .catch((error) => {
          if (error.message.includes('400')) {
            showNotSavedMessage();
            handleError400(error);
          } else {
            handleError(error, { error403: t('userAdmin.error403') });
            if (error.message.includes('403')) {
              history.push(state.from, state);
            }
          }
          setIsLoadingSave(false);
        });
    }
  }, [
    mode,
    formValues,
    isIbcOrThirdPartySelected,
    employedAtComp,
    state,
    connectedUser.isIbcCredentials,
    connectedUser.id,
    employedAtUnit,
    t,
    showNotSavedMessage,
    dispatch,
    history,
    handleError400,
    handleError,
  ]);

  const handleCancel = () => {
    history.push(state.from, state);
  };

  const handleSendInvitation = () => {
    Users.sendInvitation(formValues.userId)
      .then(() => {
        dispatch(addNotification(200, 'error', t('userProfile.invite.ok200')));
      })
      .catch((error) => {
        handleError(error, { error404: t('userProfile.invite.error404') });
      });
  };

  const handleDeleteAccount = () => {
    if (state.userProfileToEdit?.previousLogin === null) {
      setConfirmDialogPropsCommon({
        title: t('userProfile.delete.titleConfirmDelete'),
        contentText: t('userProfile.delete.contentConfirmDelete'),
        isDelete: true,
        buttonText: '',
        onAction: (isConfirmed: boolean) => {
          if (isConfirmed) {
            setIsLoadingSave(true);
            Users.deleteUserAccount(formValues.id, true)
              .then(() => {
                dispatch(addNotification(200, 'error', t('userProfile.delete.ok200')));
                setIsLoadingSave(false);
                history.push(state.from, state);
              })
              .catch((error) => {
                handleError(error, { error404: t('userProfile.delete.error404') });
                setIsLoadingSave(false);
              });
          }
          setConfirmDialogCommonOpen(false);
          setConfirmDialogPropsCommon(undefined);
        },
      });
      setConfirmDialogCommonOpen(true);
    }
  };

  useEffect(() => {
    if (isNeedToSave) {
      handleSave();
    }
  }, [isNeedToSave, handleSave]);

  const handleEnableUserChange = (isDisabled: boolean) => {
    setConfirmDialogProps({
      itemToDelete: undefined,
      contentText: isDisabled
        ? t('userProfile.create.contentConfirmDisable')
        : t('userProfile.create.contentConfirmEnable'),
      isDelete: false,
      isDisabled: !isDisabled,
      onDeleted: () => null,
      onAction: (isConfirmed: boolean) => {
        if (isConfirmed) {
          enableOrDisableUser.current = true;
          setIsNeedToSave(true);
        } else {
          setIsNeedToSave(false);
        }
        setConfirmDialogOpen(false);
        setConfirmDialogProps(undefined);
      },
    });
    setConfirmDialogOpen(true);
  };

  const disableButton =
    mode === ModeEnum.Update ? (
      <Grid item container xs={3} justifyContent="flex-end">
        <Button
          fullWidth
          variant="contained"
          id="btnSave"
          onClick={() => handleEnableUserChange(!formValues.isDisabled)}
          data-testid="btnSave"
        >
          {formValues.isDisabled ? t('userProfile.create.lblBtnEnableAcc') : t('userProfile.create.lblBtnDisableAcc')}
        </Button>
      </Grid>
    ) : (
      <></>
    );

  return (
    <Container sx={{ paddingBottom: theme.spacing(13) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContainerTitle
            title={mode === ModeEnum.Create ? t('userProfile.create.header') : t('userProfile.update.header')}
            button={disableButton}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Card elevation={5} data-testid="userProfileCard">
          <CardContent>
            <Grid container padding={2}>
              <UserInfos
                mode={mode}
                formValues={formValues}
                setFormValues={setFormValues}
                organizations={organizations}
                isLoadingOrganizations={isLoadingOrganizations}
                isIbcOrThirdPartySelected={isIbcOrThirdPartySelected}
                data-testid="userInfos"
                firstNameFieldError={firstNameFieldError}
                middleNameFieldError={middleNameFieldError}
                lastNameFieldError={lastNameFieldError}
                organizationFieldError={organizationFieldError}
                employedAtCompanyFieldError={employedAtCompanyFieldError}
                employedAtComp={employedAtComp}
                setEmployedAtComp={setEmployedAtComp}
                employedAtUnit={employedAtUnit}
                setEmployedAtUnit={setEmployedAtUnit}
                isEmailError={isEmailError}
                setIsNoSponsorError={setIsNoSponsorError}
                isPermittedEmailDomainError={isPermittedEmailDomainError}
                isPermittedUserIdDomainError={isPermittedUserIdDomainError}
                isCompanyNotIbccrError={isCompanyNotIbccrError}
                isCompanyNotCompanycrError={isCompanyNotCompanycrError}
                isNoSponsorError={isNoSponsorError}
                isMfaFieldError={isMfaFieldError}
                isUserIdError={isUserIdError}
                isDuplicateUserIdError={isDuplicateUserIdError}
                isDuplicateEmailError={isDuplicateEmailError}
              />
              <Grid item container xs={12}>
                <UserFunctions
                  formValues={formValues}
                  setFormValues={setFormValues}
                  assignedOrganizationsIds={formValues.assignedOrganizationsIds}
                  isLoadingRpt={isLoadingRpt}
                  userToEditId={userProfileToEdit?.id ?? null}
                  isUserFuncError={isUserFuncError}
                />
              </Grid>
              {formValues.userFunctions.some((uf) => uf.functionId === Functions.driverReport) && (
                <Grid item container xs={12}>
                  <UserDriverOptions
                    formValues={formValues}
                    setFormValues={setFormValues}
                    organizations={organizations}
                    isLoadingOrganizations={isLoadingOrganizations}
                    rptCompanies={fetchedRptCompanies}
                    isLoadingRpt={isLoadingRpt}
                    userToEditId={userProfileToEdit?.id ?? null}
                    isDroError={isDroError}
                  />
                </Grid>
              )}
              <Grid
                item
                container
                xs={12}
                spacing={1}
                justifyContent="space-between"
                sx={{ marginTop: theme.spacing(2) }}
              >
                <Grid item container xs={6} justifyContent="flex-start" spacing={1}>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      id="btnSave"
                      onClick={() => handleSave()}
                      data-testid="btnSave"
                      disabled={formValues.userType === null || formValues.isDisabled}
                    >
                      {t('userProfile.create.lblBtnSave')}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      onClick={() => handleCancel()}
                      variant="contained"
                      color="secondary"
                      data-testid="btnCancel"
                    >
                      {t('userProfile.create.lblBtnCancel')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container justifyContent="flex-end" xs={6} spacing={1}>
                  {mode === ModeEnum.Update && !(formValues.sub || formValues.isDisabled) && (
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        onClick={() => handleSendInvitation()}
                        variant="contained"
                        data-testid="btnSendInvitation"
                      >
                        {t('userProfile.create.lblBtnResendInvitation')}
                      </Button>
                    </Grid>
                  )}
                  {mode === ModeEnum.Update && !formValues.sub && (
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        onClick={() => handleDeleteAccount()}
                        disabled={state.userProfileToEdit?.previousLogin !== null}
                        variant="contained"
                        data-testid="btnDeleteAccount"
                      >
                        {t('userProfile.create.lblBtnDeleteAccount')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Loader open={isLoadingSave} />
      {confirmDialogOpen && confirmDialogProps && <ConfirmDialog {...confirmDialogProps} />}
      {confirmDialogCommonOpen && confirmDialogPropsCommon && <ConfirmDialogCommon {...confirmDialogPropsCommon} />}
    </Container>
  );
};

export default withRouter(CreateEditUserProfile);
