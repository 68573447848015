import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    left: '7px',
    right: 'unset',
    top: '7px',
    minWidth: '40px',
    height: '40px',
    borderRadius: '20px',
    backgroundColor: 'red',
    padding: '0 4px',
    transform: 'scale(1)',
    fontSize: '1.5rem',
    fontWeight: '550',
    transition: 'unset',
  },
}));

export default StyledBadge;
