/* eslint-disable no-alert */
import Constants from '../../Helpers/Constants';

export const ApplicationName = 'xx';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message',
  LoginHint: 'loginHint',
  DomainHint: 'domainHint',
  InvitationId: 'invitationId',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
};

export const ApplicationUrl = {
  Search: 'search',
  Report: 'report',
};

const prefix = '/authentication';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  AuthorityUrl: Constants.authorityUrl,
  ClientId: Constants.clientId,
  Scope: Constants.scope,
  AuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  Search: `/${ApplicationUrl.Search}`,
  LoginPage: `/`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: 'Identity/Account/Register',
  IdentityManagePath: 'Identity/Account/Manage',
};
