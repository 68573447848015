import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import * as DateHelper from '../../Helpers/DateHelper';
import { getConnectedUser } from '../../Slices/UserSlice';
import theme from '../../theme';
import { Claims, Functions } from '../../Types';
import ClaimDetail from './ClaimDetail';
import EditDialogClaim from './DataCorrection/Dialogs/EditDialogClaim';
import EditButton from './DataCorrection/EditButton';

interface ClaimItemProps {
  claim?: Claims;
  expand?: boolean;
  isCorrectionMode: boolean;
  onAccordionStateChanged: () => void;
  reportId: string;
  operatorKey: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      fontSize: '16pt',
      marginTop: '10px',
    },
    text: {
      textAlign: 'justify',
      fontWeight: 700,
      fontSize: '11pt',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('lg')]: {
        fontSize: '10pt',
      },
    },
  })
);

const ClaimItem: FC<ClaimItemProps> = ({
  claim,
  expand = false,
  isCorrectionMode,
  onAccordionStateChanged,
  reportId,
  operatorKey,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const user = useSelector((state: { user: any }) => getConnectedUser(state));

  const [expanded, setExpanded] = useState<boolean>(expand);

  const [autocompleteEditDialogOpen, setAutocompleteEditDialogOpen] = useState<boolean>(false);

  const isAllowedToEditClaim = !claim?.isThirdParty;

  const isAllowedToEditClaimAtFault = isAllowedToEditClaim && !claim?.comprehensiveFlag;

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  useEffect(() => {
    onAccordionStateChanged();
  }, [expanded, onAccordionStateChanged]);

  const isUserAllowedToEditClaim = useCallback(() => {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      return true;
    } else {
      const currentClaimCompany = claim?.carrier;

      if (!currentClaimCompany) return false;
      if (!currentClaimCompany.code) return false;

      const userCompanies = user.userFunctions.find(
        (oneUf) => oneUf.functionId === Functions.dataCorrection
      )?.reportingCompanies;

      return !!userCompanies?.find((oneComp) => oneComp.code === currentClaimCompany.code);
    }
  }, [claim?.carrier, user.userFunctions]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Accordion key={claim?.id} className="accordion" expanded={expanded} onChange={handleChange}>
        <AccordionSummary data-testid={claim?.id} expandIcon={<ArrowRightIcon />}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Typography className={classes.text}>
                {t('report.claim.dateOfLoss')} :{' '}
                {claim?.dateOfLoss
                  ? DateHelper.formatDateToLocalTime(claim?.dateOfLoss, false)
                  : t('report.common.notAvailable')}
              </Typography>
            </Grid>
            <Grid item xs={4} paddingLeft={1}>
              <Typography className={classes.text}>{claim?.carrier?.nameEn}</Typography>
            </Grid>
            <Grid container item xs={3} justifyContent="flex-start" paddingLeft={1}>
              {claim?.isThirdParty && (
                <Typography className={classes.text}>{t('report.claim.involvement_thirdparty')}</Typography>
              )}
              {claim?.isThirdParty && claim?.isReserveOnly && '\u00A0'}
              {claim?.isReserveOnly && (
                <Typography className={classes.text}>{t('report.claim.reserveOnly')}</Typography>
              )}
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <Grid item container xs={isCorrectionMode ? 8 : 12} justifyContent="flex-end">
                <Typography className={classes.text} noWrap>
                  {t('report.claim.atFault')}:
                  {claim?.atFault || claim?.atFault === 0 ? ` ${claim?.atFault}%` : t('report.common.notAvailable')}
                </Typography>
              </Grid>
              {isCorrectionMode && isUserAllowedToEditClaim() && isAllowedToEditClaimAtFault && (
                <Grid item xs={4}>
                  <EditButton
                    open={isCorrectionMode}
                    onClick={(e) => {
                      setAutocompleteEditDialogOpen(true);
                      e.stopPropagation();
                    }}
                    dataTestId="atFaultEditButton"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          data-testid={`details${claim?.id}`}
          sx={{ paddingBottom: 0, backgroundColor: theme.palette.secondary.light }}
        >
          {
            <ClaimDetail
              claim={claim}
              isCorrectionMode={isCorrectionMode}
              reportId={reportId}
              isUserAllowedToEditClaim={isUserAllowedToEditClaim}
              operatorKey={operatorKey}
              isAllowedToEditClaim={isAllowedToEditClaim}
            />
          }
        </AccordionDetails>
      </Accordion>

      {/* Dialog for Data correction */}
      <Dialog
        open={autocompleteEditDialogOpen}
        fullWidth
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setAutocompleteEditDialogOpen(false);
          }
        }}
      >
        <EditDialogClaim
          title={t('report.claim.atFaultUpdate')}
          fieldName={'atFault'}
          onClose={() => setAutocompleteEditDialogOpen(false)}
          claim={claim!}
          reportId={reportId}
          operatorKey={operatorKey}
        />
      </Dialog>
    </>
  );
};

export default ClaimItem;
