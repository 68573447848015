// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function truncate(title: string, length: number): string {
  if (title.length > length) {
    return title.substring(0, length) + '...';
  } else {
    return title;
  }
}

export function truncateStart(title: string, length: number): string {
  if (title.length > length) {
    return '...' + title.substring(title.length - length, title.length);
  } else {
    return title;
  }
}

export function toPastelColor(str: string): string {
  let hash = 0;
  for (const char of str) {
    // tslint:disable-next-line:no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return 'hsl(' + h.toString(10) + ', 30%, 80%)';
}

export function toDarkColor(str: string): string {
  let hash = 0;
  for (const char of str) {
    // tslint:disable-next-line:no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return 'hsl(' + h.toString(10) + ', 100%, 50%, 50%)';
}

/**
 *
 * @param value
 * @returns formatted phone number (999 999 9999)
 */
export const formatPhone = (value: string | null) => {
  if (value) {
    const val = value.replace(/[^0-9]/gm, '');
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;
    num = num.trim();
    return num;
  } else {
    return '';
  }
};
