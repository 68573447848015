export interface AppFunction {
  id: number;
  nameFr: string;
  nameEn: string;
  sortOrder: number;
}

export const AppFunctionFactory = (id: number, nameFr: string, nameEn: string, sortOrder: number): AppFunction => {
  return {
    id,
    nameFr,
    nameEn,
    sortOrder,
  };
};

export default AppFunction;
