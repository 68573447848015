import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { msalInstance } from './Features/IbccrAuthorization/msalInstance';
import { persistor, store } from './Store';
import { i18nInit } from './Translations/i18next';

const onBeforeLift = async () => {
  await i18nInit();
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="...is loading">
        <PersistGate persistor={persistor} onBeforeLift={onBeforeLift}>
          <App pca={msalInstance} />
        </PersistGate>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
