import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation_en from './locales/en/translation.json';
import translation_fr from './locales/fr/translation.json';

export const i18nInit = (lng = '') => {
  if (lng !== '') {
    i18n.use(initReactI18next).init({
      debug: process.env.NODE_ENV !== 'production',
      lng: lng,
      interpolation: {
        escapeValue: false,
      },
      contextSeparator: '_',
      resources: {
        en: {
          translation: translation_en,
        },
        fr: {
          translation: translation_fr,
        },
        'fr-FR': {
          translation: translation_fr,
        },
        'fr-CA': {
          translation: translation_fr,
        },
        'en-EN': {
          translation: translation_en,
        },
        'en-CA': {
          translation: translation_en,
        },
        'en-US': {
          translation: translation_en,
        },
      },
    });
  } else {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
        detection: {
          order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
          lookupQuerystring: 'lng',
          lookupCookie: 'userId-language',
          lookupLocalStorage: 'i18nextLng',
          lookupSessionStorage: 'i18nextLng',
          caches: ['localStorage'],
          excludeCacheFor: ['cimode'],
        },
        contextSeparator: '_',
        resources: {
          en: {
            translation: translation_en,
          },
          fr: {
            translation: translation_fr,
          },
          'fr-FR': {
            translation: translation_fr,
          },
          'fr-CA': {
            translation: translation_fr,
          },
          'en-EN': {
            translation: translation_en,
          },
          'en-CA': {
            translation: translation_en,
          },
          'en-US': {
            translation: translation_en,
          },
        },
      });
  }
  i18n.services.formatter?.add('phonenumber', (value) => {
    const val = value.replace(/[^0-9]/gm, '');
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;
    num = num.trim();
    return num;
  });

  return i18n;
};

export default i18n;
