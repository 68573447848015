import { requests } from '../axios';
import {
  OrganizationFactory,
  ReportingCompanyFactory,
  UnitFactory,
  UserProfileSearchRequest,
  UserProfileSearchResult,
  UserProfileSearchResultFactory,
} from '../Types';

export const findUserProfile = (params: UserProfileSearchRequest): Promise<Array<UserProfileSearchResult>> => {
  if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
    return new Promise<Array<UserProfileSearchResult>>((resolve) => {
      const { userId, userName, organizationId, reportingCompanyId, unitId, status } = params;
      resolve([
        UserProfileSearchResultFactory({
          id: 1,
          userId: userId ? userId : 'testUserId',
          userName: userName ? userName : 'testUserName',
          organization: OrganizationFactory(organizationId ? organizationId : 1, 'Carrier', 'NameEn', 'NameFr', 'code'),
          reportingCompany: ReportingCompanyFactory(
            reportingCompanyId ? reportingCompanyId : 1,
            organizationId ? organizationId : 1,
            'coderpt',
            'nameFrRpt',
            'nameEnRpt'
          ),
          unit: UnitFactory(
            unitId ? unitId : 1,
            'nameFrDep',
            'nameEnDep',
            organizationId ? organizationId : 1,
            reportingCompanyId ? reportingCompanyId : 1
          ),
          status,
        }),
        UserProfileSearchResultFactory({
          id: 2,
          userId: userId ? userId : 'testUserId',
          userName: userName ? userName : 'testUserName',
          organization: OrganizationFactory(organizationId ? organizationId : 1, 'IBC', 'NameEn', 'NameFr', 'code'),
          reportingCompany: null,
          unit: UnitFactory(
            unitId ? unitId : 2,
            'nameFrDep',
            'nameEnDep',
            organizationId ? organizationId : 2,
            undefined
          ),
          status,
        }),
      ]);
    });
  } else {
    return requests.get('/Users/Search', params);
  }
};
