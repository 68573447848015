import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Container, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

// import ContainerGraphicTitle from '../../../Components/ContainerGraphicTitle';
import ContainerTitle from '../../../Components/ContainerTitle';
import Message from '../../../Components/Message';
import { formatDateToLocalTime } from '../../../Helpers/DateHelper';
import { sortVehicleClaimsDesc, sortVehiclePoliciesDesc } from '../../../Helpers/SortHelpers';
// import VehicleReportEN from '../../../Medias/titles/Vehicle Report - EN.jpg';
// import VehicleReportFR from '../../../Medias/titles/Vehicle Report - FR.jpg';
import theme from '../../../theme';
import { ReactLocationState, VehicleClaim, VehiclePolicy } from '../../../Types';
import VehicleReportClaims from './Claims/VehicleReportClaims';
import VehicleReportPolicies from './Policies/VehicleReportPolicies';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down('lg')]: {
        maxWidth: '90%',
      },
      '@media print': {
        maxWidth: '100%',
        paddingBottom: 0,
        margin: 0,
      },
    },
    header: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(3),
      '@media print': {
        color: 'black',
      },
    },
    headerFirstColumn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(3),
      '@media print': {
        color: 'black',
      },
    },
    headerSecondColumn: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(3),
      '@media print': {
        color: 'black',
      },
    },
    subHeader: {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.primary.dark,
      padding: theme.spacing(3),
      '@media print': {
        color: 'black',
        borderTop: '1px solid black',
      },
    },
    label: {
      display: 'inline',
      marginRight: '5px',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
    value: {
      display: 'inline',
      fontWeight: 'bold',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
    info: {
      display: 'inline',
      marginX: '1px',
      color: theme.palette.primary.main,
    },
    noteLabel: {
      display: 'inline',
      fontWeight: 'bold',
      marginRight: '5px',
      '@media print': {
        fontSize: '9pt',
      },
    },
    noteValue: {
      display: 'inline',
      '@media print': {
        fontSize: '9pt',
      },
    },
  })
);

const VehicleReportTemplateBase: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state } = useLocation<ReactLocationState>();

  const vehicleReport = state && state.vehicleReport ? state.vehicleReport : null;

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      document.getElementById('btnBack')?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // useEffect(() => {
  //   document.title = vehicleReport
  //     ? `Vehicle Report - ${vehicleReport.vin} - ${formatDateToLocalTime(vehicleReport.reportDate, true, true)}`
  //     : 'Report not loaded';
  // });

  const sortVClaims = useCallback(
    (a: VehicleClaim, b: VehicleClaim): number => {
      return sortVehicleClaimsDesc(a, b, i18n);
    },
    [i18n]
  );

  const sortVPolicies = useCallback(
    (a: VehiclePolicy, b: VehiclePolicy): number => {
      return sortVehiclePoliciesDesc(a, b, i18n);
    },
    [i18n]
  );

  const buttonBack = (
    <Grid item xs={3}>
      <Button
        fullWidth
        id="btnBack"
        variant="contained"
        color="secondary"
        onClick={() => history.push(state.from, state)}
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        {t('vehicleReport.report.back')}
      </Button>
    </Grid>
  );

  return (
    <>
      {vehicleReport ? (
        <Container disableGutters fixed className={classes.container}>
          <Grid container>
            <Grid item container justifyContent="space-between" spacing={2}>
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.vehicleReportBase')} button={buttonBack} />
                {/* <ContainerGraphicTitle
                  titleImage={i18n.language.startsWith('en') ? VehicleReportEN : VehicleReportFR}
                  button={buttonBack}
                /> */}
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                boxShadow: 5,
                backgroundColor: theme.palette.white.main,
                '@media print': {
                  boxShadow: 0,
                },
              }}
            >
              {/* Vehicle info */}
              <Grid item container>
                {/* Bleu header */}
                <Grid item container xs={12}>
                  {/* Col 1 */}
                  <Grid item container xs={6} className={classes.headerFirstColumn}>
                    <Grid item container sx={{ marginBottom: theme.spacing(2) }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 'bold',
                          color: theme.palette.white.main,
                          '@media print': {
                            color: 'black',
                          },
                        }}
                      >
                        {vehicleReport.vin.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>{t('vehicleReport.report.modelYear')}:</Typography>
                        <Typography className={classes.value}>
                          {vehicleReport.modelYear ?? t('vehicleReport.report.notAvailable')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>{t('vehicleReport.report.make')}:</Typography>
                        <Typography className={classes.value}>
                          {vehicleReport.makeEn
                            ? i18n.language.startsWith('en')
                              ? vehicleReport.makeEn
                              : vehicleReport.makeFr
                            : vehicleReport.vehicleCode?.toUpperCase().startsWith('P')
                            ? t('vehicleReport.report.priceCodedVehicle')
                            : t('vehicleReport.report.notAvailable')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.label}>{t('vehicleReport.report.model')}:</Typography>
                        <Typography className={classes.value}>
                          {vehicleReport.modelEn
                            ? i18n.language.startsWith('en')
                              ? vehicleReport.modelEn
                              : vehicleReport.modelFr
                            : vehicleReport.vehicleCode?.toUpperCase().startsWith('P')
                            ? t('vehicleReport.report.priceCodedVehicle')
                            : t('vehicleReport.report.notAvailable')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Col 2 */}
                  <Grid item container xs={6} alignItems="flex-end" className={classes.headerSecondColumn}>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.reportDate')}:</Typography>
                      <Typography className={classes.value}>
                        {vehicleReport.reportDate
                          ? formatDateToLocalTime(vehicleReport.reportDate, true)
                          : t('vehicleReport.report.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.requestor')}:</Typography>
                      <Typography className={classes.value}>{vehicleReport.requestor}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.company')}:</Typography>
                      <Typography className={classes.value}>
                        {i18n.language.startsWith('en') ? vehicleReport.company.nameEn : vehicleReport.company.nameFr}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.lastUpdate')}:</Typography>
                      <Typography className={classes.value}>
                        {vehicleReport.lastUpdated
                          ? formatDateToLocalTime(vehicleReport.lastUpdated, false)
                          : t('vehicleReport.report.notAvailable')}
                      </Typography>
                    </Grid>
                    {/* false lines - empty space */}
                    <Grid item xs={12} height="100%"></Grid>
                  </Grid>
                </Grid>
                {/* Grey sub-header */}
                <Grid item container xs={12} className={classes.subHeader}>
                  {/* Col 1 */}
                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.vehicleCode')}:</Typography>
                      <Typography className={classes.value}>
                        {vehicleReport.vehicleCode ?? t('vehicleReport.report.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.gvw')}:</Typography>
                      <Typography className={classes.value}>
                        {vehicleReport.grossVehicleWeight
                          ? `${vehicleReport.grossVehicleWeight}kg`
                          : t('vehicleReport.report.notAvailable')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>{t('vehicleReport.report.branding')}:</Typography>
                      <Typography className={classes.value}>
                        {(i18n.language.startsWith('en')
                          ? vehicleReport.branding?.descriptionEn
                          : vehicleReport.branding?.descriptionFr) ?? t('vehicleReport.none')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Claims */}
              <Grid item container xs={12}>
                <VehicleReportClaims
                  claims={vehicleReport.claims.sort((a: VehicleClaim, b: VehicleClaim) => sortVClaims(a, b))}
                  /* claims={[]} */ totalLoss={vehicleReport.totalClaimsLoss}
                  totalExpense={vehicleReport.totalClaimsExpense}
                />
              </Grid>

              {/* Policies*/}
              <Grid item container xs={12}>
                <VehicleReportPolicies
                  policies={vehicleReport.policies.sort((a: VehiclePolicy, b: VehiclePolicy) => sortVPolicies(a, b))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container disableGutters fixed className={classes.container}>
          <Grid item container xs={12} justifyContent="center" marginTop={theme.spacing(2)}>
            <Message message={t('errorMessage.error404')} severity="error" />
          </Grid>
        </Container>
      )}
    </>
  );
};

export default withRouter(VehicleReportTemplateBase);
