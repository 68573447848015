import DriverReportOption from './DriverReportOption';
import NotificationRecipientOption from './NotificationRecipientOption';
import PermittedDomainOption from './PermittedDomain';
import Unit from './Unit';

export type CompanyProfileOption =
  | Unit
  | DriverReportOption
  | NotificationRecipientOption
  | PermittedDomainOption
  | undefined;

export interface CompanyProfileItem {
  itemType: CompanyProfileItemType;
  option: CompanyProfileOption;
}

export enum CompanyProfileItemType {
  Unit,
  Dro,
  Nro,
  Domain,
}
