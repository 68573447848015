import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Typography } from '@mui/material';

import { GetContactUsContent } from '../Helpers/ContactUsHelper';

const ContactUs: FC = () => {
  const { t } = useTranslation();

  return (
    <Container data-testid="contactUsContainer">
      <Box alignContent="flex-start" justifyContent="flex-start">
        {/* <Typography variant="h4" color="textPrimary" sx={{ fontWeight: 'bold' }} align="justify">
          {t('contactUs.title')}
        </Typography> */}
        {GetContactUsContent()}
      </Box>
    </Container>
  );
};

export default ContactUs;
