import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Grid, Typography } from '@mui/material';

import { formatDateToLocalTime } from '../Helpers/DateHelper';
import theme from '../theme';
import { User } from '../Types';

interface WelcomeTileProps {
  user: User;
}

const WelcomeTile: FC<WelcomeTileProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        height: '200px',
        width: '616px',
        backgroundColor: theme.palette.secondary.main,
        margin: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
        boxShadow: '#8f939c 1.95px 1.95px 5px',
      }}
    >
      <Grid
        container
        sx={{
          height: '200px',
          width: '616px',
        }}
        paddingLeft={theme.spacing(10)}
        paddingTop={theme.spacing(6)}
        paddingBottom={theme.spacing(6)}
        alignItems="center"
      >
        <Grid item container xs={12} justifyContent="flex-start">
          <Typography
            color={theme.palette.white.main}
            sx={{
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '18pt',
            }}
          >
            {t('home.welcomeMessage', { displayName: user.firstName! })}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-start">
          <Typography
            color={theme.palette.white.main}
            sx={{
              textTransform: 'none',
              fontSize: '12pt',
            }}
          >
            {user.previousLogin &&
              t('home.lastSignin', { date: formatDateToLocalTime(new Date(user.previousLogin), true) })}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WelcomeTile;
