import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Typography } from '@mui/material';

import { GetPrivacyPolicyContent } from '../Helpers/PrivacyHelper';

const Privacy: FC = () => {
  const { t } = useTranslation();

  return (
    <Container data-testid="privacyPolicyContainer">
      <Box alignContent="flex-start" justifyContent="flex-start">
        <Typography variant="h4" color="textPrimary" sx={{ fontWeight: 'bold' }} align="justify">
          {t('privacy.title')}
        </Typography>
        {GetPrivacyPolicyContent()}
      </Box>
    </Container>
  );
};

export default Privacy;
