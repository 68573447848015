import { requests } from '../axios';
import { BlobDto } from '../Types';

export const updateFaq = (lang: string, faq: BlobDto): Promise<BlobDto> => {
  return requests.put(`/faq?lang=${lang}`, faq);
};

export const getFaq = (lang: string): Promise<BlobDto> => {
  return requests.get(`/faq?lang=${lang}`, {});
};
