import { DataCorrection } from '.';
import { NinetyDaysReportStatus } from './Enums';
import UserFunctionCompany from './UserFunctionCompany';

export interface Report90DaysRequest {
  company: UserFunctionCompany | null;
  unitId: number | null;
  reportRequestor: string | null;
  fromDate: Date | null;
  toDate: Date | null;
  ninetyDaysReportStatus: NinetyDaysReportStatus;
}

export const Report90DaysRequestFactory = (props: Report90DaysRequest): Report90DaysRequest => {
  return {
    company: props.company,
    unitId: props.unitId,
    reportRequestor: props.reportRequestor,
    fromDate: props.fromDate,
    toDate: props.toDate,
    ninetyDaysReportStatus: props.ninetyDaysReportStatus,
  };
};

export interface Report90DaysResponse {
  ninetyDaysDataCorrectionId: number;
  reportId: string;
  company: UserFunctionCompany;
  reportRequestor: string;
  reportDate: Date;
  ianaTimezone: string;
  changeDate: Date;
  referenceNumber: string;
  driverLicenseNumber: string;
  changes: Array<DataCorrection>;
  reviewedBy: string;
}

export interface Report90DaysResponseCsvType {
  company: string;
  reportRequestor: string;
  reportDate: string;
  changeDate: string;
  referenceNumber: string;
  driverLicenseNumber: string;
  changes: string;
  reviewedBy: string;
}

export const Report90DaysResponseFactory = (props: Report90DaysResponse): Report90DaysResponse => {
  return {
    ninetyDaysDataCorrectionId: props.ninetyDaysDataCorrectionId,
    reportId: props.reportId,
    company: props.company,
    reportRequestor: props.reportRequestor,
    reportDate: props.reportDate,
    ianaTimezone: props.ianaTimezone,
    changeDate: props.changeDate,
    referenceNumber: props.referenceNumber,
    driverLicenseNumber: props.driverLicenseNumber,
    changes: props.changes,
    reviewedBy: props.reviewedBy,
  };
};

export interface Report90DaysUnreadCountResponse {
  unreadCount: number;
}

export default Report90DaysRequest;
