interface Unit {
  id: number;
  organizationId: number;
  reportingCompanyId?: number;
  nameFr: string;
  nameEn: string;
  hasUsers?: boolean;
}

export const UnitFactory = (
  id: number,
  nameFr: string,
  nameEn: string,
  organizationId: number,
  reportingCompanyId?: number,
  hasUsers?: boolean
): Unit => {
  return {
    id,
    nameFr,
    nameEn,
    organizationId,
    reportingCompanyId,
    hasUsers,
  };
};

export default Unit;
