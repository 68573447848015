import { requests } from '../axios';
import { ClaimCorrectionType, OperatorCorrectionType, PolicyCorrectionType, ReferenceType, Report } from '../Types';

// GET

export const getPolicyStatuses = (): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/PolicyStatuses`, {});
};

export const getGenders = (): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/Genders`, {});
};

export const getMaritalStatuses = (): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/MaritalStatuses`, {});
};

export const getVehicleLosses = (): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/VehicleLosses`, {});
};

export const getDriverTrainings = (): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/DriverTrainings`, {});
};

export const getOperatorsClaims = (claimId: number): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/Claim/Operators/${claimId}`, {});
};

export const getVehiclesClaims = (claimId: number): Promise<Array<ReferenceType>> => {
  return requests.get(`/DataCorrection/Claim/Vehicles/${claimId}`, {});
};

// POST

export const updateDriverInfo = (driverCorrection: OperatorCorrectionType): Promise<Report> => {
  return requests.post('DataCorrection/Driver', driverCorrection);
};

export const updatePolicy = (policyCorrection: PolicyCorrectionType): Promise<Report> => {
  return requests.post('DataCorrection/Policy', policyCorrection);
};

export const updateClaim = (claimCorrection: ClaimCorrectionType): Promise<Report> => {
  return requests.post('DataCorrection/Claim', claimCorrection);
};
