import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InvitationIdState {
  id: string;
}

const initialState = { id: '' } as InvitationIdState;

const invitationIdSlice = createSlice({
  name: 'invitationId',
  initialState,
  reducers: {
    setInvitationId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
  },
});

export const { setInvitationId } = invitationIdSlice.actions;
export default invitationIdSlice.reducer;
