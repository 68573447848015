export const EMAIL_REGEX =
  /^[A-Za-z0-9.dàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'_-]+@([\w-]+\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

export const VIN_REGEX = /[-[/\]{}()*+?.!"%&_=`>¨,\\^$|#\s]/;

export const DOMAIN_REGEX =
  /^@((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})/;

export const SPECIAL_CHAR_REGEX = /[^A-z\s\dàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ][\\^]?/;

export const passwordRegexMatch = (password: string) => {
  // special characters: @#$%^&*-_+=[]{}|\:',?/`~();!.
  const regex = new RegExp(
    /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,64}$/
  );
  return regex.test(password);
};
