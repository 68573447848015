import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { B2CProviders } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { B2CProvider, ModeEnum } from '../../../Types';

export interface AddRenameProviderProps {
  mode: ModeEnum.Create | ModeEnum.Update;
  item?: B2CProvider;
  onClose: () => void;
  onCreated: (result: B2CProvider) => void;
  onUpdated: (result: B2CProvider) => void;
}

const AddRenameProvider: FC<AddRenameProviderProps> = ({ mode, item, onClose, onCreated, onUpdated }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<{ domain: string; hint: string }>({
    domain: item && item.emailDomainName ? item.emailDomainName : '',
    hint: item && item.b2CDomainName ? item.b2CDomainName : '',
  });

  const isFormValid = useCallback(() => {
    return formValues.domain.length === 0 || formValues.hint.length === 0;
  }, [formValues]);

  const handleSave = async () => {
    setLoading(true);

    const params = {
      b2cDomainName: formValues.hint,
      emailDomainName: formValues.domain,
    };

    if (mode === ModeEnum.Create) {
      await B2CProviders.add(params)
        .then((provider) => {
          dispatch(
            addNotification(200, 'success', t('successMessage.created', { resource: provider.emailDomainName }))
          );
          onCreated(provider);
        })
        .catch(() => {
          dispatch(addNotification(500, 'error', t('errorMessage.error500')));
        })
        .finally(() => setLoading(false));
    } else if (item) {
      await B2CProviders.update(item.id, params)
        .then((provider) => {
          dispatch(
            addNotification(200, 'success', t('successMessage.updated', { resource: provider.emailDomainName }))
          );
          onUpdated(provider);
        })
        .catch(() => {
          dispatch(addNotification(500, 'error', t('errorMessage.error500')));
        })
        .finally(() => setLoading(false));
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      document.getElementById('btnSave')?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="addRenameDialogComponent"
      >
        <Typography
          variant="h5"
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
        >
          {mode === ModeEnum.Create
            ? t('settings.b2cSettings.addProviderTitle')
            : t('settings.b2cSettings.editProviderTitle')}
        </Typography>

        <DialogContent>
          <Grid container spacing={1} paddingTop={2} paddingBottom={1} paddingRight={1}>
            <Grid item xs={12}>
              <DashCustomInput
                label={t('settings.b2cSettings.hint')}
                labelGridSize={4}
                fieldGridSize={8}
                id="hint"
                value={formValues.hint}
                fullWidth
                variant="outlined"
                color="secondary"
                disabled={mode === ModeEnum.Update}
                placeholder={mode === ModeEnum.Create ? t('settings.b2cSettings.hint') : ''}
                autoComplete="nope"
                sx={{ paddingRight: '0' }}
                onChange={(e) => setFormValues({ ...formValues, hint: e.target.value, domain: e.target.value })}
                inputProps={{ 'data-testid': 'b2cDomainName', maxLength: 60 }}
              />
            </Grid>
            <Grid item xs={12}>
              <DashCustomInput
                label={t('settings.b2cSettings.domain')}
                labelGridSize={4}
                fieldGridSize={8}
                id="domain"
                value={formValues.domain}
                fullWidth
                variant="outlined"
                color="secondary"
                placeholder={t('settings.b2cSettings.domain')}
                autoComplete="nope"
                sx={{ paddingRight: '0' }}
                onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
                inputProps={{ 'data-testid': 'emailDomainName', maxLength: 60 }}
                autoFocus
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container columnGap={1} sx={{ justifyContent: 'end' }}>
            <Grid item xs={4}>
              <Button
                fullWidth
                size="large"
                data-testid="cancel"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                size="large"
                data-testid="save"
                variant="contained"
                id="btnSave"
                color="primary"
                disabled={isFormValid()}
                onClick={() => handleSave()}
              >
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {loading && <Loader open={true} />}
      </Dialog>
    </>
  );
};

export default AddRenameProvider;
