import { FC } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';

type LinkButtonProps = {
  label: string;
  link: string;
};

const LinkButton: FC<LinkButtonProps> = ({ label, link }) => {
  const handleClick = () => {
    window.open(link);
  };

  return (
    <>
      <Button
        size="large"
        variant="text"
        color="white"
        onClick={handleClick}
        sx={{ fontWeight: 400, padding: 0 }}
        data-testid="francaisBtn"
        endIcon={<KeyboardArrowRightIcon />}
      >
        {label}
      </Button>
    </>
  );
};

export default LinkButton;
