import { useTranslation } from 'react-i18next';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';

import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr';

import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import Message from '../../../Components/Message';
import { DATE_FORMAT, DATE_MASK } from '../../../Helpers/Constants';
import useAutofocus from '../../../Hooks/UseAutofocus';
import useIbcOrThirdPartySelected from '../../../Hooks/UseIbcOrThirdPartySelected';
import theme from '../../../theme';
import { FileStatus, Organization, ReportingCompany } from '../../../Types';
import { FileType } from '../../../Types/SdcReport';
import useSdcCompany from '../Hooks/useSdcCompany';
import useSdcFileStatus from '../Hooks/useSdcFileStatus';
import useSdcFileType from '../Hooks/useSdcFileType';
import useSdcOrganization from '../Hooks/useSdcOrganization';
import useSdcReportForm from '../Hooks/useSdcReportForm';
import SdcReportResults from './SdcReportResults';

const SdcReportSearch = () => {
  const { t, i18n } = useTranslation();

  const organizationRef = useAutofocus();

  const {
    formValues,
    errorFromDate,
    errorToDate,
    results,
    isLoadingResults,
    setOrganizationValue,
    setCompanyValue,
    handleFormSubmit,
    handleClearFields,
    handleAutocompleteOrgChange,
    handleAutocompleteCompanyChange,
    handleAutocompleteTypeChange,
    handleAutocompleteStatusChange,
    handleInputChange,
    handleDatePickerChange,
  } = useSdcReportForm();

  const { organizations, isLoadingOrganizations, sortOrganization } = useSdcOrganization(setOrganizationValue);
  const { companies, isLoadingCompanies, sortCompanies } = useSdcCompany(formValues.organizationId, setCompanyValue);
  const { fileStatusList, isLoadingFileStatus } = useSdcFileStatus();
  const { fileTypeList, isLoadingFileType } = useSdcFileType();

  const isIbcOrThirdPartySelected = useIbcOrThirdPartySelected(
    formValues.organizationId ? formValues.organizationId : null
  );

  return (
    <>
      <Card elevation={3} data-testid="sdcReportSearchCard">
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container>
              <Grid item container spacing={2} padding={2} xs={12}>
                <Grid item container spacing={1} xs={10} lg={10} xl={10}>
                  <Grid item md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingOrganizations}
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.organizationId !== null && organizations.length > 0
                          ? organizations.find((org) => org.id === formValues.organizationId)
                          : null
                      }
                      id="organization"
                      options={organizations.sort((a: Organization, b: Organization) => sortOrganization(a, b))}
                      getOptionLabel={(option: Organization) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          autoFocus
                          label={t('sdcReport.organization')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('sdcReport.all')}
                          InputProps={{
                            ...params.InputProps,
                            inputRef: organizationRef,
                            endAdornment: (
                              <>
                                {isLoadingOrganizations ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteOrgChange(value)}
                      disableClearable={organizations && organizations.length === 1}
                      data-testid="organization"
                    />
                  </Grid>
                  <Grid item md={12} lg={11} xl={8}>
                    {!isIbcOrThirdPartySelected ? (
                      <Autocomplete
                        fullWidth
                        noOptionsText={t('search.noOption')}
                        value={
                          formValues.reportingCompanyId !== null && companies.length > 0
                            ? companies.find((com) => com.id === formValues.reportingCompanyId)
                            : null
                        }
                        id="company"
                        options={companies.sort((a: ReportingCompany, b: ReportingCompany) => sortCompanies(a, b))}
                        getOptionLabel={(option: ReportingCompany) =>
                          i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                        }
                        renderInput={(params) => (
                          <DashCustomInput
                            {...params}
                            label={t('ninetyReport.rptComp')}
                            labelGridSize={3}
                            fieldGridSize={8}
                            variant="outlined"
                            color="secondary"
                            placeholder={t('userAdmin.userAdmin.all')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isLoadingCompanies ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                      sx={{ marginRight: theme.spacing(4) }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        onChange={(_, value) => handleAutocompleteCompanyChange(value)}
                        loading={isLoadingCompanies}
                        loadingText={t('loading')}
                        disableClearable={companies && companies.length === 1}
                        data-testid="company"
                      />
                    ) : (
                      /* Dummy field for company when IBC or 3rd party */
                      <DashCustomInput
                        fullWidth
                        id="companyHolderIbc3rdPart"
                        value={
                          formValues.organizationId !== null && organizations.length > 0
                            ? i18n.language.startsWith('en')
                              ? organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameEn
                              : organizations.find((oneOrg) => oneOrg.id === formValues.organizationId)?.nameFr
                            : ''
                        }
                        label={t('ninetyReport.rptComp')}
                        labelGridSize={3}
                        fieldGridSize={8}
                        variant="outlined"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                        disabled={!formValues.organizationId}
                      />
                    )}
                  </Grid>
                  <Grid item md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingFileType}
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.fileTypeCode !== null && fileTypeList.length > 0
                          ? fileTypeList.find((status) => status.code === formValues.fileTypeCode)
                          : null
                      }
                      id="status"
                      options={fileTypeList}
                      getOptionLabel={(option: FileType) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          autoFocus
                          label={t('sdcReport.fileType')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingFileType ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteTypeChange(value)}
                      data-testid="status"
                    />
                  </Grid>
                  <Grid item md={12} lg={11} xl={8}>
                    <DashCustomInput
                      fullWidth
                      labelGridSize={3}
                      fieldGridSize={8}
                      variant="outlined"
                      color="secondary"
                      id="fileName"
                      label={t('sdcReport.fileName')}
                      value={formValues.fileName ?? ''}
                      inputProps={{
                        'data-testid': 'fileName',
                        name: 'fileName',
                      }}
                      autoComplete="nope"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item md={12} lg={11} xl={8}>
                    <Autocomplete
                      fullWidth
                      loading={isLoadingFileStatus}
                      noOptionsText={t('search.noOption')}
                      value={
                        formValues.fileStatusCode !== null && fileStatusList.length > 0
                          ? fileStatusList.find((status) => status.code === formValues.fileStatusCode)
                          : null
                      }
                      id="status"
                      options={fileStatusList}
                      getOptionLabel={(option: FileStatus) =>
                        i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                      }
                      renderInput={(params) => (
                        <DashCustomInput
                          {...params}
                          autoFocus
                          label={t('sdcReport.status')}
                          labelGridSize={3}
                          fieldGridSize={8}
                          variant="outlined"
                          color="secondary"
                          placeholder={t('userAdmin.userAdmin.all')}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingFileStatus ? (
                                  <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, value) => handleAutocompleteStatusChange(value)}
                      data-testid="status"
                    />
                  </Grid>

                  {/* Datepickers */}
                  <Grid container item md={12} lg={11} xl={8}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs={3} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
                        <Typography sx={{ fontWeight: 700, display: 'inline' }}>{t('sdcReport.dateRange')}</Typography>
                      </Grid>
                      <Grid item xs={3} paddingRight={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.startDate}
                            onChange={(newValue) => handleDatePickerChange('startDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'startDatePicker',
                                  placeholder: t('datepickerPlaceholderFrom').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                helperText={
                                  (errorFromDate &&
                                    (formValues.startDate ? t('sdcReport.invalidDate') : t('sdcReport.required'))) ||
                                  (errorToDate && ' ')
                                }
                                error={errorFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={3} paddingLeft={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={i18n.language.startsWith('en') ? enLocale : frLocale}
                        >
                          <DatePicker
                            clearable
                            disableFuture
                            mask={DATE_MASK}
                            inputFormat={DATE_FORMAT}
                            value={formValues.endDate}
                            onChange={(newValue) => handleDatePickerChange('endDate', newValue)}
                            renderInput={(params) => (
                              <DashCustomInput
                                {...params}
                                fullWidth
                                label={undefined}
                                fieldGridSize={12}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'endDatePicker',
                                  placeholder: t('datepickerPlaceholderTo').toString(),
                                }}
                                variant="outlined"
                                color="secondary"
                                helperText={
                                  (errorToDate &&
                                    (formValues.endDate ? t('sdcReport.invalidDate') : t('sdcReport.required'))) ||
                                  (errorFromDate && ' ')
                                }
                                error={errorToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END DatePicker */}

                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    md={12}
                    lg={11}
                    xl={8}
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    <Grid item container xs={3} sx={{ marginRight: theme.spacing(2) }} />
                    <Grid item container spacing={1} xs={6}>
                      <Grid item xs={5}>
                        <Button fullWidth type="submit" variant="contained" id="btnSearch" data-testid="btnSearch">
                          {t('sdcReport.search')}
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          onClick={handleClearFields}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('sdcReport.reset')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {results && results.length > 0 && (
                <Grid item container xs={12} padding={2}>
                  <Grid item container xs={12}>
                    <Typography fontWeight={700}>{`${results.length} ${
                      results.length === 1 ? t('ninetyReport.oneResultFound') : t('ninetyReport.manyResultsFound')
                    }`}</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <SdcReportResults fetchedResults={results} />
                  </Grid>
                </Grid>
              )}
              {results && results.length === 0 && (
                <Grid item container xs={12} padding={2} justifyContent="center" marginTop={theme.spacing(2)}>
                  <Message message={t('correctionReport.noResults')} severity="info" />
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Loader open={isLoadingResults} />
    </>
  );
};

export default SdcReportSearch;
