import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FastForward } from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme';
import { Gaps, Policy } from '../../Types';
import PolicyItem from './PolicyItem';

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      height: '32pt',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white.main,
    },
    buttonHeader: {
      color: theme.palette.white.main,
      fontWeight: 700,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '16pt',
      [theme.breakpoints.down('md')]: {
        fontSize: '12pt',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '14pt',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16pt',
      },
    },
    disclaimer: {
      fontSize: '9pt',
      color: theme.palette.primary.dark,
    },
    paper: {
      backgroundColor: theme.palette.secondary.light,
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(1.3),
      paddingRight: theme.spacing(4),
      height: '32pt',
      color: theme.palette.primary.dark,
    },
    gapTitle: {
      fontWeight: 700,
      fontSize: '11pt',
      [theme.breakpoints.down('lg')]: {
        fontSize: '10pt',
      },
    },
    noneContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.dark,
    },
    expandAll: {
      transform: 'scale(1)',
      color: theme.palette.white.main,
    },
    collapseAll: {
      transform: 'rotate(90deg)',
      color: theme.palette.white.main,
    },
  })
);

interface PolicyListProps {
  isCorrectionMode: boolean;
  policies?: Array<Policy>;
  gaps?: Array<Gaps>;
  reportId: string;
  operatorKey: number;
}

const PolicyList: React.FC<PolicyListProps> = ({
  isCorrectionMode,
  policies = [],
  gaps = [],
  reportId,
  operatorKey,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [allExpanded, setAllExpanded] = useState<boolean>(false);
  const accordionCount = policies.length;

  const handleExpandAll = () => {
    setAllExpanded(!allExpanded);
  };

  const policiesWithGaps = useCallback(() => {
    const policiesWithPossibleGaps: Array<Policy | Gaps> = [];
    if (gaps.length === 0 || policies.length < 2) return policies;
    for (let index = 0; index <= policies.length - 2; index++) {
      const current = policies[index];
      const next = policies[index + 1];
      policiesWithPossibleGaps.push(current);
      const gap = gaps.find(
        (g) => g.startDate.valueOf() === next.stopDate.valueOf() && g.stopDate.valueOf() === current.startDate.valueOf()
      );
      if (gap) policiesWithPossibleGaps.push(gap);
      if (index === policies.length - 2) {
        policiesWithPossibleGaps.push(next);
      }
    }
    return policiesWithPossibleGaps;
  }, [policies, gaps]);

  const handleAccordionStateChanged = useCallback(() => {
    const getCollapsedAccordionSections = document.querySelectorAll('#policyList [aria-expanded="true"]');
    setAllExpanded(accordionCount === getCollapsedAccordionSections.length);
  }, [accordionCount]);

  return (
    <Grid container sx={{ backgroundColor: theme.palette.white.main }}>
      <Grid container item data-testid="policiesheader" xs={12} alignItems="center" className={classes.header}>
        <Grid item>
          <Typography data-testid="header" className={classes.title}>
            {t('report.policy.header')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleExpandAll}>
            <FastForward className={clsx(!allExpanded && classes.expandAll, allExpanded && classes.collapseAll)} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item container xs={12} paddingTop={1} paddingLeft={2}>
        <Typography fontWeight={700} className={classes.disclaimer}>
          {t('report.policy.disclaimer')}:
        </Typography>
        &nbsp;
        <Typography className={classes.disclaimer} data-testid="comDisclaimer">
          {t('report.policy.commercialDisclaimer')}
        </Typography>
      </Grid>

      <Grid container item rowSpacing={0.5} padding={0.5} data-testid="policies" xs={12} id="policyList">
        {policiesWithGaps().length > 0 &&
          policiesWithGaps().map((p, index) => {
            if ('policyNumber' in p) {
              return (
                <Grid item xs={12} key={index}>
                  <PolicyItem
                    policy={p as Policy}
                    isCorrectionMode={isCorrectionMode}
                    expand={allExpanded}
                    onAccordionStateChanged={handleAccordionStateChanged}
                    reportId={reportId}
                    operatorKey={operatorKey}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} key={index}>
                  <Paper className={classes.paper} elevation={0}>
                    <Grid container alignItems="center">
                      <Grid item xs={2}></Grid>
                      <Grid item xs={4} alignItems="center" paddingLeft={1}></Grid>
                      <Grid item container xs={3} justifyContent="flex-start" paddingLeft={3}>
                        <Typography className={classes.gapTitle}>{t('report.policy.possibleGap')}</Typography>
                      </Grid>
                      <Grid item container xs={3} justifyContent="flex-end"></Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            }
          })}
        {policiesWithGaps().length === 0 && (
          <Grid item container xs={12} className={classes.noneContainer}>
            <Typography sx={{ fontWeight: 700 }}>{t('report.claim.none')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default PolicyList;
