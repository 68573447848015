import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContainerTitle from '../Components/ContainerTitle';
// import ContainerGraphicTitle from '../Components/ContainerGraphicTitle';
import UserAccessReportSearch from '../Features/UserAccessReport/UserAccessReportSearch';
import doUserHaveAccessToFeature from '../Helpers/UserHelper';
// import AccessEN from '../Medias/titles/Access - EN.jpg';
// import AccessFR from '../Medias/titles/Access - FR.jpg';
import { Functions, User } from '../Types';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBottom: theme.spacing(13),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  tab: {
    fontWeight: '700',
  },
}));

interface UserAccessReportPageProps extends RouteComponentProps {
  user: User;
}

const UserAccessReportPage: FC<UserAccessReportPageProps> = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const userAccReportAccess = doUserHaveAccessToFeature(user, Functions.userAccessReport);

  return (
    user && (
      <Container className={classes.root}>
        {userAccReportAccess && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContainerTitle title={t('menu.accessReport')} />

                {/* <ContainerGraphicTitle titleImage={i18n.language.startsWith('en') ? AccessEN : AccessFR} /> */}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UserAccessReportSearch />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    )
  );
};

export default withRouter(UserAccessReportPage);
