export interface CodeReference {
  code: string;
  descriptionEn: string;
  descriptionFr: string;
}

export const CodeReferenceFactory = (code: string, descriptionFr: string, descriptionEn: string): CodeReference => {
  return {
    code,
    descriptionEn,
    descriptionFr,
  };
};

export default CodeReference;
