import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';

import { formatDateToLocalTime } from '../../Helpers/DateHelper';
import { sortUsageReportResponse } from '../../Helpers/SortHelpers';
import { getComparator, Order } from '../../Helpers/TableHelper';
import theme from '../../theme';
import { UsageReportResponse } from '../../Types';
import { UsageReportResponseCsv } from '../../Types/UsageReport';

const useStyles = makeStyles(() => ({
  buttonText: {
    fontWeight: 700,
    padding: 0,
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  textCell: {
    fontWeight: 300,
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.primary.dark,
  },
}));

interface UsageReportResultsProps {
  fetchedResults: Array<UsageReportResponse>;
  setFetchedResultsCsv: Dispatch<SetStateAction<UsageReportResponseCsv[] | null>>;
  fromDate: Date | null;
  toDate: Date | null;
}

interface HeadCell {
  id: keyof UsageReportResponse;
  label: string;
  width: number;
}

const UsageReportResults: FC<UsageReportResultsProps> = ({
  fetchedResults,
  setFetchedResultsCsv,
  fromDate,
  toDate,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [sortedResults, setSortedResults] = useState<Array<UsageReportResponse> | null>(null);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof UsageReportResponse>('organization');

  const headCells: readonly HeadCell[] = [
    {
      id: 'organization',
      label: t('usageReport.organization'),
      width: 17,
    },
    {
      id: 'reportingCompany',
      label: t('usageReport.company'),
      width: 17,
    },
    {
      id: 'unit',
      label: t('usageReport.unit'),
      width: 17,
    },
    {
      id: 'userId',
      label: t('usageReport.userId'),
      width: 27,
    },
    {
      id: 'userName',
      label: t('usageReport.username'),
      width: 18,
    },
  ];

  // According to specification, table should have a default sort before user input
  useEffect(() => {
    setSortedResults(fetchedResults ? fetchedResults.sort((a, b) => sortUsageReportResponse(a, b, i18n)) : null);
  }, [fetchedResults, i18n]);

  // This is used to update a specific CSV state of the results to save the CSV with the order of the table
  useEffect(() => {
    setFetchedResultsCsv(
      sortedResults
        ? sortedResults
            .map((oneResult) => ({
              userId: oneResult.userId,
              userName: oneResult.userName ? oneResult.userName : '',
              organization: i18n.language.startsWith('en')
                ? oneResult.organization?.nameEn
                  ? oneResult.organization?.nameEn
                  : ''
                : oneResult.organization?.nameFr
                ? oneResult.organization?.nameFr
                : '',
              reportingCompany:
                oneResult.organization && oneResult.reportingCompany
                  ? i18n.language.startsWith('en')
                    ? oneResult.reportingCompany?.nameEn
                      ? oneResult.reportingCompany?.nameEn
                      : ''
                    : oneResult.reportingCompany?.nameFr
                    ? oneResult.reportingCompany?.nameFr
                    : ''
                  : i18n.language.startsWith('en')
                  ? oneResult.organization?.nameEn
                    ? oneResult.organization?.nameEn
                    : ''
                  : oneResult.organization?.nameFr
                  ? oneResult.organization?.nameFr
                  : '',
              unit: oneResult.unit ? oneResult.unit : '',
              numberOfRequest: oneResult.numberOfRequest,
            }))
            .sort(getComparator(order, orderBy))
        : []
    );
  }, [i18n.language, order, orderBy, setFetchedResultsCsv, sortedResults]);

  // This function was wrapped in a useCallback to prevent the unecessary render of the formatting of the date in the header
  const dateStringDisplay = useCallback(() => {
    return `${fromDate ? formatDateToLocalTime(fromDate, false) : ''} ${t('usageReport.to')} ${
      toDate ? formatDateToLocalTime(toDate, false) : ''
    } ${new Date().toLocaleDateString('en-CA', { day: '2-digit', timeZoneName: `short` }).slice(4)}`;
  }, [fromDate, t, toDate]);

  const handleRequestSort = (property: keyof UsageReportResponse) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof UsageReportResponse) => () => {
    handleRequestSort(property);
  };

  return (
    <>
      {fetchedResults.length > 0 && sortedResults && (
        <TableContainer component={'div'} data-testid="tableContainer">
          <Table
            size="small"
            stickyHeader
            aria-label="table-90days-result"
            sx={{ tableLayout: 'fixed', width: '100%' }}
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ width: `${headCell.width}%` }}
                    data-testid={`headerCell-${headCell.id}`}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell
                  key="numberOfRequests"
                  sx={{ width: '20%' }}
                  data-testid="headerCell-numberOfRequests"
                  align="right"
                >
                  <Grid container justifyContent="flex-end">
                    <Grid item>{t('usageReport.numberOfRequests')}</Grid>
                    {fromDate && toDate && (
                      <Grid item>
                        <Typography sx={{ fontSize: '9pt', fontWeight: 700 }}>{dateStringDisplay()}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="tableBody">
              {sortedResults
                .map((oneResult) => ({
                  userId: oneResult.userId,
                  userName: oneResult.userName ? oneResult.userName : '',
                  organization: i18n.language.startsWith('en')
                    ? oneResult.organization?.nameEn
                      ? oneResult.organization?.nameEn
                      : ''
                    : oneResult.organization?.nameFr
                    ? oneResult.organization?.nameFr
                    : '',
                  reportingCompany:
                    oneResult.organization && oneResult.reportingCompany
                      ? i18n.language.startsWith('en')
                        ? oneResult.reportingCompany?.nameEn
                          ? oneResult.reportingCompany?.nameEn
                          : ''
                        : oneResult.reportingCompany?.nameFr
                        ? oneResult.reportingCompany?.nameFr
                        : ''
                      : i18n.language.startsWith('en')
                      ? oneResult.organization?.nameEn
                        ? oneResult.organization?.nameEn
                        : ''
                      : oneResult.organization?.nameFr
                      ? oneResult.organization?.nameFr
                      : '',
                  unit: oneResult.unit ? oneResult.unit : '',
                  numberOfRequest: oneResult.numberOfRequest,
                }))
                .sort(getComparator(order, orderBy))
                .map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                      sx={{ width: '17%' }}
                      data-testid={`tableCell-org-${index}`}
                    >
                      <Tooltip title={row.organization} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.organization}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '17%' }}
                      data-testid={`tableCell-rptComp-${index}`}
                    >
                      <Tooltip title={row.reportingCompany ?? ''} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.reportingCompany ?? ''}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '17%' }}
                      data-testid={`tableCell-rptComp-${index}`}
                    >
                      <Tooltip title={row.unit ?? ''} enterTouchDelay={0}>
                        <Typography className={classes.textCell}>{row.unit ?? ''}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '27%' }}
                      data-testid={`tableCell-userId-${index}`}
                    >
                      <Tooltip enterTouchDelay={0} title={row.userId ?? ''}>
                        <Typography className={classes.textCell}>{row.userId}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '18%' }}
                      data-testid={`tableCell-username-${index}`}
                    >
                      <Tooltip enterTouchDelay={0} title={row.userName ?? ''}>
                        <Typography className={classes.textCell}>{row.userName}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: '20%' }}
                      data-testid={`tableCell-numberOfRequests-${index}`}
                      align="right"
                    >
                      <Typography className={classes.textCell}>{row.numberOfRequest}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default UsageReportResults;
