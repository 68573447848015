import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { formatDateToLocalTime } from '../../../../Helpers/DateHelper';
import theme from '../../../../theme';
import { VehicleClaim } from '../../../../Types';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      display: 'inline',
      color: theme.palette.primary.dark,
      marginRight: '5px',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
        color: 'black',
      },
    },
    value: {
      display: 'inline',
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      marginTop: '5px',
      '@media print': {
        fontSize: '9pt',
        color: 'black',
      },
    },
    lossGrid: {
      display: 'inline',
      marginRight: '5px',
      marginTop: '21px',
      fontSize: '9pt',
    },
  })
);

interface VehicleReportClaimsHeaderProps {
  claim: VehicleClaim;
}

const VehicleReportClaimsHeader: FC<VehicleReportClaimsHeaderProps> = ({ claim }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid
      container
      sx={{ backgroundColor: theme.palette.white.main }}
      paddingLeft={3}
      paddingRight={3}
      paddingTop={2}
      paddingBottom={2}
    >
      {/* Col 1 */}
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.dateOfLoss')}:</Typography>
          <Typography className={classes.value}>{formatDateToLocalTime(claim.dateOfLoss, false)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.insurer')}:</Typography>
          <Typography className={classes.value}>
            {(i18n.language.startsWith('en') ? claim.insurer?.nameEn : claim.insurer?.nameFr) ??
              t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.policyNumber')}:</Typography>
          <Typography className={classes.value}>
            {claim.policyNumber ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.claimNumber')}:</Typography>
          <Typography className={classes.value}>
            {claim.claimNumber ?? t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.claimStatus')}:</Typography>
          <Typography className={classes.value}>
            {i18n.language.startsWith('en') ? claim.status.descriptionEn : claim.status.descriptionFr}
          </Typography>
        </Grid>
        {claim.claimKindOfLosses &&
          claim.claimKindOfLosses?.length > 2 &&
          claim.claimKindOfLosses?.map((kol, index) => {
            if (claim.claimKindOfLosses && index + 2 < claim.claimKindOfLosses?.length) {
              return <Grid item xs={12} key={index} className={classes.lossGrid}></Grid>;
            }
          })}
      </Grid>
      {/* Col 2 */}
      <Grid item container xs={6} paddingLeft={3}>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.vehicleLoss')}:</Typography>
          <Typography className={classes.value}>
            {claim.vehicleLoss
              ? i18n.language.startsWith('en')
                ? claim.vehicleLoss.descriptionEn
                : claim.vehicleLoss.descriptionFr
              : t('vehicleReport.report.notAvailable')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.totalLoss')}:</Typography>
          <Typography className={classes.value}>
            {new Intl.NumberFormat(i18n.language, {
              style: 'currency',
              currency: 'CAD',
              currencyDisplay: 'narrowSymbol',
            }).format(claim.totalLoss)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>{t('vehicleReport.report.claims.totalExpense')}:</Typography>
          <Typography className={classes.value}>
            {new Intl.NumberFormat(i18n.language, {
              style: 'currency',
              currency: 'CAD',
              currencyDisplay: 'narrowSymbol',
            }).format(claim.totalExpense)}
          </Typography>
        </Grid>
        {/* KOL */}
        {claim.claimKindOfLosses &&
          claim.claimKindOfLosses.map((kol, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Typography className={classes.label}>
                  {`${kol.code ? `KOL${kol.code} - ` : ''}${
                    i18n.language.startsWith('en') ? kol.descriptionEn : kol.descriptionFr
                  }:`}
                </Typography>
                <Typography className={classes.value}>
                  {kol.lossAmount
                    ? `${new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: 'CAD',
                        currencyDisplay: 'narrowSymbol',
                      }).format(kol.lossAmount)} (${t('report.claim.labelLoss')}); `
                    : ''}

                  {kol.expenseAmount
                    ? `${new Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: 'CAD',
                        currencyDisplay: 'narrowSymbol',
                      }).format(kol.expenseAmount)} (${t('report.claim.labelExpense')}); `
                    : ''}
                </Typography>
              </Grid>
            );
          })}
        {claim.claimKindOfLosses && claim.claimKindOfLosses?.length >= 0 && claim.claimKindOfLosses?.length < 2 ? (
          claim.claimKindOfLosses?.map((kol, index) => {
            return <Grid item xs={12} key={index} className={classes.lossGrid}></Grid>;
          })
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default VehicleReportClaimsHeader;
