import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Autocomplete, Button, CircularProgress, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { DashClients, ReportingCompanies } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import Message from '../../../Components/Message';
import { sortOrganizations, sortRptCompanies } from '../../../Helpers/SortHelpers';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import { GetAllOrganizations, getOrganizations } from '../../../Slices/OrganizationSlice';
import theme from '../../../theme';
import {
  ClientTypeEnum,
  ModeEnum,
  ModeType,
  Organization,
  ReactLocationState,
  ReportingCompany,
  Sponsor,
} from '../../../Types';
import { FormValuesDashClientDetails } from '../DashClientDetails';

interface AddSponsorDialogProps {
  mode: ModeType;
  formValues: FormValuesDashClientDetails;
  setFormValues: Dispatch<SetStateAction<FormValuesDashClientDetails>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  clientId?: number;
  clientTypeId?: number;
  hasUser: boolean;
}

const AddSponsorDialog: FC<AddSponsorDialogProps> = ({
  mode = ModeEnum.Create,
  formValues,
  setFormValues,
  setOpen,
  clientId = 0,
  clientTypeId = 0,
  hasUser = false,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const handleError = useErrorHandler();
  const history = useHistory();
  const { state } = useLocation<ReactLocationState>();

  const organizations = useSelector((state: { organization: any }) => GetAllOrganizations(state)).filter(
    (oneOrg) => oneOrg.organizationType === 'Carrier'
  );

  const [fetchedRptCompanies, setFetchedRptCompanies] = useState<Array<ReportingCompany> | null>(null);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [reportingCompany, setReportingCompany] = useState<ReportingCompany | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingRpt, setIsLoadingRpt] = useState<boolean>(false);
  const isLoadingOrganizations = useSelector(
    (state: { organization: { isPending: boolean } }) => state.organization.isPending
  );

  const [errorExist, setErrorExist] = useState<boolean>(false);

  // Fetch Orgs
  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  // Fetch Reporting Companies
  useEffect(() => {
    if (organization) {
      setIsLoadingRpt(true);
      ReportingCompanies.getByOrganizationWithoutUserFunction(organization.id)
        .then((results) => {
          setFetchedRptCompanies(results);
          if (results.length === 1) {
            setReportingCompany(results[0]);
          }
          setIsLoadingRpt(false);
        })
        .catch((error) => {
          handleError(error);
          setIsLoadingRpt(false);
        });
    }
  }, [dispatch, handleError, organization, t]);

  const sortOrganization = useCallback(
    (a: Organization, b: Organization): number => {
      return sortOrganizations(a, b, i18n);
    },
    [i18n]
  );

  const sortCompanies = useCallback(
    (a: ReportingCompany, b: ReportingCompany): number => {
      return sortRptCompanies(a, b, i18n);
    },
    [i18n]
  );

  const handleAdd = async () => {
    if (organization && reportingCompany) {
      setIsLoading(true);

      const sponsor: Sponsor = {
        id: 0,
        clientId: clientId,
        organization: {
          id: organization.id,
          nameEn: organization.nameEn,
          nameFr: organization.nameFr,
        },
        reportingCompany: {
          id: reportingCompany.id,
          nameEn: reportingCompany.nameEn,
          nameFr: reportingCompany.nameFr,
        },
        hasUser: false,
      };

      if (mode === ModeEnum.Update) {
        DashClients.createSponsor(sponsor)
          .then((result) => {
            // result.hasUser = hasUser;
            // setFormValues({
            //   ...formValues,
            //   sponsors: formValues.sponsors.concat(result),
            // });
            // setIsLoading(false);
            // setOpen(false);
            // dispatch(addNotification(200, 'success', t('dashclients.clientDetails.ok200AddSponsor')));

            DashClients.getClientDetails(clientId.toString(), ClientTypeEnum[clientTypeId])
              .then((result) => {
                if (result) {
                  result.sponsors.forEach((s) => (s.hasUser = result.hasEmployedUser));
                  setFormValues({
                    ...formValues,
                    sponsors: result.sponsors,
                  });
                }
                setIsLoading(false);
                setOpen(false);
                dispatch(addNotification(200, 'success', t('dashclients.clientDetails.ok200AddSponsor')));
              })
              .catch((error) => {
                handleError(error);
                setIsLoading(false);
              });
          })
          .catch((error) => {
            // handleError(error, { error409: 'dashclients.clientDetails.error409DuplicateSponsor' });
            if (error.message.includes('409')) {
              setErrorExist(true);
              setIsLoading(false);
              return;
            }
            setIsLoading(false);
            setOpen(false);
          });
      } else {
        if (
          formValues.sponsors.find(
            (oneSpon) =>
              oneSpon.organization.id === organization.id && oneSpon.reportingCompany.id === reportingCompany.id
          )
        ) {
          setIsLoading(false);
          setErrorExist(true);
        } else {
          setFormValues({
            ...formValues,
            sponsors: formValues.sponsors.concat(sponsor),
          });
          setIsLoading(false);
          setOpen(false);
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAutocompleteOrg = (value: Organization | null) => {
    setErrorExist(false);
    setOrganization(value);
    setReportingCompany(null);
  };

  const handleAutocompleteComp = (value: ReportingCompany | null) => {
    setErrorExist(false);
    setReportingCompany(value);
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
      >
        {t('dashclients.clientDetails.addSponsor')}
      </Typography>
      <DialogContent sx={{ padding: theme.spacing(4) }} data-testid="addSponDiag">
        <Grid container spacing={1}>
          <Grid item container xs={12} rowSpacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                loading={isLoadingOrganizations}
                loadingText={t('loading')}
                noOptionsText={t('search.noOption')}
                value={organization ? organizations.find((oneOrg) => oneOrg.id === organization.id) : null}
                id="organizationId"
                data-testid="organizationId"
                options={organizations.sort((a: Organization, b: Organization) => sortOrganization(a, b))}
                getOptionLabel={(option: Organization) =>
                  i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                }
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    label={undefined}
                    labelGridSize={0}
                    fieldGridSize={12}
                    variant="outlined"
                    color="secondary"
                    placeholder={t('dashclients.clientDetails.orgSponsorPlaceholder')}
                  />
                )}
                onChange={(_, value) => handleAutocompleteOrg(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                loading={isLoadingRpt}
                loadingText={t('loading')}
                noOptionsText={t('search.noOption')}
                disableClearable={fetchedRptCompanies == null ? true : fetchedRptCompanies.length === 1 ? true : false}
                value={
                  reportingCompany !== null && fetchedRptCompanies
                    ? fetchedRptCompanies.find((oneRpt) => oneRpt.id === reportingCompany.id)
                    : null
                }
                id="reportingCompanyId"
                data-testid="reportingCompanyId"
                options={
                  fetchedRptCompanies
                    ? fetchedRptCompanies.sort((a: ReportingCompany, b: ReportingCompany) => sortCompanies(a, b))
                    : []
                }
                getOptionLabel={(option: ReportingCompany) =>
                  i18n.language.startsWith('en') ? option.nameEn : option.nameFr
                }
                renderInput={(params) => (
                  <DashCustomInput
                    {...params}
                    label={undefined}
                    labelGridSize={0}
                    fieldGridSize={12}
                    variant="outlined"
                    color="secondary"
                    placeholder={t('dashclients.clientDetails.compSponsorPlaceholder')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingRpt ? (
                            <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                onChange={(_, value) => handleAutocompleteComp(value)}
                disabled={!organization}
              />
            </Grid>
            {errorExist && (
              <Grid item xs={12}>
                <Message
                  message={t('dashclients.clientDetails.error409DuplicateSponsor')}
                  severity="error"
                  compact={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
          <Grid item xs={4}>
            <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={handleClose}>
              {t('dialog.cancel')}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              data-testid="add"
              variant="contained"
              color="primary"
              onClick={handleAdd}
              disabled={!(organization && reportingCompany)}
            >
              {t('dialog.add')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Loader open={isLoading} />
    </>
  );
};

export default AddSponsorDialog;
