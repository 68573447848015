import { Grid, GridSize, OutlinedTextFieldProps, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import theme from '../theme';

const useStyles = makeStyles(() => ({
  customLabel: {
    display: 'flex !important',
    width: '100% !important',
    alignItems: 'baseline !important',
  },
  labelAlignment: {
    verticalAlign: 'sub',
    display: 'inline',
  },
}));

interface DashCustomInputTopAlignProps extends OutlinedTextFieldProps {
  labelGridSize?: GridSize;
  fieldGridSize?: GridSize;
  labelColor?: string;
  isRequired?: boolean;
}

const DashCustomInputTopAlign = ({
  labelGridSize,
  fieldGridSize,
  labelColor,
  isRequired,
  ...props
}: DashCustomInputTopAlignProps) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" wrap="nowrap" className={classes.customLabel}>
      {props.label && (
        <Grid item xs={labelGridSize ? labelGridSize : 4} sx={{ marginRight: theme.spacing(2), textAlign: 'end' }}>
          <Typography
            color={props.disabled ? theme.palette.text.disabled : labelColor}
            sx={{ fontWeight: 700, display: 'inline' }}
            className={classes.labelAlignment}
          >
            {props.label}
          </Typography>
          {isRequired && !props.disabled && (
            <Typography color={theme.palette.primary.main} sx={{ fontWeight: 700, display: 'inline' }}>
              &nbsp;*
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={fieldGridSize ? fieldGridSize : 8}>
        <TextField {...props} label={undefined} size="small" margin="none" sx={{ paddingRight: 0 }} />
      </Grid>
    </Grid>
  );
};

export default DashCustomInputTopAlign;
