import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import { NotificationRecipientOptions } from '../../../axios';
import DashCustomInput from '../../../Components/DashCustomInput';
import Loader from '../../../Components/Loader';
import { EMAIL_REGEX } from '../../../Helpers/Regex';
import useErrorHandler from '../../../Hooks/UseErrorHandler';
import { addNotification } from '../../../Slices/NotificationSlice';
import theme from '../../../theme';
import { ModeEnum } from '../../../Types';
import NotificationRecipientOption from '../../../Types/NotificationRecipientOption';
import { AddDialogProps } from './AddDialogProps';

const AddRecipientDialog: FC<AddDialogProps<NotificationRecipientOption>> = ({
  mode,
  item,
  selectedOrgId,
  selectedRptCompanyId,
  onAction,
  onCreated,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [open, setOpen] = useState<boolean>(true);
  const [field, setField] = useState<string>(item && item.email ? item.email : '');
  const [isEmailFormatError, setIsEmailFormatError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isFieldEmpty = useCallback(() => {
    return field.length === 0;
  }, [field]);

  const handleSave = async () => {
    if (!field.match(EMAIL_REGEX)) {
      setIsEmailFormatError(true);
    } else {
      setIsLoading(true);
      setOpen(false);
      const params = {
        email: field,
        organizationId: selectedOrgId,
        reportingCompanyId: selectedRptCompanyId,
      };

      if (mode === ModeEnum.Create) {
        await NotificationRecipientOptions.create(params)
          .then((recipient) => {
            dispatch(addNotification(200, 'success', t('successMessage.created', { resource: recipient.email })));
            onCreated(recipient);
            setIsLoading(false);
          })
          .catch((error) => {
            handleError(error, { error409: t('errorMessage.error409Duplicate') });
            setIsLoading(false);
          });
      }
      onAction();
    }
  };

  const handleClose = () => {
    setOpen(false);
    onAction();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value as string;
    if (!value.match(EMAIL_REGEX)) {
      //setIsEmailFormatError(true);
      setField(e.target.value);
    } else {
      setIsEmailFormatError(false);
      setField(e.target.value);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
        fullWidth
        data-testid="addRenameDialogComponent"
      >
        <Typography variant="h5" sx={{ paddingLeft: theme.spacing(4), paddingTop: theme.spacing(4) }}>
          {t('userAdmin.companyProf.add')} {t('userAdmin.companyProf.dialogTitleNro')}
        </Typography>
        <DialogContent sx={{ padding: theme.spacing(4) }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
              <DashCustomInput
                autoFocus
                id="name"
                value={field}
                fullWidth
                labelGridSize={0}
                fieldGridSize={12}
                variant="outlined"
                color="secondary"
                placeholder={t('userAdmin.companyProf.addEmailPlaceholder')}
                autoComplete="nope"
                sx={{ paddingRight: '0' }}
                onChange={(e) => handleEmailChange(e)}
                error={isEmailFormatError}
                helperText={isEmailFormatError && t('userAdmin.companyProf.emailError')}
                inputProps={{ 'data-testid': 'textfield', maxLength: 60 }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
        >
          <Grid container spacing={1} sx={{ justifyContent: 'end' }}>
            <Grid item xs={4}>
              <Button fullWidth data-testid="cancel" variant="contained" color="secondary" onClick={handleClose}>
                {t('dialog.cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                data-testid="accept"
                variant="contained"
                color="primary"
                disabled={isFieldEmpty() || isEmailFormatError}
                ref={enterKeyRef}
                onClick={handleSave}
              >
                {t('dialog.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading} />
    </>
  );
};

export default AddRecipientDialog;
