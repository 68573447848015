import { requests } from '../axios';
import { SystemMessage } from '../Types';

export const updateSystemMessage = (systemMessage: SystemMessage): Promise<SystemMessage> => {
  return requests.put('/SystemMessage', systemMessage);
};

export const getSystemMessage = (): Promise<SystemMessage> => {
  return requests.get('/SystemMessage', {});
};
