export interface ClaimKindOfLosses {
  code: string | null;
  descriptionEn: string;
  descriptionFr: string;
  lossAmount: number | null;
  expenseAmount: number | null;
  isReserve: boolean;
  reserveDate: string;
}

export const ClaimKindOfLossesFactory = (
  code: string | null,
  descriptionEn: string,
  descriptionFr: string,
  lossAmount: number | null,
  expenseAmount: number | null,
  isReserve: boolean,
  reserveDate: string
): ClaimKindOfLosses => {
  return {
    code: code,
    descriptionEn: descriptionEn,
    descriptionFr: descriptionFr,
    lossAmount: lossAmount,
    expenseAmount: expenseAmount,
    isReserve: isReserve,
    reserveDate: reserveDate,
  };
};

export default ClaimKindOfLosses;
