import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';

import theme from '../theme';

export interface ConfirmDialogProps {
  id?: number;
  title: string;
  contentText: string;
  isDelete: boolean;
  buttonText?: string;
  isOkButton?: boolean;
  onAction: (isConfirmed: boolean, id?: number) => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  id,
  title,
  contentText,
  isDelete,
  buttonText,
  isOkButton,
  onAction,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const enterKeyRef = useRef<HTMLButtonElement>(null);

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      enterKeyRef.current?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleConfirm = () => {
    setOpen(false);
    if (isDelete) {
      onAction(true, id);
    } else {
      onAction(true);
    }
  };

  const handleClose = (event?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
      onAction(false);
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => handleClose(event, reason)}
      maxWidth="sm"
      data-testid="confirmDialogComponent"
    >
      <Typography
        variant="h5"
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4) }}
      >
        {title}
      </Typography>

      <DialogContent>
        <Box padding={1}>
          <Typography>{contentText}</Typography>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingBottom: theme.spacing(4) }}
      >
        <Grid container justifyContent="flex-end" columnGap={1}>
          {!isOkButton && (
            <Grid item xs={4}>
              <Button
                fullWidth
                size="large"
                data-testid="cancel"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                {t('dialog.cancel')}
              </Button>
            </Grid>
          )}
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              data-testid="accept"
              variant="contained"
              color="primary"
              ref={enterKeyRef}
              onClick={() => handleConfirm()}
            >
              {buttonText ? buttonText : isDelete && !buttonText ? t('dialog.delete') : t('dialog.yes')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
