import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ApplicationPaths } from './Features/Authorization/AuthorizationConstants';
import AuthorizationRoutes from './Features/Authorization/AuthorizationRoutes';
import AuthorizeRoute from './Features/Authorization/AuthorizeRoute';
import DashClientDetails from './Features/DashClients/DashClientDetails';
import UserAccessReportDetails from './Features/UserAccessReport/UserAccessReportDetails';
import CreateEditUserProfile from './Features/UserAdministration/UserProfile/CreateEditUserProfile/CreateEditUserProfile';
import VehicleReportTemplateBase from './Features/VehicleReport/ReportTemplate/VehicleReportTemplateBase';
import About from './Pages/About';
import B2CSetup from './Pages/B2CSetup';
import ChangePasswordPage from './Pages/ChangePasswordPage';
import CompanyProfilePage from './Pages/CompanyProfilePage';
import ContactUs from './Pages/ContactUs';
import CorrectionReportPage from './Pages/CorrectionReportPage';
import DashClientsPage from './Pages/DashClientsPage';
import Disclaimer from './Pages/Disclaimer';
import DriverReportSearch from './Pages/DriverReportSearch';
import Faq from './Pages/Faq';
import Home from './Pages/Home';
import LoginError from './Pages/LoginError';
import NpmPackagesDependencies from './Pages/NpmPackagesDependencies';
import NugetPackagesDependencies from './Pages/NugetPackagesDependencies';
import Privacy from './Pages/Privacy';
import Report from './Pages/Report';
import Report90Days from './Pages/Report90Days';
import ReportHistoryPage from './Pages/ReportHistoryPage';
import SdcReportPage from './Pages/SdcReportPage';
import Settings from './Pages/Settings';
import Signin from './Pages/Signin';
import Signout from './Pages/Signout';
import Terms from './Pages/Terms';
import UsageReportPage from './Pages/UsageReportPage';
import UserAccessReportPage from './Pages/UserAccessReportPage';
import UserAdministration from './Pages/UserAdministration';
import VehicleReport from './Pages/VehicleReport';
import { User } from './Types';

interface RouteProps {
  user: User | undefined;
}

const Routes: FC<RouteProps> = ({ user }) => {
  return (
    <Switch>
      <AuthorizeRoute exact path="/" component={Home} user={user} />
      <AuthorizeRoute exact path="/vehicle-report" component={VehicleReport} user={user} />
      <AuthorizeRoute exact path="/vehicle-report/:id" component={VehicleReportTemplateBase} />
      <AuthorizeRoute exact path="/driver-report" component={DriverReportSearch} user={user} />
      <AuthorizeRoute exact path="/report-history" component={ReportHistoryPage} user={user} />
      <AuthorizeRoute exact path="/ninetydays-report" component={Report90Days} user={user} />
      <AuthorizeRoute exact path="/reports/:id" component={Report} />
      <AuthorizeRoute exact path="/user-administration" component={UserAdministration} user={user} />
      <AuthorizeRoute exact path="/user-administration/userProfile" component={CreateEditUserProfile} user={user} />
      <AuthorizeRoute exact path="/company-profile" component={CompanyProfilePage} user={user} />
      <AuthorizeRoute exact path="/usage-report" component={UsageReportPage} user={user} />
      <AuthorizeRoute exact path="/user-access-report" component={UserAccessReportPage} user={user} />
      <AuthorizeRoute exact path="/user-access-report-details/:id" component={UserAccessReportDetails} user={user} />
      <AuthorizeRoute exact path="/settings" component={Settings} user={user} />
      <AuthorizeRoute exact path="/dash-client" component={DashClientsPage} user={user} />
      <AuthorizeRoute exact path="/dash-client/:id" component={DashClientDetails} user={user} />
      <AuthorizeRoute exact path="/correction-report" component={CorrectionReportPage} user={user} />
      <AuthorizeRoute exact path="/change-password" component={ChangePasswordPage} user={user} />
      <AuthorizeRoute exact path="/b2c" component={B2CSetup} user={user} />
      <AuthorizeRoute exact path="/sdc" component={SdcReportPage} user={user} />
      <Route exact path="/about" component={About} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/contactUs" component={ContactUs} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/NugetPackagesDependencies" component={NugetPackagesDependencies} />
      <Route exact path="/NpmPackagesDependencies" component={NpmPackagesDependencies} />
      <Route exact path="/login" component={Signin} />
      <Route exact path="/login/:invitationId" component={Signin} />
      <Route exact path="/advanced-login" render={() => <Redirect to={ApplicationPaths.Login} />} />
      <Route exact path="/login-failed/:code" component={LoginError} />
      <Route exact path="/authentication/logged-out" component={Signout} />
      <Route path={ApplicationPaths.AuthorizationPrefix} component={AuthorizationRoutes} />
    </Switch>
  );
};

export default Routes;
