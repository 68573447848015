import { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import { B2CProviders } from '../../axios';
import DashCustomInput from '../../Components/DashCustomInput';
import LanguageToggle from '../../Components/LanguageToggle';
import LinkButton from '../../Components/LinkButton';
import myInitObject from '../../Helpers/Constants';
import { EMAIL_REGEX } from '../../Helpers/Regex';
import { sessionGetData, sessionRemoveData, sessionStore } from '../../Helpers/SessionStorageService';
import DashWhiteLogoEN from '../../Medias/DASH-logo-white-EN.png';
import DashWhiteLogoFR from '../../Medias/DASH-logo-white-FR.png';
import Signin_BG from '../../Medias/Signin_BG.jpg';
import { addNotification } from '../../Slices/NotificationSlice';
import { RootState } from '../../Store';
import theme from '../../theme';
import { ApplicationPaths } from '../Authorization/AuthorizationConstants';

const SigninForm: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const invitationId: string = useSelector((state: RootState) => state.invitationId.id);

  const [username, setUserName] = useState<string>('');
  const [remembermeChecked, setRemembermeChecked] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const userId = sessionGetData('userid');
    if (userId) {
      setUserName(userId);
      setRemembermeChecked(true);
    }
  }, []);

  const handleSigninClicked = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!EMAIL_REGEX.test(username)) {
      setShowError(true);
      return;
    }
    setShowError(false);

    if (remembermeChecked) {
      sessionStore('userid', username);
    } else {
      sessionRemoveData('userid');
    }

    setIsLoading(true);
    B2CProviders.find(username)
      .then((provider) => {
        history.push(
          `${ApplicationPaths.Login}?domainHint=${provider.b2CDomainName}&loginHint=${username}` +
            (invitationId ? `&invitationId=${invitationId}` : ``)
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(addNotification(500, 'error', t('signin.error500')));
      });
  };

  // const handleSignInOptions = () => {
  //   history.push(`${ApplicationPaths.Login}` + (invitationId ? `?invitationId=${invitationId}` : ``));
  // };

  const handleFieldChange = (input: string) => {
    setShowError(false);
    setUserName(input);
  };

  const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemembermeChecked(event.target.checked);
  };

  return (
    <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <div
        style={{
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${Signin_BG})`,
        }}
      >
        <div
          style={{
            width: '599px',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.6)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: 'white',
            fontFamily: 'Arial',
            padding: '3.5rem',
            paddingBottom: '3.5rem',
            marginLeft: 'auto',
            marginRight: '7.5%',
          }}
        >
          <img src={i18n.language.startsWith('en') ? DashWhiteLogoEN : DashWhiteLogoFR} alt="DASH-White-Logo" />
          <Typography sx={{ fontSize: '1.25rem', marginTop: theme.spacing(2) }}>{t('signin.subTitle')}</Typography>
          <form onSubmit={handleSigninClicked} style={{ padding: '0 0', marginTop: '3.4rem', marginBottom: '3.4rem' }}>
            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography>{t('signin.lbemail')}</Typography>
              </Grid>
              <Grid item sx={{ marginTop: '0px' }} xs={12}>
                <DashCustomInput
                  labelGridSize={0}
                  fieldGridSize={12}
                  error={showError}
                  label={undefined}
                  id="username"
                  fullWidth
                  autoFocus
                  value={username}
                  variant="outlined"
                  color="secondary"
                  placeholder={t('signin.emailPlaceholder')}
                  autoComplete="nope"
                  onChange={(e) => handleFieldChange(e.target.value)}
                  inputProps={{ 'data-testid': 'username' }}
                  helperText={showError ? t('signin.invalidEmail') : ''}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remembermeChecked}
                      onChange={handleRememberMeChange}
                      color="white"
                      disableRipple
                      sx={{ paddingTop: 0, paddingBottom: 0, color: '#fff' }}
                    />
                  }
                  label={<Typography>{t('signin.lbRemeberMe')}</Typography>}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  id="btnSignIn"
                  type="submit"
                  sx={{ height: '39px', paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}
                  disabled={isLoading}
                >
                  {t('signin.button')}
                </Button>
              </Grid>
              {/* <Grid item>
                  {Constants.activateDevFeatures === 'true' && (
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Grid item xs={12}>
                        <Link
                          id="btnDefaultSignIn"
                          component="button"
                          variant="body2"
                          onClick={handleSignInOptions}
                          disabled={isLoading}
                        >
                          {t('signin.defaultLogin')}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </Grid> */}
            </Grid>
          </form>
          <Grid item container spacing={0} alignItems="center">
            <Grid item xs={12}>
              <LinkButton
                label={t('signin.lbForgotPass')}
                link={`${myInitObject.passwordResetPortalUrl}?lang=${i18n.language.startsWith('en') ? 'en' : 'fr'}`}
              />
            </Grid>
            <Grid item xs={12}>
              <LinkButton label={t('signin.lbLearnMore')} link={myInitObject.dashInfoSiteUrl} />
            </Grid>
            <Grid item xs={12}>
              <LanguageToggle isLoginPage />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SigninForm;
