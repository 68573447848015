import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Autocomplete, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Cookies from 'js-cookie';

import DashCustomInput from '../../Components/DashCustomInput';
import { compareABstrings } from '../../Helpers/SortHelpers';
import { GetAllJurisdictions } from '../../Slices/JurisdictionSlice';
import theme from '../../theme';
import { DriverReportOption, DriverReportOptionFactory, Jurisdiction, ReportingCompany } from '../../Types';
import { FormValuesProps } from './Search';

const useStyles = makeStyles(() => ({
  clearFields: {
    marginTop: '25px',
  },
  buttonText: {
    fontWeight: 700,
    fontSize: '14px',
    padding: 0,
    minWidth: 0,
    margin: 0,
    height: 40,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface FullSearchProps {
  formValues: FormValuesProps;
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesProps>>;
  fieldsEnabled: boolean;
  companies: Array<ReportingCompany> | null;
  isLoadingCompanies: boolean;
  dros: Array<DriverReportOption>;
  defaultDro: DriverReportOption;
  isLoadingDros: boolean;
  onSearch: (numberOfYears: DriverReportOption) => void;
  onLoading: (loading: boolean) => void;
}

const FullSearch: FC<FullSearchProps> = ({
  formValues,
  setFormValues,
  fieldsEnabled,
  isLoadingCompanies,
  dros,
  defaultDro,
  isLoadingDros,
  onSearch,
  onLoading,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

  const [numberOfYears, setNumberOfYears] = useState<DriverReportOption>(defaultDro);

  const provinces = useSelector((state: { province: any }) => GetAllJurisdictions(state));

  const startYear = 1900;
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i.toString());
  }

  // Listener for the enter key
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'NumpadEnter' || event.code === 'Enter') {
      document.getElementById('btnSearch')?.click();
    }
  };

  // Activate and deactivate listener for enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const sortJurisdictions = useCallback(
    (a: Jurisdiction, b: Jurisdiction): number => {
      return i18n.language.startsWith('en')
        ? compareABstrings(
            a.jurisdictionDescriptionEn.toLocaleLowerCase(),
            b.jurisdictionDescriptionEn.toLocaleLowerCase()
          )
        : compareABstrings(
            a.jurisdictionDescriptionFr
              .toLocaleLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
            b.jurisdictionDescriptionFr
              .toLocaleLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          );
    },
    [i18n.language]
  );

  const handleClearFields = () => {
    const resetObject: FormValuesProps = {
      jurisdictionCode: Cookies.get('userId-jurisdiction') ?? '',
      driverLicenceNumber: '',
      fullName: '',
      policyReportingCompanyCode: null,
      policyNumber: '',
      claimReportingCompanyCode: null,
      claimNumber: '',
      vehicleIdentificationNumber: '',
      fullAddress: '',
      yearOfBirth: '',
      comment: '',
    };
    setFormValues(resetObject);
    setNumberOfYears(defaultDro);
  };

  const handleToggleAdvanced = () => {
    setIsAdvanced(!isAdvanced);
  };

  const handleJurisdictionInputChange = (value: string) => {
    setFormValues({ ...formValues, jurisdictionCode: value });
    Cookies.set('userId-jurisdiction', value);
  };

  const handleInputChange = (e: React.ChangeEvent<{ name?: any; value: unknown }>) => {
    if (e.target.name === 'yearOfBirth' && (e.target.value as string).length > 4) {
      return;
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleVinFieldInputChange = (e: React.ChangeEvent<{ name?: any; value: unknown }>) => {
    const value = e.target.value as string;
    const trimValue = value.trim();
    if (trimValue.length <= 17) {
      setFormValues({ ...formValues, [e.target.name]: trimValue });
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onLoading(true);

    if (
      (formValues.policyReportingCompanyCode && formValues.policyNumber.length === 0) ||
      (formValues.claimReportingCompanyCode && formValues.claimNumber.length === 0)
    ) {
      onLoading(false);
      setIsAdvanced(true);
      return;
    } else {
      // If Component testing, use fake data
      if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
        if (formValues.jurisdictionCode === 'QC') {
          onLoading(false);
          return;
        }
        const numberOfYearsStub: DriverReportOption = DriverReportOptionFactory(1, 'testCypress', 1, true, 1, 15);
        onSearch(numberOfYearsStub);
        onLoading(false);
        return;
      } else {
        onSearch(numberOfYears);
      }
    }
  };

  return (
    <Card elevation={3}>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid item container xs={12} padding={2} data-testid="fullSearch">
            {/* Column fields */}
            <Grid item container xs={12} spacing={1}>
              {/* DLN / Jurisdiction */}
              <Grid item container md={11} lg={10} xl={8} spacing={1}>
                <Grid item xs={12}>
                  <DashCustomInput
                    fullWidth
                    labelGridSize={3}
                    fieldGridSize={5}
                    variant="outlined"
                    color="secondary"
                    id="driverLicenceNumber"
                    disabled={!fieldsEnabled}
                    label={t('search.ddlLicenseIdPlaceholder')}
                    value={formValues.driverLicenceNumber}
                    autoFocus
                    inputProps={{
                      'data-testid': 'driverLicenceNumber',
                      name: 'driverLicenceNumber',
                    }}
                    onChange={handleInputChange}
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    noOptionsText={t('search.noOption')}
                    value={
                      formValues.jurisdictionCode
                        ? provinces.find(
                            (oneProvince: Jurisdiction) => oneProvince.jurisdictionCode === formValues.jurisdictionCode
                          )
                        : null
                    }
                    id="jurisdiction"
                    data-testid="jurisdiction"
                    options={provinces.sort((a: Jurisdiction, b: Jurisdiction) => sortJurisdictions(a, b))}
                    disabled={!fieldsEnabled}
                    getOptionLabel={(option: Jurisdiction) =>
                      i18n.language.startsWith('en')
                        ? option.jurisdictionDescriptionEn
                        : option.jurisdictionDescriptionFr
                    }
                    onChange={(_, value) => handleJurisdictionInputChange(value ? value.jurisdictionCode : '')}
                    renderInput={(params) => (
                      <DashCustomInput
                        {...params}
                        labelGridSize={3}
                        fieldGridSize={5}
                        label={t('search.lblJurisdiction')}
                        variant="outlined"
                        color="secondary"
                        placeholder={t('search.placeholderJurisdiction')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {/* Advanced search segment */}
              {isAdvanced && (
                <Grid item container xs={12} spacing={1}>
                  <Grid item container md={11} lg={10} xl={8} spacing={1}>
                    <Grid item xs={12}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        label={t('search.driverName')}
                        value={formValues.fullName}
                        id="fullName"
                        inputProps={{
                          'data-testid': 'fullName',
                          name: 'fullName',
                        }}
                        onChange={handleInputChange}
                        disabled={!fieldsEnabled}
                        autoComplete="nope"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        label={t('search.address')}
                        value={formValues.fullAddress}
                        id="fullAddress"
                        inputProps={{
                          'data-testid': 'fullAddress',
                          name: 'fullAddress',
                        }}
                        onChange={handleInputChange}
                        disabled={!fieldsEnabled}
                        autoComplete="nope"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        label={t('search.yearOfBirth')}
                        value={formValues.yearOfBirth}
                        id="yearOfBirth"
                        placeholder={t('search.placeholderYoB')}
                        inputProps={{
                          'data-testid': 'yearOfBirth',
                          name: 'yearOfBirth',
                          maxLength: 4,
                          min: 1900,
                        }}
                        sx={{ display: 'inline-block' }}
                        onChange={handleInputChange}
                        onKeyDown={(evt) =>
                          (evt.key === 'e' || evt.key === '.' || evt.key === '+' || evt.key === '-') &&
                          evt.preventDefault()
                        }
                        disabled={!fieldsEnabled}
                        autoComplete="nope"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        label={t('search.vin')}
                        value={formValues.vehicleIdentificationNumber}
                        id="vehicleIdentificationNumber"
                        inputProps={{
                          'data-testid': 'vehicleIdentificationNumber',
                          name: 'vehicleIdentificationNumber',
                        }}
                        onChange={handleVinFieldInputChange}
                        disabled={!fieldsEnabled}
                        autoComplete="nope"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        label={t('search.policy')}
                        value={formValues.policyNumber}
                        id="policyNumber"
                        inputProps={{
                          'data-testid': 'policyNumber',
                          name: 'policyNumber',
                        }}
                        onChange={handleInputChange}
                        disabled={!fieldsEnabled}
                        autoComplete="nope"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DashCustomInput
                        fullWidth
                        labelGridSize={3}
                        fieldGridSize={5}
                        variant="outlined"
                        color="secondary"
                        label={t('search.claim')}
                        value={formValues.claimNumber}
                        id="claimNumber"
                        inputProps={{
                          'data-testid': 'claimNumber',
                          name: 'claimNumber',
                        }}
                        onChange={handleInputChange}
                        disabled={!fieldsEnabled}
                        autoComplete="nope"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* noy */}
              <Grid item container md={11} lg={10} xl={8} spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    disableClearable
                    noOptionsText={t('search.noOption')}
                    loading={isLoadingDros && isLoadingCompanies}
                    loadingText={t('loading')}
                    value={numberOfYears}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    id="numberOfYears"
                    data-testid="numberOfYears"
                    options={dros}
                    disabled={!fieldsEnabled}
                    getOptionLabel={(option: DriverReportOption) =>
                      i18n.language.startsWith('en')
                        ? `${
                            option.yearsOfData ? `${option.yearsOfData} Years` : t('userAdmin.companyProf.maxYears')
                          } ${option.note ? ` - ${option.note}` : ''}`
                        : `${option.yearsOfData ? `${option.yearsOfData} ans` : t('userAdmin.companyProf.maxYears')} ${
                            option.note ? ` - ${option.note}` : ''
                          }`
                    }
                    onChange={(_, value) => setNumberOfYears(value)}
                    renderInput={(params) => (
                      <DashCustomInput
                        {...params}
                        labelGridSize={3}
                        fieldGridSize={5}
                        label={t('search.numberOfYears')}
                        variant="outlined"
                        color="secondary"
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true,
                          endAdornment: (
                            <>
                              {isLoadingDros ? (
                                <CircularProgress color="inherit" size={20} sx={{ marginRight: theme.spacing(4) }} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {/* Legal */}
              <Grid
                item
                container
                md={11}
                lg={10}
                xl={8}
                spacing={1}
                sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sx={{
                    marginLeft: theme.spacing(3),
                  }}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item container xs={9}>
                    <Grid item container xs={12}>
                      <Typography align="justify" sx={{ fontSize: '9pt' }}>
                        <Trans i18nKey="search.legalSearch0" />
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography align="justify" sx={{ fontSize: '9pt' }}>
                        {t('search.legalSearch1')}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography align="justify" sx={{ fontSize: '9pt' }}>
                        {t('search.legalSearch2')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Ref */}
              <Grid item container md={11} lg={10} xl={8} spacing={1}>
                <Grid item xs={12}>
                  <DashCustomInput
                    fullWidth
                    labelGridSize={3}
                    fieldGridSize={5}
                    variant="outlined"
                    color="secondary"
                    label={t('search.comment')}
                    value={formValues.comment}
                    id="comment"
                    inputProps={{
                      'data-testid': 'comment',
                      name: 'comment',
                      maxLength: 30,
                    }}
                    onChange={handleInputChange}
                    disabled={!fieldsEnabled}
                    autoComplete="nope"
                  />
                </Grid>
              </Grid>
              {/* Buttons */}
              <Grid item container xs={12} spacing={1} sx={{ marginTop: theme.spacing(1) }} alignItems="center">
                <Grid item container xs={7} md={11} lg={10} xl={8} sx={{ marginLeft: theme.spacing(2) }}>
                  <Grid item container alignItems="center" wrap="nowrap" spacing={0}>
                    <Grid item xs={3} sx={{ textAlign: 'end' }}></Grid>
                    <Grid item container xs={5} spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          disabled={!fieldsEnabled}
                          variant="contained"
                          fullWidth
                          id="btnSearch"
                          // onClick={handleSubmit}
                          type="submit"
                          data-testid="btnSearch"
                        >
                          {t('search.btnSearchText')}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          onClick={handleClearFields}
                          variant="contained"
                          color="secondary"
                          data-testid="clearFields"
                        >
                          {t('search.clearFields')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} paddingLeft={1}>
                      <Button
                        className={classes.buttonText}
                        size="large"
                        variant="text"
                        color="primary"
                        id={'btnAdvancedSearch'}
                        data-testid="showAdvanced"
                        onClick={() => handleToggleAdvanced()}
                      >
                        {isAdvanced ? t('search.hideAdvanced') : t('search.showAdvanced')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};
export default FullSearch;
